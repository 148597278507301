import React,{useState} from 'react';
import "../../App.scss";
import Ayat from "../../assets/img/ayat-l.png";
import Kerja from "../../assets/img/kerjasama.svg";
import Gambar from "../../assets/img/GAMBAR.jpg";
import { Row, Col,  Button, Form,Alert,Modal } from 'react-bootstrap';
import {Resetuser} from "../../actions/auth.actions";
import {connect} from "react-redux";
import {forgotPassword} from "../../actions/auth.actions";
import swal from 'sweetalert';


class Reset_password extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
          fields: {},
          response: {},
          errors: {}
        }
      }

      
      forgotPassword = async (values) => {
        try {
          // dilempar kesini
            const response =  await this.props.dispatch(forgotPassword(values));
            console.log(response);
            if (!response.responseBody.success) {
                swal({text: response.responseBody.body.message , icon: 'error'})
              throw response;
            } else {
                swal({text: response.responseBody.body.message , icon: 'success'})
                const { history } = this.props;
                history.push('/signin');

            }
        } catch (error) {
          console.log(error);
        }
    }
    
    

    
       
        handleValidation() {
            let fields = this.state.fields;
            let errors = {};
            let formIsValid = true;

            //UserName
            if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Username Tidak Ada";
            }
            //Kode Reset
            if (!fields["otp_kode"]) {
                formIsValid = false;
                errors["otp_kode"] = "Kode Reset Harus Diisi";
                }

            //Password
            if (!fields["password"]) {
                formIsValid = false;
                errors["password"] = "Cannot be empty";
            }
            //Refeat Password
            if (!fields["re_password"]) {
                formIsValid = false;
                errors["re_password"] = "Cannot be empty";
            }


            this.setState({ errors: errors });
            return formIsValid;
        }


        onSubmit = (event) => {
            event.preventDefault();

            if (this.handleValidation()) {
                console.log("berhasil");
                this.forgotPassword(this.state.fields);
            
            } else {
                swal({text: 'Form has errors', icon: 'error'})
            }

        }

        handleChange = (event) => {
            let fields = this.state.fields;
            fields[event.currentTarget.getAttribute('name')] = event.target.value;
            fields['type'] = "update_password";
            this.setState({ fields });
        }

        setShow = (event) => {
            event.target.setAttribute('show2', false)
        }


    render() {
        const error_text = this.props.loginUser.errors;
        let error_panel;

        if (error_text) {
        error_panel =
            <Alert variant="danger" show={this.props.loginUser.isError}>
            <center>{error_text.message}</center>
            </Alert>
            ;
        } else {
        error_panel = '';
        }

     
        
        return(
    //  <div style={{backgroundImage:"url(" + Gambar + ")",backgroundSize:"cover",opacity:"0.7",height:"900px"}} >
        <div>
            <div className="container" style={{paddingTop:"100px",paddingBottom:"100px" }}>
            <div className="row mt-4 align-items-center justify-content-center">
               <div className="col-lg-6 col-sm-8 col-xs-8 col-lg-justify-content-left col-justify-content-center my-5"  >
                    <div className="card" style={{borderRadius:"20px", backgroundColor: "#fafafa", paddingRight: "10px"}}>
                        <div className=" text-center mt-4 ml-4" style={{color:"#666666", fontSize:"24px" }}>Reset Password</div>
                        <div className="card-body" >
                            <Form onSubmit={this.onSubmit} className="pl-3">
                            
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label className="text-muted"></Form.Label>
                                    <input className="form-control" type="email" name="email" id="email" placeholder="Email" onChange={this.handleChange} value={this.state.fields["email"] || ''} />
                                    <span style={{ color: "red" }}>{this.state.errors["email"]}</span>
                                </Form.Group>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label className="text-muted"></Form.Label>
                                    <input className="form-control" type="text" name="otp_kode" id="otp_kode" placeholder="Kode Reset Password" onChange={this.handleChange} value={this.state.fields["otp_kode"] || ''} />
                                    <span style={{ color: "red" }}>{this.state.errors["otp_kode"]}</span>
                                </Form.Group>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label className="text-muted"></Form.Label>
                                    <input className="form-control" type="password" name="password" id="password" placeholder="Password Baru" onChange={this.handleChange} value={this.state.fields["password"] || ''} />
                                    <span style={{ color: "red" }}>{this.state.errors["password"]}</span>
                                </Form.Group>

                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label></Form.Label>
                                    <input className="form-control" type="password" name="re_password" id="re_password" placeholder="Confirm Password Baru" onChange={this.handleChange} value={this.state.fields["re_password"] || ''}/>
                                    <span style={{ color: "red" }}>{this.state.errors["re_password"]}</span>
                                </Form.Group>
                            <div className="text-center pt-4">
                            <Button style={{borderRadius:"30px", padding:"5px 80px", background: "#033C86"  }} className=""  variant="primary" type="submit">
                                Submit
                            </Button>
                            </div>
                            </Form>
                        
                        </div>
                    </div>
                </div>
            </div>
            </div>
      
     </div>
            );
        }
    }
// }

function mapStateToProps(state) {
    return { 
      loginUser: state.authReducer.loginUser, 
      getUser: state.userReducer.getUser, 
      auth: state.authReducer.authData }
  }
  
  function mapDispatchToProps(dispatch) {
    return { dispatch }
  }

 

export default connect(mapStateToProps, mapDispatchToProps)(Reset_password) ;