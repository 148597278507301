import {api, fetchApi} from "../service/api";


export const getProject = (payload) => {
    return async (dispatch) => {

        try {
          dispatch({
            type: "GET_DATA_LOADING"
          });
            const response = await fetchApi("project", "GET" , payload, 200);


            if(response.status == 'success') {
                dispatch({
                    type: "GET_DATA_SUCCESS",
                });
                dispatch({
                    type: "GET_DATA_COMPLETE",
                    payload: response.data  
                });
                return response;
            } else {
                throw response;
            }
            
        } catch (error) {
            dispatch({
                type: "GET_DATA_ERROR",
                payload: error.responseBody
            });
            dispatch({
                type: "GET_DATA_FAIL"
            });
            return error;
        }
    }
}
