import { combineReducers } from 'redux';

const ListRecommendation = (state = {}, action) => {
    switch (action.type) {

      case "GET_LOADING":
          return {
            isLoading: true,
            isError: false,
            isSuccess: false,
            data: null,
            errors: null
          }

      case "GET_SUCCESS":
          return {
            isLoading: false,
            isError: false,
            isSuccess: true,
            data: action.payload,
            errors: null
          }

      case "GET_FAIL":
          return {
            isLoading: false,
            isError: true,
            isSuccess: false,
            data: null,
            errors: action.payload
          }

      default:
        return state;
    }
}

export default combineReducers({
    ListRecommendation
});
