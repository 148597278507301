import React, { Component, memo, useState } from 'react'
import { Modal, Form, Alert } from 'react-bootstrap';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../../../App.scss';
import '../../../AppMobile.scss';
import { BASE_URL, tokenGet } from "../../../service/api";
import img_avatar from '../../../assets/img/img_avatar.png'
import receiving_money from '../../../assets/img/receiving_money_1_@2x.png'
import saving_money from '../../../assets/img/saving_money_1_.png'
import change_img from '../../../assets/img/notfound.jpg'
import TabUserPemodal from './TabUserPemodal'
import $ from 'jquery'
import axios from 'axios'
import CurrencyFormat from 'react-currency-format';
import {
  Button, Reveal,
  Dropdown, Portal,
  Checkbox,
  Grid,
  Header,
  Icon,
  Image,
  Menu,
  Segment,
  Sidebar,
  Input,
  Label,
} from 'semantic-ui-react'
import swal from 'sweetalert';
import { saveAs } from 'file-saver';

export default class SidebarUserPemodal extends Component {

  constructor(props) {
    super(props);
    // 1. bind your functions in the constructor.
    this.mouseOver = this.mouseOver.bind(this);
    this.mouseOut = this.mouseOut.bind(this);
    this.state = {
      hover: false,
      animation: 'push',
      direction: 'left',
      allBanks: [],
      dimmed: false,
      visible: true,
      copySuccess: 'Copied!',
      change_img: change_img,
      img_profile: img_avatar,
      openPortal: false,
      totalInvest: 0,
      totalShareProfit: 0,
      errors: {}
    };
    this.increaseCount = this.increaseCount.bind(this);
  }

  increaseCount() {
    this.setState({ count: this.count + 1 });
  }

  handleAnimationChange = (animation) => () =>
    this.setState((prevState) => ({ animation, visible: !prevState.visible }))

  handleDimmedChange = (e, { checked }) => this.setState({ dimmed: checked })

  handleDirectionChange = (direction) => () =>
    this.setState({ direction, visible: false })

  copyToClipboard = (e) => {
    this.refCode.select();
    document.execCommand('copy');
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
  };

  handleInputChange = e => {
    this.setState({ value: e.target.value });
  };

  getTotalInvest() {
    const userinfo = JSON.parse(this.props.userinfo)

    axios.get(BASE_URL + "/customer/pemodal/pymntpemodal", {
      params: {
        user_id: userinfo.user_id,
      }
    },
      axios.defaults.headers.common['Authorization'] = localStorage.token,
      axios.defaults.headers.common['Accept'] = 'application/json'
    )
      .then((resp) => {
        this.setState({
          totalInvest: resp.data.totalInvest.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          totalShareProfit: resp.data.totalShareProfit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        })

      })
      .catch((resp) => {

      });
  }

  getDataBank() {

    axios.get(BASE_URL + "/allbanks",
      axios.defaults.headers.common['Authorization'] = tokenGet,
      axios.defaults.headers.common['Accept'] = 'application/json'
    )
      .then((resp) => {
        this.setState({
          allBanks: resp.data.data,
        })
        this.getTotalInvest();
      })
      .catch((resp) => {
        this.setState({
          allBanks: [],
        })
      });
  }

  componentDidMount() {
    var dataUser = JSON.parse(localStorage.DataUser);

    dataUser.user.dokumen.map((a, i) => {
      if (a.type == 'profile') {
        this.setState({
          img_profile: a.nama_file
        })
      }
    })

    this.getDataBank();
  }

  mouseOver = () => {
    this.setState({ hover: true });
  }

  mouseOut() {
    this.setState({ hover: false });
  }

  onFileChange(e, param) {
    var files = e.target.files;
    if (!files.length)
      return;
    this.createImage(files[0], param);
  }

  createImage(file, param) {
    var tmp = [];
    var reader = new FileReader();

    reader.onload = e => {
      if (param == 111) {
        this.setState({
          img_profile: e.target.result
        })
        this.uplFotoPic();
      }
    };
    reader.readAsDataURL(file);
  }

  uplFotoPic() {
    const userinfo = JSON.parse(this.props.userinfo)

    var values = {
      "file": this.state.img_profile,
      "user_id": userinfo.user_id
    }

    let config = {
      headers: {
        'Authorization': localStorage.token,
        'content-type': 'application/json',
        'Access-Control-Allow-Credentials': true,
        'Access-Control-Allow-Methods': 'POST',
      },
      maxContentLength: 52428890,
      timeout: 10000000,
    }

    axios.post(BASE_URL + "/customer/pemodal/uplfotopic", values, config)
      .then((resp) => {
        this.setState({
          openPortal: true
        });

        setTimeout(() => {
          this.setState({
            openPortal: false
          });
        }, 3000);
      })
      .catch((resp) => {

      });

  }
  clearConsole() {
    if (window.console || window.console.firebug) {
      console.clear();
    }
  }

  handleClosePortal = () => this.setState({ openPortal: false })

  handleDirectSign = () => {
    window.open("https://ttd.sandbox-111094.com/2AYLsSMwYlvB1", "Popup","toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30")
  }

  render() {
    // this.clearConsole();
    const { animation, dimmed, direction, visible, change_img, img_profile, openPortal, totalInvest, totalShareProfit } = this.state
    const vertical = direction === 'bottom' || direction === 'top'
    const userinfo = JSON.parse(this.props.userinfo)
    const verified = userinfo.ktp !== null ? 1 : 0
    const dataIsComplete = userinfo.is_complete
    const verified_advance = userinfo.status_user
    const agreement = userinfo.agreement
    const signature_status = userinfo.signature_status 
    const allBanks = this.state.allBanks
    const addressDefinitions = allBanks
    const stateOptions = addressDefinitions.map(state => ({
      key: state.id,
      text: state.nama_bank,
      value: state.nama_bank,
    }))

    // lengkapiData = async (values) => {
    const lengkapiData = async (values) => {
      // console.log("POST_DATA: " + JSON.stringify(values))
      // return;

      let config = {
        headers: {
          'Authorization': localStorage.token,
          'content-type': 'application/json',
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Methods': 'POST',
        },
        maxContentLength: 52428890,
        timeout: 10000000,
      }

      $(".btnSimpan").addClass('loading');
      $(".btnSimpan").addClass('disabled');
      $(".alertSuccessSubmit").attr('style', '')

      axios.post(BASE_URL + "/customer/pemodal/lengkapidata", values, config)
        .then((resp) => {
          // alert(resp.data.body.message);
          // setTimeout(() => {
          //   window.location.reload(true);
          // }, 0)
          swal({text: resp.data.body.message, icon: 'success', closeOnClickOutside: false})
              .then((value) => {
                setTimeout(() => {
                  window.location.reload(true);
                }, 0)
              });
        })
        .catch(error => {
          if (error.response.data.error_message) {
            // alert('Credit: ' + error.response.data.error_message.credit + ', Fraud: ' + error.response.data.error_message.fraud + ', Multiplatform: ' + error.response.data.error_message.multiplatform);
            swal({ text: 'Credit: ' + error.response.data.error_message.credit + ', Fraud: ' + error.response.data.error_message.fraud + ', Multiplatform: ' + error.response.data.error_message.multiplatform, icon: 'error'});
          } else if (error.response.data.success === false) {
            console.log("RESPONSE >> " + JSON.stringify(error.response.data))
            // alert(error.response.data.body.message);
            swal({text: error.response.data.body.message, icon: 'error'})
          } else {
            // alert('Request Timeout')
            swal({text: 'Request Timeout', icon: 'error'})
          }
          $(".btnSimpan").removeClass('loading');
          $(".btnSimpan").removeClass('disabled');
          $(".alertSuccessSubmit").attr('style', 'display:none')
        });

    }

    const changeImgProfile = e => {
      $("input[id='my_file']").click();
    }

    const handleDownloadAgreement_ = async() => {
      var FileSaver = require('file-saver');
      FileSaver.saveAs(BASE_URL + "/customer/pemodal/agreement/download", "dokumen_sample.png");
    }

    const handleDownloadAgreement = async() => {
      let config = {
        headers: {
          'Authorization': localStorage.token,
          'content-type': 'application/json',
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Methods': 'POST',
        },
        maxContentLength: 52428890,
        timeout: 10000000,
      }

      axios.post(BASE_URL + "/customer/pemodal/agreement/download", config)
        .then((resp) => {

            if (resp.data.success == false) {
                swal({ text: resp.data.body.message, icon: 'error' })
            } else {
                let link = resp.data.body.data
                // window.open(link)
                // console.log("LINK >> " + link)

                downloadFromResponseLink(link)
            }
        })
        .catch((error) => {
            try {
                let errorStatusCode = error.response.status
      
                if (errorStatusCode === 401) {
                  setTimeout(() => {
                    window.location.href = "/Signin"
                    localStorage.removeItem('token');
                    localStorage.clear();
      
                    this.props.dispatch({
                      type: "AUTH_USER_LOGOUT",
                    })
                  }, 500);
                } else {
                  let errorBody = error.response.data.body
                  swal({ text: errorBody.message, icon: 'error' })
                }
      
              } catch (error) {
                swal({ text: 'Error tidak terdefinisi!', icon: 'error' })
              }
        });
    }

    const downloadFromResponseLink = async(downloadLink) => {
      var url = downloadLink
      var FileSaver = require('file-saver');
      FileSaver.saveAs(url, "dokumen_sample.png");
    }

    const addDefaultSrc = (ev) => {
      ev.target.src = img_avatar;
      ev.target.onerror = null;
    }

    return (
      <Container className="sidebarUser">
        <Row className="justify-content-md-center">
          <Col xs lg="12">
            <Grid columns={2}>
              <Grid.Column>
                <Portal onClose={this.handleClosePortal} open={openPortal}>
                  <Segment
                    style={{
                      left: '40%',
                      position: 'fixed',
                      top: '15%',
                      zIndex: 1000,
                    }}
                  >
                    <Header><Icon name='check circle' color="green" /> Update Berhasil...</Header>
                    <p>Selamat Ubah Foto Profil Berhasil.</p>
                  </Segment>
                </Portal>
              </Grid.Column>
            </Grid>
            <Sidebar.Pushable className="mPushable" as={Segment}>

              {/* Sidebar */}
              <Sidebar
                as={Menu}
                animation={animation}
                direction={direction}
                icon='labeled'
                vertical
                visible={visible}
                width='thin'
                className='displayProfile'
              >
                <Menu.Item>
                  {/* {(verified > 0) ? */}
                  {(dataIsComplete == 5) ?
                    <>
                      <div className="grid-box" onMouseOver={this.mouseOver.bind(this)} onMouseOut={this.mouseOut.bind(this)}>
                        {this.state.hover ?
                          (
                            <>
                              <Image htmlFor="embedpollfileinput" 
                                src={change_img} 
                                onError={(e) => addDefaultSrc(e)}
                                className="imgProfilePicCg" size='small' verticalAlign="middle" circular
                                href="#" onClick={changeImgProfile} title="Ubah Foto Profil" />
                              <input type="file" id="my_file" onChange={(event) => { this.onFileChange(event, 111) }} style={{ display: 'none' }} />
                            </>
                          ) :
                          <Image 
                            src={img_profile} 
                            onError={(e) => addDefaultSrc(e)}
                            className="imgProfilePic" size='small' verticalAlign="middle" circular />
                        }
                      </div>
                    </>
                    :
                    <div className="grid-box">
                      <Image 
                        src={img_avatar} 
                        onError={(e) => addDefaultSrc(e)}
                        className="imgProfilePic" size='small' verticalAlign="middle" circular />
                    </div>
                  }
                  <h5 id="fonts" className="nama" style={{marginTop: 20}}>
                    {userinfo.nama_depan}
                    {userinfo.nama_tengah == null ? '' : userinfo.nama_tengah == '' ? '' : ' ' + userinfo.nama_tengah}
                    {userinfo.nama_belakang == null ? '' : userinfo.nama_belakang == '' ? '' : ' ' + userinfo.nama_belakang}
                  </h5>
                  <span id="fonts" className="namaID">ID : {userinfo.user_id}</span>
                  {/* <Header id="fonts" textAlign='left' as='h5' id="fonts" color="blue" >KODE REFERRAL</Header>
              
                  {document.queryCommandSupported('copy') &&
                  <Input id="fonts" size="mini" className="inputRef"
                      ref={(refcode) => this.refCode = refcode}
                      action={{
                      color: 'blue',
                      labelPosition: 'right',
                      content: 'COPY',
                      icon: 'copy',
                      size:'mini',
                      onClick: () => this.copyToClipboard()
                      }}
                      defaultValue={userinfo.refferal_code}
                      onChange={this.handleInputChange}
                  />
                  } */}

                  <div id="fonts" className="informasiPribadi">
                    <Header id="fonts" textAlign='left' as='h5' color="blue">INFORMASI PRIBADI</Header>
                    <Header id="fonts" as='h6' color="blue" size='small'>
                      <Icon name='phone' />
                      <Header.Content>
                        Handphone
                        <Header.Subheader>{userinfo.no_hp_kode + userinfo.no_hp}</Header.Subheader>
                      </Header.Content>
                    </Header>
                    <Header id="fonts" as='h6' color="blue" size='small'>
                      <Icon name='mail' />
                      <Header.Content>
                        Email
                        <Header.Subheader>{userinfo.email}</Header.Subheader>
                      </Header.Content>
                    </Header>
                    <Header id="fonts" as='h6' color="blue" size='small'>
                      <Icon name='map marker alternate' />
                      <Header.Content>
                        Alamat
                        <Header.Subheader>{userinfo.alamat}</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </div>

                  <Header id="fonts" textAlign='left' as='h5' color="blue">Status Akun :</Header>
                  {
                    dataIsComplete < 5 ? 
                      <>
                      {/* <Header id="fonts" as='h6' color="blue" size='small'>
                        <Header.Content>
                          <Edit userinfo={userinfo} lengkapiData={lengkapiData} stateOptions={stateOptions} />
                          <span className="uploadKet">(Wajib)</span>
                        </Header.Content>
                      </Header> */}
                      <Header id="fonts" as='h6' textAlign='left' color="blue" size='small' style={{ fontSize: '11px', marginTop: '0px' }}>
                        <Header.Content>
                          Kelengkapan Data <span className="privyVerified">(Belum dilengkapi)</span>
                        </Header.Content>
                      </Header>
                      </>
                    : verified_advance < 3 ?
                      <Header id="fonts" as='h6' textAlign='left' color="blue" size='small' style={{ fontSize: '11px', marginTop: '0px' }}>
                        <Header.Content>
                          Kelengkapan Data <span className="privyVerified" style={{color:'orange'}}>(Sedang diverifikasi)</span>
                        </Header.Content>
                      </Header>
                    : verified_advance == 3 ?
                      <Header id="fonts" as='h6' textAlign='left' color="blue" size='small' style={{ fontSize: '11px', marginTop: '0px' }}>
                        <Header.Content>
                          Kelengkapan Data <span className="privyVerified" style={{color:'green'}}>(Terverifikasi)</span>
                        </Header.Content>
                      </Header>
                    : verified_advance == 5 ?
                      <Header id="fonts" as='h6' textAlign='left' color="blue" size='small' style={{ fontSize: '11px', marginTop: '0px' }}>
                        <Header.Content>
                          Kelengkapan Data <span className="privyVerified" style={{color:'red'}}>(Ditolak)</span>
                        </Header.Content>
                      </Header>
                    : <></>
                  }

                  <Header id="fonts" as='h6' textAlign='left' color="blue" size='small' style={{ fontSize: '11px', marginTop: '0px' }}>
                    <Header.Content>
                      Digital Signature 
                      {
                          signature_status == null ? <span className="privyVerified"> (Belum terdaftar)</span> :
                          signature_status == '1' ? <span className="privyVerified" style={{color:'orange'}}> (Unverified)</span> :
                          signature_status == '2' ? <span className="privyVerified" style={{color:'green'}}> (Verified)</span> : 
                          signature_status == '3' ? <span className="privyVerified" style={{color:'red'}}> (Expired)</span> : 
                          <></>
                      }
                    </Header.Content>
                  </Header>

                </Menu.Item>
              </Sidebar>

              {/* Menu Center */}
              <Sidebar.Pusher id="fonts" className="mContentUtama" dimmed={dimmed && visible} style={{ backgroundColor: '#eeeeee' }}>
                <Segment basic style={{ backgroundColor: 'white' }}>
                  <Container className="profileMobile">
                    <Row>
                      <Col md={4}>
                        {/* {(verified > 0) ? */}
                        {(dataIsComplete == 5) ?
                          <>
                            <div className="grid-box" onMouseOver={this.mouseOver.bind(this)} onMouseOut={this.mouseOut.bind(this)} style={{ textAlign: 'center' }}>
                              {this.state.hover ?
                                (
                                  <>
                                    <Image htmlFor="embedpollfileinput" src={change_img} className="imgProfilePicCg" size='small' verticalAlign="middle" circular
                                      href="#" onClick={changeImgProfile} title="Ubah Foto Profil" />
                                    <input type="file" id="my_file" onChange={(event) => { this.onFileChange(event, 111) }} style={{ display: 'none' }} />
                                  </>
                                ) :
                                <Image src={img_profile} className="imgProfilePic" size='small' verticalAlign="middle" circular />
                              }
                            </div>
                          </>
                          :
                          <div className="grid-box" style={{ textAlign: 'center' }}>
                            <Image src={img_profile} className="imgProfilePic" size='small' verticalAlign="middle" circular />
                          </div>
                        }
                        <h4 id="fonts" className="nama" style={{ textAlign: 'center' }}>{userinfo.full_name}</h4>
                      </Col>
                      <Col md={4}>
                        <div id="fonts" className="informasiPribadi">
                          <span id="fonts" className="namaID">ID : {userinfo.user_id}</span>
                          <Header id="fonts" as='h6' color="blue" size='small'>
                            <Icon name='phone' />
                            <Header.Content>
                              Handphone 
                              <Header.Subheader>{userinfo.no_hp}</Header.Subheader>
                            </Header.Content>
                          </Header>
                          <Header id="fonts" as='h6' color="blue" size='small'>
                            <Icon name='mail' />
                            <Header.Content>
                              Email
                              <Header.Subheader>{userinfo.email}</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </div>
                      </Col>
                      <Col md={4} >
                        <div id="fonts" className="informasiPribadi">
                          {/* {verified == 0 ? */}
                          {dataIsComplete < 5 ?
                            (
                              <div><Header id="fonts" as='h6' color="blue" size='small'>
                                <Header.Content>
                                  <Edit userinfo={userinfo} lengkapiData={lengkapiData} stateOptions={stateOptions} />
                                  <span className="uploadKet">(Wajib)</span>
                                </Header.Content>
                              </Header>
                                <Button id="fonts" color='blue' size='mini' disabled className="mdaftarPemodal" style={{ marginTop: "30px" }}>Daftar Menjadi Penerbit </Button>
                              </div>
                            )
                            :
                            (
                              <div style={{ paddingTop: '0px' }}>
                                {verified_advance == 3 ?
                                  <>
                                    <Header id="fonts" as='h6' color="green" style={{ fontSize: '11px' }}>
                                      <Header.Content>
                                        Terverifikasi
                                        <Icon name="check" circular color='green' size='small' style={{ marginLeft: '85px' }}></Icon>
                                      </Header.Content>
                                    </Header>
                                    <Header id="fonts" as='h6' color="blue" size='small'>
                                      <Icon name='map marker alternate' />
                                      <Header.Content>
                                        Alamat
                                        <Header.Subheader>{userinfo.alamat}</Header.Subheader>
                                      </Header.Content>
                                    </Header>
                                    <Button id="fonts" color='blue' size='mini' disabled className="mdaftarPemodal" style={{ marginTop: "10px" }}>Daftar Menjadi Penerbit </Button>
                                  </>
                                  : verified_advance == 5 ?
                                    <>
                                      <Header id="fonts" as='h6' color="red" style={{ fontSize: '11px' }}>
                                        <Header.Content>
                                          Verifikasi Ditolak
                                          <Icon name="check" circular color='red' size='small' style={{ marginLeft: '60px' }}></Icon>
                                        </Header.Content>
                                      </Header>
                                      <Header id="fonts" as='h6' color="blue" size='small'>
                                        <Icon name='map marker alternate' />
                                        <Header.Content>
                                          Alamat
                                          <Header.Subheader>{userinfo.alamat}</Header.Subheader>
                                        </Header.Content>
                                      </Header>
                                      <Button id="fonts" color='blue' size='mini' className="mdaftarPemodal" disabled style={{ marginTop: "10px" }}>Daftar Menjadi Penerbit </Button>
                                    </>
                                    :
                                    <>
                                      <Header id="fonts" as='h6' color="yellow" style={{ fontSize: '11px' }}>
                                        <Header.Content>
                                          Proses Verifikasi
                                          <Icon name="check" circular color='yellow' size='small' style={{ marginLeft: '60px' }}></Icon>
                                        </Header.Content>
                                      </Header>
                                      <Header id="fonts" as='h6' color="blue" size='small'>
                                        <Icon name='map marker alternate' />
                                        <Header.Content>
                                          Alamat
                                          <Header.Subheader>{userinfo.alamat}</Header.Subheader>
                                        </Header.Content>
                                      </Header>
                                      <Button id="fonts" color='blue' size='mini' className="mdaftarPemodal" disabled style={{ marginTop: "10px" }}>Daftar Menjadi Penerbit </Button>
                                    </>
                                }
                              </div>
                            )
                          }
                        </div>
                      </Col>
                    </Row>
                  </Container>
                  <div className="paddingSpace profileMobile"></div>
                  <Container>
                    <Row>
                      <Col md={6} className="borderRightInvest">
                        <Header id="fonts" as='h2' color='blue'>
                          <Image src={saving_money} size='mini' />
                          <Header.Content>
                            <Header.Subheader>Total Investasi</Header.Subheader>
                            Rp. {totalInvest} ,-
                          </Header.Content>
                        </Header>
                      </Col>
                      <Col md={6}>
                        <Header id="fonts" as='h2' color='blue'>
                          <Image src={receiving_money} size='mini' />
                          <Header.Content>
                            <Header.Subheader>Dividen</Header.Subheader>
                            Rp. {totalShareProfit} ,-
                          </Header.Content>
                        </Header>
                      </Col>
                    </Row>
                  </Container>
                  <div className="paddingSpace"></div>
                  <Container >
                    <Row>
                      <Col md={12}>
                        <TabUserPemodal key="TabUserPemodalKey" 
                          userinfo={JSON.stringify(userinfo)} 
                          verified={verified} 
                          verified_advance={verified_advance} 
                          dataIsComplete={dataIsComplete} 
                          agreement={agreement}
                          />
                      </Col>
                    </Row>
                  </Container>
                </Segment>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </Col>
        </Row>
      </Container>
    )
  }
}

const Edit = memo((props) => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);

  const testShowHide = () => {
    if (show) {
      setShow(false)
      setShow2(true)
    } else {
      // setShow(true)
    }
  }

  return (
    <>
      <Button color='blue' size='mini' className="btnDataDiri" onClick={handleShow}>Upload Data Diri</Button>

      <Modal style={{ borderRadius: "30px" }} show={show} onHide={handleClose} animation={false}>
        <Modal.Title
          style={{ paddingTop: 30, paddingLeft: 30, paddingRight: 30, color: "#1F1F1F", fontSize: "18px", fontWeight: "500", fontFamily: "Helvetica Neue LT Std" }} >
          {/* <div className='text-center'>Apakah anda badan hukum; dan pihak yang mempunyai pengalaman berinvestasi di pasar modal?</div> */}
          <div className='text-center'>Apakah Anda Badan Hukum (Perusahaan) atau Perorangan?</div>
          <p />
        </Modal.Title>
        <div className="row text-center" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
          <div className="col-md-6">
            {/* Badan Usaha */}
            <Perusahaan userinfo={props.userinfo} stateOptions={props.stateOptions} lengkapiData={props.lengkapiData} />
          </div>
          <div className="col-md-6">
            {/* <Perusahaan userinfo={props.userinfo} stateOptions={props.stateOptions} lengkapiData={props.lengkapiData} /> */}
            {/* <BisnisOrExpertPerson userinfo={props.userinfo} stateOptions={props.stateOptions} lengkapiData={props.lengkapiData} /> */}
            <a href="#" id="fonts" onClick={testShowHide} className="btn my-4 btnPilihan" style={{ background: "#214981", color: "#ffffff", padding: "5px 50px", borderRadius: "20px" }}>
              Perorangan
            </a>
          </div>
        </div>
      </Modal>

      <Modal style={{ borderRadius: "30px" }} show={show2} onHide={handleClose2} animation={false}>
        <Modal.Title
          style={{ paddingTop: 30, paddingLeft: 30, paddingRight: 30, color: "#1F1F1F", fontSize: "18px", fontWeight: "500", fontFamily: "Helvetica Neue LT Std" }} >
          <div className='text-center'>Apakah anda berpengalaman dalam berinvestasi?</div>
          <p />
          {/* <div className='text-center'>(dibuktikan dengan kepemilikan rekening Efek paling sedikit 2 (dua) tahun)</div> */}
          <Alert id="fonts" className="alertInfo" variant="warning" show={true} style={{ fontSize: "14px", display: 'block' }}>
            <center>Yang dibuktikan dengan kepemilikan rekening Efek paling sedikit 2 (dua) tahun.</center>
          </Alert>
        </Modal.Title>
        <div className="row text-center" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
          <div className="col-md-6">
            {/* Pribadi dengan pengalaman pasar modal */}
            <PribadiExpert userinfo={props.userinfo} stateOptions={props.stateOptions} lengkapiData={props.lengkapiData} />
          </div>
          <div className="col-md-6">
            {/*Pribadi non pengalaman*/}
            <Pribadi userinfo={props.userinfo} stateOptions={props.stateOptions} lengkapiData={props.lengkapiData} />
          </div>
        </div>
      </Modal>
    </>
  );
})

function Pribadi(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [form, setForm] = useState({
    type: 'pribadi',
    nik_ktp: '',
    tempat_lahir: '',
    nama: props.userinfo.full_name,
    jenis_kelamin: '',
    alamat: '',
    foto_ktp: '',
    selfie_ktp: '',
    npwp: '',
    no_rek: '',
    cabang_bank: '',
    data_bank: '',
    buku_tabungan: '',
    atas_nama_bank: '',
    cabang_bank: '',
    no_hp: props.userinfo.no_hp,
    penghasilan: 0,
  });

  // const handleTextChange = event => setForm({ ...form, [event.target.name]: event.target.value })
  const handleTextChange = event => {
    setForm({ ...form, [event.target.name]: event.target.value })

    var index = event.target.name
    setErrorMessage(errorMessage => ({ ...errorMessage, [index]: "" }));
  }

  const [errorMessage, setErrorMessage] = useState({});

  // fields['type'] = "pribadi";

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  async function handleInputSubmit() {

    let errors = {};
    let isValid = true;

    if (form.nik_ktp == '') {
      errors['nik_ktp'] = "NIK tidak boleh kosong!"
      isValid = false
    }
    if (form.tempat_lahir == '') {
      errors['tempat_lahir'] = "Tempat lahir tidak boleh kosong!"
      isValid = false
    }
    if (form.nama == '') {
      errors['nama'] = "Nama tidak boleh kosong!"
      isValid = false
    }
    if (form.jenis_kelamin == '') {
      errors['jenis_kelamin'] = "Jenis kelamin tidak boleh kosong!"
      isValid = false
    }
    if (form.alamat == '') {
      errors['alamat'] = "Alamat tidak boleh kosong!"
      isValid = false
    }
    if (form.penghasilan == '' || form.penghasilan == 0) {
      errors['penghasilan'] = "Penghasilan tidak boleh kosong!"
      isValid = false
    }

    if (form.no_rek == '') {
      errors['no_rek'] = "No. Rekening tidak boleh kosong!"
      isValid = false
    }
    if (form.cabang_bank == '') {
      errors['cabang_bank'] = "Cabang Bank tidak boleh kosong!"
      isValid = false
    }
    if (form.data_bank == '') {
      errors['data_bank'] = "Bank tidak boleh kosong!"
      isValid = false
    }
    if (form.atas_nama_bank == '') {
      errors['atas_nama_bank'] = "Nama pemilik rekening tidak boleh kosong!"
      isValid = false
    }

    var formField = document.forms[1]

    var fotoKtp = formField.querySelector('input[name="foto_ktp"]').files[0]
    var fotoSelfie = formField.querySelector('input[name="selfie_ktp"]').files[0]
    var fotoNPWP = formField.querySelector('input[name="npwp"]').files[0]
    var bukuTabungan = formField.querySelector('input[name="buku_tabungan"]').files[0]

    if (fotoKtp) {
      var sizefotoKtp = formField.querySelector('input[name="foto_ktp"]').files[0].size
      var typefotoKtp = formField.querySelector('input[name="foto_ktp"]').files[0].type

      if (typefotoKtp != 'image/jpeg' && typefotoKtp != 'image/png' && typefotoKtp != 'image/jpeg' || sizefotoKtp > 2048000) {
        // alert('Foto KTP harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB')
        errors['foto_ktp'] = "Foto KTP harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB!"
        isValid = false
      }
    } else {
      // alert("Harap isi semua data dan lampirankan dokumen");
      errors['foto_ktp'] = "Lampirkan Foto KTP!"
      isValid = false
    }

    if (fotoSelfie) {
      var sizeselfie_ktp = formField.querySelector('input[name="selfie_ktp"]').files[0].size
      var typeselfie_ktp = formField.querySelector('input[name="selfie_ktp"]').files[0].type

      if (typeselfie_ktp != 'image/jpeg' && typeselfie_ktp != 'image/png' && typeselfie_ktp != 'image/jpeg' || sizeselfie_ktp > 2048000) {
        // alert('Foto Selfie harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB')
        errors['selfie_ktp'] = "Foto Selfie KTP harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB!"
        isValid = false
      }
    } else {
      // alert("Harap isi semua data dan lampirankan dokumen");
      errors['selfie_ktp'] = "Lampirkan Foto anda dengan KTP!"
      isValid = false
    }

    if (fotoNPWP) {
      var sizefotoNPWP = formField.querySelector('input[name="npwp"]').files[0].size
      var typefotoNPWP = formField.querySelector('input[name="npwp"]').files[0].type

      if (typefotoNPWP != 'image/jpeg' && typefotoNPWP != 'image/png' && typefotoNPWP != 'image/jpeg' || sizefotoNPWP > 2048000) {
        // alert('Foto NPWP harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB')
        errors['npwp'] = "Foto NPWP harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB!"
        isValid = false
      }
    } else {
      // alert("Harap isi semua data dan lampirkan dokumen");
      errors['npwp'] = "Lampirkan Foto NPWP!"
      isValid = false
    }

    if (bukuTabungan) {
      var sizebukuTabungan = formField.querySelector('input[name="buku_tabungan"]').files[0].size
      var typebukuTabungan = formField.querySelector('input[name="buku_tabungan"]').files[0].type

      if (typebukuTabungan != 'image/jpeg' && typebukuTabungan != 'image/png' && typebukuTabungan != 'image/jpeg' || sizebukuTabungan > 2048000) {
        // alert('Foto Buku Tabungan harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB')
        errors['buku_tabungan'] = "Foto Buku Tabungan harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB!"
        isValid = false
      }
    } else {
      // alert("Harap isi semua data dan lampirankan dokumen");
      errors['buku_tabungan'] = "Lampirkan Buku Tabungan!"
      isValid = false
    }

    setErrorMessage(errorMessage => ({
      ...errorMessage,
      ...errors
    }));

    if (isValid) {
      form.foto_ktp = await toBase64(fotoKtp)
      form.selfie_ktp = await toBase64(fotoSelfie)
      form.npwp = await toBase64(fotoNPWP)
      form.buku_tabungan = await toBase64(bukuTabungan)

      props.lengkapiData(form)
    }
    // console.log('berhasil',form)
  }

  return (
    <>
      <a href="#" id="fonts" onClick={handleShow} className="btn my-4 btnPilihan" style={{ background: "#214981", color: "#ffffff", padding: "5px 50px", borderRadius: "20px" }}>
        {/* Pribadi */}
        Tidak
      </a>

      <Modal size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        show={show} onHide={handleClose} animation={false}>
        <Form id="fonts" name="FormPribadi" style={{ padding: "20px" }} onSubmit={e => e.preventDefault()} encType="multipart/form-data"  >
          <Alert id="fonts" className="alertSuccessSubmit" variant="success" show={true} style={{ display: 'none' }}>
            <center>Terimakasih, data anda sedang kami proses...</center>
          </Alert>
          <Alert id="fonts" className="alertInfo" variant="warning" show={true} style={{ display: 'block' }}>
            {/* <center>Terimakasih, data anda sedang kami proses...</center> */}
            <p><strong>CATATAN!</strong></p>
            <p>Setiap Pemodal dengan penghasilan sampai dengan
              Rp500.000.000,00 (lima ratus juta rupiah) per
              tahun, dapat membeli Efek melalui Layanan Urun
              Dana paling banyak sebesar 5% (lima persen) dari
              penghasilan per tahun; dan</p>
            <p>Setiap Pemodal dengan penghasilan lebih dari
              Rp500.000.000,00 (lima ratus juta rupiah) per
              tahun, dapat membeli Efek melalui Layanan Urun
              Dana paling banyak sebesar 10% (sepuluh persen)
              dari penghasilan per tahun.</p>
          </Alert>
          <h5 id="fonts" >Data Sesuai Ktp</h5>
          {/* {JSON.stringify(form)} */}
          <Form.Group as={Row} controlId="ktp">
            <Col sm="6">
              <Form.Control type="text" name="nik_ktp" defaultValue={form.nik_ktp} onChange={handleTextChange} placeholder="NIK KTP" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["nik_ktp"]}</span>
            </Col>
            <Col sm="6">
              <Form.Control type="text" name="tempat_lahir" defaultValue={form.tempat_lahir} onChange={handleTextChange} placeholder="Tempat Lahir" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["tempat_lahir"]}</span>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="nama">
            <Col sm="6">
              <Form.Control type="text" name="nama" defaultValue={form.nama} onChange={handleTextChange} placeholder="Nama" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["nama"]}</span>
            </Col>
            <Col sm="6">
              <select className="custom-select" name="jenis_kelamin" defaultValue={form.jenis_kelamin} onChange={handleTextChange}>
                <option>--Pilih Jenis Kelamin--</option>
                <option>Laki-Laki</option>
                <option>Perempuan</option>
              </select>
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["jenis_kelamin"]}</span>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="alamat">
            <Col sm="12">
              <Form.Control type="text" name="alamat" defaultValue={form.alamat} onChange={handleTextChange} placeholder="Alamat" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["alamat"]}</span>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="penghasilan">
            <Col sm="12">
              {/* <Form.Control type="number" name="penghasilan" defaultValue={form.penghasilan} onChange={handleTextChange} placeholder="Penghasilan" /> */}
              <CurrencyFormat className='form-control'
                name="penghasilan" defaultValue={form.penghasilan} onChange={handleTextChange} placeholder="Penghasilan Pertahun"
                thousandSeparator={'.'} decimalSeparator={','} prefix={''} maxLength={19} autocomplete="off" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["penghasilan"]}</span>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="foto_ktp">
            <Col sm="6">
              <Form.Label>
                Foto Ktp
              </Form.Label>
              <Form.Control
                type="file"
                name="foto_ktp"
                // defaultValue={form.foto_ktp} 
                onChange={handleTextChange}
                placeholder="Foto Ktp" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["foto_ktp"]}</span>
            </Col>
            <Col sm="6">
              <Form.Label>
                Selfie dengan Ktp
              </Form.Label>
              <Form.Control type="file" name="selfie_ktp"
                // defaultValue={form.selfie_ktp} 
                onChange={handleTextChange} placeholder="Selfie Pegang Ktp" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["selfie_ktp"]}</span>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="npwp">
            <Col sm="6">
              <Form.Label>
                NPWP
              </Form.Label>
              <Form.Control type="file" name="npwp"
                // defaultValue={form.npwp} 
                onChange={handleTextChange} placeholder="NPWP" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["npwp"]}</span>
            </Col>
            <Col sm="6">
              <Form.Label>
                Buku Tabungan
              </Form.Label>
              <Form.Control type="file" name="buku_tabungan"
                // defaultValue={form.buku_tabungan} 
                onChange={handleTextChange} placeholder="Buku Tabungan" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["buku_tabungan"]}</span>
            </Col>
          </Form.Group>

          <h5 id="fonts" >Data Bank</h5>
          <Form.Group as={Row} controlId="norek">
            <Col sm="6">
              <Form.Control type="text" name="no_rek" defaultValue={form.no_rek} onChange={handleTextChange} placeholder="No Rekening" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["no_rek"]}</span>
            </Col>
            <Col sm="6">
              <Form.Control type="text" name="cabang_bank" defaultValue={form.cabang_bank} onChange={handleTextChange} placeholder="Cabang Bank" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["cabang_bank"]}</span>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="data_bank">
            <Col sm="6">
              <Dropdown
                onChange={(e, { value }) => {
                  form.data_bank = value
                  setErrorMessage(errorMessage => ({ ...errorMessage, ['data_bank']: "" }))
                }}
                name="data_bank"
                placeholder='--Pilih Bank--' style={{ width: '100%' }}
                closeOnChange search selection options={props.stateOptions} />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["data_bank"]}</span>
            </Col>
            <Col sm="6">
              <Form.Control type="text" name="atas_nama_bank" defaultValue={form.atas_nama_bank} onChange={handleTextChange} placeholder="Atas Nama" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["atas_nama_bank"]}</span>
            </Col>
          </Form.Group>
          <Modal.Footer>
            <Button className="btnSimpan" id="fonts" style={{ marginRight: "auto", marginLeft: "auto", padding: "5px 50px", borderRadius: "20px" }} type="button" onClick={handleInputSubmit} color="blue">
              Simpan
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

function PribadiExpert(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [form, setForm] = useState({
    type: 'pribadiexpert',
    nik_ktp: '',
    tempat_lahir: '',
    nama: props.userinfo.full_name,
    jenis_kelamin: '',
    alamat: '',
    foto_ktp: '',
    selfie_ktp: '',
    npwp: '',
    no_rek: '',
    cabang_bank: '',
    data_bank: '',
    buku_tabungan: '',
    atas_nama_bank: '',
    cabang_bank: '',
    no_hp: props.userinfo.no_hp,
    sid: ''
  });

  const [value, setValue] = useState('');
  const [errorMessage, setErrorMessage] = useState({});
  
  const handleTextChange = event => {
    setForm({ ...form, [event.target.name]: event.target.value })

    var index = event.target.name
    setErrorMessage(errorMessage => ({ ...errorMessage, [index]: "" }));

    if(index == 'sid'){
      var textValue = event.target.value;

      const ca = /^[a-zA-Z]*$/;
      const re = /^[0-9\b]+$/;
      var subStr1 = textValue.slice(0, 3)
      var subStr2 = textValue.slice(3, 13)

      if(textValue.length <= 3){
        if(subStr1 === '' || ca.test(subStr1)){
          setValue(event.target.value.toUpperCase())
        } 
      } else {
        if(subStr2 === '' || re.test(subStr2)){
          setValue(subStr1 + subStr2)
        }
      }
    }
  }

  // fields['type'] = "pribadi";

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  async function handleInputSubmit() {

    let errors = {};
    let isValid = true;

    if (form.nik_ktp == '') {
      errors['nik_ktp'] = "NIK tidak boleh kosong!"
      isValid = false
    }
    if (form.tempat_lahir == '') {
      errors['tempat_lahir'] = "Tempat lahir tidak boleh kosong!"
      isValid = false
    }
    if (form.nama == '') {
      errors['nama'] = "Nama tidak boleh kosong!"
      isValid = false
    }
    if (form.jenis_kelamin == '') {
      errors['jenis_kelamin'] = "Jenis kelamin tidak boleh kosong!"
      isValid = false
    }
    if (form.alamat == '') {
      errors['alamat'] = "Alamat tidak boleh kosong!"
      isValid = false
    }

    if (form.no_rek == '') {
      errors['no_rek'] = "No. Rekening tidak boleh kosong!"
      isValid = false
    }
    if (form.cabang_bank == '') {
      errors['cabang_bank'] = "Cabang Bank tidak boleh kosong!"
      isValid = false
    }
    if (form.data_bank == '') {
      errors['data_bank'] = "Bank tidak boleh kosong!"
      isValid = false
    }
    if (form.atas_nama_bank == '') {
      errors['atas_nama_bank'] = "Nama pemilik rekening tidak boleh kosong!"
      isValid = false
    }
    if (form.sid == '') {
      errors['sid'] = "SID tidak boleh!"
      isValid = false
    } else {
      if (form.sid.length < 13) {
        errors['sid'] = "Masukkan 3 karakter dan 10 digit No. SID!"
        isValid = false
      }
    }

    var formField = document.forms[1]

    var fotoKtp = formField.querySelector('input[name="foto_ktp"]').files[0]
    var fotoSelfie = formField.querySelector('input[name="selfie_ktp"]').files[0]
    var fotoNPWP = formField.querySelector('input[name="npwp"]').files[0]
    var bukuTabungan = formField.querySelector('input[name="buku_tabungan"]').files[0]

    if (fotoKtp) {
      var sizefotoKtp = formField.querySelector('input[name="foto_ktp"]').files[0].size
      var typefotoKtp = formField.querySelector('input[name="foto_ktp"]').files[0].type

      if (typefotoKtp != 'image/jpeg' && typefotoKtp != 'image/png' && typefotoKtp != 'image/jpeg' || sizefotoKtp > 2048000) {
        // alert('Foto KTP harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB')
        errors['foto_ktp'] = "Foto KTP harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB!"
        isValid = false
      }
    } else {
      // alert("Harap isi semua data dan lampirankan dokumen");
      errors['foto_ktp'] = "Lampirkan Foto KTP!"
      isValid = false
    }

    if (fotoSelfie) {
      var sizeselfie_ktp = formField.querySelector('input[name="selfie_ktp"]').files[0].size
      var typeselfie_ktp = formField.querySelector('input[name="selfie_ktp"]').files[0].type

      if (typeselfie_ktp != 'image/jpeg' && typeselfie_ktp != 'image/png' && typeselfie_ktp != 'image/jpeg' || sizeselfie_ktp > 2048000) {
        // alert('Foto Selfie harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB')
        errors['selfie_ktp'] = "Foto Selfie harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB!"
        isValid = false
      }
    } else {
      // alert("Harap isi semua data dan lampirankan dokumen");
      errors['selfie_ktp'] = "Lampirkan Foto anda dengan KTP!"
      isValid = false
    }

    if (fotoNPWP) {
      var sizefotoNPWP = formField.querySelector('input[name="npwp"]').files[0].size
      var typefotoNPWP = formField.querySelector('input[name="npwp"]').files[0].type

      if (typefotoNPWP != 'image/jpeg' && typefotoNPWP != 'image/png' && typefotoNPWP != 'image/jpeg' || sizefotoNPWP > 2048000) {
        // alert('Foto NPWP harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB')
        errors['npwp'] = "Foto NPWP harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB!"
        isValid = false
      }
    } else {
      // alert("Harap isi semua data dan lampirankan dokumen");
      errors['npwp'] = "Lampirkan Foto NPWP!"
      isValid = false
    }

    if (bukuTabungan) {
      var sizebukuTabungan = formField.querySelector('input[name="buku_tabungan"]').files[0].size
      var typebukuTabungan = formField.querySelector('input[name="buku_tabungan"]').files[0].type

      if (typebukuTabungan != 'image/jpeg' && typebukuTabungan != 'image/png' && typebukuTabungan != 'image/jpeg' || sizebukuTabungan > 2048000) {
        // alert('Foto Buku Tabungan harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB')
        errors['buku_tabungan'] = "Foto Buku Tabungan harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB!"
        isValid = false
      }
    } else {
      // alert("Harap isi semua data dan lampirankan dokumen");
      errors['buku_tabungan'] = "Lampirkan Buku Tabungan!"
      isValid = false
    }

    setErrorMessage(errorMessage => ({
      ...errorMessage,
      ...errors
    }));

    if (isValid) {
      form.foto_ktp = await toBase64(fotoKtp)
      form.selfie_ktp = await toBase64(fotoSelfie)
      form.npwp = await toBase64(fotoNPWP)
      form.buku_tabungan = await toBase64(bukuTabungan)

      props.lengkapiData(form)
    }
    // console.log('berhasil',form)
  }

  return (
    <>
      <a href="#" id="fonts" onClick={handleShow} className="btn my-4 btnPilihan" style={{ background: "#214981", color: "#ffffff", padding: "5px 50px", borderRadius: "20px" }}>
        {/* Pribadi */}
        Ya
      </a>

      <Modal size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        show={show} onHide={handleClose} animation={false}>
        <Form id="fonts" name="FormPribadi" style={{ padding: "20px" }} onSubmit={e => e.preventDefault()} encType="multipart/form-data"  >
          <Alert id="fonts" className="alertSuccessSubmit" variant="info" show={true} style={{ display: 'none' }}>
            <center>Terimakasih, data anda sedang kami proses...</center>
          </Alert>
          <h5 id="fonts" >Data Sesuai Ktp</h5>
          {/* {JSON.stringify(form)} */}
          <Form.Group as={Row} controlId="ktp">

            <Col sm="6">
              <Form.Control type="text" name="nik_ktp" defaultValue={form.nik_ktp} onChange={handleTextChange} placeholder="NIK KTP" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["nik_ktp"]}</span>
            </Col>
            <Col sm="6">
              <Form.Control type="text" name="tempat_lahir" defaultValue={form.tempat_lahir} onChange={handleTextChange} placeholder="Tempat Lahir" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["tempat_lahir"]}</span>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="nama">
            <Col sm="6">
              <Form.Control type="text" name="nama" defaultValue={form.nama} onChange={handleTextChange} placeholder="Nama" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["nama"]}</span>
            </Col>
            <Col sm="6">
              <select className="custom-select" name="jenis_kelamin" defaultValue={form.jenis_kelamin} onChange={handleTextChange}>
                <option>--Pilih Jenis Kelamin--</option>
                <option>Laki-Laki</option>
                <option>Perempuan</option>
              </select>
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["jenis_kelamin"]}</span>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="alamat">
            <Col sm="12">
              <Form.Control type="text" name="alamat" defaultValue={form.alamat} onChange={handleTextChange} placeholder="Alamat" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["alamat"]}</span>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="foto_ktp">
            <Col sm="6">
              <Form.Label>
                Foto Ktp
              </Form.Label>
              <Form.Control type="file" name="foto_ktp"
                // defaultValue={form.foto_ktp} 
                onChange={handleTextChange} placeholder="Foto Ktp" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["foto_ktp"]}</span>
            </Col>
            <Col sm="6">
              <Form.Label>
                Selfie dengan Ktp
              </Form.Label>
              <Form.Control type="file" name="selfie_ktp"
                // defaultValue={form.selfie_ktp} 
                onChange={handleTextChange} placeholder="Selfie Pegang Ktp" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["selfie_ktp"]}</span>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="npwp">
            <Col sm="6">
              <Form.Label>
                NPWP
              </Form.Label>
              <Form.Control type="file" name="npwp"
                // defaultValue={form.npwp} 
                onChange={handleTextChange} placeholder="NPWP" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["npwp"]}</span>
            </Col>
            <Col sm="6">
              <Form.Label>
                Buku Tabungan
              </Form.Label>
              <Form.Control type="file" name="buku_tabungan"
                // defaultValue={form.buku_tabungan} 
                onChange={handleTextChange} placeholder="Buku Tabungan" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["buku_tabungan"]}</span>
            </Col>
          </Form.Group>

          <h5 id="fonts" >Data Bank</h5>
          <Form.Group as={Row} controlId="norek">
            <Col sm="6">
              <Form.Control type="text" name="no_rek" defaultValue={form.no_rek} onChange={handleTextChange} placeholder="No Rekening" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["no_rek"]}</span>
            </Col>
            <Col sm="6">
              <Form.Control type="text" name="cabang_bank" defaultValue={form.cabang_bank} onChange={handleTextChange} placeholder="Cabang Bank" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["cabang_bank"]}</span>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="data_bank">
            <Col sm="6">
              <Dropdown
                onChange={(e, { value }) => {
                  form.data_bank = value
                  setErrorMessage(errorMessage => ({ ...errorMessage, ['data_bank']: "" }))
                }}
                name="data_bank"
                placeholder='--Pilih Bank--' style={{ width: '100%' }}
                closeOnChange search selection options={props.stateOptions} />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["data_bank"]}</span>
            </Col>
            <Col sm="6">
              <Form.Control type="text" name="atas_nama_bank" defaultValue={form.atas_nama_bank} onChange={handleTextChange} placeholder="Atas Nama" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["atas_nama_bank"]}</span>
            </Col>
          </Form.Group>

          <h5 id="fonts" >SID (Single Investor Identification)</h5>
          <Form.Group as={Row} controlId="sid">
            <Col sm="12">
              <Form.Control type="text" name="sid" defaultValue={form.sid} onChange={handleTextChange} 
              placeholder="Contoh: MFD1225999999" value={value}
              maxLength={13}
              autoComplete="off"/>
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["sid"]}</span>
            </Col>
          </Form.Group>

          <Modal.Footer>
            <Button className="btnSimpan" id="fonts" style={{ marginRight: "auto", marginLeft: "auto", padding: "5px 50px", borderRadius: "20px" }} type="button" onClick={handleInputSubmit} color="blue">
              Simpan
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

function Perusahaan(props) {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [form, setForm] = useState({
    type: 'perusahaan',
    nik_ktp: '',
    tempat_lahir: '',
    full_name: props.userinfo.full_name,
    jenis_kelamin: '',
    alamat: '',
    foto_ktp: '',
    selfie_ktp: '',
    akta: '',
    nib: '',
    sk_kemenkumham: '',
    no_rek: '',
    cabang_bank: '',
    npwp_perusahaan: '',
    ktp_pengurus: '',
    buku_tabungan: '',
    data_bank: '',
    atas_nama_bank: ''
  });

  const handleTextChange = event => {
    setForm({ ...form, [event.target.name]: event.target.value })

    var index = event.target.name
    setErrorMessage(errorMessage => ({ ...errorMessage, [index]: "" }));
  }

  const [errorMessage, setErrorMessage] = useState({});

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  async function handleInputSubmit() {

    let errors = {};
    let isValid = true;

    if (form.nik_ktp == '') {
      errors['nik_ktp'] = "NIK tidak boleh kosong!"
      isValid = false
    }
    if (form.tempat_lahir == '') {
      errors['tempat_lahir'] = "Tempat lahir tidak boleh kosong!"
      isValid = false
    }
    if (form.nama == '') {
      errors['full_name'] = "Nama tidak boleh kosong!"
      isValid = false
    }
    if (form.jenis_kelamin == '') {
      errors['jenis_kelamin'] = "Jenis kelamin tidak boleh kosong!"
      isValid = false
    }
    if (form.alamat == '') {
      errors['alamat'] = "Alamat tidak boleh kosong!"
      isValid = false
    }

    if (form.no_rek == '') {
      errors['no_rek'] = "No. Rekening tidak boleh kosong!"
      isValid = false
    }
    if (form.cabang_bank == '') {
      errors['cabang_bank'] = "Cabang Bank tidak boleh kosong!"
      isValid = false
    }
    if (form.data_bank == '') {
      errors['data_bank'] = "Bank tidak boleh kosong!"
      isValid = false
    }
    if (form.atas_nama_bank == '') {
      errors['atas_nama_bank'] = "Nama pemilik rekening tidak boleh kosong!"
      isValid = false
    }

    var formField = document.forms[1]
    var fotoKtp = formField.querySelector('input[name="foto_ktp"]').files[0]
    var fotoSelfie = formField.querySelector('input[name="selfie_ktp"]').files[0]
    var fotoNPWPPerus = formField.querySelector('input[name="npwp_perusahaan"]').files[0]
    var bukuTabungan = formField.querySelector('input[name="buku_tabungan"]').files[0]
    var fotoAkta = formField.querySelector('input[name="akta"]').files[0]
    var fotoNib = formField.querySelector('input[name="nib"]').files[0]
    var fotoSkmenkumham = formField.querySelector('input[name="sk_kemenkumham"]').files[0]
    var fotoktp_pengurus = formField.querySelector('input[name="ktp_pengurus"]').files[0]

    if (fotoKtp) {
      var sizefotoKtp = formField.querySelector('input[name="foto_ktp"]').files[0].size
      var typefotoKtp = formField.querySelector('input[name="foto_ktp"]').files[0].type

      if (typefotoKtp != 'image/jpeg' && typefotoKtp != 'image/png' && typefotoKtp != 'image/jpeg' || sizefotoKtp > 2048000) {
        // alert('Foto KTP harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB')
        errors['foto_ktp'] = "Foto KTP harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB!"
        isValid = false
      }
    } else {
      // alert("Harap isi semua data dan lampirankan dokumen");
      errors['foto_ktp'] = "Lampirkan Foto KTP!"
      isValid = false
    }

    if (fotoSelfie) {
      var sizeselfie_ktp = formField.querySelector('input[name="selfie_ktp"]').files[0].size
      var typeselfie_ktp = formField.querySelector('input[name="selfie_ktp"]').files[0].type

      if (typeselfie_ktp != 'image/jpeg' && typeselfie_ktp != 'image/png' && typeselfie_ktp != 'image/jpeg' || sizeselfie_ktp > 2048000) {
        // alert('Foto Selfie harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB')
        errors['selfie_ktp'] = "Foto Selfie harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB!"
        isValid = false
      }
    } else {
      // alert("Harap isi semua data dan lampirankan dokumen");
      errors['selfie_ktp'] = "Lampirkan Foto anda dengan KTP!"
      isValid = false
    }

    if (fotoNPWPPerus) {
      var sizefotoNPWPPerus = formField.querySelector('input[name="npwp_perusahaan"]').files[0].size
      var typefotoNPWPPerus = formField.querySelector('input[name="npwp_perusahaan"]').files[0].type

      if (typefotoNPWPPerus != 'image/jpeg' && typefotoNPWPPerus != 'image/png' && typefotoNPWPPerus != 'image/jpeg' || sizefotoNPWPPerus > 2048000) {
        // alert('Foto NPWP Perusahaan harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB')
        // return false;
        errors['npwp_perusahaan'] = "Foto NPWP harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB!"
        isValid = false
      }
    } else {
      errors['npwp_perusahaan'] = "Lampirkan Foto NPWP!"
      isValid = false
    }

    if (bukuTabungan) {
      var sizebukuTabungan = formField.querySelector('input[name="buku_tabungan"]').files[0].size
      var typebukuTabungan = formField.querySelector('input[name="buku_tabungan"]').files[0].type

      if (typebukuTabungan != 'image/jpeg' && typebukuTabungan != 'image/png' && typebukuTabungan != 'image/jpeg' || sizebukuTabungan > 2048000) {
        errors['buku_tabungan'] = "Foto Buku Tabungan harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB!"
        isValid = false
      }
    } else {
      errors['buku_tabungan'] = "Lampirkan Buku Tabungan!"
      isValid = false
    }

    if (fotoAkta) {
      var sizefotoAkta = formField.querySelector('input[name="akta"]').files[0].size
      var typefotoAkta = formField.querySelector('input[name="akta"]').files[0].type

      if (typefotoAkta != 'image/jpeg' && typefotoAkta != 'image/png' && typefotoAkta != 'image/jpeg' || sizefotoAkta > 2048000) {
        // alert('Foto Akta harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB')
        errors['akta'] = "Foto AKTA Perusahaan harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB!"
        isValid = false
      }
    } else {
      errors['akta'] = "Lampirkan AKTA Perusahaan!"
      isValid = false
    }

    if (fotoNib) {
      var sizefotoNib = formField.querySelector('input[name="nib"]').files[0].size
      var typefotoNib = formField.querySelector('input[name="nib"]').files[0].type

      if (typefotoNib != 'image/jpeg' && typefotoNib != 'image/png' && typefotoNib != 'image/jpeg' || sizefotoNib > 2048000) {
        // alert('Foto NIB harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB')
        errors['nib'] = "Foto NIB harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB!"
        isValid = false
      }
    } else {
      errors['nib'] = "Lampirkan NIB Perusahaan!"
      isValid = false
    }

    if (fotoSkmenkumham) {
      var sizefotoSkmenkumham = formField.querySelector('input[name="sk_kemenkumham"]').files[0].size
      var typefotoSkmenkumham = formField.querySelector('input[name="sk_kemenkumham"]').files[0].type

      if (typefotoSkmenkumham != 'image/jpeg' && typefotoSkmenkumham != 'image/png' && typefotoSkmenkumham != 'image/jpeg' || sizefotoSkmenkumham > 2048000) {
        // alert('Foto SK Kemenkumham harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB')
        errors['sk_kemenkumham'] = "Foto SK Kemenkumham harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB!"
        isValid = false
      }
    } else {
      errors['sk_kemenkumham'] = "Lampirkan SK Kemenkumham!"
      isValid = false
    }

    if (fotoktp_pengurus) {
      var sizefotoktp_pengurus = formField.querySelector('input[name="ktp_pengurus"]').files[0].size
      var typefotoktp_pengurus = formField.querySelector('input[name="ktp_pengurus"]').files[0].type

      if (typefotoktp_pengurus != 'image/jpeg' && typefotoktp_pengurus != 'image/png' && typefotoktp_pengurus != 'image/jpeg' || sizefotoktp_pengurus > 2048000) {
        // alert('Foto KTP Pengurus harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB')
        errors['ktp_pengurus'] = "Foto KTP Pengurus harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB!"
        isValid = false
      }
    } else {
      errors['ktp_pengurus'] = "Lampirkan KTP Pengurus!"
      isValid = false
    }

    setErrorMessage(errorMessage => ({
      ...errorMessage,
      ...errors
    }));

    if (isValid) {
      if (fotoKtp) {
        form.foto_ktp = await toBase64(fotoKtp)
      }
      if (fotoSelfie) {
        form.selfie_ktp = await toBase64(fotoSelfie)
      }
      if (fotoNPWPPerus) {
        form.npwp_perusahaan = await toBase64(fotoNPWPPerus)
      }
      if (bukuTabungan) {
        form.buku_tabungan = await toBase64(bukuTabungan)
      }
      if (fotoAkta) {
        form.akta = await toBase64(fotoAkta)
      }
      if (fotoNib) {
        form.nib = await toBase64(fotoNib)
      }
      if (fotoSkmenkumham) {
        form.sk_kemenkumham = await toBase64(fotoSkmenkumham)
      }
      if (fotoktp_pengurus) {
        form.ktp_pengurus = await toBase64(fotoktp_pengurus)
      }

      props.lengkapiData(form)
    }
  }

  return (
    <>
      <a href="#" id="fonts" onClick={handleShow} className="btn my-4 btnPilihan" style={{ background: "#214981", color: "#ffffff", padding: "5px 50px", borderRadius: "20px" }}>
        Badan Hukum
      </a>

      <Modal size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        show={show} onHide={handleClose} animation={false}>
        <Form id="fonts" name="FormPerusahaan" style={{ padding: "20px" }} onSubmit={e => e.preventDefault()} encType="multipart/form-data" >
          <Alert id="fonts" className="alertSuccessSubmit" variant="info" show={true} style={{ display: 'none' }}>
            <center>Terimakasih, data anda sedang kami proses...</center>
          </Alert>
          <h5 id="fonts" >Data Penanggung Jawab</h5>
          <Form.Group as={Row} controlId="ktp">
            <Col sm="6">
              <Form.Control type="text" name="nik_ktp" defaultValue={form.nik_ktp} onChange={handleTextChange} placeholder="NIK KTP" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["nik_ktp"]}</span>
            </Col>
            <Col sm="6">
              <Form.Control type="text" name="tempat_lahir" defaultValue={form.tempat_lahir} onChange={handleTextChange} placeholder="Tempat Tanggal Lahir" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["tempat_lahir"]}</span>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="nama">
            <Col sm="6">
              <Form.Control type="text" name="full_name" defaultValue={form.full_name} onChange={handleTextChange} placeholder="Nama" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["full_name"]}</span>
            </Col>
            <Col sm="6">
              <select className="custom-select" name="jenis_kelamin" defaultValue={form.jenis_kelamin} onChange={handleTextChange}>
                <option>--Pilih Jenis Kelamin--</option>
                <option>Laki-Laki</option>
                <option>Perempuan</option>
              </select>
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["jenis_kelamin"]}</span>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="alamat">
            <Col sm="12">
              <Form.Control type="text" name="alamat" defaultValue={form.alamat} onChange={handleTextChange} placeholder="Alamat" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["alamat"]}</span>
            </Col>
          </Form.Group>

          <h5>Data Bank</h5>
          <Form.Group as={Row} controlId="norek">
            <Col sm="6">
              <Form.Control type="text" name="no_rek" defaultValue={form.no_rek} onChange={handleTextChange} placeholder="No Rekening" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["no_rek"]}</span>
            </Col>
            <Col sm="6">
              <Form.Control type="text" name="cabang_bank" defaultValue={form.cabang_bank} onChange={handleTextChange} placeholder="Cabang Bank" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["cabang_bank"]}</span>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="data_bank">
            <Col sm="6">
              <Dropdown
                // onChange={(e, { value }) => form.data_bank = value}
                onChange={(e, { value }) => {
                  form.data_bank = value
                  setErrorMessage(errorMessage => ({ ...errorMessage, ['data_bank']: "" }))
                }}
                name="data_bank"
                placeholder='--Pilih Bank--' style={{ width: '100%' }}
                closeOnChange search selection options={props.stateOptions} />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["data_bank"]}</span>
            </Col>
            <Col sm="6">
              <Form.Control type="text" name="atas_nama_bank" defaultValue={form.atas_nama_bank} onChange={handleTextChange} placeholder="Atas Nama" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["atas_nama_bank"]}</span>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="foto_ktp">
            <Col sm="6">
              <Form.Label>
                Foto Ktp
              </Form.Label>
              <Form.Control type="file" name="foto_ktp"
                // defaultValue={form.foto_ktp} 
                onChange={handleTextChange} placeholder="Foto Ktp" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["foto_ktp"]}</span>
            </Col>
            <Col sm="6">
              <Form.Label column sm="6">
                Selfie dengan Ktp
              </Form.Label>
              <Form.Control type="file" name="selfie_ktp"
                // defaultValue={form.selfie_ktp} 
                onChange={handleTextChange} placeholder="Selfie Pegang Ktp" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["selfie_ktp"]}</span>
            </Col>
          </Form.Group>

          <h5 id="fonts" >Data Perusahaan / Badan Hukum *(Wajib Diisi)</h5>
          <Form.Group as={Row} controlId="akta">
            <Col sm="6">
              <Form.Label>
                Akta
              </Form.Label>
              <Form.Control type="file" name="akta"
                // defaultValue={form.akta} 
                onChange={handleTextChange} />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["akta"]}</span>
            </Col>
            <Col sm="6">
              <Form.Label>
                NIB
              </Form.Label>
              <Form.Control type="file" name="nib"
                // defaultValue={form.nib} 
                onChange={handleTextChange} placeholder="NIB" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["nib"]}</span>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="sk">
            <Col sm="6">
              <Form.Label>
                SK Kemenkumham
              </Form.Label>
              <Form.Control type="file" name="sk_kemenkumham"
                // defaultValue={form.sk_kemenkumham} 
                onChange={handleTextChange} placeholder="SK Kemenkumham" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["sk_kemenkumham"]}</span>
            </Col>
            <Col sm="6">
              <Form.Label>
                Foto NPWP Perusahaan
              </Form.Label>
              <Form.Control type="file" name="npwp_perusahaan"
                // defaultValue={form.npwp_perusahaan} 
                onChange={handleTextChange} placeholder="NPWP Perusahaan" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["npwp_perusahaan"]}</span>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="pengurus">
            <Col sm="6">
              <Form.Label>
                Foto Ktp Pengurus
              </Form.Label>
              <Form.Control type="file" name="ktp_pengurus"
                // defaultValue={form.ktp_pengurus} 
                onChange={handleTextChange} placeholder="KTP Pengurus" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["ktp_pengurus"]}</span>
            </Col>
            <Col sm="6">
              <Form.Label>
                Buku Tabungan
              </Form.Label>
              <Form.Control type="file" name="buku_tabungan"
                // defaultValue={form.buku_tabungan} 
                onChange={handleTextChange} placeholder="Buku Tabungan" />
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["buku_tabungan"]}</span>
            </Col>
          </Form.Group>

          <Modal.Footer>
            <Button id="fonts" className="btnSimpan" style={{ marginRight: "auto", marginLeft: "auto", padding: "5px 50px", borderRadius: "20px" }} color="blue" type="button" onClick={handleInputSubmit}>
              Simpan
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
