import React, { useState } from 'react';
import { connect } from "react-redux";
import { compose } from "redux";
import Navbar from "../../components/navbar_home";
import Footer from "../../components/Footer";
import Kerja from "../../assets/img/kerjasama.co.id.vertical.png";
import Gambar from "../../assets/img/shutterstock_527307331.jpg";
import Misi from "../../assets/img/misi_icon.svg";
import Visi from "../../assets/img/visi_icon.svg";
import Comissioner from "../../assets/img/comissioner.png";
import Presdir from "../../assets/img/presdir.png";
import Director from "../../assets/img/director.png";
import "../../App.scss";



class Tentangkami extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.isLoading = true;
    }

    clearConsole() {
        if (window.console || window.console.firebug) {
            console.clear();
        }
    }

    render() {
        this.clearConsole();
        return (
            <div>
                <Navbar />
                <Body />
                <VisiMisi />
                {/* <Tengah/> */}
                <Team/>
                <Footer />
            </div>
        );
    }
}

function Body() {
    return (
        <div className="container " style={{ marginTop: "50px" }}>
            <div className="row" >
                <div className="col-md-12 col-lg-12">
                    {/* <div className="gambar text-center">
                        <img src={Kerja} style={{ width: "30%" }} alt="" />
                    </div> */}
                    <div className="visi-misi text-center">
                        <h4 style={{ fontSize: "30px", fontWeight: "700", color: "#033C85" }}>Tentang Kami</h4>
                    </div>
                    <div className="content" style={{ marginTop: "80px" }}>
                        <h6 style={{ fontSize: "18px", color: "#474d6d", fontWeight: "700", textAlign: "justify", fontFamily: "helvetica" }}>
                            PT. KERJASAMA UNTUK NEGERI (KUNN)
                            <span style={{ color: "#58595B", fontSize: "18px", fontWeight: "500" }}> sebagai penyelenggara layanan penawaran efek berbasis terknologi informasi yang selanjutnya
                                disebut kerjasama.co.id. Kerjasama.co.id adalah penyelenggara yang menyediakan,
                                mengelola dan mengoperasikan penawaran efek melalui layanan urun dana berbasis teknologi informasi.
                                <p style={{ paddingTop: 20 }}>
                                    Kerjasama.co.id membantu pemodal dalam melakukan manajemen risiko sehingga pemodal akan mendapatkan
                                    imbal hasil yang menarik dengan tetap memperhatikan adanya risiko usaha, risiko investasi,
                                    risiko likuiditas, kegagalan sistem elektronik, kelangkaan pembagian dividen dan/atau dilusi efek
                                    serta gagal bayar atas efek. Selain itu, Kerjasama.co.id juga membantu meningkatkan pertumbuhan
                                    ekonomi usaha mikro dan menengah bagi penerbit efek yang membutuhkan modal untuk melakukan
                                    pengembangan usaha.
                                </p>
                            </span>
                        </h6>
                    </div>
                </div>
            </div>
        </div>

    );
}

function VisiMisi() {
    return (
        <div className="container " style={{ marginTop: "100px", marginBottom: '100px' }}>
            <div className="row" >
                <div className="col-md-12 col-lg-12">
                    <div className="visi-misi text-center">
                        <h4 style={{ fontSize: "30px", fontWeight: "700", color: "#033C85" }}>Visi & Misi</h4>
                    </div>
                    <div className="content" style={{ marginTop: "80px" }}>
                        <h5 style={{ color: "#033C85", fontSize: "25px", fontWeight: "700", fontFamily: "helvetica" }}>Visi</h5>
                        <div className="isi">
                            <span style={{ color: "#58595B", fontSize: "18px", fontFamily: 'Helvetica' }}>
                                Menjadi platform layanan penawaran efek berbasis teknologi terkemuka yang mendorong pertumbuhan usaha mikro dan menengah
                            </span>
                        </div>
                    </div>
                    <div className="content" style={{ marginTop: "30px" }}>
                        <h5 style={{ color: "#033C85", fontSize: "25px", fontWeight: "700", fontFamily: "helvetica" }}>Misi</h5>
                        <ul style={{ marginLeft: "25px", fontFamily: 'Helvetica' }}>
                            <li style={{ color: "#58595B", fontSize: "18px", fontWeight: "400" }}>
                                Memberikan kemudahan dan keamanan bagi publik sebagai pemodal untuk melakukan investasi efek dengan imbal hasil yang menarik              		</li>
                            <li className="mt-2" style={{ color: "#58595B", fontSize: "18px", fontWeight: "500" }}>
                                Membantu dan mendorong usaha mikro dan menengah sebagai penerbit efek yang membutuhkan tambahan modal untuk melakukan pengembangan usaha
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    );
}


function Tengah() {
    return (
        <div style={{ marginTop: "100px", marginBottom: '100px' }}>
            <div className="visi-misi text-center">
                <h4 style={{ fontSize: "40px", fontWeight: "700", color: "#033C85", textTransform: "uppercase" }}>visi & misi</h4>
            </div>
            <div className="row body-isi" style={{ marginTop: "100px" }}>
                <div className="col-md-6" >
                    <img src={Gambar} style={{ backgroundSize: "cover", width: "105%", height: "100%" }} alt="" />
                </div>
                <div className="col-md-6 kanan" style={{ background: "#EEEEEE" }}>
                    <div className="visi-kanan d-flex" style={{ paddingLeft: "100px", paddingTop: "50px" }}>
                        <img src="" alt="" />
                        <h5 style={{ color: "#033C85", fontSize: "40px", fontWeight: "700", textTransform: "uppercase", fontFamily: "helvetica" }}>visi</h5>
                    </div>
                    <div className="isi" style={{ paddingLeft: "100px" }}>
                        <span style={{ color: "#58595B", fontSize: "23px", fontWeight: "400", fontFamily: 'Helvetica' }}>
                        Menjadi platform layanan penawaran efek berbasis teknologi terkemuka yang mendorong pertumbuhan usaha mikro dan menengah
                        </span>
                    </div>


                    <div className="misi-kanan d-flex" style={{ paddingLeft: "100px", paddingTop: "50px" }}>
                        <img src="" alt="" />
                        <h5 style={{ color: "#033C85", fontSize: "40px", fontWeight: "700", textTransform: "uppercase", fontFamily: "helvetica" }}>misi</h5>
                    </div>
                    <ul style={{ paddingLeft: "100px", fontFamily: 'Helvetica' }}>
                        <li style={{ color: "#58595B", fontSize: "23px", fontWeight: "400" }}>
                            Memberikan kemudahan dan keamanan bagi publik sebagai pemodal untuk melakukan investasi efek dengan imbal hasil yang menarik
                        </li>
                        <li className="mt-2" style={{ color: "#58595B", fontSize: "23px", fontWeight: "500" }}> 
                            Membantu dan mendorong usaha mikro dan menengah sebagai penerbit efek yang membutuhkan tambahan modal untuk melakukan pengembangan usaha
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

function Team() {
    return (
        <div className="container text-center" style={{ marginTop: "100px", marginBottom: '100px' }}>
            <div className="row">
                <div className="col-md-12">
                    <h4 style={{ color: "#033C85", fontSize: "30px", fontWeight: "700" }}>Tim Kami yang Berpengalaman</h4>
                </div>
            </div>
            <div className="row" style={{ marginTop: "80px" }}>
                <div className="col-md-4 col-lg-4 ">
                    <img src={Comissioner} alt="" width={300}/>
                    <div className="isi-body ">
                        <h3 style={{ color: "#58595B", fontSize: "20px", fontWeight: "700", marginTop: "30px" }}>Marjuky</h3>
                        <h5 style={{ color: "#033C85", fontWeight: "700", fontSize: "20px" }}>Komisaris</h5>
                        <p style={{ color: "#58595B", fontSize: "16px", fontWeight: "200" }}>
                            Berpengalaman sebagai Direktur PT Aska Indoco pada tahun 2010 sampai dengan 2012. 
                            Pada tahun 2012 sampai dengan 2016 sebagai Direktur Penjualan PT Dima Indonesia.
                            Pada tahun 2016 sampai dengan sekarang sebagai Chief Operational Officer PT Dima Indonesia,
                            dan 2020 sampai dengan sekarang sebagai Komisaris PT Kerjasama Untuk Negeri.
                        </p>
                    </div>
                </div>
                <div className="col-md-4 col-lg-4 ">
                    <img src={Presdir} alt="" width={325}/>
                    <div className="isi-body ">
                        <h3 style={{color:"#58595B",fontSize:"20px",fontWeight:"700", marginTop:"24px"}}>Prof. Dr. Gunadi, MSc., Ak.</h3>
                        <h5 style={{color:"#033C85",fontWeight:"700",fontSize:"20px"}}>Direktur Utama</h5>
                        <p style={{color:"#58595B",fontSize:"16px",fontWeight:"200"}}>
                            Berpengalaman sebagai Komisaris PT Timah pada tahun 1997 sampai dengan 2000. 
                            Pada tahun 2000 sampai dengan 2006 sebagai Direktur Peraturan Ditjen Pajak.
                            Pada tahun 2006 sampai dengan 2011 sebagai Wakil Kepala PPATK.
                            Pada tahun 2013 sampai dengan 2019 sebagai Ketua Komite Pengawas Perpajakan.
                            Pada tahun 2020 sampai dengan 2021 sebagai Direktur PT Kerjasama Untuk Negeri.
                            Pada tahun 2021 sampai dengan sekarang sebagai Direktur Utama PT Kerjasama Untuk Negeri,
                            dan 1998 sampai dengan sekarang sebagai Guru Besar Universitas Indonesia.
                        </p>
                    </div>
                </div>
                <div className="col-md-4 col-lg-4">
                    <img src={Director} alt="" width={300}/>
                    <div>
                        <h3 style={{ color: "#58595B", fontSize: "20px", fontWeight: "700", marginTop: "28px" }} >Heri</h3>
                        <h5 style={{ color: "#033C85", fontWeight: "700", fontSize: "20px" }}>Direktur</h5>
                        <p style={{ color: "#58595B", fontSize: "16px", fontWeight: "200" }}>
                            Berpengalaman sebagai Direktur PT Esham Dima Mandiri pada tahun 2016 sampai dengan sekarang,
                            dan 2020 sampai dengan sekarang sebagai Direktur PT Kerjasama Untuk Negeri.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Tentangkami;
