import React from 'react';
import { api, fetchApi } from "./api";
import Home from "../pages/landing/Home";
import ErrorPage from "../pages/landing/ErrorPage"
import { Redirect, useHistory } from 'react-router-dom';
import { BallClipRotateMultiple } from 'react-pure-loaders';


class Api extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      error: null,
      isLoaded: false,
      hasError: false,
      items: []
    };

  }

  componentDidMount() {
    // fetch("https://c0e172a6b76c.ngrok.io/newkerjasamacom/")
    fetch(process.env.REACT_APP_BASE_URL + "/frontend?status=1")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.response
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,

          });
        }
      )
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    // logErrorToMyService(error, info);
  }

  render() {
    const { error, isLoaded, items } = this.state;
    // const data = () => {
    //   console.log(items.project[0])
    // }

    // const project = [{ ...items.project}]
    // const handleclick = ({ id_project}) => {
    //   // <Redirect to="/Detail"/>
    //   // useHistory().push("/Detail")
    //   // return "/Detail/"+id_project
    //   return id_project
    // }

    // if (items.length == 0) {
    //   // You can render any custom fallback UI
    //   return <h1>Something went wrong.</h1>;
    // }

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return (
        <div
          style={{ paddingTop: '50px', paddingBottom: '50px', Width: 'initial', textAlign: 'center' }}
        >
          <BallClipRotateMultiple
            color={'#123abc'}
            loading
          />
        </div>
      );
    } else {
      return (
        <div className="row justify-content-center">
          {(items.project.length > 0) ?
            items.project.map(item => (
              <div key={item.id_project} className="col-md-6 col-sm-6 col-lg-4 promo-kun-3 my-3">

                <div className="card">
                  <img
                    className="card-img-top"
                    src={item.gambar}
                    width="180"
                    alt=""
                  />{" "}
                  <div className="card-body" >

                    <h5 className="card-title">
                      Proyek <span className="font-weight-bold"> {item.slot_nominal} /Slot</span>
                    </h5>
                    <h4 className="mb-2">{item.nama_project}</h4>
                    {/* <h6 className="text-muted my-3">by Syarfi & Sadaya</h6> */}
                    <div className="row">
                      <div className="col-md-6 col-sm-6">
                        <h6>
                          Kebutuhan Dana <br />{" "}
                          <span className="font-weight-bold">{item.modal}</span>{" "}
                        </h6>{" "}
                        <br />
                        <h6>
                          Slot <br />{" "}
                          <span className="font-weight-bold">{item.total_slot_req} Slot</span>
                          <br />
                        </h6>{" "}
                        <br />
                        <h6>
                          Penggalangan dimulai <br />{" "}
                          <span className="font-weight-bold">{item.date_awal}</span>
                        </h6>
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <h6>
                          Masa investasi/Tenor <br />
                          <span className="font-weight-bold">{item.durasi_crowd} Bulan</span>
                        </h6>{" "}
                        <br />
                        <h6>
                          Estimasi Keuntungan <br />{" "}
                          <span className="font-weight-bold">19%</span>{" "}
                        </h6>{" "}
                        <br />
                        <h6>
                          Penggalangan Berakhir <br />{" "}
                          <span className="font-weight-bold">{item.date_akhir}</span>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <a
                    href={`/Detail/${item.id_project}`}
                    className="btn btn-primary btn-block"
                  >
                    {" "}
                    Detail
                  </a>
                </div>
              </div>


            )) : <div
              style={{ paddingTop: '50px', paddingBottom: '50px', Width: 'initial', textAlign: 'center' }}
            >
              <BallClipRotateMultiple
                color={'#123abc'}
                loading
              />
            </div>}
        </div>



      );
    }
  }
}

export default Api;

