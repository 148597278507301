import React from "react";
import { Container, Row, Col, Tabs, Tab, Modal } from "react-bootstrap";
import seru2 from "../../../assets/img/Group 895.png";
import empty from "../../../assets/img/empty.png";
import templateproposal from "../../../assets/Kisi-kisi Proposal.pptx";
import { BASE_URL } from "../../../service/api";
import axios from "axios";
import {
  Button,
  Icon,
  Input,
  Image,
  Grid,
  Header,
  Segment,
  Form,
  TextArea,
  Dropdown,
  Progress,
  Card,
  Pagination,
  Menu,
} from "semantic-ui-react";
import $ from "jquery";
import swal from "sweetalert";

class Pengajuan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gambarProposal: "Unggah Proposal",
      nama_project: "",
      nilai_proyek: "",
      deskripsi: "",
      type_project: "",
      type_efek: "",
      paramBack: "",
      gambarProyek1: "",
      gambarProyek2: "",
      gambarProyek3: "",
      gambarProyek4: "",
      gambarProyek5: "",
      gambarProyek: [],
      proposal: "",
      showModalPengajuan: false,
      showModalPengajuanViewDetail: false,
      items: {
        proposal: {
          id_project_data: null,
          nama_file: null,
        },
        gambar: [],
      },
    };
  }

  price = () =>
    this.state.isFocus
      ? this.state.nilai_proyek.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      : this.state.nilai_proyek;

  handleBlur = (e) => this.setState({ isFocus: false });
  handleFocus = (e) => this.setState({ isFocus: true });
  handleTextChangePrice = (e) =>
    this.setState({ nilai_proyek: e.target.value.replace(/,/g, "") });

  onFileChange(e, param) {
    var files = e.target.files;
    if (!files.length) {
    } else {
      return this.createImage(files[0], param);
    }
  }

  createImage(file, param) {
    var tmp = [];
    var reader = new FileReader();

    reader.onload = (e) => {
      if (param == 0) {
        this.setState({
          gambarProyek1: e.target.result,
        });
      } else if (param == 1) {
        this.setState({
          gambarProyek2: e.target.result,
        });
      } else if (param == 2) {
        this.setState({
          gambarProyek3: e.target.result,
        });
      } else if (param == 3) {
        this.setState({
          gambarProyek4: e.target.result,
        });
      } else if (param == 4) {
        this.setState({
          gambarProyek5: e.target.result,
        });
      } else if (param == 89) {
        this.setState({
          gambarProposal: file.name,
        });
      }
    };
    reader.readAsDataURL(file);
  }

  onFileChangeEdit(e) {
    var files = e.target.files;
    if (!files.length) {
    } else {
      return this.createImageEdit(files[0]);
    }
  }

  createImageEdit(file) {
    var tmp = [];
    var reader = new FileReader();

    reader.onload = (e) => {
      if (this.state.fileTo == 89) {
        this.setState({
          gambarProposal: file.name,
        });
      } else {
        this.setState({
          image: e.target.result,
        });
      }
    };
    reader.readAsDataURL(file);
  }

  handleShowModalPengajuan = () => {
    this.setState({ showModalPengajuan: true });
  };

  handleCloseModalPengajuan = () => {
    this.setState({ showModalPengajuan: false });
    this.refreshFormInput();
  };

  refreshFormInput = () => {
    this.setState({
      nama_project: "",
      type_project: "",
      type_efek: "",
      deskripsi: "",
      nilai_proyek: "",
      proposal: "",
      gambarProposal: "Unggah Proposal",
      gambarProyek1: "",
      gambarProyek2: "",
      gambarProyek3: "",
      gambarProyek4: "",
      gambarProyek5: "",
    });
  };

  // handleShowModalPengajuanViewDetail = () => {
  //     this.setState({ showModalPengajuanViewDetail: true })
  // }

  handleCloseModalPengajuanViewDetail = () => {
    this.setState({ showModalPengajuanViewDetail: false });
    this.refreshFormInput();
  };

  handleShowModalEditData = () => {
    this.setState({
      id_project: this.state.items.id_project,
      nama_project: this.state.items.nama_project,
      type_project: this.state.items.type_project,
      type_efek: this.state.items.type_efek,
      deskripsi: this.state.items.deskripsi,
      nilai_proyek: this.state.items.modal,
      showModalEditData: true,
      showModalPengajuanViewDetail: false,
    });
  };

  handleCloseModalEditData = () => {
    this.setState({ showModalEditData: false });
    this.setState({ showModalPengajuanViewDetail: true });
  };

  handleShowModalEditProposal = () => {
    this.setState({
      id_project: this.state.items.id_project,
      proposal: this.state.items.proposal,
      gambarProposal: "Unggah Proposal",
      fileTo: 89,
      showModalEditProposal: true,
      showModalPengajuanViewDetail: false,
    });
  };

  handleCloseModalEditProposal = () => {
    this.setState({ showModalEditProposal: false });
    this.setState({ showModalPengajuanViewDetail: true });
  };

  handleShowModalEditGambar = (param, imageId) => {
    this.setState({
      id_project: this.state.items.id_project,
      id_project_data: imageId,
      fileTo: param,
      image: null,
      showModalEditGambar: true,
      showModalPengajuanViewDetail: false,
    });
  };

  handleCloseModalEditGambar = () => {
    this.setState({ showModalEditGambar: false });
    this.setState({ showModalPengajuanViewDetail: true });
  };

  render() {
    let userinfo = this.props.userinfo;

    const { gambarProposal } = this.state;

    const optionsEfek = [
      { key: "EBU", text: "Efek Bersifat Utang (EBU)", value: "EBU" },
      { key: "EBU", text: "Efek Bersifat Ekuitas (EBE)", value: "EBE" },
    ];

    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        // reader.onloadend = function () {
        //     // alert(reader.result);
        //     var base64result = reader.result.substr(reader.result.indexOf(',') + 1);
        //     resolve(base64result);
        // };
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

    const handleTextChange = (event) => {
      this.setState({
        [event.target.name]: event.target.value,
      });
    };

    const handleInputSubmit = async () => {
      const form = {
        id_pengelola: userinfo.id,
        user_id: userinfo.user_id,
        nama_project: this.state.nama_project,
        deskripsi: this.state.deskripsi,
        modal: this.state.nilai_proyek,
        proposal: this.state.proposal,
        type_project: this.state.type_project,
        type_efek: this.state.type_efek,
      };

      // var formField = document.forms[0]
      var formField = document.querySelector('form[name="formInputProject"]');

      if (userinfo.status_user != 3) {
        swal({
          text: "Data anda belum terverifikasi, anda belum diizinkan untuk mengajukan pendanaan",
          icon: "error",
          button: "OK",
        });
        return false;
      }

      var gambarProyek1 = formField.querySelector('input[name="gambarProyek1"]')
        .files[0];
      var gambarProyek2 = formField.querySelector('input[name="gambarProyek2"]')
        .files[0];
      var gambarProyek3 = formField.querySelector('input[name="gambarProyek3"]')
        .files[0];
      var gambarProyek4 = formField.querySelector('input[name="gambarProyek4"]')
        .files[0];
      var gambarProyek5 = formField.querySelector('input[name="gambarProyek5"]')
        .files[0];
      var proposald = formField.querySelector('input[name="proposal"]')
        .files[0];

      var gambarProyektmp = [];

      if (proposald) {
        var sizeproposald = formField.querySelector('input[name="proposal"]')
          .files[0].size;
        var typeproposald = formField.querySelector('input[name="proposal"]')
          .files[0].type;

        if (typeproposald != "application/pdf" || sizeproposald > 5120000) {
          swal({
            text: "File Proposal harus berformat PDF dan ukuran Max 5MB",
            icon: "error",
            button: "OK",
          });
          return false;
        }
      } else {
        swal({
          text: "Harap isi semua data dan lampiran dokumen",
          icon: "error",
          button: "OK",
        });
        // app.setState({ openInputPrivy: false, openAgreePrivy: false })
        return false;
      }

      if (gambarProyek1) {
        var gambarProyek1size = formField.querySelector(
          'input[name="gambarProyek1"]'
        ).files[0].size;
        var gambarProyek1type = formField.querySelector(
          'input[name="gambarProyek1"]'
        ).files[0].type;

        if (
          (gambarProyek1type != "image/jpeg" &&
            gambarProyek1type != "image/png" &&
            gambarProyek1type != "image/gif") ||
          gambarProyek1size > 5120000
        ) {
          swal({
            text: "File harus berupa Format JPEG, PNG, GIF dan ukuran Max 5MB",
            icon: "error",
            button: "OK",
          });
          return false;
        } else {
          gambarProyektmp.push(await toBase64(gambarProyek1));
        }
      }
      if (gambarProyek2) {
        var gambarProyek2size = formField.querySelector(
          'input[name="gambarProyek2"]'
        ).files[0].size;
        var gambarProyek2type = formField.querySelector(
          'input[name="gambarProyek2"]'
        ).files[0].type;

        if (
          (gambarProyek2type != "image/jpeg" &&
            gambarProyek2type != "image/png" &&
            gambarProyek2type != "image/gif") ||
          gambarProyek2size > 5120000
        ) {
          swal({
            text: "File harus berupa Format JPEG, PNG, GIF dan ukuran Max 5MB",
            icon: "error",
            button: "OK",
          });
          return false;
        } else {
          gambarProyektmp.push(await toBase64(gambarProyek2));
        }
      }
      if (gambarProyek3) {
        var gambarProyek3size = formField.querySelector(
          'input[name="gambarProyek3"]'
        ).files[0].size;
        var gambarProyek3type = formField.querySelector(
          'input[name="gambarProyek3"]'
        ).files[0].type;

        if (
          (gambarProyek3type != "image/jpeg" &&
            gambarProyek3type != "image/png" &&
            gambarProyek3type != "image/gif") ||
          gambarProyek3size > 5120000
        ) {
          swal({
            text: "File harus berupa Format JPEG, PNG, GIF dan ukuran Max 5MB",
            icon: "error",
            button: "OK",
          });
          return false;
        } else {
          gambarProyektmp.push(await toBase64(gambarProyek3));
        }
      }
      if (gambarProyek4) {
        var gambarProyek4size = formField.querySelector(
          'input[name="gambarProyek4"]'
        ).files[0].size;
        var gambarProyek4type = formField.querySelector(
          'input[name="gambarProyek4"]'
        ).files[0].type;

        if (
          (gambarProyek4type != "image/jpeg" &&
            gambarProyek4type != "image/png" &&
            gambarProyek4type != "image/gif") ||
          gambarProyek4size > 5120000
        ) {
          swal({
            text: "File harus berupa Format JPEG, PNG, GIF dan ukuran Max 5MB",
            icon: "error",
            button: "OK",
          });
          return false;
        } else {
          gambarProyektmp.push(await toBase64(gambarProyek4));
        }
      }
      if (gambarProyek5) {
        var gambarProyek5size = formField.querySelector(
          'input[name="gambarProyek5"]'
        ).files[0].size;
        var gambarProyek5type = formField.querySelector(
          'input[name="gambarProyek5"]'
        ).files[0].type;

        if (
          (gambarProyek5type != "image/jpeg" &&
            gambarProyek5type != "image/png" &&
            gambarProyek5type != "image/gif") ||
          gambarProyek5size > 5120000
        ) {
          swal({
            text: "File harus berupa Format JPEG, PNG, GIF dan ukuran Max 5MB",
            icon: "error",
            button: "OK",
          });
          return false;
        } else {
          gambarProyektmp.push(await toBase64(gambarProyek5));
        }
      }

      if (proposald) {
        form.proposal = await toBase64(proposald);
      }

      if (gambarProyektmp.length == 0) {
        swal({
          text: "Harap isi semua data dan lampirankan dokumen",
          icon: "error",
          button: "OK",
        });
        return false;
      }

      form.gambar = gambarProyektmp;

      $(".uploadSubmit").addClass("loading");
      $(".uploadSubmit").addClass("disabled");

      submitProyek(form);
    };

    const submitProyek = async (values) => {
      // const formData = new FormData();
      // formData.append('file',JSON.stringify(values))
      // setTimeout(() => {
      //     window.location.reload(true);
      // }, 2000)
      // console.log('DATA_POST: ', JSON.stringify(values))
      // return

      let config = {
        headers: {
          Authorization: localStorage.token,
          "content-type": "application/json",
          //   'content-type': 'multipart/form-data',
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods": "POST",
          //   'Access-Control-Allow-Headers' : 'Content-Type, Content-Length, Accept-Encoding',
        },
        maxContentLength: 52428890,
        timeout: 10000000,
        // mode: 'no-cors',
      };

      axios
        .post(BASE_URL + "/customer/pengelola/submitproyek", values, config)
        .then((resp) => {
          $(".uploadSubmit").removeClass("loading");
          $(".uploadSubmit").removeClass("disabled");

          if (resp.data.success == false) {
            swal({
              text: resp.data.message,
              icon: "warning",
              closeOnClickOutside: false,
            }).then((value) => {
              this.refreshFormInput();
              window.location.reload(true);
            });
          } else {
            swal({
              text: resp.data.message,
              icon: "success",
              closeOnClickOutside: false,
            }).then((value) => {
              this.refreshFormInput();
              window.location.reload(true);
            });
          }
        })
        .catch((error) => {
          $(".uploadSubmit").removeClass("loading");
          $(".uploadSubmit").removeClass("disabled");

          try {
            let errorStatusCode = error.response.status;

            if (errorStatusCode === 401) {
              setTimeout(() => {
                window.location.href = "/Signin";
                localStorage.removeItem("token");
                localStorage.clear();

                this.props.dispatch({
                  type: "AUTH_USER_LOGOUT",
                });
              }, 500);
            } else {
              let errorBody = error.response.data;
              swal({ text: errorBody.message, icon: "error" });
            }
          } catch (error) {
            swal({ text: "Error tidak terdefinisi!", icon: "error" });
          }
        });
    };

    const handleCancel = async (id) => {
      swal({
        title: "Konfirmasi Pembatalan",
        text: "Apakah anda akan membatalkan pengajuan?",
        icon: "warning",
        buttons: ["Tidak", "Ya"],
        closeOnClickOutside: false,
      }).then((ok) => {
        if (ok) {
          let post = {};
          post.id_project = id;

          // console.log('DATA_POST: ', JSON.stringify(post))
          // return

          let config = {
            headers: {
              Authorization: localStorage.token,
              "content-type": "application/json",
              "Access-Control-Allow-Credentials": true,
              "Access-Control-Allow-Methods": "POST",
            },
            maxContentLength: 52428890,
            timeout: 10000000,
            // mode: 'no-cors',
          };

          axios
            .post(
              BASE_URL + "/customer/pengelola/pengajuan/cancel",
              post,
              config
            )
            .then((resp) => {
              $(".uploadSubmit").removeClass("loading");
              $(".uploadSubmit").removeClass("disabled");

              if (resp.data.success == false) {
                swal({
                  text: resp.data.message,
                  icon: "warning",
                  closeOnClickOutside: false,
                }).then((value) => {
                  this.refreshFormInput();
                  window.location.reload(true);
                });
              } else {
                swal({
                  text: resp.data.message,
                  icon: "success",
                  closeOnClickOutside: false,
                }).then((value) => {
                  this.refreshFormInput();
                  window.location.reload(true);
                });
              }
            })
            .catch((error) => {
              $(".uploadSubmit").removeClass("loading");
              $(".uploadSubmit").removeClass("disabled");

              try {
                let errorStatusCode = error.response.status;

                if (errorStatusCode === 401) {
                  setTimeout(() => {
                    window.location.href = "/Signin";
                    localStorage.removeItem("token");
                    localStorage.clear();

                    this.props.dispatch({
                      type: "AUTH_USER_LOGOUT",
                    });
                  }, 500);
                } else {
                  let errorBody = error.response.data;
                  swal({ text: errorBody.message, icon: "error" });
                }
              } catch (error) {
                swal({ text: "Error tidak terdefinisi!", icon: "error" });
              }
            });
        }
      });
    };

    const handleSubmitEditData = async () => {
      let values = {};
      values.id_project = this.state.items.id_project;
      values.nama_project = this.state.nama_project;
      values.type_project = this.state.type_project;
      values.type_efek = this.state.type_efek;
      values.deskripsi = this.state.deskripsi;
      values.modal = this.state.nilai_proyek;

      // console.log('EDIT', JSON.stringify(values))
      // return

      let config = {
        headers: {
          Authorization: localStorage.token,
          "content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods": "POST",
        },
        maxContentLength: 52428890,
        timeout: 10000000,
      };

      axios
        .post(
          BASE_URL + "/customer/pengelola/pengajuan/editdata",
          values,
          config
        )
        .then((resp) => {
          $(".uploadSubmit").removeClass("loading");
          $(".uploadSubmit").removeClass("disabled");

          if (resp.data.success == false) {
            swal({
              text: resp.data.message,
              icon: "warning",
              closeOnClickOutside: false,
            }).then((value) => {
              // this.refreshFormInput()
              window.location.reload(true);
            });
          } else {
            swal({
              text: resp.data.message,
              icon: "success",
              closeOnClickOutside: false,
            }).then((value) => {
              // this.refreshFormInput()
              window.location.reload(true);
            });
          }
        })
        .catch((error) => {
          $(".uploadSubmit").removeClass("loading");
          $(".uploadSubmit").removeClass("disabled");

          try {
            let errorStatusCode = error.response.status;

            if (errorStatusCode === 401) {
              setTimeout(() => {
                window.location.href = "/Signin";
                localStorage.removeItem("token");
                localStorage.clear();

                this.props.dispatch({
                  type: "AUTH_USER_LOGOUT",
                });
              }, 500);
            } else {
              let errorBody = error.response.data;
              swal({ text: errorBody.message, icon: "error" });
            }
          } catch (error) {
            swal({ text: "Error tidak terdefinisi!", icon: "error" });
          }
        });
    };

    const handleSubmitEditProposal = async () => {
      var formField = document.querySelector('form[name="formEditProposal"]');
      var proposald = formField.querySelector('input[name="proposal"]')
        .files[0];

      if (proposald) {
        var sizeproposald = formField.querySelector('input[name="proposal"]')
          .files[0].size;
        var typeproposald = formField.querySelector('input[name="proposal"]')
          .files[0].type;

        if (typeproposald != "application/pdf" || sizeproposald > 5120000) {
          swal({
            text: "File Proposal harus berformat PDF dan ukuran Max 5MB",
            icon: "error",
            button: "OK",
          });
          return false;
        }
      } else {
        swal({ text: "Lampirkan file proposal", icon: "error", button: "OK" });
        return false;
      }

      let values = {};
      values.id_project = this.state.items.id_project;
      values.id_project_data = this.state.items.proposal.id_project_data;
      if (proposald) {
        values.proposal = await toBase64(proposald);
      }

      // console.log('EDIT', JSON.stringify(values))
      // return

      let config = {
        headers: {
          Authorization: localStorage.token,
          "content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods": "POST",
        },
        maxContentLength: 52428890,
        timeout: 10000000,
      };

      axios
        .post(
          BASE_URL + "/customer/pengelola/pengajuan/editproposal",
          values,
          config
        )
        .then((resp) => {
          $(".uploadSubmit").removeClass("loading");
          $(".uploadSubmit").removeClass("disabled");

          if (resp.data.success == false) {
            swal({
              text: resp.data.message,
              icon: "warning",
              closeOnClickOutside: false,
            }).then((value) => {
              // this.refreshFormInput()
              window.location.reload(true);
            });
          } else {
            swal({
              text: resp.data.message,
              icon: "success",
              closeOnClickOutside: false,
            }).then((value) => {
              // this.refreshFormInput()
              window.location.reload(true);
            });
          }
        })
        .catch((error) => {
          $(".uploadSubmit").removeClass("loading");
          $(".uploadSubmit").removeClass("disabled");

          try {
            let errorStatusCode = error.response.status;

            if (errorStatusCode === 401) {
              setTimeout(() => {
                window.location.href = "/Signin";
                localStorage.removeItem("token");
                localStorage.clear();

                this.props.dispatch({
                  type: "AUTH_USER_LOGOUT",
                });
              }, 500);
            } else {
              let errorBody = error.response.data;
              swal({ text: errorBody.message, icon: "error" });
            }
          } catch (error) {
            swal({ text: "Error tidak terdefinisi!", icon: "error" });
          }
        });
    };

    const handleSubmitEditGambar = async () => {
      var formField = document.querySelector('form[name="formEditGambar"]');
      var gambar = formField.querySelector('input[name="gambarProyekEdit"]')
        .files[0];

      if (gambar) {
        var sizegambar = formField.querySelector(
          'input[name="gambarProyekEdit"]'
        ).files[0].size;
        var typegambar = formField.querySelector(
          'input[name="gambarProyekEdit"]'
        ).files[0].type;

        if (
          (typegambar != "image/jpeg" &&
            typegambar != "image/jpg" &&
            typegambar != "image/png") ||
          sizegambar > 5120000
        ) {
          swal({
            text: "File harus berupa Format JPEG, PNG, GIF dan ukuran Max 5MB",
            icon: "error",
            button: "OK",
          });
          return false;
        }
      } else {
        swal({
          text: "Lampirkan file gambar bisnis",
          icon: "error",
          button: "OK",
        });
        return false;
      }

      let values = {};
      values.id_project = this.state.items.id_project;
      values.id_project_data = this.state.id_project_data;
      values.file_to = this.state.fileTo;
      if (gambar) {
        values.gambar = await toBase64(gambar);
      }

      // console.log('EDIT', JSON.stringify(values))
      // return

      let config = {
        headers: {
          Authorization: localStorage.token,
          "content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods": "POST",
        },
        maxContentLength: 52428890,
        timeout: 10000000,
      };

      axios
        .post(
          BASE_URL + "/customer/pengelola/pengajuan/editgambar",
          values,
          config
        )
        .then((resp) => {
          $(".uploadSubmit").removeClass("loading");
          $(".uploadSubmit").removeClass("disabled");

          if (resp.data.success == false) {
            swal({
              text: resp.data.message,
              icon: "warning",
              closeOnClickOutside: false,
            }).then((value) => {
              // this.refreshFormInput()
              window.location.reload(true);
            });
          } else {
            swal({
              text: resp.data.message,
              icon: "success",
              closeOnClickOutside: false,
            }).then((value) => {
              // this.refreshFormInput()
              window.location.reload(true);
            });
          }
        })
        .catch((error) => {
          $(".uploadSubmit").removeClass("loading");
          $(".uploadSubmit").removeClass("disabled");

          try {
            let errorStatusCode = error.response.status;

            if (errorStatusCode === 401) {
              setTimeout(() => {
                window.location.href = "/Signin";
                localStorage.removeItem("token");
                localStorage.clear();

                this.props.dispatch({
                  type: "AUTH_USER_LOGOUT",
                });
              }, 500);
            } else {
              let errorBody = error.response.data;
              swal({ text: errorBody.message, icon: "error" });
            }
          } catch (error) {
            swal({ text: "Error tidak terdefinisi!", icon: "error" });
          }
        });
    };

    const getDetail = async (id) => {
      let config = {
        headers: {
          Authorization: localStorage.token,
          "content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods": "POST",
        },
        maxContentLength: 52428890,
        timeout: 10000000,
        // mode: 'no-cors',
      };

      axios
        .get(
          BASE_URL + "/customer/pengelola/pengajuan/detail",
          {
            params: {
              id_project: id,
            },
          },
          config
        )
        .then((resp) => {
          if (resp.data.success == false) {
            swal({
              text: resp.data.message,
              icon: "warning",
              closeOnClickOutside: false,
            }).then((value) => {});
          } else {
            this.setState({
              items: resp.data.data,

              // gambarProyek1: resp.data.data.gambar[0].nama_file,
              // gambarProyek2: resp.data.data.gambar[1].nama_file,
              // gambarProyek3: resp.data.data.gambar[2].nama_file,
              // gambarProyek4: resp.data.data.gambar[3].nama_file,
              // gambarProyek5: resp.data.data.gambar[4].nama_file,

              showModalPengajuanViewDetail: true,
            });
          }
        })
        .catch((error) => {
          try {
            let errorStatusCode = error.response.status;

            if (errorStatusCode === 401) {
              setTimeout(() => {
                window.location.href = "/Signin";
                localStorage.removeItem("token");
                localStorage.clear();

                this.props.dispatch({
                  type: "AUTH_USER_LOGOUT",
                });
              }, 500);
            } else {
              let errorBody = error.response.data;
              swal({ text: errorBody.message, icon: "error" });
            }
          } catch (error) {
            swal({ text: "Error tidak terdefinisi!", icon: "error" });
          }
        });
    };

    const openEditForm = (data) => {
      this.setState({
        action: "edit",
        id_project: data.id_project,
        nama_project: data.nama_project,
        type_project: data.type_project,
        type_efek: data.type_efek,
        deskripsi: data.deskripsi,
        nilai_proyek: data.modal,
        showModalPengajuan: true,
      });
    };

    const openViewDetail = (data) => {
      getDetail(data.id_project);
    };

    return (
      <>
        {userinfo.is_complete == 5 ? (
          <>
            {userinfo.agreement == 1 ? (
              <Container>
                <Row
                  className="justify-content-md-center"
                  style={{ marginTop: "10px", marginBottom: "5px" }}
                >
                  <Col xs md="12">
                    <Button
                      className="mini"
                      onClick={this.handleShowModalPengajuan}
                    >
                      Ajukan Pendanaan
                    </Button>
                  </Col>
                </Row>
                <Row className="justify-content-md-center">
                  <Col xs md="12" style={{ padding: "20px", marginTop: "0px" }}>
                    <Card.Group>
                      {this.props &&
                      this.props.listPengajuan &&
                      this.props.listPengajuan.length > 0 ? (
                        this.props.listPengajuan.map((item, index) => (
                          <>
                            <Segment className="col-12" key={item.id_project}>
                              <Row>
                                <Col>
                                  <Grid columns={3}>
                                    <Grid.Row>
                                      <Grid.Column width={4}>
                                        <Segment
                                          onClick={(event) => {
                                            "this.clickDetailPortofolio(itemWishlist.proyek_overview.id_project, itemWishlist.itemPVA) ";
                                          }}
                                          style={{
                                            marginLeft: "15px",
                                            border: "none",
                                            boxShadow: "none",
                                          }}
                                        >
                                          <div className="ui fluid image">
                                            <Image
                                              src={item.nama_file}
                                              wrapped
                                              ui={false}
                                              className="imgProyekBerjalan"
                                            />
                                          </div>
                                        </Segment>
                                      </Grid.Column>

                                      <Grid.Column width={6}>
                                        <Segment
                                          style={{
                                            marginLeft: "0px",
                                            border: "none",
                                            boxShadow: "none",
                                          }}
                                          onClick={(event) => {
                                            "this.clickDetailPortofolio(itemWishlist.proyek_overview.id_project, itemWishlist.itemPVA)";
                                          }}
                                        >
                                          <Header
                                            id="fonts"
                                            as="h4"
                                            color="blue"
                                          >
                                            <a
                                              href="#"
                                              className="hrefProyekBrjln"
                                            >
                                              {" "}
                                              {item.nama_project}{" "}
                                            </a>
                                            <Header.Subheader id="fonts">
                                              <div id="fonts" className="">
                                                {" "}
                                                {item.created_at}{" "}
                                              </div>
                                            </Header.Subheader>
                                          </Header>
                                        </Segment>
                                        <Segment
                                          style={{
                                            marginLeft: "0px",
                                            marginTop: "-25px",
                                            border: "none",
                                            boxShadow: "none",
                                          }}
                                          onClick={
                                            "(event) => { this.clickDetailPortofolio(itemWishlist.proyek_overview.id_project, itemWishlist.itemPVA) }"
                                          }
                                        >
                                          <Header
                                            id="fonts"
                                            as="h4"
                                            color="grey"
                                          >
                                            Kategori Bisnis
                                            <Header.Subheader id="fonts">
                                              <div id="fonts" className="">
                                                {" "}
                                                {item.category}
                                              </div>
                                            </Header.Subheader>
                                          </Header>
                                        </Segment>
                                        <Segment
                                          style={{
                                            marginLeft: "0px",
                                            marginTop: "-25px",
                                            border: "none",
                                            boxShadow: "none",
                                          }}
                                          onClick={
                                            "(event) => { this.clickDetailPortofolio(itemWishlist.proyek_overview.id_project, itemWishlist.itemPVA) }"
                                          }
                                        >
                                          <Header
                                            id="fonts"
                                            as="h4"
                                            color="grey"
                                          >
                                            Nilai Bisnis
                                            <Header.Subheader id="fonts">
                                              <div id="fonts" className="">
                                                {" "}
                                                {item.modal}
                                              </div>
                                            </Header.Subheader>
                                          </Header>
                                        </Segment>
                                      </Grid.Column>
                                      <Grid.Column width={4}>
                                        <Segment
                                          style={{
                                            marginLeft: "0px",
                                            border: "none",
                                            boxShadow: "none",
                                          }}
                                        >
                                          <Header
                                            id="fonts"
                                            as="h4"
                                            color="blue"
                                          >
                                            ID. {item.id_project}
                                            <Header.Subheader id="fonts">
                                              {/* <div id="fonts" className=""> {item.created_at} </div> */}
                                              <div>&nbsp;</div>
                                            </Header.Subheader>
                                          </Header>
                                        </Segment>
                                        <Segment
                                          style={{
                                            marginLeft: "0px",
                                            marginTop: "-25px",
                                            border: "none",
                                            boxShadow: "none",
                                          }}
                                        >
                                          <Header
                                            id="fonts"
                                            as="h4"
                                            color="blue"
                                          >
                                            Tipe Efek
                                            <Header.Subheader id="fonts">
                                              <div className="rigTotalSlot">
                                                {item.type_efek_text}
                                              </div>
                                            </Header.Subheader>
                                          </Header>
                                        </Segment>
                                        <Segment
                                          style={{
                                            marginLeft: "0px",
                                            marginTop: "-25px",
                                            border: "none",
                                            boxShadow: "none",
                                          }}
                                        >
                                          <Header
                                            id="fonts"
                                            as="h4"
                                            color="blue"
                                          >
                                            Status
                                            <Header.Subheader id="fonts">
                                              <div className="rigTotalSlot">
                                                {item.status_text}
                                              </div>
                                            </Header.Subheader>
                                          </Header>
                                        </Segment>
                                      </Grid.Column>

                                      <Grid.Column width={1}>
                                        <div
                                          style={{
                                            marginLeft: "0px",
                                            marginTop: "0px",
                                          }}
                                        >
                                          <Dropdown
                                            text="Aksi"
                                            icon="angle down"
                                            floating
                                            labeled
                                            button
                                            className="icon mini"
                                          >
                                            <Dropdown.Menu
                                              style={{ marginLeft: "-80px" }}
                                            >
                                              <Dropdown.Item
                                                onClick={() =>
                                                  openViewDetail(item)
                                                }
                                              >
                                                <Icon name="eye" />
                                                Tampilkan Detail
                                              </Dropdown.Item>
                                              {/* <Dropdown.Item 
                                                                                            disabled={item.status < 5 ? false : true}
                                                                                            onClick={()=> openEditForm(item)}><Icon name='edit'/>Edit Data</Dropdown.Item> */}
                                              <Dropdown.Item
                                                style={{ color: "red" }}
                                                disabled={
                                                  item.status < 100 ? false : true
                                                }
                                                onClick={() =>
                                                  handleCancel(item.id_project)
                                                }
                                              >
                                                <Icon name="cancel" />
                                                Batal Pengajuan
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </Grid.Column>
                                    </Grid.Row>
                                  </Grid>
                                </Col>
                              </Row>
                            </Segment>
                          </>
                        ))
                      ) : (
                        <Container
                          style={{
                            paddingTop: "100px",
                            paddingBottom: "200px",
                          }}
                        >
                          <div
                            style={{
                              fontWeight: "bold",
                              fontSize: "16px",
                              textAlign: "center",
                            }}
                          >
                            Belum ada bisnis yang dapat di tampilkan
                          </div>
                          <div
                            style={{
                              fontSize: "14px",
                              textAlign: "center",
                              lineHeight: "1.5em",
                            }}
                          >
                            Mulai ajukan pendanaan..
                          </div>
                        </Container>
                      )}

                      {this.state &&
                      this.state.dataListPaymentVA &&
                      this.state.dataListPaymentVA.length > 0 &&
                      this.state.isFilter == false &&
                      this.state.dataProyekBerjalan.length > 4 ? (
                        <>
                          <Container align="right">
                            <Row>
                              <Col xs md="12">
                                <Pagination
                                  size="mini"
                                  activePage={this.state.activePage}
                                  totalPages={Math.ceil(
                                    this.state.dataProyekBerjalan.length / 4
                                  )}
                                  onPageChange={this.PaginationClick}
                                />
                              </Col>
                            </Row>
                          </Container>
                        </>
                      ) : null}
                    </Card.Group>
                  </Col>
                </Row>
              </Container>
            ) : (
              <Container
                style={{ paddingTop: "100px", paddingBottom: "200px" }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    textAlign: "center",
                  }}
                >
                  Belum ada pengajuan yang dapat di tampilkan
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    textAlign: "center",
                    lineHeight: "1.5em",
                  }}
                >
                  Silahkan menandatangani Perjanjian Penerbit secara digital..
                </div>
              </Container>
            )}
          </>
        ) : (
          <Container style={{ paddingTop: "100px", paddingBottom: "200px" }}>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "16px",
                textAlign: "center",
              }}
            >
              Belum ada pengajuan yang dapat di tampilkan
            </div>
            <div
              style={{
                fontSize: "14px",
                textAlign: "center",
                lineHeight: "1.5em",
              }}
            >
              Silahkan lengkapi data profil..
            </div>
          </Container>
        )}

        <Modal
          size="lg"
          style={{ borderRadius: "30px" }}
          show={this.state.showModalPengajuan}
          animation={false}
        >
          <Modal.Header>
            <Header
              id="fonts"
              as="h3"
              color="blue"
              className="col-md-11"
              style={{
                textAlign: "left",
                marginTop: "5px",
                marginBottom: "5px",
              }}
            >
              Form Pengajuan Pendanaan
            </Header>
            <button
              type="button"
              class="close"
              onClick={this.handleCloseModalPengajuan}
            >
              &times;
            </button>
          </Modal.Header>
          <Modal.Body>
            <Form
              id="fonts"
              name="formInputProject"
              onSubmit={(e) => e.preventDefault()}
              encType="multipart/form-data"
              style={{ marginTop: "10px" }}
            >
              <Container>
                <Row className="justify-content-md-center">
                  <Col xs md="12">
                    <Segment>
                      <Header
                        id="fonts"
                        as="h5"
                        color="blue"
                        className="col-md-11"
                      >
                        Data Bisnis
                      </Header>

                      <Form.Field>
                        Nama Bisnis
                        <input
                          placeholder="Nama Bisnis"
                          className="form-control"
                          name="nama_project"
                          value={this.state.nama_project}
                          onChange={handleTextChange}
                        />
                        <i style={{ fontSize: "11px" }}>
                          <span style={{ color: "red" }}>*</span>Nama bisnis
                          samakan dengan nama bisnis di proposal
                        </i>
                      </Form.Field>
                      <Form.Field className="mrg10">
                        Kategori Bisnis
                        <Dropdown
                          name="type_project"
                          value={this.state.type_project}
                          className="form-control"
                          onChange={(e, { value }) => {
                            this.setState({ type_project: value });
                            // setErrorMessage(errorMessage => ({ ...errorMessage, ['province_other']: "" }))
                            // props.getDataDistrict(2, form.province_other)
                          }}
                          placeholder="Kategori Bisnis"
                          style={{ width: "100%" }}
                          closeOnChange
                          search
                          selection
                          options={this.props.dataCategory.map((item) => ({
                            key: item.category_id,
                            text: item.category,
                            value: item.category_id,
                          }))}
                        />
                      </Form.Field>
                      <Form.Field className="mrg10">
                        Tipe Efek
                        <Dropdown
                          name="type_efek"
                          value={this.state.type_efek}
                          className="form-control"
                          onChange={(e, { value }) => {
                            this.setState({ type_efek: value });
                            // setErrorMessage(errorMessage => ({ ...errorMessage, ['province_other']: "" }))
                            // props.getDataDistrict(2, form.province_other)
                          }}
                          placeholder="Tipe Efek"
                          style={{ width: "100%" }}
                          closeOnChange
                          search
                          selection
                          options={optionsEfek}
                        />
                      </Form.Field>
                      <Form.Field className="mrg10">
                        Deskripsi Bisnis
                        <TextArea
                          placeholder="Deskripsi ..."
                          rows="5"
                          name="deskripsi"
                          value={this.state.deskripsi}
                          onChange={handleTextChange}
                        />
                      </Form.Field>
                      <Form.Field className="mrg10">
                        Nilai Bisnis
                        <Input
                          placeholder="Rp."
                          name="nilai_proyek"
                          type="text"
                          value={this.price()}
                          onBlur={this.handleBlur}
                          onFocus={this.handleFocus}
                          onChange={this.handleTextChangePrice}
                        />
                      </Form.Field>
                      <Form.Group
                        style={{ fontSize: "11px", paddingLeft: "7px" }}
                        className="mrg20"
                      >
                        <Button
                          color="blue"
                          style={{ fontSize: "12px" }}
                          href={templateproposal}
                          target="_blank"
                        >
                          Download Panduan Proposal
                        </Button>
                        <input
                          type="file"
                          name="proposal"
                          className="inputfile"
                          id="embedpollfileinputProposal"
                          onChange={(event) => {
                            this.onFileChange(event, 89);
                          }}
                          accept={"application/pdf"}
                        />
                        <label
                          htmlFor="embedpollfileinputProposal"
                          className="ui small blue right floated button"
                        >
                          {gambarProposal}
                        </label>
                      </Form.Group>
                      <div style={{ fontSize: "11px" }} className="mrg10">
                        <span style={{ color: "red" }}>*</span>File proposal
                        harus berupa format PDF, ukuran file tidak boleh
                        melebihi 1MB <br />
                        <span style={{ color: "red" }}>*</span>Pastikan proposal
                        yang anda unggah berdasarkan template proposal yang kami
                        sediakan
                      </div>
                    </Segment>

                    <Segment
                      style={{ marginTop: "30px", marginBottom: "10px" }}
                    >
                      <Header
                        id="fonts"
                        as="h5"
                        color="blue"
                        className="col-md-11"
                      >
                        Gambar Bisnis
                      </Header>
                      <Col xs md="12">
                        <Grid>
                          <Grid.Row columns={5}>
                            <Grid.Column>
                              {this.state.gambarProyek1 == "" ||
                              this.state.gambarProyek1 == null ? (
                                <Image src={empty} />
                              ) : (
                                <Image src={this.state.gambarProyek1} />
                              )}
                              <Input
                                className="imgfile"
                                type="file"
                                name="gambarProyek1"
                                onChange={(event) => {
                                  this.onFileChange(event, 0);
                                }}
                                accept={"image/jpg, image/jpeg, image/png"}
                              />
                            </Grid.Column>
                            <Grid.Column>
                              {this.state.gambarProyek2 == "" ||
                              this.state.gambarProyek2 == null ? (
                                <Image src={empty} />
                              ) : (
                                <Image src={this.state.gambarProyek2} />
                              )}
                              <Input
                                className="imgfile"
                                type="file"
                                name="gambarProyek2"
                                onChange={(event) => {
                                  this.onFileChange(event, 1);
                                }}
                                accept={"image/jpg, image/jpeg, image/png"}
                              />
                            </Grid.Column>
                            <Grid.Column>
                              {this.state.gambarProyek3 == "" ||
                              this.state.gambarProyek3 == null ? (
                                <Image src={empty} />
                              ) : (
                                <Image src={this.state.gambarProyek3} />
                              )}
                              <Input
                                className="imgfile"
                                type="file"
                                name="gambarProyek3"
                                onChange={(event) => {
                                  this.onFileChange(event, 2);
                                }}
                                accept={"image/jpg, image/jpeg, image/png"}
                              />
                            </Grid.Column>
                            <Grid.Column>
                              {this.state.gambarProyek4 == "" ||
                              this.state.gambarProyek4 == null ? (
                                <Image src={empty} />
                              ) : (
                                <Image src={this.state.gambarProyek4} />
                              )}
                              <Input
                                className="imgfile"
                                type="file"
                                name="gambarProyek4"
                                onChange={(event) => {
                                  this.onFileChange(event, 3);
                                }}
                                accept={"image/jpg, image/jpeg, image/png"}
                              />
                            </Grid.Column>
                            <Grid.Column>
                              {this.state.gambarProyek5 == "" ||
                              this.state.gambarProyek5 == null ? (
                                <Image src={empty} />
                              ) : (
                                <Image src={this.state.gambarProyek5} />
                              )}
                              <Input
                                className="imgfile"
                                type="file"
                                name="gambarProyek5"
                                onChange={(event) => {
                                  this.onFileChange(event, 4);
                                }}
                                accept={"image/jpg, image/jpeg, image/png"}
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Col>
                      <div style={{ fontSize: "11px" }} className="mrg20">
                        <span style={{ color: "red" }}>*</span>Ukuran gambar
                        maximal 500x400 pixel File gambar harus berupa format
                        JPEG, PNG, atau GIF <br />
                        <span style={{ color: "red" }}>*</span>Gambar tidak
                        boleh melebihi 1MB
                      </div>
                    </Segment>

                    <div style={{ textAlign: "center", padding: "20px" }}>
                      <Button
                        color="green"
                        className="uploadSubmit col-md-2"
                        circular
                        size="small"
                        type="button"
                        onClick={handleInputSubmit}
                      >
                        Ajukan
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          style={{ borderRadius: "30px" }}
          show={this.state.showModalPengajuanViewDetail}
          animation={false}
        >
          <Modal.Header>
            <Header
              id="fonts"
              as="h3"
              color="blue"
              className="col-md-11"
              style={{
                textAlign: "left",
                marginTop: "5px",
                marginBottom: "5px",
              }}
            >
              Detail Pengajuan Pendanaan
            </Header>
            <button
              type="button"
              class="close"
              onClick={this.handleCloseModalPengajuanViewDetail}
            >
              &times;
            </button>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row className="justify-content-md-center">
                <Col xs md="12">
                  <Segment>
                    <Header
                      id="fonts"
                      as="h5"
                      color="blue"
                      className="col-md-11"
                    >
                      Data Bisnis
                      {this.state.items.status == 1 ? (
                        <Button
                          className="mini"
                          basic
                          color="blue"
                          style={{ marginLeft: "20px" }}
                          onClick={this.handleShowModalEditData}
                        >
                          <Icon name="edit" />
                          Edit
                        </Button>
                      ) : null}
                    </Header>

                    <Row>
                      <Col xs md="3">
                        Nama Bisnis
                      </Col>
                      <Col xs md="9">
                        {this.state.items.nama_project}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs md="3">
                        Kategori Bisnis
                      </Col>
                      <Col xs md="9">
                        {this.state.items.category}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs md="3">
                        Tipe Efek
                      </Col>
                      <Col xs md="9">
                        {this.state.items.type_efek_text}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs md="3">
                        Deskripsi
                      </Col>
                      <Col xs md="9">
                        {this.state.items.deskripsi}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs md="3">
                        Nilai Bisnis
                      </Col>
                      <Col xs md="9">
                        {this.state.items.modal}
                      </Col>
                    </Row>
                  </Segment>

                  <Segment>
                    <Header
                      id="fonts"
                      as="h5"
                      color="blue"
                      className="col-md-11"
                    >
                      Proposal
                      {this.state.items.status == 1 ? (
                        <Button
                          className="mini"
                          basic
                          color="blue"
                          style={{ marginLeft: "20px" }}
                          onClick={this.handleShowModalEditProposal}
                        >
                          <Icon name="edit" />
                          Edit
                        </Button>
                      ) : null}
                    </Header>

                    <Row>
                      <Col xs md="3">
                        Proposal Bisnis
                      </Col>
                      <Col xs md="9">
                        <a
                          href={this.state.items.proposal.nama_file}
                          target="_blank"
                        >
                          Tampilkan
                        </a>
                      </Col>
                    </Row>
                  </Segment>

                  <Segment>
                    <Header
                      id="fonts"
                      as="h5"
                      color="blue"
                      className="col-md-11"
                    >
                      Gambar Bisnis
                    </Header>

                    <Col xs md="12">
                      <Grid>
                        <Grid.Row columns={5}>
                          {this.state.items.gambar.map((item, index) => (
                            <Grid.Column>
                              {item.nama_file == "" ||
                              item.nama_file == null ? (
                                <Image src={empty} />
                              ) : (
                                <Image
                                  src={item.nama_file}
                                  width="150"
                                  height="80"
                                />
                              )}
                              {this.state.items.status == 1 ? (
                                <Button
                                  className="mini"
                                  basic
                                  color="blue"
                                  style={{ marginTop: "5px", width: "100%" }}
                                  onClick={() =>
                                    this.handleShowModalEditGambar(
                                      index + 1,
                                      item.id_project_data
                                    )
                                  }
                                >
                                  <Icon name="edit" />
                                  Edit
                                </Button>
                              ) : null}
                            </Grid.Column>
                          ))}
                        </Grid.Row>
                      </Grid>
                    </Col>
                  </Segment>

                  <div style={{ textAlign: "center", padding: "20px" }}>
                    <Button
                      color="blue"
                      className="uploadSubmit col-md-2"
                      circular
                      size="small"
                      type="button"
                      onClick={this.handleCloseModalPengajuanViewDetail}
                    >
                      Keluar
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          style={{ borderRadius: "30px" }}
          show={this.state.showModalEditData}
          animation={false}
        >
          <Modal.Header>
            <Header
              id="fonts"
              as="h3"
              color="blue"
              className="col-md-11"
              style={{
                textAlign: "left",
                marginTop: "5px",
                marginBottom: "5px",
              }}
            >
              <Icon
                name="angle left"
                style={{ cursor: "pointer" }}
                onClick={this.handleCloseModalEditData}
              />
              Edit Data Pengajuan
            </Header>
          </Modal.Header>
          <Modal.Body>
            <Form
              id="fonts"
              name="formEditData"
              onSubmit={(e) => e.preventDefault()}
              encType="multipart/form-data"
              style={{ marginTop: "10px" }}
            >
              <Container>
                <Row className="justify-content-md-center">
                  <Col xs md="12">
                    <Segment>
                      <Header
                        id="fonts"
                        as="h5"
                        color="blue"
                        className="col-md-11"
                      >
                        Data Bisnis
                      </Header>

                      <Form.Field>
                        Nama bisnis
                        <input
                          placeholder="Nama Bisnis"
                          className="form-control"
                          name="nama_project"
                          value={this.state.nama_project}
                          onChange={handleTextChange}
                        />
                        <i style={{ fontSize: "11px" }}>
                          <span style={{ color: "red" }}>*</span>Nama bisnis
                          samakan dengan nama bisnis di proposal
                        </i>
                      </Form.Field>
                      <Form.Field className="mrg10">
                        Kategori Bisnis
                        <Dropdown
                          name="type_project"
                          value={this.state.type_project}
                          className="form-control"
                          onChange={(e, { value }) => {
                            this.setState({ type_project: value });
                            // setErrorMessage(errorMessage => ({ ...errorMessage, ['province_other']: "" }))
                            // props.getDataDistrict(2, form.province_other)
                          }}
                          placeholder="Kategori Bisnis"
                          style={{ width: "100%" }}
                          closeOnChange
                          search
                          selection
                          options={this.props.dataCategory.map((item) => ({
                            key: item.category_id,
                            text: item.category,
                            value: item.category_id,
                          }))}
                        />
                      </Form.Field>
                      <Form.Field className="mrg10">
                        Tipe Efek
                        <Dropdown
                          name="type_efek"
                          value={this.state.type_efek}
                          className="form-control"
                          onChange={(e, { value }) => {
                            this.setState({ type_efek: value });
                            // setErrorMessage(errorMessage => ({ ...errorMessage, ['province_other']: "" }))
                            // props.getDataDistrict(2, form.province_other)
                          }}
                          placeholder="Tipe Efek"
                          style={{ width: "100%" }}
                          closeOnChange
                          search
                          selection
                          options={optionsEfek}
                        />
                      </Form.Field>
                      <Form.Field className="mrg10">
                        Deskripsi Bisnis
                        <TextArea
                          placeholder="Deskripsi ..."
                          rows="5"
                          name="deskripsi"
                          value={this.state.deskripsi}
                          onChange={handleTextChange}
                        />
                      </Form.Field>
                      <Form.Field className="mrg10">
                        Nilai Bisnis
                        <Input
                          placeholder="Rp."
                          name="nilai_proyek"
                          type="text"
                          value={this.price()}
                          onBlur={this.handleBlur}
                          onFocus={this.handleFocus}
                          onChange={this.handleTextChangePrice}
                        />
                      </Form.Field>
                    </Segment>

                    <div style={{ textAlign: "center", padding: "20px" }}>
                      <Button
                        color="green"
                        className="uploadSubmit col-md-2"
                        circular
                        size="small"
                        type="button"
                        onClick={handleSubmitEditData}
                      >
                        Simpan
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          style={{ borderRadius: "30px" }}
          show={this.state.showModalEditProposal}
          animation={false}
        >
          <Modal.Header>
            <Header
              id="fonts"
              as="h3"
              color="blue"
              className="col-md-11"
              style={{
                textAlign: "left",
                marginTop: "5px",
                marginBottom: "5px",
              }}
            >
              <Icon
                name="angle left"
                style={{ cursor: "pointer" }}
                onClick={this.handleCloseModalEditProposal}
              />
              Edit Proposal Pengajuan
            </Header>
          </Modal.Header>
          <Modal.Body>
            <Form
              id="fonts"
              name="formEditProposal"
              onSubmit={(e) => e.preventDefault()}
              encType="multipart/form-data"
              style={{ marginTop: "10px" }}
            >
              <Container>
                <Row className="justify-content-md-center">
                  <Col xs md="12">
                    <Segment>
                      <Header
                        id="fonts"
                        as="h5"
                        color="blue"
                        className="col-md-11"
                      >
                        Proposal Bisnis
                      </Header>

                      <Form.Group
                        style={{ fontSize: "11px", paddingLeft: "7px" }}
                        className="mrg20"
                      >
                        <Button
                          color="blue"
                          style={{ fontSize: "12px" }}
                          href={templateproposal}
                          target="_blank"
                        >
                          Download Panduan Proposal
                        </Button>
                        <input
                          type="file"
                          name="proposal"
                          className="inputfile"
                          id="embedpollfileinputProposal"
                          onChange={(event) => {
                            this.onFileChangeEdit(event);
                          }}
                          accept={"application/pdf"}
                        />
                        <label
                          htmlFor="embedpollfileinputProposal"
                          className="ui small blue right floated button"
                        >
                          {gambarProposal}
                        </label>
                      </Form.Group>
                      <div style={{ fontSize: "11px" }} className="mrg10">
                        <span style={{ color: "red" }}>*</span>File proposal
                        harus berupa format PDF, ukuran file tidak boleh
                        melebihi 1MB <br />
                        <span style={{ color: "red" }}>*</span>Pastikan proposal
                        yang anda unggah berdasarkan template proposal yang kami
                        sediakan
                      </div>
                    </Segment>

                    <div style={{ textAlign: "center", padding: "20px" }}>
                      <Button
                        color="green"
                        className="uploadSubmit col-md-2"
                        circular
                        size="small"
                        type="button"
                        onClick={handleSubmitEditProposal}
                      >
                        Simpan
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          style={{ borderRadius: "30px" }}
          show={this.state.showModalEditGambar}
          animation={false}
        >
          <Modal.Header>
            <Header
              id="fonts"
              as="h3"
              color="blue"
              className="col-md-11"
              style={{
                textAlign: "left",
                marginTop: "5px",
                marginBottom: "5px",
              }}
            >
              <Icon
                name="angle left"
                style={{ cursor: "pointer" }}
                onClick={this.handleCloseModalEditGambar}
              />
              Edit Gambar
            </Header>
          </Modal.Header>
          <Modal.Body>
            <Form
              id="fonts"
              name="formEditGambar"
              onSubmit={(e) => e.preventDefault()}
              encType="multipart/form-data"
              style={{ marginTop: "10px" }}
            >
              <Container>
                <Row className="justify-content-md-center">
                  <Col xs md="12">
                    <Segment>
                      <Header
                        id="fonts"
                        as="h5"
                        color="blue"
                        className="col-md-11"
                      >
                        Gambar Bisnis {this.state.fileTo}
                      </Header>

                      {this.state.image == "" || this.state.image == null ? (
                        <Image src={empty} size="small" />
                      ) : (
                        <Image src={this.state.image} size="small" />
                      )}
                      <p />
                      <Input
                        className="imgfile"
                        type="file"
                        name="gambarProyekEdit"
                        onChange={(event) => {
                          this.onFileChangeEdit(event);
                        }}
                        accept={"image/jpg, image/jpeg, image/png"}
                      />

                      <div style={{ fontSize: "11px" }} className="mrg20">
                        <span style={{ color: "red" }}>*</span>Ukuran gambar
                        maximal 500x400 pixel File gambar harus berupa format
                        JPEG, PNG, atau GIF <br />
                        <span style={{ color: "red" }}>*</span>Gambar tidak
                        boleh melebihi 1MB
                      </div>
                    </Segment>

                    <div style={{ textAlign: "center", padding: "20px" }}>
                      <Button
                        color="green"
                        className="uploadSubmit col-md-2"
                        circular
                        size="small"
                        type="button"
                        onClick={handleSubmitEditGambar}
                      >
                        Simpan
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Pengajuan;
