import { api, fetchApi } from "../service/api";

export const getUserpengelola = (payload) => {
    return async (dispatch) => {

        try {
            dispatch({
                type: "GET_USER_LOADING"
            });
            const response = await fetchApi("/customer/pengelola/users", "GET", null, payload.token);
            // console.log(response);
            if (response.responseBody.success) {
                var txe = JSON.stringify(response.responseBody)
                localStorage.setItem('DataUser', txe);
                dispatch({
                    type: "GET_USER_PENGELOLA_SUCCESS",
                    payload: response.responseBody
                });
                return response;
            } else {
                throw response;
            }

        } catch (error) {
            dispatch({
                type: "GET_USER_FAIL",
                payload: error.responseBody
            });
            return error;
        }
    }
}

export const updateUserpengelola = (payload) => {
    return async (dispatch) => {

         try {
            // console.log(payload);
            dispatch({
                type: "GET_USER_LOADING"
            });
            const response = await fetchApi("/customer/pengelola/users", "PUT",payload,localStorage.token);
            
            if (response.success) {
                dispatch({
                    type: "GET_USER_PEMODAL_SUCCESS",
                    payload: response.responseBody.body
                });
                return response;
            } else {
                throw response;
            }

        } catch (error) {
            dispatch({
                type: "GET_USER_PEMODAL_FAIL",
                payload: error.responseBody.body
            });
            return error;
        }
    }
}

export const updatePasspengelola = (payload) => {
    return async (dispatch) => {

         try {
            // console.log(payload);
            dispatch({
                type: "GET_USER_LOADING"
            });
            const response = await fetchApi("/customer/pengelola/update_password", "POST", payload, localStorage.token);
            
            if (response.responseBody.success) {
                dispatch({
                    type: "GET_USER_PEMODAL_SUCCESS",
                    payload: response.responseBody.body
                });
                return response;
            } else {
                throw response;
            }

        } catch (error) {
            dispatch({
                type: "GET_USER_PEMODAL_FAIL",
                payload: error.responseBody.body
            });
            return error;
        }
    }
}

export const verifikasiUser = (payload) => {
    return async (dispatch) => {

        try {
            dispatch({
                type: "GET_USER_LOADING"
            });
            const response = await fetchApi("/User/Verifikasi", "POST", payload, 200);
            if (response.success) {
                dispatch({
                    type: "GET_USER_PENGELOLA_SUCCESS",
                    payload: response.responseBody
                });
                return response;
            } else {
                throw response;
            }

        } catch (error) {
            dispatch({
                type: "GET_USER_FAIL",
                payload: error.responseBody
            });
            return error;
        }
    }
}
