import React from 'react'
import { Container, Row, Col, Tabs, Tab, Modal as ModalForm } from 'react-bootstrap'
import seru2 from "../../../assets/img/Group 895.png";
import {  Button, Icon } from 'semantic-ui-react'

class Informasi extends React.Component  {

    constructor(props){
        super(props);
        
    }

    render() {

        let userinfo = this.props.userinfo

        return(
            <>
            {userinfo.is_complete == 5 && userinfo.status_user < 3 ? 
                <Container>
                    <Row className="justify-content-md-center mInformasiAwal">
                        <Col xs md="12">
                            <div className="attention p-4">
                                <div className="info d-flex">
                                    <div className="icon"><img src={seru2} alt="" /></div>
                                    <div className="isi text-muted ml-5">
                                        <h5 id="fonts" >Informasi</h5>
                                        <p id="fonts" className="">
                                            Data anda sedang diverifikasi dalam waktu 1x24 jam. {this.props.userinfo.dataIsComplete}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            :(userinfo.is_complete < 5 || userinfo.is_complete == 5) && userinfo.status_user == 5 ?
                <Container>
                    <Row className="justify-content-md-center mInformasiAwal">
                        <Col xs md="12">
                            <div className="attention p-4">
                                <div className="info d-flex">
                                    <Icon name='remove circle' color='red' style={{ fontSize: '45px' }} />
                                    <div className="isi text-muted ml-3">
                                        <h5 id="fonts" >Informasi</h5>
                                        <p id="fonts" className="">
                                            Akun anda ditolak! Silahkan menghubungi admin Kerjasama.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            : userinfo.is_complete < 5 && userinfo.status_user < 3 ?
                <Container>
                    <Row className="justify-content-md-center mInformasiAwal">
                        <Col xs md="12">
                            <div className="attention p-4">
                                <div className="info d-flex">
                                    <div className="icon"><img src={seru2} alt="" /></div>
                                    <div className="isi text-muted ml-3">
                                        <h5 id="fonts" >Informasi</h5>
                                        <p id="fonts" className="">
                                            <a href={'/Profilepenerbit'}>Lengkapi data profil</a> terlebih dahulu untuk mulai mengajukan pendanaan. Terima Kasih
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            : userinfo.is_complete == 5 && userinfo.status_user == 3 && userinfo.agreement != 1 ?
                <>
                    <Container>
                        <Row className="justify-content-md-center mInformasiAwal">
                            <Col xs md="12">
                                <div className="attention p-4">
                                    <div className="info d-flex">
                                        <div className="icon"><img src={seru2} alt="" /></div>
                                        <div className="isi text-muted ml-5">
                                            <h5 id="fonts" >Informasi</h5>
                                            <p id="fonts" className="">
                                                Anda wajib menandatangani Perjanjian Penerbit secara digital, untuk dapat melakukan transaksi investasi.
                                            </p>
                                            <p><Button color='blue' onClick={this.props.handleShowModalAgreement}>Mulai tandatangan</Button></p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </>
            : null
            }
            </>
        )
    }
}

export default Informasi;