import React,{useState} from 'react';
import "../../App.scss";
import {connect} from "react-redux";
import {compose} from "redux";
import { Player } from 'video-react';
import { BallBeat } from 'react-pure-loaders';
//import Logo from "../../assets/img/logo_kerja.svg"
import Logo from "../../assets/img/kerjasama.co.id.vertical.png"
import Waktu from "../../assets/img/waktu3.svg";
import Invest from "../../assets/img/invesment2.svg";
import icon4v1 from "../../assets/img/how-work-icon4-v1.svg";
import icon5v1 from "../../assets/img/how-work-icon5-v1.svg";
import icon6v1 from "../../assets/img/how-work-icon6-v1.svg";
import Arrow from "../../assets/img/icon-arrow-kerja.svg";
import Project1 from "../../assets/img/project1.svg";
import Halal from "../../assets/img/halal.svg";
import Memberdayakan from "../../assets/img/memberdayakan.svg";
import Amanah from "../../assets/img/amanah.svg";
import Transparan from "../../assets/img/transparan.svg";
import Slider from "react-animated-slider";
import Navbar from "../../components/navbar_home";
import Footer from "../../components/Footer";
import Api from "../../service/Api_selesai";
import ApiProyek from "../../service/ApiProyek";
import { FaWhatsapp } from "react-icons/fa";
import {getProject} from '../../actions/frontend.action';
import "react-animated-slider/build/horizontal.css";
import { Picture } from 'react-responsive-picture';
import Carousel from 'react-bootstrap/Carousel'
import slide1 from '../../assets/img/slider/Slider 23-02.png';
import slide2 from '../../assets/img/slider/Slider 2-02.png';
import slide3 from '../../assets/img/slider/Slider 23-02.png';

import {Card, Button, Container, Col, Row, body, Image} from 'react-bootstrap';
import { Tab, Divider } from 'semantic-ui-react';
import {loginUser} from "../../actions/auth.actions";


const slideres = [
  {
    image: require('../../assets/img/slider/Slider 23-02.png'),
    head: `Kerjasama.co.id`,
    description: `Platform yang mempertemukan penerbit usaha dengan pemodal dalam kerjasama usaha sesuai syariat islam dengan menekankan kepada etika, keadilan dan kesetaraan.`,
    modal : `#Modal Minimal 1 Jt `,
    untung: `#Imbas Hasil untung Hingga 20% Per Tahun`
  },
  {
    image: require('../../assets/img/slider/Slider 2-02.png'),
    head: " Pemodal",
    description: `Solusi bagi pemilik modal yang membutuhkan usaha untuk memperoleh keuntungan, dapat tumbuh dan berkembang serta berkontribusi terhadap perekonomian syariah di Indonesia.`
  },
  {
    image: require('../../assets/img/slider/Slider 1-02.png'),
    head: "Penerbit",
    description: `
    Solusi bagi penerbit usaha yang membutuhkan modal untuk mengembangkan usahanya dengan skema yang adil dan saling menguntungkan.`
  }
];

class Home extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoading: true,
      data:[]
    };
  }
  
  componentDidMount() {
    this.setState({isLoading:true});
    fetch(Api )
  .then(response => response.json())
  .then(data => this.setState({ data: data.data, isLoading:false }))
  .catch(error => this.setState({ error, isLoading: false }));
}
  
  // projectGet = async (values) => {
    // try {
      //     const response = await this.props.dispatch(getProject(values));
      //     if (response.status == 'failed') {
        //         console.log(response.message);
        //         throw response.message;
        //     } else {
          //         this.setState({ errors: null,items:response.data});
          //     }
          // } catch (error) {
            //     console.log(error);
            // }
          // }
          
        



	render() {
    
		return(
      <div>
          <BallBeat
          color={'#123abc'}
          loading={this.isLoading}
          />
          <Navbar />
        
          <Header/>
          <Pendanaan  />
          <Proyek />
          <Agency />
          <Cara_Kerja/>
          <Kerjasama />
          <Ayat />
          <Blog />
          <Partner />
          <Lokasi />
         
          <Footer />
      </div>
		);
	}
  
}



function Header() {
  return (
  <div>
  <Carousel> 
    <Carousel.Item className="Slide1">
    <Image className="d-block w-100" src={slide1} alt="First slide"
    id="hed"/>

      <Carousel.Caption>
        <div>
          <div className="text-left" style={{borderLeft:"8px solid #eee",padding:"5px 20px"}}>
            <h2 className="titleSlide1">KERJASAMA.CO.ID</h2>
            <h4 className="descSlide1">Platform yang mempertemukan <span className="font-weight-bold"> penerbit usaha</span> <br/> dengan <span className="font-weight-bold">pemodal</span> dalam kerjasama usaha sesuai <br/> syariat islam dengan menekankan kepada etika, <br/> keadilan dan kesetaraan</h4>
            <div className="footSlide1"><i>#Modal Minimal 1 Jt</i> <br/> <i>#Imbas Hasil untung Hingga 20% Per Tahun</i></div>
          </div>
        </div>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item className="Slide2">
      <img
        className="d-block w-100"
        src={slide2}
        alt="Second slide"
        id="hed"
      />
  
      <Carousel.Caption>
      <div>
          <div className="text-right" style={{borderRight:"8px solid #fff",padding:"5px 20px"}}>
            <h2 className="titleSlide2">PEMODAL</h2>
            <h4 className="descSlide2">Solusi bagi pemilik modal yang membutuhkan usaha <br/> untuk memperoleh keuntungan, dapat tumbuh dan <br/> berkembang serta berkontribusi terhadap <br/> perekonomian syariah di Indonesia.</h4>
            <a href="#" className="footSlide2" >Lihat Selengkapnya...</a>

          </div>
        </div>
     
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item className="Slide3">
      <img
        className="d-block w-100"
        src={slide3}
        alt="Third slide"
        id="hed"
      />
  
      <Carousel.Caption>
      <div>
          <div className="text-left" style={{borderLeft:"8px solid #fff",padding:"5px 20px"}}>
            <h2 className="titleSlide3" >PENERBIT</h2>
            <h4 className="descSlide3">Solusi bagi penerbit usaha yang membutuhkan <br/> modal untuk mengembangkan usahanya dengan <br/> skema yang adil dan saling menguntungkan.</h4>
            <a href="#" className="footSlide3">Lihat Selengkapnya...</a>

          </div>
        </div>
      </Carousel.Caption>
    </Carousel.Item>
  </Carousel>
  
  </div>
  );
  }


function Pendanaan() { 
const GetData = document.querySelector("#promo-kun");

  return (
    <section className="pendanaan" id="pendanaan">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-6 col-md-8">
            <h3>Pendanaan</h3>
            <div className="content-kun" id="content-kun">
              <ul className="nav nav-fill justify-content-center ">
                <li className="nav-item ">
                  <a href="/" className="btn nav-ling" id="semua">
                    Semua
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#pendanaan" id="populer" className="btn nav-ling">
                    Terpopuler
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#pendanaan" className="btn nav-ling" id="terbaru">
                    Terbaru
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#pendanaan" className="btn nav-ling">
                    Akan Berakhir
                  </a>
                </li>
                <li className="nav-item active">
                  <a href="home/selesai" className="btn nav-ling">
                    Selesai
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

       <div className="row promo-kun justify-content-center" id="promo-kun"> 
         <div className="   ">
          
             <Api/>
         </div>

        
         
           
         </div>
          
        </div>
     
    </section>
  );
 }
  



function Proyek() {
  return (
    
    <div className="proyek" style={{background:"fff"}} >
        <div className="proyek text-center" style={{background:"#fff"}}>
          <h2>proyek yang sedang berjalan</h2>
        </div>
          <ApiProyek/>
   </div>
  );
}


function Agency() {
  return (
    <section className="agency">
      <div className="container ">
        <div className="row text-center">
          <div className="col-lg">
            <h4>Mengapa kerjasama.co.id ??</h4>
          </div>
        </div>
        <div className="row text-center">
          <div className="col-md-3 col-sm-3">
            <img src={Halal} className="img-fluid" alt="" />
            <div className="halal" />
            <h5>Halal</h5>
            <div className="isi-body">
              Insya Allah akad sesuai <br /> syariah islam tanpa unsur <br />{" "}
              Riba, Gharar, Masyir,
              <br /> Tadlis, Dharar, Zhulm, dan <br />
              Haram.
            </div>
          </div>
          <div className="col-md-3 col-sm-3">
            <img src={Amanah} className="img-fluid" alt="" />
            <div className="amanah">
              <h5>Amanah</h5>
              <div className="isi-body">
                Insya Allah Bisnis nyata <br /> dalam Kegiatan <br />{" "}
                Perekonomian riil yang
                <br /> dikelola secara jujur dan <br />
                dapat di percaya.
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-3">
            <img src={Transparan} className="img-fluid" alt="" />
            <div className="transparan">
              <h5>Transparan</h5>
              <div className="isi-body">
                Insya Allah Bisnis nyata <br /> yang dijalankan dengan <br />{" "}
                prinsip keterbukaan dan <br />
                dapat dipertanggung <br />
                jawabkan.
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-3">
            <img src={Memberdayakan} className="img-fluid" alt="" />
            <div className="memberdayakan">
              <h5>Memberdayakan</h5>
              <div className="isi-body">
                Insya Allah memberikan <br /> manfaat dan <br /> kesejahteraan
                bagi <br /> seluruh lapisan
                <br /> masyarakat
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function Cara_Kerja(){
  const panes = [
    {
      menuItem: 'Menjadi Pemodal',
      render: () => <Tab.Pane attached={false}><Tab1CaraKerja/></Tab.Pane>,
    },
    {
      menuItem: 'Menjadi Penerbit',
      render: () => <Tab.Pane attached={false}><Tab2CaraKerja/></Tab.Pane>,
    },
    // {
    //   menuItem: 'Simulasi',
    //   render: () => <Tab.Pane disabled attached={false}></Tab.Pane>,
    // },
  ]

  return (
    <section className="cara-kerja">
      <div className="container">
          <div className="row text-center">
              <div className="col-lg">
                <h4 className="title-kun"> Cara kerja</h4>
                  <Tab className='tabCaraKerja' menu={{ color:'blue', secondary: true, pointing: true, "renderActiveOnly": true, }} panes={panes} />
              </div>
          </div>
      </div>
    </section>
  );
}

function Tab1CaraKerja() {
  return (
    <div className="row conten-tab justify-content-center">
              <div className="col-lg-4 col-sm-4 col-md-4">
                  <div className="content-gambar"> <img src={Project1} className="img-fluid" alt=""/>
                      <div className="content-body justify-content-center">
                          <h5  className="text-center">Temukan Pembiayaan <br /> Proyek Pilihan Anda</h5>
                          <img src={Arrow} className="img-fluid" id="arrow-kerja" width="20%" alt=""/>
                          <ul id="testi">
                              <li>
                                  Telusuri Website kami dan temukan berbagai kampanye pembiayaan proyek yang
                                  sudah
                                  terseleksi.
                              </li>
                              <li className="mt-4">
                                  Kesempatan Membiayai proyek-proyek pilihan Anda mulai dari Rp 1.000.000, dengan
                                  imbalan hasil rata-rata tiap proyect mencapai 15% pertahun
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
              <div className="col-lg-4 col-sm-4 col-md-4"><img src={Invest} className="img-fluid" alt=""/>
                  <div className="content-body">
                      <h5 className="text-center">Danai Proyek Anda</h5> <br/>
                      <img src={Arrow} className="img-fluid" id="arrow-kerja" width="20%" alt=""/>
                      <ul id="testi">
                          <li>
                              Tentukan Jumlah Pembiayaan yang ingin anda berikan, Transfer dana dan tanda tangan
                              akad-akad Syariah secara digital.
                          </li>
                          <li className="mt-4">
                              pembayaran pembiayaan proyek pilihan anda dilakukan melalui Escrow Acount dan
                              Virtual
                              Account untuk menjamin keamanan dan kemudahan transaksi secara digital.
                          </li>
                      </ul>
                  </div>
              </div>
              <div className="col-lg-4 col-sm-4"><img src={Waktu} className="img-fluid" alt=""/>
                  <div className="content-body">
                      <h5  className="text-center">Dapatkan Laporan dan <br/> Imbal Hasil</h5>
                      <ul id="testi">
                          <li>
                              Rasakan kemudahan mendapatkan laporan Perkembangan proyect yang Anda biayai, bahkan
                              cukup melalui ketuk jari pada perangkat favorit Anda
                          </li>

                          <li className="mt-4">
                              Berdaya dan tumbuh bersama Penerbit Proyek pilihan Anda dalam sinergi kebaikan,
                              dapatkan imbal hasil setelah masa tenor pembiayaan selesai
                          </li>
                      </ul>
                  </div>
              </div>
          </div>
  )
}

function Tab2CaraKerja() {
  return (
    <div className="row conten-tab justify-content-center">
      <div className="col-lg-4 col-sm-4 col-md-4">
          <div className="content-gambar"> <img src={icon4v1} className="img-fluid" alt=""/>
              <div className="content-body justify-content-center">
                  <h5  className="text-center">Penuhi Persyaratan <br/> Utama</h5>
                  <img src={Arrow} className="img-fluid" id="arrow-kerja" width="20%" alt=""/>
                  <ul id="testi">
                      <li>
                      Bukan usaha baru dan sudah memiliki pengalaman di bidangnya
                      </li>
                      <li className="mt-4">
                      Memiliki rencana yang jelas dan bisa di pertanggung jawabkan
                      </li>
                      <li className="mt-4">
                      Memiliki nilai ekonomis yang tinggi dengan risiko minimal
                      </li>
                  </ul>
              </div>
          </div>
      </div>
      <div className="col-lg-4 col-sm-4 col-md-4"><img src={icon5v1} className="img-fluid" alt=""/>
          <div className="content-body">
              <h5 className="text-center">Uji Kelayakan</h5> <br/>
              <img src={Arrow} className="img-fluid" id="arrow-kerja" width="20%" alt=""/>
              <ul id="testi">
                  <li>
                  Pengumpulan data dan verifikasi informasi mengenai proyek.
                  </li>
                  <li className="mt-4">
                  Uji kelayakan berdasarkan sistem scoring Kerjasama.co.id.
                  </li>
              </ul>
          </div>
      </div>
      <div className="col-lg-4 col-sm-4"><img src={icon6v1} className="img-fluid" alt=""/>
          <div className="content-body">
              <h5  className="text-center">Kampanye Pembiayaan</h5>
              <ul id="testi">
                  <li>
                    Lakukan tanda tangan digital untuk akad Wakalah.
                  </li>
                  <li className="mt-4">
                    Kampanye pembiayaan proyek dirilis di website Kerjasama.co.id.
                  </li>
                  <li className="mt-4">
                    Setelah kampanye berhasil terpenuhi, lakukan tanda tangan digital sekali lagi untuk akad musyarakah/mudharabah dan dana akan segera ditransfer.
                  </li>
              </ul>
          </div>
      </div>
  </div>
  )
}

function Kerjasama() {
  return (
    <section className="kerjasama">
      <div className="container" id="kerjasama-yt">
        <div className="row">
         <div className="col-md-12 text-center">
            {/*<img
              src={Logo}
              id="join-kerjasama"
              className="img-fluid"              
	      alt=""
            />*/}
	    <img
              src={Logo}
              className="img-fluid"              
	      alt=""
	      width="150"
	      style={{margin:"40px"}}
            />

         </div>
        </div>
        <div className="row" id="row-kiri">
          <div className="col-md-6 col-lg-6 col-sm-12">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                className="embed-responsive-item"
                src="https://www.youtube.com/embed/Kg0EIIKUsSc"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className=" col-md-6 col-lg-6 col-sm-12" id="row-kanan">
            <h3>PT.Kerjasama untuk negeri (KUNN)</h3>
            <div className="isi">
              sebagai penyedia layanan portal crowdfunding syariah kerjasama.co.id
              merupakan perusahaan yang didirikan berdasarkan hukum Negara
              Kesatuan Republik Indonesia yang tercatat dalam regulatory sandbox
              Inovasi keuangan digital Otoritas Jasa Keuangan (OJK) dengan nomor
              tercatat :5-87/MS.72/2019, dalam cluster project financing.
              kerjasama.co.id menyatakan tunduk terhadap perundang-undangan dan
              peraturan hokum Negara Kesatuan Republik Indonesia. kerjasama.co.id
              adalah penyelenggara kerjasama antara penerbit usaha dan pemodal
              dengan menerapkan model bisnis sesuai syariat Islam menggunakan
              akad Musyarakah dan Mudharabah.
            </div>
          </div>
        </div>
        </div>
    </section>
  );
}

function Ayat() {
  return (
    <section className="ayat-alquran">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-sm-12" id="kiri">
            <img
              src="https://drive.google.com/uc?id=1iLHt5iJ3peL9-mo5fGtfD6qd7QUyW6fM"
              className="img-fluid"
              id="alquran"
              style={{backgroundSize:"cover",width:"70%"}}
              alt=""
            />
          </div>
          <div className="col-lg col-sm" id="kanan">
            <h3>Al-Baqarah Ayat 278</h3>
            <p>
              “Hai orang-orang yang beriman, bertakwalah kepada Allah dan
              tinggalkan sisa riba (yang belum dipungut ) jika kamu orang-orang
              yang beriman.“
            </p>

            <h3> Al-Baqarah Ayat 279 </h3>
            <p>
              {" "}
              “Maka jika kamu tidak mengerjakan (meninggalkan sisa riba), maka
              ketahuilah, bahwa Allah dan Rasul- Nya akan memerangimu. Dan jika
              kamu bertaubat (dari pengambilan riba), maka bagimu pokok hartamu;
              kamu tidak menganiaya dan tidak (pula) dianiaya.”{" "}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

function Blog() {
  return (
    <div className="blog">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-sm-12">
            <h4>Blog</h4>
          </div>
        </div>
        <div className="row" id="content-blog">
          <div className="col-sm-12 col-md-6 col-lg-6" id="blog-kiri">
            <div className="card">
              <div className="kun-item">
                <a href="!#" className="img">
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkIX6lRa47y1-ZiTqinwjX_DSRfhBuULlylR4rm-c-pYB-3c1Mzg&amp"
                    className="img-fluid"
                    width="500"
                    height="300"
                    alt=""
                  />
                </a>
              </div>
              <div className="kunblog-title">Properti Syariah Semakin Diminati</div>
              <div className="kunblog-body">
                Saat ini perkembangan teknologi semakin pesat, termasuk di
                bidang ekonomi. Salah satunya adalah Fintech atau financial
                technology yang perlahan masuk ke sistem keuangan syariah.
                Fintech syariah bisa menjadi solusi bijak dalam memanfaatkan
                teknologi pada perencanaan keuangan keluarga.Pada fintech
                syariah ketentuan yang dipakai harus berdasarkan ketentuan
                syariat islam, seperti pada akad, syarat, rukun,hukum,
                administrasi pajak, akuntansi hingga audit. Ada berbagai
                kelebihan yang bisa diambil dari fintech syariah dibandingkan
                dengan fintech konvensional diantaranya dasar-dasar yang dianut
                tidak ada sistem bunga atau riba juga akadnya
              </div>
            </div>
          </div>
          <div className=" col-md-6 col-lg-6" id="blog-kanan">
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="">
                  <img
                    src="https://i.ibb.co/Hp6ZGrs/blog-kanan.jpg"
                    className="card-img-top"
                    alt=""
                  />
                  <div className="icon-title">Pengertian Properti Syariah</div>
                  <div className="icon-body">
                    Properti syariah secara bahasa terdiri dari dua suku kata
                    yakni ‘property’ dan juga ‘syariah’. Properti adalah harta
                    yang dimiliki oleh seseorang atau kelompok yang mana ada hak
                    ekslusif. Adapun bentuk hartanya biasanya
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="">
                  <img
                    src="https://i.ibb.co/Hp6ZGrs/blog-kanan.jpg"
                    className="card-img-top"
                    alt=""
                  />
                  <div className="icon-body">
                    <div className="icon-title">
                      Jangan Salah Pilih, Ini kriteria property syariah
                    </div>
                    <div className="icon-body">
                      Dewan Syariah Nasional Majelis Ulama Indonesia (DSN MUI)
                      telah menetapkan kriteria yang membuat sebuah properti
                      (produk) layak dinyatakan sesuai syariah (halal). Kriteria
                      yang pertama adalah syaran, yaitu segala sesuatu
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="">
                  <img
                    src="https://i.ibb.co/88gGjTf/5-Prinsip-Dasar-Usaha-18.png"
                    className="card-img-top"
                    alt=""
                  />
                  <div className="icon-title">
                    5 Prinsip Dasar Usaha Properti Syariah
                  </div>
                  <p className="icon-body">
                    Dewan Syariah Nasional Majelis Ulama Indonesia (DSN MUI)
                    telah menetapkan kriteria yang membuat sebuah properti
                    (produk) layak dinyatakan sesuai syariah (halal). Kriteria
                    yang pertama adalah syaran, yaitu segala sesuatu
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="">
                  <img
                    src="https://img.okezone.com/content/2017/02/23/320/1626044/kontribusi-sektor-keuangan-diprediksi-meningkat-PRju7CvPS2.jpg"
                    className="card-img-top img-fluid"
                    alt=""
                  />
                  <div className="icon-title">
                    Digitalisasi Sektor Keuangan Melalui FinTech Syariah
                  </div>
                  <p className="icon-body">
                    Memasuki era industri 4.0 kita mengetahui bahwa telah
                    terjadi perubahan gaya hidup pada masyarakat. Perubahan dari
                    era konvensional menuju era digitalisasi. Digitalisasi ini
                    merambah ke semua bidang.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
   {/* <div className="container">
     <div className="row">
       <div className="col-sm-12">
       <div className="container text-center">
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <a className="page-link" href="!#">
                Previous
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="!#">
                1
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="!#">
                2
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="!#">
                3
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="!#">
                Next
              </a>
            </li>
          </ul>
        </nav>{" "}
      </div>
       </div>
     </div>
   </div>
   </div> */}
   </div>
  );
}

function Partner() {
  https://i.ibb.co/b6H4gxz/kerjasama-white.png
  return (
    <div className="jumbotron jumbotron-fluid">
      <div className="container">
        <div className="row partner ">
          <div className="col-sm-12 col-lg-12">
            <h4>Partner</h4>
          </div>
        </div>
        <div className="row logo">
          <div className="col-md-4 col-sm-4 col-lg-4 img-fluid">
            <img
              src={require('../../assets/img/partner/gkn-aerospace.png')}
              id="logo1"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-md-4 col-sm-4 col-lg-4 img-fluid">
            <img
              src={require('../../assets/img/partner/okeoce-logo.png')}
              width="250"
              id="logo2"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-md-4 col-sm-4 col-lg-4 img-fluid">
            <img
              src={require('../../assets/img/partner/tda-logo.png')}
              width="250"
              id="logo3"
              alt=""
              className="img-fluid"
            />
          </div>
        </div>{" "}
      </div>
    </div>
  );
}

function Lokasi() {
  return (
    <div className="hubungi-kami">
      <h4>Hubungi Kami</h4>
      <div className="frame">
        <div
          title="my-frame"
          className="embed-responsive embed-responsive-16by9"
        >
          <iframe
            className="embed-responsive-item"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d991.5653792362398!2d107.00038884286627!3d-6.229212719465191!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69912eb0bfb885%3A0x930ecd6b6cbdfdb0!2sKerjasama.com!5e0!3m2!1sid!2sid!4v1575441977593!5m2!1sid!2sid"
            width="100%"
            allowFullScreen
          />
        </div>
      </div>
    </div>
  );
}


function mapStateToProps(state) {
  return { loginUser: state.authReducer.loginUser }
}

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
