import React from 'react';
import { connect } from "react-redux";
import { compose } from "redux";
import { BallBeat } from 'react-pure-loaders';
import { Row, Col, Card, Button, Container, CardGroup, Alert, Image, Table, Badge, Form } from 'react-bootstrap';
import { verifikasiUser } from "../actions/userpemodal.actions";
import logo from "../assets/img/logo.png";
import avatar from "../assets/img/img_avatar.png";
import swal from 'sweetalert';

class VerifikasiData extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            fields: {
                user_jenis: "l"
            },
            imgSrc: {},
            errors: {}
        }
    }


    veriifikasi = async (values) => {
        try {
            const response = await this.props.dispatch(verifikasiUser(values));
            if (!response.success) {
                console.log(response);
            } else {
                this.setState({ errors: null });
            }
        } catch (error) {
            console.log(error);
        }
    }

    handleValidation() {
        let fields = this.state.fields;
        let imgSrc = this.state.imgSrc;
        let errors = {};
        let formIsValid = true;

        //Name
        if (!fields["user_nik"]) {
            formIsValid = false;
            errors["user_nik"] = "Cannot be empty";
        }

        //Email
        if (!fields["user_tempat_lahir"]) {
            formIsValid = false;
            errors["user_tempat_lahir"] = "Cannot be empty";
        }
        
        if (!fields["user_tgl_lahir"]) {
            formIsValid = false;
            errors["user_tgl_lahir"] = "Cannot be empty";
        }

        if (!fields["user_jenis"]) {
            formIsValid = false;
            errors["user_jenis"] = "Cannot be empty";
        }

        if (!fields["user_alamat"]) {
            formIsValid = false;
            errors["user_alamat"] = "Cannot be empty";
        }

        if (!fields["user_token"]) {
            formIsValid = false;
            errors["user_token"] = "Cannot be empty";
        }

        if (!imgSrc["user_ktp"]) {
            formIsValid = false;
            errors["user_ktp"] = "Cannot be empty";
        }


        if (!imgSrc["user_ktp_selfie"]) {
            formIsValid = false;
            errors["user_ktp_selfie"] = "Cannot be empty";
        }

        if (!imgSrc["user_npwp"]) {
            formIsValid = false;
            errors["user_npwp"] = "Cannot be empty";
        }



        this.setState({ errors: errors });
        return formIsValid;
    }

    onSubmit = (event) => {
        event.preventDefault();
        var data_post=this.state.fields;
        data_post.user_id=this.props.getUser.user.user_id
        data_post.user_ktp=this.state.imgSrc["user_ktp"]
        data_post.user_ktp_selfie=this.state.imgSrc["user_ktp_selfie"]
        data_post.user_npwp=this.state.imgSrc["user_npwp"]
        if (this.handleValidation()) {
            this.veriifikasi(data_post);
        } else {
            // alert("Form has errors.")
            swal({text: 'Form has errors', icon: 'error'})
        }

    }

    handleChange = (event) => {
        let fields = this.state.fields;
        if(event.currentTarget.getAttribute('name')=="user_jenis"){
            fields[event.currentTarget.getAttribute('name')] = this.refs.user_jenis.value;
            this.setState({ fields });
        }else{
            fields[event.currentTarget.getAttribute('name')] = event.target.value;
            this.setState({ fields });
        }
    }

    setShow = (event) => {
        event.target.setAttribute('show2', false)
    }

    onChangeImage = (event) =>{
        var name_objt = event.currentTarget.getAttribute('name');
        var file = this.refs[name_objt].files[0];
        if (file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/jpg'){
            let imgSrc = this.state.imgSrc;
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            
            reader.onloadend = function (e) {
                imgSrc[name_objt]=reader.result
                this.setState({ imgSrc });
            }.bind(this);
            
        }else{
            event.target.value='';
            // alert("file tidak didukung, format file harus jpg/jpeg/png")
            swal({text: 'File tidak didukung, format file harus jpg/jpeg/png', icon: 'error'})
        }
    // TODO: concat files
    }


    render() {

        return (
            <div style={{ margin: "20px" }}>
                <h3 className="text-center">Step 1 - Lengkapi Identitas</h3>
                <hr style={{border:"1px solid blue"}}></hr>
                <form onSubmit={this.onSubmit}>
                    <div style={{ padding: "28px" }}>
                        <Row>
                            <Col md="6">
                                <div className="form-group">
                                    <label htmlFor="user_nik">NIK</label>
                                    <input className="form-control form-control-rounded" name="user_nik" id="user_nik" type="number" onChange={this.handleChange} value={this.state.fields["user_nik"] || ''} />
                                    <span style={{ color: "red" }}>{this.state.errors["user_nik"]}</span>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="user_tempat_lahir">Tempat lahir</label>
                                    <input className="form-control form-control-rounded" name="user_tempat_lahir" id="user_tempat_lahir" type="text" onChange={this.handleChange} value={this.state.fields["user_tempat_lahir"] || ''} />
                                    <span style={{ color: "red" }}>{this.state.errors["user_tempat_lahir"]}</span>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="user_tgl_lahir">Tanggal lahir</label>
                                    <input className="form-control form-control-rounded" name="user_tgl_lahir" id="user_tgl_lahir" type="date" onChange={this.handleChange} value={this.state.fields["user_tgl_lahir"] || ''} />
                                    <span style={{ color: "red" }}>{this.state.errors["user_tgl_lahir"]}</span>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="user_jenis">Jenis Kelamin</label>
                                    <Form.Control as="select" ref="user_jenis" className="form-control form-control-rounded" name="user_jenis" id="user_jenis" onChange={this.handleChange}>
                                        <option value="l">Laki-Laki</option>
                                        <option value="p">Perempuan</option>
                                    </Form.Control>
                                    <span style={{ color: "red" }}>{this.state.errors["user_jenis"]}</span>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="user_ref">Alamat</label>
                                    <Form.Control as="textarea" rows="3" className="form-control form-control-rounded" onChange={this.handleChange} name="user_alamat" id="user_alamat" value={this.state.fields["user_alamat"] || ''}/>
                                    <span style={{ color: "red" }}>{this.state.errors["user_alamat"]}</span>
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="form-group">
                                    <label htmlFor="user_ktp">Upload foto ktp</label>
                                    <input
                                        ref="user_ktp"
                                        type="file"
                                        name="user_ktp"
                                        className="form-control"
                                        onChange={this.onChangeImage} />    
                                    <span style={{ color: "red" }}>{this.state.errors["user_ktp"]}</span>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="user_ktp_selfie">Upload foto selfie + bawa ktp</label>
                                    <input
                                        ref="user_ktp_selfie"
                                        type="file"
                                        name="user_ktp_selfie"
                                        className="form-control"
                                        onChange={this.onChangeImage} />
                                    <span style={{ color: "red" }}>{this.state.errors["user_ktp_selfie"]}</span>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="user_npwp">Upload NPWP pribadi</label>
                                    <input
                                        ref="user_npwp"
                                        type="file"
                                        name="user_npwp"
                                        className="form-control"
                                        onChange={this.onChangeImage}
                                        />
                                    <span style={{ color: "red" }}>{this.state.errors["user_npwp"]}</span>
                                </div>
                                <img style={{width:"130px", height:"130px", margin:"3px"}} src={this.state.imgSrc["user_ktp"] || ''} />
                                <img style={{width:"130px", height:"130px", margin:"3px"}} src={this.state.imgSrc["user_ktp_selfie"] || ''} />
                                <img style={{width:"130px", height:"130px", margin:"3px"}} src={this.state.imgSrc["user_npwp"] || ''} />
                                <div className="form-group">
                                    <label htmlFor="user_token">Kode verifikasi (<i>dikirim ke email</i>)</label>
                                    <input className="form-control form-control-rounded" name="user_token" id="user_token" type="number" onChange={this.handleChange} value={this.state.fields["user_token"] || ''} />
                                    <span style={{ color: "red" }}>{this.state.errors["user_token"]}</span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <br/><br/>
                    <Row>
                        <Col md="12" className="text-center">
                            <Button variant="info" type="submit" className="pull">Berikutnya</Button>
                        </Col>
                    </Row>

                </form>
            </div>
           
        )
    }

}




function mapStateToProps(state) {
    return { verifikasiUser: state.userReducer.verifikasiUser, getUser: state.userReducer.getUser }
}

function mapDispatchToProps(dispatch) {
    return { dispatch }
}



export default connect(mapStateToProps, mapDispatchToProps)(VerifikasiData);
