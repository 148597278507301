import React, { Component, memo, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import dompet from "../assets/img/dompet.png";
import $ from 'jquery'
import axios from 'axios'
import '../App.scss'
import { BASE_URL, tokenGet } from "../service/api";
import {Form as FormBootstrap} from 'react-bootstrap';
import { fetchApi } from '../service/api';
import {
    Button,
    Divider,
    Checkbox,
    Modal,
    Grid,
    Card,
    Header,
    Icon,
    Image,
    Menu,
    Segment,
    Sidebar,
    Input,
    Label,
    Message,
    List,
    Form,
} from 'semantic-ui-react'
import swal from 'sweetalert';

export default class DetailDompet extends Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            itemsDompet : [],
            open: false,
            open2 : false,
            openUbah : false,
            pin : '',
            confirmpin : '',
            oldpin : '',
            suksesInsertPin : false
        };
    }

    getDompet() {
        axios.get(BASE_URL+"/customer/dompet/detail/"+JSON.parse(this.props.userinfo).id,
        axios.defaults.headers.common['Authorization'] = localStorage.token,
        axios.defaults.headers.common['Accept']        = 'application/json'
        )
        .then((resp) => {
            if (resp.data.data) {
                this.setState({ 
                    itemsDompet : resp.data.data,
                })
            } else {
                // alert('Terjadi Kesalahan, Silahkan refresh browser');
                // return false;
            }
        })
        .catch((resp) =>{
            // alert('Terjadi Kesalahan, Silahkan refresh browser');
            // return false;
        });
    }

    componentDidMount() {
        this.getDompet();
    }

    show = (size) => () => {
        this.setState({ size, open: true, pin : '', confirmpin : '' })
        setTimeout(()=>{$("#pin").focus()}, 1);
    }

    showUbah = (size) => () => {
        this.setState({ size, openUbah: true, oldpin: '' })
        setTimeout(()=>{$("#oldpin").focus()}, 1);
    }

    close = () => this.setState({ open: false, open2 : false, openUbah: false })

    handleInputChange = e => {
        this.setState({ pin: e.target.value });
    };

    handleInputChangePin = e => {
        this.setState({ oldpin: e.target.value });
    };

    handleInputConfirmPin = e => {
        this.setState({ confirmpin: e.target.value });
    };

    submitOldPin = e => {
        if (this.state.oldpin.length < 6) {
            $(".errPin").attr('style','')
            setTimeout(()=>{$(".errPin").attr('style','display:none')}, 1000);
        } else {
            this.setState({ size : 'mini', open: true, openUbah: false, pin : '' })
            setTimeout(()=>{$("#pin").focus()}, 1);
        }
    }

    submitPin = e => {
        if (this.state.pin.length < 6) {
            $(".errPin").attr('style','')
            setTimeout(()=>{$(".errPin").attr('style','display:none')}, 1000);
        } else {
            this.setState({ size : 'mini', open2: true, open: false, confirmpin : '' })
            setTimeout(()=>{$("#confirmpin").focus()}, 1);
        }
    }

    submitConfirmPin = e => {
        if (this.state.confirmpin.length < 6) {
            $(".errKonfirmPin").attr('style','')
            setTimeout(()=>{$(".errKonfirmPin").attr('style','display:none')}, 1000);
        } else {
            if (this.state.oldpin) {
                if (this.state.pin === this.state.confirmpin) {
                    this.updatePin();
                } else {
                    $(".errPinTidakSama").attr('style','')
                    setTimeout(()=>{$(".errPinTidakSama").attr('style','display:none')}, 2000);
                }
            } else {
                if (this.state.pin === this.state.confirmpin) {
                    this.insertPin();
                } else {
                    $(".errPinTidakSama").attr('style','')
                    setTimeout(()=>{$(".errPinTidakSama").attr('style','display:none')}, 2000);
                }
            }
        }
    }

    insertPin () {
        var bodyFormData = new FormData();
        bodyFormData.set('pin', this.state.pin);
        bodyFormData.set('confirm_pin', this.state.confirmpin);

        axios({
            method: 'post',
            url: BASE_URL+"/customer/dompet/pin",
            data: bodyFormData,
            headers: {'Content-Type': 'multipart/form-data' }
        })
        .then((resp) => {
            this.setState({ suksesInsertPin: true })
            // alert(resp.data.message)
            // this.close()

            swal({
                text: resp.data.message, 
                icon: 'success', 
                closeOnClickOutside: false
            }).then((value) => {
                this.close()
            });
        })
        .catch((resp) =>{
            // alert('Terjadi Kesalahan, Cobalah beberapa saat lagi');
            // this.close()

            swal({
                text: 'Terjadi Kesalahan, Cobalah beberapa saat lagi', 
                icon: 'error', 
                closeOnClickOutside: false
            }).then((value) => {
                this.close()
            });
        });

    }

    updatePin () {
        var bodyFormData = new FormData();
        bodyFormData.set('old_pin', this.state.oldpin);
        bodyFormData.set('pin', this.state.pin);
        bodyFormData.set('confirm_pin', this.state.confirmpin);

        axios({
            method: 'post',
            url: BASE_URL+"/customer/dompet/update_pin",
            data: bodyFormData,
            headers: {'Content-Type': 'multipart/form-data' }
        })
        .then((resp) => {
            this.setState({ suksesInsertPin: true })
            // alert(resp.data.message)
            // this.close()

            swal({
                text: resp.data.message, 
                icon: 'success', 
                closeOnClickOutside: false
            }).then((value) => {
                this.close()
            });
        })
        .catch((resp) =>{
            // alert('Gagal update, Pin Lama tidak sesuai !');
            // this.close()

            swal({
                text: 'Gagal update, Pin Lama tidak sesuai !', 
                icon: 'error', 
                closeOnClickOutside: false
            }).then((value) => {
                this.close()
            });
        });

    }

    render() {

          const styleInput = {
            width: "75%",
          };
          const styleLabel = {
            width: "47%",
          };

        const userinfo = JSON.parse(this.props.userinfo)
        const itemsDompet = this.state.itemsDompet
        const { open, size, pin, open2, confirmpin, openUbah, oldpin} = this.state
        
        return (
            <div>
                { (userinfo.pin || this.state.suksesInsertPin == true) ? (
                <>
                    <Container>
                        <Row className="justify-content-md-center">
                            <Col xs md="12">
                                <Header  id="fonts" as='h3' color='blue' className='col-md-11'>
                                    Daftar Dompet
                                    <div style={{marginTop:"-23px",textAlign:'right',marginRight:'-60px'}}>
                                        <Button color='blue' size='mini' onClick={this.showUbah('mini')}>Ubah PIN</Button>
                                    </div>
                                    <Header.Subheader>Dompet</Header.Subheader>
                                </Header>
                                <Header id="fonts" as='h3' color='blue' style={{marginTop:'15px'}}>
                                    <Image src={dompet} size='mini' />
                                    <Header.Content   id="fonts" className='col-md-11'>
                                        Saldo
                                        <Header.Subheader>{itemsDompet.saldo} 
                                            <div style={{marginTop:"-30px",textAlign:'right',marginRight:'-47px'}}>
                                                <Button color='green' size='mini' style={{width:'82px',marginRight:'40px'}} >Top Up</Button>
                                                <Button color='blue' size='mini' style={{width:'82px'}} >Cairkan</Button>
                                            </div>
                                        </Header.Subheader>
                                    </Header.Content>
                                </Header>
                            <Divider section/>
                                <Header  id="fonts" as='h3' color='blue' className='col-md-11'>
                                    Data Rekening
                                </Header>
                                <Col xs='12' md='12'>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={8}>
                                                <div className="ui labeled input dataRekening">
                                                    <div  id="fonts" className="ui label label" style={styleLabel}>No Rekening</div>
                                                    <input type="text" readOnly value={userinfo.no_rek} style={styleInput}/>
                                                </div>
                                            </Grid.Column>
                                            <Grid.Column width={8}>
                                                <div className="ui labeled input dataRekening">
                                                    <div  id="fonts" className="ui label label" style={{width:'51%'}}>Cabang Bank</div>
                                                    <input type="text" readOnly value={userinfo.cabang_bank} style={{width:'75%'}}/>
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={8}>
                                                <div className="ui labeled input dataRekening">
                                                    <div  id="fonts" className="ui label label" style={{width:'49%'}}>Nama Bank</div>
                                                    <input type="text" readOnly value={userinfo.data_bank} style={{width:'77%'}}/>
                                                </div>
                                            </Grid.Column>
                                            <Grid.Column width={8}>
                                                <div className="ui labeled input dataRekening">
                                                    <div  id="fonts" className="ui label label" style={{width:'47%'}}>Pemilik Rekening</div>
                                                    <input type="text" readOnly value={userinfo.atas_nama_bank} style={{width:'70%'}}/>
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Col>
                                <Divider section/>
                                <Header  id="fonts" as='h3' color='blue' className='col-md-11'>
                                    Riwayat
                                </Header>
                                <List divided relaxed>
                                    <List.Item>
                                        <List.Content  id="fonts" floated='right'>
                                            &nbsp;
                                        </List.Content>
                                        <List.Content>
                                            <List.Header id="fonts"  as='h6'>&nbsp;</List.Header>
                                        </List.Content>
                                    </List.Item>
                                </List>
                            </Col>
                        </Row>
                    </Container>
                </>) : (
                    <>
                    <Container>
                        <Row className="justify-content-md-center">
                            <Col xs md="12">
                                <Header  id="fonts" as='h3' color='blue' className='col-md-11'>
                                    Daftar Dompet
                                    <div style={{marginTop:"-23px",textAlign:'right',marginRight:'-60px'}}>
                                        <Button color='blue' size='mini' onClick={this.show('mini')}>Buat PIN</Button>
                                    </div>
                                    <Header.Subheader>Dompet</Header.Subheader>
                                </Header>
                                <Header id="fonts" as='h3' color='blue' style={{marginTop:'15px'}}>
                                    <Image src={dompet} size='mini' />
                                    <Header.Content   id="fonts" className='col-md-11'>
                                        Saldo
                                        <Header.Subheader>{itemsDompet.saldo} 
                                            <div style={{marginTop:"-30px",textAlign:'right',marginRight:'-47px'}}>
                                                <Button disabled color='grey' size='mini' style={{width:'82px'}} >Cairkan</Button>
                                            </div>
                                        </Header.Subheader>
                                    </Header.Content>
                                </Header>
                            <Divider section/>
                                <Header  id="fonts" as='h3' color='blue' className='col-md-11'>
                                    Data Rekening
                                </Header>
                                <Col xs='12' md='12'>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={8}>
                                                <div className="ui labeled input dataRekening">
                                                    <div  id="fonts" className="ui label label" style={styleLabel}>No Rekening</div>
                                                    <input type="text" readOnly value={userinfo.no_rek} style={styleInput}/>
                                                </div>
                                            </Grid.Column>
                                            <Grid.Column width={8}>
                                                <div className="ui labeled input dataRekening">
                                                    <div  id="fonts" className="ui label label" style={{width:'51%'}}>Cabang Bank</div>
                                                    <input type="text" readOnly value={userinfo.cabang_bank} style={{width:'75%'}}/>
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={8}>
                                                <div className="ui labeled input dataRekening">
                                                    <div  id="fonts" className="ui label label" style={{width:'49%'}}>Nama Bank</div>
                                                    <input type="text" readOnly value={userinfo.data_bank} style={{width:'77%'}}/>
                                                </div>
                                            </Grid.Column>
                                            <Grid.Column width={8}>
                                                <div className="ui labeled input dataRekening">
                                                    <div  id="fonts" className="ui label label" style={{width:'47%'}}>Pemilik Rekening</div>
                                                    <input type="text" readOnly value={userinfo.atas_nama_bank} style={{width:'70%'}}/>
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Col>
                            </Col>
                        </Row>
                    </Container>
                    </>
                ) }

                <Modal className="modalPin" size={size} open={openUbah} onClose={this.close} closeIcon>
                    <Modal.Header>Masukkan PIN Lama Anda</Modal.Header>
                    <Modal.Content>
                        <Input type="password" id="oldpin" name="oldpin" className="inputPin" 
                        defaultValue={oldpin}
                        onChange={this.handleInputChangePin} maxLength="6" placeholder="______"/>
                        <br/>
                        <Label basic color='orange' pointing className="errPin" style={{display:'none'}}>
                            Masukkan 6 digit Pin Anda
                        </Label>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button positive circular onClick={this.submitOldPin}>Submit</Button>
                    </Modal.Actions>
                </Modal>

                <Modal className="modalPin" size={size} open={open} onClose={this.close} closeIcon>
                    <Modal.Header>Buat PIN Dompet Anda</Modal.Header>
                    <Modal.Content>
                        <Input type="password" id="pin" name="pin" className="inputPin" 
                        defaultValue={pin}
                        onChange={this.handleInputChange} maxLength="6" placeholder="______"/>
                        <br/>
                        <Label basic color='orange' pointing className="errPin" style={{display:'none'}}>
                            Masukkan 6 digit Pin Anda
                        </Label>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button positive circular onClick={this.submitPin}>Submit</Button>
                    </Modal.Actions>
                </Modal>


                <Modal className="modalPin" size={size} open={open2} onClose={this.close} closeIcon>
                    <Modal.Header>Konfirmasi PIN Dompet Anda</Modal.Header>
                    <Modal.Content>
                        <Input type="password" id="confirmpin" name="confirmpin" className="inputPin" 
                        defaultValue={confirmpin}
                        onChange={this.handleInputConfirmPin} maxLength="6" placeholder="______"/>
                        <br/>
                        <Label basic color='orange' pointing className="errKonfirmPin" style={{display:'none'}}>
                            Masukkan 6 digit Pin Konfirmasi Anda
                        </Label>
                        <Label basic color='red' pointing className="errPinTidakSama" style={{display:'none'}}>
                            Pin dan Konfirmasi Pin Tidak Sama
                        </Label>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button positive circular onClick={this.submitConfirmPin}>Submit</Button>
                    </Modal.Actions>
                </Modal>

            </div>
        )
    }

}