import React from 'react';
import { connect } from "react-redux";
import Routes from './components/Routes';
import { Helmet } from 'react-helmet';
import Detail from './pages/landing/Detail';
class Main extends React.Component {
  render() {
    const { authData: { isLoggedInPemodal } } = this.props;
    return (
      <html>
        <header>
          <Helmet>
            <title>Kerjasama | Platform Investasi Bisnis</title>
          </Helmet>
          <Routes isLoggedInPemodal={isLoggedInPemodal} />
        </header>
        <body>
          <div style={{position: 'fixed', right: '20px', bottom: '20px', zIndex: 1000}}>
            <a href={process.env.REACT_APP_LINK_WHATSAPP} target="_blank">
              <button 
                style={{backgroundColor: '#CCC', verticalAlign: 'center', height: '36px', borderRadius: '5px', border: 'solid 1px #ddd'}}>
                <i className="fa fa-whatsapp" 
                  style={{color: '#32C03C', fontSize: 'medium'}} 
                  aria-hidden={true}>
                </i> 
                Hubungi Kami
              </button>
            </a>
          </div>
        </body>
      </html>
    );
  }
}


function mapStateToProps(state) {
  return { authData: state.authReducer.authData }
}

export default connect(mapStateToProps, null)(Main)
