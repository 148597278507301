import PropTypes from 'prop-types'
import React, { Component, memo, useState } from 'react'
import { connect } from "react-redux";
import { getUserpemodal } from "../actions/userpemodal.actions";
import { updatePasspemodal } from "../actions/userpemodal.actions";
import { getUserpengelola } from "../actions/userpengelola.actions";
import { updatePasspengelola } from "../actions/userpengelola.actions";
import { fetchApi } from '../service/api';
import { Button, Modal, Dropdown, Form, Row, Col} from "react-bootstrap";
import swal from 'sweetalert';


class UbahKataSandi extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      c: false,
      userinfo: [],
      isLoad: false,

      show: false,
    }
  }

  getUserpemodal = async (values) => {

    try {
      // console.log(this.props.auth.isUserId);
      const response = await this.props.dispatch(getUserpemodal(values));

      localStorage.DataUser = JSON.stringify(response.responseBody);
      // console.log(response.responseBody.success);

      if (!response.responseBody.success) {
        throw response;
      } else if (response.responseBody.success) {

        this.state.userinfo = response.responseBody.user;
        this.state.isLoad = true;

      }

    } catch (error) {
      console.log(error);
    }
  }

  updatePass = async (values) => {
    try {
      // dilempar kesini
      const response = await this.props.dispatch(updatePasspemodal(values));
      if (response.responseBody.success == false) {
        throw response;
      } else {
        this.clearConsole()
        swal({text: response.responseBody.body.message, icon: 'success', button: 'OK'})
        this.showModal(false)
        // window.location.reload(true);
      }
    } catch (error) {
      // console.log(error);
      try{
        let errorStatus = error.responseBody.error
        if(errorStatus === 'UNAUTHORIZED_SINGLE_LOGIN_EXPIRED'){
          swal({text: error.responseBody.message, icon: 'warning', closeOnClickOutside: false})
          .then((value) => {
            setTimeout(() => {
              window.location.href = "/Signin"
              localStorage.removeItem('token');
              localStorage.clear();
              this.props.dispatch({
                type: "AUTH_USER_LOGOUT",
              })
            }, 500);
          });
        } else {
          setTimeout(() => {
            window.location.href = "/Signin"
            localStorage.removeItem('token');
            localStorage.clear();
            this.props.dispatch({
              type: "AUTH_USER_LOGOUT",
            })
          }, 500);
        }
      }catch(error){
        swal({ text: 'Error tidak terdefinisi!', icon: 'error' })
      }
    }
  }

  getUserpengelola = async (values) => {

    try {
      // console.log(this.props.auth.isUserId);
      const response = await this.props.dispatch(getUserpengelola(values));

      localStorage.DataUser = JSON.stringify(response.responseBody);

      if (!response.responseBody.success) {
        throw response;
      } else if (response.responseBody.success) {

        this.state.userinfo = response.responseBody.user;
        this.state.isLoad = true;

      }

    } catch (error) {
      console.log(error);
    }
  }

  updatePasspengelola = async (values) => {
    try {
      // dilempar kesini
      const response = await this.props.dispatch(updatePasspengelola(values));
      if (!response.responseBody.success) {
        throw response;
      } else {
        this.clearConsole()
        swal({text: response.responseBody.body.message, icon: 'success', button: 'OK'})
        this.showModal(false)
        const { history } = this.props;
      }
      // console.log(JSON.stringify(response))
    } catch (error) {
      try{
        let errorStatus = error.responseBody.error
        if(errorStatus === 'UNAUTHORIZED_SINGLE_LOGIN_EXPIRED'){
          swal({text: error.responseBody.message, icon: 'warning', closeOnClickOutside: false})
          .then((value) => {
            setTimeout(() => {
              window.location.href = "/Signin"
              localStorage.removeItem('token');
              localStorage.clear();
              this.props.dispatch({
                type: "AUTH_USER_LOGOUT",
              })
            }, 500);
          });
        } else {
          setTimeout(() => {
            window.location.href = "/Signin"
            localStorage.removeItem('token');
            localStorage.clear();
            this.props.dispatch({
              type: "AUTH_USER_LOGOUT",
            })
          }, 500);
        }
      }catch(error){
        swal({ text: 'Error tidak terdefinisi!', icon: 'error' })
      }
    }
  }

  // update Password
  updatePassword = (event, data) => {
    event.preventDefault()
    if (this.props.isUser == 1) {
      this.updatePass(data);
    } else {
      this.updatePasspengelola(data);
    }
  }

  clearConsole() {
    if (window.console || window.console.firebug) {
        console.clear();
    }
  }

  showModal = (param) => {
    this.setState({ 
      show: param
    });
  }

  render() {
    return (
      <KataSandiComp 
        updatePassword={this.updatePassword} 
        isUser={this.props.isUser} 
        showModal={this.showModal} 
        show={this.state.show} 
      />
    )
  }

}

const KataSandiComp = memo((props) => {

  const { update } = JSON.parse(localStorage.DataUser);

  const mergeUser = { ...data }
  const [data, setData] = useState(mergeUser)
  // console.log(data); 
  const handleChange = e => setData({ ...data, [e.target.name]: e.target.value })
  const handleSubmit = event => props.updatePassword(event, data)

  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const handleClose = () => props.showModal(false)
  const handleShow = () => props.showModal(true)

  return (
    <>
      <Dropdown.Item onClick={handleShow}><i className="fa fa-fw fa-key mr-2" ></i>Ubah Password</Dropdown.Item>

      <Modal show={props.show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title id="fonts">Ubah Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="fonts" onSubmit={handleSubmit} >
            <Form.Group as={Row} controlId="oldpassword">
              <Form.Label column sm="4">
                Password Lama
              </Form.Label>
              <Col sm="8">
                <Form.Control type="password" name="oldpassword" onChange={handleChange} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="password">
              <Form.Label column sm="4">
                Password Baru
              </Form.Label>
              <Col sm="8">
                <Form.Control type="password" name="password" onChange={handleChange} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="refeatpassword">
              <Form.Label column sm="4">
                Refeat Password
              </Form.Label>
              <Col sm="8">
                <Form.Control type="password" name="repassword" onChange={handleChange} />
              </Col>
            </Form.Group>
            <Modal.Footer>
              <Button type="submit" variant="primary"  >
                Simpan
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
});

function mapStateToProps(state) {
  return {
    getUserpemodal: state.userReducer.getUserpemodal,
    auth: state.authReducer.authData
  }
}

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(UbahKataSandi);

