import React , {memo,useState} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    Link,
  } from "react-router-dom";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import seru from "../../../assets/img/seru.png";
import seru2 from "../../../assets/img/Group 895.png";
import proyk from "../../../assets/img/Group 863.png";
import dompet from "../../../assets/img/dompet.png";
import bni from "../../../assets/img/bnivec.svg";
import bali from "../../../assets/img/bali.png";
import icon_pengelola from "../../../assets/img/Icon Pengelola.svg";
import { BASE_URL, tokenGet } from "../../../service/api";
import '../../../App.scss'
import '../../../Bg.scss'
import DetailDompet from '../../DetailDompet'
import axios from 'axios'
import $ from 'jquery'
import {
    Button,Message,
    Checkbox,Modal,
    TextArea,
    Grid,
    Card,
    Form,
    Header,
    Radio,
    Icon,
    Image,
    Menu,
    Segment,
    Sidebar,
    Input,
    Label,
    List,
    StatisticValue,
} from 'semantic-ui-react'
import { connect } from "react-redux";
import { BallBeat } from 'react-pure-loaders';
import { getUserpemodal } from "../../../actions/userpemodal.actions";
import Navbar_user from "../../../components/navbar_home"; 
import swal from 'sweetalert';

class BayarStep2 extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            error: null,
            items: [],
            paramsPembayaranHead : 1,
            c: false,
            userinfo: [],
            itemsDompet: [],
            isLoad:false,  
            dataProyekTersimpan : [],
            valuePayment: 'VA',
            open: false,
            suksesInsertTagihan : false,
            dataPembayaran : [],
            closeOnEscape : false,
            closeOnDimmerClick :  false,
            openAgreePrivy: false,
            openTolakPrivy : false,
            openInputPrivy : false,
            hari: '',
            tanggal : '',
            myDays : ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jum&#39;at', 'Sabtu']
        };
    }

    getUserpemodal = async (values) => {

        try {
            // console.log(this.props.auth.isUserId);
            const response = await this.props.dispatch(getUserpemodal(values));
            
            localStorage.DataUser = JSON.stringify(response.responseBody);
          // console.log(response.responseBody.success);
           
             if (!response.responseBody.success) {
                 throw response;
             } else if (response.responseBody.success) {
                if (!this.props.location.data) {
                    // alert('Maaf Refresh dilarang pada saat melakukan pembayaran, anda akan dikembalikan ke halaman dashboard utama')
                    // window.setTimeout(function(){
                    //     window.location.href = "/UserPemodal"
                    // },0);
                    swal({
                        text: 'Maaf Refresh dilarang pada saat melakukan pembayaran, anda akan dikembalikan ke halaman dashboard utama', 
                        icon: 'warning', 
                        closeOnClickOutside: false
                    }).then((value) => {
                        window.setTimeout(function(){
                            window.location.href = "/UserPemodal"
                        },0);
                    });
                 } else {
                    this.state.userinfo = JSON.stringify(response.responseBody.user);
                    this.state.isLoad = true;
                    this.getListProyekTersimpan();
                    this.getDompet();
                    $("body").addClass('bg');
                 }
            }
          
        } catch (error) {
            setTimeout(() => {
                window.location.href = "/Signin"
                localStorage.removeItem('token');
                localStorage.clear();
        
                this.props.dispatch({
                    type: "AUTH_USER_LOGOUT",
                })
            }, 2000);
        }
    }
    
    getListProyekTersimpan () {
        const userinfo = JSON.parse(this.state.userinfo)
        
        axios.get(BASE_URL+"/customer/pemodal/proyektersimpan", {params: {
            id_pemodal  : userinfo.id,
            type        : 0,
            limit       : 10
        }}, 
        axios.defaults.headers.common['Authorization'] = localStorage.token,
        axios.defaults.headers.common['Accept']        = 'application/json'
        )
        .then((resp) => {

            var lengthdataSlot = resp.data.data.length;
            
            this.setState({ 
                dataProyekTersimpan : resp.data.data,
                totalSlot : lengthdataSlot,
                totalProyek : lengthdataSlot,
            })

            var tmpSaldoPemb = 0
            resp.data.data.map((answer, i) => {     
                var postSlot = {
                    "ProyekID" : answer.id_project,
                    "HargaSlot": answer.proyek_overview.slot_nominal * answer.slot,
                    "Slot"     : answer.slot
                }

                var tmpPembayaran = answer.proyek_overview.slot_nominal * answer.slot
                tmpSaldoPemb  = parseInt(tmpSaldoPemb) + parseInt(tmpPembayaran)

                this.setState({ 
                    dataSlot : [...this.state.dataSlot,postSlot],
                    totalPembayaran : tmpSaldoPemb
                })
            })
            
        })
        .catch((resp) =>{

        });
    }

    getDompet() {
        
        axios.get(BASE_URL+"/customer/dompet",
        axios.defaults.headers.common['Authorization'] = localStorage.token,
        axios.defaults.headers.common['Accept']        = 'application/json'
        )
        .then((resp) => {
            if (resp.data.data) {
                var date = new Date();
                var day = date.getDate();
                var month = date.getMonth();
                var yy = date.getYear();
                var year = (yy < 1000) ? yy + 1900 : yy;
                var months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
                var myDays = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jum&#39;at', 'Sabtu'];
                var thisDay = date.getDay();
                thisDay = myDays[thisDay];
                var tgl = day + ' ' + months[month] + ' ' + year

                this.setState({ 
                    itemsDompet : resp.data.data,
                    tanggal     : tgl,
                    hari : thisDay
                })

                if (this.state.valuePayment === 'Dompet') {
                    $(".ui.compact.segment.Dompet").attr('style','background-color:#00000017');
                    $(".TransferChild").attr('style','display:none')
                } else {
                    $(".TransferChild").attr('style','display:none')
                    $(".saldoDompet").attr('style','display:none');
                    $(".ringkasContainer").removeClass('methodpay');
                    $(".sisaSaldoDompet").attr('style','display:none');
                    $(".ui.compact.segment.Dompet").style('style','background-color:white');
                }

                
            } else {
                // alert('Terjadi Kesalahan, Silahkan refresh browser');
                // return false;
            }
        })
        .catch((resp) =>{
            // alert('Terjadi Kesalahan, Silahkan refresh browser');
            // return false;
        });
    }

    handleChange = (e, { value }) => 
    {
        this.setState({ valuePayment : value })

        if (value === 'Dompet') {
            $(".ui.compact.segment.Dompet").attr('style','background-color:#00000017');
            $(".ui.compact.segment.VA").attr('style','background-color:white');
            $(".ui.compact.segment.Transfer").attr('style','background-color:white');
            $(".saldoDompet").attr('style','');
            $(".ringkasContainer").addClass('methodpay');
            $(".sisaSaldoDompet").attr('style','');
            $(".TransferChild").attr('style','display:none')
        } else if (value === 'Trf') {
            $(".TransferChild").attr('style','')
            $(".ringkasContainer").removeClass('methodpay');
            $(".saldoDompet").attr('style','display:none');
            $(".sisaSaldoDompet").attr('style','display:none');
            $(".ui.compact.segment.Dompet").attr('style','background-color:white');
            $(".ui.compact.segment.VA").attr('style','background-color:white');
            $(".ui.compact.segment.Transfer").attr('style','background-color:#00000017');
        } else if (value === 'VA') {
            $(".ringkasContainer").removeClass('methodpay');
            $(".saldoDompet").attr('style','display:none');
            $(".sisaSaldoDompet").attr('style','display:none');
            $(".ui.compact.segment.Dompet").attr('style','background-color:white');
            $(".ui.compact.segment.VA").attr('style','background-color:#00000017');
            $(".ui.compact.segment.Transfer").attr('style','background-color:white');
            $(".TransferChild").attr('style','display:none')
        }
    }

    show = (size) => () => {
        this.setState({ size, open: true})
    }
    close = () => {
        // $(".modal.modalKonfirmasiTagihan .ui.positive.button").removeClass('disabled');
        // $(".modal.modalKonfirmasiTagihan .ui.blue.icon.right.labeled.button").removeClass('loading');
        this.setState({ open: false })
    }
    clearConsole() { 
        if(window.console || window.console.firebug) {
           console.clear();
        }
      }

     render() {
        const verified = this.props.verified
        const verified_advance = this.props.verified_advance
        const { error, isLoaded, items, hari, tanggal } = this.state;
        
        if (!this.state.isLoad) {
           
            const d = this.getUserpemodal({ token: this.props.auth.isUserId });
            
            return (
             <div
             style={{paddingTop:'300px',Width:'initial',textAlign:'center'}}
              >
               <BallBeat
              color={'#123abc'}
              loading
              />
              Loading....</div>
            );
          }
          else {
            this.clearConsole();
            const dataSlot = this.props.location.data.dataSlot
            const dataProyek = this.props.location.data.dataProyek
            const totalSlot = this.props.location.data.totalSlot
            const totalPembelian = this.props.location.data.totalPembelian
            const platformFee = this.props.location.data.platformFee
            const totalPembayaran = this.props.location.data.totalPembayaran
            
            const openMdlPrivy = e => {
                this.setState({ size : 'mini', open: false, openAgreePrivy: true })
            }
            
            const openMdlTolakPrivy = e => {
                this.setState({ size : 'mini', open: false, openAgreePrivy: false, openTolakPrivy : true })
            }

            const closeTolakPrivy = e => {
                this.setState({ openTolakPrivy:false })
            }

            const closeAgreePrivy = e => {
                this.setState({ openAgreePrivy:false })
            }

            const closeInputPrivy = e => {
                this.setState({ openInputPrivy:false })
            }

            const openMdlInputPrivy = e => {
                this.setState({ size : 'mini', open: false, openInputPrivy: true})
            }

            const submitTagihan = e => {
                
                $(".ui.blue.button").addClass('disabled');
                $(".ui.blue.button").addClass('loading');

                $(".modal.modalKonfirmasiTagihan .ui.green.circular.button").addClass('disabled');
                $(".modal.modalKonfirmasiTagihan .ui.green.circular.button").addClass('loading');
                $(".modal.modalKonfirmasiTagihan .ui.red.circular.button").addClass('disabled');

                $(".modal.modalAgreePrivy .ui.green.circular.button").addClass('disabled');
                $(".modal.modalAgreePrivy .ui.green.circular.button").addClass('loading');
                $(".modal.modalAgreePrivy .ui.red.circular.button").addClass('disabled');

                $(".modal.modalInputPrivy .ui.green.circular.button").addClass('disabled');
                $(".modal.modalInputPrivy .ui.green.circular.button").addClass('loading');

                var userinfo = JSON.parse(this.state.userinfo)
                var dataSlot = this.props.location.data.dataSlot
                var dataProyek = this.props.location.data.dataProyek
        
                var projectsData = []
                $.each(dataSlot, function( index, value ) {
                    projectsData.push({
                        project_id : value.ProyekID,
                        slot : value.Slot
                    })
                });
        
                var postData = {
                    funding_method  : this.state.valuePayment,  
                    user_id         : userinfo.user_id,
                    user_name       : userinfo.full_name,
                    user_email      : userinfo.email,
                    user_phone      : userinfo.no_hp,
                    projects        : projectsData
                }

                // console.log("POSTDATA: " + JSON.stringify(postData))
                // return
        
                axios.post(BASE_URL+"/customer/ProjectFunding", postData,
                  axios.defaults.headers.common['Authorization'] = localStorage.token,
                  axios.defaults.headers.common['Accept']        = 'application/json'
                  )
                .then((resp) => {
                    if (resp.data.status == true) {
                            this.setState({ 
                                // dataPembayaran : {
                                //     bank: "BNIS",
                                //     virtual_account: "9880159112127070"
                                // },
                                dataPembayaran : resp.data.data
                            })
                            window.setTimeout(getDataPembayaran, 2000); 
                    } else {
                        // alert(resp.data.message)
                        // window.setTimeout(function(){
                        //     window.location.href = "/UserPemodal"
                        // },0);
                        swal({
                            text: resp.data.message, 
                            icon: 'error', 
                            closeOnClickOutside: false
                        }).then((value) => {
                            window.setTimeout(function(){
                                window.location.href = "/UserPemodal"
                            },0);
                        });
                    }
                })
                .catch(error => {
                    // if (error.response.data.message) {
                    //     alert(error.response.data.message)
                    // } else {
                    //     alert('Maaf Transaksi belum bisa dilakukan, anda akan dikembalikan ke halaman dashboard utama')
                    // }
                    // window.setTimeout(function(){
                    //     window.location.href = "/UserPemodal"
                    // },0);

                    if (error.response.data.message) {
                        swal({text: error.response.data.message, icon: 'error', closeOnClickOutside: false})
                        .then((value) => {
                            window.setTimeout(function(){
                                window.location.href = "/UserPemodal"
                            },0);
                        });
                    } else {
                        swal({text: 'Maaf Transaksi belum bisa dilakukan, anda akan dikembalikan ke halaman dashboard utama', icon: 'error', closeOnClickOutside: false})
                        .then((value) => {
                            window.setTimeout(function(){
                                window.location.href = "/UserPemodal"
                            },0);
                        });
                    }
                });
            }

            const getDataPembayaran = event => {
                document.getElementById("btnPembayaranAuto").click();
            }

            return (
                <div style={{ backgroundColor: "#EEEEEE" }}>
                    <Navbar_user pembayaran={this.state.paramsPembayaranHead} />
                    <div className="row">
                        <div className="col-md-8">
                            <MetodeBayar dataProyekTersimpan={this.state.dataProyekTersimpan} dataSlot={dataSlot} dataProyek={dataProyek}
                            totalSlot={totalSlot} totalPembayaran={totalPembayaran} valuePayment={this.state.valuePayment} handleChange={this.handleChange}
                            />
                        </div>
                        <div className="col-md-4">
                            <RingkasanPembayaran 
                            dataSlot={dataSlot}
                            totalSlot={totalSlot} 
                            totalPembelian={totalPembelian} 
                            platformFee={platformFee} 
                            totalPembayaran={totalPembayaran} 
                            dataProyek={dataProyek} show={this.show('mini')}
                            close={this.close} open={this.state.open} size={this.state.size} submitTagihan={submitTagihan} dataPembayaran={this.state.dataPembayaran}
                            closeOnEscape={this.state.closeOnEscape} closeOnDimmerClick={this.state.closeOnDimmerClick} openMdlPrivy={openMdlPrivy} openMdlTolakPrivy={openMdlTolakPrivy}
                            openAgreePrivy={this.state.openAgreePrivy} openTolakPrivy={this.state.openTolakPrivy} closeTolakPrivy={closeTolakPrivy} closeAgreePrivy={closeAgreePrivy}
                            openMdlInputPrivy={openMdlInputPrivy} openInputPrivy={this.state.openInputPrivy} closeInputPrivy={closeInputPrivy} hari={hari} tanggal={tanggal}
                            />
                        </div>
                    </div>
                    <br/><br/><br/>
                </div>
            )
        }
     }
}

function MetodeBayar({dataProyekTersimpan,dataSlot,totalSlot,totalPembayaran,dataProyek,valuePayment,handleChange,submitTagihan}) {
    return(
        <>
        <div className="boxContainer">
            <div className="judulboxTop">
                <div className="namaboxTop">Pilih Metode Pembayaran</div>
            </div>
            <Form>
            <Container className="radioMethodPay">
                {/* <Row className="rowMethodPay">
                    <Col xs md="12">
                        <Form.Field>
                            <Segment compact className="Dompet disabled">
                                <Radio
                                    label='Dompet'
                                    disabled
                                    name='radioGroup'
                                    value='Dompet'
                                    checked={valuePayment === 'Dompet'}
                                    onChange={handleChange}
                                />
                                <Label>
                                    ( Coming Soon )
                                </Label>
                                <Image src={dompet} size='mini' />
                            </Segment>
                        </Form.Field>
                    </Col>
                </Row>
                <Row className="rowMethodPay">
                    <Col xs md="12">
                        <Form.Field>
                            <Segment compact className="Transfer disabled">
                                <Radio
                                    label='Bank Transfer'
                                    disabled
                                    name='radioGroup'
                                    value='Trf'
                                    checked={valuePayment === 'Trf'}
                                    onChange={handleChange}
                                />
                                <Label>
                                    ( Coming Soon )
                                </Label>
                                <Image src={bni} size='mini' className="bni" />
                                <Segment compact className="Transfer TransferChild">
                                    Kode pembayaran akan dikembalikan ke dompet anda.
                                </Segment>
                                <div className="TrfKetentuanBayar TransferChild">
                                    Ketentuan Pembayaran:
                                </div>
                                <Col className="TransferChild" xs md="12">
                                    <ul>
                                        <li>Total pembayaran kamu belum termasuk kode pembyaran untuk keperluan proses verivikasi otomatis</li>
                                        <li>Mohon transfer tepat sampai 3 digit terakhir</li>
                                    </ul>
                                </Col>
                            </Segment>
                        </Form.Field>
                    </Col>
                </Row> */}
                <Row className="rowMethodPay">
                    <Col xs md="12">
                        <Form.Field>
                            <Segment compact className="VA">
                                <Radio
                                    label='Virtual Account'
                                    name='radioGroup'
                                    value='VA'
                                    checked={valuePayment === 'VA'}
                                    onChange={handleChange}
                                />
                                {/* <Image src={bni} size='mini' className="bni" /> */}
                            </Segment>
                        </Form.Field>
                    </Col>
                </Row>
            </Container>
            </Form>
        </div>
        <div className="boxContainerMessage">
            <span>Harap melakukan pembayaran dalam waktu 1x24 jam</span>
        </div>
        </>
    )
}

function RingkasanPembayaran({dataSlot,totalSlot,totalPembelian,platformFee,totalPembayaran,dataProyek,show,close,open,size,submitTagihan,dataPembayaran,closeOnEscape,closeOnDimmerClick,
    openMdlPrivy,openAgreePrivy,openTolakPrivy,openMdlTolakPrivy,closeTolakPrivy,closeAgreePrivy,openMdlInputPrivy,openInputPrivy,closeInputPrivy,hari,tanggal}) {
    return(
        <div className="ringkasContainer methodpay">
            <div className="judulboxTop">
                <div className="namaboxTop">Ringkasan Pembayaran</div>
            </div>
            { (dataSlot.length == 1) ? 
                (
                <>
            <div className="totalProyek">
                <Header as='h5' floated='left' color='grey'>
                    Total Pembelian
                </Header>
                <Header as='h5' floated='right' color='grey'>
                    {/* Rp. {dataProyek.HargaSlot.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
                    Rp. {totalPembelian.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Header>
            </div>
            <br/>
            <div className="totalProyek">
                <Header as='h5' floated='left' color='grey'>
                    Biaya Layanan(5%)
                </Header>
                <Header as='h5' floated='right' color='grey'>
                    {/* Rp. {(dataProyek.HargaSlot).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
                    Rp. {platformFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Header>
            </div>
            <br/><br/>
            <hr/>
            <div className="totalProyek">
                <Header as='h5' floated='left'>
                    Total Pembayaran
                </Header>
                <Header as='h5' floated='right' style={{color:'#214981'}}>
                    {/* Rp. {dataProyek.HargaSlot.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
                    Rp. {totalPembayaran.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Header>
            </div>
            <br className="saldoDompet"/>
            <div className="totalProyek saldoDompet">
                <Header as='h5' floated='left' color='grey'>
                    Saldo Dompet
                </Header>
                <Header as='h5' floated='right' color='grey'>
                    Rp. 0
                </Header>
            </div>
            <br className="saldoDompet"/><hr className="saldoDompet"/>
            <div className="totalProyek sisaSaldoDompet">
                <Header as='h5' floated='left' color='grey'>
                    Sisa Saldo Dompet
                </Header>
                <Header as='h5' floated='right' color='grey'>
                    Rp. 0
                </Header>
            </div>
            <br/><br/>
            <div style={{textAlign:'center'}}>
                {/* onClick={show} */}
                <Link to={{ pathname: "/Pembayaran/TagihanVA", data: {
                        dataSlot: dataSlot,
                        totalSlot: totalSlot,
                        platformFee: platformFee,
                        totalPembayaran: totalPembayaran,
                        dataProyek: dataSlot[0],
                        dataPembayaran: dataPembayaran
                } }}>
                    <Button id="btnPembayaranAuto" style={{display:'none'}}>Tampilkan Pembayaran</Button>
                </Link>
                <Button id="fonts" className="col-md-11" size='medium' color="blue" onClick={submitTagihan}>Bayar Sekarang</Button>
            </div>
            </>
            )
            :
            (
                <>
                <div className="totalProyek">
                <Header as='h5' floated='left' color='grey'>
                    Total Pembelian
                </Header>
                <Header as='h5' floated='right' color='grey'>
                    Rp. {totalPembelian.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Header>
                </div>
                <br/>
                <div className="totalProyek">
                    <Header as='h5' floated='left' color='grey'>
                        Biaya Layanan(5%)
                    </Header>
                    <Header as='h5' floated='right' color='grey'>
                        Rp. {platformFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </Header>
                </div>
                <br/><br/>
                <hr/>
                <div className="totalProyek">
                    <Header as='h5' floated='left'>
                        Total Pembayaran
                    </Header>
                    <Header as='h5' floated='right' style={{color:'#214981'}}>
                        Rp. {totalPembayaran.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </Header>
                </div>
                <br className="saldoDompet"/>
                <div className="totalProyek saldoDompet">
                    <Header as='h5' floated='left' color='grey'>
                        Saldo Dompet
                    </Header>
                    <Header as='h5' floated='right' color='grey'>
                        Rp. 0
                    </Header>
                </div>
                <br className="saldoDompet"/><hr className="saldoDompet"/>
                <div className="totalProyek sisaSaldoDompet">
                    <Header as='h5' floated='left' color='grey'>
                        Sisa Saldo Dompet
                    </Header>
                    <Header as='h5' floated='right' color='grey'>
                        Rp. 0
                    </Header>
                </div>
                <br/><br/>
                <div style={{textAlign:'center'}}>
                    {/* onClick={show} */}
                    <Link to={{ pathname: "/Pembayaran/TagihanVA", data: {
                        dataSlot:dataSlot,totalSlot:totalSlot,totalPembayaran:totalPembayaran,
                        dataProyek:dataSlot[0],dataPembayaran:dataPembayaran
                    } }}>
                        <Button id="btnPembayaranAuto" style={{display:'none'}}/>
                    </Link>
                    <Button id="btnPembayaranAuto fonts" className="col-md-11" size='medium' color="blue" onClick={submitTagihan}>Bayar</Button>
                </div>
                </>
            )}

            <Modal className="modalKonfirmasiTagihan" size={size} open={open} onClose={close} closeIcon closeOnEscape={closeOnEscape} closeOnDimmerClick={closeOnDimmerClick}>
                <Modal.Header></Modal.Header>
                <Modal.Content>
                    <p>Apakah Anda Sudah Mempunyai Akun PrivyID ?</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="red" circular onClick={openMdlPrivy}>Belum</Button>
                    <Button
                    color="green"
                    circular
                    content='Sudah'
                    onClick={openMdlInputPrivy}
                    />
                </Modal.Actions>
            </Modal>

            <Modal className="modalInputPrivy" size={size} open={openInputPrivy} onClose={closeInputPrivy} closeIcon closeOnEscape={closeOnEscape} closeOnDimmerClick={closeOnDimmerClick}>
                <Modal.Header>Masukkan ID PrivyID Anda</Modal.Header>
                <Modal.Content>
                    <Input placeholder='Id PrivyID' />
                </Modal.Content>
                <Modal.Actions>
                    <Link to={{ pathname: "/Pembayaran/TagihanVA", data: {
                            dataSlot:dataSlot,totalSlot:totalSlot,totalPembayaran:totalPembayaran,
                            dataProyek:dataSlot[0],dataPembayaran:dataPembayaran
                        } }}>
                        <Button
                        id="btnPembayaranAuto"
                        color="green"
                        circular
                        content='Submit'
                        onClick={submitTagihan}
                        />
                    </Link>
                </Modal.Actions>
            </Modal>

            <Modal className="modalAgreePrivy" size={size} open={openAgreePrivy} onClose={closeAgreePrivy} closeIcon closeOnEscape={closeOnEscape} closeOnDimmerClick={closeOnDimmerClick}>
                <Modal.Header>Pembuatan PrivyID</Modal.Header>
                <Modal.Content>
                <div className="text_kebijakan p-3 cardTextAgreePrivy">
                <table>
                    <tbody>
                    <tr>
                    <td width="389">
                    <p><strong>PERJANJIAN LAYANAN TANDA TANGAN ELEKTRONIK</strong></p>
                    <p>Perjanjian Layanan Tanda Tangan Elektronik ini (selanjutnya disebut sebagai &ldquo;<strong>Perjanjian</strong>&rdquo;) dilangsungkan dan ditandatangani pada hari [<strong>{hari}</strong>], tanggal [<strong>{tanggal}</strong>]&nbsp; oleh dan antara:</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>1.&nbsp;<strong>PT. KERJASAMA UNTUK NEGERI</strong>, suatu<strong>&nbsp;</strong>perseroan terbatas yang didirikan berdasarkan hukum negara Republik Indonesia dan beralamat di Sinpasa Commercial Block B-27, Summarecon Bekasi, Jalan Bulevar Selatan No. 5, Marga Mulya, Bekasi Utara, Kota Bekasi, Jawa Barat, 17143, Indonesia&nbsp;(selanjutnya disebut sebagai<strong>&nbsp;</strong>&ldquo;<strong>KERJASAMA.CO.ID</strong>&rdquo;), dalam hal ini diwakili secara sah oleh Prasetyo Adi Nugroho&nbsp;selaku Presiden Direktur, dan oleh karenanya berwenang bertindak untuk dan atas nama KERJASAMA.CO.ID; dan</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>2.&nbsp;<strong>PT PRIVY IDENTITAS DIGITAL</strong>,&nbsp;suatu<strong>&nbsp;</strong>perseroan terbatas yang didirikan berdasarkan hukum negara Republik Indonesia dan beralamat di Jl. Kemang Raya No. 15 C Lantai 4, RT/RW 013/01, Kelurahan Bangka, Kecamatan Mampang Prapatan, Jakarta Selatan, 12730, Indonesia&nbsp;(selanjutnya disebut sebagai<strong>&nbsp;</strong>&ldquo;<strong>PRIVY</strong>&rdquo;).</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>KERJASAMA.CO.ID<strong>&nbsp;</strong>dan<strong>&nbsp;</strong>PRIVY<strong>&nbsp;</strong>selanjutnya secara sendiri-sendiri disebut sebagai &ldquo;<strong>Pihak</strong>&rdquo; dan secara bersama-sama disebut sebagai &ldquo;<strong>Para Pihak</strong>&rdquo;.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>BAHWA:</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>1.&nbsp;PRIVY merupakan sebuah perseroan terbatas yang melakukan kegiatan usaha sebagai penyelenggara Tanda Tangan Elektronik dengan sistem yang telah terdaftar pada Kementerian Komunikasi dan Informatika Republik Indonesia;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>2.&nbsp;KERJASAMA.CO.iD&nbsp;merupakan perseroan terbatas yang bergerak di bidang&nbsp;Teknologi Finansial&nbsp;berbasis Syariah&nbsp;dan melakukan&nbsp;kegiatan Pembiayaan Proyek;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>3.&nbsp;KERJASAMA.CO.ID&nbsp;bermaksud untuk menggunakan Layanan&nbsp;PRIVY dalam menyelenggarakan kegiatan usahanya.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>Para Pihak dengan ini menyatakan persetujuannya untuk melangsungkan Perjanjian ini dengan syarat-syarat dan ketentuan-ketentuan sebagai berikut:</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>PASAL 1</strong></p>
                    <p><strong>DEFINISI DAN INTERPRETASI</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>1.1&nbsp;Kecuali dimana konteksnya menentukan lain, istilah-istilah&nbsp;yang diawali dengan huruf kapital dalam Perjanjian ini&nbsp;memiliki arti sebagai berikut:</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&ldquo;<strong>Antarmuka Aplikasi Pemrograman</strong>&rdquo;<strong>&nbsp;</strong>atau &rdquo;<strong>API</strong>&rdquo;&nbsp;adalah sebuah spesifikasi yang dimaksudkan untuk digunakan sebagai sebuah antarmuka oleh komponen piranti lunak untuk berkomunikasi dengan piranti lainnya. &nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>Data Pembuatan Tanda Tangan Elektronik</strong>&nbsp;adalah kode kriptografi dan/atau kode lain yang unik dan terasosiasi dengan Sertifikat Elektronik kepada orang tertentu,&nbsp;yang dihasilkan dari perkembangan teknologi informasi.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>Dashboard </strong>adalah antarmuka yang dapat menampilkan dan/atau mengolah Informasi Elektronik yang relevan dengan pelaksanaan Perjanjian ini.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>Dokumen Elektronik</strong>&nbsp;adalah setiap Informasi Elektronik termasuk namun tidak terbatas pada Kontrak Elektronik, yang dibuat, diteruskan, dikirimkan, diterima, atau disimpan dalam bentuk analog, digital, elektromagnetik, optikal, atau sejenisnya, yang dapat dilihat, ditampilkan, dan/atau didengar melalui komputer atau sistem elektronik, termasuk tetapi tidak terbatas pada tulisan, suara, gambar, peta, rancangan, foto atau sejenisnya, huruf, tanda, angka, kode akses, simbol atau perforasi yang memiliki makna atau arti atau dapat dipahami oleh orang yang mampu memahaminya.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>Hari Kerja </strong>adalah suatu hari (selain hari Sabtu,&nbsp;Minggu, dan hari libur nasional) dimana bank-bank&nbsp;di Indonesia pada umumnya menjalankan kegiatan operasionalnya dan kliring sesuai dengan ketentuan Bank Indonesia.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>Informasi Elektronik</strong>&nbsp;adalah satu atau sekumpulan data elektronik, termasuk tetapi tidak terbatas pada tulisan, suara, gambar, peta, rancangan, foto, <em>electronic data interchange</em>&nbsp;(EDI), surat elektronik (<em>electronic mail</em>), telegram, teleks, <em>telecopy </em>atau sejenisnya, huruf, tanda, angka, kode akses, simbol, atau perforasi yang telah diolah yang memiliki arti atau dapat dipahami oleh orang yang mampu memahaminya.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>Ketentuan dan Syarat Umum </strong>adalah kebijakan umum sesuai dengan standar Privy yang termasuk namun tidak terbatas pada Ketentuan Penggunaan yang tertera pada https://privy.id/terms-and-conditions, Kebijakan Sertifikat dan Tata Cara Pelaksanaan Sertifikat (CP/CPS), Kebijakan Jaminan, Kebijakan Privasi, Perjanjian Pemegang Sertifikat dan Perjanjian Pihak Pengandal sebagaimana tertera dalam https://repository.privyca.id/</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>Konsumen</strong>&nbsp;adalah&nbsp;setiap orang yang akan menandatangani Dokumen Elektronik atau Kontrak Elektronik dengan KERJASAMA.CO.ID&nbsp;dengan menggunakan Layanan PRIVY.&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>Kontrak Elektronik</strong>&nbsp;adalah Konsumen dan setiap perjanjian antara KERJASAMA.CO.ID&nbsp;dan Konsumen yang dibuat melalui sistem elektronik.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>Kuota atau Saldo</strong>&nbsp;adalah besaran nilai yang dapat digunakan oleh KERJASAMA.CO.ID&nbsp;untuk menggunakan Layanan yang diberikan oleh PrivyID.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>Pajak</strong>&nbsp;adalah segala jenis pajak atas barang dan jasa, pajak penghasilan, dan pajak pertambahan nilai.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>Penanda Tangan</strong>&nbsp;adalah subjek hukum yang terasosiasikan atau terkait dengan Tanda Tangan Elektronik.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>Perwakilan</strong>&nbsp;adalah setiap karyawan, personil, staf, pemegang saham, anggota direksi atau dewan komisaris, afiliasi, agen, sub-kontraktor, akuntan, konsultan, kuasa, dan setiap perwakilan dari pihak yang bersangkutan, dan setiap orang yang bekerja untuk atau memberikan jasa kepada suatu Pihak.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>Layanan</strong>&nbsp;adalah rangkaian sistem identitas dan Tanda Tangan Elektronik.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>Rupiah</strong>&nbsp;adalah mata uang Republik Indonesia yang sah.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>Sertifikat Elektronik</strong>&nbsp;adalah sertifikat yang diterbitkan oleh PRIVY yang bersifat elektronik dan&nbsp;memuat Tanda Tangan Elektronik serta&nbsp;identitas yang menunjukkan subjek hukum atau para pihak dalam Transaksi Elektronik.&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>Tanda Tangan Elektronik</strong>&nbsp;adalah tanda tangan yang terdiri atas Informasi Elektronik yang dilekatkan, terasosiasi atau terkait dengan Informasi Elektronik lainnya yang digunakan sebagai alat verifikasi dan autentikasi.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>Transaksi Elektronik </strong>adalah perbuatan hukum yang dilakukan dengan menggunakan komputer, jaringan komputer, dan/atau media elektronik lainnya.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>1.2&nbsp;Dalam Perjanjian ini:</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>(a)&nbsp;istilah dalam bentuk tunggal juga termasuk bentuk jamak dan begitu juga sebaliknya;</p>
                    <p>(b)&nbsp;istilah dalam bentuk salah satu gender mencakup seluruh gender;</p>
                    <p>(c)&nbsp;dimana ada istilah atau frasa yang didefinisikan, bentuk ungkapan atau gramatikal lainnya dari kata atau frasa tersebut harus diartikan secara analogi; dan</p>
                    <p>(d)&nbsp;istilah &ldquo;termasuk&rdquo; dan &ldquo;mencakup&rdquo; dan istilah sejenis tidak akan dianggap atau ditafsirkan sebagai bersifat membatasi.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>1.3&nbsp;Setiap rujukan dalam Perjanjian ini atas:</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>(a)&nbsp;suatu Pasal adalah kepada suatu Pasal dalam Perjanjian ini (kecuali dinyatakan sebaliknya);</p>
                    <p>(b)&nbsp;setiap Pihak mencakup pengganti dan penerima pengalihan yang diizinkan bagi Pihak tersebut;</p>
                    <p>(c)&nbsp;Perjanjian ini mencakup seluruh perubahan atau tambahan atas Perjanjian ini; dan</p>
                    <p>(d)&nbsp;setiap orang termasuk pribadi kodrati, firma, perusahaan, korporasi, pemerintah, negara, perkumpulan, koperasi, konsorsium, persekutuan (baik memiliki harta kekayaan terpisah atau tidak).</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>1.4&nbsp;Judul dalam Perjanjian ini adalah hanya dibuat untuk mempermudah rujukan dan tidak akan mempengaruhi penafsiran dari Perjanjian ini.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>PASAL 2</strong></p>
                    <p><strong>PENUNJUKKAN</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>KERJASAMA.CO.ID&nbsp;dengan ini setuju untuk menunjuk PRIVY&nbsp;secara eksklusif sebagai penyedia Layanan&nbsp;dengan lingkup kerja dan spesifikasi sebagaimana ditentukan di dalam Perjanjian ini, dan PRIVY&nbsp;dengan ini setuju untuk menerima penunjukkan tersebut.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>PASAL 3</strong></p>
                    <p><strong>JANGKA WAKTU PERJANJIAN</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>3.1&nbsp;Perjanjian ini berlaku terhitung efektif sejak selama [_] sejak tanggal Perjanjian ini, kecuali diakhiri lebih awal sesuai dengan ketentuan Perjanjian ini.</p>
                    <p><strong>&nbsp;</strong></p>
                    <p>3.2&nbsp;Perjanjian akan otomatis diperpanjang selama 1 (satu) tahun apabila tidak ada pengakhiran Perjanjian dari Para Pihak.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>PASAL 4</strong></p>
                    <p><strong>LINGKUP KERJA DAN SPESIFIKASI</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>4.1&nbsp;Layanan PRIVY yang tersedia berdasarkan Perjanjian ini adalah EnterpriseID. EnterpriseID terdiri dari:</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>(a)&nbsp;Enterprise Action Balance: jumlah aktivitas penandatanganan dan/atau pengiriman dokumen yang dapat dilakukan oleh KERJASAMA.CO.ID&nbsp;dan Konsumen/Penanda Tangan;&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>(b)&nbsp;Employee Account: jumlah kuota orang yang dapat didaftarkan menjadi anggota dari EnterpriseID KERJASAMA.CO.ID; dan</p>
                    <p>&nbsp;</p>
                    <p>(c)&nbsp;PrivyID User Authentication: layanan registrasi dan verifikasi data yang akan digunakan sebagai Data Pembuatan Tanda Tangan Elektronik.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>4.2&nbsp;Layanan PRIVY adalah sebagai berikut:</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>(a)&nbsp;Penyediaan <em>server</em>&nbsp;<em>side facial recognition engine</em>;</p>
                    <p>&nbsp;</p>
                    <p>(b)&nbsp;Penyediaan <em>client side liveness detection system</em>;</p>
                    <p>(c)&nbsp;Penyediaan Data Pembuatan Tanda Tangan Elektronik bagi para pihak dalam Dokumen Elektronik;</p>
                    <p>&nbsp;</p>
                    <p>(d)&nbsp;Penyediaan <em>Dashboard</em>&nbsp;yang menunjukkan status penandatanganan Dokumen Elektronik;</p>
                    <p>&nbsp;</p>
                    <p>(e)&nbsp;Penyediaan <em>Server</em>&nbsp;<em>side digital signing engine</em>; dan/atau</p>
                    <p>&nbsp;</p>
                    <p>(f)&nbsp;Penyediaan penandaan waktu (<em>time stamp</em>) pada Dokumen Elektronik.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>4.3&nbsp;Prosedur pelaksanaan pemberian Layanan adalah sebagai berikut:</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>(a)&nbsp;PRIVY akan menerbitkan Data Pembuatan Tanda Tangan Elektronik berdasarkan data yang telah diverifikasi atau diotentikasi oleh KERJASAMA.CO.ID;</p>
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>(b)&nbsp;Jika diminta oleh KERJASAMA.CO.ID, PRIVY akan melakukan verifikasi atas identitas Konsumen dan salah satu metode verifikasi yang dilakukan PRIVY adalah membandingkan swafoto yang diunggah oleh Konsumen dengan foto pada fisik Kartu Tanda Penduduk yang diunggah oleh Konsumen;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>(c)&nbsp;KERJASAMA.CO.ID&nbsp;wajib untuk menginformasikan kepada Konsumen bahwa untuk menandatangani Dokumen Elektronik, Konsumen akan memperoleh Data Pembuatan Tanda Tangan Elektronik dari PRIVY;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>(d)&nbsp;KERJASAMA.CO.ID&nbsp;wajib untuk memastikan bahwa Konsumen mengerti dan menyetujui bahwa data pribadinya akan ditransmisikan oleh KERJASAMA.CO.ID&nbsp;kepada PRIVY untuk kepentingan penggunaan Tanda Tangan Elektronik, dan Konsumen telah membaca dan menyatakan tunduk pada syarat dan ketentuan penggunaan Layanan PRIVY sebelum KERJASAMA.Co.ID&nbsp;mentransmisikan data pribadi Konsumen kepada PRIVY;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>(e)&nbsp;PRIVY dan KERJASAMA.CO.ID&nbsp;akan menggunakan Antarmuka Aplikasi Pemrograman untuk melaksanakan transmisi Informasi Elektronik atau Dokumen Elektronik yang akan ditandatangani dengan menggunakan Tanda Tangan Elektronik oleh para pihak dalam Dokumen Elektronik;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>(f)&nbsp;hasil Dokumen Elektronik yang telah ditandatangani oleh para pihak terkait dengan menggunakan Tanda Tangan Elektronik adalah dalam format .PDF;</p>
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>(g)&nbsp;KERJASAMA.CO.ID&nbsp;akan menerima Dokumen Elektronik yang telah ditandatangani oleh para pihak terkait menggunakan Tanda Tangan Elektronik (sebagaimana dimaksud dalam huruf (f) di atas) melalui Antarmuka Aplikasi Pemrograman/API dan akan menyimpan Dokumen Elektronik tersebut dalam sistem elektronik milik KERJASAMA.CO.ID. PRIVY tidak bertanggung jawab atas hilangnya atau rusaknya Dokumen Elektronik yang telah diterima oleh KERJASAMA.CO.ID&nbsp;dari PRIVY dan PRIVY tidak berkewajiban untuk menyimpan setiap Dokumen Elektronik untuk kepentingan KERJASAMA.CO.ID&nbsp;setelah Dokumen Elektronik tersebut diterima oleh KERJASAMA.CO.ID;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>(h)&nbsp;Spesifikasi Tanda Tangan Elektronik PRIVY serta aturan teknis pelaksanaan Perjanjian ini terdapat pada Lampiran I dari Perjanjian ini yang merupakan satu kesatuan dan bagian yang tak terpisahkan dari Perjanjian ini.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>4.4 PRIVY akan memastikan bahwa:</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>(a)&nbsp;Dengan teknik kriptografi, Data Pembuatan Tanda Tangan Elektronik tidak dapat diketahui dari data verifikasi Tanda Tangan Elektronik melalui penghitungan tertentu, dalam kurun waktu tertentu dan dengan alat yang wajar.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>(b)&nbsp;Data Pembuatan Tanda Tangan Elektronik tersimpan dalam suatu media elektronik yang dapat diakses hanya oleh Penanda Tangan saat penandatanganan.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>(c)&nbsp;Data yang terkait dengan Penanda Tangan disimpan di tempat atau sarana penyimpanan data, yang menggunakan sistem terpercaya milik PRIVY yang dapat mendeteksi adanya perubahan atas data tersebut dan memenuhi persyaratan sebagai berikut: &nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>i.&nbsp;hanya orang yang diberi wewenang yang dapat memasukkan data baru, mengubah, menukar, atau mengganti data;</p>
                    <p>ii.&nbsp;informasi Sertifikat Elektronik dapat diperiksa keautentikannya; dan</p>
                    <p>iii.&nbsp;perubahan teknis lainnya yang melanggar persyaratan keamanan sesuai peraturan perundang-undangan Indonesia dapat dideteksi atau diketahui oleh PRIVY.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>4.5&nbsp;KERJASAMA.CO.ID&nbsp;harus memastikan bahwa Penanda Tangan wajib menjaga dan bertanggung jawab atas kerahasiaan Data Pembuatan Tanda Tangan Elektronik. PRIVY tidak bertanggungjawab atas segala akibat yang timbul dari kelalaian atau kesalahan Penanda Tangan dalam mengamankan Data Pembuatan Tanda Tangan Elektronik-nya, termasuk namun tidak terbatas pada kelalaian atau kesalahan Penanda Tangan sehingga <em>userID</em>&nbsp;dan<em>&nbsp;password</em>&nbsp;diketahui oleh pihak lain.</p>
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>4.6&nbsp;Tingkat ketersediaan Layanan: <em>Server</em>&nbsp;di sisi PRIVY akan tersedia dengan rata-rata minimum ketersediaan sebesar 99% (sembilan puluh sembilan persen) dalam satu tahun kalender, yang mana tidak termasuk pemeliharaan terjadwal. Waktu ketidaktersediaan yang disebabkan perawatan terjadwal akan diberitahukan selambat-lambatnya tujuh (7) hari kalender sebelum tanggal pemeliharaan yang direncanakan.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>4.7&nbsp;Setiap Pihak bertanggungjawab atas segala kerugian dan/atau kerusakan yang timbul dari kelalaian atau kesalahannya sendiri sehubungan dengan &nbsp;penerimaan dan penyimpanan Informasi Elektronik mengenai data pribadi Konsumen yang diminta atau diterima dari Pihak lainnya.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>4.8&nbsp;KERJASAMA.CO.ID&nbsp;menyatakan dan menjamin bahwa KERJASAMA.CO.ID&nbsp;telah mendapatkan izin-izin usahanya dari otoritas pemerintah yang berwenang untuk menjalankan kegiatan usahanya dan telah dan akan selalu mendapatkan persetujuan tertulis terlebih dahulu dari Konsumen atas penggunaan, pemrosesan, pengolahan dan penyimpanan data pribadi Konsumen oleh PRIVY untuk kepentingan pelaksanaan Perjanjian ini, dan KERJASAMA.CO.ID&nbsp;melepaskan dan membebaskan PRIVY dari setiap gugatan ganti rugi dan tuntutan yang diajukan oleh Konsumen atau pihak lain sehubungan dengan hal tersebut.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>4.9&nbsp;PRIVY tidak bertanggungjawab atas ketidaktersediaan sistem pembuatan Dokumen Elektronik dan/atau Tanda Tangan Elektronik yang disebabkan karena gangguan atau tidak berfungsinya sistem elektronik yang berada pada penguasaan KERJASAMA.CO.ID&nbsp;atau Perwakilannya.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>4.10&nbsp;PRIVY tidak bertanggung jawab atas keberlakuan Dokumen Elektronik terkait dengan dokumen yang menurut ketentuan hukum dan peraturan perundang-undangan yang berlaku harus dibuat dalam bentuk tertulis, dalam bentuk akta notaril atau akta yang dibuat oleh pejabat pembuat akta, atau harus dibubuhkan tanda tangan basah atau sidik jari.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>4.11&nbsp;PRIVY tidak bertanggung jawab atas isi dan keabsahan Kontrak Elektronik dan PRIVY bukanlah pihak dari suatu Kontrak Elektronik. Dengan demikian, KERJASAMA.CO.ID&nbsp;melepaskan dan membebaskan PRIVY dari setiap gugatan ganti rugi dan tuntutan yang diajukan oleh Konsumen sehubungan dengan isi dan keabsahan Kontrak Elektronik.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>4.12&nbsp;PRIVY bertanggungjawab untuk selalu melakukan verifikasi sesuai standar yang diterapkan PRIVY untuk tiap peringkat verifikasi. PRIVY tidak bertanggungjawab atas akurasi atau kebenaran identitas Konsumen yang telah diverifikasi oleh PRIVY, selama proses verifikasi telah dilakukan sesuai dengan standar yang diterapkan oleh PRIVY.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>PASAL</strong><strong>&nbsp;5</strong></p>
                    <p><strong>HAK KEKAYAAN INTELEKTUAL</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>5.1&nbsp;PRIVY akan tetap menjadi pemilik hak kekayaan intelektual (&ldquo;<strong>HKI</strong>&rdquo;) atas segala hal, baik yang ada saat ini maupun yang ada di masa mendatang, yang berkaitan dengan atau diciptakan dalam rangka pelaksanaan kewajibannya berdasarkan Perjanjian ini, termasuk semua kustomisasi pada Layanan. Penggunaan Layanan selain untuk tujuan sebagaimana dimaksud dalam Perjanjian ini merupakan pelanggaran HKI PRIVY.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>5.2&nbsp;PRIVY dapat menggunakan dan memasang logo dan nama perusahaan KERJASAMA.CO.ID&nbsp;untuk kepentingan komersial dan pemasarannya, baik pada media dalam jaringan (<em>online</em>) maupun pada media cetak tertulis (<em>offline</em>) yang digunakan PRIVY. Tindakan-tindakan tersebut akan dilakukan secara wajar dan dengan itikad baik tanpa tujuan untuk menyalahgunakan logo dan nama perusahaan dari KERJASAMA.CO.ID.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>PASAL 6</strong></p>
                    <p><strong>HARGA DAN KETENTUAN PEMBAYARAN</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>6.1&nbsp;Para Pihak sepakat &nbsp;bahwa jumlah biaya yang harus dibayar oleh KERJASAMA.CO.ID&nbsp;untuk pemakaian Layanan adalah sebagaimana dimuat di dalam Lampiran II dari Perjanjian ini dan jumlah biaya tersebut di luar pajak pertambahan nilai&nbsp;(selanjutnya disebut sebagai &ldquo;<strong>Harga</strong>&rdquo;).</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>6.2&nbsp;Rincian ketentuan pembayaran akan tertera dalam Lampiran II Perjanjian ini (&ldquo;<strong>Ketentuan Pembayaran</strong>&rdquo;)</p>
                    <p>&nbsp;</p>
                    <p>6.3&nbsp;Apabila Harga sudah termasuk biaya pelatihan/pengenalan Layanan PRIVY, maka pelatihan/pengenalan tersebut akan&nbsp;dilakukan di &nbsp;dalam wilayah DKI Jakarta untuk empat belas (14) Hari Kerja dalam satu tahun kalender pertama sejak tanggal Perjanjian ini. KERJASAMA.CO.ID&nbsp;dapat meminta pelatihan/pengenalan tambahan atas Layanan PRIVY di luar waktu yang diberikan di atas atau di tempat-tempat di luar wilayah DKI Jakarta atas biayanya sendiri dan pada waktu tertentu yang disepakati PRIVY sesuai ketersediaannya. Biaya dan pengeluaran untuk pelatihan tambahan tersebut akan disepakati secara terpisah oleh Para Pihak.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>6.4&nbsp;Harga tidak termasuk kustomisasi API atau pembuatan aplikasi, modifikasi tampilan dan fitur di luar standar PRIVY yang dapat diminta oleh KERJASAMA.CO.ID&nbsp;dalam waktu tiga (3) bulan&nbsp;sejak tanggal Perjanjian ini. &nbsp;PRIVY dapat menolak setiap permintaan tambahan tersebut jika tidak sesuai dengan standar PRIVY. Dalam hal PRIVY bersedia untuk memberikan layanan tambahan tersebut, PRIVY akan mengenakan biaya tambahan.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>6.5&nbsp;Apabila terjadi perpanjangan otomatis Perjanjian sebagaimana tertera dalam Pasal 3 maka KERJASAMA.CO.ID&nbsp;akan sepakat untuk membayarkan Harga dengan Ketentuan Pembayaran yang telah disepakati dalam Lampiran II Perjanjian untuk periode tambahan Jangka Waktu Perjanjian.</p>
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>6.6&nbsp;Semua pembayaran atas Harga akan dilakukan dalam mata uang Rupiah dan dengan cara transfer ke rekening bank PRIVY sebagai berikut:</p>
                    <p>&nbsp;</p>
                    <p><strong>Nama Bank: Bank Mandiri</strong></p>
                    <p><strong>Cabang: Katamso 13703</strong></p>
                    <p><strong>Nomor Rekening: 137.009.009.0917 (Rupiah)</strong></p>
                    <p><strong>Atas Nama: PT PRIVY IDENTITAS DIGITAL</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>6.7&nbsp;Biaya transfer yang dikenakan oleh bank ditanggung oleh Pihak yang mengirim uang.</p>
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>6.8&nbsp;Untuk keperluan administrasi pembayaran KERJASAMA.CO.ID&nbsp;wajib menyerahkan salinan Nomor Pokok Wajib Pajak (NPWP) kepada Privy.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>PASAL</strong><strong>&nbsp;7</strong></p>
                    <p><strong>PAJAK</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>7.1&nbsp;Masing-masing Pihak wajib menanggung dan membayar pajak-pajak yang berkaitan dengan pelaksanaan Perjanjian ini sesuai dengan peraturan perundang-undangan perpajakan yang berlaku.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>7.2&nbsp;Daftar Harga Produk yang tertera dalam Perjanjian ini belum termasuk Pajak Pertambahan Nilai (PPN) wajib dibayarkan oleh KERJASAMA.CO.ID&nbsp;secara penuh kepada PRIVY.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>7.3&nbsp;Pajak penghasilan (PPh) yang harus dipotong dari nilai tagihan PRIVY wajib disetorkan oleh KERJASAMA.CO.ID&nbsp;ke kas negara dan KERJASAMA.CO.ID&nbsp;harus segera memberikan bukti pemotongan pajak tersebut kepada PRIVY selambat-lambatnya tiga puluh (30) hari kalender sejak tanggal pembayaran tagihan atas Harga kepada PRIVY.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>PASAL</strong><strong>&nbsp;8</strong></p>
                    <p><strong>KERAHASIAAN</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>8.1&nbsp;Setiap Pihak setuju untuk menjaga kerahasiaan seluruh data dan informasi yang diberikan oleh Pihak lainnya (baik lisan maupun tulisan, dalam bentuk apapun, dan disimpan dalam media apapun), semua komunikasi dan korespondensi sehubungan dengan Layanan, spesifikasi, harga, hak kekayaan intelektual (baik yang terdaftar maupun tidak), data dan informasi teknis (antara lain rahasia dagang, metode, pengetahuan teknis (<em>know-how</em>), rumus, komposisi, proses, penemuan baru, mesin, model, desain, perangkat, spesifikasi, karakteristik suatu produk atau layanan, invensi, program komputer, riset dan sejenisnya), data dan informasi ketenagakerjaan dan/atau Perjanjian ini atau yang didapatkan oleh setiap Perwakilannya (sebagaimana didefinisikan di dalam Perjanjian ini) (&ldquo;<strong>Informasi Rahasia</strong>&rdquo;), dan untuk tidak menggunakan semua Informasi Rahasia tersebut untuk setiap tujuan selain yang dimaksud dalam Perjanjian ini. &nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>8.2&nbsp;Tanpa mengabaikan ketentuan di atas, apabila salah satu Pihak membuka Informasi Rahasia Pihak lainnya karena diwajibkan oleh hukum atau oleh suatu keputusan pengadilan atau oleh penyidik, maka hal tersebut bukanlah merupakan pelanggaran Perjanjian ini.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>8.3&nbsp;Masing-masing Pihak harus memastikan bahwa setiap Pewakilannya atau setiap orang yang menerima Informasi Rahasia akan menjaga kerahasiaan Informasi Rahasia dan tidak akan menyebarluaskan, menggandakan, atau menyalin Informasi Rahasia untuk setiap tujuan selain yang dimaksud dalam Perjanjian ini.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>PASAL</strong><strong>&nbsp;9</strong></p>
                    <p><strong>PERNYATAAN DAN JAMINAN</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>9.1&nbsp;Pada tanggal Perjanjian ini dan pada setiap waktu selama berlakunya Perjanjian ini, masing-masing Pihak menyatakan dan menjamin kepada<strong>&nbsp;</strong>Pihak lainnya hal-hal sebagai berikut:</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>(a)&nbsp;ia&nbsp;adalah suatu perseroan terbatas yang didirikan secara sah berdasarkan hukum Republik Indonesia yang mempunyai kekuasaan dan wewenang penuh untuk menandatangani dan melaksanakan segala kewajibannya berdasarkan Perjanjian ini;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>(b)&nbsp;ia&nbsp;telah memperoleh semua persetujuan dan kewenangan, termasuk namun tidak terbatas pada persetujuan korporasi (sebagaimana ditentukan dalam anggaran dasarnya) untuk secara sah menandatangani dan melaksanakan seluruh kewajibannya berdasarkan Perjanjian ini;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>(c)&nbsp;penandatanganan, penyerahan, dan pelaksanaan segala kewajibannya berdasarkan Perjanjian ini tidak akan menyebabkan pelanggaran terhadap (i) suatu ketentuan dalam anggaran dasarnya; (ii) suatu perjanjian dimana ia merupakan pihak; (iii) suatu perintah, keputusan, atau penetapan yang diterbitkan oleh pengadilan atau pemerintah; (iv) hak kekayaan intelektual pihak lain, dan (v) ketentuan peraturan perundang-undangan yang berlaku; dan</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>(d)&nbsp;Perjanjian ini telah ditandatangani dengan sah dan setiap syarat dan ketentuan di dalam mengikat bagi dirinya.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>9.2&nbsp;Setiap pernyataan dan jaminan yang disebutkan di dalam pasal ini maupun di setiap bagian di dalam Perjanjian ini dibuat pada tanggal Perjanjian ini dan dianggap diulang oleh Pihak yang membuatnya setiap saat sampai dengan berakhirnya Perjanjian ini.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>PASAL</strong><strong>&nbsp;10</strong></p>
                    <p><strong>KEADAAN KAHAR</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>10.1&nbsp;Suatu Pihak tidak bertanggung jawab atas keterlambatan atau kegagalan pemenuhan kewajibannya berdasarkan Perjanjian ini, jika keterlambatan atau kegagalan tersebut disebabkan karena suatu Keadaan Kahar.</p>
                    <p>&nbsp;</p>
                    <p>&ldquo;<strong>Keadaan Kahar</strong>&rdquo; dapat diartikan sebagai suatu keadaan tanpa kesalahan ataupun kelalaian yang diakibatkan oleh salah satu Pihak yang terjadi di luar kendali Pihak tersebut, dimana Pihak tersebut secara wajar tidak mampu untuk mencegah atau mengatasinya, termasuk namun tidak terbatas pada&nbsp;bencana alam, perang (baik dideklarasikan maupun tidak), invasi, konflik bersenjata, kerusuhan, demonstrasi, revolusi atau kudeta, tindak terorisme, sabotase atau kerusakan akibat kriminalisme, ledakan nuklir, radioaktif atau kontaminasi kimia atau ionisasi radiasi, tekanan gelombang akibat pesawat terbang atau benda melayang lainnya yang berkecepatan suara atau diatas kecepatan suara, gangguan listrik, gangguan sistem atau jaringan pihak ketiga lainnya atau perubahan peraturan perundang-undangan atau kebijakan pemerintah yang dapat mempengaruhi secara material kemampuan salah satu Pihak ataupun Para Pihak untuk memenuhi kewajibannya berdasarkan Perjanjian ini.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>10.2&nbsp;Dalam hal salah satu Pihak tidak mampu untuk memenuhi kewajibannya dalam Perjanjian ini dengan alasan Keadaan Kahar, Pihak &nbsp;tersebut harus memberitahukan secara tertulis kepada Pihak lainnya selambat-lambatnya dalam waktu tujuh (7) hari kalender setelah terjadinya Keadaan Kahar tersebut disertai dengan bukti-bukti yang berhubungan dengan Keadaan Kahar tersebut. Kelalaian dalam melakukan pemberitahuan Keadaan Kahar menyebabkan tidak diakuinya alasan Keadaan Kahar oleh Pihak lainnya.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>PASAL 11</strong></p>
                    <p><strong>PENGAKHIRAN PERJANJIAN</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>11.1&nbsp;Suatu Pihak dapat mengakhiri Perjanjian ini apabila:</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>(a)&nbsp;Pihak lainnya melakukan pelanggaran yang bersifat material atas Perjanjian ini dan gagal untuk melakukan upaya perbaikan dalam waktu sepuluh (10) Hari Kerja setelah suatu pemberitahuan tertulis mengenai materi atau kondisi yang merupakan pelanggaran yang bersifat material telah diterima oleh Pihak yang melakukan pelanggaran;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>(b)&nbsp;salah satu Pihak memasukkan permohonan untuk penundaan kewajiban pembayaran utang, pailit, dipailitkan, atau memulai proses likuidasi atau pembubaran, baik atas permintaan sendiri atau karena perintah pengadilan;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>(c)&nbsp;salah satu Pihak melanggar atau gagal untuk memenuhi ketentuan hukum yang berlaku, regulasi, atau perintah dari pengadilan atau otoritas pemerintahan yang kompeten yang mengakibatkan Perjanjian ini menjadi tidak dapat dilaksanakan atau jika dilaksanakan akan menimbulkan suatu pelanggaran hukum oleh Pihak yang tetap melaksanakan Perjanjian ini; atau</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>(d)&nbsp;terjadi perubahan kebijakan atau peraturan perundang-undangan yang berlaku yang melarang PRIVY untuk menyediakan sebagian atau seluruh layanan berdasarkan Perjanjian ini.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>11.1&nbsp;Dalam hal terjadi pengakhiran Perjanjian ini, Para Pihak sepakat mengesampingkan ketentuan-ketentuan dalam Pasal 1266 dan Pasal 1267 Kitab Undang-Undang Hukum Perdata Indonesia mengenai diperlukannya suatu putusan pengadilan untuk mengakhiri suatu perjanjian.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>11.3&nbsp;Dengan tetap merujuk pada Pasal 11.1, dalam hal berakhirnya jangka waktu atau pengakhiran Perjanjian ini:</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>(a)&nbsp;PRIVY wajib menyerahkan seluruh Layanan yang telah dibayar namun belum diserahkan kepada KERJASAMA.CO.ID; dan</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>(b)&nbsp;KERJASAMA.CO.ID&nbsp;wajib segera membayar tagihan atas Layanan yang telah diberikan kepada KERJASAMA.CO.ID&nbsp;namun belum dibayar kepada PRIVY.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>PASAL</strong><strong>&nbsp;12</strong></p>
                    <p><strong>PEMBERITAHUAN</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>12.1&nbsp;Kecuali dinyatakan lain dalam Perjanjian ini, semua surat menyurat atau pemberitahuan- pemberitahuan antara Para Pihak dalam Perjanjian ini mengenai atau sehubungan dengan Perjanjian akan dilakukan dengan pos tercatat atau melalui perusahaan ekspedisi (kurir), atau email ke alamat-alamat tersebut di bawah ini:</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>PT [NAMA PERSEROAN]</strong></p>
                    <p>Alamat :&nbsp;Sinpasa Commercial Block B-27, Summarecon Bekasi, Jalan Bulevar Selatan No. 5, Marga Mulya, Bekasi Utara, Kota Bekasi, Jawa Barat, 17143, Indonesia</p>
                    <p>Telepon :&nbsp; (021) 89466000</p>
                    <p>E-mail :&nbsp; prasetyo@kerjasama.com</p>
                    <p>U.p. :&nbsp;Prasetyo Adi Nugroho (Presiden Direktur)</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>12.1&nbsp;Jika terjadi perubahan alamat dari alamat tersebut dan/atau alamat terakhir yang tercatat pada masing-masing Pihak, maka Pihak yang mengubah alamat wajib memberitahukan secara tertulis perubahan tersebut kepada Pihak lainnya dalam jangka waktu selambat-lambatnya 7 (tujuh) Hari Kerja sebelum perubahan alamat dimaksud berlaku efektif. Jika perubahan alamat tersebut tidak diberitahukan, maka surat menyurat, pemberitahuan dan/atau permintaan berdasarkan Perjanjian ini dianggap telah diberikan dengan semestinya dengan pos tercatat, melalui perusahaan ekspedisi (kurir) yang ditujukan ke alamat diatas atau alamat terakhir yang diketahui/tercatat pada masing-masing Pihak.</p>
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>12.2&nbsp;Surat menyurat, pemberitahuan dan/atau komunikasi ke alamat tersebut di atas, dianggap telah diterima dengan ketentuan sebagai berikut:</p>
                    <p>(a)&nbsp;pada hari yang sama apabila diserahkan langsung yang dibuktikan dengan tanda tangan penerima pada buku pengantar surat/ekspedisi atau tanda terima lain yang diterbitkan oleh pengirim;</p>
                    <p>&nbsp;</p>
                    <p>(b)&nbsp;pada hari ketiga, apabila pemberitahuan tersebut dikirimkan melalui pos tercatat yang dibuktikan dengan resi pengiriman pos tercatat; dan</p>
                    <p>&nbsp;</p>
                    <p>(c)&nbsp;pada hari yang sama, apabila dikirim melalui surat elektronik (<em>email</em>) yang dibuktikan dengan status terkirim dari alat yang bersangkutan.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>PASAL</strong><strong>&nbsp;13</strong></p>
                    <p><strong>PENGALIHAN</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>Perjanjian ini, baik seluruhnya maupun sebagian, tidak dapat dialihkan kepada pihak manapun&nbsp;tanpa&nbsp;persetujuan tertulis terlebih dahulu dari Pihak lainnya.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>PASAL</strong><strong>&nbsp;14</strong></p>
                    <p><strong>HUKUM YANG BERLAKU DAN PENYELESAIAN SENGKETA</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>14.1&nbsp;Perjanjian ini dan hak dan kewajiban dari Para Pihak dalam Perjanjian ini akan diatur oleh dan ditafsirkan menurut hukum Republik Indonesia.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>14.1&nbsp;Segala sengketa yang timbul dari atau yang berkaitan dengan Perjanjian ini (termasuk segala kewajiban non-kontraktual yang timbul dari atau yang berkaitan dengan Perjanjian ini dan segala permasalahan sehubungan dengan keberadaan, keabsahan atau pembatalan Perjanjian ini) akan dirujuk ke dan diselesaikan oleh badan arbitrase menurut peraturan prosedur arbitrase pada Badan Arbitrase Nasional Indonesia ("<strong>BANI</strong>") yang pada tanggal Perjanjian ini beralamat di Wahana Graha Lantai 1 dan 2, Jl. Mampang Prapatan No. 2 Jakarta 12760, dengan ketentuan sebagai berikut:</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>a.&nbsp;Bahasa yang digunakan dalam arbitrase adalah Bahasa Indonesia;</p>
                    <p>b.&nbsp;Tempat arbitrase adalah di Jakarta, Indonesia;</p>
                    <p>c.&nbsp;Para Pihak akan secara bersama-sama mengangkat satu (1) orang arbiter yang akan menjadi arbiter tunggal untuk menyelesaikan sengketa;</p>
                    <p>d.&nbsp;Biaya arbitrase dan biaya hukum wajib ditanggung oleh Pihak yang kalah; dan</p>
                    <p>e.&nbsp;Putusan arbitrase bersifat final dan mengikat Para Pihak.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>PASAL</strong><strong>&nbsp;15</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>BAHASA</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>Para Pihak sepakat bahwa versi Bahasa Indonesia dari Perjanjian ini adalah bahasa yang mengatur Perjanjian ini untuk semua tujuan (termasuk, untuk menghilangkan keragu-raguan, jika terdapat inkonsistensi antara versi Bahasa Inggris dengan versi Bahasa Indonesia) dan versi Bahasa Inggris dibuatkan hanya semata-mata untuk acuan Para Pihak.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>PASAL 16</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>KETIDAKSAHAN, KETIDAKBERLAKUAN ATAU TIDAK DAPAT DILAKSANAKAN</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>Jika sewaktu-waktu suatu bagian apapun dari Perjanjian ini menjadi tidak sah, tidak berlaku atau tidak dapat dilaksanakan, maka keabsahan, keberlakuan dan kekuatan pelaksanaan ketentuan-ketentuan lain dalam Perjanjian ini tidak akan terpengaruh atau terhalang karenanya, dan Para Pihak akan menandatangani perubahan atas hal tersebut, yang mana bersamaan dengan ketentuan lain akan memberikan hak dan kewajiban Para Pihak yang sejauh mungkin akan sama atau mendekati ketentuan-ketentuan yang tidak sah, tidak berlaku atau tidak dapat dilaksanakan tersebut.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>PASAL</strong><strong>&nbsp;17</strong></p>
                    <p><strong>KESELURUHAN PERJANJIAN DAN PERUBAHAN</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>17.1&nbsp;Perjanjian ini mencakup seluruh kesepakatan antara Para Pihak dan menggantikan seluruh kesepakatan terdahulu dan/atau pemahaman Para Pihak yang dilakukan secara lisan maupun tertulis sehubungan dengan pokok permasalahan dalam Perjanjian ini.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>17.2&nbsp;Tidak ada perubahan atau pengesampingan atas Perjanjian ini yang dapat berlaku kecuali dalam bentuk tertulis dan ditandatangani oleh Para Pihak.</p>
                    <p>&nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>17.3&nbsp;Semua lampiran dan spesifikasi yang disebutkan dalam Perjanjian ini, semua dokumen yang harus diserahkan berdasarkan Perjanjian ini dan syarat dan ketentuan terkait penggunaan PrivyID, merupakan satu kesatuan dan bagian yang tidak terpisahkan dari Perjanjian ini. &nbsp;</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>PASAL</strong><strong>&nbsp;18</strong></p>
                    <p><strong>KEBERLAKUAN TERUS MENERUS</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>Ketentuan-ketentuan dalam Pasal-Pasal 4, 5, 7, 11, 12, 14, 15, 17, 18 dan pasal-pasal lainnya dari Perjanjian ini yang ditujukan untuk berlaku secara terus menerus terlepas dari pengakhiran Perjanjian ini akan tetap berlaku terlepas dari pengakhiran atau daluarsa dari Perjanjian ini.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>PASAL</strong><strong>&nbsp;19</strong></p>
                    <p><strong>PENANDATANGANAN TERPISAH</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p>Perjanjian ini dapat ditandatangani secara terpisah oleh masing-masing Pihak, dan ketika ditandatangani maka akan dianggap menjadi satu kesatuan dokumen asli yang tidak terpisahkan dari Perjanjian ini.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td width="389">
                    <p><strong>DEMIKIANLAH</strong>&nbsp;Perjanjian ini ditandatangani oleh Para Pihak dalam 2 (dua) rangkap pada hari dan tanggal tersebut di atas.</p>
                    </td>
                    <td width="37">&nbsp;</td>
                    </tr>
                    <tr>
                    <td colSpan="2" width="427">
                    <p><strong>&nbsp;</strong></p>
                    </td>
                    </tr>
                    <tr>
                    <td colSpan="2" width="427">
                    <p>For and on behalf of:</p>
                    <p><strong>PT </strong><strong>KERJASAMA UNTUK NEGERI</strong></p>
                    </td>
                    </tr>
                    <tr>
                    <td colSpan="2" width="427">
                    <p>Name/Nama : Prasetyo Adi Nugroho</p>
                    </td>
                    </tr>
                    <tr>
                    <td colSpan="2" width="427">
                    <p>Title/Jabatan : Presiden Direktur</p>
                    </td>
                    </tr>
                    </tbody>
                    </table>
                </div>  <br/>
                <p className="subheadprivy">Bersedia buat privyID dari data - data tersebut?</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="red" onClick={openMdlTolakPrivy} circular>Tidak</Button>
                    <Button
                    color="green"
                    // icon='checkmark'
                    circular
                    // labelPosition='right'
                    content='Setuju'
                    onClick={submitTagihan}
                    />
                    <Link to={{ pathname: "/Pembayaran/TagihanVA", data: {
                        dataSlot:dataSlot,totalSlot:totalSlot,totalPembayaran:totalPembayaran,
                        dataProyek:dataSlot[0],dataPembayaran:dataPembayaran
                    } }}>
                        <Button id="btnPembayaranAuto" className="col-md-11 bayarButton" size='medium' color="blue" style={{display:'none'}}>Sudah</Button>
                    </Link>
                </Modal.Actions>
            </Modal>
            
            <Modal className="modalTolakPrivy" size={size} open={openTolakPrivy} onClose={closeTolakPrivy} closeIcon closeOnEscape={closeOnEscape} closeOnDimmerClick={closeOnDimmerClick}>
                <Modal.Header>Apa Alasannya ?</Modal.Header>
                <Modal.Content>
                    <Form>
                        <TextArea placeholder='Ketik Alasannya di sini..' />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                    color="green"
                    circular
                    content='Submit'
                    onClick={closeTolakPrivy}
                    />
                    <Link to={{ pathname: "/Pembayaran/TagihanVA", data: {
                        dataSlot:dataSlot,totalSlot:totalSlot,totalPembayaran:totalPembayaran,
                        dataProyek:dataSlot[0],dataPembayaran:dataPembayaran
                    } }}>
                        <Button id="btnPembayaranAuto" className="col-md-11 bayarButton" size='medium' color="blue" style={{display:'none'}}>Submit</Button>
                    </Link>
                </Modal.Actions>
            </Modal>

    </div>
    )
}

    


function mapStateToProps(state) {
    return {
        getUserpemodal: state.userReducer.getUserpemodal,
        auth: state.authReducer.authData
    }
}

function mapDispatchToProps(dispatch) {
    return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(BayarStep2);