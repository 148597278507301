import React, { Component, memo, useState } from "react";
import {
  Modal,
  Form,
  Alert,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import "../../../App.scss";
import "../../../AppMobile.scss";
import { BASE_URL, tokenGet } from "../../../service/api";
import img_avatar from "../../../assets/img/img_avatar.png";
import seru2 from "../../../assets/img/Group 895.png";
import change_img from "../../../assets/img/notfound.jpg";
import $ from "jquery";
import axios from "axios";
import {
  Button,
  Reveal,
  Dropdown,
  Portal,
  Checkbox,
  Grid,
  Header,
  Icon,
  Image,
  Menu,
  Segment,
  Sidebar,
  Input,
  Label,
  List,
} from "semantic-ui-react";
import { SettingsEthernet } from "@material-ui/icons";
import swal from "sweetalert";
import { show } from "topbar";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";
import { Calendar, utils } from "react-modern-calendar-datepicker";
import dateFormat, { masks } from "dateformat";

import { getUserpemodal } from "../../../actions/userpemodal.actions";
import { connect } from "react-redux";

// export default class SidebarProfilePemodal extends Component {
class SidebarProfilePemodal extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    // 1. bind your functions in the constructor.
    this.mouseOver = this.mouseOver.bind(this);
    this.mouseOut = this.mouseOut.bind(this);
    this.state = {
      hover: false,
      animation: "push",
      direction: "left",
      dataBank: [],
      dimmed: false,
      visible: true,
      copySuccess: "Copied!",
      change_img: change_img,
      img_profile: img_avatar,
      openPortal: false,
      totalInvest: 0,
      totalShareProfit: 0,
      errors: {},
      profileShow: 0,
      dataCountries: [],
      dataProvince: [],
      dataDistrict: [],
      dataSubDistrict: [],
      dataProvinceOther: [],
      dataDistrictOther: [],
      dataSubDistrictOther: [],
      tipePemodal: null,
      userinfo: this.props.userinfo,
    };
    this.increaseCount = this.increaseCount.bind(this);
  }

  increaseCount() {
    this.setState({ count: this.count + 1 });
  }

  initLengkapiData = (param) => {
    this.state.tipePemodal = param;
  };

  handleAnimationChange = (animation) => () =>
    this.setState((prevState) => ({ animation, visible: !prevState.visible }));

  handleDimmedChange = (e, { checked }) => this.setState({ dimmed: checked });

  handleDirectionChange = (direction) => () =>
    this.setState({ direction, visible: false });

  copyToClipboard = (e) => {
    this.refCode.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
  };

  handleInputChange = (e) => {
    this.setState({ value: e.target.value });
  };

  getUserpemodal = async () => {
    var values = { token: localStorage.token };

    try {
      const response = await this.props.dispatch(getUserpemodal(values));

      if (!response.responseBody.success) {
        throw response;
      } else if (response.responseBody.success) {
        this.setState({ userinfo: JSON.stringify(response.responseBody.user) });
      }
    } catch (error) {
      console.log("ERROR >> " + JSON.stringify(error));
      // setTimeout(() => {
      //   window.location.href = "/Signin"
      //   localStorage.removeItem('token');
      //   localStorage.clear();

      //   this.props.dispatch({
      //     type: "AUTH_USER_LOGOUT",
      //   })
      // }, 2000);
    }
  };

  getTotalInvest() {
    const userinfo = JSON.parse(this.props.userinfo);

    axios
      .get(
        BASE_URL + "/customer/pemodal/pymntpemodal",
        {
          params: {
            user_id: userinfo.user_id,
          },
        },
        (axios.defaults.headers.common["Authorization"] = localStorage.token),
        (axios.defaults.headers.common["Accept"] = "application/json")
      )
      .then((resp) => {
        this.setState({
          totalInvest: resp.data.totalInvest
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          totalShareProfit: resp.data.totalShareProfit
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        });
      })
      .catch((resp) => {});
  }

  getDataBank() {
    axios
      .get(
        BASE_URL + "/allbanks",
        (axios.defaults.headers.common["Authorization"] = tokenGet),
        (axios.defaults.headers.common["Accept"] = "application/json")
      )
      .then((resp) => {
        this.setState({
          dataBank: resp.data.data,
        });
        this.getTotalInvest();
      })
      .catch((resp) => {
        this.setState({
          dataBank: [],
        });
      });
  }

  getDataCountries() {
    axios
      .get(
        BASE_URL + "/customer/countries",
        {},
        (axios.defaults.headers.common["Authorization"] = localStorage.token),
        (axios.defaults.headers.common["Accept"] = "application/json")
      )
      .then((resp) => {
        this.setState({
          dataCountries: resp.data.data,
        });
        // console.log(JSON.stringify(this.state.dataCountries))
      })
      .catch((resp) => {});
  }

  getDataProvince = (param, id) => {
    axios
      .get(
        BASE_URL + "/customer/province?id=" + id,
        {},
        (axios.defaults.headers.common["Authorization"] = localStorage.token),
        (axios.defaults.headers.common["Accept"] = "application/json")
      )
      .then((resp) => {
        if (param == 1) {
          this.setState({ dataProvince: resp.data.data });
        } else {
          this.setState({ dataProvinceOther: resp.data.data });
        }
      })
      .catch((resp) => {
        console.log("GET PROVINCE GAGAL >> " + resp);
      });
  };

  getDataDistrict = (param, id) => {
    axios
      .get(
        BASE_URL + "/customer/district?id=" + id,
        {},
        (axios.defaults.headers.common["Authorization"] = localStorage.token),
        (axios.defaults.headers.common["Accept"] = "application/json")
      )
      .then((resp) => {
        if (param == 1) {
          this.setState({ dataDistrict: resp.data.data });
        } else {
          this.setState({ dataDistrictOther: resp.data.data });
        }
      })
      .catch((resp) => {
        console.log("GET DISTRICT GAGAL >> " + resp);
      });
  };

  getDataSubDistrict = (param, id) => {
    axios
      .get(
        BASE_URL + "/customer/districtsub?id=" + id,
        {},
        (axios.defaults.headers.common["Authorization"] = localStorage.token),
        (axios.defaults.headers.common["Accept"] = "application/json")
      )
      .then((resp) => {
        if (param == 1) {
          this.setState({ dataSubDistrict: resp.data.data });
        } else {
          this.setState({ dataSubDistrictOther: resp.data.data });
        }
      })
      .catch((resp) => {
        console.log("GET SUB DISTRICT GAGAL >> " + resp);
      });
  };

  componentDidMount() {
    var dataUser = JSON.parse(localStorage.DataUser);

    dataUser.user.dokumen.map((a, i) => {
      if (a.type == "profile") {
        this.setState({
          img_profile: a.nama_file,
        });
      }
    });

    // this.getUserpemodal()

    this.getDataCountries();
    this.getDataBank();

    var country =
      dataUser.user.country_code == null ? "360" : dataUser.user.country_code;
    var province = dataUser.user.province;
    var district = dataUser.user.district;
    this.getDataProvince(1, country);
    this.getDataDistrict(1, province);
    this.getDataSubDistrict(1, district);

    var countryOther = dataUser.user.country_code_other;
    var provinceOther = dataUser.user.province_other;
    var districtOther = dataUser.user.district_other;
    this.getDataProvince(2, countryOther);
    this.getDataDistrict(2, provinceOther);
    this.getDataSubDistrict(2, districtOther);
  }

  mouseOver = () => {
    this.setState({ hover: true });
  };

  mouseOut() {
    this.setState({ hover: false });
  }

  onFileChange(e, param) {
    var files = e.target.files;
    if (!files.length) return;
    this.createImage(files[0], param);
  }

  createImage(file, param) {
    var tmp = [];
    var reader = new FileReader();

    reader.onload = (e) => {
      if (param == 111) {
        this.setState({
          img_profile: e.target.result,
        });
        this.uplFotoPic();
      }
    };
    reader.readAsDataURL(file);
  }

  uplFotoPic() {
    const userinfo = JSON.parse(this.props.userinfo);

    var values = {
      file: this.state.img_profile,
      user_id: userinfo.user_id,
    };

    let config = {
      headers: {
        Authorization: localStorage.token,
        "content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Methods": "POST",
      },
      maxContentLength: 52428890,
      timeout: 10000000,
    };

    axios
      .post(BASE_URL + "/customer/pemodal/uplfotopic", values, config)
      .then((resp) => {
        this.setState({
          openPortal: true,
        });

        setTimeout(() => {
          this.setState({
            openPortal: false,
          });
        }, 3000);
      })
      .catch((resp) => {});
  }
  clearConsole() {
    if (window.console || window.console.firebug) {
      console.clear();
    }
  }

  handleClosePortal = () => this.setState({ openPortal: false });

  handleClick = (param) => {
    // alert('The link was clicked >> ' + param);
    this.setState({ profileShow: param });
  };

  clearArrayProvinceEtc = (param) => {
    if (param == 1) {
      this.state.dataProvince = new Array();
      this.state.dataDistrict = new Array();
      this.state.dataSubDistrict = new Array();
    } else {
      this.state.dataProvinceOther = new Array();
      this.state.dataDistrictOther = new Array();
      this.state.dataSubDistrictOther = new Array();
    }
  };

  render() {
    // this.clearConsole();
    const {
      animation,
      dimmed,
      direction,
      visible,
      change_img,
      img_profile,
      openPortal,
      totalInvest,
      totalShareProfit,
    } = this.state;
    const vertical = direction === "bottom" || direction === "top";
    const userinfo = JSON.parse(this.state.userinfo); //JSON.parse(this.props.userinfo)
    const verified = userinfo.ktp !== null ? 1 : 0;
    const dataIsComplete = userinfo.is_complete;
    const verified_advance = userinfo.status_user;
    const agreement = userinfo.agreement;
    const signature_status = userinfo.signature_status;
    const addressDefinitions = this.state.dataBank;
    const stateOptions = addressDefinitions.map((state) => ({
      key: state.id,
      text: state.nama_bank,
      value: state.nama_bank,
    }));

    this.state.tipePemodal = userinfo.tipe_pemodal;

    // console.log('TIPEPEMODAL : ' + this.state.tipePemodal)

    // lengkapiData = async (values) => {
    const lengkapiData = async (values) => {
      // console.log("POST_DATA: " + JSON.stringify(values))
      // return;

      let config = {
        headers: {
          Authorization: localStorage.token,
          "content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods": "POST",
        },
        maxContentLength: 52428890,
        timeout: 10000000,
      };

      $(".btnSimpan").addClass("loading");
      $(".btnSimpan").addClass("disabled");
      $(".alertSuccessSubmit").attr("style", "");

      axios
        .post(BASE_URL + "/customer/pemodal/lengkapidata", values, config)
        .then((resp) => {
          // alert(resp.data.body.message);
          // setTimeout(() => {
          //   window.location.reload(true);
          // }, 0)
          swal({
            text: resp.data.body.message,
            icon: "success",
            closeOnClickOutside: false,
          }).then((value) => {
            setTimeout(() => {
              window.location.reload(true);
            }, 0);
          });
        })
        .catch((error) => {
          if (error.response.data.error_message) {
            // alert('Credit: ' + error.response.data.error_message.credit + ', Fraud: ' + error.response.data.error_message.fraud + ', Multiplatform: ' + error.response.data.error_message.multiplatform);
            swal({
              text:
                "Credit: " +
                error.response.data.error_message.credit +
                ", Fraud: " +
                error.response.data.error_message.fraud +
                ", Multiplatform: " +
                error.response.data.error_message.multiplatform,
              icon: "error",
            });
          } else if (error.response.data.success === false) {
            console.log("RESPONSE >> " + JSON.stringify(error.response.data));
            // alert(error.response.data.body.message);
            swal({ text: error.response.data.body.message, icon: "error" });
          } else {
            // alert('Request Timeout')
            swal({ text: "Request Timeout", icon: "error" });
          }
          $(".btnSimpan").removeClass("loading");
          $(".btnSimpan").removeClass("disabled");
          $(".alertSuccessSubmit").attr("style", "display:none");
        });
    };

    const changeImgProfile = (e) => {
      $("input[id='my_file']").click();
    };

    const addDefaultSrc = (ev) => {
      ev.target.src = img_avatar;
      ev.target.onerror = null;
    };

    return (
      <Container className="sidebarUser">
        <Row className="justify-content-md-center">
          <Col xs lg="12">
            <Grid columns={2}>
              <Grid.Column>
                <Portal onClose={this.handleClosePortal} open={openPortal}>
                  <Segment
                    style={{
                      left: "40%",
                      position: "fixed",
                      top: "15%",
                      zIndex: 1000,
                    }}
                  >
                    <Header>
                      <Icon name="check circle" color="green" /> Update
                      Berhasil...
                    </Header>
                    <p>Selamat Ubah Foto Profil Berhasil.</p>
                  </Segment>
                </Portal>
              </Grid.Column>
            </Grid>

            <Sidebar.Pushable className="mPushable" as={Segment}>
              <Sidebar
                as={Menu}
                animation={animation}
                direction={direction}
                icon="labeled"
                vertical
                visible={visible}
                width="thin"
                className="displayProfile"
              >
                <Menu.Item>
                  {verified > 0 ? (
                    <>
                      <div
                        className="grid-box"
                        onMouseOver={this.mouseOver.bind(this)}
                        onMouseOut={this.mouseOut.bind(this)}
                      >
                        {this.state.hover ? (
                          <>
                            <Image
                              htmlFor="embedpollfileinput"
                              src={change_img}
                              onError={(e) => addDefaultSrc(e)}
                              className="imgProfilePicCg"
                              size="small"
                              verticalAlign="middle"
                              circular
                              href="#"
                              onClick={changeImgProfile}
                              title="Ubah Foto Profil"
                            />
                            <input
                              type="file"
                              id="my_file"
                              onChange={(event) => {
                                this.onFileChange(event, 111);
                              }}
                              style={{ display: "none" }}
                            />
                          </>
                        ) : (
                          <Image
                            src={img_profile}
                            onError={(e) => addDefaultSrc(e)}
                            className="imgProfilePic"
                            size="small"
                            verticalAlign="middle"
                            circular
                          />
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="grid-box">
                      <Image
                        src={img_profile}
                        onError={(e) => addDefaultSrc(e)}
                        className="imgProfilePic"
                        size="small"
                        verticalAlign="middle"
                        circular
                      />
                    </div>
                  )}
                  <h5 id="fonts" className="nama" style={{ marginTop: 20 }}>
                    {userinfo.nama_depan}
                    {userinfo.nama_tengah == null
                      ? ""
                      : userinfo.nama_tengah == ""
                      ? ""
                      : " " + userinfo.nama_tengah}
                    {userinfo.nama_belakang == null
                      ? ""
                      : userinfo.nama_belakang == ""
                      ? ""
                      : " " + userinfo.nama_belakang}
                  </h5>
                  <span id="fonts" className="namaID">
                    ID : {userinfo.user_id}
                  </span>
                  {/* <Header id="fonts" textAlign='left' as='h5' id="fonts" color="blue" >KODE REFERRAL</Header>
              
              {document.queryCommandSupported('copy') &&
              <Input id="fonts" size="mini" className="inputRef"
                  ref={(refcode) => this.refCode = refcode}
                  action={{
                  color: 'blue',
                  labelPosition: 'right',
                  content: 'COPY',
                  icon: 'copy',
                  size:'mini',
                  onClick: () => this.copyToClipboard()
                  }}
                  defaultValue={userinfo.refferal_code}
                  onChange={this.handleInputChange}
              />
              } */}

                  {userinfo.is_complete == 5 && this.state.tipePemodal == 2 && (
                    <div id="fonts" className="informasiPribadi">
                      <Header id="fonts" as="h6" color="blue" size="mini">
                        <Icon name="user" />
                        <Header.Content>
                          <a href="#" onClick={this.handleClick.bind(this, 0)}>
                            Profil Saya
                          </a>
                        </Header.Content>
                      </Header>
                      <Header id="fonts" as="h6" color="blue" size="mini">
                        <Icon name="address book" />
                        <Header.Content>
                          <a href="#" onClick={this.handleClick.bind(this, 1)}>
                            Data Pribadi
                          </a>
                        </Header.Content>
                      </Header>
                      <Header id="fonts" as="h6" color="blue" size="mini">
                        <Icon name="building" />
                        <Header.Content>
                          <a href="#" onClick={this.handleClick.bind(this, 2)}>
                            Data Perusahaan
                          </a>
                        </Header.Content>
                      </Header>
                      <Header id="fonts" as="h6" color="blue" size="mini">
                        <Icon name="credit card" />
                        <Header.Content>
                          <a href="#" onClick={this.handleClick.bind(this, 3)}>
                            Data Bank
                          </a>
                        </Header.Content>
                      </Header>
                    </div>
                  )}
                  {userinfo.is_complete == 5 && this.state.tipePemodal == 1 && (
                    <div id="fonts" className="informasiPribadi">
                      <Header id="fonts" as="h6" color="blue" size="mini">
                        <Icon name="user" />
                        <Header.Content>
                          <a href="#" onClick={this.handleClick.bind(this, 0)}>
                            Profil Saya
                          </a>
                        </Header.Content>
                      </Header>
                      <Header id="fonts" as="h6" color="blue" size="mini">
                        <Icon name="address book" />
                        <Header.Content>
                          <a href="#" onClick={this.handleClick.bind(this, 1)}>
                            Data Pribadi
                          </a>
                        </Header.Content>
                      </Header>
                      <Header id="fonts" as="h6" color="blue" size="mini">
                        <Icon name="credit card" />
                        <Header.Content>
                          <a href="#" onClick={this.handleClick.bind(this, 3)}>
                            Data Bank
                          </a>
                        </Header.Content>
                      </Header>
                    </div>
                  )}

                  {userinfo.is_complete < 5 && (
                    <div id="fonts" className="informasiPribadi">
                      <Header id="fonts" as="h6" color="blue" size="mini">
                        <Icon name="user" />
                        <Header.Content>
                          <a href="#" onClick={this.handleClick.bind(this, 0)}>
                            Profil Saya
                          </a>
                        </Header.Content>
                      </Header>
                    </div>
                  )}

                  <Header id="fonts" textAlign="left" as="h5" color="blue">
                    Status Akun :
                  </Header>
                  {dataIsComplete < 5 ? (
                    <>
                      {/*<Header id="fonts" as='h6' color="blue" size='small'>
                        <Header.Content>
                          <Edit userinfo={userinfo} lengkapiData={lengkapiData} stateOptions={stateOptions} />
                          <span className="uploadKet">(Wajib)</span>
                        </Header.Content>
                      </Header> */}
                      <Header
                        id="fonts"
                        as="h6"
                        textAlign="left"
                        color="blue"
                        size="small"
                        style={{ fontSize: "11px", marginTop: "0px" }}
                      >
                        <Header.Content>
                          Kelengkapan Data{" "}
                          <span className="privyVerified">
                            (Belum dilengkapi)
                          </span>
                        </Header.Content>
                      </Header>
                    </>
                  ) : verified_advance < 3 ? (
                    <Header
                      id="fonts"
                      as="h6"
                      textAlign="left"
                      color="blue"
                      size="small"
                      style={{ fontSize: "11px", marginTop: "0px" }}
                    >
                      <Header.Content>
                        Kelengkapan Data{" "}
                        <span
                          className="privyVerified"
                          style={{ color: "orange" }}
                        >
                          (Sedang diverifikasi)
                        </span>
                      </Header.Content>
                    </Header>
                  ) : verified_advance == 3 ? (
                    <Header
                      id="fonts"
                      as="h6"
                      textAlign="left"
                      color="blue"
                      size="small"
                      style={{ fontSize: "11px", marginTop: "0px" }}
                    >
                      <Header.Content>
                        Kelengkapan Data{" "}
                        <span
                          className="privyVerified"
                          style={{ color: "green" }}
                        >
                          (Terverifikasi)
                        </span>
                      </Header.Content>
                    </Header>
                  ) : verified_advance == 5 ? (
                    <Header
                      id="fonts"
                      as="h6"
                      textAlign="left"
                      color="blue"
                      size="small"
                      style={{ fontSize: "11px", marginTop: "0px" }}
                    >
                      <Header.Content>
                        Kelengkapan Data{" "}
                        <span
                          className="privyVerified"
                          style={{ color: "green" }}
                        >
                          (Ditolak)
                        </span>
                      </Header.Content>
                    </Header>
                  ) : (
                    <></>
                  )}

                  <Header
                    id="fonts"
                    as="h6"
                    textAlign="left"
                    color="blue"
                    size="small"
                    style={{ fontSize: "11px", marginTop: "0px" }}
                  >
                    <Header.Content>
                      Digital Signature
                      {signature_status == null ? (
                        <span className="privyVerified">
                          {" "}
                          (Belum terdaftar)
                        </span>
                      ) : signature_status == "1" ? (
                        <span
                          className="privyVerified"
                          style={{ color: "orange" }}
                        >
                          {" "}
                          (Unverified)
                        </span>
                      ) : signature_status == "2" ? (
                        <span
                          className="privyVerified"
                          style={{ color: "green" }}
                        >
                          {" "}
                          (Verified)
                        </span>
                      ) : signature_status == "3" ? (
                        <span
                          className="privyVerified"
                          style={{ color: "red" }}
                        >
                          {" "}
                          (Expired)
                        </span>
                      ) : (
                        <></>
                      )}
                    </Header.Content>
                  </Header>
                </Menu.Item>
              </Sidebar>

              {/* Menu Center */}
              <Sidebar.Pusher
                id="fonts"
                className="mContentUtama"
                dimmed={dimmed && visible}
                style={{ backgroundColor: "#eeeeee" }}
              >
                {/* {verified_advance === 3 ?
                  <Segment basic style={{ backgroundColor: 'white' }}>
                    Terverifikasi
                  </Segment>
                  : verified_advance === 5 ?
                    <Segment basic style={{ backgroundColor: 'white' }}>
                      Verifikasi ditolak (advance ai)
                    </Segment>
                    :
                    <Segment basic style={{ backgroundColor: 'white' }}>
                      <div className="info d-flex">
                        <div className="icon"><img src={seru2} alt="" /></div>
                        <div className="isi text-muted ml-5">
                          <h5 id="fonts" >Informasi</h5>
                          <p id="fonts" className="">Jika data anda sudah lengkap, silahkan di konfirmasikan untuk proses verifikasi.</p>
                          <Button color='default'>Konfirmasi Kelengkapan Data</Button>
                        </div>
                      </div>
                    </Segment>
                } */}
                {verified_advance === 3 ? (
                  // <Segment basic style={{ backgroundColor: 'white' }}>
                  //   Terverifikasi
                  // </Segment>
                  <></>
                ) : verified_advance === 5 ? (
                  <Segment basic style={{ backgroundColor: "white" }}>
                    <div className="info d-flex">
                      {/* <div className="icon"><img src={seru2} alt="" /></div> */}
                      <Icon
                        name="remove circle"
                        color="red"
                        style={{ fontSize: "45px" }}
                      />
                      <div className="isi text-muted ml-3">
                        <h5 id="fonts">Informasi</h5>
                        <p id="fonts" className="">
                          Akun anda ditolak!
                        </p>
                        {/* <Button color='default'>Konfirmasi Kelengkapan Data</Button> */}
                      </div>
                    </div>
                  </Segment>
                ) : userinfo.is_complete > 4 ? (
                  <Segment basic style={{ backgroundColor: "white" }}>
                    <div className="info d-flex">
                      <div className="icon">
                        <img src={seru2} alt="" />
                      </div>
                      <div className="isi text-muted ml-3">
                        <h5 id="fonts">Informasi</h5>
                        <p id="fonts" className="">
                          Kelengkapan data anda sedang diverifikasi. <br />
                          Silahkan periksa email konfirmasi hasil verifikasi.{" "}
                          <br />
                          Jika dalam waktu 1x24 tidak ada pesan konfirmasi
                          melalui email, silahkan menghubungi CS Kerjasama.co.id
                        </p>
                      </div>
                    </div>
                  </Segment>
                ) : (
                  <Segment basic style={{ backgroundColor: "white" }}>
                    <div className="info d-flex">
                      <div className="icon">
                        <img src={seru2} alt="" />
                      </div>
                      <div className="isi text-muted ml-3">
                        <h5 id="fonts">Informasi</h5>
                        <p id="fonts" className="">
                          Lengkapi data profil terlebih dahulu untuk mulai
                          mendanai.
                        </p>
                        <ButtonMulaiLengkapiData
                          initLengkapiData={this.initLengkapiData}
                          userinfo={userinfo}
                        />
                      </div>
                    </div>
                  </Segment>
                )}

                <Segment basic style={{ backgroundColor: "white" }}>
                  <Container className="profileMobile">
                    <Row>
                      <Col md={4}>
                        {verified > 0 ? (
                          <>
                            <div
                              className="grid-box"
                              onMouseOver={this.mouseOver.bind(this)}
                              onMouseOut={this.mouseOut.bind(this)}
                              style={{ textAlign: "center" }}
                            >
                              {this.state.hover ? (
                                <>
                                  <Image
                                    htmlFor="embedpollfileinput"
                                    src={change_img}
                                    className="imgProfilePicCg"
                                    size="small"
                                    verticalAlign="middle"
                                    circular
                                    href="#"
                                    onClick={changeImgProfile}
                                    title="Ubah Foto Profil"
                                  />
                                  <input
                                    type="file"
                                    id="my_file"
                                    onChange={(event) => {
                                      this.onFileChange(event, 111);
                                    }}
                                    style={{ display: "none" }}
                                  />
                                </>
                              ) : (
                                <Image
                                  src={img_profile}
                                  className="imgProfilePic"
                                  size="small"
                                  verticalAlign="middle"
                                  circular
                                />
                              )}
                            </div>
                          </>
                        ) : (
                          <div
                            className="grid-box"
                            style={{ textAlign: "center" }}
                          >
                            <Image
                              src={img_profile}
                              className="imgProfilePic"
                              size="small"
                              verticalAlign="middle"
                              circular
                            />
                          </div>
                        )}
                        <h4
                          id="fonts"
                          className="nama"
                          style={{ textAlign: "center" }}
                        >
                          {userinfo.full_name}
                        </h4>
                      </Col>
                      <Col md={4}>
                        <div id="fonts" className="informasiPribadi">
                          <span id="fonts" className="namaID">
                            ID : {userinfo.user_id}
                          </span>
                          <Header id="fonts" as="h6" color="blue" size="small">
                            <Icon name="phone" />
                            <Header.Content>
                              Handphone
                              <Header.Subheader>
                                {userinfo.no_hp}
                              </Header.Subheader>
                            </Header.Content>
                          </Header>
                          <Header id="fonts" as="h6" color="blue" size="small">
                            <Icon name="mail" />
                            <Header.Content>
                              Email
                              <Header.Subheader>
                                {userinfo.email}
                              </Header.Subheader>
                            </Header.Content>
                          </Header>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div id="fonts" className="informasiPribadi">
                          {verified == 0 ? (
                            <div>
                              <Header
                                id="fonts"
                                as="h6"
                                color="blue"
                                size="small"
                              >
                                <Header.Content>
                                  {/* <Edit userinfo={userinfo} lengkapiData={lengkapiData} stateOptions={stateOptions} /> */}
                                  <span className="uploadKet">(Wajib)</span>
                                </Header.Content>
                              </Header>
                              <Button
                                id="fonts"
                                color="blue"
                                size="mini"
                                disabled
                                className="mdaftarPemodal"
                                style={{ marginTop: "30px" }}
                              >
                                Daftar Menjadi Penerbit{" "}
                              </Button>
                            </div>
                          ) : (
                            <div style={{ paddingTop: "0px" }}>
                              {verified_advance == 3 ? (
                                <>
                                  <Header
                                    id="fonts"
                                    as="h6"
                                    color="green"
                                    style={{ fontSize: "11px" }}
                                  >
                                    <Header.Content>
                                      Terverifikasi
                                      <Icon
                                        name="check"
                                        circular
                                        color="green"
                                        size="small"
                                        style={{ marginLeft: "85px" }}
                                      ></Icon>
                                    </Header.Content>
                                  </Header>
                                  <Header
                                    id="fonts"
                                    as="h6"
                                    color="blue"
                                    size="small"
                                  >
                                    <Icon name="map marker alternate" />
                                    <Header.Content>
                                      Alamat
                                      <Header.Subheader>
                                        {userinfo.alamat}
                                      </Header.Subheader>
                                    </Header.Content>
                                  </Header>
                                  <Button
                                    id="fonts"
                                    color="blue"
                                    size="mini"
                                    disabled
                                    className="mdaftarPemodal"
                                    style={{ marginTop: "10px" }}
                                  >
                                    Daftar Menjadi Penerbit{" "}
                                  </Button>
                                </>
                              ) : verified_advance == 5 ? (
                                <>
                                  <Header
                                    id="fonts"
                                    as="h6"
                                    color="red"
                                    style={{ fontSize: "11px" }}
                                  >
                                    <Header.Content>
                                      Verifikasi Ditolak
                                      <Icon
                                        name="check"
                                        circular
                                        color="red"
                                        size="small"
                                        style={{ marginLeft: "60px" }}
                                      ></Icon>
                                    </Header.Content>
                                  </Header>
                                  <Header
                                    id="fonts"
                                    as="h6"
                                    color="blue"
                                    size="small"
                                  >
                                    <Icon name="map marker alternate" />
                                    <Header.Content>
                                      Alamat
                                      <Header.Subheader>
                                        {userinfo.alamat}
                                      </Header.Subheader>
                                    </Header.Content>
                                  </Header>
                                  <Button
                                    id="fonts"
                                    color="blue"
                                    size="mini"
                                    className="mdaftarPemodal"
                                    disabled
                                    style={{ marginTop: "10px" }}
                                  >
                                    Daftar Menjadi Penerbit{" "}
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Header
                                    id="fonts"
                                    as="h6"
                                    color="yellow"
                                    style={{ fontSize: "11px" }}
                                  >
                                    <Header.Content>
                                      Proses Verifikasi
                                      <Icon
                                        name="check"
                                        circular
                                        color="yellow"
                                        size="small"
                                        style={{ marginLeft: "60px" }}
                                      ></Icon>
                                    </Header.Content>
                                  </Header>
                                  <Header
                                    id="fonts"
                                    as="h6"
                                    color="blue"
                                    size="small"
                                  >
                                    <Icon name="map marker alternate" />
                                    <Header.Content>
                                      Alamat
                                      <Header.Subheader>
                                        {userinfo.alamat}
                                      </Header.Subheader>
                                    </Header.Content>
                                  </Header>
                                  <Button
                                    id="fonts"
                                    color="blue"
                                    size="mini"
                                    className="mdaftarPemodal"
                                    disabled
                                    style={{ marginTop: "10px" }}
                                  >
                                    Daftar Menjadi Penerbit{" "}
                                  </Button>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Container>

                  <div className="paddingSpace profileMobile"></div>
                  {/* <Container>
                    <Row>
                      <Col md={6} className="borderRightInvest">
                        <Header id="fonts" as='h2' color='blue'>
                          <Image src={saving_money} size='mini' />
                          <Header.Content>
                            <Header.Subheader>Total Investasi</Header.Subheader>
                            Rp. {totalInvest} ,-
                          </Header.Content>
                        </Header>
                      </Col>
                      <Col md={6}>
                        <Header id="fonts" as='h2' color='blue'>
                          <Image src={receiving_money} size='mini' />
                          <Header.Content>
                            <Header.Subheader>Dividen</Header.Subheader>
                            Rp. {totalShareProfit} ,-
                          </Header.Content>
                        </Header>
                      </Col>
                    </Row>
                  </Container> */}
                  {/* <div className="paddingSpace"></div> */}

                  {this.state.profileShow == 1 ? (
                    <DataPribadi
                      userinfo={userinfo}
                      tipePemodal={this.state.tipePemodal}
                      dataCountries={this.state.dataCountries}
                      dataProvince={this.state.dataProvince}
                      dataDistrict={this.state.dataDistrict}
                      dataSubDistrict={this.state.dataSubDistrict}
                      dataProvinceOther={this.state.dataProvinceOther}
                      dataDistrictOther={this.state.dataDistrictOther}
                      dataSubDistrictOther={this.state.dataSubDistrictOther}
                      getDataProvince={this.getDataProvince}
                      getDataDistrict={this.getDataDistrict}
                      getDataSubDistrict={this.getDataSubDistrict}
                      clearArrayProvinceEtc={this.clearArrayProvinceEtc}
                      getUserpemodal={this.getUserpemodal}
                    />
                  ) : this.state.profileShow == 2 ? (
                    <DataPerusahaan
                      userinfo={userinfo}
                      getUserpemodal={this.getUserpemodal}
                    />
                  ) : this.state.profileShow == 3 ? (
                    <DataBank
                      userinfo={userinfo}
                      dataBank={this.state.dataBank}
                      getUserpemodal={this.getUserpemodal}
                    />
                  ) : (
                    <ProfilSaya
                      userinfo={userinfo}
                      tipePemodal={this.state.tipePemodal}
                      dataCountries={this.state.dataCountries}
                      dataProvince={this.state.dataProvince}
                      dataDistrict={this.state.dataDistrict}
                      dataSubDistrict={this.state.dataSubDistrict}
                      getDataProvince={this.getDataProvince}
                      getDataDistrict={this.getDataDistrict}
                      getDataSubDistrict={this.getDataSubDistrict}
                      clearArrayProvinceEtc={this.clearArrayProvinceEtc}
                      getUserpemodal={this.getUserpemodal}
                    />
                  )}
                </Segment>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </Col>
        </Row>
      </Container>
    );
  }
}

const ButtonMulaiLengkapiData = memo((props) => {
  // const [clientType, setClientType] = useState('direct')

  const initFormPerusahaan = () => {
    //Indirect Client
    var CryptoJS = require("crypto-js");
    var key = process.env.REACT_APP_PRIVATE_KEY;
    var type = CryptoJS.AES.encrypt("perusahaan", key).toString();
    var client = CryptoJS.AES.encrypt("indirect", key).toString();

    setShowModal(false);
    window.location.href =
      "/Lengkapidatapemodal?type=" +
      encodeURIComponent(type) +
      "&client=" +
      encodeURIComponent(client);
  };

  const initFormPribadi = () => {
    //Direct client
    var CryptoJS = require("crypto-js");
    var key = process.env.REACT_APP_PRIVATE_KEY;
    var type = CryptoJS.AES.encrypt("pribadi", key).toString();
    var client = CryptoJS.AES.encrypt("direct", key).toString();

    setShowModal(false);
    window.location.href =
      "/Lengkapidatapemodal?type=" +
      encodeURIComponent(type) +
      "&client=" +
      encodeURIComponent(client);
  };

  // const initFormPribadiExpert = () => {
  //   //Bisa direct bisa indirect
  //   var CryptoJS = require("crypto-js");
  //   var key = process.env.REACT_APP_PRIVATE_KEY
  //   var type = CryptoJS.AES.encrypt('pribadiexpert', key).toString();
  //   var client = CryptoJS.AES.encrypt(clientType, key).toString();

  //   setShowModal2(false)
  //   setShowModalPribadi(false)
  //   window.location.href = "/Lengkapidatapemodal?type=" + encodeURIComponent(type) + '&client=' + encodeURIComponent(client)
  // }

  // const initFormPribadiNonExpert = () => {
  //   //Bisa direct bisa indirect
  //   var CryptoJS = require("crypto-js");
  //   var key = process.env.REACT_APP_PRIVATE_KEY
  //   var type = CryptoJS.AES.encrypt('pribadi', key).toString();
  //   var client = CryptoJS.AES.encrypt(clientType, key).toString();

  //   setShowModal2(false)
  //   setShowModalPribadi(false)
  //   window.location.href = "/Lengkapidatapemodal?type=" + encodeURIComponent(type) + '&client=' + encodeURIComponent(client)
  // }

  //Direct : Perorangan (individu tersebut yang langsung menjadi pemodal)
  //Indirect : Mewakili badan hukum (perusahaan)

  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => {
    if (props.userinfo.tipe_pemodal > 0) {
      // if(props.userinfo.tipe_pemodal == 1){ //direct non pengalaman
      //   setClientType('direct')
      //   initFormPribadiNonExpert()
      // }
      // if(props.userinfo.tipe_pemodal == 2){ //direct dengan pengalaman
      //   setClientType('direct')
      //   initFormPribadiExpert()
      // }
      // if(props.userinfo.tipe_pemodal == 3){ //indirect perusahaan
      //   initFormPerusahaan()
      // }
      // if(props.userinfo.tipe_pemodal == 4){ //indirect pribadi non pengalaman
      //   setClientType('indirect')
      //   initFormPribadiNonExpert()
      // }
      // if(props.userinfo.tipe_pemodal == 5){ //indirect pribadi dengan pengalaman
      //   setClientType('indirect')
      //   initFormPribadiExpert()
      // }

      if (props.userinfo.tipe_pemodal == 1) {
        //individu / perorangan
        initFormPribadi();
      }
      if (props.userinfo.tipe_pemodal == 2) {
        //badan hukum / perusahaan
        initFormPerusahaan();
      }
    } else {
      setShowModal(true);
    }
  };
  const handleClose = () => {
    setShowModal(false);
  };

  // const [showModalPribadi, setShowModalPribadi] = useState(false)
  // const handleShowModalPribadi = () => {
  //   setShowModalPribadi(true)
  //   setShowModal(false)
  // }
  // const handleCloseModalPribadi = () => {
  //   setShowModalPribadi(false)
  // }

  // const [showModal2, setShowModal2] = useState(false)
  // const handleShowModal2 = (param) => {
  //   setClientType(param)
  //   setShowModal2(true)
  //   setShowModal(false)
  // }

  const handleShowModal2 = (param) => {
    if (param == "indirect") {
      initFormPerusahaan();
    } else if (param == "direct") {
      initFormPribadi();
    }
  };
  // const handleCloseModal2 = () => {
  //   setShowModal2(false)
  // }

  return (
    <>
      <Button color="default" onClick={handleShowModal}>
        Lengkapi Data
      </Button>

      {/* <Modal style={{ borderRadius: "30px" }} show={showModal} onHide={handleClose} animation={false}>
        <Modal.Title
          style={{ paddingTop: 30, paddingLeft: 30, paddingRight: 30, color: "#1F1F1F", fontSize: "18px", fontWeight: "500", fontFamily: "Helvetica Neue LT Std" }} >
          <div className='text-center'>Apakah Anda Badan Hukum (Perusahaan) atau Perorangan?</div>
          <p />
        </Modal.Title>
        <div className="row text-center" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
          <div className="col-md-6">
            <a href="#" id="fonts" onClick={initFormPerusahaan} className="btn my-4 btnPilihan" style={{ background: "#214981", color: "#ffffff", padding: "5px 50px", borderRadius: "20px" }}>
              Badan Hukum
            </a>
          </div>
          <div className="col-md-6">
            <a href="#" id="fonts" onClick={handleShowModalPribadi} className="btn my-4 btnPilihan" style={{ background: "#214981", color: "#ffffff", padding: "5px 50px", borderRadius: "20px" }}>
              Perorangan
            </a>
          </div>
        </div>
      </Modal> */}

      <Modal
        style={{ borderRadius: "30px" }}
        show={showModal}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Title
          style={{
            paddingTop: 30,
            paddingLeft: 30,
            paddingRight: 30,
            color: "#1F1F1F",
            fontSize: "18px",
            fontWeight: "500",
            fontFamily: "Helvetica Neue LT Std",
          }}
        >
          {/* <div className='text-center'>Pilih "<strong>Direct</strong>" jika anda sebagai pemodal langsung. Pilih "<strong>Indirect</strong>" jika anda mewakili pihak lain.</div> */}
          <div className="text-center">
            Pilih "<strong>Direct</strong>" jika anda sebagai pemodal langsung.
            Pilih "<strong>Indirect</strong>" jika anda mewakili badan hukum
            (perusahaan).
          </div>
          <p />
        </Modal.Title>
        <div
          className="row text-center"
          style={{ paddingTop: "50px", paddingBottom: "50px" }}
        >
          <div className="col-md-6">
            <a
              href="#"
              id="fonts"
              onClick={() => handleShowModal2("direct")}
              className="btn my-4 btnPilihan"
              style={{
                background: "#28a745",
                color: "#ffffff",
                padding: "5px 50px",
                borderRadius: "20px",
              }}
            >
              Direct
            </a>
          </div>
          <div className="col-md-6">
            <a
              href="#"
              id="fonts"
              onClick={() => handleShowModal2("indirect")}
              className="btn my-4 btnPilihan"
              style={{
                background: "#28a745",
                color: "#ffffff",
                padding: "5px 50px",
                borderRadius: "20px",
              }}
            >
              Indirect
            </a>
          </div>
        </div>
      </Modal>

      {/* Buat Testing */}

      {/* <Modal style={{ borderRadius: "30px" }} show={showModal2} onHide={handleCloseModal2} animation={false}>
        <Modal.Title
          style={{ paddingTop: 30, paddingLeft: 30, paddingRight: 30, color: "#1F1F1F", fontSize: "18px", fontWeight: "500", fontFamily: "Helvetica Neue LT Std" }} >
          <div className='text-center'>
            { clientType == 'direct' && 'Apakah anda Badan Hukum (Perusahaan) atau Perorangan?'}
            { clientType == 'indirect' && 'Apakah pihak yang anda wakilkan Badan Hukum (Perusahaan) atau Perorangan?'}
          </div>
          <p />
        </Modal.Title>
        <div className="row text-center" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
          <div className="col-md-6">
            <a href="#" id="fonts" onClick={initFormPerusahaan} className="btn my-4 btnPilihan" style={{ background: "#214981", color: "#ffffff", padding: "5px 50px", borderRadius: "20px" }}>
              Badan Hukum
            </a>
          </div>
          <div className="col-md-6">
            <a href="#" id="fonts" onClick={handleShowModalPribadi} className="btn my-4 btnPilihan" style={{ background: "#214981", color: "#ffffff", padding: "5px 50px", borderRadius: "20px" }}>
              Perorangan
            </a>
          </div>
        </div>
      </Modal> */}

      {/* end buat testing */}

      {/* <Modal style={{ borderRadius: "30px" }} show={showModalPribadi} onHide={handleCloseModalPribadi} animation={false}>
        <Modal.Title
          style={{ paddingTop: 30, paddingLeft: 30, paddingRight: 30, color: "#1F1F1F", fontSize: "18px", fontWeight: "500", fontFamily: "Helvetica Neue LT Std" }} >
          <div className='text-center'>
            {clientType == 'direct' && 'Apakah anda berpengalaman dalam berinvestasi?'}
            {clientType == 'indirect' && 'Apakah individu yang anda wakilkan berpengalaman dalam berinvestasi?'}
          </div>
          <p />
          <Alert id="fonts" className="alertInfo" variant="warning" show={true} style={{ fontSize: "14px", display: 'block' }}>
            <center>Yang dibuktikan dengan kepemilikan rekening Efek paling sedikit 2 (dua) tahun.</center>
          </Alert>
        </Modal.Title>
        <div className="row text-center" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
          <div className="col-md-6">
            <a href="#" id="fonts" onClick={initFormPribadiExpert} className="btn my-4 btnPilihan" style={{ background: "#214981", color: "#ffffff", padding: "5px 50px", borderRadius: "20px" }}>
              Ya
            </a>
          </div>
          <div className="col-md-6">
            <a href="#" id="fonts" onClick={initFormPribadiNonExpert} className="btn my-4 btnPilihan" style={{ background: "#214981", color: "#ffffff", padding: "5px 50px", borderRadius: "20px" }}>
              Tidak
            </a>
          </div>
        </div>
      </Modal> */}
    </>
  );
});

const ProfilSaya = memo((props) => {
  const [showEdit, setShowEdit] = useState(false);

  const openEditForm = () => {
    setShowEdit(true);
  };

  const closeEditForm = () => {
    setShowEdit(false);
  };

  const [form, setForm] = useState({
    type: "", // Badan hukum / pribadi
    nama_depan:
      props.userinfo.nama_depan == null ? "" : props.userinfo.nama_depan,
    nama_tengah:
      props.userinfo.nama_tengah == null ? "" : props.userinfo.nama_tengah,
    nama_belakang:
      props.userinfo.nama_belakang == null ? "" : props.userinfo.nama_belakang,
    no_hp_kode:
      props.userinfo.no_hp_kode == null ? "" : props.userinfo.no_hp_kode,
    no_hp: props.userinfo.no_hp == null ? "" : props.userinfo.no_hp,
    email: props.userinfo.email == null ? "" : props.userinfo.email,
  });

  const [errorMessage, setErrorMessage] = useState({});

  const handleTextChange = (event) => {
    var index = event.target.name;
    setErrorMessage((errorMessage) => ({ ...errorMessage, [index]: "" }));

    if (index === "nama_depan" && event.target.value.length > 30) return;
    if (index === "nama_tengah" && event.target.value.length > 30) return;
    if (index === "nama_belakang" && event.target.value.length > 30) return;

    if (index === "no_hp_kode") {
      const re = /^[+0-9\b]+$/;
      if (event.target.value != "" && !re.test(event.target.value)) {
        return;
      }
      if (
        event.target.value.length > 1 &&
        event.target.value.slice(-1) == "+"
      ) {
        return;
      }
      if (event.target.value.length > 5) {
        return;
      }
    }

    if (index === "no_hp") {
      const re = /^[0-9\b]+$/;
      if (event.target.value != "" && !re.test(event.target.value)) {
        return;
      }
      if (event.target.value.length > 11) {
        return;
      }
    }

    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const [loading, setLoading] = useState(false);

  const beforeUnloadListener = (event) => {
    event.preventDefault();
    event.returnValue = "";
    return "";
  };

  const postData = async (values) => {
    window.addEventListener("beforeunload", beforeUnloadListener);

    // console.log(JSON.stringify(values))
    // return

    let config = {
      headers: {
        Authorization: localStorage.token,
        "content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Methods": "POST",
      },
      maxContentLength: 52428890,
      timeout: 10000000,
    };

    setLoading(true);

    axios
      .post(BASE_URL + "/customer/pemodal/update_profile/data", values, config)
      .then((resp) => {
        setLoading(false);
        window.removeEventListener("beforeunload", beforeUnloadListener);

        swal({ text: resp.data.body.message, icon: "success" });
        // console.log('RESPON ' + JSON.stringify(resp.data))

        props.getUserpemodal();

        setShowEdit(false);
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        setLoading(false);
        window.removeEventListener("beforeunload", beforeUnloadListener);

        try {
          let errorStatusCode = error.response.status;

          if (errorStatusCode === 401) {
            setTimeout(() => {
              window.location.href = "/Signin";
              localStorage.removeItem("token");
              localStorage.clear();

              this.props.dispatch({
                type: "AUTH_USER_LOGOUT",
              });
            }, 500);
          } else {
            let errorBody = error.response.data.body;
            swal({ text: errorBody.message, icon: "error" });
          }
        } catch (error) {
          swal({ text: "Error tidak terdefinisi!", icon: "error" });
        }
      });
  };

  async function handleSubmit() {
    let errors = {};
    let isValid = true;

    if (form.nama_depan == "") {
      errors["nama_depan"] = "Masukkan nama depan!";
      isValid = false;
    }
    if (form.no_hp_kode == "") {
      errors["no_hp"] = "Masukkan nomor handphone!";
      isValid = false;
    }
    if (form.no_hp == "") {
      errors["no_hp"] = "Masukkan nomor handphone!";
      isValid = false;
    }
    if (form.email == "") {
      errors["email"] = "Masukkan email!";
      isValid = false;
    } else {
      const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!re.test(form.email)) {
        errors["email"] = "Masukkan email dengan benar!";
        isValid = false;
      }
    }

    setErrorMessage((errorMessage) => ({
      ...errorMessage,
      ...errors,
    }));

    if (isValid) {
      postData(form);
    } else {
      swal({ text: "Periksa kembali data yang anda masukkan!", icon: "error" });
    }
  }

  return (
    <>
      {showEdit == false ? (
        <Container style={{ display: "block" }}>
          <Row>
            <Col md={12}>
              <Header id="fonts" as="h6" color="blue" size="small">
                <Header.Content>
                  Profil Saya{" "}
                  <a
                    href="#"
                    onClick={openEditForm.bind(this)}
                    style={{ marginLeft: "10px" }}
                  >
                    <Icon name="pencil alternate" />
                  </a>
                </Header.Content>
              </Header>

              <Form.Group as={Row}>
                <Col sm="4" style={{ color: "gray" }}>
                  Nama
                </Col>
                <Col sm="8">
                  {props.userinfo.nama_depan}
                  {props.userinfo.nama_tengah == null
                    ? ""
                    : props.userinfo.nama_tengah == ""
                    ? ""
                    : " " + props.userinfo.nama_tengah}
                  {props.userinfo.nama_belakang == null
                    ? ""
                    : props.userinfo.nama_belakang == ""
                    ? ""
                    : " " + props.userinfo.nama_belakang}
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: "gray" }}>
                  Nomor Handphone
                </Col>
                <Col sm="8">
                  {props.userinfo.no_hp_kode + " " + props.userinfo.no_hp}
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: "gray" }}>
                  Email
                </Col>
                <Col sm="8">{props.userinfo.email}</Col>
              </Form.Group>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container style={{ display: "block" }}>
          <Form
            onSubmit={(e) => e.preventDefault()}
            encType="multipart/form-data"
            autoComplete="off"
          >
            <Row>
              <Col md={12}>
                <Header id="fonts" as="h6" color="blue" size="small">
                  <Header.Content>Edit Profil</Header.Content>
                </Header>

                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Nama Depan<span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      name="nama_depan"
                      value={form.nama_depan}
                      onChange={handleTextChange}
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["nama_depan"]}
                    </span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Nama Tengah
                  </Col>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      name="nama_tengah"
                      value={form.nama_tengah}
                      onChange={handleTextChange}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Nama Belakang
                  </Col>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      name="nama_belakang"
                      value={form.nama_belakang}
                      onChange={handleTextChange}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Nomor Handphone<span style={{ color: "red" }}>*</span>
                    <br />
                    <span style={{ fontSize: "11px" }}>
                      (Contoh: +62 82112341234)
                    </span>
                  </Col>
                  <Col sm="8">
                    <Form.Group as={Row}>
                      <Col sm="2">
                        <Form.Control
                          type="text"
                          name="no_hp_kode"
                          value={form.no_hp_kode}
                          onChange={handleTextChange}
                          disabled={true}
                        />
                      </Col>
                      <Col sm="10">
                        <Form.Control
                          type="text"
                          name="no_hp"
                          value={form.no_hp}
                          onChange={handleTextChange}
                          disabled={true}
                        />
                      </Col>
                    </Form.Group>
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["no_hp"]}
                    </span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Email<span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      name="email"
                      value={form.email}
                      onChange={handleTextChange}
                      disabled={true}
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["email"]}
                    </span>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div style={{ float: "right" }}>
                  <Button
                    color="default"
                    type="button"
                    onClick={closeEditForm.bind(this)}
                    disabled={loading}
                  >
                    Kembali
                  </Button>
                  <Button
                    className="btnSimpan"
                    color="green"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {loading && (
                      <Spinner
                        animation="border"
                        variant="default"
                        size="sm"
                        style={{ marginRight: "5px" }}
                      />
                    )}
                    {loading && <span>Loading..</span>}
                    {!loading && <span>Simpan</span>}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      )}
    </>
  );
});

const DataPribadi = memo((props) => {
  const [actionForm, setActionForm] = useState("edit");

  const initData = {
    member_code:
      props.userinfo.member_code == null ? "" : props.userinfo.member_code,
    client_code:
      props.userinfo.client_code == null ? "" : props.userinfo.client_code,
    tax_code: props.userinfo.tax_code == null ? "" : props.userinfo.tax_code,

    nama_depan:
      props.userinfo.nama_depan == null ? "" : props.userinfo.nama_depan,
    nama_tengah:
      props.userinfo.nama_tengah == null ? "" : props.userinfo.nama_tengah,
    nama_belakang:
      props.userinfo.nama_belakang == null ? "" : props.userinfo.nama_belakang,
    nationality:
      props.userinfo.nationality == null ? "360" : props.userinfo.nationality, // default >> Indonesia

    jenis_kelamin:
      props.userinfo.jenis_kelamin == null ? "" : props.userinfo.jenis_kelamin,
    tempat_lahir:
      props.userinfo.tempat_lahir == null ? "" : props.userinfo.tempat_lahir,
    tanggal_lahir:
      props.userinfo.tanggal_lahir == null ? "" : props.userinfo.tanggal_lahir,

    status_pernikahan:
      props.userinfo.status_pernikahan == null
        ? ""
        : props.userinfo.status_pernikahan,
    nama_pasangan:
      props.userinfo.nama_pasangan == null ? "" : props.userinfo.nama_pasangan,

    nama_ibu_kandung:
      props.userinfo.nama_ibu_kandung == null
        ? ""
        : props.userinfo.nama_ibu_kandung,

    nama_ahli_waris:
      props.userinfo.nama_ahli_waris == null
        ? ""
        : props.userinfo.nama_ahli_waris,
    hubungan_ahli_waris:
      props.userinfo.hubungan_ahli_waris == null
        ? ""
        : props.userinfo.hubungan_ahli_waris,

    country:
      props.userinfo.country_code == null ? "360" : props.userinfo.country_code, // default >> Indonesia
    province: props.userinfo.province == null ? "" : props.userinfo.province,
    district: props.userinfo.district == null ? "" : props.userinfo.district,
    sub_district:
      props.userinfo.sub_district == null ? "" : props.userinfo.sub_district,
    alamat: props.userinfo.alamat == null ? "" : props.userinfo.alamat,
    alamat2: props.userinfo.alamat2 == null ? "" : props.userinfo.alamat2,
    alamat3: props.userinfo.alamat3 == null ? "" : props.userinfo.alamat3,
    kode_pos: props.userinfo.kode_pos == null ? "" : props.userinfo.kode_pos,
    no_telepon_kode:
      props.userinfo.no_telepon_kode == null
        ? ""
        : props.userinfo.no_telepon_kode,
    no_telepon:
      props.userinfo.no_telepon == null ? "" : props.userinfo.no_telepon,
    no_hp_kode:
      props.userinfo.no_hp_kode == null ? "" : props.userinfo.no_hp_kode,
    no_hp: props.userinfo.no_hp == null ? "" : props.userinfo.no_hp,
    email: props.userinfo.email == null ? "" : props.userinfo.email,
    no_fax_kode:
      props.userinfo.no_fax_kode == null ? "" : props.userinfo.no_fax_kode,
    no_fax: props.userinfo.no_fax == null ? "" : props.userinfo.no_fax,

    //Identitas Other
    country_other:
      props.userinfo.country_code_other == null
        ? ""
        : props.userinfo.country_code_other,
    province_other:
      props.userinfo.province_other == null
        ? ""
        : props.userinfo.province_other,
    district_other:
      props.userinfo.district_other == null
        ? ""
        : props.userinfo.district_other,
    sub_district_other:
      props.userinfo.sub_district_other == null
        ? ""
        : props.userinfo.sub_district_other,
    alamat_other:
      props.userinfo.alamat_other == null ? "" : props.userinfo.alamat_other,
    alamat_other2:
      props.userinfo.alamat_other2 == null ? "" : props.userinfo.alamat_other2,
    alamat_other3:
      props.userinfo.alamat_other3 == null ? "" : props.userinfo.alamat_other3,
    kode_pos_other:
      props.userinfo.kode_pos_other == null
        ? ""
        : props.userinfo.kode_pos_other,
    no_telepon_kode_other:
      props.userinfo.no_telepon_kode_other == null
        ? ""
        : props.userinfo.no_telepon_kode_other,
    no_telepon_other:
      props.userinfo.no_telepon_other == null
        ? ""
        : props.userinfo.no_telepon_other,
    no_hp_kode_other:
      props.userinfo.no_hp_kode_other == null
        ? ""
        : props.userinfo.no_hp_kode_other,
    no_hp_other:
      props.userinfo.no_hp_other == null ? "" : props.userinfo.no_hp_other,
    email_other:
      props.userinfo.email_other == null ? "" : props.userinfo.email_other,
    no_fax_kode_other:
      props.userinfo.no_fax_kode_other == null
        ? ""
        : props.userinfo.no_fax_kode_other,
    no_fax_other:
      props.userinfo.no_fax_other == null ? "" : props.userinfo.no_fax_other,
    //End identitas other

    pendidikan:
      props.userinfo.pendidikan == null ? "" : props.userinfo.pendidikan,
    pekerjaan: props.userinfo.pekerjaan == null ? "" : props.userinfo.pekerjaan,
    deskripsi_pekerjaan:
      props.userinfo.deskripsi_pekerjaan == null
        ? ""
        : props.userinfo.deskripsi_pekerjaan,
    deskripsi_usaha:
      props.userinfo.deskripsi_usaha == null
        ? ""
        : props.userinfo.deskripsi_usaha,
    penghasilan:
      props.userinfo.penghasilan == null ? "" : props.userinfo.penghasilan,
    sumber_dana:
      props.userinfo.sumber_dana == null ? "" : props.userinfo.sumber_dana,
    deskripsi_sumber_dana:
      props.userinfo.deskripsi_sumber_dana == null
        ? ""
        : props.userinfo.deskripsi_sumber_dana,
    tujuan_investasi:
      props.userinfo.tujuan_investasi == null
        ? ""
        : props.userinfo.tujuan_investasi,
    sid: props.userinfo.sid == null ? "" : props.userinfo.sid,
    kepemilikan_efek:
      props.userinfo.kepemilikan_efek == null
        ? ""
        : props.userinfo.kepemilikan_efek,
    ktp: props.userinfo.ktp == null ? "" : props.userinfo.ktp,
    ktp_register:
      props.userinfo.ktp_register == null ? "" : props.userinfo.ktp_register,
    ktp_expired:
      props.userinfo.ktp_expired == null ? "" : props.userinfo.ktp_expired,
    npwp: props.userinfo.npwp == null ? "" : props.userinfo.npwp,
    npwp_register:
      props.userinfo.npwp_register == null ? "" : props.userinfo.npwp_register,
    passport: props.userinfo.passport == null ? "" : props.userinfo.passport,
    passport_expired:
      props.userinfo.passport_expired == null
        ? ""
        : props.userinfo.passport_expired,
    kitas: props.userinfo.kitas == null ? "" : props.userinfo.kitas,
    kitas_expired:
      props.userinfo.kitas_expired == null ? "" : props.userinfo.kitas_expired,

    experience:
      props.userinfo.experience == null ? "" : props.userinfo.experience,
    experience_evidence: "",

    post_to: "pribadi",
    tipe_pemodal:
      props.userinfo.tipe_pemodal == "1"
        ? "pribadi"
        : props.userinfo.tipe_pemodal == "2"
        ? "perusahaan"
        : "",
    tipe_client:
      props.userinfo.tipe_client == "1"
        ? "direct"
        : props.userinfo.tipe_client == "2"
        ? "indirect"
        : "",
  };

  const [form, setForm] = useState(initData);

  const sid_old = props.userinfo.sid == null ? "" : props.userinfo.sid;

  const [fileSrc, setFileSrc] = useState({
    experience_evidence:
      props.userinfo.experience_evidence == null
        ? ""
        : props.userinfo.experience_evidence,
  });

  const dateMin = new Date();
  dateMin.setDate(dateMin.getDate() + 1);
  const minimumDate = {
    year: dateFormat(dateMin, "yyyy"),
    month: dateFormat(dateMin, "mm"),
    day: dateFormat(dateMin, "dd"),
  };

  const dateMax = new Date();
  dateMax.setDate(dateMax.getDate() - 1);
  const maximumDate = {
    year: dateFormat(dateMax, "yyyy"),
    month: dateFormat(dateMax, "mm"),
    day: dateFormat(dateMax, "dd"),
  };

  const [showEdit, setShowEdit] = useState(false);

  const openEditForm = () => {
    setShowEdit(true);
  };

  const closeEditForm = () => {
    setShowEdit(false);
    window.scrollTo(0, 0);
  };

  const [selectedDayDOB, setSelectedDayDOB] = useState(
    form.tanggal_lahir == ""
      ? null
      : {
          year: parseInt(dateFormat(form.tanggal_lahir, "yyyy")),
          month: parseInt(dateFormat(form.tanggal_lahir, "mm")),
          day: parseInt(dateFormat(form.tanggal_lahir, "dd")),
        }
  );
  const handleChangeDOB = (event) => {
    setSelectedDayDOB(event);

    // var selectDate = event ? `${event.day}/${event.month}/${event.year}` : ''
    var selectDate = event ? `${event.year}-${event.month}-${event.day}` : "";
    setForm({ ...form, ["tanggal_lahir"]: selectDate });
    setErrorMessage((errorMessage) => ({
      ...errorMessage,
      ["tanggal_lahir"]: "",
    }));
  };
  // render regular HTML input element
  const renderCustomInputDOB = ({ ref }) => (
    <Form.Control
      readOnly
      ref={ref} // necessary
      placeholder="Pilih tanggal"
      // value={selectedDay ? `✅ : ${selectedDay.year}` : ''}
      onClick={() => colZindex("col-tanggal-lahir")}
      value={
        selectedDayDOB
          ? `${selectedDayDOB.day}/${selectedDayDOB.month}/${selectedDayDOB.year}`
          : ""
      }
      // style={{
      //   textAlign: 'center',
      //   padding: '1rem 1.5rem',
      //   fontSize: '1.5rem',
      //   border: '1px solid #9c88ff',
      //   borderRadius: '100px',
      //   boxShadow: '0 1.5rem 2rem rgba(156, 136, 255, 0.2)',
      //   color: '#9c88ff',
      //   outline: 'none',
      // }}
      // className="my-custom-input-class" // a styling class
    />
  );

  const colZindex = (id) => {
    $(".col-datepicker").removeAttr("style");
    $("#" + id).css("z-index", "999");
  };

  const [selectedDayKTPReg, setSelectedDayKTPReg] = useState(
    form.ktp_register == ""
      ? null
      : {
          year: parseInt(dateFormat(form.ktp_register, "yyyy")),
          month: parseInt(dateFormat(form.ktp_register, "mm")),
          day: parseInt(dateFormat(form.ktp_register, "dd")),
        }
  );
  const renderCustomInputKTPReg = ({ ref }) => (
    <Form.Control
      readOnly
      ref={ref}
      placeholder="Pilih tanggal"
      onClick={() => colZindex("col-ktp-register")}
      value={
        selectedDayKTPReg
          ? `${selectedDayKTPReg.day}/${selectedDayKTPReg.month}/${selectedDayKTPReg.year}`
          : ""
      }
    />
  );
  const handleChangeKTPReg = (event) => {
    setSelectedDayKTPReg(event);
    if (event !== "") {
      var selectDate = event ? `${event.year}-${event.month}-${event.day}` : "";
      setForm({ ...form, ["ktp_register"]: selectDate });
      setErrorMessage((errorMessage) => ({
        ...errorMessage,
        ["ktp_register"]: "",
      }));
    }
  };

  const [selectedDayKTPExpired, setSelectedDayKTPExpired] = useState(
    form.ktp_expired == "" || form.ktp_expired == "9998-12-31"
      ? null
      : {
          year: parseInt(dateFormat(form.ktp_expired, "yyyy")),
          month: parseInt(dateFormat(form.ktp_expired, "mm")),
          day: parseInt(dateFormat(form.ktp_expired, "dd")),
        }
  );
  const handleChangeKTPExpired = (event) => {
    setSelectedDayKTPExpired(event);
    if (event !== "") {
      setChecked(false);
      // var selectDate = event ? `${event.day}/${event.month}/${event.year}` : ''
      var selectDate = event ? `${event.year}-${event.month}-${event.day}` : "";
      setForm({ ...form, ["ktp_expired"]: selectDate });
      setErrorMessage((errorMessage) => ({
        ...errorMessage,
        ["ktp_expired"]: "",
      }));
    }
  };
  const renderCustomInputKTPExpired = ({ ref }) => (
    <Form.Control
      id={"textKTPExpired"}
      readOnly
      ref={ref}
      placeholder="Pilih tanggal"
      onClick={() => colZindex("col-ktp-expired")}
      value={
        selectedDayKTPExpired
          ? `${selectedDayKTPExpired.day}/${selectedDayKTPExpired.month}/${selectedDayKTPExpired.year}`
          : ""
      }
    />
  );
  const [checked, setChecked] = useState(
    form.ktp_expired == "9998-12-31" ? true : false
  );
  const handleCheck = () => {
    setChecked(!checked);
    if (!checked) {
      setSelectedDayKTPExpired(null);
      setForm({ ...form, ["ktp_expired"]: "9998-12-31" });
      setErrorMessage((errorMessage) => ({
        ...errorMessage,
        ["ktp_expired"]: "",
      }));
    } else {
      setForm({ ...form, ["ktp_expired"]: "" });
      setErrorMessage((errorMessage) => ({
        ...errorMessage,
        ["ktp_expired"]: "Masukkan tanggal masa berlaku KTP!",
      }));
    }
  };

  const [sidDisabled, setSidDisabled] = useState(
    form.experience != "" ? false : true
  );

  const [checkedClientExperience, setCheckedClientExperience] = useState(
    form.experience == "1" ? true : false
  );
  const handleCheckClientExperience = () => {
    setErrorMessage((errorMessage) => ({ ...errorMessage, ["sid"]: "" }));
    if (!checkedClientExperience) {
      setCheckedClientExperience(true);

      form.experience = "1";

      setCheckedClientNonExperience(false);
      setSidDisabled(false);
    }
  };
  const [checkedClientNonExperience, setCheckedClientNonExperience] = useState(
    form.experience == "0" ? true : false
  );
  const handleCheckClientNonExperience = () => {
    setErrorMessage((errorMessage) => ({ ...errorMessage, ["sid"]: "" }));
    if (!checkedClientNonExperience) {
      setCheckedClientNonExperience(true);

      form.experience = "0";
      form.experience_evidence = "";

      setCheckedClientExperience(false);
      setSidDisabled(false);
    }
  };

  const [selectedDayNPWPReg, setSelectedDayNPWPReg] = useState(
    form.npwp_register == ""
      ? null
      : {
          year: parseInt(dateFormat(form.npwp_register, "yyyy")),
          month: parseInt(dateFormat(form.npwp_register, "mm")),
          day: parseInt(dateFormat(form.npwp_register, "dd")),
        }
  );
  const renderCustomInputNPWPReg = ({ ref }) => (
    <Form.Control
      readOnly
      ref={ref}
      placeholder="Pilih tanggal"
      onClick={() => colZindex("col-npwp-register")}
      value={
        selectedDayNPWPReg
          ? `${selectedDayNPWPReg.day}/${selectedDayNPWPReg.month}/${selectedDayNPWPReg.year}`
          : ""
      }
    />
  );
  const handleChangeNPWPReg = (event) => {
    setSelectedDayNPWPReg(event);
    if (event !== "") {
      // var selectDate = event ? `${event.day}/${event.month}/${event.year}` : ''
      var selectDate = event ? `${event.year}-${event.month}-${event.day}` : "";
      setForm({ ...form, ["npwp_register"]: selectDate });
      setErrorMessage((errorMessage) => ({
        ...errorMessage,
        ["npwp_register"]: "",
      }));
    }
  };

  const [selectedDayPassportExpired, setSelectedDayPassportExpired] = useState(
    form.passport_expired == ""
      ? null
      : {
          year: parseInt(dateFormat(form.passport_expired, "yyyy")),
          month: parseInt(dateFormat(form.passport_expired, "mm")),
          day: parseInt(dateFormat(form.passport_expired, "dd")),
        }
  );
  const renderCustomInputPassportExpired = ({ ref }) => (
    <Form.Control
      readOnly
      ref={ref}
      placeholder="Pilih tanggal"
      onClick={() => colZindex("col-passport-expired")}
      value={
        selectedDayPassportExpired
          ? `${selectedDayPassportExpired.day}/${selectedDayPassportExpired.month}/${selectedDayPassportExpired.year}`
          : ""
      }
    />
  );
  const handleChangePassportExpired = (event) => {
    setSelectedDayPassportExpired(event);
    if (event !== "") {
      // var selectDate = event ? `${event.day}/${event.month}/${event.year}` : ''
      var selectDate = event ? `${event.year}-${event.month}-${event.day}` : "";
      setForm({ ...form, ["passport_expired"]: selectDate });
      setErrorMessage((errorMessage) => ({
        ...errorMessage,
        ["passport_expired"]: "",
      }));
    }
  };

  const [selectedDayKitasExpired, setSelectedDayKitasExpired] = useState(
    form.kitas_expired == ""
      ? null
      : {
          year: parseInt(dateFormat(form.kitas_expired, "yyyy")),
          month: parseInt(dateFormat(form.kitas_expired, "mm")),
          day: parseInt(dateFormat(form.kitas_expired, "dd")),
        }
  );
  const renderCustomInputKitasExpired = ({ ref }) => (
    <Form.Control
      readOnly
      ref={ref}
      placeholder="Pilih tanggal"
      onClick={() => colZindex("col-kitas-expired")}
      value={
        selectedDayKitasExpired
          ? `${selectedDayKitasExpired.day}/${selectedDayKitasExpired.month}/${selectedDayKitasExpired.year}`
          : ""
      }
    />
  );
  const handleChangeKitasExpired = (event) => {
    setSelectedDayKitasExpired(event);
    if (event !== "") {
      // var selectDate = event ? `${event.day}/${event.month}/${event.year}` : ''
      var selectDate = event ? `${event.year}-${event.month}-${event.day}` : "";
      setForm({ ...form, ["kitas_expired"]: selectDate });
      setErrorMessage((errorMessage) => ({
        ...errorMessage,
        ["kitas_expired"]: "",
      }));
    }
  };

  const jenisKelaminOptions = [
    { key: "1", text: "Pria", value: "1" },
    { key: "2", text: "Wanita", value: "2" },
  ];

  const statusPernikahanOptions = [
    { key: "1", text: "Single", value: "1" },
    { key: "2", text: "Menikah", value: "2" },
    { key: "3", text: "Duda", value: "3" },
    { key: "4", text: "Janda", value: "4" },
  ];

  const pendidikanOptions = [
    { key: "1", text: "Lainnya", value: "1" },
    { key: "2", text: "SD", value: "2" },
    { key: "3", text: "SMP", value: "3" },
    { key: "4", text: "SMA (termasuk D1 dan D2)", value: "4" },
    { key: "5", text: "D3/Akademi", value: "5" },
    { key: "6", text: "S1", value: "6" },
    { key: "7", text: "S2", value: "7" },
    { key: "8", text: "S3", value: "8" },
  ];

  const pekerjaanOptions = [
    { key: "1", text: "Lainnya", value: "1" },
    { key: "2", text: "Pegawai Swasta", value: "2" },
    { key: "3", text: "Pegawai Negeri Sipil", value: "3" },
    { key: "4", text: "Ibu Rumah Tangga", value: "4" },
    { key: "5", text: "Wiraswasta/Pengusaha", value: "5" },
    { key: "6", text: "Pelajar", value: "6" },
    { key: "7", text: "TNI/Polisi", value: "7" },
    { key: "8", text: "Pensiunan", value: "8" },
    { key: "9", text: "Guru", value: "9" },
  ];

  const penghasilanOptions = [
    { key: "1", text: "< Rp 10 juta", value: "1" }, // maximal buy efek 5%
    { key: "2", text: "Rp 10 juta - Rp 50 juta", value: "2" }, // maximal buy efek 5%
    { key: "3", text: "Rp 50 juta - Rp 100 juta", value: "3" }, // maximal buy efek 5%
    { key: "4", text: "Rp 100 juta - Rp 500 juta", value: "4" }, // maximal buy efek 5%
    { key: "5", text: "Rp 500 juta - Rp 1 milyar", value: "5" }, // maximal buy efek 10%
    { key: "6", text: "> Rp 1 milyar", value: "6" }, // maximal buy efek 10%
  ];

  const sumberDanaOptions = [
    { key: "1", text: "Lain-lain", value: "1" },
    { key: "2", text: "Gaji", value: "2" },
    { key: "3", text: "Profit bisnis", value: "3" },
    { key: "4", text: "Bunga", value: "4" },
    { key: "5", text: "Warisan", value: "5" },
    { key: "6", text: "Hibah dari orang tua atau anak", value: "6" },
    { key: "7", text: "Hibah dari pasangan", value: "7" },
    { key: "8", text: "Dana pension", value: "8" },
    { key: "9", text: "Undian", value: "9" },
    { key: "10", text: "Hasil investasi", value: "10" },
    { key: "11", text: "Deposito", value: "11" },
    { key: "12", text: "Modal", value: "12" },
    { key: "13", text: "Pinjaman", value: "13" },
  ];

  const tujuanInvestasiOptions = [
    { key: "1", text: "Lain-lain", value: "1" },
    { key: "2", text: "Price appreciation", value: "2" },
    { key: "3", text: "Investasi jangka panjang", value: "3" },
    { key: "4", text: "Spekulasi", value: "4" },
    { key: "5", text: "Pendapatan", value: "5" },
  ];

  const kepemilikanEfekOptions = [
    { key: "1", text: "Sendiri", value: "1" },
    { key: "2", text: "Mewakili pihak lain", value: "2" },
  ];

  // const [countrySelected, setCountrySelected] = useState(null)
  const countryOptions = [
    { key: "360", text: "Indonesia", value: "360" },
    { key: "840", text: "United States", value: "840" },
  ];

  const [isDisplay, setIsDisplay] = useState("block");
  const refreshOtherCountries = () => {
    props.clearArrayProvinceEtc(1);
    if (form.country != "360") {
      form.province = "other";
      form.district = "other";
      form.sub_district = "other";
      setIsDisplay("none");
    } else {
      form.province = "";
      form.district = "";
      form.sub_district = "";
      setIsDisplay("block");
    }
  };

  const [isDisplayOther, setIsDisplayOther] = useState("block");
  const refreshOtherCountries_other = () => {
    props.clearArrayProvinceEtc(2);
    if (form.country_other != "360") {
      form.province_other = "other";
      form.district_other = "other";
      form.sub_district_other = "other";
      setIsDisplayOther("none");
    } else {
      form.province_other = "";
      form.district_other = "";
      form.sub_district_other = "";
      setIsDisplayOther("block");
    }
  };

  const [errorMessage, setErrorMessage] = useState({});

  const handleTextChange = (event) => {
    var index = event.target.name;
    setErrorMessage((errorMessage) => ({ ...errorMessage, [index]: "" }));

    if (index === "member_code" && event.target.value.length > 5) return;
    if (index === "client_code" && event.target.value.length > 4) return;
    if (index === "tax_code" && event.target.value.length > 4) return;

    if (index === "nama_depan" && event.target.value.length > 30) return;
    if (index === "nama_tengah" && event.target.value.length > 30) return;
    if (index === "nama_belakang" && event.target.value.length > 30) return;
    if (index === "tempat_lahir" && event.target.value.length > 100) return;
    if (index === "nama_ibu_kandung" && event.target.value.length > 30) return;

    if (index === "alamat" && event.target.value.length > 60) return;
    if (index === "alamat2" && event.target.value.length > 60) return;
    if (index === "alamat3" && event.target.value.length > 60) return;
    if (index === "alamat_other" && event.target.value.length > 60) return;
    if (index === "alamat_other2" && event.target.value.length > 60) return;
    if (index === "alamat_other3" && event.target.value.length > 60) return;

    if (index === "deskripsi_pekerjaan" && event.target.value.length > 100)
      return;
    if (index === "deskripsi_usaha" && event.target.value.length > 100) return;
    if (index === "deskripsi_sumber_dana" && event.target.value.length > 100)
      return;

    if (index === "kode_pos" || index === "kode_pos_other") {
      const re = /^[0-9\b]+$/;
      if (event.target.value != "" && !re.test(event.target.value)) {
        //  this.setState({value: e.target.value})
        return;
      }
      if (event.target.value.length > 5) {
        return;
      }
    }

    if (index === "no_telepon_kode") {
      setErrorMessage((errorMessage) => ({
        ...errorMessage,
        ["no_telepon"]: "",
      }));
    }
    if (index === "no_telepon_kode_other") {
      setErrorMessage((errorMessage) => ({
        ...errorMessage,
        ["no_telepon_other"]: "",
      }));
    }
    if (index === "no_hp_kode") {
      setErrorMessage((errorMessage) => ({ ...errorMessage, ["no_hp"]: "" }));
    }
    if (index === "no_hp_kode_other") {
      setErrorMessage((errorMessage) => ({
        ...errorMessage,
        ["no_hp_other"]: "",
      }));
    }

    if (
      index === "no_telepon_kode" ||
      index === "no_hp_kode" ||
      index === "no_fax_kode" ||
      index === "no_telepon_kode_other" ||
      index === "no_hp_kode_other" ||
      index === "no_fax_kode_other"
    ) {
      const re = /^[+0-9\b]+$/;
      if (event.target.value != "" && !re.test(event.target.value)) {
        return;
      }
      if (
        event.target.value.length > 1 &&
        event.target.value.slice(-1) == "+"
      ) {
        return;
      }
      if (event.target.value.length > 5) {
        return;
      }
    }

    if (
      index === "no_telepon" ||
      index === "no_hp" ||
      index === "no_fax" ||
      index === "no_telepon_other" ||
      index === "no_hp_other" ||
      index === "no_fax_other"
    ) {
      const re = /^[0-9\b]+$/;
      if (event.target.value != "" && !re.test(event.target.value)) {
        return;
      }
      if (event.target.value.length > 10) {
        return;
      }
    }

    if (index === "sid") {
      if (event.target.value.length > 15) {
        return;
      }
    }

    if (index === "ktp") {
      const re = /^[0-9\b]+$/;
      if (event.target.value != "" && !re.test(event.target.value)) {
        return;
      }
      if (event.target.value.length > 16) {
        return;
      }
    }

    if (index === "npwp") {
      // Exp: 79.275.653.8.422.000
      const re = /^[0-9.\b]+$/;
      if (event.target.value != "" && !re.test(event.target.value)) {
        return;
      }
      if (event.target.value.length > 20) {
        return;
      }
    }

    if (index === "passport") {
      if (event.target.value.length < 1) {
        setErrorMessage((errorMessage) => ({
          ...errorMessage,
          ["passport_expired"]: "",
        }));
        setSelectedDayPassportExpired(null);
      }
      if (event.target.value.length > 20) {
        return;
      }
    }

    if (index === "kitas") {
      if (event.target.value.length < 1) {
        setErrorMessage((errorMessage) => ({
          ...errorMessage,
          ["kitas_expired"]: "",
        }));
        setSelectedDayKitasExpired(null);
      }
      if (event.target.value.length > 30) {
        return;
      }
    }

    let resultValue = event.target.value;

    if (index === "client_code") {
      resultValue = event.target.value.toUpperCase();
    }

    setForm({ ...form, [event.target.name]: resultValue });
  };

  const [loading, setLoading] = useState(false);

  const beforeUnloadListener = (event) => {
    event.preventDefault();
    event.returnValue = "";
    return "";
  };

  const postData = async (values) => {
    window.addEventListener("beforeunload", beforeUnloadListener);

    // console.log(JSON.stringify(values))
    // return

    let config = {
      headers: {
        Authorization: localStorage.token,
        "content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Methods": "POST",
      },
      maxContentLength: 52428890,
      timeout: 10000000,
    };

    setLoading(true);

    axios
      .post(BASE_URL + "/customer/pemodal/lengkapidata_v2", values, config)
      .then((resp) => {
        setLoading(false);
        window.removeEventListener("beforeunload", beforeUnloadListener);

        swal({ text: resp.data.body.message, icon: "success" });
        // console.log('RESPON ' + JSON.stringify(resp.data))

        props.getUserpemodal();

        setShowEdit(false);
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        setLoading(false);
        window.removeEventListener("beforeunload", beforeUnloadListener);

        try {
          let errorStatusCode = error.response.status;

          if (errorStatusCode === 401) {
            setTimeout(() => {
              window.location.href = "/Signin";
              localStorage.removeItem("token");
              localStorage.clear();

              this.props.dispatch({
                type: "AUTH_USER_LOGOUT",
              });
            }, 500);
          } else {
            let errorBody = error.response.data.body;
            swal({ text: errorBody.message, icon: "error" });
          }
        } catch (error) {
          swal({ text: "Error tidak terdefinisi!", icon: "error" });
        }
      });
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async function handleSubmit(event) {
    event.preventDefault();

    // postData(form)
    // return

    let errors = {};
    let isValid = true;

    if (form.kitas == "") form.kitas_expired = "";
    if (form.passport == "") form.passport_expired = "";

    if (form.member_code == "") {
      errors["member_code"] = "Masukkan kode member!";
      isValid = false;
    }
    if (form.client_code == "") {
      errors["client_code"] = "Masukkan kode klien!";
      isValid = false;
    }
    if (form.tax_code == "") {
      errors["tax_code"] = "Masukkan kode tingkat pajak!";
      isValid = false;
    }

    if (form.nama_depan == "") {
      errors["nama_depan"] = "Masukkan nama depan!";
      isValid = false;
    }
    if (form.nationality == "") {
      errors["nationality"] = "Pilih kewarganegara!";
      isValid = false;
    }
    if (form.jenis_kelamin == "") {
      errors["jenis_kelamin"] = "Pilih jenis kelamin!";
      isValid = false;
    }
    if (form.tempat_lahir == "") {
      errors["tempat_lahir"] = "Masukkan tempat lahir!";
      isValid = false;
    }
    if (form.tanggal_lahir == "") {
      errors["tanggal_lahir"] = "Pilih tanggal lahir!";
      isValid = false;
    }
    if (form.status_pernikahan == "") {
      errors["status_pernikahan"] = "Pilih status pernikahan!";
      isValid = false;
    }

    if (form.nama_ibu_kandung == "" && props.tipeClient == "direct") {
      errors["nama_ibu_kandung"] = "Masukkan nama Ibu kandung!";
      isValid = false;
    }
    if (form.nama_ibu_kandung != "" && form.nama_ibu_kandung.length < 3) {
      errors["nama_ibu_kandung"] = "Masukkan minimal 3 karakter!";
      isValid = false;
    }

    if (form.nama_pasangan != "" && form.nama_pasangan.length < 3) {
      errors["nama_pasangan"] = "Masukkan minimal 3 karakter!";
      isValid = false;
    }

    if (form.nama_ahli_waris != "" && form.nama_ahli_waris.length < 3) {
      errors["nama_ahli_waris"] = "Masukkan minimal 3 karakter!";
      isValid = false;
    }

    if (form.country == "") {
      errors["country"] = "Pilih Negara!";
      isValid = false;
    }
    if (form.province == "") {
      errors["province"] = "Pilih province!";
      isValid = false;
    }
    if (form.district == "") {
      errors["district"] = "Pilih kota atau kabupaten!";
      isValid = false;
    }
    if (form.sub_district == "") {
      errors["sub_district"] = "Pilih kecamatan!";
      isValid = false;
    }
    if (form.alamat == "") {
      errors["alamat"] = "Masukkan alamat sesuai identitas KTP!";
      isValid = false;
    }
    if (form.kode_pos == "") {
      errors["kode_pos"] = "Masukkan kode pos!";
      isValid = false;
    }
    if (form.no_telepon_kode == "" && props.tipeClient == "direct") {
      errors["no_telepon"] = "Masukkan kode area telepon!";
      isValid = false;
    }
    if (form.no_telepon == "" && props.tipeClient == "direct") {
      errors["no_telepon"] = "Masukkan nomor telepon!";
      isValid = false;
    }
    if (form.no_telepon_kode == "" && form.no_telepon != "") {
      errors["no_telepon"] = "Masukkan kode area telepon!";
      isValid = false;
    }
    if (form.no_telepon_kode != "" && form.no_telepon == "") {
      errors["no_telepon"] = "Masukkan nomor telepon!";
      isValid = false;
    }
    if (form.no_telepon_kode_other == "" && form.no_telepon_other != "") {
      errors["no_telepon_other"] = "Masukkan kode area telepon!";
      isValid = false;
    }
    if (form.no_telepon_kode_other != "" && form.no_telepon_other == "") {
      errors["no_telepon_other"] = "Masukkan nomor telepon!";
      isValid = false;
    }

    if (form.no_hp_kode == "") {
      errors["no_hp"] = "Masukkan kode area handphone!";
      isValid = false;
    }
    if (form.no_hp == "") {
      errors["no_hp"] = "Masukkan nomor handphone!";
      isValid = false;
    }
    if (form.no_hp_kode_other == "" && form.no_hp_other != "") {
      errors["no_hp_other"] = "Masukkan kode area handphone!";
      isValid = false;
    }
    if (form.no_hp_kode_other != "" && form.no_hp_other == "") {
      errors["no_hp_other"] = "Masukkan nomor handphone!";
      isValid = false;
    }

    if (form.email == "") {
      errors["email"] = "Masukkan email!";
      isValid = false;
    } else {
      const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!re.test(form.email)) {
        errors["email"] = "Masukkan email dengan benar!";
        isValid = false;
      }
    }

    if (form.email_other != "") {
      const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!re.test(form.email_other)) {
        errors["email_other"] = "Masukkan email dengan benar!";
        isValid = false;
      }
    }

    if (form.pendidikan == "") {
      errors["pendidikan"] = "Pilih pendidikan terakhir!";
      isValid = false;
    }
    if (form.pekerjaan == "") {
      errors["pekerjaan"] = "Pilih jenis pekerjaan!";
      isValid = false;
    }
    if (form.pekerjaan == 1 && form.deskripsi_pekerjaan == "") {
      errors["deskripsi_pekerjaan"] = "Masukkan keterangan jenis pekerjaan!";
      isValid = false;
    }
    if (form.pekerjaan == 5 && form.deskripsi_usaha == "") {
      errors["deskripsi_usaha"] = "Masukkan keterangan jenis usaha!";
      isValid = false;
    }
    if (form.penghasilan == "") {
      errors["penghasilan"] = "Pilih penghasilan!";
      isValid = false;
    }
    if (form.sumber_dana == "") {
      errors["sumber_dana"] = "Pilih sumber dana!";
      isValid = false;
    }
    if (form.sumber_dana == 1 && form.deskripsi_sumber_dana == "") {
      errors["deskripsi_sumber_dana"] = "Masukkan keterangan sumber dana!";
      isValid = false;
    }
    if (form.tujuan_investasi == "") {
      errors["tujuan_investasi"] = "Pilih tujuan investasi!";
      isValid = false;
    }
    if (form.sid == "" && form.experience == "1") {
      errors["sid"] = "Masukkan nomor SID!";
      isValid = false;
    }
    if (!checkedClientExperience && !checkedClientNonExperience) {
      errors["sid"] = "Silahkan ceklist apakah anda berpengalaman atau tidak!";
      isValid = false;
    }
    if (actionForm == "insert") {
      if (form.experience_evidence == "" && form.experience == "1") {
        errors["experience_evidence"] =
          "Lampirkan bukti kepemilikan rekening efek!";
        isValid = false;
      }
    } else {
      if (sid_old != form.sid) {
        if (form.experience_evidence == "" && form.experience == "1") {
          errors["experience_evidence"] =
            "Lampirkan bukti kepemilikan rekening efek!";
          isValid = false;
        }
      }
    }
    if (form.experience_evidence != "" && form.experience == "1") {
      var formField = document.querySelector('form[name="formDataPribadi"]');
      var experienceEvidence = formField.querySelector(
        'input[name="experience_evidence"]'
      ).files[0];

      if (experienceEvidence.size > 2048000) {
        errors["experience_evidence"] = "Maksimal file upload 2 MB!";
        isValid = false;
      } else {
        form.experience_evidence = await toBase64(experienceEvidence);
      }
    }
    if (form.kepemilikan_efek == "") {
      errors["kepemilikan_efek"] = "Pilih kepemilikan efek!";
      isValid = false;
    }
    if (form.ktp == "") {
      errors["ktp"] = "Masukkan nomor KTP!";
      isValid = false;
    }
    if (form.ktp_expired == "") {
      errors["ktp_expired"] = "Masukkan tanggal masa berlaku KTP!";
      isValid = false;
    }
    if (form.npwp == "") {
      errors["npwp"] = "Masukkan nomor NPWP!";
      isValid = false;
    }
    if (form.npwp_register == "") {
      errors["npwp_register"] = "Masukkan tanggal registrasi NPWP!";
      isValid = false;
    }
    if (form.country != "") {
      if (form.country != "360" && form.passport == "") {
        errors["passport"] = "Masukkan nomor Passport!";
        isValid = false;
      }
      if (
        (form.passport != "" || form.country != "360") &&
        form.passport_expired == ""
      ) {
        errors["passport_expired"] = "Masukkan tanggal masa berlaku Passport!";
        isValid = false;
      }
    }
    // if (form.kitas == '') {
    //   errors['kitas'] = "Masukkan nomor KITAS/SKD!"
    //   isValid = false
    // }
    if (form.kitas != "" && form.kitas_expired == "") {
      errors["kitas_expired"] = "Masukkan tanggal masa berlaku KITAS/SKD!";
      isValid = false;
    }

    setErrorMessage((errorMessage) => ({
      ...errorMessage,
      ...errors,
    }));

    if (isValid) {
      postData(form);
    } else {
      swal({ text: "Periksa kembali data yang anda masukkan!", icon: "error" });
    }
  }

  return (
    <>
      {showEdit == false ? (
        <Container style={{ display: "block" }}>
          <Row>
            <Col md={12}>
              <Header id="fonts" as="h6" color="blue" size="small">
                <Header.Content>
                  Data Pribadi{" "}
                  <a
                    href="#"
                    onClick={openEditForm.bind(this)}
                    style={{ marginLeft: "10px" }}
                  >
                    <Icon name="pencil alternate" />
                  </a>
                </Header.Content>
              </Header>

              <Form.Group as={Row}>
                <Col sm="4" style={{ color: "gray" }}>
                  No. Identitas
                </Col>
                <Col sm="8">{props.userinfo.ktp}</Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: "gray" }}>
                  Nama
                </Col>
                <Col sm="8">
                  {props.userinfo.nama_depan +
                    " " +
                    props.userinfo.nama_tengah +
                    " " +
                    props.userinfo.nama_belakang}
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: "gray" }}>
                  Jenis Kelamin
                </Col>
                <Col sm="8">
                  {jenisKelaminOptions
                    .filter((a) => a.value == props.userinfo.jenis_kelamin)
                    .map((b) => b.text)}
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: "gray" }}>
                  Nomor Telepon
                </Col>
                <Col sm="8">
                  {props.userinfo.no_telepon_kode +
                    " " +
                    props.userinfo.no_telepon}
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: "gray" }}>
                  Email
                </Col>
                <Col sm="8">{props.userinfo.email}</Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: "gray" }}>
                  Alamat
                </Col>
                <Col sm="8">{props.userinfo.alamat}</Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: "gray" }}>
                  Tempat, Taggal Lahir
                </Col>
                <Col sm="8">
                  {props.userinfo.tempat_lahir}, {props.userinfo.tanggal_lahir}
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: "gray" }}>
                  Warga Negara
                </Col>
                <Col sm="8">
                  {props.dataCountries
                    .filter((a) => a.country_id == props.userinfo.nationality)
                    .map((b) => b.country_name)}
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: "gray" }}>
                  NPWP
                </Col>
                <Col sm="8">{props.userinfo.npwp}</Col>
              </Form.Group>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container style={{ display: "block" }}>
          <Form
            onSubmit={(e) => e.preventDefault()}
            encType="multipart/form-data"
            autoComplete="off"
          >
            <Row>
              <Col md={12}>
                <Header id="fonts" as="h6" color="blue" size="small">
                  <Header.Content>Edit Data Pribadi</Header.Content>
                </Header>

                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Kode Member<span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      name="member_code"
                      value={form.member_code}
                      onChange={handleTextChange}
                      disabled={true}
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["member_code"]}
                    </span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Kode Client<span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      name="client_code"
                      value={form.client_code}
                      onChange={handleTextChange}
                      disabled={true}
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["client_code"]}
                    </span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Kode Tingkat Pajak Investor
                    <span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      name="tax_code"
                      value={form.tax_code}
                      onChange={handleTextChange}
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["tax_code"]}
                    </span>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Nama Depan<span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      name="nama_depan"
                      value={form.nama_depan}
                      onChange={handleTextChange}
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["nama_depan"]}
                    </span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Nama Tengah
                  </Col>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      name="nama_tengah"
                      value={form.nama_tengah}
                      onChange={handleTextChange}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Nama Belakang
                  </Col>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      name="nama_belakang"
                      value={form.nama_belakang}
                      onChange={handleTextChange}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Kewarganegaraan<span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="8" id="col-country">
                    <Dropdown
                      name="nationality"
                      onChange={(e, { value }) => {
                        form.nationality = value;
                        setErrorMessage((errorMessage) => ({
                          ...errorMessage,
                          ["nationality"]: "",
                        }));
                      }}
                      placeholder="Warga Negara"
                      style={{ width: "100%" }}
                      closeOnChange
                      search
                      selection
                      value={form.nationality}
                      options={props.dataCountries.map((item) => ({
                        key: item.country_id,
                        text: item.country_name,
                        value: item.country_id,
                      }))}
                      onOpen={() => $("#col-country").css("z-index", "999")}
                      onClose={() => $("#col-country").removeAttr("style")}
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["nationality"]}
                    </span>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    No. KTP<span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      name="ktp"
                      value={form.ktp}
                      onChange={handleTextChange}
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["ktp"]}
                    </span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Tanggal Registrasi KTP
                  </Col>
                  <Col sm="8" id="col-ktp-register" className="col-datepicker">
                    <DatePicker
                      name="ktp_register"
                      value={selectedDayKTPReg}
                      onChange={handleChangeKTPReg}
                      renderInput={renderCustomInputKTPReg}
                      shouldHighlightWeekends
                      maximumDate={maximumDate}
                      calendarPopperPosition={"bottom"}
                    />
                    <br />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["ktp_register"]}
                    </span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Masa berlaku KTP<span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="3" id="col-ktp-expired" className="col-datepicker">
                    <DatePicker
                      name="ktp_expired"
                      value={selectedDayKTPExpired}
                      onChange={handleChangeKTPExpired}
                      renderInput={renderCustomInputKTPExpired}
                      shouldHighlightWeekends
                      minimumDate={minimumDate}
                      calendarPopperPosition={"bottom"}
                    />
                    <br />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["ktp_expired"]}
                    </span>
                  </Col>
                  <Col sm="5">
                    <Checkbox
                      onChange={handleCheck}
                      checked={checked}
                      label="Seumur hidup"
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    NPWP<span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      name="npwp"
                      value={form.npwp}
                      onChange={handleTextChange}
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["npwp"]}
                    </span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Tanggal Registrasi NPWP
                    <span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="8" id="col-npwp-register" className="col-datepicker">
                    <DatePicker
                      name="npwp_register"
                      value={selectedDayNPWPReg}
                      onChange={handleChangeNPWPReg}
                      renderInput={renderCustomInputNPWPReg}
                      shouldHighlightWeekends
                      maximumDate={maximumDate}
                      calendarPopperPosition={"bottom"}
                    />
                    <br />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["npwp_register"]}
                    </span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    No. Passport
                    <br />
                    <span style={{ fontSize: "11px" }}>
                      (Wajib diisi jika warga negara selain Indonesia)
                    </span>
                    <span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      name="passport"
                      value={form.passport}
                      onChange={handleTextChange}
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["passport"]}
                    </span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Masa Berlaku Passport
                    <br />
                    <span style={{ fontSize: "11px" }}>
                      (Wajib diisi jika passport diisi)
                    </span>
                    <span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col
                    sm="8"
                    id="col-passport-expired"
                    className="col-datepicker"
                  >
                    <DatePicker
                      name="passport_expired"
                      value={selectedDayPassportExpired}
                      onChange={handleChangePassportExpired}
                      renderInput={renderCustomInputPassportExpired}
                      shouldHighlightWeekends
                      minimumDate={minimumDate}
                      calendarPopperPosition={"bottom"}
                    />
                    <br />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["passport_expired"]}
                    </span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    No. KITAS/SKD
                  </Col>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      name="kitas"
                      value={form.kitas}
                      onChange={handleTextChange}
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["kitas"]}
                    </span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Masa Berlaku KITAS/SKD
                    <br />
                    <span style={{ fontSize: "11px" }}>
                      (Wajib diisi jika KITAS/SKD diisi)
                    </span>
                    <span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="8" id="col-kitas-expired" className="col-datepicker">
                    <DatePicker
                      name="kitas_expired"
                      value={selectedDayKitasExpired}
                      onChange={handleChangeKitasExpired}
                      renderInput={renderCustomInputKitasExpired}
                      shouldHighlightWeekends
                      minimumDate={minimumDate}
                      calendarPopperPosition={"bottom"}
                    />
                    <br />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["kitas_expired"]}
                    </span>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Tempat Lahir
                    <br />
                    <span style={{ fontSize: "11px" }}>
                      (Wajib diisi untuk pemodal langsung)
                    </span>
                    <span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      name="tempat_lahir"
                      value={form.tempat_lahir}
                      onChange={handleTextChange}
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["tempat_lahir"]}
                    </span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Tanggal Lahir<span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="8" id="col-tanggal-lahir" className="col-datepicker">
                    <DatePicker
                      name="tanggal_lahir"
                      value={selectedDayDOB}
                      onChange={handleChangeDOB}
                      renderInput={renderCustomInputDOB}
                      shouldHighlightWeekends
                      maximumDate={maximumDate}
                      calendarPopperPosition={"bottom"}
                    />
                    <br />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["tanggal_lahir"]}
                    </span>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Alamat<span style={{ color: "red" }}>*</span>
                    <br />
                    <span style={{ fontSize: "11px" }}>
                      (Alamat sesuai identitas KTP)
                    </span>
                    <span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      name="alamat"
                      value={form.alamat}
                      onChange={handleTextChange}
                      placeholder="Masukkan nama jalan, nama pemukiman, dan nomor rumah"
                    />
                    <br />
                    <Form.Control
                      type="text"
                      name="alamat2"
                      value={form.alamat2}
                      onChange={handleTextChange}
                      placeholder="Masukkan RT/RW"
                    />
                    <br />
                    <Form.Control
                      type="text"
                      name="alamat3"
                      value={form.alamat3}
                      onChange={handleTextChange}
                      placeholder="Masukkan nama desa/kelurahan"
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["alamat"]}
                    </span>
                  </Col>
                </Form.Group>

                <div style={{ display: isDisplay }}>
                  <Form.Group as={Row}>
                    <Col sm="4" style={{ color: "gray" }}>
                      Provinsi<span style={{ color: "red" }}>*</span>
                    </Col>
                    <Col sm="8">
                      <Dropdown
                        name="province"
                        onChange={(e, { value }) => {
                          form.province = value;
                          setErrorMessage((errorMessage) => ({
                            ...errorMessage,
                            ["province"]: "",
                          }));
                          props.getDataDistrict(1, form.province);
                        }}
                        placeholder="Provinsi"
                        style={{ width: "100%" }}
                        closeOnChange
                        search
                        selection
                        value={form.province}
                        options={props.dataProvince.map((item) => ({
                          key: item.province_id,
                          text: item.province_name,
                          value: item.province_id,
                        }))}
                      />
                      <span style={{ color: "red", fontSize: 11 }}>
                        {errorMessage["province"]}
                      </span>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col sm="4" style={{ color: "gray" }}>
                      Kota/Kabupaten<span style={{ color: "red" }}>*</span>
                    </Col>
                    <Col sm="8" id="col-district">
                      <Dropdown
                        name="district"
                        onChange={(e, { value }) => {
                          form.district = value;
                          setErrorMessage((errorMessage) => ({
                            ...errorMessage,
                            ["district"]: "",
                          }));
                          props.getDataSubDistrict(1, form.district);
                        }}
                        placeholder="Kota/Kabupaten"
                        style={{ width: "100%" }}
                        closeOnChange
                        search
                        selection
                        value={form.district}
                        options={props.dataDistrict.map((item) => ({
                          key: item.district_id,
                          text: item.district_name,
                          value: item.district_id,
                        }))}
                        onOpen={() => $("#col-district").css("z-index", "999")}
                        onClose={() => $("#col-district").removeAttr("style")}
                      />
                      <span style={{ color: "red", fontSize: 11 }}>
                        {errorMessage["district"]}
                      </span>
                    </Col>
                  </Form.Group>
                  {/* sub_district wajib ada, untuk mendukung digital signature */}
                  <Form.Group as={Row}>
                    <Col sm="4" style={{ color: "gray" }}>
                      Kecamatan<span style={{ color: "red" }}>*</span>
                    </Col>
                    <Col sm="8" id="col-sub-district">
                      <Dropdown
                        name="sub_district"
                        onChange={(e, { value }) => {
                          form.sub_district = value;
                          setErrorMessage((errorMessage) => ({
                            ...errorMessage,
                            ["sub_district"]: "",
                          }));
                        }}
                        placeholder="Kecamatan"
                        style={{ width: "100%" }}
                        closeOnChange
                        search
                        selection
                        value={form.sub_district}
                        // options={options}
                        options={props.dataSubDistrict.map((item) => ({
                          key: item.sub_district_id,
                          text: item.sub_district_name,
                          value: item.sub_district_id,
                        }))}
                        onOpen={() =>
                          $("#col-sub-district").css("z-index", "999")
                        }
                        onClose={() =>
                          $("#col-sub-district").removeAttr("style")
                        }
                      />
                      <span style={{ color: "red", fontSize: 11 }}>
                        {errorMessage["sub_district"]}
                      </span>
                    </Col>
                  </Form.Group>
                </div>

                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Kode Pos<span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      name="kode_pos"
                      value={form.kode_pos}
                      onChange={handleTextChange}
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["kode_pos"]}
                    </span>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Negara<span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="8" id="col-country">
                    <Dropdown
                      name="country"
                      onChange={(e, { value }) => {
                        form.country = value;
                        setErrorMessage((errorMessage) => ({
                          ...errorMessage,
                          ["country"]: "",
                        }));
                        refreshOtherCountries();
                        props.getDataProvince(1, form.country);
                        if (form.country == "360") {
                          form.no_telepon_kode = "+62";
                          form.no_hp_kode = "+62";
                        } else {
                          form.no_telepon_kode = "";
                          form.no_hp_kode = "";
                        }
                      }}
                      placeholder="Negara"
                      style={{ width: "100%" }}
                      closeOnChange
                      search
                      selection
                      value={form.country}
                      options={props.dataCountries.map((item) => ({
                        key: item.country_id,
                        text: item.country_name,
                        value: item.country_id,
                      }))}
                      onOpen={() => $("#col-country").css("z-index", "999")}
                      onClose={() => $("#col-country").removeAttr("style")}
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["country"]}
                    </span>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Nomor Telepon
                    <br />
                    <span style={{ fontSize: "11px" }}>
                      (Wajib diisi untuk pemodal langsung.
                      <br />
                      Contoh: +62 211234123)
                    </span>
                    <span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="8">
                    <Form.Group as={Row}>
                      <Col sm="2">
                        <Form.Control
                          type="text"
                          name="no_telepon_kode"
                          value={form.no_telepon_kode}
                          onChange={handleTextChange}
                        />
                      </Col>
                      <Col sm="10">
                        <Form.Control
                          type="text"
                          name="no_telepon"
                          value={form.no_telepon}
                          onChange={handleTextChange}
                        />
                      </Col>
                    </Form.Group>
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["no_telepon"]}
                    </span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Nomor Handphone<span style={{ color: "red" }}>*</span>
                    <br />
                    <span style={{ fontSize: "11px" }}>
                      (Contoh: +62 82112341234)
                    </span>
                  </Col>
                  <Col sm="8">
                    <Form.Group as={Row}>
                      <Col sm="2">
                        <Form.Control
                          type="text"
                          name="no_hp_kode"
                          value={form.no_hp_kode}
                          onChange={handleTextChange}
                          disabled={true}
                        />
                      </Col>
                      <Col sm="10">
                        <Form.Control
                          type="text"
                          name="no_hp"
                          value={form.no_hp}
                          onChange={handleTextChange}
                          disabled={true}
                        />
                      </Col>
                    </Form.Group>
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["no_hp"]}
                    </span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Email<span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      name="email"
                      value={form.email}
                      onChange={handleTextChange}
                      disabled={true}
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["email"]}
                    </span>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Fax.
                    <br />
                    <span style={{ fontSize: "11px" }}>
                      (Contoh: +62 211234123)
                    </span>
                  </Col>
                  <Col sm="8">
                    <Form.Group as={Row}>
                      <Col sm="2">
                        <Form.Control
                          type="text"
                          name="no_fax_kode"
                          value={form.no_fax_kode}
                          onChange={handleTextChange}
                        />
                      </Col>
                      <Col sm="10">
                        <Form.Control
                          type="text"
                          name="no_fax"
                          value={form.no_fax}
                          onChange={handleTextChange}
                        />
                      </Col>
                    </Form.Group>
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["no_fax"]}
                    </span>
                  </Col>
                </Form.Group>

                {/** Identitas other */}
                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Alamat Lainnya
                    <br />
                    <span style={{ fontSize: "11px" }}>
                      (Alamat sesuai domisili)
                    </span>
                    <span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      name="alamat_other"
                      value={form.alamat_other}
                      onChange={handleTextChange}
                      placeholder="Masukkan nama jalan, nama pemukiman, dan nomor rumah"
                    />
                    <br />
                    <Form.Control
                      type="text"
                      name="alamat_other2"
                      value={form.alamat_other2}
                      onChange={handleTextChange}
                      placeholder="Masukkan RT/RW"
                    />
                    <br />
                    <Form.Control
                      type="text"
                      name="alamat_other3"
                      value={form.alamat_other3}
                      onChange={handleTextChange}
                      placeholder="Masukkan nama desa/kelurahan"
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["alamat_other"]}
                    </span>
                  </Col>
                </Form.Group>

                <div style={{ display: isDisplayOther }}>
                  <Form.Group as={Row}>
                    <Col sm="4" style={{ color: "gray" }}>
                      Provinsi Lainnya
                    </Col>
                    <Col sm="8">
                      <Dropdown
                        name="province_other"
                        onChange={(e, { value }) => {
                          form.province_other = value;
                          setErrorMessage((errorMessage) => ({
                            ...errorMessage,
                            ["province_other"]: "",
                          }));
                          props.getDataDistrict(2, form.province_other);
                        }}
                        placeholder="Provinsi"
                        style={{ width: "100%" }}
                        closeOnChange
                        search
                        selection
                        value={form.province_other}
                        options={props.dataProvinceOther.map((item) => ({
                          key: item.province_id,
                          text: item.province_name,
                          value: item.province_id,
                        }))}
                      />
                      <span style={{ color: "red", fontSize: 11 }}>
                        {errorMessage["province_other"]}
                      </span>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col sm="4" style={{ color: "gray" }}>
                      Kota/Kabupaten Lainnya
                    </Col>
                    <Col sm="8" id="col-district">
                      <Dropdown
                        name="district_other"
                        onChange={(e, { value }) => {
                          form.district_other = value;
                          setErrorMessage((errorMessage) => ({
                            ...errorMessage,
                            ["district_other"]: "",
                          }));
                          props.getDataSubDistrict(2, form.district_other);
                        }}
                        placeholder="Kota/Kabupaten"
                        style={{ width: "100%" }}
                        closeOnChange
                        search
                        selection
                        value={form.district_other}
                        options={props.dataDistrictOther.map((item) => ({
                          key: item.district_id,
                          text: item.district_name,
                          value: item.district_id,
                        }))}
                        onOpen={() => $("#col-district").css("z-index", "999")}
                        onClose={() => $("#col-district").removeAttr("style")}
                      />
                      <span style={{ color: "red", fontSize: 11 }}>
                        {errorMessage["district_other"]}
                      </span>
                    </Col>
                  </Form.Group>
                  {/* sub_district wajib ada, untuk mendukung digital signature */}
                  <Form.Group as={Row}>
                    <Col sm="4" style={{ color: "gray" }}>
                      Kecamatan Lainnya
                    </Col>
                    <Col sm="8" id="col-sub-district">
                      <Dropdown
                        name="sub_district_other"
                        onChange={(e, { value }) => {
                          form.sub_district_other = value;
                          setErrorMessage((errorMessage) => ({
                            ...errorMessage,
                            ["sub_district_other"]: "",
                          }));
                        }}
                        placeholder="Kecamatan"
                        style={{ width: "100%" }}
                        closeOnChange
                        search
                        selection
                        value={form.sub_district_other}
                        // options={options}
                        options={props.dataSubDistrictOther.map((item) => ({
                          key: item.sub_district_id,
                          text: item.sub_district_name,
                          value: item.sub_district_id,
                        }))}
                        onOpen={() =>
                          $("#col-sub-district").css("z-index", "999")
                        }
                        onClose={() =>
                          $("#col-sub-district").removeAttr("style")
                        }
                      />
                      <span style={{ color: "red", fontSize: 11 }}>
                        {errorMessage["sub_district_other"]}
                      </span>
                    </Col>
                  </Form.Group>
                </div>

                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Kode Pos Lainnya
                  </Col>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      name="kode_pos_other"
                      value={form.kode_pos_other}
                      onChange={handleTextChange}
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["kode_pos_other"]}
                    </span>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Negara Lainnya
                  </Col>
                  <Col sm="8" id="col-country">
                    <Dropdown
                      name="country_other"
                      onChange={(e, { value }) => {
                        form.country_other = value;
                        setErrorMessage((errorMessage) => ({
                          ...errorMessage,
                          ["country_other"]: "",
                        }));
                        refreshOtherCountries_other();
                        props.getDataProvince(2, form.country_other);
                        if (form.country_other == "360") {
                          form.no_telepon_kode_other = "+62";
                          form.no_hp_kode_other = "+62";
                        } else {
                          form.no_telepon_kode_other = "";
                          form.no_hp_kode_other = "";
                        }
                      }}
                      placeholder="Negara"
                      style={{ width: "100%" }}
                      closeOnChange
                      search
                      selection
                      value={form.country_other}
                      options={props.dataCountries.map((item) => ({
                        key: item.country_id,
                        text: item.country_name,
                        value: item.country_id,
                      }))}
                      onOpen={() => $("#col-country").css("z-index", "999")}
                      onClose={() => $("#col-country").removeAttr("style")}
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["country_other"]}
                    </span>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Nomor Telepon Lainnya
                    <br />
                    <span style={{ fontSize: "11px" }}>
                      (Contoh: +62 211234123)
                    </span>
                  </Col>
                  <Col sm="8">
                    <Form.Group as={Row}>
                      <Col sm="2">
                        <Form.Control
                          type="text"
                          name="no_telepon_kode_other"
                          value={form.no_telepon_kode_other}
                          onChange={handleTextChange}
                        />
                      </Col>
                      <Col sm="10">
                        <Form.Control
                          type="text"
                          name="no_telepon_other"
                          value={form.no_telepon_other}
                          onChange={handleTextChange}
                        />
                      </Col>
                    </Form.Group>
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["no_telepon_other"]}
                    </span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Nomor Handphone Lainnya
                    <br />
                    <span style={{ fontSize: "11px" }}>
                      (Contoh: +62 82112341234)
                    </span>
                  </Col>
                  <Col sm="8">
                    <Form.Group as={Row}>
                      <Col sm="2">
                        <Form.Control
                          type="text"
                          name="no_hp_kode_other"
                          value={form.no_hp_kode_other}
                          onChange={handleTextChange}
                        />
                      </Col>
                      <Col sm="10">
                        <Form.Control
                          type="text"
                          name="no_hp_other"
                          value={form.no_hp_other}
                          onChange={handleTextChange}
                        />
                      </Col>
                    </Form.Group>
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["no_hp_other"]}
                    </span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Email Lainnya
                  </Col>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      name="email_other"
                      value={form.email_other}
                      onChange={handleTextChange}
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["email_other"]}
                    </span>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Fax. Lainnya
                    <br />
                    <span style={{ fontSize: "11px" }}>
                      (Contoh: +62 211234123)
                    </span>
                  </Col>
                  <Col sm="8">
                    <Form.Group as={Row}>
                      <Col sm="2">
                        <Form.Control
                          type="text"
                          name="no_fax_other"
                          value={form.no_fax_other}
                          onChange={handleTextChange}
                        />
                      </Col>
                      <Col sm="10">
                        <Form.Control
                          type="text"
                          name="no_fax_other"
                          value={form.no_fax_other}
                          onChange={handleTextChange}
                        />
                      </Col>
                    </Form.Group>
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["no_fax_other"]}
                    </span>
                  </Col>
                </Form.Group>
                {/** End identitas other */}

                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Jenis Kelamin<span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="8" id="col-jenis-kelamin">
                    <Dropdown
                      name="jenis_kelamin"
                      value={form.jenis_kelamin}
                      onChange={(e, { value }) => {
                        form.jenis_kelamin = value;
                        setErrorMessage((errorMessage) => ({
                          ...errorMessage,
                          ["jenis_kelamin"]: "",
                        }));
                      }}
                      placeholder="Jenis Kelamin"
                      style={{ width: "100%" }}
                      closeOnChange
                      search
                      selection
                      options={jenisKelaminOptions}
                      onOpen={() =>
                        $("#col-jenis-kelamin").css("z-index", "999")
                      }
                      onClose={() =>
                        $("#col-jenis-kelamin").removeAttr("style")
                      }
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["jenis_kelamin"]}
                    </span>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Status Pernikahan<span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="8" id="col-status-pernikahan">
                    <Dropdown
                      name="status_pernikahan"
                      value={form.status_pernikahan}
                      onChange={(e, { value }) => {
                        form.status_pernikahan = value;
                        setErrorMessage((errorMessage) => ({
                          ...errorMessage,
                          ["status_pernikahan"]: "",
                        }));
                      }}
                      placeholder="Status Pernikahan"
                      style={{ width: "100%" }}
                      closeOnChange
                      search
                      selection
                      options={statusPernikahanOptions}
                      onOpen={() =>
                        $("#col-status-pernikahan").css("z-index", "999")
                      }
                      onClose={() =>
                        $("#col-status-pernikahan").removeAttr("style")
                      }
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["status_pernikahan"]}
                    </span>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Nama Pasangan (Suami / Istri)
                  </Col>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      name="nama_pasangan"
                      value={form.nama_pasangan}
                      onChange={handleTextChange}
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["nama_pasangan"]}
                    </span>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Nama Gadis Ibu Kandung
                    <br />
                    <span style={{ fontSize: "11px" }}>
                      (Wajib diisi untuk pemodal langsung)
                    </span>
                    <span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      name="nama_ibu_kandung"
                      value={form.nama_ibu_kandung}
                      onChange={handleTextChange}
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["nama_ibu_kandung"]}
                    </span>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Nama Ahli Waris
                  </Col>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      name="nama_ahli_waris"
                      value={form.nama_ahli_waris}
                      onChange={handleTextChange}
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["nama_ahli_waris"]}
                    </span>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Hubungan Ahli Waris
                  </Col>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      name="hubungan_ahli_waris"
                      value={form.hubungan_ahli_waris}
                      onChange={handleTextChange}
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["hubungan_ahli_waris"]}
                    </span>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Pendidikan Terakhir<span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="8" id="col-pendidikan">
                    <Dropdown
                      name="pendidikan"
                      value={form.pendidikan}
                      onChange={(e, { value }) => {
                        form.pendidikan = value;
                        setErrorMessage((errorMessage) => ({
                          ...errorMessage,
                          ["pendidikan"]: "",
                        }));
                      }}
                      placeholder="Pendidikan Terakhir"
                      style={{ width: "100%" }}
                      closeOnChange
                      search
                      selection
                      options={pendidikanOptions}
                      onOpen={() => $("#col-pendidikan").css("z-index", "999")}
                      onClose={() => $("#col-pendidikan").removeAttr("style")}
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["pendidikan"]}
                    </span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Pekerjaan<span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="8" id="col-pekerjaan">
                    <Dropdown
                      name="pekerjaan"
                      value={form.pekerjaan}
                      onChange={(e, { value }) => {
                        form.pekerjaan = value;
                        setErrorMessage((errorMessage) => ({
                          ...errorMessage,
                          ["pekerjaan"]: "",
                        }));
                        if (form.pekerjaan != 1 || form.pekerjaan != 5) {
                          setErrorMessage((errorMessage) => ({
                            ...errorMessage,
                            ["deskripsi_pekerjaan"]: "",
                          }));
                          setErrorMessage((errorMessage) => ({
                            ...errorMessage,
                            ["deskripsi_usaha"]: "",
                          }));
                        }
                      }}
                      placeholder="Pekerjaan"
                      style={{ width: "100%" }}
                      closeOnChange
                      search
                      selection
                      options={pekerjaanOptions}
                      onOpen={() => $("#col-pekerjaan").css("z-index", "999")}
                      onClose={() => $("#col-pekerjaan").removeAttr("style")}
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["pekerjaan"]}
                    </span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  {" "}
                  {/*Wajib diisi jika Pekerjaan dipilih = Lainnya*/}
                  <Col sm="4" style={{ color: "gray" }}>
                    Deskripsi Pekerjaan
                    <br />
                    <span style={{ fontSize: "11px" }}>
                      (Wajib diisi jika pekerjaan dipilih "Lainnya")
                    </span>
                    <span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      name="deskripsi_pekerjaan"
                      value={form.deskripsi_pekerjaan}
                      onChange={handleTextChange}
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["deskripsi_pekerjaan"]}
                    </span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  {" "}
                  {/*Wajib diisi jika Pekerjaan dipilih = Wiraswasta*/}
                  <Col sm="4" style={{ color: "gray" }}>
                    Deskripsi Usaha / Bisnis
                    <br />
                    <span style={{ fontSize: "11px" }}>
                      (Wajib diisi jika pekerjaan dipilih "Wiraswasta")
                    </span>
                    <span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      name="deskripsi_usaha"
                      value={form.deskripsi_usaha}
                      onChange={handleTextChange}
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["deskripsi_usaha"]}
                    </span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Penghasilan Pertahun<span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="8" id="col-penghasilan">
                    <Dropdown
                      name="penghasilan"
                      value={form.penghasilan}
                      onChange={(e, { value }) => {
                        form.penghasilan = value;
                        setErrorMessage((errorMessage) => ({
                          ...errorMessage,
                          ["penghasilan"]: "",
                        }));
                      }}
                      placeholder="Penghasilan Pertahun"
                      style={{ width: "100%" }}
                      closeOnChange
                      search
                      selection
                      options={penghasilanOptions}
                      onOpen={() => $("#col-penghasilan").css("z-index", "999")}
                      onClose={() => $("#col-penghasilan").removeAttr("style")}
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["penghasilan"]}
                    </span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Sumber Dana Investasi<span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="8" id="col-sumber-dana">
                    <Dropdown
                      name="sumber_dana"
                      value={form.sumber_dana}
                      onChange={(e, { value }) => {
                        form.sumber_dana = value;
                        setErrorMessage((errorMessage) => ({
                          ...errorMessage,
                          ["sumber_dana"]: "",
                        }));
                        if (form.pekerjaan != 1) {
                          setErrorMessage((errorMessage) => ({
                            ...errorMessage,
                            ["deskripsi_sumber_dana"]: "",
                          }));
                        }
                      }}
                      placeholder="Sumber Dana Investasi"
                      style={{ width: "100%" }}
                      closeOnChange
                      search
                      selection
                      options={sumberDanaOptions}
                      onOpen={() => $("#col-sumber-dana").css("z-index", "999")}
                      onClose={() => $("#col-sumber-dana").removeAttr("style")}
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["sumber_dana"]}
                    </span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  {" "}
                  {/*Wajib diisi jika sumber dana dipilih = lainnya*/}
                  <Col sm="4" style={{ color: "gray" }}>
                    Deskripsi Sumber Dana
                    <br />
                    <span style={{ fontSize: "11px" }}>
                      (Wajib diisi jika sumber dana dipilih "Lainnya")
                    </span>
                    <span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      name="deskripsi_sumber_dana"
                      value={form.deskripsi_sumber_dana}
                      onChange={handleTextChange}
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["deskripsi_sumber_dana"]}
                    </span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Tujuan Investasi<span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="8" id="col-tujuan-investasi">
                    <Dropdown
                      name="tujuan_investasi"
                      value={form.tujuan_investasi}
                      onChange={(e, { value }) => {
                        form.tujuan_investasi = value;
                        setErrorMessage((errorMessage) => ({
                          ...errorMessage,
                          ["tujuan_investasi"]: "",
                        }));
                      }}
                      placeholder="Tujuan Investasi"
                      style={{ width: "100%" }}
                      closeOnChange
                      search
                      selection
                      options={tujuanInvestasiOptions}
                      onOpen={() =>
                        $("#col-tujuan-investasi").css("z-index", "999")
                      }
                      onClose={() =>
                        $("#col-tujuan-investasi").removeAttr("style")
                      }
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["tujuan_investasi"]}
                    </span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    SID
                    <br />
                    <span style={{ fontSize: "11px" }}>
                      (SID bisa diisi setelah anda memilih pengalaman atau non
                      pengalaman)
                    </span>
                    <span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="3">
                    <Form.Control
                      type="text"
                      name="sid"
                      value={form.sid}
                      onChange={handleTextChange}
                      disabled={!sidDisabled ? false : true}
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["sid"]}
                    </span>
                  </Col>
                  <Col sm="5">
                    <Checkbox
                      onChange={handleCheckClientExperience}
                      checked={checkedClientExperience}
                      label="Pengalaman 2 tahun atau lebih"
                    />
                    <br />
                    <Checkbox
                      onChange={handleCheckClientNonExperience}
                      checked={checkedClientNonExperience}
                      label="Non pengalaman atau pengalaman kurang dari 2 tahun"
                    />
                  </Col>
                </Form.Group>
                {form.experience == "1" && (
                  <Form.Group as={Row}>
                    <Col sm="4" style={{ color: "gray" }}></Col>
                    <Col sm="8">
                      <div style={{ marginBottom: 10 }}>
                        <span style={{ fontSize: "11px" }}>
                          Lampirkan bukti kepemilikan rekening efek (format:
                          pdf, jpg, jpeg, png)
                        </span>
                        <span style={{ color: "red" }}>*</span>
                      </div>
                      <Form.Control
                        type="file"
                        name="experience_evidence"
                        onChange={handleTextChange}
                        placeholder="Bukti pengalaman 2 tahun atau lebih"
                        accept="application/pdf, image/jpg, image/jpeg, image/png"
                      />
                      <br />
                      <span style={{ color: "red", fontSize: 11 }}>
                        {errorMessage["experience_evidence"]}
                      </span>
                    </Col>
                  </Form.Group>
                )}
                {actionForm == "edit" && form.experience == "1" && (
                  <Form.Group as={Row}>
                    <Col sm="4" style={{ color: "gray" }}></Col>
                    <Col sm="8">
                      <a href="#" onClick={"handleShowModal.bind(this)"}>
                        Lihat bukti rekening kepemilikan efek
                      </a>
                    </Col>
                  </Form.Group>
                )}
                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: "gray" }}>
                    Kepemilikan Efek<span style={{ color: "red" }}>*</span>
                  </Col>
                  <Col sm="8" id="col-kepemilikan-efek">
                    <Dropdown
                      name="kepemilikan_efek"
                      value={form.kepemilikan_efek}
                      // onChange={(e, { value }) => {
                      //   form.kepemilikan_efek = value
                      //   setErrorMessage(errorMessage => ({ ...errorMessage, ['kepemilikan_efek']: "" }))
                      // }}
                      placeholder="Kepemilikan Efek"
                      style={{ width: "100%" }}
                      closeOnChange
                      search
                      selection
                      options={kepemilikanEfekOptions}
                      onOpen={() =>
                        $("#col-kepemilikan-efek").css("z-index", "999")
                      }
                      onClose={() =>
                        $("#col-kepemilikan-efek").removeAttr("style")
                      }
                      disabled={true}
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {errorMessage["kepemilikan_efek"]}
                    </span>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                {/* <Header id="fonts" as='h6' color="blue" size='small'>
                <Header.Content>
                  FOTO IDENTITAS
                </Header.Content>
              </Header> */}
                <Form.Group as={Row}>
                  <Col sm="12">
                    <div className="form-title-lengkapi-data-background">
                      # FOTO IDENTITAS
                    </div>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col sm="3">
                    <div id="upload-foto-selfie" className="file-upload">
                      <div className="file-upload-tile">
                        FOTO SELFIE
                        {/* <span style={{ color: 'red' }}>*</span> */}
                      </div>

                      <Button
                        style={{ width: "100%" }}
                        type="button"
                        color="green"
                        // onClick={handleShowModalUploadFoto.bind(
                        //   this,
                        //   "foto_selfie"
                        // )}
                      >
                        Capture
                      </Button>

                      <div
                        class="image-upload-wrap"
                        // onClick={handleShowModalUploadFoto.bind(
                        //   this,
                        //   "foto_selfie"
                        // )}
                      >
                        <div className="drag-text">
                          {/* <h3>Drag and drop a file or select add Image</h3> */}
                          <h3>+</h3>
                        </div>
                      </div>
                      <div className="file-upload-content">
                        <img
                          className="file-upload-image"
                          src="#"
                          alt="your image"
                        />
                        <div className="image-title-wrap">
                          <Button
                            type="button"
                            color="red"
                            // onClick={handleRemoveFoto.bind(this, "foto_selfie")}
                            className="remove-image"
                          >
                            Remove{" "}
                            <span className="image-title">Uploaded Image</span>
                          </Button>
                        </div>
                      </div>
                      <span style={{ color: "red", fontSize: 11 }}>
                        {errorMessage["foto_selfie"]}
                      </span>
                    </div>
                  </Col>
                  <Col sm="3">
                    <div id="upload-foto-selfie-ktp" className="file-upload">
                      <div className="file-upload-tile">
                        FOTO SELFIE DENGAN KTP
                        {/* <span style={{ color: 'red' }}>*</span> */}
                      </div>

                      <Button
                        style={{ width: "100%" }}
                        type="button"
                        color="green"
                        // onClick={handleShowModalUploadFoto.bind(
                        //   this,
                        //   "foto_selfie_ktp"
                        // )}
                      >
                        Capture
                      </Button>

                      <div
                        class="image-upload-wrap"
                        // onClick={handleShowModalUploadFoto.bind(
                        //   this,
                        //   "foto_selfie_ktp"
                        // )}
                      >
                        <div className="drag-text">
                          {/* <h3>Drag and drop a file or select add Image</h3> */}
                          <h3>+</h3>
                        </div>
                      </div>
                      <div className="file-upload-content">
                        <img
                          className="file-upload-image"
                          src="#"
                          alt="your image"
                        />
                        <div className="image-title-wrap">
                          <Button
                            type="button"
                            color="red"
                            // onClick={handleRemoveFoto.bind(
                            //   this,
                            //   "foto_selfie_ktp"
                            // )}
                            className="remove-image"
                          >
                            Remove{" "}
                            <span className="image-title">Uploaded Image</span>
                          </Button>
                        </div>
                      </div>
                      <span style={{ color: "red", fontSize: 11 }}>
                        {errorMessage["foto_selfie_ktp"]}
                      </span>
                    </div>
                  </Col>
                  <Col sm="3">
                    <div id="upload-foto-ktp" className="file-upload">
                      <div className="file-upload-tile">
                        FOTO KTP{/* <span style={{ color: 'red' }}>*</span> */}
                      </div>

                      <Button
                        style={{ width: "50%" }}
                        type="button"
                        color="green"
                        // onClick={handleShowModalUploadFoto.bind(
                        //   this,
                        //   "foto_ktp"
                        // )}
                      >
                        Capture
                      </Button>

                      <Button
                        style={{ width: "46%" }}
                        type="button"
                        color="green"
                        onClick={() => {
                          $("#upload-foto-ktp .file-upload-input").trigger(
                            "click"
                          );
                        }}
                      >
                        Pilih
                      </Button>

                      <div class="image-upload-wrap">
                        <input
                          className={"file-upload-input"}
                          type="file"
                          onChange={(event) => {
                            if (event.target.files && event.target.files[0]) {
                              var filename = event.target.files[0].name;

                              var reader = new FileReader();

                              reader.onload = function (e) {
                                $("#upload-foto-ktp .image-upload-wrap").hide();
                                $("#upload-foto-ktp .file-upload-image").attr(
                                  "src",
                                  e.target.result
                                );
                                $(
                                  "#upload-foto-ktp .file-upload-content"
                                ).show();
                                $("#upload-foto-ktp .image-title").html(
                                  filename
                                );
                              };

                              form.foto_ktp = event.target.files[0];
                              reader.readAsDataURL(event.target.files[0]);
                            } else {
                              $(
                                "#upload-foto-ktp .file-upload-input"
                              ).replaceWith(
                                $("#upload-foto-ktp .file-upload-input").clone()
                              );
                              $("#upload-foto-ktp .file-upload-content").hide();
                              $("#upload-foto-ktp .image-upload-wrap").show();
                            }
                          }}
                          accept="image/*"
                        />
                        <div className="drag-text">
                          <h3>+</h3>
                        </div>
                      </div>
                      <div className="file-upload-content">
                        <img
                          className="file-upload-image"
                          src="#"
                          alt="your image"
                        />
                        <div className="image-title-wrap">
                          <Button
                            type="button"
                            color="red"
                            // onClick={handleRemoveFoto.bind(this, "foto_ktp")}
                            className="remove-image"
                          >
                            Remove{" "}
                            <span className="image-title">Uploaded Image</span>
                          </Button>
                        </div>
                      </div>
                      <span style={{ color: "red", fontSize: 11 }}>
                        {errorMessage["foto_ktp"]}
                      </span>
                    </div>
                  </Col>
                  <Col sm="3">
                    <div id="upload-foto-npwp" className="file-upload">
                      <div className="file-upload-tile">
                        FOTO NPWP{/* <span style={{ color: 'red' }}>*</span> */}
                      </div>

                      <Button
                        style={{ width: "50%" }}
                        type="button"
                        color="green"
                        // onClick={handleShowModalUploadFoto.bind(
                        //   this,
                        //   "foto_npwp"
                        // )}
                      >
                        Capture
                      </Button>

                      <Button
                        style={{ width: "46%" }}
                        type="button"
                        color="green"
                        onClick={() => {
                          $("#upload-foto-npwp .file-upload-input").trigger(
                            "click"
                          );
                        }}
                      >
                        Pilih
                      </Button>

                      <div class="image-upload-wrap">
                        <input
                          className={"file-upload-input"}
                          type="file"
                          onChange={(event) => {
                            if (event.target.files && event.target.files[0]) {
                              var filename = event.target.files[0].name;

                              var reader = new FileReader();

                              reader.onload = function (e) {
                                $(
                                  "#upload-foto-npwp .image-upload-wrap"
                                ).hide();
                                $("#upload-foto-npwp .file-upload-image").attr(
                                  "src",
                                  e.target.result
                                );
                                $(
                                  "#upload-foto-npwp .file-upload-content"
                                ).show();
                                $("#upload-foto-npwp .image-title").html(
                                  filename
                                );
                              };

                              form.foto_npwp = event.target.files[0];
                              reader.readAsDataURL(event.target.files[0]);
                            } else {
                              $(
                                "#upload-foto-npwp .file-upload-input"
                              ).replaceWith(
                                $(
                                  "#upload-foto-npwp .file-upload-input"
                                ).clone()
                              );
                              $(
                                "#upload-foto-npwp .file-upload-content"
                              ).hide();
                              $("#upload-foto-npwp .image-upload-wrap").show();
                            }
                          }}
                          accept="image/*"
                        />
                        <div className="drag-text">
                          <h3>+</h3>
                        </div>
                      </div>
                      <div className="file-upload-content">
                        <img
                          className="file-upload-image"
                          src="#"
                          alt="your image"
                        />
                        <div className="image-title-wrap">
                          <Button
                            type="button"
                            color="red"
                            // onClick={handleRemoveFoto.bind(this, "foto_npwp")}
                            className="remove-image"
                          >
                            Remove{" "}
                            <span className="image-title">Uploaded Image</span>
                          </Button>
                        </div>
                      </div>
                      <span style={{ color: "red", fontSize: 11 }}>
                        {errorMessage["foto_npwp"]}
                      </span>
                    </div>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div style={{ float: "right" }}>
                  <Button
                    color="default"
                    type="button"
                    onClick={closeEditForm.bind(this)}
                    disabled={loading}
                  >
                    Kembali
                  </Button>
                  <Button
                    className="btnSimpan"
                    color="green"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {loading && (
                      <Spinner
                        animation="border"
                        variant="default"
                        size="sm"
                        style={{ marginRight: "5px" }}
                      />
                    )}
                    {loading && <span>Loading..</span>}
                    {!loading && <span>Simpan</span>}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      )}
    </>
  );
});

const DataPerusahaan = memo((props) => {
  const [showEdit, setShowEdit] = useState(false);

  const openEditForm = () => {
    setShowEdit(true);
  };

  const closeEditForm = () => {
    setShowEdit(false);
  };

  const initData = {
    nama_perusahaan:
      props.userinfo.nama_badan == null ? "" : props.userinfo.nama_badan,
    alamat_perusahaan:
      props.userinfo.alamat_badan == null ? "" : props.userinfo.alamat_badan,
    telepon_perusahaan_kode:
      props.userinfo.no_telepon_badan_kode == null
        ? ""
        : props.userinfo.no_telepon_badan_kode,
    telepon_perusahaan:
      props.userinfo.no_telepon_badan == null
        ? ""
        : props.userinfo.no_telepon_badan,
    fax_perusahaan_kode:
      props.userinfo.no_fax_badan_kode == null
        ? ""
        : props.userinfo.no_fax_badan_kode,
    fax_perusahaan:
      props.userinfo.no_fax_badan == null ? "" : props.userinfo.no_fax_badan,
    npwp_perusahaan:
      props.userinfo.npwp_badan == null ? "" : props.userinfo.npwp_badan,
    npwp_perusahaan_register:
      props.userinfo.npwp_badan_register == null
        ? ""
        : props.userinfo.npwp_badan_register,

    tipe_pemodal:
      props.userinfo.tipe_pemodal == "1"
        ? "pribadi"
        : props.userinfo.tipe_pemodal == "2"
        ? "perusahaan"
        : "",
    tipe_client:
      props.userinfo.tipe_client == "1"
        ? "direct"
        : props.userinfo.tipe_client == "2"
        ? "indirect"
        : "",
  };

  const [form, setForm] = useState(initData);

  const [document1, setDocument1] = useState(
    props.userinfo.document1 == null ? "" : props.userinfo.document1
  );
  const [document2, setDocument2] = useState(
    props.userinfo.document2 == null ? "" : props.userinfo.document2
  );
  const [document3, setDocument3] = useState(
    props.userinfo.document3 == null ? "" : props.userinfo.document3
  );
  const [document4, setDocument4] = useState(
    props.userinfo.document4 == null ? "" : props.userinfo.document4
  );
  const [document5, setDocument5] = useState(
    props.userinfo.document5 == null ? "" : props.userinfo.document5
  );

  const dateMax = new Date();
  dateMax.setDate(dateMax.getDate() - 1);
  const maximumDate = {
    year: dateFormat(dateMax, "yyyy"),
    month: dateFormat(dateMax, "mm"),
    day: dateFormat(dateMax, "dd"),
  };

  const [selectedDayNPWPReg, setSelectedDayNPWPReg] = useState(
    form.npwp_perusahaan_register == ""
      ? null
      : {
          year: parseInt(dateFormat(form.npwp_perusahaan_register, "yyyy")),
          month: parseInt(dateFormat(form.npwp_perusahaan_register, "mm")),
          day: parseInt(dateFormat(form.npwp_perusahaan_register, "dd")),
        }
  );
  const renderCustomInputNPWPReg = ({ ref }) => (
    <Form.Control
      readOnly
      ref={ref}
      placeholder="Pilih tanggal"
      value={
        selectedDayNPWPReg
          ? `${selectedDayNPWPReg.day}/${selectedDayNPWPReg.month}/${selectedDayNPWPReg.year}`
          : ""
      }
    />
  );
  const handleChangeNPWPReg = (event) => {
    setSelectedDayNPWPReg(event);
    if (event !== "") {
      // var selectDate = event ? `${event.day}/${event.month}/${event.year}` : ''
      var selectDate = event ? `${event.year}-${event.month}-${event.day}` : "";
      setForm({ ...form, ["npwp_perusahaan_register"]: selectDate });
      setErrorMessage((errorMessage) => ({
        ...errorMessage,
        ["npwp_perusahaan_register"]: "",
      }));
    }
  };

  const [errorMessage, setErrorMessage] = useState({});

  const handleTextChange = (event) => {
    var index = event.target.name;
    setErrorMessage((errorMessage) => ({ ...errorMessage, [index]: "" }));

    if (index === "nama_perusahaan" && event.target.value.length > 100) return;
    if (index === "alamat_perusahaan" && event.target.value.length > 180)
      return;

    if (
      index === "telepon_perusahaan_kode" ||
      index === "fax_perusahaan_kode"
    ) {
      const re = /^[+0-9\b]+$/;
      if (event.target.value != "" && !re.test(event.target.value)) {
        return;
      }
      if (
        event.target.value.length > 1 &&
        event.target.value.slice(-1) == "+"
      ) {
        return;
      }
      if (event.target.value.length > 5) {
        return;
      }
    }

    if (index === "telepon_perusahaan" || index === "fax_perusahaan") {
      const re = /^[0-9\b]+$/;
      if (event.target.value != "" && !re.test(event.target.value)) {
        return;
      }
      if (event.target.value.length > 10) {
        return;
      }
    }

    if (index === "npwp_perusahaan") {
      // 79.275.653.8.422.000
      const re = /^[0-9.\b]+$/;
      if (event.target.value != "" && !re.test(event.target.value)) {
        return;
      }
      if (event.target.value.length > 20) {
        return;
      }
    }

    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const [loading, setLoading] = useState(false);
  const [loadingModalUpload, setLoadingModalUpload] = useState(false);

  const beforeUnloadListener = (event) => {
    event.preventDefault();
    event.returnValue = "";
    return "";
  };

  const postData = async (values, post_to) => {
    window.addEventListener("beforeunload", beforeUnloadListener);

    values.post_to = post_to;

    // console.log(JSON.stringify(values))
    // return

    let config = {
      headers: {
        Authorization: localStorage.token,
        "content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Methods": "POST",
      },
      maxContentLength: 52428890,
      timeout: 10000000,
    };

    if (post_to == "perusahaan") {
      setLoading(true);
    }
    if (post_to == "document_upload") setLoadingModalUpload(true);

    axios
      .post(BASE_URL + "/customer/pemodal/lengkapidata_v2", values, config)
      .then((resp) => {
        window.removeEventListener("beforeunload", beforeUnloadListener);

        swal({ text: resp.data.body.message, icon: "success" });

        props.getUserpemodal();

        // setShowEdit(false)
        // window.scrollTo(0, 0)

        var dataRes = resp.data.body.data;

        props.getUserpemodal();

        if (post_to == "perusahaan") {
          setLoading(false);
          setShowEdit(false);
          window.scrollTo(0, 0);
        }
        if (post_to == "document_upload") {
          setLoadingModalUpload(false);
          setShowModalUpload(false);

          if (dataRes.type == "document1") {
            setDocument1(dataRes.file);
          }
          if (dataRes.type == "document2") {
            setDocument2(dataRes.file);
          }
          if (dataRes.type == "document3") {
            setDocument3(dataRes.file);
          }
          if (dataRes.type == "document4") {
            setDocument4(dataRes.file);
          }
          if (dataRes.type == "document5") {
            setDocument5(dataRes.file);
          }
        }
      })
      .catch((error) => {
        if (post_to == "perusahaan") {
          setLoading(false);
        }
        if (post_to == "document_upload") setLoadingModalUpload(false);

        window.removeEventListener("beforeunload", beforeUnloadListener);

        try {
          let errorStatusCode = error.response.status;

          if (errorStatusCode === 401) {
            setTimeout(() => {
              window.location.href = "/Signin";
              localStorage.removeItem("token");
              localStorage.clear();

              this.props.dispatch({
                type: "AUTH_USER_LOGOUT",
              });
            }, 500);
          } else {
            let errorBody = error.response.data.body;
            swal({ text: errorBody.message, icon: "error" });
          }
        } catch (error) {
          swal({ text: "Error tidak terdefinisi!", icon: "error" });
        }
      });
  };

  async function handleSubmit(event) {
    event.preventDefault();

    let errors = {};
    let isValid = true;

    if (form.nama_perusahaan == "") {
      errors["nama_perusahaan"] = "Masukkan nama perusahaan!";
      isValid = false;
    }
    if (form.alamat_perusahaan == "") {
      errors["alamat_perusahaan"] = "Masukkan alamat perusahaan!";
      isValid = false;
    }
    if (form.telepon_perusahaan_kode == "" || form.telepon_perusahaan == "") {
      errors["telepon_perusahaan"] = "Masukkan nomor telepon perusahaan!";
      isValid = false;
    }
    // if (form.fax_perusahaan == '') {
    //   errors['fax_perusahaan'] = "Masukkan fax telepon perusahaan!"
    //   isValid = false
    // }
    if (form.npwp_perusahaan == "") {
      errors["npwp_perusahaan"] = "Masukkan nomor npwp perusahaan!";
      isValid = false;
    }
    if (form.npwp_perusahaan_register == "") {
      errors["npwp_perusahaan_register"] =
        "Masukkan tanggal registrasi NPWP perusahaan!";
      isValid = false;
    }

    setErrorMessage((errorMessage) => ({
      ...errorMessage,
      ...errors,
    }));

    if (isValid) {
      postData(form, "perusahaan");
    } else {
      swal({ text: "Periksa kembali data yang anda masukkan!", icon: "error" });
    }
  }

  const [file, setFile] = useState({
    file_upload: "",
    file_to: "",
  });

  const [documentUpload, setDocumentUpload] = useState(null);
  const [showModalUpload, setShowModalUpload] = useState(false);
  const handleShowModalUpload = (param) => {
    setFile({
      file_upload: "",
      file_to: param,
    });
    setDocumentUpload(param);
    setShowModalUpload(true);
  };
  const handleCloseModalUpload = () => {
    setErrorMessage((errorMessage) => ({
      ...errorMessage,
      ["file_upload"]: "",
    }));
    setShowModalUpload(false);
  };

  const [documentShow, setDocumentShow] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = (param) => {
    setDocumentShow(param);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleFileChange = (event) => {
    setFile({ ...file, [event.target.name]: event.target.value });

    var index = event.target.name;
    setErrorMessage((errorMessage) => ({ ...errorMessage, [index]: "" }));
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async function handleUpload(event) {
    event.preventDefault();

    let errors = {};
    let isValid = true;

    if (file.file_to == "") {
      errors["file_upload"] = "File upload belum ditentukan!";
      isValid = false;
    }

    if (file.file_upload == "") {
      errors["file_upload"] = "Silahkan pilih file!";
      isValid = false;
    } else {
      var formField = document.querySelector('form[name="formDokumenModal"]');
      var fileUpload = formField.querySelector('input[name="file_upload"]')
        .files[0];
      if (file.file_upload.size > 2048000) {
        errors["file_upload"] = "Maksimal file upload 2 MB!";
        isValid = false;
      }
    }

    setErrorMessage((errorMessage) => ({
      ...errorMessage,
      ...errors,
    }));

    if (isValid) {
      file.file_upload = await toBase64(fileUpload);
      postData(file, "document_upload");
    }
  }

  return (
    <>
      {showEdit == false ? (
        <Container style={{ display: "block" }}>
          <Row>
            <Col md={12}>
              <Header id="fonts" as="h6" color="blue" size="small">
                <Header.Content>
                  Data Perusahaan{" "}
                  <a
                    href="#"
                    onClick={openEditForm.bind(this)}
                    style={{ marginLeft: "10px" }}
                  >
                    <Icon name="pencil alternate" />
                  </a>
                </Header.Content>
              </Header>

              <Form.Group as={Row}>
                <Col sm="4" style={{ color: "gray" }}>
                  Nama Perusahaan
                </Col>
                <Col sm="8">{props.userinfo.nama_badan}</Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: "gray" }}>
                  Alamat
                </Col>
                <Col sm="8">{props.userinfo.alamat_badan}</Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: "gray" }}>
                  Nomor Telepon
                </Col>
                <Col sm="8">
                  {props.userinfo.no_telepon_badan_kode +
                    " " +
                    props.userinfo.no_telepon_badan}
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: "gray" }}>
                  Nomor Fax
                </Col>
                <Col sm="8">
                  {props.userinfo.no_fax_badan_kode +
                    " " +
                    props.userinfo.no_fax_badan}
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: "gray" }}>
                  NPWP
                </Col>
                <Col sm="8">{props.userinfo.npwp_badan}</Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: "gray" }}>
                  Tanggal Registrasi NPWP
                </Col>
                <Col sm="8">{props.userinfo.npwp_badan_register}</Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="vertical-line" />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Header id="fonts" as="h6" color="blue" size="small">
                <Header.Content>Dokumen</Header.Content>
              </Header>

              <Form.Group as={Row}>
                <Col sm="6" style={{ color: "gray" }}>
                  Akta Pendirian Perusahaan & Anggaran Dasar{" "}
                  <a
                    href="#"
                    onClick={handleShowModalUpload.bind(this, "document1")}
                    style={{ marginLeft: "10px" }}
                  >
                    <Icon name="pencil alternate" />
                  </a>
                </Col>
                <Col sm="6">
                  <a href="#" onClick={handleShowModal.bind(this, 1)}>
                    Tampilkan
                  </a>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="6" style={{ color: "gray" }}>
                  Anggaran Dasar Perubahan Terbaru{" "}
                  <a
                    href="#"
                    onClick={handleShowModalUpload.bind(this, "document2")}
                    style={{ marginLeft: "10px" }}
                  >
                    <Icon name="pencil alternate" />
                  </a>
                </Col>
                <Col sm="6">
                  <a href="#" onClick={handleShowModal.bind(this, 2)}>
                    Tampilkan
                  </a>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="6" style={{ color: "gray" }}>
                  Company Profile, TDP/NIB, SIUP, NPWP{" "}
                  <a
                    href="#"
                    onClick={handleShowModalUpload.bind(this, "document3")}
                    style={{ marginLeft: "10px" }}
                  >
                    <Icon name="pencil alternate" />
                  </a>
                </Col>
                <Col sm="6">
                  <a href="#" onClick={handleShowModal.bind(this, 3)}>
                    Tampilkan
                  </a>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="6" style={{ color: "gray" }}>
                  Laporan Keuangan (3 Tahun Terakhir){" "}
                  <a
                    href="#"
                    onClick={handleShowModalUpload.bind(this, "document4")}
                    style={{ marginLeft: "10px" }}
                  >
                    <Icon name="pencil alternate" />
                  </a>
                </Col>
                <Col sm="6">
                  <a href="#" onClick={handleShowModal.bind(this, 4)}>
                    Tampilkan
                  </a>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="6" style={{ color: "gray" }}>
                  Data mengenai bisnis dan proyeksi pendapatan{" "}
                  <a
                    href="#"
                    onClick={handleShowModalUpload.bind(this, "document5")}
                    style={{ marginLeft: "10px" }}
                  >
                    <Icon name="pencil alternate" />
                  </a>
                </Col>
                <Col sm="6">
                  <a href="#" onClick={handleShowModal.bind(this, 5)}>
                    Tampilkan
                  </a>
                </Col>
              </Form.Group>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container style={{ display: "block" }}>
          <Row>
            <Col md={12}>
              <Header id="fonts" as="h6" color="blue" size="small">
                <Header.Content>Edit Data Perusahaan</Header.Content>
              </Header>

              <Form.Group as={Row}>
                <Col sm="4" style={{ color: "gray" }}>
                  Nama Perusahaan<span style={{ color: "red" }}>*</span>
                </Col>
                <Col sm="8">
                  <Form.Control
                    type="text"
                    name="nama_perusahaan"
                    value={form.nama_perusahaan}
                    onChange={handleTextChange}
                  />
                  <span style={{ color: "red", fontSize: 11 }}>
                    {errorMessage["nama_perusahaan"]}
                  </span>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: "gray" }}>
                  Alamat<span style={{ color: "red" }}>*</span>
                </Col>
                <Col sm="8">
                  <Form.Control
                    type="text"
                    name="alamat_perusahaan"
                    value={form.alamat_perusahaan}
                    onChange={handleTextChange}
                  />
                  <span style={{ color: "red", fontSize: 11 }}>
                    {errorMessage["alamat_perusahaan"]}
                  </span>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: "gray" }}>
                  Telepon<span style={{ color: "red" }}>*</span>
                  <br />
                  <span style={{ fontSize: "11px" }}>
                    (Contoh: +62 211234123)
                  </span>
                </Col>
                <Col sm="8">
                  <Form.Group as={Row}>
                    <Col sm="2">
                      <Form.Control
                        type="text"
                        name="telepon_perusahaan_kode"
                        value={form.telepon_perusahaan_kode}
                        onChange={handleTextChange}
                      />
                    </Col>
                    <Col sm="10">
                      <Form.Control
                        type="text"
                        name="telepon_perusahaan"
                        value={form.telepon_perusahaan}
                        onChange={handleTextChange}
                      />
                    </Col>
                  </Form.Group>
                  <span style={{ color: "red", fontSize: 11 }}>
                    {errorMessage["telepon_perusahaan"]}
                  </span>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: "gray" }}>
                  Fax.
                  <br />
                  <span style={{ fontSize: "11px" }}>
                    (Contoh: +62 211234123)
                  </span>
                </Col>
                <Col sm="8">
                  <Form.Group as={Row}>
                    <Col sm="2">
                      <Form.Control
                        type="text"
                        name="fax_perusahaan_kode"
                        value={form.fax_perusahaan_kode}
                        onChange={handleTextChange}
                      />
                    </Col>
                    <Col sm="10">
                      <Form.Control
                        type="text"
                        name="fax_perusahaan"
                        value={form.fax_perusahaan}
                        onChange={handleTextChange}
                      />
                    </Col>
                  </Form.Group>
                  <span style={{ color: "red", fontSize: 11 }}>
                    {errorMessage["fax_perusahaan"]}
                  </span>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: "gray" }}>
                  NPWP Perusahaan<span style={{ color: "red" }}>*</span>
                </Col>
                <Col sm="8">
                  <Form.Control
                    type="text"
                    name="npwp_perusahaan"
                    value={form.npwp_perusahaan}
                    onChange={handleTextChange}
                  />
                  <span style={{ color: "red", fontSize: 11 }}>
                    {errorMessage["npwp_perusahaan"]}
                  </span>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: "gray" }}>
                  Tanggal Registrasi NPWP<span style={{ color: "red" }}>*</span>
                </Col>
                <Col sm="8">
                  <DatePicker
                    name="npwp_perusahaan_register"
                    value={selectedDayNPWPReg}
                    onChange={handleChangeNPWPReg}
                    renderInput={renderCustomInputNPWPReg}
                    shouldHighlightWeekends
                    maximumDate={maximumDate}
                    calendarPopperPosition={"bottom"}
                  />
                  <br />
                  <span style={{ color: "red", fontSize: 11 }}>
                    {errorMessage["npwp_perusahaan_register"]}
                  </span>
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div style={{ float: "right" }}>
                <Button
                  color="default"
                  type="button"
                  onClick={closeEditForm.bind(this)}
                  disabled={loading}
                >
                  Kembali
                </Button>
                <Button
                  className="btnSimpan"
                  color="green"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading && (
                    <Spinner
                      animation="border"
                      variant="default"
                      size="sm"
                      style={{ marginRight: "5px" }}
                    />
                  )}
                  {loading && <span>Loading..</span>}
                  {!loading && <span>Simpan</span>}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      )}

      <Modal
        size="lg"
        style={{ borderRadius: "30px" }}
        show={showModal}
        onHide={handleCloseModal}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {documentShow == 1 && (
              <span>Akta Pendirian Perusahaan & Anggaran Dasar</span>
            )}
            {documentShow == 2 && <span>Anggaran Dasar Perubahan Terbaru</span>}
            {documentShow == 3 && (
              <span>Company Profile, TDP/NIB, SIUP, NPWP</span>
            )}
            {documentShow == 4 && (
              <span>Laporan Keuangan (3 Tahun Terakhir)</span>
            )}
            {documentShow == 5 && (
              <span>Data mengenai bisnis dan proyeksi pendapatan</span>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {documentShow == 1 && (
            <iframe src={document1} height="1000" width="775" />
          )}
          {documentShow == 2 && (
            <iframe src={document2} height="1000" width="775" />
          )}
          {documentShow == 3 && (
            <iframe src={document3} height="1000" width="775" />
          )}
          {documentShow == 4 && (
            <iframe src={document4} height="1000" width="775" />
          )}
          {documentShow == 5 && (
            <iframe src={document5} height="1000" width="775" />
          )}
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        style={{ borderRadius: "30px" }}
        show={showModalUpload}
        onHide={false}
        animation={false}
      >
        <Form
          name="formDokumenModal"
          onSubmit={(e) => e.preventDefault()}
          encType="multipart/form-data"
          autoComplete="off"
        >
          <Modal.Body>
            {documentUpload == "document1" && (
              <strong>Upload Akta Pendirian Perusahaan & Anggaran Dasar</strong>
            )}
            {documentUpload == "document2" && (
              <strong>Upload Anggaran Dasar Perubahan Terbaru</strong>
            )}
            {documentUpload == "document3" && (
              <strong>Upload Company Profile, TDP/NIB, SIUP, NPWP</strong>
            )}
            {documentUpload == "document4" && (
              <strong>Upload Laporan Keuangan (3 Tahun Terakhir)</strong>
            )}
            {documentUpload == "document5" && (
              <strong>
                Upload Data mengenai bisnis dan proyeksi pendapatan
              </strong>
            )}
            <br />
            <span style={{ fontSize: "11px" }}>
              (Format: pdf. Maksimal 2 MB.)
            </span>
            <p />
            <Form.Control
              type="file"
              name="file_upload"
              onChange={handleFileChange}
              accept={"application/pdf"}
            />
            <br />
            <span style={{ color: "red", fontSize: 11 }}>
              {errorMessage["file_upload"]}
            </span>
          </Modal.Body>
          <Modal.Footer>
            <Button color="blue" onClick={handleCloseModalUpload}>
              Close
            </Button>
            <Button
              color="green"
              onClick={handleUpload}
              disabled={loadingModalUpload}
            >
              {loadingModalUpload && (
                <Spinner
                  animation="border"
                  variant="default"
                  size="sm"
                  style={{ marginRight: "5px" }}
                />
              )}
              {loadingModalUpload && <span>Loading..</span>}
              {!loadingModalUpload && <span>Upload</span>}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
});

const DataBank = memo((props) => {
  const [showEdit, setShowEdit] = useState(false);

  const openEditForm = () => {
    setShowEdit(true);
  };

  const closeEditForm = () => {
    setShowEdit(false);
  };

  const initData = {
    bank: props.userinfo.data_bank == null ? "" : props.userinfo.data_bank,
    no_rekening: props.userinfo.no_rek == null ? "" : props.userinfo.no_rek,
    nama_pemilik_rekening:
      props.userinfo.atas_nama_bank == null
        ? ""
        : props.userinfo.atas_nama_bank,
    cabang_bank:
      props.userinfo.cabang_bank == null ? "" : props.userinfo.cabang_bank,

    tipe_pemodal:
      props.userinfo.tipe_pemodal == "1"
        ? "pribadi"
        : props.userinfo.tipe_pemodal == "2"
        ? "perusahaan"
        : "",
    tipe_client:
      props.userinfo.tipe_client == "1"
        ? "direct"
        : props.userinfo.tipe_client == "2"
        ? "indirect"
        : "",
  };

  const [form, setForm] = useState(initData);

  const [bukuRekening, setBukuRekening] = useState(
    props.userinfo.buku_tabungan == null ? "" : props.userinfo.buku_tabungan
  );

  const [errorMessage, setErrorMessage] = useState({});

  const handleTextChange = (event) => {
    var index = event.target.name;
    setErrorMessage((errorMessage) => ({ ...errorMessage, [index]: "" }));

    if (index === "no_rekening") {
      const re = /^[0-9\b]+$/;
      if (event.target.value != "" && !re.test(event.target.value)) {
        return;
      }
      if (event.target.value.length > 30) {
        return;
      }
    }

    if (index === "nama_pemilik_rekening" && event.target.value.length > 50)
      return;
    if (index === "cabang_bank" && event.target.value.length > 100) return;

    let resultValue = event.target.value;
    if (index === "cabang_bank") {
      resultValue = event.target.value.toUpperCase();
    }

    setForm({ ...form, [event.target.name]: resultValue });
  };

  const [loading, setLoading] = useState(false);
  const [loadingModalUpload, setLoadingModalUpload] = useState(false);

  const beforeUnloadListener = (event) => {
    event.preventDefault();
    event.returnValue = "";
    return "";
  };

  const handleFileChange = (event) => {
    setFile({ ...file, [event.target.name]: event.target.value });

    var index = event.target.name;
    setErrorMessage((errorMessage) => ({ ...errorMessage, [index]: "" }));
  };

  const postData = async (values, post_to) => {
    window.addEventListener("beforeunload", beforeUnloadListener);

    values.post_to = post_to;

    // console.log(JSON.stringify(values))
    // return

    let config = {
      headers: {
        Authorization: localStorage.token,
        "content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Methods": "POST",
      },
      maxContentLength: 52428890,
      timeout: 10000000,
    };

    if (post_to == "bank") {
      setLoading(true);
    }
    if (post_to == "document_upload") setLoadingModalUpload(true);

    axios
      .post(BASE_URL + "/customer/pemodal/lengkapidata_v2", values, config)
      .then((resp) => {
        window.removeEventListener("beforeunload", beforeUnloadListener);

        swal({ text: resp.data.body.message, icon: "success" });

        var dataRes = resp.data.body.data;

        props.getUserpemodal();

        // setShowEdit(false)
        // window.scrollTo(0, 0)

        if (post_to == "bank") {
          setLoading(false);
          setShowEdit(false);
          window.scrollTo(0, 0);
        }
        if (post_to == "document_upload") {
          setLoadingModalUpload(false);
          setShowModalUpload(false);

          setBukuRekening(dataRes.file);
        }
      })
      .catch((error) => {
        if (post_to == "bank") {
          setLoading(false);
        }
        if (post_to == "document_upload") setLoadingModalUpload(false);

        window.removeEventListener("beforeunload", beforeUnloadListener);

        try {
          let errorStatusCode = error.response.status;

          if (errorStatusCode === 401) {
            setTimeout(() => {
              window.location.href = "/Signin";
              localStorage.removeItem("token");
              localStorage.clear();

              this.props.dispatch({
                type: "AUTH_USER_LOGOUT",
              });
            }, 500);
          } else {
            let errorBody = error.response.data.body;
            swal({ text: errorBody.message, icon: "error" });
          }
        } catch (error) {
          swal({ text: "Error tidak terdefinisi!", icon: "error" });
        }
      });
  };

  async function handleSubmit(event) {
    event.preventDefault();

    // props.handlePageNext()
    // return

    let errors = {};
    let isValid = true;

    if (form.bank == "") {
      errors["bank"] = "Pilih Bank!";
      isValid = false;
    }
    if (form.no_rekening == "") {
      errors["no_rekening"] = "Masukkan nomor rekening!";
      isValid = false;
    }
    if (form.nama_pemilik_rekening == "") {
      errors["nama_pemilik_rekening"] = "Masukkan nama pemilik rekening!";
      isValid = false;
    }
    if (form.cabang_bank == "") {
      errors["cabang_bank"] = "Masukkan cabang Bank!";
      isValid = false;
    }

    setErrorMessage((errorMessage) => ({
      ...errorMessage,
      ...errors,
    }));

    if (isValid) {
      postData(form, "bank");
    } else {
      swal({ text: "Periksa kembali data yang anda masukkan!", icon: "error" });
    }
  }

  const [file, setFile] = useState({
    file_upload: "",
    file_to: "",
  });

  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [showModalUpload, setShowModalUpload] = useState(false);
  const handleShowModalUpload = () => {
    setFile({
      file_upload: "",
      file_to: "buku_rekening",
    });
    setShowModalUpload(true);
  };
  const handleCloseModalUpload = () => {
    setErrorMessage((errorMessage) => ({
      ...errorMessage,
      ["file_upload"]: "",
    }));
    setShowModalUpload(false);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async function handleUpload(event) {
    event.preventDefault();

    let errors = {};
    let isValid = true;

    if (file.file_to == "") {
      errors["file_upload"] = "File upload belum ditentukan!";
      isValid = false;
    }

    if (file.file_upload == "") {
      errors["file_upload"] = "Silahkan pilih file!";
      isValid = false;
    } else {
      var formField = document.querySelector('form[name="formDokumenModal"]');
      var fileUpload = formField.querySelector('input[name="file_upload"]')
        .files[0];
      if (fileUpload.size > 2048000) {
        errors["file_upload"] = "Maksimal file upload 2 MB!";
        isValid = false;
      }
    }

    setErrorMessage((errorMessage) => ({
      ...errorMessage,
      ...errors,
    }));

    if (isValid) {
      file.file_upload = await toBase64(fileUpload);
      postData(file, "document_upload");
    } else {
      swal({ text: "Periksa kembali data yang anda masukkan!", icon: "error" });
    }
  }

  return (
    <>
      {showEdit == false ? (
        <Container style={{ display: "block" }}>
          <Row>
            <Col md={12}>
              <Header id="fonts" as="h6" color="blue" size="small">
                <Header.Content>
                  Data Bank{" "}
                  <a
                    href="#"
                    onClick={openEditForm.bind(this)}
                    style={{ marginLeft: "10px" }}
                  >
                    <Icon name="pencil alternate" />
                  </a>
                </Header.Content>
              </Header>

              <Form.Group as={Row}>
                <Col sm="4" style={{ color: "gray" }}>
                  Nama Bank
                </Col>
                <Col sm="8">{props.userinfo.data_bank}</Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: "gray" }}>
                  No. Rekening
                </Col>
                <Col sm="8">{props.userinfo.no_rek}</Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: "gray" }}>
                  Nama Pemilik
                </Col>
                <Col sm="8">{props.userinfo.atas_nama_bank}</Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: "gray" }}>
                  Cabang
                </Col>
                <Col sm="8">{props.userinfo.cabang_bank}</Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="vertical-line" />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Header id="fonts" as="h6" color="blue" size="small">
                <Header.Content>Dokumen</Header.Content>
              </Header>

              <Form.Group as={Row}>
                <Col sm="4" style={{ color: "gray" }}>
                  Copy Buku Rekening{" "}
                  <a
                    href="#"
                    onClick={handleShowModalUpload.bind(this)}
                    style={{ marginLeft: "10px" }}
                  >
                    <Icon name="pencil alternate" />
                  </a>
                </Col>
                <Col sm="8">
                  <a href="#" onClick={handleShowModal.bind(this)}>
                    Tampilkan
                  </a>
                </Col>
              </Form.Group>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container style={{ display: "block" }}>
          <Row>
            <Col md={12}>
              <Header id="fonts" as="h6" color="blue" size="small">
                <Header.Content>Edit Data Bank</Header.Content>
              </Header>

              <Form.Group as={Row}>
                <Col sm="4" style={{ color: "gray" }}>
                  Nama Bank<span style={{ color: "red" }}>*</span>
                </Col>
                <Col sm="8">
                  <Dropdown
                    name="bank"
                    value={form.bank}
                    onChange={(e, { value }) => {
                      form.bank = value;
                      setErrorMessage((errorMessage) => ({
                        ...errorMessage,
                        ["bank"]: "",
                      }));
                    }}
                    placeholder="Pilih Bank"
                    style={{ width: "100%" }}
                    closeOnChange
                    search
                    selection
                    options={props.dataBank.map((item) => ({
                      key: item.id,
                      text: item.nama_bank,
                      value: item.nama_bank,
                    }))}
                  />
                  <span style={{ color: "red", fontSize: 11 }}>
                    {errorMessage["bank"]}
                  </span>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: "gray" }}>
                  No. Rekening<span style={{ color: "red" }}>*</span>
                </Col>
                <Col sm="8">
                  <Form.Control
                    type="text"
                    name="no_rekening"
                    value={form.no_rekening}
                    onChange={handleTextChange}
                  />
                  <span style={{ color: "red", fontSize: 11 }}>
                    {errorMessage["no_rekening"]}
                  </span>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: "gray" }}>
                  Nama Pemilik<span style={{ color: "red" }}>*</span>
                </Col>
                <Col sm="8">
                  <Form.Control
                    type="text"
                    name="nama_pemilik_rekening"
                    value={form.nama_pemilik_rekening}
                    onChange={handleTextChange}
                  />
                  <span style={{ color: "red", fontSize: 11 }}>
                    {errorMessage["nama_pemilik_rekening"]}
                  </span>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: "gray" }}>
                  Cabang<span style={{ color: "red" }}>*</span>
                </Col>
                <Col sm="8">
                  <Form.Control
                    type="text"
                    name="cabang_bank"
                    value={form.cabang_bank}
                    onChange={handleTextChange}
                  />
                  <span style={{ color: "red", fontSize: 11 }}>
                    {errorMessage["cabang_bank"]}
                  </span>
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div style={{ float: "right" }}>
                <Button
                  color="default"
                  type="button"
                  onClick={closeEditForm.bind(this)}
                  disabled={loading}
                >
                  Kembali
                </Button>
                <Button
                  className="btnSimpan"
                  color="green"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading && (
                    <Spinner
                      animation="border"
                      variant="default"
                      size="sm"
                      style={{ marginRight: "5px" }}
                    />
                  )}
                  {loading && <span>Loading..</span>}
                  {!loading && <span>Simpan</span>}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      )}

      <Modal
        size="lg"
        style={{ borderRadius: "30px" }}
        show={showModal}
        onHide={handleCloseModal}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Copy Buku Rekening</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe src={bukuRekening} height="1000" width="775" />
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        style={{ borderRadius: "30px" }}
        show={showModalUpload}
        onHide={false}
        animation={false}
      >
        <Form
          name="formDokumenModal"
          onSubmit={(e) => e.preventDefault()}
          encType="multipart/form-data"
          autoComplete="off"
        >
          <Modal.Body>
            <strong>Upload Copy Buku Rekening</strong>
            <br />
            <span style={{ fontSize: "11px" }}>
              (Format: pdf. Maksimal 2 MB.)
            </span>
            <p />
            <Form.Control
              type="file"
              name="file_upload"
              onChange={handleFileChange}
              accept={"application/pdf"}
            />
            <br />
            <span style={{ color: "red", fontSize: 11 }}>
              {errorMessage["file_upload"]}
            </span>
          </Modal.Body>
          <Modal.Footer>
            <Button color="blue" onClick={handleCloseModalUpload}>
              Close
            </Button>
            <Button
              color="green"
              onClick={handleUpload}
              disabled={loadingModalUpload}
            >
              {loadingModalUpload && (
                <Spinner
                  animation="border"
                  variant="default"
                  size="sm"
                  style={{ marginRight: "5px" }}
                />
              )}
              {loadingModalUpload && <span>Loading..</span>}
              {!loadingModalUpload && <span>Upload</span>}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
});

function mapStateToProps(state) {
  return {
    getUserpemodal: state.userReducer.getUserpemodal,
    auth: state.authReducer.authData,
  };
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarProfilePemodal);
