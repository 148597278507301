import React from 'react';
import { api, fetchApi } from "../service/api";
import Home from "../pages/landing/Home";
import { BallClipRotateMultiple } from 'react-pure-loaders';


class Api extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: []
    };
  }

  componentDidMount() {
    // fetch("https://c0e172a6b76c.ngrok.io/newkerjasamacom/")
    fetch(process.env.REACT_APP_BASE_URL + "/frontend?tag=1")
      .then(res => res.json())
      .then(
        (result) => {
          if (result.response.statistic) {
            this.setState({
              isLoaded: true,
              items: result.response
            });
          } else {
            var dataPush = {
              'akumulasi': 0,
              'berjalan': 0,
              'penerima': 0
            }
            var pushstat = {
              statistic: dataPush
            }
            this.setState({
              isLoaded: true,
              items: pushstat
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: false,
          });
        }
      )
  }
  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded || this.state && this.state.items && this.state.items.length > 0) {
      return (
        <div className='proyek text-center'
          style={{ paddingTop: '100px', paddingBottom: '100px', Width: 'initial', textAlign: 'center', marginLeft: '50%' }}
        >
          <BallClipRotateMultiple
            color={'#123abc'}
            loading
          />
        </div>
      );
    } else {
      return (
        <div className="container">
          <div className="col-md-12">
            <div className="row jusify-content-center" >
              <div className="col-md-4 d-flex" id="gambar">
                <img src="/icon-total-pembayaran(1).svg" id="gambar1" alt="" />
                <div className="count-info">
                  <h4>
                    Total akumulasi <br /> Investasi Bisnis
                  </h4>
                  {/* <div className="rp">Rp</div>
                  <h5 className="font-weight-bold">
                    {items.statistic.akumulasi}
                  </h5> */}
                  <div className="angka">Rp {items.statistic.akumulasi}</div>
                </div>
              </div>
              <div className="col-md-4 d-flex" id="gambar">
                <img src="/icon-total-pembayaran (2).svg" alt="gambar1" />
                <div className="count-info">
                  <h4>
                    Total Investasi Bisnis berjalan
                  </h4>
                  {/* <div className="rp">Rp</div> 
                  <h5 className="">
                    {items.statistic.berjalan}
                  </h5> */}
                  <div className="angka">Rp {items.statistic.berjalan}</div>
                </div>
              </div>
              <div className="col-md-4 d-flex" id="gambar">
                <img src="/icon-total-pembayaran (3).svg" alt="" />
                <div className="count-info-akun">
                  <h4>
                    Total Akumulasi Penerimaan Dana
                  </h4>
                  {/* <div className="akun">{items.statistic.penerima}</div> */}
                  <div className="angka">Rp {items.statistic.penerima}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Api;