import React, { useState } from 'react';
import Navbar from "../../components/navbar_home";
import Footer from "../../components/Footer";
import "../../App.scss";

class PrivacyPolicy extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.isLoading = true;
    }

    clearConsole() {
        if (window.console || window.console.firebug) {
            console.clear();
        }
    }

    render() {
        this.clearConsole();
        return (
            <div>
                <Navbar />
                <Body />
                <Footer />
            </div>
        );
    }
}

function Body() {
    return (
        <div className="container " style={{ marginTop: "50px", marginBottom: "50px" }}>
            <div className="row" >
                <div className="col-md-12 col-lg-12">
                    {/* <div className="gambar text-center">
                        <img src={Kerja} style={{ width: "30%" }} alt="" />
                    </div> */}
                    <div className="visi-misi text-center">
                        <h4 style={{ fontSize: "24px", fontWeight: "700", color: "#033C85", textTransform: "uppercase" }}>KEBIJAKAN PRIVASI</h4>
                    </div>
                    <div className="content" style={{ marginTop: "50px", marginLeft: "50px", marginRight: "50px"}}>
                        <p><b>GAMBARAN SINGKAT</b></p>
                        <ol style={{marginLeft: '-20px', lineHeight: '1.5em'}}>
                            <li>Kerjasama.co.id berkomitmen untuk melindungi dan menghormati privasi Anda.</li>
                            <li>Kebijakan Privasi ini berlaku untuk semua orang dan entitas yang mengakses Situs Ini. Dengan mengakses dan/atau menggunakan layanan-layanan yang tersedia atau melalui Situs, Anda dengan ini mengakui bahwa Anda telah membaca dan setuju dengan ketentuan-ketentuan Kebijakan Privasi ini (beserta dengan Ketentuan-Ketentuan Penggunaan), Kebijakan Cookie kami, dan setiap dokumen lainnya yang dimaksud atasnya).</li>
                            <li>Kebijakan Privasi (beserta Ketentuan-Ketentuan Penggunaan kami, Kebijakan Cookie kami, dan setiap dokumen lain yang dimaksud dalam Kebijakan Privasi ini) dirancang untuk membantu Anda mengerti bagaimana kami mengumpulkan, menggunakan, mengungkapkan dan/atau mengolah Data Pribadi yang Anda telah berikan kepada kami, serta membantu Anda dalam mengambil keputusan yang diberitahukan sebelum memberikan Data Pribadi Anda kepada kami. Bacalah hal-hal berikut ini dengan teliti untuk memahami pandangan dan kebiasaan kami sehubungan dengan Data Pribadi Anda dan bagaimana kami akan memperlakukannya.</li>
                        </ol>

                        <p><b>DEFINISI</b></p>
                        <ol style={{marginLeft: '-20px', lineHeight: '1.5em'}}>
                            <li>"Kerjasama.co.id" memiliki arti yang sama sebagaimana ditetapkan dalam Ketentuan-Ketentuan Penggunaan.</li>
                            <li>"Grup Kerjasama.co.id" memiliki arti yang sama sebagaimana ditetapkan dalam Ketentuan-Ketentuan Penggunaan.</li>
                            <li>"Kebijakan Cookie" mengacu kepada kebijakan cookie milik Situs Kerjasama.co.id.</li>
                            <li>"Layanan Interaktif" memiliki arti yang sama sebagaimana ditetapkan dalam Ketentuan-Ketentuan Penggunaan.</li>
                            <li>"Data Pribadi" berarti informasi atau data yang dapat digunakan secara langsung atau tidak langsung untuk mengidentifikasi seorang individu yang merupakan orang perseorangan.</li>
                            <li>"Produk" memiliki arti yang sama sebagaimana ditetapkan dalam Ketentuan-Ketentuan Penggunaan.</li>
                            <li>"Pihak Ketiga Yang Dipilih" mengacu kepada para pihak ketiga yang ditetapkan dalam pasal 4.5 di bawah.</li>
                            <li>"Layanan" memiliki arti yang sama sebagaimana ditetapkan dalam Ketentuan-Ketentuan Penggunaan.</li>
                            <li>"Situs" memiliki arti yang sama sebagaimana ditetapkan dalam Ketentuan-Ketentuan Penggunaan.</li>
                            <li>"Konten Situs" memiliki arti yang sama sebagaimana ditetapkan dalam Ketentuan-Ketentuan Penggunaan.</li>
                            <li>"Ketentuan-Ketentuan Penggunaan" mengacu kepada Ketentuan-Ketentuan Penggunaan di situs ini.</li>
                            <li>"Konten Pihak Ketiga" memiliki arti yang sama sebagaimana ditetapkan dalam Ketentuan-Ketentuan Penggunaan.</li>
                            <li>"Situs Pihak Ketiga" memiliki arti yang sama sebagaimana ditetapkan dalam Ketentuan-Ketentuan Penggunaan.</li>
                            <li>"Para Pengguna" memiliki arti yang sama sebagaimana ditetapkan dalam Ketentuan-Ketentuan Penggunaan.</li>
                            <li>"Kami" dan "Milik Kami" memiliki arti yang sama sebagaimana ditetapkan dalam Ketentuan-Ketentuan Penggunaan.</li>
                            <li>"Anda" dan "Milik Anda" memiliki arti yang sama sebagaimana ditetapkan dalam Ketentuan-Ketentuan Penggunaan.</li>
                        </ol>

                        <p><b>PENGUMPULAN INFORMASI</b></p>
                        <ol style={{marginLeft: '-20px', lineHeight: '1.5em'}}>
                            <li>
                                Dari waktu ke waktu, Kerjasama.co.id dapat mengumpulkan informasi dan data tentang Anda, termasuk informasi dan data:
                                <ul style={{listStyleType: 'square', marginLeft: '15px'}}>
                                    <li>bahwa diberikan oleh Anda atau diberikan oleh siapapun yang diizinkan oleh Anda;</li>
                                    <li>dalam kaitannya dengan setiap kunjungan Anda ke Situs kami; dan</li>
                                    <li>dari para pihak ketiga lainnya yang diizinkan oleh Anda dan sumber-sumber yang tersedia untuk umum termasuk lembaga atau biro pemeringkat kredit.</li>
                                </ul>
                            </li>
                            <li>Melalui Anda, Kerjasama.co.id juga dapat mengumpulkan informasi dan data tentang para anggota keluarga, teman, penerima manfaat, pengacara, pemegang saham, pemilik manfaat, kuasa, orang-orang di bawah perwaliamanatan, wali amanat, penjamin Anda, penyedia jaminan lainnya dan individu-individu lain (secara kolektif semua yang tersebut di atas yang adalah orang perseorangan, "Individu Yang Relevan").</li>
                            <li>
                                Dalam hal ini, Kerjasama.co.id dapat mengumpulkan informasi dan data berikut yang dinyatakan dalam pasal 3.1 dan 3.2 sebagai berikut:
                                <ul style={{listStyleType: 'square', marginLeft: '15px'}}>
                                    <li>Setiap informasi atau data tentang Anda dan/atau Individu Yang Relevan tersebut yang dapat mengidentifikasi Anda atau Individu Yang Relevan tersebut, termasuk tetapi tidak terbatas pada nama, tanggal lahir, KTP, paspor atau nomor atau keterangan identifikasi lain, alamat, alamat email, nomor telepon, rincian kontak, informasi keuangan dan kartu kredit, deskripsi dan foto pribadi, informasi portofolio produk dan layanan, pola dan perilaku transaksi, latar belakang keuangan, sejarah pendanaan, latar belakang pendidikan dan data kependudukan;</li>
                                    <li>Setiap informasi dan data tentang Anda dan/atau Individu yang relevan tersebut dihasilkan dan/atau diberikan kepada kami selama mengakses Situs kami dan melakukan kegiatan apapun pada Situs kami. Contohnya, username terdaftar Anda untuk Situs, hasil pencarian dan sejarah untuk Produk dan Layanan dalam Situs kami, sejarah penyerahan Produk, catatan transaksi untuk Layanan dan Produk yang diberikan melalui Situs kami, catatan partisipasi dalam setiap Layanan Interaktif pada situs kami, dan jawaban Anda atas pertanyaan-pertanyaan yang ditujukan untuk verifikasi keamanan;</li>
                                    <li>Setiap informasi dan data tentang Anda dan Individu Yang Relevan tersebut dihasilkan dan/atau diberikan kepada kami dalam perjalanan mempertahankan hubungan antara kami dan Anda termasuk laporan tinjauan akun dan catatan korespondensi dengan Anda melalui telepon atau melalui email;</li>
                                    <li>
                                        Setiap informasi dan data tentang Anda yang dikumpulkan dalam kaitannya dengan setiap kunjungan Anda ke Situs kami termasuk:
                                        <ol style={{marginLeft: '-25px', lineHeight: '1.5em'}}>
                                            <li>informasi teknis, termasuk alamat Internet Protocol (IP) yang digunakan untuk menghubungkan komputer Anda dengan Internet, informasi login Anda, tipe dan versi browser, pengaturan zona waktu, tipe dan versi browser plug-in, sistem operasi dan platform;</li>
                                            <li>informasi kunjungan situs, termasuk semua Uniform Resources Locators (URL) clickstream ke, melalui dan dari Situs kami (termasuk tanggal dan waktu); produk-produk yang Anda lihat atau cari; waktu respon halaman, kesalahan unduh, lama kunjungan ke laman-laman tertentu, informasi interaksi laman (seperti scrolling, klik, dan mouse-over), dan metode-metode yang digunakan untuk pindah dari laman tersebut dan nomor telepon apapun yang digunakan untuk menghubungi nomor layanan pelanggan kami; dan</li>
                                            <li>
                                                informasi dan data dari cookies atau teknologi lainnya yang digunakan pada Situs kami. Untuk informasi lebih lanjut tentang cookie dan teknologi tersebut dan tujuan kami menggunakannya, lihat di Kebijakan Cookie kami.
                                                <ul style={{listStyleType: 'square', marginLeft: '15px'}}>
                                                    <li>Setiap informasi dan data tentang Anda yang dikumpulkan oleh kami ketika Anda berpartisipasi dalam acara yang kami selenggarakan, seperti pesta, seminar, program kontes atau penghargaan, yang dapat termasuk foto, rekaman video dan suara Anda;</li>
                                                    <li>Setiap informasi dan data tentang Anda yang kami terima jika Anda menggunakan website lain apapun yang dioperasikan oleh kami, afiliasi dan anak perusahaan kami atau layanan lain yang disediakan oleh kami, afiliasi dan anak perusahaan kami. Dalam hal tersebut, kami akan memberitahu Anda dan mendapatkan persetujuan Anda untuk pengumpulan informasi atau data tentang Anda dengan Kebijakan Privasi yang terkait yang terkandung dalam website lain yang dioperasikan oleh kami.</li>
                                                    <li>Setiap informasi dan data tentang Anda yang kami terima dari para pihak ketiga Yang Dipilih (ditetapkan pada pasal [4.5] di bawah);</li>
                                                </ul>
                                            </li>
                                        </ol>
                                    </li>
                                </ul>
                            </li>
                            <li>Informasi dan data tentang Anda dan Individu yang relevan yang dimaksud di atas pada pasal [3.3] secara kolektif merupakan bagian dari data Pribadi.</li>
                        </ol>

                        <p><b>PENGGUNAAN DAN PENGUNGKAPAN INFORMASI DAN DATA PRIBADI</b></p>
                        <ol style={{marginLeft: '-20px', lineHeight: '1.5em'}}>
                            <li>
                                Apabila dianggap perlu dan pantas oleh Kerjasama.co.id dan sesuai dengan hukum, aturan, dan peraturan yang berlaku, Data Pribadi dapat digunakan dan diungkapkan oleh Kerjasama.co.id dan/atau Grup Kerjasama.co.id untuk tujuan berikut ini: 
                                <ul style={{listStyleType: 'square', marginLeft: '15px'}}>
                                    <li>memberikan akses kepada Anda dan memfasilitasi penggunaan Situs kami dan memastikan bahwa Konten Situs, Produk, Layanan, Layanan Interaktif, Konten Pihak Ketiga dan Situs Pihak Ketiga ditampilkan dengan cara yang paling efektif untuk Anda dan Perangkat Anda;</li>
                                    <li>memberikan kepada Anda informasi, Konten Situs, Produk, Layanan dan Layanan Interaktif yang Anda minta dari kami dan akses ke Situs Pihak Ketiga dan Konten Pihak Ketiga;</li>
                                    <li>melaksanakan kewajiban kami yang timbul dari setiap kontrak yang diadakan antara Anda dan kami;</li>
                                    <li>memberikan kepada Anda informasi tentang Produk dan Layanan lain yang kami tawarkan yang serupa dengan Produk dan Layanan yang Anda telah beli atau tanyakan;</li>
                                    <li>memberikan kepada Anda, atau mengizinkan Para Pihak Ketiga Yang Dipilih untuk memberikan informasi tentang Produk atau Layanan kepada Anda yang kami rasa mungkin menarik bagi Anda. Apabila Anda telah menjadi Pengguna, kami hanya akan menghubungi Anda melalui email, SMS atau telepon untuk memberikan Informasi tentang Produk dan Layanan yang serupa dengan yang sebelumnya telah Anda pertimbangkan, pendanaan atau manfaatkan. Jika Anda seorang pelanggan baru, dan apabila Anda setuju untuk mengungkapkan Data Pribadi Anda kepada Para Pihak Ketiga Yang Dipilih, kami (atau Para Pihak Ketiga Yang Dipilih) dapat menghubungi Anda melalui email atau SMS untuk memberikan informasi tentang Produk atau Layanan;</li>
                                    <li>memberitahu Anda tentang perubahan-perubahan di Situs, Konten Situs, Produk, Layanan, Layanan Interaktif, Konten Pihak Ketiga dan Situs Pihak Ketiga;</li>
                                    <li>mengelola Situs kami dan untuk pengoperasian internalnya, termasuk pemecahan masalah (troubleshooting), analisis data, pengujian, penelitian, tujuan statistik dan survei;</li>
                                    <li>meningkatkan Situs kami untuk memastikan bahwa Konten Situs, Produk, Layanan, Layanan Interaktif, Konten Pihak Ketiga dan Situs Pihak Ketiga disampaikan dengan cara yang paling efektif untuk Anda dan Perangkat Anda;</li>
                                    <li>memungkinkan Anda untuk berpartisipasi dalam Layanan Interaktif dan fitur interaktif lainnya dari Situs, Produk dan Layanan kami, jika Anda memilih untuk melakukannya;</li>
                                    <li>menerapkan dan meninjau langkah-langkah pengamanan dan perlindungan pada Situs kami sehingga menjaga Situs kami tetap aman dan terjamin;</li>
                                    <li>menilai atau memahami efektivitas iklan kami dan iklan pihak ketiga pada Situs kami dan pada website pihak ketiga lain, dan untuk menyampaikan iklan yang relevan kepada Anda;</li>
                                    <li>membuat saran dan rekomendasi untuk Anda dan Para Pengguna lain Situs kami tentang Produk, Layanan atau produk dan layanan pihak ketiga yang mungkin menarik bagi Anda atau mereka;</li>
                                    <li>membuat keputusan yang berkaitan dengan pembukaan atau kelanjutan akun(-akun) Pengguna dan pembuatan, penyediaan atau kelanjutan dari Layanan(-Layanan) dan Produk(-Produk) selain itu menjaga akurasi informasi "kenali pelanggan Anda" dan melakukan pemeriksaan anti pencucian uang, kredit dan latar belakang;</li> 
                                    <li>menyediakan, mengoperasikan, memproses dan mengelola akun(-akun) Pengguna, Layanan(-Layanan) dan Produk(-Produk) atau mengelola aplikasi-aplikasi untuk Akun(-Akun) Pengguna, Layanan(-Layanan) dan Produk(-Produk), setiap transaksi (termasuk transaksi pengiriman uang), dan menjaga kualitas layanan dan melatih staf;</li>
                                    <li>melakukan kegiatan-kegiatan yang berkaitan dengan penyediaan akun(-akun) Pengguna, Layanan(-Layanan) dan Produk(-Produk), termasuk langganan atau usulan langganan atas Produk(-Produk) atau Layanan(-Layanan) (baik yang ditawarkan atau yang dikeluarkan oleh Kerjasama.co.id atau yang lainnya) dan penyediaan laporan penelitian atau bahan untuk analisis;</li>
                                    <li>menyediakan layanan dan dukungan terkait Produk, termasuk tetapi tidak terbatas pada, penyediaan dukungan pengelolaan atau administratif atau bertindak sebagai perantara/pihak yang ditunjuk (nominee)/agen sehubungan dengan partisipasi Anda dalam Produk(-Produk);</li>
                                    <li>menyediakan atau memberikan akses kepada materi-materi terkait Produk seperti dokumen penawaran, laporan penelitian, profil produk, lembar fakta, lembar ketentuan (term sheet) atau materi-materi lain terkait produk;</li>
                                    <li>memenuhi persyaratan-persyaratan hukum, regulasi dan kepatuhan di dalam dan luar negeri yang berlaku pada Kerjasama.co.id dan Grup Kerjasama.co.id berdasarkan undang-undang yang berlaku (termasuk anti pencucian uang dan kewajiban pajak yang berlaku pada Kerjasama.co.id dan Grup Kerjasama.co.id, dan pengungkapan pada setiap bursa efek, pengadilan, otoritas pemerintah, otoritas pengawas atau regulator lain apapun sesuai dengan pedoman, peraturan, perintah, petunjuk atau permintaan yang relevan dari organisasi-organisasi tersebut) dan mematuhi setiap perjanjian internasional atau perjanjian yang berlaku dengan atau antara pemerintah asing dan dalam negeri yang berlaku pada Kerjasama.co.id dan Grup Kerjasama.co.id;</li>
                                    <li>memeriksa identitas dan/atau kewenangan dari para kuasa Anda yang menghubungi Kerjasama.co.id atau Grup Kerjasama.co.id atau dapat dihubungi oleh Kerjasama.co.id atau Grup Kerjasama.co.id dan untuk melaksanakan atau menanggapi permintaan, pertanyaan atau instruksi dari para kuasa yang telah diperiksa atau para pihak lain sesuai dengan prosedur pengamanan kami yang berlaku;</li>
                                    <li>melakukan penilaian risiko, analisis dan perencanaan statistik dan tren, termasuk melaksanakan pengolahan data, analisis statistik, kredit, risiko dan anti pencucian uang, membuat dan mengelola model penilaian kredit, melakukan pemeriksaan dan peninjauan kredit dan latar belakang lain, dan menyimpan sejarah kredit Anda dan kuasa Anda yang sah (terlepas apakah ada hubungan langsung antara kuasa yang sah tersebut dengan Kerjasama.co.id atau Grup Kerjasama.co.id atau tidak) untuk referensi saat ini dan di masa mendatang;</li>
                                    <li>memantau dan mencatat panggilan dan komunikasi elektronik dengan Anda untuk tujuan pencatatan, kualitas, pelatihan, investigasi, dan pencegahan penipuan;</li>
                                    <li>mendeteksi, mencegah, melakukan penyidikan dan mendakwa kejahatan dan penipuan termasuk membantu dalam setiap penyidikan kejahatan oleh otoritas yang relevan terhadap Anda, Individu Yang Relevan atau orang lain;</li>
                                    <li>melaksanakan (termasuk, namun tidak terbatas pada, penagihan jumlah yang terutang kepada Kerjasama.co.id dan/atau Grup Kerjasama.co.id) atau membela hak-hak Kerjasama.co.id atau Grup Kerjasama.co.id, yang berasal dari kontrak atau lainnya;</li>
                                    <li>melakukan menajemen internal untuk mengoperasikan sistem informasi kontrol dan manajemen dan melaksanakan audit internal atau mengizinkan pelaksanaan audit eksternal;</li>
                                    <li>memungkinkan penerima hak yang sebenarnya atau yang diajukan dari Kerjasama.co.id, atau peserta atau sub-peserta dari hak-hak Kerjasama.co.id sehubungan dengan Anda, untuk mengevaluasi transaksi yang dimaksudkan untuk menjadi obyek dari pengalihan, partisipasi atau sub-partisipasi;</li>
                                    <li>mematuhi pengaturan kontrak oleh atau antara industri otonom, badan industri, asosiasi penyedia industri atau lembaga lain yang serupa dengan Kerjasama.co.id atau Grup Kerjasama.co.id; dan</li>
                                    <li>mengelola hubungan Kerjasama.co.id dengan Anda, yang dapat termasuk menyediakan informasi kepada Anda atau Individu Yang Relevan, tentang produk dan layanan Kerjasama.co.id atau Grup Kerjasama.co.id, apabila secara khusus disetujui atau apabila diizinkan berdasarkan peraturan perundang-undangan yang berlaku;</li>
                                    <li>untuk memasarkan produk atau layanan keuangan atau yang terkait dengan pinjaman melalui berbagai moda komunikasi termasuk surat, telepon, SMS, fax, email, internet, riset pasar, dan merancang atau menyaring produk atau layanan yang serupa termasuk dengan melakukan riset pasar, analisis data, dan survei;</li>
                                    <li>mematuhi setiap kewajiban, persyaratan, kebijakan, prosedur, penilaian atau pengaturan untuk berbagi data dan informasi dalam Kerjasama.co.id atau Grup Kerjasama.co.id dan penggunaan data dan informasi lainnya sesuai dengan setiap program Grup Kerjasama.co.id untuk pemenuhan sanksi atau pencegahan atau pendeteksian pencucian uang, pendanaan teroris atau kegiatan melanggar hukum lainnya.</li>
                                </ul>
                            </li>
                            <li>Kami dapat menggabungkan setiap informasi dan data, terlepas dari bagaimana informasi dan data tersebut diberikan kepada kami atau dikumpulkan oleh kami, dan menggunakan informasi atau data yang digabungkan untuk tujuan yang ditetapkan dalam pasal [4.1] di atas.</li>
                            <li>Kami juga dapat menggunakan dan mengungkapkan Data Pribadi untuk tujuan lain yang berkaitan dengan hal-hal apapun di atas sebagaimana ditetapkan dalam pasal 4.1 dan 4.2, yang orang normal akan menganggap sesuai dengan situasi tersebut.</li>
                            <li>Sebagaimana tujuan dari kami dapat mengumpulkan, menggunakan, mengungkapkan atau mengolah Data Pribadi Anda tergantung pada keadaan pada saat itu, tujuan tersebut mungkin tidak muncul dalam pasal di atas. Namun, kami akan memberitahu Anda tentang tujuan lain tersebut pada saat meminta persetujuan Anda untuk pengumpulan Data Pribadi tersebut, kecuali pengolahan Data Pribadi Anda tanpa persetujuan Anda yang diizinkan oleh hukum.</li>
                            <li>
                                Kami juga dapat berbagi Data Pribadi dengan Para Pihak Ketiga Yang Dipilih termasuk:
                                <ul style={{listStyleType: 'square', marginLeft: '15px'}}>
                                    <li>para mitra usaha, pemasok dan sub-kontraktor untuk pelaksanaan setiap kontrak yang kami adakan dengan mereka atau Anda;</li>
                                    <li>para pengiklan dan jaringan-jaringan iklan yang memerlukan data untuk memilih dan menyediakan iklan yang relevan kepada Anda dan orang lain. Kami tidak mengungkapkan informasi tentang individu yang dapat diidentifikasi kepada para pengiklan, tetapi kami dapat memberikan mereka informasi agregat tentang Para Pengguna kami (misalnya, kami dapat memberitahu mereka bahwa 500 pria berusia di bawah 30 tahun mengklik iklan mereka pada hari tertentu). Kami juga bisa menggunakan informasi agregat tersebut untuk membantu para pengiklan menjangkau jenis audiens yang mereka ingin targetkan. Kami dapat menggunakan informasi atau data yang dikumpulkan dari Anda untuk memungkinkan kami memenuhi keinginan para pengiklan kami dengan menampilkan iklan mereka kepada audiens yang menjadi target;</li> 
                                    <li>penyedia analisis dan mesin pencari yang membantu kami dalam peningkatan dan optimalisasi situs kami;</li>
                                    <li>calon penjual atau pembeli bisnis atau aset apabila kami menjual atau membeli bisnis atau aset apapun, dalam hal ini kami dapat mengalihkan Data Pribadi Anda kepada pembeli atau penjual tersebut sebagai bagian dari transaksi;</li>
                                    <li>setiap organisasi atau orang tersebut, jika kami berada di bawah kewajiban untuk mengungkapkan atau membagikan Data Pribadi Anda untuk mematuhi setiap kewajiban hukum, atau untuk melaksanakan atau menerapkan Ketentuan-Ketentuan Penggunaan kami, Kebijakan Cookie kami, dan perjanjian lainnya, atau untuk melindungi hak, aset, atau keamanan dari Kerjasama.co.id dan Grup Kerjasama.co.id, pelanggan kami, Para Pengguna atau lainnya. Ini termasuk bertukar informasi dengan perusahaan dan organisasi lain untuk tujuan perlindungan dari penipuan dan pengurangan risiko kredit;</li>
                                    <li>agen, kontraktor atau penyedia layanan pihak ketiga yang menyediakan administrasi, pengiriman pos, telemarketing, telekomunikasi penjualan langsung, call center, proses bisnis, perjalanan, visa, manajemen pengetahuan, sumber daya manusia, pengolahan data, teknologi informasi, komputer, pembayaran, penagihan utang, referensi kredit atau pemeriksaan-pemeriksaan latar belakang lain atau layanan-layanan lain kepada Kerjasama.co.id atau Grup Kerjasama.co.id sehubungan dengan pengoperasian bisnis dari Kerjasama.co.id atau Grup Kerjasama.co.id;</li>
                                    <li>orang atau entitas yang merupakan bagian dari Grup Kerjasama.co.id walaupun hanya sepanjang diperlukan untuk memenuhi Tujuan Yang Diizinkan yang relevan;</li>
                                    <li>bank tertarik atau penarik sehubungan dengan setiap cek atau yang setara yang diproses oleh Kerjasama.co.id atau Grup Kerjasama.co.id;</li>
                                    <li>lembaga keuangan yang dengannya Anda memiliki atau mengajukan untuk memiliki urusan terkait dengan setiap Produk dan/atau Layanan;</li>
                                    <li>orang atau entitas yang kepada orang atau entitas tersebut Kerjasama.co.id atau Grup Kerjasama.co.id berkewajiban atau disyaratkan untuk melakukan pengungkapan sesuai dengan proses hukum atau berdasarkan persyaratan dari hukum, peraturan, perintah pengadilan di dalam dan luar negeri atau perjanjian yang diadakan, yang mengikat atau berlaku untuk Kerjasama.co.id atau Grup Kerjasama.co.id dan setiap otoritas pemerintah, di dalam atau luar negeri, atau antara dua otoritas pemerintah atau lebih di dalam atau di luar negeri, atau setiap pengungkapan berdasarkan dan untuk tujuan pedoman atau petunjuk yang diberikan atau dikeluarkan oleh badan penegak hukum, regulasi, badan pemerintah, otoritas pajak, lembaga penegak hukum atau otoritas-otoritas lain, atau peraturan otonom dari badan industri atau asosiasi dari penyedia jasa keuangan atau bursa efek yang disyaratkan atau diharapkan untuk dipenuhi oleh Kerjasama.co.id atau Grup Kerjasama.co.id atau setiap pengungkapan sesuai dengan setiap kontrak atau komitmen lain dari Kerjasama.co.id atau Grup Kerjasama.co.id dengan lembaga hukum, regulasi, badan pemerintah, pajak, lembaga penegak hukum atau otoritas-otoritas lain, atau badan otonom atau industri atau asosiasi dari penyedia jasa keuangan, atau bursa efek, semua yang mungkin di dalam atau di luar Singapura termasuk pengungkapan ke sidang pengadilan, pengadilan tribunal, dan/atau lembaga hukum, regulasi, pajak dan badan pemerintah, terlepas apakah proses hukum, kewajiban, permintaan, persyaratan, perjanjian atau pedoman tersebut saat ini masih ada atau dibuat di masa mendatang;</li>
                                    <li>lembaga keuangan, agen proses, atau pihak lain yang akan terlibat dalam transaksi pengiriman uang atau kegiatan perbankan/keuangan apapun;</li>
                                    <li>penerima pengalihan yang sebenarnya atau yang diajukan dari Kerjasama.co.id atau Grup Kerjasama.co.id, atau peserta atau sub-peserta atau penerima pengalihan hak-hak Kerjasama.co.id atau Grup Kerjasama.co.id sehubungan dengan Anda atau Individu Yang Relevan lain, semua atau sebagian dari aset atau bisnis dari Kerjasama.co.id atau Grup Kerjasama.co.id; dan </li>
                                    <li>pihak yang memberikan atau mengajukan untuk memberikan jaminan atau jaminan pihak ketiga kepada penerima jaminan atau menjamin kewajiban-kewajiban Anda atau orang-orang dari Individu Yang Relevan manapun.</li>
                                </ul>
                            </li>
                            <li>Dalam hal ini, tujuan atas penggunaan dan pengungkapan informasi dan data tentang Anda yang dimaksud berdasarkan pasal 4 ini secara kolektif selanjutnya disebut sebagai "Tujuan Yang Diizinkan".</li>
                        </ol>

                        <p><b>DATA PRIBADI DAN LEMBAGA RUJUKAN KREDIT</b></p>
                        <ol style={{marginLeft: '-20px', lineHeight: '1.5em'}}>
                            <li>
                                Kerjasama.co.id atau Grup Kerjasama.co.id dapat, dengan sendirinya dan/atau sebagai agen Anda, menyediakan informasi dan data yang relevan tentang Anda dan Individu Yang Relevan tersebut kepada lembaga-lembaga referensi kredit untuk tujuan memperbarui catatan kredit Anda dalam basis data kredit pelanggan dari lembaga-lembaga tersebut. Dalam hal ini, informasi dan data yang relevan tentang Anda dan Individu Yang Relevan akan sehubungan dengan Produk(-Produk) dan/atau Layanan(-Layanan) yang diadakan atau digunakan oleh Anda dan Individu Yang Relevan tersebut (apakah sebagai peminjam, pemberi hak tanggungan, penjamin atau penyedia jaminan dan apakah dengan nama Anda dan nama Individu Yang Relevan sendiri atau bersama dengan orang lain) dan informasi dan data ini akan termasuk:</li>
                                <ul style={{listStyleType: 'square', marginLeft: '15px'}}>
                                    <li>nama panjang;</li>
                                    <li>kapasitas sehubungan dengan Produk (sebagai peminjam, pemberi hak tanggungan, penjamin atau penyedia jaminan, dan apakah dengan nama sendiri atau dengan nama bersama dengan orang lain);</li>
                                    <li>nomor identitas (misalnya, nomor kartu identitas, paspor atau KTP);</li>
                                    <li>tanggal lahir;</li>
                                    <li>alamat korespondensi;</li>
                                    <li>akun atau nomor referensi berkenaan dengan setiap Produk;</li>
                                    <li>jenis Produk yang diadakan dan/atau digunakan;</li>
                                    <li>status akun (contohnya, aktif, ditutup, dihapus (selain karena putusan kepailitian), dihapus karena karena putusan kepailitian);</li>
                                    <li>tren dan status pembayaran (misalnya, pembayaran semua atau sebagian, saat ini atau di akhir periode);</li>
                                    <li>tanggal penutupan akun, jika ada, berkenaan dengan setiap Produk;</li>
                                    <li>tanggal penggunaan Produk; dan</li>
                                    <li>informasi umum tentang akun dan informasi mengenai setiap standar materi, jika ada, yang berkaitan dengan standar materi.</li>
                                </ul>
                            <li>Untuk tujuan menentukan risiko kredit Anda ketika Anda mengajukan untuk setiap Produk atau Layanan, Kerjasama.co.id atau Grup Kerjasama.co.id dapat mengakses dan memperoleh dari lembaga-lembaga referensi kredit informasi pribadi dan akun atau catatan tentang Anda dan Individu Yang Relevan tersebut (termasuk informasi tentang jumlah perhitungan fasilitas/pinjaman) yang disimpan oleh lembaga referensi kredit manapun dalam yurisdiksi yang relevan.</li>
                            <li>
                                Kerjasama.co.id atau Grup Kerjasama.co.id dan Individu Yang Relevan dari waktu ke waktu dapat juga mengakses informasi pribadi dan akun atau catatan tentang Anda dan Individu Yang Relevan tersebut (termasuk informasi tentang perhitungan hak tanggungan) yang disimpan oleh lembaga referensi kredit untuk tujuan meninjau salah satu hal berikut dalam kaitannya dengan Produk yang sudah ada yang diberikan kepada Anda atau pihak ketiga yang kewajibannya dijamin oleh Anda: 
                                <ul style={{listStyleType: 'square', marginLeft: '15px'}}>
                                    <li>peningkatan jumlah kredit;</li>
                                    <li>pengetatan kredit (termasuk pembatalan kredit atau penurunan jumlah kredit);</li>
                                    <li>pemberian Produk tambahan; atau</li>
                                    <li>penempatan atau pelaksanaan skema pengaturan yang berkaitan dengan Anda atau pihak ketiga manapun yang kewajibannya dijamin oleh Anda.</li>
                                </ul>
                            </li>
                            <li>Kerjasama.co.id atau Grup Kerjasama.co.id juga dapat memperoleh laporan kredit mengenai Anda dari lembaga referensi kredit dalam mempertimbangkan setiap permohonan dari Anda untuk mendaftarkan dan mengunggah suatu Produk pada Situs kami untuk langganan. Dalam hal ini, jika Anda ingin mengakses laporan kredit tentang Anda tersebut, Kerjasama.co.id akan memberikan kepada Anda rincian kontak dari lembaga referensi kredit yang terkait kepada Anda untuk mendapatkan laporan secara independen.</li>
                        </ol>

                        <p><b>ADMINISTRASI DAN PENGELOLAAN DATA PRIBADI ANDA</b></p>
                        <span style={{lineHeight: '1.5em'}}>Kami akan melakukan upaya yang wajar untuk memastikan bahwa Data Pribadi Anda akurat dan lengkap, jika Data Pribadi Anda kemungkinan akan digunakan oleh Kerjasama.co.id untuk membuat keputusan yang mempengaruhi Anda, atau mengungkapkan kepada organisasi lain. Namun, ini berarti bahwa Anda harus juga memberitahukan kepada kami setiap perubahan pada Data Pribadi Anda yang awalnya Anda berikan kepada kami. Kami tidak akan bertanggung jawab untuk mengandalkan Data Pribadi yang tidak akurat dan tidak lengkap yang diakibatkan karena Anda tidak memberitahukan kepada kami setiap perubahan pada Data Pribadi Anda yang awalnya Anda berikan kepada kami.</span>
                        <ol style={{marginLeft: '-20px', lineHeight: '1.5em'}}>
                            <li>Kami juga akan menempatkan pengaturan pengamanan yang wajar untuk memastikan Data Pribadi Anda cukup terlindungi dan aman. Pengaturan pengamanan yang sesuai akan dilakukan untuk mencegah akses, pengumpulan, penggunana, pengungkapan, penyalinan, perubahan, kebocoran, kehilangan, kerusakan dan/atau perubahan yang tidak sah terhadap Data Pribadi Anda. Namun, kami tidak menanggung suatu tanggung jawab apapun atas setiap penggunaan tidak sah dari Data Pribadi Anda oleh para pihak ketiga yang sepenuhnya disebabkan oleh faktor-faktor di luar kendali kami.</li>
                            <li>Kami juga akan menempatkan langkah-langkah yang memastikan Data Pribadi Anda yang ada di bawah penguasaan atau berada di bawah kendali kami dimusnahkan dan/atau dijadikan bersifat anonim sesegera mungkin saat dianggap wajar untuk mengasumsikan bahwa (i) tujuan Data Pribadi itu dikumpulkan tidak lagi dipenuhi dengan penyimpanan Data Pribadi tersebut; dan (ii) penyimpanan tidak lagi perlu dilakukan untuk tujuan hukum atau bisnis lain apapun.</li>
                            <li>Apabila Data Pribadi Anda akan dipindahkan dari negara manapun di tempat Anda mengakses Situs ini, kami akan mematuhi hukum yang berlaku dalam melakukannya.</li>
                        </ol>

                        <p><b>HAK UNTUK MENOLAK PENGGUNAAN DATA PRIBADI UNTUK TUJUAN PEMASARAN</b></p>
                        <p><span style={{lineHeight: '1.5em'}}>Anda memiliki hak untuk meminta kami agar tidak mengolah Data Pribadi Anda untuk tujuan pemasaran. Kami akan memberitahu Anda dan meminta persetujuan dari Anda sebelum kami menggunakan Data Pribadi Anda untuk tujuan tersebut atau jika kami bermaksud mengungkapkan Data Pribadi Anda kepada pihak ketiga manapun untuk tujuan tersebut. Anda dapat menggunakan hak Anda untuk menolak pengolahan tersebut dengan cara memberikan tanda pada formulir yang terkait yang kami gunakan untuk mengumpulkan data Anda. Bahkan setelah Anda memberikan persetujuan Anda atas Data Pribadi yang akan digunakan untuk tujuan pemasaran, Anda dapat, setiap saat setelah itu, menarik persetujuan Anda dengan menghubungi kami di cs@kerjasama.co.id.</span></p>

                        <p><b>LINK KE SITUS LAIN</b></p>
                        <p><span style={{lineHeight: '1.5em'}}>Situs kami dapat, dari waktu ke waktu, berisi link ke Situs-Situs Pihak Ketiga dan website dari jaringan mitra, pengiklan, afiliasi atau anak perusahaan kami. Jika Anda mengikuti suatu link ke salah satu website tersebut, perlu Anda ketahui bahwa website tersebut dapat memiliki atau tidak memiliki kebijakan privasi mereka sendiri dan bahwa kami tidak menerima tanggung jawab atau kewajiban atas kebijakan-kebijakan ini atau tidak adanya kebijakan-kebijakan ini. Bacalah kebijakan ini sebelum Anda menyerahkan informasi apapun termasuk Data Pribadi Anda pada website tersebut.</span></p>

                        <p><b>AKSES KE INFORMASI</b></p>
                        <ol style={{marginLeft: '-20px', lineHeight: '1.5em'}}>
                            <li>Anda memiliki hak untuk meminta dari kami Data Pribadi tentang Anda yang disimpan oleh Kerjasama.co.id. Jika Anda ingin mengakses, memutakhirkan atau mengubah atau menghapus setiap informasi yang Anda berikan kepada kami, hubungi kami di cs@kerjasama.co.id untuk mendapatkan bantuan. Setiap permintaan akses dapat dikenakan biaya sebesar [jumlah biaya yang dibebankan] untuk memenuhi biaya kami dalam menyediakan detail informasi yang kami miliki tentang Anda kepada Anda.</li>
                            <li>
                                Dalam keadaan-keadaan luar biasa, Kerjasama.co.id dapat menolak akses Anda ke Data Pribadi Anda tetapi kami akan memberikan kepada Anda penjelasan tentang alasan(-alasan) yang mencegah kami melakukan hal itu berdasarkan hukum yang berlaku. Keadaan-keadaan luar biasa tersebut termasuk tetapi tidak terbatas pada apabila: 
                                <ul style={{listStyleType: 'square', marginLeft: '15px'}}>
                                    <li>Otoritas yang melakukan penyelidikan atau lembaga pemerintah berkeberatan terhadap pemenuhan permintaan pelanggan oleh Kerjasama.co.id;</li>
                                    <li>Informasi dapat, dalam pelaksanaan kewenangan dan/atau penilaian yang wajar dari Kerjasama.co.id, mempengaruhi hidup atau keamanan seorang individu; dan</li>
                                    <li>Data dikumpulkan sehubungan dengan investigasi terhadap pelanggaran kontrak, kecurigaan terhadap aktivitas-aktivitas penipuan atau pelanggaran hukum.</li>
                                </ul>
                            </li>
                        </ol>

                        <p><b>PERUBAHAN TERHADAP KEBIJAKAN PRIVASI KAMI</b></p>
                        <p><span style={{lineHeight: '1.5em'}}>Setiap perubahan yang kami lakukan terhadap Kebijakan Privasi kami di masa mendatang akan diposting pada halaman ini dan, jika perlu, diberitahukan kepada Anda melalui email. Harap sering memeriksa kembali untuk melihat pemutakhiran (update) atau perubahan terhadap kebijakan privasi kami.</span></p>

                        <p><b>ANAK DI BAWAH UMUR</b></p>
                        <p><span style={{lineHeight: '1.5em'}}>Kerjasama.co.id tidak dapat membedakan usia orang yang mengakses dan menggunakan website-nya. Jika seorang anak di bawah umur (menurut hukum yang berlaku) telah memberikan Data Pribadinya kepada Kerjasama.co.id tanpa persetujuan orang tua atau walinya, orang tua atau walinya itu harus menghubungi Kerjasama.co.id untuk menghapus Data Pribadi yang terkait dan menghapus nama anak di bawah umur tersebut dari daftar pelanggannya.</span></p>
                    
                        <p><b>PENGAKUAN</b></p>
                        <ol style={{marginLeft: '-20px', lineHeight: '1.5em'}}>
                            <li>Anda mengakui bahwa apabila Anda tidak memberikan informasi atau data yang relevan atau menarik persetujuan sehubungan dengan pengumpulan, penggunaan dan/atau pengungkapan informasi atau data yang relevan sebagaimana dijelaskan dalam Kebijakan Privasi, Kerjasama.co.id berpeluang untuk tidak dapat membuka atau melanjutkan akun Pengguna, atau membuat atau memberikan atau melanjutkan setiap Produk dan Layanan, atau terus mengizinkan Anda menggunakan atau mengakses Situs, Konten Situs, Produk, Layanan atau Layanan Interaktif kami. Kerjasama.co.id atau Grup Kerjasama.co.id akan memberitahu Anda tentang konsekuensi dari penarikan persetujuan tersebut seandainya Anda memberitahukan kepada kami tentang keinginan Anda untuk menarik persetujuan Anda.</li> 
                            <li>Anda menjamin kepada Kerjasama.co.id dan Grup Kerjasama.co.id bahwa apabila Anda atau kuasa Anda bertanggung jawab atas penyediaan setiap informasi atau data berkaitan dengan siapapun kepada Kerjasama.co.id dan Grup Kerjasama.co.id, atau menyediakan setiap informasi atau data tersebut kepada Kerjasama.co.id dan Grup Kerjasama.co.id, Anda telah memberitahukan dan mendapatkan persetujuan dari orang(-orang) yang bersangkutan untuk mengizinkan Kerjasama.co.id dan/atau Grup Kerjasama.co.id untuk mengumpulkan, menggunakan dan/atau mengungkapkan informasi berkaitan dengan orang(-orang) yang bersangkutan sebagaimana dijelaskan dalam Kebijakan Privasi ini.</li>
                            <li>Anda setuju bahwa Anda telah memperoleh persetujuan dari semua Individu Yang Relevan tersebut sehingga setiap infromasi atau data dari Individu Yang Relevan dapat diolah, disimpan, ditransfer atau diungkapkan dalam dan kepada negara manapun sebagaimana yang dianggap oleh Kerjasama.co.id dan Grup Kerjasama.co.id sesuai dengan hukum yang berlaku untuk Tujuan Yang Diizinkan. Data tersebut juga dapat diolah, disimpan, ditansfer atau diungkapkan sesuai dengan hukum dan praktik, aturan dan peraturan setempat (termasuk setiap permintaan berdasarkan peraturan, tindakan dan perintah pemerintah) di negara/yurisdiksi tersebut.</li>
                            <li>Sesuai dengan Ketentuan-Ketentuan Penggunaan, Kebijakan Cookie dan [Perjanjian Induk], Anda setuju bahwa Kerjasama.co.id dapat mengubah dan memodifikasi ketentuan-ketentuan dari Kebijakan Privasi ini dari waktu ke waktu dan bawa setelah pemberitahuan kepada Anda terhadap Kebijakan Privasi yang diubah tersebut, konten-konten daripadanya sama halnya akan mengubah, meragamkan dan melengkapi Ketentuan-Ketentuan Penggunaan, Kebijakan Cookie dan [Perjanjian Induk], perjanjian-perjanjian dan/atau pengaturan-pengaturan tersebut di atas akan berlaku sejak tanggal yang ditetapkan dalam Kebijakan Privasi yang diubah tersebut, dan tanpa mengurangi hal tersebut di atas, akses/penggunaan atau kelanjutan akses/penggunaan Anda atas Situs, Konten Situs, Produk, Layanan, Layanan Interaktif setelah perubahan tersebut juga harus dianggap sebagai penerimaan dan persetujuan Anda pada hal-hal tersebut.</li>
                        </ol>

                        <p><b>KONTAK</b></p>
                        <p><span style={{lineHeight: '1.5em'}}>Pertanyaan, komentar dan permintaan mengenai Kebijakan Privasi ini diterima dan dapat diarahkan kepada Petugas Perlindungan Data Kerjasama.co.id di alamat berikut: Komplek Pluit Mas Blok BB No. 3-4, Kel. Pejagalan, Kec. Penjaringan, Jakarta Utara 14450. </span></p>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicy;
