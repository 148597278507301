import React, { Component, memo, useState } from 'react'
import { Modal, Form, Alert } from 'react-bootstrap';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../../../App.scss'
import '../../../AppMobile.scss';
import { BASE_URL, tokenGet } from "../../../service/api";
import img_avatar from '../../../assets/img/img_avatar.png'
import receiving_money from '../../../assets/img/receiving_money_1_@2x.png'
import saving_money from '../../../assets/img/saving_money_1_.png'
import change_img from '../../../assets/img/notfound.jpg'
import TabUserPengelola from './TabUserPengelola'
import $ from 'jquery'
import axios from 'axios'
import {
  Button, Portal,
  Dropdown,
  Checkbox,
  Grid,
  Header,
  Icon,
  Image,
  Menu,
  Segment,
  Sidebar,
  Input,
  Label,
} from 'semantic-ui-react'
import swal from 'sweetalert';
import { saveAs } from 'file-saver';

export default class SidebarUserPengelola extends Component {
  constructor(props) {
    super(props);
    // 1. bind your functions in the constructor.
    this.mouseOver = this.mouseOver.bind(this);
    this.mouseOut = this.mouseOut.bind(this);
    this.state = {
      hover: false,
      animation: 'push',
      direction: 'left',
      allBanks: [],
      dimmed: false,
      visible: true,
      copySuccess: 'Copied!',
      change_img: change_img,
      img_profile: img_avatar,
      openPortal: false,
      totalShareProfit: 0,
    };
  }

  handleAnimationChange = (animation) => () =>
    this.setState((prevState) => ({ animation, visible: !prevState.visible }))

  handleDimmedChange = (e, { checked }) => this.setState({ dimmed: checked })

  handleDirectionChange = (direction) => () =>
    this.setState({ direction, visible: false })

  copyToClipboard = (e) => {
    this.refCode.select();
    document.execCommand('copy');
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
  };

  handleInputChange = e => {
    this.setState({ value: e.target.value });
  };

  getDataBank() {

    axios.get(BASE_URL + "/allbanks",
      axios.defaults.headers.common['Authorization'] = localStorage.token,
      axios.defaults.headers.common['Accept'] = 'application/json'
    )
      .then((resp) => {
        this.setState({
          allBanks: resp.data.data,
        })
      })
      .catch((resp) => {
        this.setState({
          allBanks: [],
        })
      });
  }

  componentDidMount() {

    var dataUser = JSON.parse(localStorage.DataUser);

    this.setState({
      totalShareProfit: dataUser.totalShareProfit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    })

    dataUser.user.dokumen.map((a, i) => {
      if (a.type == 'profile') {
        this.setState({
          img_profile: a.nama_file,
        })
      }
    })
    this.getDataBank();
  }

  mouseOver = () => {
    this.setState({ hover: true });
  }

  mouseOut() {
    this.setState({ hover: false });
  }

  onFileChange(e, param) {
    var files = e.target.files;
    if (!files.length)
      return;
    this.createImage(files[0], param);
  }

  createImage(file, param) {
    var tmp = [];
    var reader = new FileReader();

    reader.onload = e => {
      if (param == 111) {
        this.setState({
          img_profile: e.target.result
        })
        this.uplFotoPic();
      }
    };
    reader.readAsDataURL(file);
  }

  uplFotoPic() {
    const userinfo = JSON.parse(this.props.userinfo)

    var values = {
      "file": this.state.img_profile,
      "user_id": userinfo.user_id
    }

    let config = {
      headers: {
        'Authorization': localStorage.token,
        'content-type': 'application/json',
        'Access-Control-Allow-Credentials': true,
        'Access-Control-Allow-Methods': 'POST',
      },
      maxContentLength: 52428890,
      timeout: 10000000,
    }

    axios.post(BASE_URL + "/customer/pengelola/uplfotopic", values, config)
      .then((resp) => {
        this.setState({
          openPortal: true
        });

        setTimeout(() => {
          this.setState({
            openPortal: false
          });
        }, 3000);
      })
      .catch((resp) => {

      });

  }

  clearConsole() {
    if (window.console || window.console.firebug) {
      console.clear();
    }
  }

  handleClose = () => this.setState({ openPortal: false })

  render() {
    this.clearConsole();
    const { animation, dimmed, direction, visible, img_profile, change_img, openPortal, totalShareProfit } = this.state
    const vertical = direction === 'bottom' || direction === 'top'
    const userinfo = JSON.parse(this.props.userinfo)
    const verified = userinfo.ktp !== null ? 1 : 0
    const dataIsComplete = userinfo.is_complete
    const verified_advance = userinfo.status_user
    const agreement = userinfo.agreement
    const signature_status = userinfo.signature_status
    const allBanks = this.state.allBanks
    const addressDefinitions = allBanks
    const stateOptions = addressDefinitions.map(state => ({
      key: state.id,
      text: state.nama_bank,
      value: state.nama_bank,
    }))

    // lengkapiData = async (values) => {
    const lengkapiData = async (values) => {

      let config = {
        headers: {
          'Authorization': localStorage.token,
          'content-type': 'application/json',
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Methods': 'POST',
        },
        maxContentLength: 52428890,
        timeout: 10000000,
      }

      $(".btnSimpan").addClass('loading');
      $(".btnSimpan").addClass('disabled');
      $(".alertSuccessSubmit").attr('style', '')

      axios.post(BASE_URL + "/customer/pengelola/lengkapidata", values, config)
        .then((resp) => {
          alert(resp.data.body.message);
          setTimeout(() => {
            window.location.reload(true);
          }, 0)
        })
        .catch(error => {
          if (error.response.data.error_message) {
            alert('Credit: ' + error.response.data.error_message.credit + ', Fraud: ' + error.response.data.error_message.fraud + ', Multiplatform: ' + error.response.data.error_message.multiplatform);
          } else if (error.response.data.success === false) {
            alert(error.response.data.body.message);
          } else {
            alert('Request Timeout')
          }
          $(".btnSimpan").removeClass('loading');
          $(".btnSimpan").removeClass('disabled');
          $(".alertSuccessSubmit").attr('style', 'display:none')
        });

    }

    const changeImgProfile = e => {
      $("input[id='my_file']").click();
    }

    const handleDownloadAgreement_ = async () => {
      var FileSaver = require('file-saver');
      FileSaver.saveAs(BASE_URL + "/customer/pengelola/agreement/download", "dokumen_sample.png");
    }

    const handleDownloadAgreement = async () => {
      let config = {
        headers: {
          'Authorization': localStorage.token,
          'content-type': 'application/json',
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Methods': 'POST',
        },
        maxContentLength: 52428890,
        timeout: 10000000,
      }

      axios.post(BASE_URL + "/customer/pengelola/agreement/download", config)
        .then((resp) => {

          if (resp.data.success == false) {
            swal({ text: resp.data.body.message, icon: 'error' })
          } else {
            let link = resp.data.body.data
            // window.open(link)
            // console.log("LINK >> " + link)

            downloadFromResponseLink(link)
          }
        })
        .catch((error) => {
          try {
            let errorStatusCode = error.response.status

            if (errorStatusCode === 401) {
              setTimeout(() => {
                window.location.href = "/Signin"
                localStorage.removeItem('token');
                localStorage.clear();

                this.props.dispatch({
                  type: "AUTH_USER_LOGOUT",
                })
              }, 500);
            } else {
              let errorBody = error.response.data.body
              swal({ text: errorBody.message, icon: 'error' })
            }

          } catch (error) {
            swal({ text: 'Error tidak terdefinisi!', icon: 'error' })
          }
        });
    }

    const downloadFromResponseLink = async (downloadLink) => {
      var url = downloadLink
      var FileSaver = require('file-saver');
      FileSaver.saveAs(url, "dokumen_sample.png");
    }

    const addDefaultSrc = (ev) => {
      ev.target.src = img_avatar;
      ev.target.onerror = null;
    }

    return (
      <Container className="sidebarUser">
        <Row className="justify-content-md-center">
          <Col xs lg="12">
            <Grid columns={2}>
              <Grid.Column>
                <Portal onClose={this.handleClosePortal} open={openPortal}>
                  <Segment
                    style={{
                      left: '40%',
                      position: 'fixed',
                      top: '15%',
                      zIndex: 1000,
                    }}
                  >
                    <Header><Icon name='check circle' color="green" /> Update Berhasil...</Header>
                    <p>Selamat Ubah Foto Profil Berhasil.</p>
                  </Segment>
                </Portal>
              </Grid.Column>
            </Grid>
            <Sidebar.Pushable className="mPushable" as={Segment}>

              {/* Sidebar */}
              <Sidebar
                as={Menu}
                animation={animation}
                direction={direction}
                icon='labeled'
                vertical
                visible={visible}
                width='thin'
              >
                <Menu.Item>
                  {/* {(verified > 0) ? */}
                  {(dataIsComplete == 5) ?
                    <>
                      <div className="grid-box" onMouseOver={this.mouseOver.bind(this)} onMouseOut={this.mouseOut.bind(this)}>
                        {this.state.hover ?
                          (
                            <>
                              <Image htmlFor="embedpollfileinput"
                                src={change_img}
                                onError={(e) => addDefaultSrc(e)}
                                className="imgProfilePicCg" size='small' verticalAlign="middle" circular
                                href="#" onClick={changeImgProfile} title="Ubah Foto Profil" />
                              <input type="file" id="my_file" onChange={(event) => { this.onFileChange(event, 111) }} style={{ display: 'none' }} />
                            </>
                          ) :
                          <Image
                            src={img_profile}
                            onError={(e) => addDefaultSrc(e)}
                            className="imgProfilePic" size='small' verticalAlign="middle" circular />
                        }
                      </div>
                    </>
                    :
                    <div className="grid-box">
                      <Image
                        src={img_profile}
                        onError={(e) => addDefaultSrc(e)}
                        className="imgProfilePic" size='small' verticalAlign="middle" circular />
                    </div>
                  }
                  <h5 id="fonts" className="nama" style={{ marginTop: 20 }}>
                    {userinfo.nama_depan}
                    {userinfo.nama_tengah == null ? '' : userinfo.nama_tengah == '' ? '' : ' ' + userinfo.nama_tengah}
                    {userinfo.nama_belakang == null ? '' : userinfo.nama_belakang == '' ? '' : ' ' + userinfo.nama_belakang}
                  </h5>
                  <span id="fonts" className="namaID">ID : {userinfo.user_id}</span>
                  {/* <Header id="fonts" as='h5' textAlign='left' color="blue">KODE REFERRAL</Header>
              
                  {document.queryCommandSupported('copy') &&
                  <Input id="fonts" size="mini" className="inputRef"
                      ref={(refcode) => this.refCode = refcode}
                      action={{
                      color: 'blue',
                      labelPosition: 'right',
                      content: 'COPY',
                      icon: 'copy',
                      size:'mini',
                      onClick: () => this.copyToClipboard()
                      }}
                      defaultValue={userinfo.refferal_code}
                      onChange={this.handleInputChange}
                  />
                  } */}

                  <div id="fonts" className="informasiPribadi">
                    <Header id="fonts" textAlign='left' as='h5' color="blue">INFORMASI PRIBADI</Header>
                    <Header id="fonts" as='h6' color="blue" size='small'>
                      <Icon name='phone' />
                      <Header.Content>
                        Handphone
                        <Header.Subheader>{userinfo.no_hp}</Header.Subheader>
                      </Header.Content>
                    </Header>
                    <Header id="fonts" as='h6' color="blue" size='small'>
                      <Icon name='mail' />
                      <Header.Content>
                        Email
                        <Header.Subheader>{userinfo.email}</Header.Subheader>
                      </Header.Content>
                    </Header>
                    <Header id="fonts" as='h6' color="blue" size='small'>
                      <Icon name='map marker alternate' />
                      <Header.Content>
                        Alamat
                        <Header.Subheader>{userinfo.alamat}</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </div>

                  <Header id="fonts" textAlign='left' as='h5' color="blue">Status Akun :</Header>
                  {
                    dataIsComplete < 5 ?
                      <>
                        {/* <Header id="fonts" as='h6' color="blue" size='small'>
                        <Header.Content>
                          <Edit userinfo={userinfo} lengkapiData={lengkapiData} stateOptions={stateOptions} />
                          <span className="uploadKet">(Wajib)</span>
                        </Header.Content>
                      </Header> */}
                        <Header id="fonts" as='h6' textAlign='left' color="blue" size='small' style={{ fontSize: '11px', marginTop: '0px' }}>
                          <Header.Content>
                            Kelengkapan Data <span className="privyVerified">(Belum dilengkapi)</span>
                          </Header.Content>
                        </Header>
                      </>
                      : verified_advance < 3 ?
                        <Header id="fonts" as='h6' textAlign='left' color="blue" size='small' style={{ fontSize: '11px', marginTop: '0px' }}>
                          <Header.Content>
                            Kelengkapan Data <span className="privyVerified" style={{ color: 'orange' }}>(Sedang diverifikasi)</span>
                          </Header.Content>
                        </Header>
                        : verified_advance == 3 ?
                          <Header id="fonts" as='h6' textAlign='left' color="blue" size='small' style={{ fontSize: '11px', marginTop: '0px' }}>
                            <Header.Content>
                              Kelengkapan Data <span className="privyVerified" style={{ color: 'green' }}>(Terverifikasi)</span>
                            </Header.Content>
                          </Header>
                          : verified_advance == 5 ?
                            <Header id="fonts" as='h6' textAlign='left' color="blue" size='small' style={{ fontSize: '11px', marginTop: '0px' }}>
                              <Header.Content>
                                Kelengkapan Data <span className="privyVerified" style={{ color: 'red' }}>(Ditolak)</span>
                              </Header.Content>
                            </Header>
                            : <></>
                  }

                  <Header id="fonts" as='h6' textAlign='left' color="blue" size='small' style={{ fontSize: '11px', marginTop: '0px' }}>
                    <Header.Content>
                      Digital Signature
                      {
                        signature_status == null ? <span className="privyVerified"> (Belum terdaftar)</span> :
                          signature_status == '1' ? <span className="privyVerified" style={{ color: 'orange' }}> (Unverified)</span> :
                            signature_status == '2' ? <span className="privyVerified" style={{ color: 'green' }}> (Verified)</span> :
                              signature_status == '3' ? <span className="privyVerified" style={{ color: 'red' }}> (Expired)</span> :
                                <></>
                      }
                    </Header.Content>
                  </Header>

                  <Header id="fonts" as='h6' textAlign='left' color="blue" size='small' style={{ fontSize: '11px', marginTop: '0px' }}>
                    <Header.Content>
                      <a href='javascript:void(0)' onClick={handleDownloadAgreement}> Download Dokumen Perjanjian</a>
                    </Header.Content>
                  </Header>

                </Menu.Item>
              </Sidebar>

              {/* Menu Center */}
              <Sidebar.Pusher className="mContentUtama" dimmed={dimmed && visible} style={{ backgroundColor: '#eeeeee' }}>
                <Segment basic style={{ backgroundColor: 'white' }}>
                  <Container className="profileMobile">
                    <Row>
                      <Col md={4}>
                        {/* {(verified > 0) ? */}
                        {(dataIsComplete == 5) ?
                          <>
                            <div className="grid-box" onMouseOver={this.mouseOver.bind(this)} onMouseOut={this.mouseOut.bind(this)}>
                              {this.state.hover ?
                                (
                                  <>
                                    <Image htmlFor="embedpollfileinput" src={change_img} className="imgProfilePicCg" size='small' verticalAlign="middle" circular
                                      href="#" onClick={changeImgProfile} title="Ubah Foto Profil" />
                                    <input type="file" id="my_file" onChange={(event) => { this.onFileChange(event, 111) }} style={{ display: 'none' }} />
                                  </>
                                ) :
                                <Image src={img_profile} className="imgProfilePic" size='small' verticalAlign="middle" circular />
                              }
                            </div>
                          </>
                          :
                          <div className="grid-box">
                            <Image src={img_profile} className="imgProfilePic" size='small' verticalAlign="middle" circular />
                          </div>
                        }
                        <h4 id="fonts" className="nama" style={{ textAlign: 'center' }}>{userinfo.full_name}</h4>
                      </Col>
                      <Col md={4}>
                        <div id="fonts" className="informasiPribadi">
                          <span id="fonts" className="namaID">ID : {userinfo.user_id}</span>
                          <Header id="fonts" as='h6' color="blue" size='small'>
                            <Icon name='phone' />
                            <Header.Content>
                              Handphone
                              <Header.Subheader>{userinfo.no_hp}</Header.Subheader>
                            </Header.Content>
                          </Header>
                          <Header id="fonts" as='h6' color="blue" size='small'>
                            <Icon name='mail' />
                            <Header.Content>
                              Email
                              <Header.Subheader>{userinfo.email}</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </div>
                      </Col>
                      <Col md={4} >
                        <div id="fonts" className="informasiPribadi">
                          {/* {verified == 0 ? */}
                          {(dataIsComplete == 5) ?
                            (
                              <div><Header id="fonts" as='h6' color="blue" size='small'>
                                <Header.Content>
                                  <Edit userinfo={userinfo} lengkapiData={lengkapiData} stateOptions={stateOptions} />
                                  <span className="uploadKet">(Wajib)</span>
                                </Header.Content>
                              </Header>
                                <Button color='blue' size='mini' disabled style={{ marginTop: "30px" }}>Daftar Menjadi Pemodal </Button>
                              </div>
                            )
                            :
                            (
                              <div style={{ paddingTop: '10px' }}>
                                {verified_advance == 3 ?
                                  <>
                                    <Header id="fonts" as='h6' color="green" style={{ fontSize: '11px' }}>
                                      <Header.Content>
                                        Terverifikasi
                                        <Icon name="check" circular color='green' size='small' style={{ marginLeft: '85px' }}></Icon>
                                      </Header.Content>
                                    </Header>
                                    <Header id="fonts" as='h6' color="blue" size='small'>
                                      <Icon name='map marker alternate' />
                                      <Header.Content>
                                        Alamat
                                        <Header.Subheader>{userinfo.alamat}</Header.Subheader>
                                      </Header.Content>
                                    </Header>
                                    <Button color='blue' disabled size='mini' style={{ marginTop: "10px" }}>Daftar Menjadi Pemodal</Button>
                                  </>
                                  : verified_advance == 5 ?
                                    <>
                                      <Header id="fonts" as='h6' color="red" style={{ fontSize: '11px' }}>
                                        <Header.Content>
                                          Verifikasi Ditolak
                                          <Icon name="check" circular color='red' size='small' style={{ marginLeft: '60px' }}></Icon>
                                        </Header.Content>
                                      </Header>
                                      <Header id="fonts" as='h6' color="blue" size='small'>
                                        <Icon name='map marker alternate' />
                                        <Header.Content>
                                          Alamat
                                          <Header.Subheader>{userinfo.alamat}</Header.Subheader>
                                        </Header.Content>
                                      </Header>
                                      <Button color='blue' size='mini' disabled style={{ marginTop: "10px" }}>Daftar Menjadi Pemodal</Button>
                                    </>
                                    :
                                    <>
                                      <Header id="fonts" as='h6' color="yellow" style={{ fontSize: '11px' }}>
                                        <Header.Content>
                                          Proses Verifikasi
                                          <Icon name="check" circular color='yellow' size='small' style={{ marginLeft: '60px' }}></Icon>
                                        </Header.Content>
                                      </Header>
                                      <Header id="fonts" as='h6' color="blue" size='small'>
                                        <Icon name='map marker alternate' />
                                        <Header.Content>
                                          Alamat
                                          <Header.Subheader>{userinfo.alamat}</Header.Subheader>
                                        </Header.Content>
                                      </Header>
                                      <Button color='blue' size='mini' disabled style={{ marginTop: "10px" }}>Daftar Menjadi Pemodal</Button>
                                    </>
                                }
                              </div>
                            )
                          }
                        </div>
                      </Col>
                    </Row>
                  </Container>
                  <div className="paddingSpace profileMobile"></div>
                  <Container>
                    <Row>
                      <Col md={12}>
                        <Header id="fonts" as='h2' color='blue'>
                          <Image src={receiving_money} size='mini' />
                          <Header.Content>
                            <Header.Subheader>Saldo Efek</Header.Subheader>
                            Rp. {totalShareProfit} ,-
                          </Header.Content>
                        </Header>
                      </Col>
                      {/* <Col md={6} className="bgSisaPengelola"></Col> */}
                    </Row>
                  </Container>
                  <div className="paddingSpace"></div>
                  <Container>
                    <Row>
                      <Col md={12}>
                        <TabUserPengelola
                          userinfo={JSON.stringify(userinfo)}
                          verified={verified}
                          verified_advance={verified_advance}
                          dataIsComplete={dataIsComplete}
                          agreement={agreement}
                        />
                      </Col>
                    </Row>
                  </Container>
                </Segment>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </Col>
        </Row>
      </Container>
    )
  }
}

const Edit = memo((props) => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Perusahaan userinfo={props.userinfo} stateOptions={props.stateOptions} lengkapiData={props.lengkapiData} />
    </>
  );
})

function Sendiri(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [form, setForm] = useState({
    type: 'pribadi',
    nik_ktp: '',
    tempat_lahir: '',
    nama: '',
    jenis_kelamin: '',
    alamat: '',
    foto_ktp: '',
    selfie_ktp: '',
    npwp: '',
    no_rek: '',
    cabang_bank: '',
    data_bank: '',
    atas_nama_bank: '',
    buku_tabungan: '',
  });

  const handleTextChange = event => setForm({ ...form, [event.target.name]: event.target.value })

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  async function handleInputSubmit() {
    var formField = document.forms[0]
    var fotoKtp = formField.querySelector('input[name="foto_ktp"]').files[0]
    var fotoSelfie = formField.querySelector('input[name="selfie_ktp"]').files[0]
    var fotoNPWP = formField.querySelector('input[name="npwp"]').files[0]
    var bukuTabungan = formField.querySelector('input[name="buku_tabungan"]').files[0]

    if (fotoKtp == null || fotoKtp == "", fotoSelfie == null || fotoSelfie == "", fotoNPWP == null || fotoNPWP == "", bukuTabungan == null || bukuTabungan == "") {
      alert("Harap isi semua data dan lampirankan dokumen");
      return false;
    }

    if (fotoKtp) {
      form.foto_ktp = await toBase64(fotoKtp)
    }
    if (fotoSelfie) {
      form.selfie_ktp = await toBase64(fotoSelfie)
    }
    if (fotoNPWP) {
      form.npwp = await toBase64(fotoNPWP)
    }
    if (bukuTabungan) {
      form.buku_tabungan = await toBase64(bukuTabungan)
    }

    new SidebarUserPengelola().lengkapiData(form)
    // console.log('berhasil',form)
  }

  return (
    <>
      <a href="#" onClick={handleShow} className="btn my-4" style={{ background: "#214981", color: "#ffffff", padding: "5px 50px", borderRadius: "20px" }}>
        Pribadi
      </a>

      <Modal size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        show={show} onHide={handleClose} animation={false}>
        <Form name="formPribadi" style={{ padding: "20px" }} onSubmit={e => e.preventDefault()} encType="multipart/form-data"  >
          <h5>Data Sesuai Ktp</h5>
          {/* {JSON.stringify(form)} */}
          <Form.Group as={Row} controlId="ktp">

            <Col sm="6">
              <Form.Control type="text" name="nik_ktp" value={form.nik_ktp} onChange={handleTextChange} placeholder="NIK KTP" />
            </Col>
            <Col sm="6">
              <Form.Control type="text" name="tempat_lahir" value={form.tempat_lahir} onChange={handleTextChange} placeholder="Tempat Lahir" />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="nama">
            <Col sm="6">
              <Form.Control type="text" name="nama" value={form.nama} onChange={handleTextChange} placeholder="Nama" />
            </Col>
            <Col sm="6">
              <select className="custom-select" name="jenis_kelamin" value={form.jenis_kelamin} onChange={handleTextChange}>
                <option>Jenis Kelamin</option>
                <option>Laki-Laki</option>
                <option>perempuan</option>
              </select>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="alamat">
            <Col sm="12">
              <Form.Control type="text" name="alamat" value={form.alamat} onChange={handleTextChange} placeholder="Alamat" />
            </Col>

          </Form.Group>
          <Form.Group as={Row} controlId="foto_ktp">
            <Col sm="6">
              <Form.Label column sm="6" >
                Foto Ktp
              </Form.Label>
              <Form.Control type="file" name="foto_ktp" value={form.foto_ktp} onChange={handleTextChange} placeholder="Foto Ktp" />
            </Col>
            <Col sm="6">
              <Form.Label column sm="6">
                Selfie dengan Ktp
              </Form.Label>
              <Form.Control type="file" name="selfie_ktp" value={form.selfie_ktp} onChange={handleTextChange} placeholder="Selfie Pegang Ktp" />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="npwp">
            <Col sm="6">
              <Form.Label column sm="4">
                NPWP
              </Form.Label>
              <Form.Control type="file" name="npwp" value={form.npwp} onChange={handleTextChange} placeholder="NPWP" />
            </Col>
          </Form.Group>

          <h5>Data Bank</h5>
          <Form.Group as={Row} controlId="norek">
            <Col sm="6">
              <Form.Control type="text" name="no_rek" value={form.no_rek} onChange={handleTextChange} placeholder="No Rekening" />
            </Col>
            <Col sm="6">
              <Form.Control type="text" name="cabang_bank" value={form.cabang_bank} onChange={handleTextChange} placeholder="Cabang Bank" />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="data_bank">
            <Col sm="6">
              <select className="custom-select" name="data_bank" value={form.data_bank} onChange={handleTextChange}>
                <option>Bni Syariah</option>
              </select>
            </Col>
            <Col sm="6">
              <Form.Control type="text" name="atas_nama_bank" value={form.atas_nama_bank} onChange={handleTextChange} placeholder="Atas Nama" />
            </Col>
          </Form.Group>
          <Form.Group>
            <Col sm="6">
              <Form.Label>
                Buku Tabungan
              </Form.Label>
              <Form.Control type="file" name="buku_tabungan" value={form.buku_tabungan} onChange={handleTextChange} placeholder="Buku Tabungan" />
            </Col>
          </Form.Group>
          <Modal.Footer>
            <Button style={{ marginRight: "auto", marginLeft: "auto", padding: "5px 50px", borderRadius: "20px" }} type="button" onClick={handleInputSubmit} color="blue">
              Simpan
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

function Perusahaan(props) {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [form, setForm] = useState({
    type: 'perusahaan',
    nik_ktp: '',
    tempat_lahir: '',
    full_name: props.userinfo.full_name,
    jenis_kelamin: '',
    alamat: '',
    foto_ktp: '',
    selfie_ktp: '',
    akta: '',
    nib: '',
    sk_kemenkumham: '',
    no_rek: '',
    cabang_bank: '',
    npwp_perusahaan: '',
    ktp_pengurus: '',
    buku_tabungan: '',
    data_bank: 'Bni Syariah',
    atas_nama_bank: ''
  });

  const handleTextChange = event => setForm({ ...form, [event.target.name]: event.target.value })

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  async function handleInputSubmit() {

    var formField = document.forms[0]
    var fotoKtp = formField.querySelector('input[name="foto_ktp"]').files[0]
    var fotoSelfie = formField.querySelector('input[name="selfie_ktp"]').files[0]
    var fotoNPWPPerus = formField.querySelector('input[name="npwp_perusahaan"]').files[0]
    var bukuTabungan = formField.querySelector('input[name="buku_tabungan"]').files[0]
    var fotoAkta = formField.querySelector('input[name="akta"]').files[0]
    var fotoNib = formField.querySelector('input[name="nib"]').files[0]
    var fotoSkmenkumham = formField.querySelector('input[name="sk_kemenkumham"]').files[0]
    var fotoktp_pengurus = formField.querySelector('input[name="ktp_pengurus"]').files[0]

    if (fotoKtp) {
      var sizefotoKtp = formField.querySelector('input[name="foto_ktp"]').files[0].size
      var typefotoKtp = formField.querySelector('input[name="foto_ktp"]').files[0].type

      if (typefotoKtp != 'image/jpeg' && typefotoKtp != 'image/png' && typefotoKtp != 'image/jpeg' || sizefotoKtp > 2048000) {
        alert('Foto KTP harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB')
        return false;
      }
    } else {
      alert("Harap isi semua data dan lampirankan dokumen");
      return false;
    }

    if (fotoSelfie) {
      var sizeselfie_ktp = formField.querySelector('input[name="selfie_ktp"]').files[0].size
      var typeselfie_ktp = formField.querySelector('input[name="selfie_ktp"]').files[0].type

      if (typeselfie_ktp != 'image/jpeg' && typeselfie_ktp != 'image/png' && typeselfie_ktp != 'image/jpeg' || sizeselfie_ktp > 2048000) {
        alert('Foto Selfie harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB')
        return false;
      }
    } else {
      alert("Harap isi semua data dan lampirankan dokumen");
      return false;
    }

    if (fotoNPWPPerus) {
      var sizefotoNPWPPerus = formField.querySelector('input[name="npwp_perusahaan"]').files[0].size
      var typefotoNPWPPerus = formField.querySelector('input[name="npwp_perusahaan"]').files[0].type

      if (typefotoNPWPPerus != 'image/jpeg' && typefotoNPWPPerus != 'image/png' && typefotoNPWPPerus != 'image/jpeg' || sizefotoNPWPPerus > 2048000) {
        alert('Foto NPWP Perusahaan harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB')
        return false;
      }
    } else {
      alert("Harap isi semua data dan lampirankan dokumen");
      return false;
    }

    if (bukuTabungan) {
      var sizebukuTabungan = formField.querySelector('input[name="buku_tabungan"]').files[0].size
      var typebukuTabungan = formField.querySelector('input[name="buku_tabungan"]').files[0].type

      if (typebukuTabungan != 'image/jpeg' && typebukuTabungan != 'image/png' && typebukuTabungan != 'image/jpeg' || sizebukuTabungan > 2048000) {
        alert('Foto Buku Tabungan harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB')
        return false;
      }
    } else {
      alert("Harap isi semua data dan lampirankan dokumen");
      return false;
    }

    if (fotoAkta) {
      var sizefotoAkta = formField.querySelector('input[name="akta"]').files[0].size
      var typefotoAkta = formField.querySelector('input[name="akta"]').files[0].type

      if (typefotoAkta != 'image/jpeg' && typefotoAkta != 'image/png' && typefotoAkta != 'image/jpeg' || sizefotoAkta > 2048000) {
        alert('Foto Akta harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB')
        return false;
      }
    } else {
      alert("Harap isi semua data dan lampirankan dokumen");
      return false;
    }

    if (fotoNib) {
      var sizefotoNib = formField.querySelector('input[name="nib"]').files[0].size
      var typefotoNib = formField.querySelector('input[name="nib"]').files[0].type

      if (typefotoNib != 'image/jpeg' && typefotoNib != 'image/png' && typefotoNib != 'image/jpeg' || sizefotoNib > 2048000) {
        alert('Foto NIB harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB')
        return false;
      }
    } else {
      alert("Harap isi semua data dan lampirankan dokumen");
      return false;
    }

    if (fotoSkmenkumham) {
      var sizefotoSkmenkumham = formField.querySelector('input[name="sk_kemenkumham"]').files[0].size
      var typefotoSkmenkumham = formField.querySelector('input[name="sk_kemenkumham"]').files[0].type

      if (typefotoSkmenkumham != 'image/jpeg' && typefotoSkmenkumham != 'image/png' && typefotoSkmenkumham != 'image/jpeg' || sizefotoSkmenkumham > 2048000) {
        alert('Foto SK Kemenkumham harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB')
        return false;
      }
    } else {
      alert("Harap isi semua data dan lampirankan dokumen");
      return false;
    }

    if (fotoktp_pengurus) {
      var sizefotoktp_pengurus = formField.querySelector('input[name="ktp_pengurus"]').files[0].size
      var typefotoktp_pengurus = formField.querySelector('input[name="ktp_pengurus"]').files[0].type

      if (typefotoktp_pengurus != 'image/jpeg' && typefotoktp_pengurus != 'image/png' && typefotoktp_pengurus != 'image/jpeg' || sizefotoktp_pengurus > 2048000) {
        alert('Foto KTP Pengurus harus berupa Format JPEG, PNG, GIF dan ukuran Max 2MB')
        return false;
      }
    } else {
      alert("Harap isi semua data dan lampirankan dokumen");
      return false;
    }

    if (fotoKtp) {
      form.foto_ktp = await toBase64(fotoKtp)
    }
    if (fotoSelfie) {
      form.selfie_ktp = await toBase64(fotoSelfie)
    }
    if (fotoNPWPPerus) {
      form.npwp_perusahaan = await toBase64(fotoNPWPPerus)
    }
    if (bukuTabungan) {
      form.buku_tabungan = await toBase64(bukuTabungan)
    }
    if (fotoAkta) {
      form.akta = await toBase64(fotoAkta)
    }
    if (fotoNib) {
      form.nib = await toBase64(fotoNib)
    }
    if (fotoSkmenkumham) {
      form.sk_kemenkumham = await toBase64(fotoSkmenkumham)
    }
    if (fotoktp_pengurus) {
      form.ktp_pengurus = await toBase64(fotoktp_pengurus)
    }

    props.lengkapiData(form)
    // console.log('berhasil',form)
  }

  return (
    <>
      <Button color='blue' size='mini' className="btnDataDiri" onClick={handleShow}>Upload Data Diri</Button>

      <Modal size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        show={show} onHide={handleClose} animation={false}>
        <Form id="fonts" name="FormPerusahaan" style={{ padding: "20px" }} onSubmit={e => e.preventDefault()} encType="multipart/form-data" >
          <Alert id="fonts" className="alertSuccessSubmit" variant="info" show={true} style={{ display: 'none' }}>
            <center>Terimakasih, data anda sedang kami proses...</center>
          </Alert>
          <h5 id="fonts" >Data Penanggung Jawab</h5>
          <Form.Group as={Row} controlId="ktp">
            <Col sm="6">
              <Form.Control type="text" name="nik_ktp" defaultValue={form.nik_ktp} onChange={handleTextChange} placeholder="NIK KTP" />
            </Col>
            <Col sm="6">
              <Form.Control type="text" name="tempat_lahir" defaultValue={form.tempat_lahir} onChange={handleTextChange} placeholder="Tempat Tanggal Lahir" />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="nama">
            <Col sm="6">
              <Form.Control type="text" name="full_name" defaultValue={form.full_name} onChange={handleTextChange} placeholder="Nama" />
            </Col>
            <Col sm="6">
              <select className="custom-select" name="jenis_kelamin" defaultValue={form.jenis_kelamin} onChange={handleTextChange}>
                <option>Jenis Kelamin</option>
                <option>Laki-Laki</option>
                <option>perempuan</option>
              </select>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="alamat">
            <Col sm="12">
              <Form.Control type="text" name="alamat" defaultValue={form.alamat} onChange={handleTextChange} placeholder="Alamat" />
            </Col>
          </Form.Group>


          <h5 id="fonts" >Data Bank</h5>
          <Form.Group as={Row} controlId="norek">
            <Col sm="6">
              <Form.Control type="text" name="no_rek" defaultValue={form.no_rek} onChange={handleTextChange} placeholder="No Rekening" />
            </Col>
            <Col sm="6">
              <Form.Control type="text" name="cabang_bank" defaultValue={form.cabang_bank} onChange={handleTextChange} placeholder="Cabang Bank" />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="data_bank">
            <Col sm="6">
              <Dropdown
                onChange={(e, { value }) => form.data_bank = value}
                name="data_bank"
                placeholder='Pilih Bank' style={{ width: '100%' }}
                closeOnChange search selection options={props.stateOptions} />
            </Col>
            <Col sm="6">
              <Form.Control type="text" name="atas_nama_bank" defaultValue={form.atas_nama_bank} onChange={handleTextChange} placeholder="Atas Nama" />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="foto_ktp">
            <Col sm="6">
              <Form.Label column sm="6" >
                Foto Ktp
              </Form.Label>
              <Form.Control type="file" name="foto_ktp" defaultValue={form.foto_ktp} onChange={handleTextChange} placeholder="Foto Ktp" />
            </Col>
            <Col sm="6">
              <Form.Label column sm="6">
                Selfie dengan Ktp
              </Form.Label>
              <Form.Control type="file" name="selfie_ktp" defaultValue={form.selfie_ktp} onChange={handleTextChange} placeholder="Selfie Pegang Ktp" />
            </Col>
          </Form.Group>

          <p>&nbsp;</p>
          <h5 id="fonts" >Data Perusahaan / Badan Hukum *(Wajib Diisi)</h5>
          <Form.Group as={Row} controlId="akta">
            <Col sm="6">
              <Form.Label column sm="12">
                {/* Akta */}
                Akta Pendirian Perusahan & Anggaran Dasar
              </Form.Label>
              <Form.Control type="file" name="akta" defaultValue={form.akta} onChange={handleTextChange} />
            </Col>
            <Col sm="6">
              <Form.Label column sm="12">
                {/* NIB */}
                Company Profile, TDP/NIB, SIUP, NPWP
              </Form.Label>
              <Form.Control type="file" name="nib" defaultValue={form.nib} onChange={handleTextChange} placeholder="NIB" />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="sk">
            <Col sm="6">
              <Form.Label column sm="12">
                {/* SK Kemenkumham */}
                Anggaran Dasar Perubahan Terbaru
              </Form.Label>
              <Form.Control type="file" name="sk_kemenkumham" defaultValue={form.sk_kemenkumham} onChange={handleTextChange} placeholder="Sk Kemenkumham" />
            </Col>
            <Col sm="6">
              <Form.Label column sm="12">
                {/* Foto NPWP Perusahaan */}
                Laporan Keuangan (3 Tahun Terakhir)
              </Form.Label>
              <Form.Control type="file" name="npwp_perusahaan" defaultValue={form.npwp_perusahaan} onChange={handleTextChange} placeholder="NPWP" />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="pengurus">
            <Col sm="6">
              <Form.Label column sm="12">
                {/* Foto Ktp Pengurus */}
                Copy Identitas Manajemen (KTP, NPWP)
              </Form.Label>
              <Form.Control type="file" name="ktp_pengurus" defaultValue={form.ktp_pengurus} onChange={handleTextChange} placeholder="Ktp Pengurus" />
            </Col>
            <Col sm="6">
              <Form.Label column sm="12">
                {/* Buku Tabungan */}
                Data mengenai bisnis dan proyeksi pendapatan
              </Form.Label>
              <Form.Control type="file" name="buku_tabungan" defaultValue={form.buku_tabungan} onChange={handleTextChange} placeholder="Buku Tabungan" />
            </Col>
          </Form.Group>

          <Modal.Footer>
            <Button id="fonts" className="btnSimpan" style={{ marginRight: "auto", marginLeft: "auto", padding: "5px 50px", borderRadius: "20px" }} color="blue" type="button" onClick={handleInputSubmit}>
              Simpan
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}