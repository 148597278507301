import React, { useState } from 'react';
import { connect } from "react-redux";
import { compose } from "redux";
import Navbar from "../../components/navbar_home";
import Footer from "../../components/Footer";
import Pemodal from "../../assets/img/pemodal.png";
import "../../App.scss";



class Simulasi extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.isLoading = true;
        console.log(this.props);
    }

    render() {

        return (
            <div>
                <Navbar />
                <Body />
                <Lanjutan />
                <Footer />
            </div>
        );

    }

}
function Body() {
    return (
        <div className="container" style={{ marginTop: "150px" }}>
            <div className="row">
                <div className="col-lg" id="simulasi">
                    <h2 style={{ fontSize: "40px", fontWeight: "700", color: "#033C85", fontFamily: 'Helvetica' }}>SIMULASI PEMBAYARAN</h2>
                    <img src="https://i.ibb.co/7Gyd8xX/image6.png" className="img-fluid mt-5" alt="" />
                    <div className="conten-info">
                        <h4 style={{ fontSize: "26px", fontWeight: "700", color: "#58595B", marginTop: "50px", fontFamily: 'Helvetica' }}>1. Pembiayaan proyek menggunakan Akad Musyarakah</h4>
                        <p style={{ color: "#58595B", fontSize: "18px", fontFamily: "helvetica", fontWeight: "450", fontFamily: 'Helvetica' }}>Penerbit ingin melakukan pengembangan proyek propertinya pada lahan yang telah ia miliki
                            dengan kebutuhan dana Rp 100juta. Namun, <br /> Penerbit hanya memiliki dana sebesar Rp
                            40juta. <br /> <br /> Penerbit ingin dicarikan Pemodal yang mampu membayarkan kekurangan modal
                            sebesar Rp 60juta, maka Penerbit mengajukan pembiayaan proyek melalui platform
                            Kerjasama.co.id. Penerbit menawarkan pembagian keuntungan dengan Pemodal melalui platform
                            Kerjasama.co.id. <br /> <br /> 1. Modal awal Penerbit: Rp 40juta  (≈40%)  <br /> 2. Modal yang akan
                            didapatkan dari Pemodal: Rp 60juta  (≈60%)  <br /> 3. Jangka waktu kerjasama: 1 tahun <br />
                            4.Gambaran Resiko:
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}


function Lanjutan() {
    return (
        <div className="container">
            <table className="table table-bordered ">
                <thead>
                    <tr className="table-success text-left">
                        <th>Resiko Penerbit</th>
                        <th>Resiko Pemodal</th>
                    </tr>
                </thead>
                <tbody className="text-left">
                    <tr>
                        <td>1. Penyertaan Modal</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>2. Skill Penerbit</td>
                        <td id="Modal">1. Penyertaan Modal</td>

                    </tr>
                    <tr>
                        <td>3. Pekerjaan Lapangan</td>
                        <td></td>

                    </tr>
                </tbody>
            </table>
            <p className="text-italic">(Apa itu akad Musyarakah? Silahkan cari di kolom Sharia Corner)</p>
            <br /><br />
            <div className="simulasi-info">
                <h4 style={{ fontSize: "26px", fontWeight: "700", color: "#58595B", fontFamily: 'Helvetica' }}>2. SIMULASI BAGI KEUNTUNGAN</h4>
                <p>Perhitungan Bagi hasil sebagai berikut :</p>
                <ul className="simulasi-ul">
                    <li>Dengan mempertimbangkan jumlah resiko Penerbit lebih besar dari resiko Pemodal, maka
                        presentase Bagi Hasil yang disepakati adalah 60% Penerbit, 40% Pemodal</li>
                    <li>Income proyek: Rp 150juta</li>
                    <li>Keuntungan: Rp (150-100)juta = Rp 50juta</li>
                </ul>

                <p>Dengan pembagian keuntungan Penerbit : Pemodal = 60%:40% maka secara umum :</p>
                <ul className="simulasi-ul">
                    <li>Ujroh wajib dibayarkan kepada penyelenggara/platform Kerjasama.co.id setelah modal Rp
                        60juta dari Pemodal terkumpul di rekening Escrow milik platform, agar modal dapat
                        di-disburse ke dompet Penerbit. <br /><br />Jika Penerbit tidak sanggup membayar Ujroh
                        setelah modal terkumpul di Escrow, maka Penerbit wajib membayar Ujroh saat selesainya
                        proyek menggunakan akad Qardh. <br /><br />Ujroh wajib dibayarkan terlepas dari apakah
                        proyek untung ataupun rugi. Besaran Ujroh yang dibayarkan adalah 5% dari modal yang
                        diperoleh Penerbit; 5% x Rp 60juta = Rp 3juta. (Definisi Ujroh dan Qardh dapat Anda
                        pelajari di Sharia Corner. DefinisiEscrow dan Dompet dapat Andacari di FAQ)</li><br />
                    <li>
                        Pemodal dalam pembiayaan ini ada 2 orang, dimana modal yang diberikan : <br />a Pemodal
                        A: 45 slot ( Rp 45juta ) ≈ 75% total modal terkumpul <br /> b Pemodal B: 15 slot ( Rp
                        15juta ) ≈ 25% total modal terkumpul
                    </li>
                </ul> <br /><br />
                <p>Maka, pembagian keuntungan bersih (sebelum dipotong pajak) sebagai berikut:</p>
                <ul className="simulasi-ul">
                    <li>Penerbit: Rp (30-3)juta = Rp 27juta ≈ 67,5%</li>
                    <li>Pemodal: Rp 20juta, dibagikan pada 2 Pemodal dengan pembagian : <br />a Pemodal A:
                        75% x Rp 20juta = Rp 15juta <br /> b Pemodal B: 25% x Rp 20juta = Rp 5juta</li>
                </ul> <br /><br /><br />
                <div className="bagi-rugi-info">
                    <h4 style={{ fontSize: "26px", fontWeight: "700", color: "#58595B", fontFamily: 'Helvetica' }}>3. Simulasi Bagi Kerugian</h4>
                    <p>Sedangkan, apabila proyek mengalami kerugian maka Penerbit dan Pemodal sama-sama wajib
                        menanggung kerugian. Hal ini berdasarkan hadist: Dari Abdullah bin Amr bin Ash radhiyallahu
                        ‘anhu, Nabi shallallahu ‘alaihi wa sallam bersabda, <br /><br />“Tidak boleh ada keuntungan
                        tanpa menanggung resiko kerugian.” <br />
                        HR. Ahmad 6831, Nasa’i 4647, dan di-shahihkan al-Albani <br /><br /> Artikel selengkapnya
                        dapat Anda pelajari di Blog melalui link <a
                            href="https://pengusahamuslim.com/5108-kaidah-jual-beli-bagian-14-keuntungan-berbanding-dengan-resiko.html"
                            style={{ textDecoration: "none" }}>https://pengusahamuslim.com/5108-kaidah-jual-beli-bagian-14-keuntungan-berbanding-dengan-resiko.html
                        </a> <br /><br />Perhitungan Bagi Rugi sebagai berikut : </p>
                    <ul className="simulasi-ul">
                        <li>Dengan mempertimbangkan jumlah penyertaan modal di awal, dimana Penerbit menyertakan
                            modal Rp 40juta  ≈40%  dan total penyertaan modal Pemodal sebesar Rp 60juta  ≈60% ,
                            maka presentase Bagi Rugi yang disepakati adalah 40% Penerbit, 60% Pemodal.</li>
                        <li>Income proyek: Rp 80juta dibawah nilai proyek Rp 100juta</li>
                        <li>Kerugian: Rp 80-100juta = Rp 20juta</li>
                        <br /><br />
                    </ul>
                    <p>
                        Dengan pembagian kerugian Penerbit:Pemodal = 40%:60%, maka:</p>
                    <ul className="simulasi-ul">
                        <li>Kerugian yang ditanggung Penerbit: 40% x Total Kerugian = Rp 8juta</li>
                        <li>Kerugian yang ditanggung Pemodal: 60% x Total Kerugian = Rp 12juta, dengan pembagian
                            : <br />a Pemodal A menanggung: 75% x Rp 12juta = Rp 9juta <br />b Pemodal B
                            menanggung: 25% x Rp 12juta = Rp 3juta</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Simulasi;

