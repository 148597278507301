import { combineReducers } from 'redux';

const getUserpemodal = (state = {}, action) => {
  switch (action.type) {

    case "GET_USER_PEMODAL_LOADING":
      return {
        isLoading: true,
        isError: false,
        isSuccess: false,
        user: null,
        errors: null
      }

    case "GET_USER_PEMODAL_SUCCESS":
      return {
        isLoading: false,
        isError: false,
        isSuccess: true,
        user: action.payload.result,
        errors: null
      }

    case "GET_USER_PEMODAL_FAIL":
      return {
        isLoading: false,
        isError: true,
        isSuccess: false,
        user: null,
        errors: action.payload
      }

    default:
      return state
  }
}
const getUserpengelola = (state = {}, action) => {
  switch (action.type) {

    case "GET_USER_PENGELOLA_LOADING":
      return {
        isLoading: true,
        isError: false,
        isSuccess: false,
        user: null,
        errors: null
      }

    case "GET_USER_PENGELOLA_SUCCESS":
      return {
        isLoading: false,
        isError: false,
        isSuccess: true,
        user: action.payload.result,
        errors: null
      }

    case "GET_USER_PENGELOLA_FAIL":
      return {
        isLoading: false,
        isError: true,
        isSuccess: false,
        user: null,
        errors: action.payload
      }

    default:
      return state
  }
}

const getUser = (state = {}, action) => {
    switch (action.type) {

      case "GET_USER_LOADING":
          return {
            isLoading: true,
            isError: false,
            isSuccess: false,
            user: null,
            errors: null
          }

      case "GET_USER_SUCCESS":
          return {
            isLoading: false,
            isError: false,
            isSuccess: true,
            user: action.payload.result,
            errors: null
          }

      case "GET_USER_FAIL":
          return {
            isLoading: false,
            isError: true,
            isSuccess: false,
            user: null,
            errors: action.payload
          }

      default:
        return state
    }
}


const verifikasiUser = (state = {}, action) => {
  switch (action.type) {

    case "GET_VERIFIKASI_LOADING":
      return {
        isLoading: true,
        isError: false,
        isSuccess: false,
        errors: null
      }

    case "GET_VERIFIKASI_SUCCESS":
      return {
        isLoading: false,
        isError: false,
        isSuccess: true,
        errors: null
      }

    case "GET_VERIFIKASI_FAIL":
      return {
        isLoading: false,
        isError: true,
        isSuccess: false,
        errors: action.payload
      }

    default:
      return {
        isLoading: false,
        isError: false,
        isSuccess: false,
        errors: null
      }
  }
}

const regUser = (state = {}, action) => {
  switch (action.type) {

    case "REGISTER_USER_LOADING":
      return {
        isLoading: true,
        isError: false,
        isSuccess: false,
        userDetails: null,
        errors: null
      }

    case "REGISTER_USER_SUCCESS":
      return {
        isLoading: false,
        isError: false,
        isSuccess: true,
        userDetails: action.payload,
        errors: null
      }

    case "REGISTER_USER_FAIL":
      return {
        isLoading: false,
        isError: true,
        isSuccess: false,
        userDetails: null,
        errors: action.payload
      }

    default:
      return state
  }
}

export default combineReducers({
  getUserpemodal, getUserpengelola, getUser, regUser, verifikasiUser
});
