import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
// import { Router, Route } from 'react-router-dom'
import "./assets/bootstrap/css/bootstrap.min.css"
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/fonts/font-awesome.min.css"
import "./assets/fonts/ionicons.min.css"
import "./assets/css/Dark-Footer.css"
import "./assets/css/Filter.css"
import "./assets/css/navigasi.css"
import "./assets/css/styles.css"

import Routes from "./components/Routes";
import Main from "./Main";

import persist from "./config/store";
const persistStore = persist();

export default class App extends React.Component {
  render() {
    return (
      <Provider store={persistStore.store}>
        <PersistGate loading={null} persistor={persistStore.persistor}>
          <Main />
        </PersistGate>
      </Provider>
    );
  }
}
