import React from 'react';
import { connect } from "react-redux";
import { compose } from "redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";


import Home from '../pages/landing/Home';
import Selesai from '../pages/landing/Selesai';
import Api from '../service/Api_home';
import UserPemodal from '../pages/dashboards/pemodal/UserPemodal';
import UserPengelola from '../pages/dashboards/penerbit/UserPengelola';
import Detail from '../pages/landing/Detail';
import Signin from '../pages/landing/Signin';
import Reset_password_user from '../pages/landing/Reset_password';
import Lupa_password from '../pages/landing/Lupa_password';
import DaftarPengelola from '../pages/dashboards/penerbit/Daftarpengelola';
import DaftarPemodal from '../pages/dashboards/pemodal/Daftarpemodal';
import ErrorPage from '../pages/landing/ErrorPage';
import Panduan from '../pages/landing/Panduan';
import Pengaduan from '../pages/landing/Pengaduan';
import Simulasi from '../pages/landing/Simulasi';
import Tentang from '../pages/landing/Tentangkami';
import Faq from '../pages/landing/Faq';
import BayarStep1 from '../pages/dashboards/pemodal/BayarStep1';
import BayarStep2 from '../pages/dashboards/pemodal/BayarStep2';
import BayarStepVA from '../pages/dashboards/pemodal/BayarStepVA';
import NotFound from '../pages/landing/NotFound';
import VerifikasiOTP from '../pages/VerifikasiOTP'
import ProfilePemodal from "../pages/dashboards/pemodal/ProfilePemodal"
import LengkapiDataPemodal from "../pages/dashboards/pemodal/LengkapiDataPemodal"
import ProfilePenerbit from "../pages/dashboards/penerbit/ProfilePenerbit"
import LengkapiDataPenerbit from "../pages/dashboards/penerbit/LengkapiDataPenerbit"
import TermsCondition from '../pages/landing/TermsCondition';
import PrivacyPolicy from '../pages/landing/PrivacyPolicy';


function PrivateRoute({ component: Component, authed, ...rest }) {
  let urlElelement = window.location.href.split('#')
  let pathUrl = '/Signin'
  if (urlElelement[1]) {
    pathUrl = '/Signin/#' + urlElelement[1]
  }

  return (
    <Route
      {...rest}
      render={(props) => authed === true
        ? <Component {...props} />
        : <Redirect to={{ pathname: pathUrl, state: { from: props.location } }} />}
    />
  )
}

class Routes extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path='/detail/:id_project' component={Detail} />
          <Route path='/home/selesai' component={Selesai} />
          <Route path='/Panduan' component={Panduan} />
          <Route path='/Pengaduan' component={Pengaduan} />
          <Route path='/Simulasi' component={Simulasi} />
          <Route path='/Signin' component={Signin} />
          <Route path='/Daftarpengelola' component={DaftarPengelola} />
          <Route path='/Daftarpemodal' component={DaftarPemodal} />
          <Route path='/Tentang' component={Tentang} />
          <Route path='/Api' component={Api} />
          <Route path='/error' component={ErrorPage} />
          <Route path='/Lupa-password' component={Lupa_password} />
          <Route path='/Reset-password-user' component={Reset_password_user} />
          <Route path='/Pembayaran/ProyekTerpilih' component={BayarStep1} />
          <Route path='/Pembayaran/MetodePembayaran' component={BayarStep2} />
          <Route path='/Pembayaran/TagihanVA' component={BayarStepVA} />
          <Route path='/Faq' component={Faq} />
          <Route path="/Verifikasi" component={VerifikasiOTP} />
          <PrivateRoute authed={this.props.auth.isLoggedInPengelola} path='/Userpengelola' component={UserPengelola} />
          <PrivateRoute authed={this.props.auth.isLoggedInPengelola} path='/Profilepenerbit' component={ProfilePenerbit} />
          <PrivateRoute authed={this.props.auth.isLoggedInPengelola} path='/Lengkapidatapenerbit' component={LengkapiDataPenerbit} />
          <PrivateRoute authed={this.props.auth.isLoggedInPemodal} path='/Userpemodal' component={UserPemodal} />
          <PrivateRoute authed={this.props.auth.isLoggedInPemodal} path='/Profilepemodal' component={ProfilePemodal} />
          <PrivateRoute authed={this.props.auth.isLoggedInPemodal} path='/Lengkapidatapemodal' component={LengkapiDataPemodal} />
          <Route path='/SyaratDanKetentuan' component={TermsCondition} />
          <Route path='/KebijakanPrivasi' component={PrivacyPolicy} />
          <Route path='/document-sign' component={Home} />
          <Route path='/register-sign' component={Home} />
          <Route component={NotFound} />
        </Switch>
      </Router >
    );
  }

}

function mapStateToProps(state) {
  return { auth: state.authReducer.authData }
}

function mapDispatchToProps(dispatch) {
  return { dispatch }
}


export default connect(mapStateToProps, mapDispatchToProps)(Routes);