/* eslint-disable no-unused-expressions */
import React, { Component, useState } from 'react'
import { Container, Row, Col, Tabs, Tab, Form, Modal as ModalForm } from 'react-bootstrap'
import seru from "../../../assets/img/seru.png";
import seru2 from "../../../assets/img/Group 895.png";
import proyk from "../../../assets/img/Group 863.png";
import proykpengumpulandana from "../../../assets/img/proyek/pengumpulan dana.svg";
import proykfullpendanaan from "../../../assets/img/proyek/Full pendanaan.svg";
import proykproyekbrjln from "../../../assets/img/proyek/Proyek Berjalan.svg";
import proykproyekslsai from "../../../assets/img/proyek/Proyek Selesai.svg";
import proykbghsl from "../../../assets/img/proyek/Pembagian Hasil.svg";
import proykslsai from "../../../assets/img/proyek/Selesai.svg";
import dompet from "../../../assets/img/dompet.png";
import bali from "../../../assets/img/bali.png";
import { BASE_URL } from "../../../service/api";
import '../../../App.scss'
import DetailDompet from '../../DetailDompet'
import { BallBeat } from 'react-pure-loaders';
import axios from 'axios'
import BayarStep1 from './BayarStep1'
import {
    // BrowserRouter as Router,
    // Switch,
    // Route,
    // Redirect,
    Link,
    withRouter
} from "react-router-dom";
import $ from 'jquery'
import {
    Button, Message, Pagination,
    Checkbox, Progress,
    Grid, Dropdown,
    // Form,
    Modal, Table,
    Card, Breadcrumb,
    Header,
    Icon,
    Image,
    Menu,
    Segment,
    Sidebar,
    Input,
    Label,
    List,
    StatisticValue,
} from 'semantic-ui-react'
import swal from 'sweetalert'
import { CameraFeed } from '../../../components/CameraFeed';

class TabUserPemodal extends Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            basefotoKtp: '',
            baseselfieKtp: '',
            basenpwp: '',
            basenpwpperusahaan: '',
            baseakta: '',
            basenib: '',
            basesk_kemenkumham: '',
            basektp_pengurus: '',
            basebuku_tabungan: '',
            hideDiv: 'close', select: '', statusTr: '',
            hideDivBayarSekarang: 'close',
            hideDivProyekBrjln: 'close',
            hideDivPortofolio: 'close',
            dataListPaymentVA: [],
            dataProyekTersimpan: [], dataProyekBerjalan: [], datasearchListPaymentVA: [],
            favProyekTersimpan: [], dataitemPVA: [], filterListPaymentVA: [],
            dataListTransaction: [],
            alertsuccess: 'test', isFilter: false, fileMusyarakah: {}, dataMusyarakah: [],
            openTandaTangan: false, datadetailMusyarakah: [], disabledUplMusy: 'disabled',
            dataSlot: [],
            itemsDompet: [],
            totalSlot: 0,
            totalProyek: 0,
            totalPembelian: 0,
            valcheckAll: false,
            dataChecked: [],
            paramsChecked: [],
            open: false,
            closeOnEscape: false,
            closeOnDimmerClick: false,
            activeIndex: null, 
            datadetailProyekBrjln: [],
            begin: 0,
            end: 4,
            activePage: 1,
            showModalAgreement: false,
            agreementContent: '',
            showModalRegisterSigned: false,
            fileRegisterSigned: { foto_selfie: '', foto_ktp: '' },
            errorMessage: {},
            directPage: {}
        };
    }

    timeBetweenDates(startDate, toDate) {
        var dateEntered = new Date(toDate);
        var now = new Date();
        var difference = dateEntered.getTime() - now.getTime();

        var seconds = Math.floor(difference / 1000);
        var minutes = Math.floor(seconds / 60);
        var hours = Math.floor(minutes / 60);
        var days = Math.floor(hours / 24);

        var bataspendaftaran = 'Berakhir'
        hours %= 24;
        minutes %= 60;
        seconds %= 60;
        if (days == 0) {
            bataspendaftaran = 'Sampai Hari Ini'
            return bataspendaftaran
        } else if (days < 0) {
            bataspendaftaran = 'Berakhir'
            return bataspendaftaran
        } else {
            bataspendaftaran = days + ' Hari Lagi'
            return bataspendaftaran
        }
    }

    PaginationClick = (e, { activePage }) => {

        if (activePage == 1) { this.setState({ begin: 0, end: 4 }) } else if (activePage == 2) { this.setState({ begin: 4, end: 8 }) } else if (activePage == 3) {
            this.setState({ begin: 8, end: 12 })
        } else if (activePage == 4) { this.setState({ begin: 12, end: 16 }) } else if (activePage == 5) { this.setState({ begin: 16, end: 20 }) } else if (activePage == 6) {
            this.setState({ begin: 20, end: 24 })
        } else if (activePage == 7) { this.setState({ begin: 24, end: 28 }) } else if (activePage == 8) { this.setState({ begin: 28, end: 32 }) } else if (activePage == 9) {
            this.setState({ begin: 32, end: 36 })
        } else if (activePage == 10) { this.setState({ begin: 36, end: 40 }) } else if (activePage == 11) { this.setState({ begin: 40, end: 44 }) } else if (activePage == 12) {
            this.setState({ begin: 44, end: 48 })
        } else if (activePage == 13) { this.setState({ begin: 48, end: 52 }) } else if (activePage == 14) { this.setState({ begin: 52, end: 56 }) } else if (activePage == 15) {
            this.setState({ begin: 56, end: 60 })
        } else if (activePage == 16) { this.setState({ begin: 60, end: 64 }) } else if (activePage == 17) { this.setState({ begin: 64, end: 68 }) } else if (activePage == 18) {
            this.setState({ begin: 68, end: 72 })
        } else if (activePage == 19) { this.setState({ begin: 72, end: 76 }) } else if (activePage == 20) { this.setState({ begin: 76, end: 80 }) }

        this.setState({ activePage })
        this.getListPembayaranVA()
    }

    getData() {
        var queryString = window.location.search;
        var urlParams = new URLSearchParams(queryString);
        var tabs = urlParams.get('tabs')
        if (tabs) {
            this.setState({
                activeIndex: tabs,
            })
        } else {

            let urlElelement = window.location.href.split('#')
            if (urlElelement[1] === 'listproject') {
                if (this.props.dataIsComplete == 5 && this.props.verified_advance == 3) {
                    this.setState({
                        activeIndex: 'buatproyek',
                    })
                } else {
                    this.setState({
                        activeIndex: 'informasi'
                    })
                }
            } else {
                if (this.props.dataIsComplete == 5 && this.props.verified_advance == 3 && this.props.agreement == 1) {
                    this.setState({
                        activeIndex: 'buatproyek',
                    })
                } else {
                    this.setState({
                        activeIndex: 'informasi'
                    })
                }
            }
        }

        axios.get(BASE_URL + "/pendanaanproyek", {
            params: {
                tag: 'terbaru',
                limit: 10
            }
        },
            axios.defaults.headers.common['Authorization'] = localStorage.token,
            axios.defaults.headers.common['Accept'] = 'application/json'
        )
            .then((resp) => {
                this.setState({
                    items: resp.data.data,
                })
            })
            .catch((resp) => {

            });
    }

    show = (size) => () => {
        this.setState({ size, open: true, })
        // setTimeout(()=>{$("#pin").focus()}, 1);
    }

    onFileChange(e, param, funding_id) {
        var files = e.target.files;

        if (!files.length) {
            this.setState({
                fileMusyarakah: {},
                disabledUplMusy: 'disabled'
            })
        } else {
            return this.createImage(files[0], param, funding_id, files.length);
        }
    }

    createImage(file, param, funding_id, length) {
        var tmp = [];
        var reader = new FileReader();

        reader.onload = e => {
            if (param == 99) {
                var dtpost = {
                    "funding_id": funding_id,
                    "file": file.name.slice(0, 11)
                }
                this.setState({
                    fileMusyarakah: dtpost,
                })
            }
        };
        reader.readAsDataURL(file);
    }

    close = () => this.setState({ open: false })

    clearConsole() {
        if (window.console || window.console.firebug) {
            console.clear();
        }
    }

    getDompet() {
        axios.get(BASE_URL + "/customer/dompet",
            axios.defaults.headers.common['Authorization'] = localStorage.token,
            axios.defaults.headers.common['Accept'] = 'application/json'
        )
            .then((resp) => {
                if (resp.data.data) {
                    this.setState({
                        itemsDompet: resp.data.data,
                    })
                }
            })
            .catch((resp) => { });
    }

    getListPembayaranVA() {
        const userinfo = JSON.parse(this.props.userinfo)

        axios.get(BASE_URL + "/customer/pemodal/pymntpemodal", {
            params: {
                user_id: userinfo.user_id,
            }
        },
            axios.defaults.headers.common['Authorization'] = localStorage.token,
            axios.defaults.headers.common['Accept'] = 'application/json'
        )
            .then((resp) => {
                var tmpData = []

                resp.data.data.map((a, i) => {
                    
                    var date = new Date();
                    date.setDate(date.getDate());
                    var day = ('0' + date.getDate()).slice(-2);
                    var month = ('0' + (date.getMonth() + 1)).slice(-2);
                    var yy = date.getYear();
                    var hours = ('0' + date.getHours()).slice(-2);
                    var minutes = ('0' + date.getMinutes()).slice(-2);
                    var seconds = ('0' + date.getSeconds()).slice(-2);
                    var year = (yy < 1000) ? yy + 1900 : yy;

                    var tgl = year + '-' + month + '-' + day + ' ' + hours + ":" + minutes + ":" + seconds

                    console.log("DATE_NOW >> " + tgl)
                    console.log("EXPIRED >> " + a.datetime_expired)

                    // if(date2 > a.payment_date){
                    //     console.log("Tidak bisa cancel")
                    // } else {
                    //     console.log("bisa cancel")
                    // }

                    if (a.va_status == 0) {
                        if (tgl > a.datetime_expired) {
                            a.statusPVA = 'Kadaluarsa'
                            a.isCancel = false
                        } else {
                            a.statusPVA = 'Menunggu Pembayaran'
                            a.isCancel = true
                        }
                    } else if (a.va_status == 1 && this.state.dataProyekBerjalan[i].proyek_overview.status == 5 || this.state.dataProyekBerjalan[i].proyek_overview.status == 6) {
                        a.statusPVA = 'Masa Penawaran'
                        a.isCancel = true

                        //Compare tanggal untuk cancel
                        if (a.payment_date != null) {
                            var date1 = new Date(tgl);
                            // var date1 = new Date('2023-03-09 09:59:57');
                            var date2 = new Date(a.payment_date);

                            // To calculate the time difference of two dates
                            var Difference_In_Time = date1.getTime() - date2.getTime();

                            // To calculate the no. of days between two dates
                            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

                            console.log("Difference_In_Days >> " + Difference_In_Days)

                            if (Difference_In_Days > 2) {
                                a.isCancel = true
                            } else {
                                a.isCancel = false
                            }
                        }
                    } else if (a.va_status == 1 && this.state.dataProyekBerjalan[i].proyek_overview.status == 7 || this.state.dataProyekBerjalan[i].proyek_overview.status == 8) {
                        a.statusPVA = 'Full Pendanaan'
                        a.isCancel = false
                        // axios.get(BASE_URL+"/customer/pemodal/musyarakah", {params: {
                        //     funding_id  : a.funding_id,
                        //     id_pemodal  : this.state.dataProyekBerjalan[i].dataUser.user_id
                        // }}, 
                        // axios.defaults.headers.common['Authorization'] = localStorage.token,
                        // axios.defaults.headers.common['Accept']        = 'application/json'
                        // )
                        // .then((resp) => {
                        //     dataMusyarakah            = resp.data.data[0]
                        //     dataPush.dataMusyarakah   = dataMusyarakah
                        // })
                        // .catch((resp)=> {

                        // });
                    } else if (a.va_status == 1 && this.state.dataProyekBerjalan[i].proyek_overview.status == 9) {
                        a.statusPVA = 'Bisnis Berjalan'
                        a.isCancel = false
                    } else if (a.va_status == 1 && this.state.dataProyekBerjalan[i].proyek_overview.status == 10) {
                        a.statusPVA = 'Bisnis Selesai'
                        a.isCancel = false
                    } else if (a.va_status == 1 && this.state.dataProyekBerjalan[i].proyek_overview.status == 11) {
                        a.statusPVA = 'Pembagian Dividen'
                        a.isCancel = false
                    } else if (a.va_status == 1 && this.state.dataProyekBerjalan[i].proyek_overview.status == 100) {
                        a.statusPVA = 'Selesai'
                        a.isCancel = false
                    }
                    // $(".uplFileMusyarakah"+a.Musyarakah.funding_id).addClass('disabled');

                    var dataPush = {
                        'itemPVA': a,
                        'dataUser': this.state.dataProyekBerjalan[i].dataUser,
                        'data_pengelola': this.state.dataProyekBerjalan[i].data_pengelola,
                        'id': this.state.dataProyekBerjalan[i].id,
                        'id_pemodal': this.state.dataProyekBerjalan[i].id_pemodal,
                        'id_project': this.state.dataProyekBerjalan[i].id_project,
                        'proyek_data': this.state.dataProyekBerjalan[i].proyek_data,
                        'proyek_overview': this.state.dataProyekBerjalan[i].proyek_overview,
                        'status': this.state.dataProyekBerjalan[i].status,
                        'type': this.state.dataProyekBerjalan[i].type,
                    }

                    tmpData.push(dataPush)
                })

                this.setState({
                    dataListPaymentVA: tmpData.slice(this.state.begin, this.state.end),
                    isFilter: false
                })

                this.forceUpdate();
                
            })
            .catch((error) => {
                console.log('error')
            });
    }

    searchListPembayaranVA() {
        // console.log('focus')
        const userinfo = JSON.parse(this.props.userinfo)

        axios.get(BASE_URL + "/customer/pemodal/pymntpemodal", {
            params: {
                user_id: userinfo.user_id,
            }
        },
            axios.defaults.headers.common['Authorization'] = localStorage.token,
            axios.defaults.headers.common['Accept'] = 'application/json'
        )
            .then((resp) => {

                var tmpData = []
                var tmpFilter = []
                resp.data.data.map((a, i) => {
                    var date = new Date();
                    date.setDate(date.getDate());
                    var day = ('0' + date.getDate()).slice(-2);
                    var month = date.getMonth() + 1;
                    var yy = date.getYear();
                    var hours = ('0' + date.getHours()).slice(-2);
                    var minutes = ('0' + date.getMinutes()).slice(-2);
                    var seconds = ('0' + date.getSeconds()).slice(-2);
                    var year = (yy < 1000) ? yy + 1900 : yy;

                    var tgl = year + '-' + month + '-' + day + ' ' + hours + ":" + minutes + ":" + seconds

                    if (a.va_status == 0) {
                        if (tgl > a.datetime_expired) {
                            a.statusPVA = 'Kadaluarsa'
                        } else {
                            a.statusPVA = 'Menunggu Pembayaran'
                        }
                    } else if (a.va_status == 1 && this.state.dataProyekBerjalan[i].proyek_overview.status == 5 || this.state.dataProyekBerjalan[i].proyek_overview.status == 6) {
                        a.statusPVA = 'Masa Penawaran'
                    } else if (a.va_status == 1 && this.state.dataProyekBerjalan[i].proyek_overview.status == 7 || this.state.dataProyekBerjalan[i].proyek_overview.status == 8) {
                        a.statusPVA = 'Full Pendanaan'
                        // axios.get(BASE_URL+"/customer/pemodal/musyarakah", {params: {
                        //     funding_id  : a.funding_id,
                        //     id_pemodal  : this.state.dataProyekBerjalan[i].dataUser.user_id
                        // }}, 
                        // axios.defaults.headers.common['Authorization'] = localStorage.token,
                        // axios.defaults.headers.common['Accept']        = 'application/json'
                        // )
                        // .then((resp) => {
                        //     dataMusyarakah            = resp.data.data[0]
                        //     dataPush.dataMusyarakah   = dataMusyarakah
                        // })
                        // .catch((resp)=> {

                        // });
                    } else if (a.va_status == 1 && this.state.dataProyekBerjalan[i].proyek_overview.status == 9) {
                        a.statusPVA = 'Proyek Berjalan'
                    } else if (a.va_status == 1 && this.state.dataProyekBerjalan[i].proyek_overview.status == 10) {
                        a.statusPVA = 'Proyek Selesai'
                    } else if (a.va_status == 1 && this.state.dataProyekBerjalan[i].proyek_overview.status == 11) {
                        a.statusPVA = 'Pembagian Hasil'
                    } else if (a.va_status == 1 && this.state.dataProyekBerjalan[i].proyek_overview.status == 100) {
                        a.statusPVA = 'Selesai'
                    }

                    // $(".uplFileMusyarakah"+a.Musyarakah.funding_id).addClass('disabled');

                    var dataPush = {
                        'itemPVA': a,
                        'dataUser': this.state.dataProyekBerjalan[i].dataUser,
                        'data_pengelola': this.state.dataProyekBerjalan[i].data_pengelola,
                        'id': this.state.dataProyekBerjalan[i].id,
                        'id_pemodal': this.state.dataProyekBerjalan[i].id_pemodal,
                        'id_project': this.state.dataProyekBerjalan[i].id_project,
                        'proyek_data': this.state.dataProyekBerjalan[i].proyek_data,
                        'proyek_overview': this.state.dataProyekBerjalan[i].proyek_overview,
                        'status': this.state.dataProyekBerjalan[i].status,
                        'type': this.state.dataProyekBerjalan[i].type,
                    }

                    tmpData.push(dataPush)

                    var dataFilter = {
                        key: a.funding_detail_id,
                        text: a.trx_id + ' Rp. ' + a.amount_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' / ' + a.slot + ' Slot',
                        value: a.funding_detail_id,
                    }

                    tmpFilter.push(dataFilter)
                });

                this.setState({
                    dataListPaymentVA: tmpData,
                    filterListPaymentVA: tmpFilter,
                })

            })
            .catch((resp) => {

            });
    }

    getListProyekPembayaran() {
        const userinfo = JSON.parse(this.props.userinfo)

        axios.get(BASE_URL + "/customer/pemodal/proyektersimpan", {
            params: {
                id_pemodal: userinfo.id,
                type: 0,
                limit: 10,
                status: 1,
            }
        },
            axios.defaults.headers.common['Authorization'] = localStorage.token,
            axios.defaults.headers.common['Accept'] = 'application/json'
        )
            .then((resp) => {
                var lengthdataSlot = resp.data.data.length;

                if (lengthdataSlot > 0) {
                    this.getListPembayaranVA();
                }

                this.setState({
                    dataProyekBerjalan: resp.data.data,
                })

            })
            .catch((resp) => {

            });
    }

    getListProyekTersimpan() {
        const userinfo = JSON.parse(this.props.userinfo)

        axios.get(BASE_URL + "/customer/pemodal/proyektersimpan", {
            params: {
                id_pemodal: userinfo.id,
                type: 0,
                limit: 10,
                status: 0,
            }
        },
            axios.defaults.headers.common['Authorization'] = localStorage.token,
            axios.defaults.headers.common['Accept'] = 'application/json'
        )
            .then((resp) => {

                var lengthdataSlot = resp.data.data.length;

                this.setState({
                    dataProyekTersimpan: resp.data.data,
                    totalSlot: lengthdataSlot,
                    totalProyek: lengthdataSlot,
                })

                var tmpSaldoPemb = 0
                var tmp = []
                var tmpData = []
                resp.data.data.map((answer, i) => {
                    var postSlot = {
                        "ProyekID": answer.id_project,
                        "HargaSlot": answer.proyek_overview.slot_nominal * answer.slot,
                        // "Slot": 1
                        "Slot": answer.slot
                    }

                    var tmpPembayaran = answer.proyek_overview.slot_nominal * answer.slot
                    tmpSaldoPemb = parseInt(tmpSaldoPemb) + parseInt(tmpPembayaran)

                    var dataPush = {
                        'id': 0
                    }

                    tmpData.push(dataPush)
                    tmp.push(false)
                    this.setState({
                        dataSlot: [...this.state.dataSlot, postSlot],
                        totalPembelian: tmpSaldoPemb,
                        dataChecked: tmp,
                        paramsChecked: tmpData
                    })
                })

            })
            .catch((resp) => {

            });
    }


    componentDidMount() {
        this.getData();
        this.getDompet();
        this.getListProyekTersimpan();
        this.getListProyekPembayaran();
    }

    plus(value, index, hrg, slot_terisi, slot_max) {
        var nilai = 1 + parseInt(value.Slot)
        var maxnilai = parseInt(nilai) + parseInt(slot_terisi)
        var userinfo = JSON.parse(this.props.userinfo)

        // alert(JSON.stringify(value))

        if (maxnilai > slot_max) {
            // alert('Maaf, Slot sudah terisi maksimal');
            swal({ text: 'Maaf, Slot sudah terisi maksimal', icon: 'error' })
            return false;
        }

        var hrgSlot = nilai * parseInt(hrg)

        // // cek income jika tipe pemodal perorangan tanpa pengalaman
        // if (userinfo.tipe_pemodal == 1) {
        //     var income = userinfo.income
        //     var income_indicator = process.env.REACT_APP_INCOME_INDICATOR
        //     var percent1 = (income * process.env.REACT_APP_PERCENT1) / 100
        //     var percent2 = (income * process.env.REACT_APP_PERCENT2) / 100

        //     if (income <= income_indicator) {
        //         if (hrgSlot > percent1) {
        //             // alert('Maaf, anda tidak bisa mendanai lebih dari ' + percent1);
        //             swal({ text: 'Maaf, anda tidak bisa mendanai lebih dari ' + percent1, icon: 'error' })
        //             return false;
        //         }
        //     }

        //     if (income > income_indicator) {
        //         if (hrgSlot > percent2) {
        //             // alert('Maaf, anda tidak bisa mendanai lebih dari ' + percent2);
        //             swal({ text: 'Maaf, anda tidak bisa mendanai lebih dari ' + percent2, icon: 'error' })
        //             return false;
        //         }
        //     }
        // }

        this.state.dataSlot[index].Slot = nilai
        this.state.dataSlot[index].HargaSlot = hrgSlot
        this.state.totalSlot = this.state.totalSlot + 1
        this.state.totalPembelian = parseInt(this.state.totalPembelian) + parseInt(hrg)
        this.forceUpdate()
    }

    formatRupiah(bilangan) {
        var rev = parseInt(bilangan);
        return 'Rp. ' + rev.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    minus(value, index, hrg) {
        var nilai = parseInt(value.Slot) - 1
        var tmpnSlot = this.state.totalSlot - 1;
        var tmpnHrg = parseInt(this.state.totalPembelian) - parseInt(hrg)
        if (nilai < 1) {
            nilai = 1
        } else {
            this.state.totalSlot = tmpnSlot
            this.state.totalPembelian = tmpnHrg
        }

        var hrgSlot = nilai * parseInt(hrg)

        this.state.dataSlot[index].Slot = nilai
        this.state.dataSlot[index].HargaSlot = hrgSlot
        this.forceUpdate()

    }

    handleClickBayar = (a, b, c, d) => {
        //cek ulang maksimal invest to server (getResume)
        
        let config = {
            headers: {
                'Authorization': localStorage.token,
                'content-type': 'application/json',
                'Access-Control-Allow-Credentials': true,
                'Access-Control-Allow-Methods': 'POST',
            },
        }

        var postData = {
            "id_project": b.ProyekID,
            "slot": b.Slot
        }

        axios.post(BASE_URL + "/addwishlist/paymentresume", postData, config)
            .then((resp) => {
                // swal({ text: resp.data.body.message, icon: 'success' })
                this.props.history.push({
                    pathname: "/Pembayaran/ProyekTerpilih", 
                    data: {
                        dataSlot: a,
                        dataProyek: b,
                        totalSlot: c,
                        totalPembelian: d
                    }
                });
            })
            .catch((error) => {

                try {
                    let errorStatusCode = error.response.status
          
                    if (errorStatusCode === 401) {
                      setTimeout(() => {
                        window.location.href = "/Signin"
                        localStorage.removeItem('token');
                        localStorage.clear();
          
                        this.props.dispatch({
                          type: "AUTH_USER_LOGOUT",
                        })
                      }, 500);
                    } else {
                      let errorBody = error.response.data.body
                      swal({ text: errorBody.message, icon: 'error' })
                    }
          
                  } catch (error) {
                    swal({ text: 'Error tidak terdefinisi!', icon: 'error' })
                  }
            });
    }

    checkAll(valcheckAll) {
        if (valcheckAll === false) {
            var tmp = []
            var tmpData = []
            this.state.dataProyekTersimpan.map((a, i) => {
                var dataPush = {
                    'id': a.id
                }
                tmpData.push(dataPush)
                tmp.push(true)

                this.setState({
                    dataChecked: tmp,
                    paramsChecked: tmpData,
                    valcheckAll: true,
                })
            });

        } else {
            var tmp = []
            var tmpData = []
            this.state.dataProyekTersimpan.map((a, i) => {
                tmp.push(false)

                this.setState({
                    dataChecked: tmp,
                    paramsChecked: [],
                    valcheckAll: false,
                })
            })

        }
    }

    delPilihanProyek(dataChecked, paramsChecked, valcheckAll) {
        var bodyFormData = new FormData();
        $(".modal.modalHapusPrykTersimpan .ui.positive.button").addClass('disabled');
        $(".modal.modalHapusPrykTersimpan .ui.blue.icon.right.labeled.button").addClass('loading');
        $(".modal.modalHapusPrykTersimpan .ui.blue.icon.right.labeled.button").addClass('disabled');

        if (valcheckAll === false && dataChecked.includes(true) === false) {
            // alert('Silahkan pilih proyek terlebih dahulu');
            swal({ text: 'Silahkan pilih proyek terlebih dahulu', icon: 'error' })
            this.close();
            return false;
        }

        dataChecked.map((a, i) => {
            if (a === true) {
                bodyFormData.set('id[' + i + ']', paramsChecked[i].id);
            }
        })

        axios({
            method: 'post',
            url: BASE_URL + "/customer/pemodal/deleteproyektersimpan/delete",
            data: bodyFormData,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': localStorage.token,
            }
        })
            .then((resp) => {
                setTimeout(() => {
                    this.getListProyekTersimpan();
                    $(".modal.modalHapusPrykTersimpan .ui.positive.button").removeClass('disabled');
                    $(".modal.modalHapusPrykTersimpan .ui.blue.icon.right.labeled.button").removeClass('loading');
                    $(".modal.modalHapusPrykTersimpan .ui.blue.icon.right.labeled.button").removeClass('disabled');
                    this.close();
                    this.setState({
                        valcheckAll: false,
                    })
                }, 6000);
            })
            .catch((resp) => {
                swal({ text: 'Terjadi Kesalahan, Cobalah beberapa saat lagi', icon: 'error' })
            });

    }

    clickParamsDelete(index) {
        this.state.dataProyekTersimpan.map((a, i) => {
            if (index === i) {
                var dataTmp = [...this.state.dataChecked];
                var dataParamsTmp = [...this.state.paramsChecked];

                if (this.state.dataChecked[i] === false) {
                    dataTmp[i] = true;
                    dataParamsTmp[i] = {
                        'id': a.id
                    }
                } else {
                    dataTmp[i] = false;
                    dataParamsTmp[i] = {
                        'id': 0
                    }
                }

                this.setState({
                    dataChecked: dataTmp,
                    paramsChecked: dataParamsTmp,
                })
            }
        })

    }

    detailMusyarakah(funding_id) {
        var localstr = JSON.parse(localStorage.DataUser)

        axios.get(BASE_URL + "/customer/pemodal/pymntpemodal/getmusyarakah", {
            params: {
                funding_id: funding_id,
                id_pemodal: localstr.user.user_id
            }
        },
            axios.defaults.headers.common['Authorization'] = localStorage.token,
            axios.defaults.headers.common['Accept'] = 'application/json'
        )
            .then((resp) => {
                if (resp.data.success == true) {
                    this.setState({
                        datadetailMusyarakah: resp.data.data
                    })
                }
            })
            .catch((resp) => {

            });
    }

    clickdetailProyekBrjln(id_project, params) {
        if (params.statusPVA == 'Kadaluarsa' || params.statusPVA == 'Menunggu Pembayaran') {
            return false;
        }

        axios.get(BASE_URL + "/projectdetail", {
            params: {
                id_project: id_project
            }
        },
            axios.defaults.headers.common['Authorization'] = localStorage.token,
            axios.defaults.headers.common['Accept'] = 'application/json'
        )
            .then((resp) => {
                if (resp.data.success == true) {
                    this.setState({
                        hideDivProyekBrjln: 'open',
                        datadetailProyekBrjln: resp.data.data[0],
                        dataitemPVA: params
                    });
                    this.detailMusyarakah(params.funding_id);
                }
            })
            .catch((resp) => {

            });
    }

    clickDetailPortofolio(id_project, params) {
        if (params.statusPVA == 'Kadaluarsa' || params.statusPVA == 'Menunggu Pembayaran') {
            return false;
        }

        axios.get(BASE_URL + "/projectdetail", {
            params: {
                id_project: id_project
            }
        },
            axios.defaults.headers.common['Authorization'] = localStorage.token,
            axios.defaults.headers.common['Accept'] = 'application/json'
        )
            .then((resp) => {
                if (resp.data.success == true) {
                    this.setState({
                        hideDivPortofolio: 'open',
                        datadetailProyekBrjln: resp.data.data[0],
                        dataitemPVA: params
                    });
                    this.detailMusyarakah(params.funding_id);
                }
            })
            .catch((resp) => {

            });
    }

    handleCancel(datas) {

        swal({
            title: "Konfirmasi Batal Pendanaan",
            text: "Apakah anda ingin membatalkan pendanaan untuk bisnis ini?",
            icon: "warning",
            buttons: ["Tidak", "Ya"],
            closeOnClickOutside: false 
        }) .then((ok) => {
            if (ok) {
                
                const userinfo = JSON.parse(this.props.userinfo)

                let values = {}
                values.wishlist_id = datas.id
                values.project_id = datas.itemPVA.project_id
                values.funding_id = datas.itemPVA.funding_id
                values.funding_detail_id = datas.itemPVA.funding_detail_id

                let config = {
                    headers: {
                        'Authorization': localStorage.token,
                        'content-type': 'application/json',
                        'Access-Control-Allow-Credentials': true,
                        'Access-Control-Allow-Methods': 'POST',
                    },
                    maxContentLength: 52428890,
                    timeout: 10000000,
                }
    
                axios.post(BASE_URL + "/customer/pemodal/portofolio/cancel", values, config)
                    .then((resp) => {
    
                        if (resp.data.success == false) {
                            swal({ text: resp.data.body.message, icon: 'error' })
                        } else {
                            this.getListPembayaranVA()
                            swal({ text: resp.data.body.message, icon: 'success' })
                            // swal({
                            //     //title: "Konfirmasi Batal Pendanaan",
                            //     text: resp.data.body.message,
                            //     icon: "success",
                            //     buttons: ["Tidak", "Ya"],
                            //     closeOnClickOutside: false 
                            // }) .then((ok) => {
                            //     if (ok) {
                            //         window.location.href = "/Signin"
                            //     }
                            // })
                        }
                    })
                    .catch((error) => {

                        try {
                            let errorStatusCode = error.response.status
                  
                            if (errorStatusCode === 401) {
                              setTimeout(() => {
                                window.location.href = "/Signin"
                                localStorage.removeItem('token');
                                localStorage.clear();
                  
                                this.props.dispatch({
                                  type: "AUTH_USER_LOGOUT",
                                })
                              }, 500);
                            } else {
                              let errorBody = error.response.data.body
                              // swal({ text: errorBody.message, icon: 'error' })
                              var htmlFormat = document.createElement("div");
                              htmlFormat.innerHTML = errorBody.message;
                              swal({ content: htmlFormat, icon: "error" })
                            }
                  
                        } catch (error) {
                            swal({ text: 'Error tidak terdefinisi!', icon: 'error' })
                        }
                    });
            } 
        });
    }

    handleRefund(datas) {

        swal({
            title: "Konfirmasi Pengembalian Dana",
            text: "Apakah anda ingin mengajukan pengembalian dana?",
            icon: "warning",
            buttons: ["Tidak", "Ya"],
            closeOnClickOutside: false 
        }) .then((ok) => {
            if (ok) {
                
                const userinfo = JSON.parse(this.props.userinfo)

                let values = {}
                values.project_id = datas.project_id
                values.funding_id = datas.funding_id
                values.funding_detail_id = datas.funding_detail_id

                let config = {
                    headers: {
                        'Authorization': localStorage.token,
                        'content-type': 'application/json',
                        'Access-Control-Allow-Credentials': true,
                        'Access-Control-Allow-Methods': 'POST',
                    },
                    maxContentLength: 52428890,
                    timeout: 10000000,
                }
    
                axios.post(BASE_URL + "/customer/pemodal/transaction/refund", values, config)
                    .then((resp) => {
    
                        if (resp.data.success == false) {
                            swal({ text: resp.data.body.message, icon: 'error' })
                        } else {
                            this.getDataTransaction()
                            swal({ text: resp.data.body.message, icon: 'success' })
                            // swal({
                            //     //title: "Konfirmasi Batal Pendanaan",
                            //     text: resp.data.body.message,
                            //     icon: "success",
                            //     buttons: ["Tidak", "Ya"],
                            //     closeOnClickOutside: false 
                            // }) .then((ok) => {
                            //     if (ok) {
                            //         window.location.href = "/Signin"
                            //     }
                            // })
                        }
                    })
                    .catch((error) => {
                        try {
                            let errorStatusCode = error.response.status
                  
                            if (errorStatusCode === 401) {
                              setTimeout(() => {
                                window.location.href = "/Signin"
                                localStorage.removeItem('token');
                                localStorage.clear();
                  
                                this.props.dispatch({
                                  type: "AUTH_USER_LOGOUT",
                                })
                              }, 500);
                            } else {
                              let errorBody = error.response.data.body
                              // swal({ text: errorBody.message, icon: 'error' })
                              var htmlFormat = document.createElement("div");
                              htmlFormat.innerHTML = errorBody.message;
                              swal({ content: htmlFormat, icon: "error" })
                            }
                  
                        } catch (error) {
                            swal({ text: 'Error tidak terdefinisi!', icon: 'error' })
                        }
                    });
            } 
        });
    }

    handleSelectTab = (key) => {
        console.log('TAB_SELECTION : ' + key)

        if(key === 'portofolio'){
            this.getListPembayaranVA()
        }
        if(key === 'proyekberjalan'){
            this.getDataTransaction()
        }
    }

    getDataTransaction = () => {

        const userinfo = JSON.parse(this.props.userinfo)

        axios.get(BASE_URL + "/customer/pemodal/transaction", {
            params: {
                user_id: userinfo.user_id,
            }
        },
            axios.defaults.headers.common['Authorization'] = localStorage.token,
            axios.defaults.headers.common['Accept'] = 'application/json'
        )
            .then((resp) => {

                if (resp.data.success == false) {
                    swal({ text: resp.data.body.message, icon: 'error' })
                } else {
                    // swal({ text: resp.data.body.message, icon: 'success' })
                    this.setState({
                        dataListTransaction: resp.data.body.data
                    })
                    console.log("data : " + resp.data.body.data.length)
                }

                // var tmpData = []
                // var tmpFilter = []
                // resp.data.data.map((a, i) => {
                //     var date = new Date();
                //     date.setDate(date.getDate());
                //     var day = ('0' + date.getDate()).slice(-2);
                //     var month = date.getMonth() + 1;
                //     var yy = date.getYear();
                //     var hours = ('0' + date.getHours()).slice(-2);
                //     var minutes = ('0' + date.getMinutes()).slice(-2);
                //     var seconds = ('0' + date.getSeconds()).slice(-2);
                //     var year = (yy < 1000) ? yy + 1900 : yy;

                //     var tgl = year + '-' + month + '-' + day + ' ' + hours + ":" + minutes + ":" + seconds

                //     if (a.va_status == 0) {
                //         if (tgl > a.datetime_expired) {
                //             a.statusPVA = 'Kadaluarsa'
                //         } else {
                //             a.statusPVA = 'Menunggu Pembayaran'
                //         }
                //     } else if (a.va_status == 1 && this.state.dataProyekBerjalan[i].proyek_overview.status == 5 || this.state.dataProyekBerjalan[i].proyek_overview.status == 6) {
                //         a.statusPVA = 'Masa Penawaran'
                //     } else if (a.va_status == 1 && this.state.dataProyekBerjalan[i].proyek_overview.status == 7 || this.state.dataProyekBerjalan[i].proyek_overview.status == 8) {
                //         a.statusPVA = 'Full Pendanaan'
                //     } else if (a.va_status == 1 && this.state.dataProyekBerjalan[i].proyek_overview.status == 9) {
                //         a.statusPVA = 'Proyek Berjalan'
                //     } else if (a.va_status == 1 && this.state.dataProyekBerjalan[i].proyek_overview.status == 10) {
                //         a.statusPVA = 'Proyek Selesai'
                //     } else if (a.va_status == 1 && this.state.dataProyekBerjalan[i].proyek_overview.status == 11) {
                //         a.statusPVA = 'Pembagian Hasil'
                //     } else if (a.va_status == 1 && this.state.dataProyekBerjalan[i].proyek_overview.status == 100) {
                //         a.statusPVA = 'Selesai'
                //     }

                //     var dataPush = {
                //         'itemPVA': a,
                //         'dataUser': this.state.dataProyekBerjalan[i].dataUser,
                //         'data_pengelola': this.state.dataProyekBerjalan[i].data_pengelola,
                //         'id': this.state.dataProyekBerjalan[i].id,
                //         'id_pemodal': this.state.dataProyekBerjalan[i].id_pemodal,
                //         'id_project': this.state.dataProyekBerjalan[i].id_project,
                //         'proyek_data': this.state.dataProyekBerjalan[i].proyek_data,
                //         'proyek_overview': this.state.dataProyekBerjalan[i].proyek_overview,
                //         'status': this.state.dataProyekBerjalan[i].status,
                //         'type': this.state.dataProyekBerjalan[i].type,
                //     }

                //     tmpData.push(dataPush)

                //     var dataFilter = {
                //         key: a.funding_detail_id,
                //         text: a.trx_id + ' Rp. ' + a.amount_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' / ' + a.slot + ' Slot',
                //         value: a.funding_detail_id,
                //     }

                //     tmpFilter.push(dataFilter)
                // });

                // this.setState({
                //     dataListTransaction: tmpData,
                //     // filterListPaymentVA: tmpFilter,
                // })

            })
            .catch((error) => {
                try {
                    let errorStatusCode = error.response.status
          
                    if (errorStatusCode === 401) {
                      setTimeout(() => {
                        window.location.href = "/Signin"
                        localStorage.removeItem('token');
                        localStorage.clear();
          
                        this.props.dispatch({
                          type: "AUTH_USER_LOGOUT",
                        })
                      }, 500);
                    } else {
                      let errorBody = error.response.data.body

                    //   var htmlFormat = document.createElement("div");
                    //   htmlFormat.innerHTML = errorBody.message;
                    //   swal({ content: htmlFormat, icon: "error" })
                    }
          
                } catch (error) {
                    swal({ text: 'Error tidak terdefinisi!', icon: 'error' })
                }
            });
    }

    render() {
        // this.clearConsole();

        const clickdetailDompet = event => this.setState({
            hideDiv: 'open',
        });

        const closedetailDompet = event => this.setState({
            hideDiv: 'close',
        });

        const clickbackProyekBrjln = event => this.setState({
            hideDivProyekBrjln: 'close'
        })

        const clickbackPortofolio = event => this.setState({
            hideDivPortofolio: 'close'
        })

        const toBase64 = file => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            // reader.onloadend = function () {
            //     // alert(reader.result);
            //     var base64result = reader.result.substr(reader.result.indexOf(',') + 1);
            //     resolve(base64result);
            // };
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });

        const handleCloseModalRegisterSigned = () => {
            this.setState({ showModalRegisterSigned: false })
        }

        const handleShowModalAgreement = async () => {
            // this.setState({showModalAgreement: true})

            let config = {
                headers: {
                    'Authorization': localStorage.token,
                    'content-type': 'application/json',
                    'Access-Control-Allow-Credentials': true,
                    'Access-Control-Allow-Methods': 'GET',
                },
                maxContentLength: 52428890,
                timeout: 10000000,
            }

            axios.get(BASE_URL + "/customer/pemodal/agreement/document", config)
                .then((resp) => {

                    if (resp.data.success == false) {
                        swal({ text: resp.data.body.message, icon: 'error' })
                    } else {
                        /** STATUS CODE RESPONSE 200 */
                        /** status: OK | code: USER_EXISTS_VERIFIED | message: Pengguna telah terdaftar dan Aktif. */
                        /** status: OK | code: USER_DO_NOT_EXISTS | message: Pengguna belum terdaftar. */
                        /** status: OK | code: USER_EXISTS_UNVERIFIED | message: Pengguna sudah terdaftar tapi belum verifikasi email. */
                        /** status: OK | code: USER_EXISTS_CERTIVICATE_EXPIRED | message: Pengguna sudah terdaftar tapi sertifikat sudah kadaluwarsa. */
                        /** END */

                        if (resp.data.body.code == 'USER_EXISTS_VERIFIED') {
                            // console.log(resp.data.body.data)
                            this.setState({ agreementContent: resp.data.body.data })
                            this.setState({ showModalAgreement: true })
                        } else if (resp.data.body.code == 'USER_DO_NOT_EXISTS') {
                            // swal({ text: 'Mohon untuk melakukan konfirmasi ulang Foto KTP dan Foto Selfie..', icon: 'warning' })
                            this.setState({
                                showModalRegisterSigned: true,
                            })
                        } else {
                            swal({ text: resp.data.body.message, icon: 'warning' })
                        }
                    }
                })
                .catch((error) => {
                    try {
                        let errorStatusCode = error.response.status

                        if (errorStatusCode === 401) {
                            setTimeout(() => {
                                window.location.href = "/Signin"
                                localStorage.removeItem('token');
                                localStorage.clear();

                                this.props.dispatch({
                                    type: "AUTH_USER_LOGOUT",
                                })
                            }, 500);
                        } else {
                            let errorBody = error.response.data.body
                            swal({ text: errorBody.message, icon: 'error' })
                        }

                    } catch (error) {
                        swal({ text: 'Error tidak terdefinisi!', icon: 'error' })
                    }
                });
        }
        const handleCloseModalAgreement = () => {
            this.setState({ showModalAgreement: false })
        }

        const handleDirectSign = async () => {

            let config = {
                headers: {
                    'Authorization': localStorage.token,
                    'content-type': 'application/json',
                    'Access-Control-Allow-Credentials': true,
                    'Access-Control-Allow-Methods': 'POST',
                },
                maxContentLength: 52428890,
                timeout: 10000000,
            }

            axios.post(BASE_URL + "/customer/pemodal/agreement/signed", config)
                .then((resp) => {

                    if (resp.data.success == false) {
                        swal({ text: resp.data.body.message, icon: 'error' })
                    } else {
                        let signUrl = resp.data.body.data

                        // window.open(signUrl, "Popup","toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30")
                        // window.open(signUrl, '_blank')

                        //Direct portal tekenaja, jangan lupa buka
                        //window.location.href = signUrl 
                        //this.setState({ showModalAgreement: false })
                        //End Direct

                        //Buat Demo, jangan lupa hapus
                        swal({text: resp.data.body.message, icon: 'success', closeOnClickOutside: false})
                            .then((value) => {
                                window.location.href = signUrl
                                this.setState({ showModalAgreement: false })
                            });
                        //End Demo    
                    }
                })
                .catch((error) => {
                    try {
                        let errorStatusCode = error.response.status

                        if (errorStatusCode === 401) {
                            setTimeout(() => {
                                window.location.href = "/Signin"
                                localStorage.removeItem('token');
                                localStorage.clear();

                                this.props.dispatch({
                                    type: "AUTH_USER_LOGOUT",
                                })
                            }, 500);
                        } else {
                            let errorBody = error.response.data.body
                            // swal({ text: errorBody.message, icon: 'error' })
                            var htmlFormat = document.createElement("div");
                            htmlFormat.innerHTML = errorBody.message;
                            swal({ content: htmlFormat, icon: "error" })
                        }

                    } catch (error) {
                        swal({ text: 'Error tidak terdefinisi!', icon: 'error' })
                    }
                });
        }


        async function handleUplMusyarakah(funding_id) {
            var formField = document.forms[0]

            var musyFileNew = formField.querySelector('input[name="fileMusyarakah' + funding_id + '"]').files[0]

            if (musyFileNew) {
                var sizemusyFileNew = formField.querySelector('input[name="fileMusyarakah' + funding_id + '"]').files[0].size;
                var typemusyFileNew = formField.querySelector('input[name="fileMusyarakah' + funding_id + '"]').files[0].type;

                if (typemusyFileNew != 'application/pdf' || sizemusyFileNew > 5120000) {
                    // alert('File Musyarakah harus berformat PDF dan ukuran Max 5MB')
                    swal({ text: 'File Musyarakah harus berformat PDF dan ukuran Max 5MB', icon: 'error' })
                    return false;
                }
            } else {
                // alert("Harap pilih file yang akan di upload terlebih dahulu...");
                swal({ text: 'Harap pilih file yang akan di upload terlebih dahulu', icon: 'error' })
                return false;
            }

            if (musyFileNew) {
                form.filemusyarakah = await toBase64(musyFileNew)
                form.funding_id = funding_id
                $(".uplFileMusyarakah" + funding_id).addClass('loading');
                $(".uplFileMusyarakah" + funding_id).addClass('disabled');
                form.paramsTermin = 'UplMusyarakah'
                submitDokProyek(form)
            }
        }

        const submitDokProyek = async (values) => {

            let config = {
                headers: {
                    'Authorization': localStorage.token,
                    'content-type': 'application/json',
                    'Access-Control-Allow-Credentials': true,
                    'Access-Control-Allow-Methods': 'POST',
                },
                maxContentLength: 52428890,
                timeout: 10000000,
            }

            axios.post(BASE_URL + "/customer/pemodal/upldokumenproyek", values, config)
                .then((resp) => {

                    if (resp.data.success == false) {
                        // alert(resp.data.body.message)
                        swal({ text: resp.data.body.message, icon: 'error' })
                    } else {
                        // alert(resp.data.body.message)
                        swal({ text: resp.data.body.message, icon: 'success' })
                        setTimeout(() => {
                            window.location.href = "UserPemodal/?tabs=proyekberjalan"
                        }, 1000)
                    }
                })
                .catch((resp) => {

                });

        }

        const userinfo = JSON.parse(this.props.userinfo)
        const datadetailProyekBrjln = this.state.datadetailProyekBrjln
        const dataitemPVA = this.state.dataitemPVA
        const closeOnEscape = this.state.closeOnEscape
        const closeOnDimmerClick = this.state.closeOnDimmerClick
        const kodeUserid = userinfo.user_id.slice(0, 3);
        const dataSlot = this.state.dataSlot
        const totalSlot = this.state.totalSlot
        const totalProyek = this.state.totalProyek
        const totalPembelian = this.state.totalPembelian
        const itemsDompet = this.state.itemsDompet
        const openTandaTangan = this.state.openTandaTangan
        const { open, size, fileMusyarakah, dataMusyarakah, datadetailMusyarakah, disabledUplMusy } = this.state

        const clearFilter = e => {
            this.getListPembayaranVA();
            this.setState({
                isFilter: false,
                select: '',
                statusTr: '',
                fileMusyarakah: {},
            })
        }

        const clickFilterListPaymentVA = e => {
            this.searchListPembayaranVA()
        }

        const changeFilterListPaymentVA = (event, { value }) => {

            var tmp = []
            this.state.dataListPaymentVA.map((a, i) => {
                if (a.itemPVA.funding_detail_id == value) {
                    tmp.push(a)
                }
            })
            this.setState({
                dataListPaymentVA: tmp,
                select: value,
                statusTr: '',
                isFilter: true
            })
        }
        const changeFilterStatusTr = (event, { value }) => {

            var tmp = []
            this.state.dataListPaymentVA.map((a, i) => {
                if (a.itemPVA.statusPVA == value) {
                    tmp.push(a)
                }
            })

            this.setState({
                dataListPaymentVA: tmp,
                isFilter: true,
                statusTr: value,
                select: ''
            })
        }

        const form = {
            user_id: userinfo.user_id,
            paramsTermin: '',
        }

        const openMdlTTD = e => {
            this.setState({ size: 'mini', openTandaTangan: true })
        }

        const closeMdlTTD = e => {
            this.setState({ openTandaTangan: false })
        }

        const statusProyekBerjalan = [
            {
                key: 'Menunggu Pembayaran',
                text: 'Menunggu Pembayaran',
                value: 'Menunggu Pembayaran',
            },
            {
                key: 'Kadaluarsa',
                text: 'Kadaluarsa',
                value: 'Kadaluarsa',
            },
            {
                key: 'Masa Penawaran',
                text: 'Masa Penawaran',
                value: 'Masa Penawaran',
            },
            {
                key: 'Proyek Berjalan',
                text: 'Proyek Berjalan',
                value: 'Proyek Berjalan',
            },
            {
                key: 'Full Pendanaan',
                text: 'Full Pendanaan',
                value: 'Full Pendanaan',
            },
            {
                key: 'Proyek Selesai',
                text: 'Proyek Selesai',
                value: 'Proyek Selesai',
            },
            {
                key: 'Pembagian Hasil',
                text: 'Pembagian Hasil',
                value: 'Pembagian Hasil',
            },
            {
                key: 'Selesai',
                text: 'Selesai',
                value: 'Selesai',
            },
        ]

        const body = (
            <Table.Body>
                <Table.Row>
                    <Table.Cell className="tdpengumpulandana" style={{ width: '10%' }}>Masa<br />Penawaran</Table.Cell>
                    <Table.Cell className="tdfullpendanaan" style={{ width: '23%' }}>Full<br />Pendanaan</Table.Cell>
                    <Table.Cell className="tdproyekberjln" style={{ width: '13%' }}>Bisnis<br />Berjalan</Table.Cell>
                    <Table.Cell style={{ width: '23%' }}>Bisnis<br />Selesai</Table.Cell>
                    <Table.Cell style={{ width: '13%' }}>Pembagian<br />Dividen</Table.Cell>
                    <Table.Cell>&nbsp;</Table.Cell>
                </Table.Row>
            </Table.Body>
        )

        if (userinfo.dokumen) {
            userinfo.dokumen.map((answer, i) => {
                if (answer.type == 'foto_ktp') {
                    this.state.basefotoKtp = answer.nama_file
                } else if (answer.type == 'selfie_ktp') {
                    this.state.baseselfieKtp = answer.nama_file
                } else if (answer.type == 'npwp') {
                    this.state.basenpwp = answer.nama_file
                } else if (answer.type == 'npwp_perusahaan') {
                    this.state.basenpwpperusahaan = answer.nama_file
                } else if (answer.type == 'akta') {
                    this.state.baseakta = answer.nama_file
                } else if (answer.type == 'nib') {
                    this.state.basenib = answer.nama_file
                } else if (answer.type == 'sk_kemenkumham') {
                    this.state.basesk_kemenkumham = answer.nama_file
                } else if (answer.type == 'ktp_pengurus') {
                    this.state.basektp_pengurus = answer.nama_file
                } else if (answer.type == 'buku_tabungan') {
                    this.state.basebuku_tabungan = answer.nama_file
                }

            })
        }

        const getListProyekTersimpan = event => {
            axios.get(BASE_URL + "/customer/pemodal/proyektersimpan", {
                params: {
                    id_pemodal: userinfo.id,
                    type: 0,
                    limit: 10,
                    status: 0,
                }
            },
                axios.defaults.headers.common['Authorization'] = localStorage.token,
                axios.defaults.headers.common['Accept'] = 'application/json'
            )
                .then((resp) => {
                    app.setState({
                        dataProyekTersimpan: resp.data.data,
                    })
                })
                .catch((resp) => {

                });
        }

        const favListProyekTersimpan = event => {
            axios.get(BASE_URL + "/customer/pemodal/proyektersimpan", {
                params: {
                    id_pemodal: userinfo.id,
                    type: 1,
                    limit: 10,
                    status: 0,
                }
            },
                axios.defaults.headers.common['Authorization'] = localStorage.token,
                axios.defaults.headers.common['Accept'] = 'application/json'
            )
                .then((resp) => {
                    app.setState({
                        favProyekTersimpan: resp.data.data,
                    })
                })
                .catch((resp) => {

                });
        }

        var app = this;
        var tab0 = 0;

        var app = this
        $("#tab-inform-tab-favorit").on("click", function () {
            favListProyekTersimpan();
        })

        const numberWithCommas = async (x) => {
            return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        }

        const deleteProyekTersimpan = async (val) => {
            axios.get(BASE_URL + "/customer/pemodal/deleteproyektersimpan", {
                params: {
                    id: val,
                }
            },
                axios.defaults.headers.common['Authorization'] = localStorage.token,
                axios.defaults.headers.common['Accept'] = 'application/json'
            )
                .then((resp) => {
                    getListProyekTersimpan();
                    favListProyekTersimpan();
                })
                .catch((resp) => {

                });
        }

        const favoritInsert = async (val) => {

            if (verified_advance != 3) {
                $('.msgerrorfavorit').removeAttr('style');
                window.setTimeout(function () {
                    $(".msgerrorfavorit").attr('style', 'display:none')
                }, 6000);
                return false;
            }

            var postData = {
                "id_project": val,
                "id_pemodal": userinfo.id,
                "type": 1,
            }

            axios.post(BASE_URL + "/addwishlist", postData,
                axios.defaults.headers.common['Authorization'] = localStorage.token,
                axios.defaults.headers.common['Accept'] = 'application/json'
            )
                .then((resp) => {
                    favListProyekTersimpan();
                    $('.msgsuccess').removeAttr('style');
                    window.setTimeout(function () {
                        $(".msgsuccess").attr('style', 'display:none')
                    }, 2000);
                })
                .catch((resp) => {
                    $(".msgsuccess").attr('style', 'display:none')
                });
        }

        const clickDetImg = function (nama_file, caption) {
            if (nama_file) {
                var modal = document.getElementById("myModal");
                var modalImg = document.getElementById("img01");
                var captionText = document.getElementById("caption");

                modal.style.display = "block";
                modalImg.src = nama_file;
                captionText.innerHTML = caption;
            }
        }

        const closeDetImg = function () {
            var modal = document.getElementById("myModal");
            modal.style.display = "none";
        }

        window.onclick = function (event) {
            var modal = document.getElementById("myModal");
            if (event.target == modal) {
                modal.style.display = "none";
            }
        }

        const dataIsComplete = this.props.dataIsComplete
        const verified = this.props.verified
        const verified_advance = this.props.verified_advance
        const agreement = this.props.agreement
        const { error, isLoaded, items, activeIndex } = this.state;
        if (activeIndex === null) { return null };

        const handleFileChange = event => {

            this.state.fileRegisterSigned.foto_ktp = event.target.value
            this.setState({ fileRegisterSigned: this.state.fileRegisterSigned })

            var index = event.target.name
            this.state.errorMessage[index] = ''

            this.setState({ errorMessage: this.state.errorMessage })
        }

        const uploadImage = async files => {

            if (files != 'repeat') {
                //   setIsCaptureCamera(true)
                this.state.fileRegisterSigned.foto_selfie = files
                this.setState({ fileRegisterSigned: this.state.fileRegisterSigned })
            } else {
                //   setIsCaptureCamera(false)
                this.state.fileRegisterSigned.foto_selfie = ''
                this.setState({ fileRegisterSigned: this.state.fileRegisterSigned })
            }

            this.state.errorMessage['foto_selfie'] = ''
            this.setState({ errorMessage: this.state.errorMessage })

            // Connect to a seaweedfs instance
        };

        const handleSubmitRegisterSigned = async (event) => {
            event.preventDefault()

            let errors = {};
            let isValid = true;

            let file = this.state.fileRegisterSigned

            if (file.foto_selfie == '') {
                errors['foto_selfie'] = "Silahkan ambil foto selfie!"
                isValid = false
            } else {
                if (file.foto_selfie.size > 1024000) {
                    errors['foto_selfie'] = "Maksimal file upload 1 MB!"
                    isValid = false
                }
            }

            if (file.foto_ktp == '') {
                errors['foto_ktp'] = "Silahkan pilih foto KTP!"
                isValid = false
            } else {
                var formField = document.querySelector('form[name="formRegisterSigned"]')
                var fileFoto = formField.querySelector('input[name="foto_ktp"]').files[0]
                if (fileFoto.size > 1024000) {
                    errors['foto_ktp'] = "Maksimal file upload 1 MB!"
                    isValid = false
                }
                else {
                    file.foto_ktp = fileFoto
                }
            }

            this.setState({ errorMessage: errors })

            if (isValid) {
                postData(file)
            } else {
                swal({ text: 'Periksa kembali data yang anda masukkan!', icon: 'error' })
            }
        }

        const postData = async (values) => {
            // window.addEventListener("beforeunload", beforeUnloadListener);

            // console.log("POSTDATA : " + JSON.stringify(values))
            // return

            var formData = new FormData()
            formData.append('foto_selfie', values.foto_selfie, 'foto_selfie' + '.png');
            formData.append('foto_ktp', values.foto_ktp, 'foto_ktp' + '.png');

            var contentData = formData

            let config = {
                headers: {
                    'Authorization': localStorage.token,
                    'content-type': 'multipart/form-data',
                    'Access-Control-Allow-Credentials': true,
                    'Access-Control-Allow-Methods': 'POST',
                },
                maxContentLength: 52428890,
                timeout: 10000000,
            }

            // if (post_to == 'document') { setLoading(true) }
            // if (post_to == 'document_upload') (setLoadingModalUpload(true))

            axios.post(BASE_URL + "/customer/pemodal/agreement/register", contentData, config)
                .then((resp) => {

                    // window.removeEventListener("beforeunload", beforeUnloadListener);

                    swal({ text: resp.data.body.message, icon: 'success' })
                    // console.log(JSON.stringify(resp.data.body))

                })
                .catch(error => {

                    // window.removeEventListener("beforeunload", beforeUnloadListener);

                    try {
                        let errorStatusCode = error.response.status

                        if (errorStatusCode === 401) {
                            setTimeout(() => {
                                window.location.href = "/Signin"
                                localStorage.removeItem('token');
                                localStorage.clear();

                                this.props.dispatch({
                                    type: "AUTH_USER_LOGOUT",
                                })
                            }, 500);
                        } else {
                            let errorBody = error.response.data.body
                            // swal({ text: errorBody.message, icon: 'error' })

                            var htmlFormat = document.createElement("div");
                            htmlFormat.innerHTML = errorBody.message;
                            swal({ content: htmlFormat, icon: "error" })
                        }

                    } catch (error) {
                        swal({ text: 'Error tidak terdefinisi!', icon: 'error' })
                    }
                });
        }

        return (
            <Tabs defaultActiveKey={activeIndex} key="TabPemodal" id="tab-inform" onSelect={this.handleSelectTab}>
                {
                    dataIsComplete == 5 && verified_advance == 3 && agreement == 1 ?
                        (
                            <>
                                {/* <div className="info d-flex">
                                <div className="icon"><img src={seru} alt="" /></div>
                                <div className="isi text-muted ml-5">
                                    <h5 id="fonts" >Informasi</h5>
                                     <p id="fonts" className="">Selamat, Data anda berhasil diverifikasi. Sekarang anda sudah bisa melakukan
                                         pendanaan proyek atau pengajuan pendanaan. Terima kasih.
                                    </p>
                                </div>
                            </div> */}
                            </>
                        )
                        : dataIsComplete == 5 && verified_advance < 3 ?
                            (
                                <Tab eventKey="informasi" title="Informasi" >
                                    <Container>
                                        <Row className="justify-content-md-center mInformasiAwal">
                                            <Col xs md="12">
                                                <div className="attention p-4">
                                                    <div className="info d-flex">
                                                        <div className="icon"><img src={seru2} alt="" /></div>
                                                        <div className="isi text-muted ml-5">
                                                            <h5 id="fonts" >Informasi</h5>
                                                            <p id="fonts" className="">
                                                                Data anda sedang diverifikasi dalam waktu 1x24 jam.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Tab>
                            )
                            : (dataIsComplete < 5 || dataIsComplete == 5) && verified_advance == 5 ?
                                (
                                    <Tab eventKey="informasi" title="Informasi">
                                        <Container>
                                            <Row className="justify-content-md-center mInformasiAwal">
                                                <Col xs md="12">
                                                    <div className="attention p-4">
                                                        <div className="info d-flex">
                                                            {/* <div className="icon"><img src={seru2} alt="" /></div> */}
                                                            <Icon name='remove circle' color='red' style={{ fontSize: '45px' }} />
                                                            <div className="isi text-muted ml-3">
                                                                <h5 id="fonts" >Informasi</h5>
                                                                <p id="fonts" className="">
                                                                    Akun anda ditolak! Silahkan menghubungi admin Kerjasama.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Tab>
                                )
                                : dataIsComplete < 5 && verified_advance < 3 ?
                                    (
                                        <Tab eventKey="informasi" title="Informasi" >
                                            <Container>
                                                <Row className="justify-content-md-center mInformasiAwal">
                                                    <Col xs md="12">
                                                        <div className="attention p-4">
                                                            <div className="info d-flex">
                                                                <div className="icon"><img src={seru2} alt="" /></div>
                                                                <div className="isi text-muted ml-3">
                                                                    <h5 id="fonts" >Informasi</h5>
                                                                    <p id="fonts" className="">
                                                                        <a href={'/Profilepemodal'}>Lengkapi data profil</a> terlebih dahulu untuk mulai mendanai. Terima Kasih
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Tab>
                                    )
                                    :
                                    dataIsComplete == 5 && verified_advance == 3 && agreement != 1 ?
                                        (
                                            <Tab eventKey="informasi" title="Informasi" >
                                                <Container>
                                                    <Row className="justify-content-md-center mInformasiAwal">
                                                        <Col xs md="12">
                                                            <div className="attention p-4">
                                                                <div className="info d-flex">
                                                                    <div className="icon"><img src={seru2} alt="" /></div>
                                                                    <div className="isi text-muted ml-5">
                                                                        <h5 id="fonts" >Informasi</h5>
                                                                        <p id="fonts" className="">
                                                                            Anda wajib menandatangani Perjanjian Pemodal secara digital, untuk dapat melakukan transaksi investasi.
                                                                        </p>
                                                                        <p><Button color='blue' onClick={handleShowModalAgreement}>Mulai tandatangan</Button></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>

                                                <ModalForm size="lg" style={{ borderRadius: "30px" }} show={this.state.showModalAgreement} onHide={handleCloseModalAgreement} animation={false}>
                                                    <Form name='formAgreement' onSubmit={e => e.preventDefault()} encType="multipart/form-data" autoComplete='off'>
                                                        <ModalForm.Header closeButton>
                                                            <ModalForm.Title>
                                                                {/* Perjanjian Pemodal */}
                                                            </ModalForm.Title>
                                                        </ModalForm.Header>
                                                        <ModalForm.Body>
                                                            <div dangerouslySetInnerHTML={{ __html: this.state.agreementContent }} />
                                                        </ModalForm.Body>
                                                        <ModalForm.Footer>
                                                            <Button color='blue' onClick={handleCloseModalAgreement}>Keluar</Button>
                                                            <Button color='green' onClick={handleDirectSign}>Selanjutnya</Button>
                                                            {/* <Button color='green' onClick={handleUpload} disabled={loadingModalUpload}>
                                                            {loadingModalUpload && <Spinner animation="border" variant="default" size='sm' style={{ marginRight: "5px" }}/>}
                                                            {loadingModalUpload && <span>Loading..</span>}
                                                            {!loadingModalUpload && <span>Upload</span>}
                                                            </Button> */}
                                                        </ModalForm.Footer>
                                                    </Form>
                                                </ModalForm>

                                                <ModalForm size="lg" style={{ borderRadius: "30px" }} show={this.state.showModalRegisterSigned} onHide={handleCloseModalRegisterSigned} animation={false}>
                                                    <Form name='formRegisterSigned' onSubmit={e => e.preventDefault()} encType="multipart/form-data" autoComplete='off'>
                                                        <ModalForm.Body>
                                                            <span>Mohon konfirmasi ulang Foto Selfie dan Foto KTP untuk melanjutkan proses penandatanganan.</span>
                                                            <br />
                                                            <p><strong>#Foto Selfie</strong></p>
                                                            <CameraFeed sendFile={uploadImage} />
                                                            <span style={{ color: "red", fontSize: 11 }}>{this.state.errorMessage['foto_selfie']}</span>

                                                            <p style={{ marginTop: '20px' }}><strong>#Foto KTP</strong></p>
                                                            <Form.Control type="file" name="foto_ktp" onChange={handleFileChange} accept={"image/jpg, image/png"} />
                                                            <span style={{ color: "red", fontSize: 11 }}>{this.state.errorMessage['foto_ktp']}</span>
                                                        </ModalForm.Body>
                                                        <ModalForm.Footer>
                                                            <Button color='blue' onClick={handleCloseModalRegisterSigned}>Keluar</Button>
                                                            <Button color='green' onClick={handleSubmitRegisterSigned}>Submit</Button>
                                                            {/* <Button color='green' onClick={handleUpload} disabled={loadingModalUpload}>
                                                            {loadingModalUpload && <Spinner animation="border" variant="default" size='sm' style={{ marginRight: "5px" }}/>}
                                                            {loadingModalUpload && <span>Loading..</span>}
                                                            {!loadingModalUpload && <span>Upload</span>}
                                                            </Button> */}
                                                        </ModalForm.Footer>
                                                    </Form>
                                                </ModalForm>
                                            </Tab>
                                        )
                                        : <></>
                }

                <Tab eventKey="buatproyek" title="Daftar Bisnis">
                    <TabListProyek error={error} isLoaded={isLoaded} items={JSON.stringify(items)} favoritInsert={favoritInsert} alertsuccess={this.state.alertsuccess}
                        timeBetweenDates={this.timeBetweenDates} formatRupiah={this.formatRupiah}
                    />
                </Tab>

                {/* Daftar bisnis yang akan dilakukan pendanaan */}
                {/* <Tab eventKey="proyektersimpan" title="Proyek Tersimpan"> */}
                <Tab eventKey="proyektersimpan" title="Bisnis Tersimpan" on>
                    {/* {verified == 1 && verified_advance == 3 ? ( */}
                    {dataIsComplete == 5 && verified_advance == 3 ? (
                        <Container style={{ marginTop: '30px' }}>
                            <Modal className="modalHapusPrykTersimpan" size={size} open={open} onClose={this.close} closeOnEscape={closeOnEscape} closeOnDimmerClick={closeOnDimmerClick}>
                                <Modal.Header>Hapus Proyek Tersimpan</Modal.Header>
                                <Modal.Content>
                                    <p>Apakah anda yakin akan menghapus semua data proyek tersimpan ?</p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button positive onClick={this.close}>Tidak</Button>
                                    <Button
                                        color="blue"
                                        icon='checkmark'
                                        labelPosition='right'
                                        content='Ya'
                                        onClick={(event) => { this.delPilihanProyek(this.state.dataChecked, this.state.paramsChecked, this.state.valcheckAll), event.preventDefault() }}
                                    />
                                </Modal.Actions>
                            </Modal>
                            <Row className="justify-content-md-center" style={{ paddingBottom: '30px' }}>
                                <Col xs md="12">
                                    <Card.Group>
                                        {(this.state && this.state.dataProyekTersimpan && this.state.dataProyekTersimpan.length > 0) ?
                                            <>
                                                <Checkbox checked={this.state.valcheckAll} label='Pilih Semua' onClick={(event) => { this.checkAll(this.state.valcheckAll), event.preventDefault() }} />
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <Button id="fonts" onClick={this.show('mini')} className="col-md-2" size='tiny' color="blue" style={{ marginTop: '-10px' }}>Hapus</Button>
                                                <div className="col-md-9">&nbsp;</div>
                                            </>
                                            : ''}

                                        {(this.state && this.state.dataProyekTersimpan && this.state.dataProyekTersimpan.length > 0) ?
                                            this.state.dataProyekTersimpan.map((itemWishlist, index) => (
                                                <>
                                                    <Segment className="col-12" key={itemWishlist.id}>
                                                        <Row>
                                                            <Col>
                                                                <Grid columns={3}>
                                                                    <Grid.Row>
                                                                        <Grid.Column width={4}>
                                                                            <Segment style={{ marginLeft: '30px', border: 'none', boxShadow: 'none' }}>
                                                                                <div className="ui fluid image">
                                                                                    <a className="ui white left corner label" onClick={(event) => { this.clickParamsDelete(index), event.preventDefault() }}
                                                                                        style={{ borderColor: 'transparent' }}>
                                                                                        <Checkbox checked={this.state.dataChecked[index]} style={{ marginLeft: '-19px', marginTop: '5px' }} value={itemWishlist.id}
                                                                                        />
                                                                                    </a>
                                                                                    <Image className="pryktrsmpn" src={itemWishlist.proyek_data.nama_file} wrapped ui={false} />
                                                                                    <h6 id="fonts" className="textbatalpryktrsmpn">Batal Otomatis setelah 3 hari</h6>
                                                                                </div>
                                                                            </Segment>
                                                                        </Grid.Column>
                                                                        <Grid.Column width={8}>
                                                                            <Segment style={{ marginLeft: '10px', border: 'none', boxShadow: 'none' }}>
                                                                                <Header id="fonts" as='h3' color='blue'>
                                                                                    {itemWishlist.proyek_overview.nama_project}
                                                                                    <Header.Subheader id="fonts" >
                                                                                        Rp. {itemWishlist.proyek_overview.slot_nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} / Slot
                                                                                    </Header.Subheader>
                                                                                </Header>
                                                                                <div>
                                                                                    <Button.Group>
                                                                                        <Button onClick={(event) => { 
                                                                                            this.minus(
                                                                                                dataSlot[index], 
                                                                                                index, 
                                                                                                itemWishlist.proyek_overview.slot_nominal
                                                                                            ), event.preventDefault() 
                                                                                        }} size='small' icon color="blue">
                                                                                            <Icon name='minus' />
                                                                                        </Button>
                                                                                        <Button size='small' basic>
                                                                                            {dataSlot.filter(isiSlot => isiSlot.ProyekID == itemWishlist.id_project).map(filteredIsi => (filteredIsi.Slot))}
                                                                                        </Button>
                                                                                        <Button onClick={(event) => {
                                                                                            this.plus(
                                                                                                dataSlot[index],
                                                                                                index,
                                                                                                itemWishlist.proyek_overview.slot_nominal,
                                                                                                itemWishlist.total_slot_terisi,
                                                                                                itemWishlist.proyek_overview.total_slot_req
                                                                                            ), event.preventDefault()
                                                                                        }} size='small' icon color="blue">
                                                                                            <Icon name='plus' />
                                                                                        </Button>
                                                                                    </Button.Group>
                                                                                </div>
                                                                            </Segment>
                                                                        </Grid.Column>
                                                                        <Grid.Column width={4}>
                                                                            <Segment style={{ marginLeft: '-10px', border: 'none', boxShadow: 'none' }}>
                                                                                <div className="ui fluid image">
                                                                                    <a className="ui white right corner label" onClick={() => deleteProyekTersimpan(itemWishlist.id)} style={{ borderColor: 'transparent' }}>
                                                                                        <i aria-hidden="true" className="times grey icon"></i>
                                                                                    </a>
                                                                                </div>
                                                                                <Header id="fonts" as='h3' color='blue'>
                                                                                    Rp. {dataSlot.filter(isiSlot => isiSlot.ProyekID == itemWishlist.id_project).map(filteredIsi => (filteredIsi.HargaSlot.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")))}
                                                                                </Header>
                                                                                {/* <Link 
                                                                                    to={{
                                                                                        pathname: "/Pembayaran/ProyekTerpilih", data: {
                                                                                            dataSlot: [dataSlot[index]],
                                                                                            dataProyek: dataSlot[index],
                                                                                            totalSlot: totalSlot,
                                                                                            totalPembelian: totalPembelian
                                                                                        }
                                                                                    }}>
                                                                                    <Button id="fonts" className="col-md-10" size='small' color="blue">Bayar</Button>
                                                                                </Link> */}
                                                                                <Button id="fonts" className="col-md-10" size='small' color="blue" 
                                                                                    onClick={
                                                                                        ()=>
                                                                                        this.handleClickBayar(
                                                                                            [dataSlot[index]],
                                                                                            dataSlot[index],
                                                                                            totalSlot,
                                                                                            totalPembelian
                                                                                        )
                                                                                    }>Bayar
                                                                                </Button>
                                                                            </Segment>
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                </Grid>
                                                            </Col>
                                                        </Row>
                                                    </Segment>
                                                </>
                                            )) :
                                            // <Row className="justify-content-md-center" style={{ paddingTop: '85px', paddingLeft: '200px' }}>
                                            //     <Col xs md="12">
                                            //         <Image src={proyk} size='medium' verticalAlign="middle" />
                                            //     </Col>
                                            // </Row>
                                            <Container style={{ paddingTop: '100px', paddingBottom: '200px' }}>
                                                <div style={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>Belum ada bisnis yang dapat di tampilkan</div>
                                                <div style={{ fontSize: '14px', textAlign: 'center', lineHeight: '1.5em' }}>Mulai investasi sekarang.. </div>
                                            </Container>
                                        }
                                    </Card.Group>

                                    {/* {(this.state && this.state.dataProyekTersimpan && this.state.dataProyekTersimpan.length > 0) ?
                                        <div className="bayarSekarang">
                                            <Segment vertical style={{ paddingBottom: '30px' }}>
                                                <Header id="fonts" as='h4' floated='left' color='blue'>
                                                    Bayar Sekaligus
                                                </Header>
                                                <Header id="fonts" as='h4' floated='right' color='blue'>
                                                    Total yang harus dibayarkan
                                                </Header>
                                            </Segment>
                                            <Segment vertical style={{ paddingBottom: '30px' }}>
                                                <Header id="fonts" as='h6' floated='left' color='grey'>
                                                    {totalSlot} slot dari {totalProyek} Bisnis
                                                </Header>
                                                <Header id="fonts" as='h4' floated='right' color='black'>
                                                    Rp. {totalPembelian.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </Header>
                                            </Segment>
                                            <Segment vertical>
                                                <Link to={{
                                                    pathname: "/Pembayaran/ProyekTerpilih", data: {
                                                        dataSlot: dataSlot, totalSlot: totalSlot, totalPembelian: totalPembelian,
                                                        dataProyek: dataSlot[0]
                                                    }
                                                }}>
                                                    <Button id="fonts" className="col-md-12" size='medium' color="blue">Bayar Sekarang</Button>
                                                </Link>
                                            </Segment>
                                        </div>
                                        : ''} */}

                                </Col>
                            </Row>
                        </Container>) :
                        (
                            <Container style={{ paddingTop: '100px', paddingBottom: '200px' }}>
                                <div style={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>Belum ada bisnis yang dapat di tampilkan</div>
                                <div style={{ fontSize: '14px', textAlign: 'center', lineHeight: '1.5em' }}>Silahkan lengkapi profil anda.. </div>
                            </Container>
                        )
                    }
                </Tab>

                <Tab eventKey="portofolio" title="Portofolio">
                    <Form name="formPortofolio" onSubmit={e => e.preventDefault()} encType="multipart/form-data"  >

                        {dataIsComplete == 5 ? (
                            <>
                                {(this.state.hideDivPortofolio == 'close') ? (
                                    <Container style={{ marginTop: '30px' }}>
                                        {/* Tutup dulu */}
                                        {/* <Row className="justify-content-md-center">
                                            <Col xs md="6">
                                                <Dropdown style={{ width: '100%' }} value={this.state.select} placeholder='Cari Transaksi' search selection options={this.state.filterListPaymentVA}
                                                    onChange={changeFilterListPaymentVA} onFocus={clickFilterListPaymentVA} />
                                            </Col>
                                            <Col xs md="4">
                                                <Dropdown
                                                    placeholder='Pilih Status' value={this.state.statusTr} fluid selection options={statusProyekBerjalan} onChange={changeFilterStatusTr} onFocus={clickFilterListPaymentVA}
                                                />
                                            </Col>
                                            <Col xs md="2">
                                                <Button color="red" size="small" onClick={clearFilter}>Clear</Button>
                                            </Col>
                                        </Row> */}

                                        <Row className="justify-content-md-center">
                                            <Col xs md="12" style={{ padding: '20px', marginTop: '0px' }}>
                                                <Card.Group>
                                                    {(
                                                        this.state && this.state.dataListPaymentVA && this.state.dataListPaymentVA.length > 0) ? 
                                                            this.state.dataListPaymentVA.map((itemWishlist, index) => (
                                                                <>
                                                                    <Segment className="col-12" key={itemWishlist.id}>
                                                                        <Row>
                                                                            <Col>
                                                                                <Grid columns={3}>
                                                                                    <Grid.Row>
                                                                                        <Grid.Column width={4}>
                                                                                            <Segment onClick={(event) => { this.clickDetailPortofolio(itemWishlist.proyek_overview.id_project, itemWishlist.itemPVA) }}
                                                                                                style={{ marginLeft: '15px', border: 'none', boxShadow: 'none' }}>
                                                                                                <div className="ui fluid image">
                                                                                                    <Image src={itemWishlist.proyek_data.nama_file} wrapped ui={false} className="imgProyekBerjalan" />
                                                                                                </div>
                                                                                            </Segment>
                                                                                        </Grid.Column>

                                                                                        <Grid.Column width={7}>
                                                                                            <Segment style={{ marginLeft: '0px', border: 'none', boxShadow: 'none' }} onClick={(event) => { this.clickDetailPortofolio(itemWishlist.proyek_overview.id_project, itemWishlist.itemPVA) }}>
                                                                                                <Header id="fonts" as='h4' color='blue'>
                                                                                                    <a href="#" className="hrefProyekBrjln"> {itemWishlist.proyek_overview.nama_project} </a>
                                                                                                    <Header.Subheader id="fonts" >
                                                                                                        <div id="fonts" className="">Total Pembelian</div>
                                                                                                    </Header.Subheader>
                                                                                                </Header>
                                                                                            </Segment>
                                                                                            <Segment style={{ marginLeft: '0px', marginTop: '-25px', border: 'none', boxShadow: 'none' }} onClick={"(event) => { this.clickDetailPortofolio(itemWishlist.proyek_overview.id_project, itemWishlist.itemPVA) }"}>
                                                                                                <Header id="fonts" as='h4' color='blue'>
                                                                                                    No Transaksi
                                                                                                    {/* Kode Efek */}
                                                                                                    <Header.Subheader id="fonts" >
                                                                                                        <h3 id="fonts" className="textkode">{itemWishlist.itemPVA.trx_id}</h3>
                                                                                                        <h3 id="fonts" className="textbatal">{itemWishlist.itemPVA.create_at} WIB</h3>
                                                                                                    </Header.Subheader>
                                                                                                </Header>
                                                                                            </Segment>
                                                                                        </Grid.Column>
                                                                                        <Grid.Column width={5}>
                                                                                            <Segment style={{ marginLeft: '0px', border: 'none', boxShadow: 'none' }} >
                                                                                                Rp. {itemWishlist.proyek_overview.capital_needed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                                                <div className="midTotalSlot">Rp. {itemWishlist.itemPVA.amount_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} / {itemWishlist.itemPVA.slot} Slot</div>
                                                                                            </Segment>
                                                                                            <Segment style={{ marginLeft: '0px', marginTop: '-25px', border: 'none', boxShadow: 'none' }} >
                                                                                                <Header id="fonts" as='h4' color='blue'>Status
                                                                                                    <Header.Subheader id="fonts" >
                                                                                                        <div className="rigTotalSlot">{itemWishlist.itemPVA.statusPVA}</div>
                                                                                                    </Header.Subheader>
                                                                                                </Header>
                                                                                            </Segment>
                                                                                        </Grid.Column>
                                                                                    </Grid.Row>
                                                                                </Grid>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col>
                                                                                <Grid columns={3}>
                                                                                    <Grid.Row>
                                                                                        <Grid.Column width={9}>
                                                                                            <Segment style={{ marginLeft: '15px', marginTop: '-25px', border: 'none', boxShadow: 'none' }} >
                                                                                                { 
                                                                                                    itemWishlist.itemPVA.funding_detail_status == null ?
                                                                                                        <div style={{ fontSize: '11px' }}>
                                                                                                            <span style={{ color: 'red' }}>*</span>Syarat pembatalan Efek tidak boleh lebih dari 2x24 jam
                                                                                                        </div>
                                                                                                    :itemWishlist.itemPVA.funding_detail_status == 2 ?
                                                                                                        <div style={{ fontSize: '11px' }}>
                                                                                                            <span style={{ color: 'red' }}>*</span>Syarat pembatalan Efek tidak boleh lebih dari 2x24 jam
                                                                                                        </div>
                                                                                                    :<></>
                                                                                                }
                                                                                            </Segment>
                                                                                        </Grid.Column>
                                                                                        <Grid.Column width={2}>

                                                                                        </Grid.Column>
                                                                                        <Grid.Column width={5}>
                                                                                            <Segment style={{ marginLeft: '0px', marginTop: '-25px', border: 'none', boxShadow: 'none' }} >
                                                                                                <Header id="fonts" as='h4' color='blue'>
                                                                                                    <Header.Subheader id="fonts" >
                                                                                                        { 
                                                                                                        itemWishlist.itemPVA.funding_detail_status == null ?
                                                                                                            <Button className='mini'
                                                                                                                onClick={(event) => { this.handleCancel(itemWishlist) }}
                                                                                                                disabled={itemWishlist.itemPVA.isCancel}
                                                                                                                color={itemWishlist.itemPVA.isCancel ? 'gray' : 'red'}
                                                                                                            >Batal Pendanaan</Button>
                                                                                                        : 
                                                                                                        itemWishlist.itemPVA.funding_detail_status == 2 ?
                                                                                                            <Button className='mini'
                                                                                                                onClick={(event) => { this.handleCancel(itemWishlist) }}
                                                                                                                disabled={itemWishlist.itemPVA.isCancel}
                                                                                                                color={itemWishlist.itemPVA.isCancel ? 'gray' : 'red'}
                                                                                                            >Batal Pendanaan</Button>
                                                                                                        : 
                                                                                                        // itemWishlist.itemPVA.funding_detail_status == 2 ?
                                                                                                        //     <Button style={{ fontSize: '11px', background: 'green', 
                                                                                                        //         color: 'white', borderRadius: 5, padding: '5px'}}>Terkonfirmasi</Button>
                                                                                                        // :
                                                                                                        itemWishlist.itemPVA.funding_detail_status == 3 ?
                                                                                                            <Button style={{ fontSize: '11px', background: 'gray', 
                                                                                                                color: 'white', borderRadius: 5, padding: '5px'}}>Proses Pembatalan</Button>
                                                                                                        :
                                                                                                        itemWishlist.itemPVA.funding_detail_status == 4 ?
                                                                                                            <Button style={{ fontSize: '11px', background: 'gray', 
                                                                                                                color: 'white', borderRadius: 5, padding: '5px'}}>Proses Pembatalan</Button>
                                                                                                        :
                                                                                                        itemWishlist.itemPVA.funding_detail_status == 5 ?
                                                                                                            <label style={{ fontSize: '12px', 
                                                                                                                color: 'red', borderRadius: 5, padding: '0px'}}>Pendanaan Dibatalkan</label>
                                                                                                        :<></>
                                                                                                        }
                                                                                                    </Header.Subheader>
                                                                                                </Header>
                                                                                            </Segment>
                                                                                        </Grid.Column>
                                                                                    </Grid.Row>
                                                                                </Grid>
                                                                            </Col>
                                                                        </Row>
                                                                    </Segment>
                                                                </>
                                                            ))
                                                        :
                                                        <Container style={{ paddingTop: '100px', paddingBottom: '200px' }}>
                                                            <div style={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>Belum ada bisnis yang dapat di tampilkan</div>
                                                            <div style={{ fontSize: '14px', textAlign: 'center', lineHeight: '1.5em' }}>Mulai investasi sekarang..</div>
                                                        </Container>
                                                    }

                                                    {(this.state && this.state.dataListPaymentVA && this.state.dataListPaymentVA.length > 0 && this.state.isFilter == false && this.state.dataProyekBerjalan.length > 4) ?
                                                        <>
                                                            <Container align="right">
                                                                <Row>
                                                                    <Col xs md="12">
                                                                        <Pagination
                                                                            size='mini'
                                                                            activePage={this.state.activePage}
                                                                            totalPages={Math.ceil(this.state.dataProyekBerjalan.length / 4)}
                                                                            onPageChange={this.PaginationClick}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                        </>
                                                    : null }

                                                </Card.Group>
                                            </Col>
                                        </Row>
                                    </Container>
                                ) : (
                                    <Container style={{ marginTop: '10px' }}>
                                        <Row className="justify-content-md-center">
                                            <Col xs md="12">
                                                <Breadcrumb className="brPrykBerjalan">
                                                    <Breadcrumb.Section link onClick={clickbackPortofolio}>Portofolio</Breadcrumb.Section>
                                                    <Breadcrumb.Divider />
                                                    <Breadcrumb.Section active>Detail</Breadcrumb.Section>
                                                </Breadcrumb>
                                                <Breadcrumb className="brPrykBerjalan" style={{ float: 'right' }}>
                                                    <Breadcrumb.Section link onClick={clickbackPortofolio}>{'<< Back'}</Breadcrumb.Section>
                                                </Breadcrumb>

                                                <Segment style={{ marginTop: '0px', padding: '10px' }}>
                                                    <Row>
                                                        <Col>
                                                            <Grid columns={3}>
                                                                <Grid.Row>
                                                                    <Grid.Column width={4}>
                                                                        <Segment style={{ marginLeft: '10px', border: 'none', boxShadow: 'none' }}>
                                                                            <div className="ui fluid image">
                                                                                <Image src={datadetailProyekBrjln.proyek_data[0].nama_file} size='mini' wrapped ui={false} />
                                                                            </div>
                                                                        </Segment>
                                                                    </Grid.Column>
                                                                    <Grid.Column width={7}>
                                                                        <Segment style={{ marginLeft: '0px', border: 'none', boxShadow: 'none' }}>
                                                                            <Header id="fonts" as='h4' color='blue'>
                                                                                {datadetailProyekBrjln.nama_project}
                                                                                <Header.Subheader id="fonts" >
                                                                                    Total Pembelian
                                                                                </Header.Subheader>
                                                                            </Header>
                                                                        </Segment>
                                                                        <Segment style={{ marginLeft: '0px', marginTop: '-25px', border: 'none', boxShadow: 'none' }} onClick={"(event) => { this.clickDetailPortofolio(itemWishlist.proyek_overview.id_project, itemWishlist.itemPVA) }"}>
                                                                            <Header id="fonts" as='h4' color='blue'>No Transaksi
                                                                                <Header.Subheader id="fonts" >
                                                                                    <h3 id="fonts" className="textkode">{dataitemPVA.trx_id}</h3>
                                                                                    <h3 id="fonts" className="textbatal">{dataitemPVA.create_at} WIB</h3>
                                                                                </Header.Subheader>
                                                                            </Header>
                                                                        </Segment>
                                                                    </Grid.Column>
                                                                    <Grid.Column width={5}>
                                                                        <Segment style={{ marginLeft: '0px', border: 'none', boxShadow: 'none' }}>
                                                                            {/* {this.formatRupiah(datadetailProyekBrjln.capital_needed)} */}
                                                                            Rp. {datadetailProyekBrjln.capital_needed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                            <div className="midTotalSlot">Rp. {dataitemPVA.amount_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} / {dataitemPVA.slot} Slot</div>
                                                                        </Segment>
                                                                        <Segment style={{ marginLeft: '0px', marginTop: '-25px', border: 'none', boxShadow: 'none' }} >
                                                                            <Header id="fonts" as='h4' color='blue'>Status
                                                                                <Header.Subheader id="fonts" >
                                                                                    <div className="rigTotalSlot">{dataitemPVA.statusPVA}</div>
                                                                                </Header.Subheader>
                                                                            </Header>
                                                                        </Segment>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>
                                                        </Col>
                                                    </Row>
                                                </Segment>
                                                <Segment className="col-12" style={{ marginTop: '-20px' }}>
                                                    <Row>
                                                        <Col>
                                                            <Grid columns={3}>
                                                                <Grid.Row>
                                                                    <Grid.Column width={11}>
                                                                        <Segment style={{ marginLeft: '10px', border: 'none', boxShadow: 'none' }}>
                                                                            <Header id="fonts" as='h3' color='blue'>
                                                                                Status
                                                                                <Header.Subheader id="fonts" >
                                                                                    {(datadetailProyekBrjln.status == 6) ?
                                                                                        <Image src={proykpengumpulandana} size='huge' verticalAlign="middle" /> : ''}
                                                                                    {(datadetailProyekBrjln.status == 7 || datadetailProyekBrjln.status == 8) ?
                                                                                        <Image src={proykfullpendanaan} size='huge' verticalAlign="middle" /> : ''}
                                                                                    {(datadetailProyekBrjln.status == 9) ?
                                                                                        <Image src={proykproyekbrjln} size='huge' verticalAlign="middle" /> : ''}
                                                                                    {(datadetailProyekBrjln.status == 10) ?
                                                                                        <Image src={proykproyekslsai} size='huge' verticalAlign="middle" /> : ''}
                                                                                    {(datadetailProyekBrjln.status == 11) ?
                                                                                        <Image src={proykbghsl} size='huge' verticalAlign="middle" /> : ''}
                                                                                    {(datadetailProyekBrjln.status == 100) ?
                                                                                        <Image src={proykslsai} size='huge' verticalAlign="middle" /> : ''}
                                                                                </Header.Subheader>
                                                                                <Table className="tblproyk">
                                                                                    {body}
                                                                                </Table>
                                                                            </Header>
                                                                        </Segment>
                                                                    </Grid.Column>
                                                                    {/* <Grid.Column width={5}>
                                                                        <Segment style={{ marginLeft: '10px', border: 'none', boxShadow: 'none', top: '100px' }}>
                                                                            <Button id="fonts" className="col-md-10 btnAkad" disabled size='small' color="blue">Download E-Report</Button>
                                                                        </Segment>
                                                                    </Grid.Column> */}
                                                                </Grid.Row>
                                                            </Grid>
                                                        </Col>
                                                    </Row>
                                                </Segment>
                                            </Col>
                                        </Row>
                                    </Container>
                                )}
                            </>
                        )
                        :
                        (
                            <Container style={{ paddingTop: '100px', paddingBottom: '200px' }}>
                                <div style={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>Belum ada bisnis yang dapat di tampilkan</div>
                                <div style={{ fontSize: '14px', textAlign: 'center', lineHeight: '1.5em' }}>Silahkan lengkapi profil anda.. </div>
                            </Container>
                        )}
                    </Form>
                </Tab>

                {/* <Tab eventKey="proyekberjalan" title="Proyek Berjalan"> */}
                <Tab eventKey="proyekberjalan" title="Transaksi">

                    <Form name="formMusyarakah" onSubmit={e => e.preventDefault()} encType="multipart/form-data"  >
                        <Modal className="modalTTDEakad" size={size} open={openTandaTangan} onClose={closeMdlTTD} closeIcon closeOnEscape={closeOnEscape} closeOnDimmerClick={closeOnDimmerClick}>
                            <Modal.Header>Download Akad</Modal.Header>
                            <Modal.Content>

                            </Modal.Content>
                        </Modal>
                        {dataIsComplete == 5 ? (
                            <>
                                {(this.state.hideDivProyekBrjln == 'close') ? (
                                    <Container style={{ marginTop: '30px' }}>
                                        {/* Tutup dulu */}
                                        {/* <Row className="justify-content-md-center">
                                            <Col xs md="6">
                                                <Dropdown style={{ width: '100%' }} value={this.state.select} placeholder='Cari Transaksi' search selection options={this.state.filterListPaymentVA}
                                                    onChange={changeFilterListPaymentVA} onFocus={clickFilterListPaymentVA} />
                                            </Col>
                                            <Col xs md="4">
                                                <Dropdown
                                                    placeholder='Pilih Status' value={this.state.statusTr} fluid selection options={statusProyekBerjalan} onChange={changeFilterStatusTr} onFocus={clickFilterListPaymentVA}
                                                />
                                            </Col>
                                            <Col xs md="2">
                                                <Button color="red" size="small" onClick={clearFilter}>Clear</Button>
                                            </Col>
                                        </Row> */}

                                        <Row className="justify-content-md-center">
                                            <Col xs md="12" style={{ padding: '20px', marginTop: '0px' }}>
                                                <Card.Group>
                                                    {/* {(this.state && this.state.dataListPaymentVA && this.state.dataListPaymentVA.length > 0) ?
                                                        this.state.dataListPaymentVA.map((itemWishlist, index) => ( */}
                                                    {(this.state && this.state.dataListTransaction && this.state.dataListTransaction.length > 0) ?
                                                        this.state.dataListTransaction.map((dataList, index) => (
                                                            <>
                                                                <Segment className="col-12" key={dataList.id}>
                                                                    <Row>
                                                                        <Col>
                                                                            {/* <Grid columns={3}>
                                                                                <Grid.Row>
                                                                                    <Grid.Column width={4}>
                                                                                        <Segment onClick={(event) => { this.clickdetailProyekBrjln(itemWishlist.proyek_overview.id_project, itemWishlist.itemPVA) }}
                                                                                            style={{ marginLeft: '15px', border: 'none', boxShadow: 'none' }}>
                                                                                            <div className="ui fluid image">
                                                                                                <Image src={itemWishlist.proyek_data.nama_file} wrapped ui={false} className="imgProyekBerjalan" />
                                                                                                <h3 id="fonts" className="textnotransaksi">No Transaksi</h3>
                                                                                                <h3 id="fonts" className="textkode">{itemWishlist.itemPVA.trx_id}</h3>
                                                                                                <h3 id="fonts" className="textbatal">{itemWishlist.itemPVA.create_at} WIB</h3>
                                                                                            </div>
                                                                                        </Segment>
                                                                                    </Grid.Column>
                                                                                    <Grid.Column width={7}>
                                                                                        <Segment style={{ marginLeft: '10px', border: 'none', boxShadow: 'none' }} onClick={(event) => { this.clickdetailProyekBrjln(itemWishlist.proyek_overview.id_project, itemWishlist.itemPVA) }}>
                                                                                            <Header id="fonts" as='h4' color='blue'>
                                                                                                <a href="#" className="hrefProyekBrjln" onClick={(event) => { this.clickdetailProyekBrjln(itemWishlist.proyek_overview.id_project, itemWishlist.itemPVA) }}>
                                                                                                    {itemWishlist.proyek_overview.nama_project}
                                                                                                </a>
                                                                                                <Header.Subheader id="fonts" >
                                                                                                    Rp. {itemWishlist.proyek_overview.capital_needed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                                                </Header.Subheader>
                                                                                            </Header>
                                                                                            <div className="midProyekBerjalan" onClick={(event) => { this.clickdetailProyekBrjln(itemWishlist.proyek_overview.id_project, itemWishlist.itemPVA) }}>
                                                                                                <h4 id="fonts" className="textnotransaksi">Total</h4>
                                                                                                <div className="midTotalSlot">Rp. {itemWishlist.itemPVA.amount_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} / {itemWishlist.itemPVA.slot} Slot</div>
                                                                                            </div>
                                                                                        </Segment>
                                                                                    </Grid.Column>
                                                                                    <Grid.Column width={5}>
                                                                                        <Segment style={{ marginLeft: '10px', border: 'none', boxShadow: 'none' }} >
                                                                                            {(itemWishlist.itemPVA.statusPVA == 'Kadaluarsa' || itemWishlist.itemPVA.statusPVA == 'Menunggu Pembayaran' || itemWishlist.itemPVA.statusPVA == 'Masa Penawaran'
                                                                                                || itemWishlist.itemPVA.statusPVA == 'Selesai' || itemWishlist.itemPVA.Musyarakah.file_name == '') ?
                                                                                                <>
                                                                                                    <Button id="fonts" disabled className="col-md-10 btnAkad" size='small' color="blue" >Download Akad</Button>
                                                                                                    <div className="divNamaPic">
                                                                                                        <div className="nPic">
                                                                                                            <div className="ui middle aligned left aligned grid container">
                                                                                                                <div className="ui fluid segment grContainer">
                                                                                                                    <input disabled type="file" name={"fileMusyarakah" + itemWishlist.itemPVA.funding_id} className="inputfile" id={'embedpollfileinput' + itemWishlist.itemPVA.funding_id} onChange={(event) => { this.onFileChange(event, 99, itemWishlist.itemPVA.funding_id) }} />
                                                                                                                    <label htmlFor={'embedpollfileinput' + itemWishlist.itemPVA.funding_id} className="ui mini blue right floated button" style={{ fontSize: '9px', marginTop: '5px', opacity: '0.5' }}>
                                                                                                                        <i className="ui upload icon"></i>
                                                                                                                        {(fileMusyarakah.funding_id == itemWishlist.itemPVA.funding_id) ? fileMusyarakah.file : ''}
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                                <div className="ui fluid segment grContainer" style={{ marginLeft: '5px' }}>
                                                                                                                    <Button id="fonts" className='uploadfilemusyarakah' color='blue' disabled size='mini' type="button" style={{ fontSize: '9px', marginTop: '5px' }}>Upload</Button>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="rigProyekBerjalan" onClick={(event) => { this.clickdetailProyekBrjln(itemWishlist.proyek_overview.id_project, itemWishlist.itemPVA) }}>
                                                                                                        <h4 id="fonts" className="textnotransaksi">Status</h4>
                                                                                                        <div className="rigTotalSlot">{itemWishlist.itemPVA.statusPVA}</div>
                                                                                                    </div>
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    <Button href={itemWishlist.itemPVA.Musyarakah.file_name} target="_blank" id="fonts" className="col-md-10 btnAkad" size='small' color="blue" >Download Akad</Button>
                                                                                                    <div className="divNamaPic">
                                                                                                        <div className="nPic">
                                                                                                            <div className="ui middle aligned left aligned grid container">
                                                                                                                <div className="ui fluid segment grContainer">
                                                                                                                    <input type="file" name={"fileMusyarakah" + itemWishlist.itemPVA.funding_id} className="inputfile" id={'embedpollfileinput' + itemWishlist.itemPVA.funding_id} onChange={(event) => { this.onFileChange(event, 99, itemWishlist.itemPVA.funding_id) }} />
                                                                                                                    <label htmlFor={'embedpollfileinput' + itemWishlist.itemPVA.funding_id} className="ui mini blue right floated button" style={{ fontSize: '9px', marginTop: '5px' }}>
                                                                                                                        <i className="ui upload icon"></i>
                                                                                                                        {(fileMusyarakah.funding_id == itemWishlist.itemPVA.funding_id) ? fileMusyarakah.file : ''}
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                                <div className="ui fluid segment grContainer" style={{ marginLeft: '5px' }}>
                                                                                                                    <Button id="fonts" className={"uplFileMusyarakah" + itemWishlist.itemPVA.funding_id} color='blue' onClick={(event) => { handleUplMusyarakah(itemWishlist.itemPVA.funding_id) }}
                                                                                                                        size='mini' type="button" style={{ fontSize: '9px', marginTop: '5px' }}>Upload</Button>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="rigProyekBerjalan" onClick={(event) => { this.clickdetailProyekBrjln(itemWishlist.proyek_overview.id_project, itemWishlist.itemPVA) }}>
                                                                                                        <h3 id="fonts" className="textnotransaksi">Status</h3>
                                                                                                        <div className="rigTotalSlot">{itemWishlist.itemPVA.statusPVA}</div>
                                                                                                    </div>
                                                                                                </>
                                                                                            }
                                                                                        </Segment>
                                                                                    </Grid.Column>
                                                                                </Grid.Row>
                                                                            </Grid> */}
                                                                            <Grid columns={3}>
                                                                                <Grid.Row>
                                                                                    <Grid.Column width={6}>
                                                                                        <Segment style={{ marginLeft: '10px', border: 'none', boxShadow: 'none' }} onClick={(event) => { 'this.clickdetailProyekBrjln(itemWishlist.proyek_overview.id_project, itemWishlist.itemPVA)' }}>
                                                                                            <Header id="fonts" as='h4' color='blue'>
                                                                                                <a href="#" className="hrefProyekBrjln" onClick={(event) => { 'this.clickdetailProyekBrjln(dataList.proyek_overview.id_project, itemWishlist.itemPVA) '}}>
                                                                                                    {dataList.nama_project}
                                                                                                </a>
                                                                                                <Header.Subheader id="fonts" >
                                                                                                    <div style={{fontSize: '11px'}}>
                                                                                                        No. Transaksi : {dataList.trx_id}</div>
                                                                                                </Header.Subheader>
                                                                                            </Header>
                                                                                        </Segment>
                                                                                    </Grid.Column>
                                                                                    <Grid.Column width={5}>
                                                                                        <Segment style={{ marginLeft: '10px', border: 'none', boxShadow: 'none', verticalAlign: 'center' }} onClick={(event) => { 'this.clickdetailProyekBrjln(itemWishlist.proyek_overview.id_project, itemWishlist.itemPVA)' }}>
                                                                                            <Header id="fonts" as='h4' color='blue'>
                                                                                                <div className="midTotalSlot">Rp. {dataList.amount_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} / {dataList.slot} Slot</div>
                                                                                            </Header>
                                                                                        </Segment>
                                                                                    </Grid.Column>
                                                                                    <Grid.Column width={5}>
                                                                                        <Segment style={{ marginLeft: '10px', border: 'none', boxShadow: 'none', verticalAlign: 'center' }} onClick={(event) => { 'this.clickdetailProyekBrjln(itemWishlist.proyek_overview.id_project, itemWishlist.itemPVA)' }}>
                                                                                            <Header id="fonts" as='h4' color='blue' style={{textAlign:'right'}}>
                                                                                                {
                                                                                                    dataList.va_status == 0 ?
                                                                                                    <Button className='mini' color='gray' onClick={'cek histori payment'}>Menunggu Pembayaran</Button>
                                                                                                    : 
                                                                                                    <>
                                                                                                    {
                                                                                                        dataList.funding_detail_status == 3 ? 
                                                                                                        <Button className='mini' color='blue' onClick={()=> this.handleRefund(dataList)}>Ajukan Pengembalian Dana</Button>
                                                                                                        :dataList.funding_detail_status == 4 ? 
                                                                                                        <Button className='mini' color='orange'>Pengajuan Pengembalian Dana Sedang Diproses</Button>
                                                                                                        :dataList.funding_detail_status == 5 ?   
                                                                                                        <Button className='mini' color='green' onClick={'cek histori payment'}>Pengembalian Dana Berhasil</Button>
                                                                                                        :
                                                                                                        <Button className='mini' color='green' onClick={'cek histori payment'}>Pembayaran Lunas</Button>
                                                                                                    }
                                                                                                    </>
                                                                                                }
                                                                                            </Header>
                                                                                        </Segment>
                                                                                    </Grid.Column>
                                                                                </Grid.Row>
                                                                            </Grid>
                                                                        </Col>
                                                                    </Row>
                                                                </Segment>
                                                            </>
                                                        ))
                                                        :
                                                        // <Row className="justify-content-md-center" style={{ paddingTop: '85px', paddingLeft: '200px' }}>
                                                        //     <Col xs md="12">
                                                        //         <Image src={proyk} size='medium' verticalAlign="middle" />
                                                        //     </Col>
                                                        // </Row>
                                                        <Container style={{ paddingTop: '100px', paddingBottom: '200px' }}>
                                                            <div style={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>Belum ada transaksi yang dapat di tampilkan</div>
                                                            <div style={{ fontSize: '14px', textAlign: 'center', lineHeight: '1.5em' }}>Mulai investasi sekarang.. </div>
                                                        </Container>
                                                    }

                                                    {(this.state && this.state.dataListPaymentVA && this.state.dataListPaymentVA.length > 0 && this.state.isFilter == false && this.state.dataProyekBerjalan.length > 4) ?
                                                        <>
                                                            <Container align="right">
                                                                <Row>
                                                                    <Col xs md="12">
                                                                        <Pagination
                                                                            size='mini'
                                                                            activePage={this.state.activePage}
                                                                            totalPages={Math.ceil(this.state.dataProyekBerjalan.length / 4)}
                                                                            onPageChange={this.PaginationClick}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                        </>
                                                        : ''}

                                                </Card.Group>
                                            </Col>
                                        </Row>
                                    </Container>
                                ) : (
                                    <Container style={{ marginTop: '10px' }}>
                                        <Row className="justify-content-md-center">
                                            <Col xs md="12">
                                                <Breadcrumb className="brPrykBerjalan">
                                                    <Breadcrumb.Section link onClick={clickbackProyekBrjln}>Transaksi</Breadcrumb.Section>
                                                    <Breadcrumb.Divider />
                                                    <Breadcrumb.Section active>Detail</Breadcrumb.Section>
                                                </Breadcrumb>
                                                <Breadcrumb className="brPrykBerjalan" style={{ float: 'right' }}>
                                                    <Breadcrumb.Section link onClick={clickbackProyekBrjln}>{'<< Back'}</Breadcrumb.Section>
                                                </Breadcrumb>

                                                <Segment style={{ marginTop: '0px', padding: '10px' }}>
                                                    <Row>
                                                        <Col>
                                                            <Grid columns={3}>
                                                                <Grid.Row>
                                                                    <Grid.Column width={4}>
                                                                        <Segment style={{ marginLeft: '10px', border: 'none', boxShadow: 'none' }}>
                                                                            <div className="ui fluid image">
                                                                                <Image src={datadetailProyekBrjln.proyek_data[0].nama_file} size='mini' wrapped ui={false} />
                                                                                <h3 id="fonts" className="textnotransaksi">No Transaksi</h3>
                                                                                <h3 id="fonts" className="textkode">{dataitemPVA.trx_id}</h3>
                                                                                <h3 id="fonts" className="textbatal">{dataitemPVA.create_at} WIB</h3>
                                                                            </div>
                                                                        </Segment>
                                                                    </Grid.Column>
                                                                    <Grid.Column width={7}>
                                                                        <Segment style={{ marginLeft: '10px', border: 'none', boxShadow: 'none' }}>
                                                                            <Header id="fonts" as='h3' color='blue'>
                                                                                {datadetailProyekBrjln.nama_project}
                                                                                <Header.Subheader id="fonts" >
                                                                                    {/* {this.formatRupiah(datadetailProyekBrjln.capital_needed)} */}
                                                                                    Rp. {datadetailProyekBrjln.capital_needed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                                </Header.Subheader>
                                                                            </Header>
                                                                            <div className="midProyekBerjalan">
                                                                                <h3 id="fonts" className="textnotransaksi">Total</h3>
                                                                                <div className="midTotalSlot">Rp. {dataitemPVA.amount_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} / {dataitemPVA.slot} Slot</div>
                                                                            </div>
                                                                        </Segment>
                                                                    </Grid.Column>
                                                                    <Grid.Column width={5}>
                                                                        <Segment style={{ marginLeft: '0px', border: 'none', boxShadow: 'none' }}>
                                                                            {(dataitemPVA.statusPVA == 'Kadaluarsa' || dataitemPVA.statusPVA == 'Menunggu Pembayaran' || dataitemPVA.statusPVA == 'Masa Penawaran' ||
                                                                                dataitemPVA.statusPVA == 'Selesai' || datadetailMusyarakah.file_name == '') ?
                                                                                <>
                                                                                    <Button id="fonts" disabled className="col-md-10 btnAkad" size='small' color="blue" >Download Akad</Button>
                                                                                    <div className="divNamaPic">
                                                                                        <div className="nPic">
                                                                                            <div className="ui middle aligned left aligned grid container">
                                                                                                <div className="ui fluid segment grContainer">
                                                                                                    <input disabled type="file" className="inputfile" />
                                                                                                    <label className="ui mini blue right floated button" style={{ fontSize: '9px', marginTop: '5px', opacity: '0.5' }}>
                                                                                                        <i className="ui upload icon"></i>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="ui fluid segment grContainer" style={{ marginLeft: '5px' }}>
                                                                                                    <Button id="fonts" color='blue' disabled className="uploadfilemusyarakah" size='mini' type="button" style={{ fontSize: '9px', marginTop: '5px' }}>Upload</Button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <Button href={datadetailMusyarakah.file_name} target="_blank" id="fonts" className="col-md-10 btnAkad" size='small' color="blue" >Download Akad</Button>
                                                                                    <div className="divNamaPic">
                                                                                        <div className="nPic">
                                                                                            <div className="ui middle aligned left aligned grid container">
                                                                                                <div className="ui fluid segment grContainer">
                                                                                                    <input type="file" name={"fileMusyarakah" + datadetailMusyarakah.funding_id} className="inputfile" id={'embedpollfileinput' + datadetailMusyarakah.funding_id} onChange={(event) => { this.onFileChange(event, 99, datadetailMusyarakah.funding_id) }} />
                                                                                                    <label htmlFor={'embedpollfileinput' + datadetailMusyarakah.funding_id} className="ui mini blue right floated button" style={{ fontSize: '9px', marginTop: '5px' }}>
                                                                                                        <i className="ui upload icon"></i>
                                                                                                        {(fileMusyarakah.funding_id == datadetailMusyarakah.funding_id) ? fileMusyarakah.file : ''}
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="ui fluid segment grContainer" style={{ marginLeft: '5px' }}>
                                                                                                    <Button id="fonts" className={"uplFileMusyarakah" + datadetailMusyarakah.funding_id} color='blue' onClick={(event) => { handleUplMusyarakah(datadetailMusyarakah.funding_id) }}
                                                                                                        size='mini' type="button" style={{ fontSize: '9px', marginTop: '5px' }}>Upload</Button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                            <div className="rigProyekBerjalan">
                                                                                <h3 id="fonts" className="textnotransaksi">Status</h3>
                                                                                <div className="rigTotalSlot">{dataitemPVA.statusPVA}</div>
                                                                            </div>
                                                                        </Segment>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>
                                                        </Col>
                                                    </Row>
                                                </Segment>
                                                <Segment className="col-12" style={{ marginTop: '-20px' }}>
                                                    <Row>
                                                        <Col>
                                                            <Grid columns={3}>
                                                                <Grid.Row>
                                                                    <Grid.Column width={11}>
                                                                        <Segment style={{ marginLeft: '10px', border: 'none', boxShadow: 'none' }}>
                                                                            <Header id="fonts" as='h3' color='blue'>
                                                                                Status
                                                                                <Header.Subheader id="fonts" >
                                                                                    {(datadetailProyekBrjln.status == 6) ?
                                                                                        <Image src={proykpengumpulandana} size='huge' verticalAlign="middle" /> : ''}
                                                                                    {(datadetailProyekBrjln.status == 7 || datadetailProyekBrjln.status == 8) ?
                                                                                        <Image src={proykfullpendanaan} size='huge' verticalAlign="middle" /> : ''}
                                                                                    {(datadetailProyekBrjln.status == 9) ?
                                                                                        <Image src={proykproyekbrjln} size='huge' verticalAlign="middle" /> : ''}
                                                                                    {(datadetailProyekBrjln.status == 10) ?
                                                                                        <Image src={proykproyekslsai} size='huge' verticalAlign="middle" /> : ''}
                                                                                    {(datadetailProyekBrjln.status == 11) ?
                                                                                        <Image src={proykbghsl} size='huge' verticalAlign="middle" /> : ''}
                                                                                    {(datadetailProyekBrjln.status == 100) ?
                                                                                        <Image src={proykslsai} size='huge' verticalAlign="middle" /> : ''}
                                                                                </Header.Subheader>
                                                                                <Table className="tblproyk">
                                                                                    {body}
                                                                                </Table>
                                                                            </Header>
                                                                        </Segment>
                                                                    </Grid.Column>
                                                                    <Grid.Column width={5}>
                                                                        <Segment style={{ marginLeft: '10px', border: 'none', boxShadow: 'none', top: '100px' }}>
                                                                            <Button id="fonts" className="col-md-10 btnAkad" disabled size='small' color="blue">Download E-Report</Button>
                                                                        </Segment>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>
                                                        </Col>
                                                    </Row>
                                                </Segment>
                                            </Col>
                                        </Row>
                                    </Container>
                                )}
                            </>
                        )
                            :
                            (
                                <Container style={{ paddingTop: '100px', paddingBottom: '200px' }}>
                                    <div style={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>Belum ada transaksi yang dapat di tampilkan</div>
                                    <div style={{ fontSize: '14px', textAlign: 'center', lineHeight: '1.5em' }}>Silahkan lengkapi profil anda.. </div>
                                </Container>
                            )}
                    </Form>
                </Tab>

                <Tab eventKey="favorit" title="Bisnis Favorit">
                    {dataIsComplete == 5 && verified_advance == 3 ? (
                        <Container style={{ marginTop: '30px' }}>
                            <Row className="justify-content-md-center">
                                <Col xs md="12">
                                    <Card.Group itemsPerRow={3}>
                                        {(this.state && this.state.favProyekTersimpan && this.state.favProyekTersimpan.length > 0) ?
                                            this.state.favProyekTersimpan.map(itemWishlist => (
                                                <Card raised color='red' key={itemWishlist.id}>
                                                    <div className="ui fluid image">
                                                        <a className="ui red left corner label" onClick={() => deleteProyekTersimpan(itemWishlist.id)}><i aria-hidden="true" className="heart icon"></i></a>
                                                        <Image className="imgFavoritTab" src={itemWishlist.proyek_data.nama_file} wrapped ui={false} />
                                                    </div>
                                                    <Card.Content>
                                                        <Card.Header id="fonts" >{itemWishlist.proyek_overview.nama_project}</Card.Header>
                                                        <Card.Meta style={{ color: '#13952e' }}>Rp. {itemWishlist.proyek_overview.slot_nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} / Slot</Card.Meta>
                                                        <Card.Description id="fonts" >
                                                            {itemWishlist.proyek_overview.deskripsi}
                                                        </Card.Description>
                                                    </Card.Content>
                                                    <Card.Content extra>
                                                        <div className='ui two buttons'>
                                                            <Header id="fonts" as='h6' color='blue' className="ProjectDesc">
                                                                Dana dibutuhkan
                                                                <Header.Subheader className="ProjectSubDesc">Rp. {itemWishlist.proyek_overview.capital_needed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Header.Subheader>
                                                            </Header>
                                                            <Header id="fonts" as='h6' color='blue' className="ProjectDesc">
                                                                Dividen
                                                                <Header.Subheader className="ProjectSubDesc">
                                                                    {itemWishlist.proyek_overview.est_profit}%
                                                                </Header.Subheader>
                                                            </Header>
                                                        </div>
                                                        <div className='ui two buttons'>
                                                            <Header id="fonts" as='h6' color='blue' className="ProjectDesc">
                                                                Durasi Penawaran
                                                                <Header.Subheader className="ProjectSubDesc">{itemWishlist.proyek_overview.durasi_crowd} Hari</Header.Subheader>
                                                            </Header>
                                                        </div>
                                                    </Card.Content>
                                                    <Card.Content extra style={{ textAlign: 'center' }}>
                                                        <Button id="fonts" href={`/Detail/${itemWishlist.id_project}`} className='col-md-12 btnPilihan' size='medium' style={{ backgroundColor: 'rgb(19, 149, 46)', color: 'white' }}>Detail</Button>
                                                    </Card.Content>
                                                </Card>
                                            )) :
                                            // <Row className="justify-content-md-center" style={{ paddingTop: '85px', paddingLeft: '200px' }}>
                                            //     <Col xs md="12">
                                            //         <Image src={proyk} size='medium' verticalAlign="middle" />
                                            //     </Col>
                                            // </Row>

                                            <Container style={{ paddingTop: '100px', paddingBottom: '200px' }}>
                                                <div style={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>Belum ada bisnis yang dapat di tampilkan</div>
                                                <div style={{ fontSize: '14px', textAlign: 'center', lineHeight: '1.5em' }}>Tambahkan ke daftar favorit.. </div>
                                            </Container>
                                        }
                                    </Card.Group>
                                </Col>
                            </Row>
                        </Container>
                    )
                        :
                        (
                            <Container style={{ paddingTop: '100px', paddingBottom: '200px' }}>
                                <div style={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>Belum ada bisnis yang dapat di tampilkan</div>
                                {/* <div style={{fontSize: '14px', textAlign: 'center', lineHeight: '1.5em'}}>Mulai investasi sekarang.. </div> */}
                            </Container>
                        )}
                </Tab>

                {/* <Tab eventKey="dompet" title="Dompet" style={{ paddingTop: '20px' }}>
                    {(this.state.hideDiv == 'close') ? (
                        <Container style={{ paddingTop: '10px' }}>
                            <Segment>
                                <Row className="justify-content-md-center">
                                    <Col xs md="12">
                                        <Header id="fonts" as='h3' color='blue' className='col-md-11'>
                                            Daftar Dompet
                                            {(dataIsComplete == 5 && verified_advance == 3) ? (
                                                <div style={{ marginTop: "-20px", textAlign: 'right' }}>
                                                    <div id="fonts" className="ui yellow label"><i>Buatlah PIN Dompet Anda Agar Dompet Dapat Digunakan</i></div>
                                                </div>) : ''}
                                            <Header.Subheader id="fonts" >Dompet</Header.Subheader>
                                        </Header>
                                        <Header as='h3' color='blue'>
                                            <Image src={dompet} size='mini' />
                                            <Header.Content id="fonts" className='col-md-11 mDompet'>
                                                Saldo
                                                <Header.Subheader>{itemsDompet.saldo}
                                                    {(dataIsComplete == 5 && verified_advance == 3) ? (
                                                        <div style={{ marginTop: "-30px", textAlign: 'right' }}>
                                                            <Button id="fonts" color='blue' size='mini' onClick={clickdetailDompet}>Lihat Detail</Button>
                                                        </div>

                                                    ) : (
                                                        <div style={{ marginTop: "-30px", textAlign: 'right' }}>
                                                            <Button id="fonts" color='blue' disabled size='mini'>Lihat Detail</Button>
                                                        </div>
                                                    )}
                                                </Header.Subheader>
                                            </Header.Content>
                                        </Header>
                                    </Col>
                                </Row>
                            </Segment>
                        </Container>
                    ) : (
                        <Container style={{ marginTop: '-10px' }}>
                            <Breadcrumb className="brPrykBerjalan">
                                <Breadcrumb.Section link onClick={closedetailDompet}>Dompet</Breadcrumb.Section>
                                <Breadcrumb.Divider />
                                <Breadcrumb.Section active>Detail</Breadcrumb.Section>
                            </Breadcrumb>
                            <Breadcrumb className="brPrykBerjalan" style={{ float: 'right' }}>
                                <Breadcrumb.Section link onClick={closedetailDompet}>{'<< Back'}</Breadcrumb.Section>
                            </Breadcrumb>
                            <Segment style={{ marginTop: '0px', padding: '10px 0 0 0' }}>
                                <DetailDompet hideDiv={this.state.hideDiv} userinfo={JSON.stringify(userinfo)} />
                            </Segment>
                        </Container>
                    )}
                </Tab> */}
            </Tabs>
        )
    }

}

function TabListProyek({ error, isLoaded, items, favoritInsert, alertsuccess, timeBetweenDates, formatRupiah }) {

    const itemsProject = JSON.parse(items)

    if (itemsProject.length < 1) {
        return (
            <Container>
                {/* <Row className="justify-content-md-center mFavorit">
                    <Col xs md="12">
                        <Image src={proyk} size='medium' verticalAlign="middle" />
                    </Col>
                </Row> */}
                <Container style={{ paddingTop: '100px', paddingBottom: '200px' }}>
                    <div style={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>Belum ada bisnis yang dapat di tampilkan</div>
                    {/* <div style={{fontSize: '14px', textAlign: 'center', lineHeight: '1.5em'}}>Mulai investasi sekarang.. </div> */}
                </Container>
            </Container>
        )
    } else {
        return (
            <Container>
                <Row style={{ paddingTop: '10px' }} >
                    <Message icon color="yellow" className="msgerrorfavorit" style={{ display: 'none' }}>
                        <Icon name='exclamation circle' />
                        <Message.Content id="fonts" >
                            <Message.Header id="fonts" >INFO</Message.Header>
                            Mohon maaf anda belum bisa melakukan transaksi sampai anda upload data diri dan status terverifikasi
                        </Message.Content>
                    </Message>
                    <Message icon color="green" className="msgsuccess" style={{ display: 'none' }}>
                        <Icon name='check circle' />
                        <Message.Content id="fonts" >
                            <Message.Header id="fonts" >SUCCESS</Message.Header>
                            Data sudah ditambahkan di menu favorit
                        </Message.Content>
                    </Message>

                    {itemsProject.map(item => (
                        <Col xs md="4" key={item.id_project} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                            <Card className="containerCard DivListProyek">
                                <div className="ui fluid image">
                                    <a className="ui green right corner label" onClick={() => favoritInsert(item.id_project)}><i aria-hidden="true" className="heart icon"></i></a>
                                </div>
                                <Card.Content style={{ textAlign: 'center' }}>
                                    <Card.Header style={{ fontSize: '14px', padding: '5px', fontFamily: 'Helvetica' }}>{item.nama_project}</Card.Header>
                                    <div>
                                        <Image
                                            src={item.proyek_data[0].nama_file} size="medium" className="imgListProyek" rounded style={{ padding: '0px' }} />
                                        <label className="lblhari">{timeBetweenDates(item.date_awal_val, item.date_akhir_val)}</label>
                                    </div>
                                    <Card.Meta id="fonts" style={{ textAlign: 'center', color: '#0DB809', paddingTop: '5px'}}>{item.slot_nominal} / Slot</Card.Meta>
                                </Card.Content>
                                <Card.Content extra>
                                    <div className='ui two buttons'>
                                        <Header id="fonts" as='h6' color='blue' className="ProjectDesc">
                                            Dana dibutuhkan
                                            <Header.Subheader className="ProjectSubDesc">{formatRupiah(item.capital_needed)}</Header.Subheader>
                                        </Header>
                                        <Header id="fonts" as='h6' color='blue' className="ProjectDesc">
                                            Dividen
                                            <Header.Subheader className="ProjectSubDesc">
                                                {item.est_profit} %
                                            </Header.Subheader>
                                        </Header>
                                    </div>
                                    <div className='ui two buttons'>
                                        <Header as='h6' id="fonts" color='blue' className="ProjectDesc">
                                            Durasi Penawaran
                                            <Header.Subheader className="ProjectSubDesc">{item.durasi_crowd} Hari</Header.Subheader>
                                        </Header>
                                    </div>
                                </Card.Content>
                                <Card.Content extra style={{ textAlign: 'center' }}>
                                    <Progress percent={item.persentase_slot} progress className="progressProjectDash" style={{ margin: '0 0 0' }} />
                                </Card.Content>
                                <Card.Content extra style={{ textAlign: 'center' }}>
                                    <Button id="fonts" href={`/Detail/${item.id_project}`} className='col-md-12 btnPilihan' size='medium' style={{ backgroundColor: 'rgb(19, 149, 46)', color: 'white' }}>Detail</Button>
                                </Card.Content>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        )
    }
}

export default withRouter(TabUserPemodal);