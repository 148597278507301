import React, { useState } from 'react';
import { connect } from "react-redux";
import { compose } from "redux";
import Navbar from "../../components/navbar_home";
import Footer from "../../components/Footer";
import FlowPengaduan from "../../assets/img/flow-pengaduan.png";
import "../../App.scss";



class Pengaduan extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.isLoading = true;
        console.log(this.props);
    }



    render() {

        return (
            <div>
                <Navbar />

                <Body />
                <Footer />
            </div>
        );
    }

}
function Body() {
    return (
        <div class="container" style={{ marginTop: "100px" }}>
            <div class="row">
                <div class="col-lg-12">
                    <div class="title">
                        <h1 style={{ color: "#033c85", fontWeight: "700", textTransform: "uppercase", fontFamily: 'Helvetica' }}>Layanan Pengaduan</h1>
                        {/* <img src={Pemodal} class="img-fluid" className="mt-5" style={{ width: "100%" }} alt="" /> */}
                    </div>
                    <div class="isi mt-5">
                        <h2 style={{ color: "#343a40", fontWeight: "700", fontFamily: 'Helvetica', fontSize: "22px" }}>SALURAN PENGADUAN YANG BISA DIPILIH</h2>
                        <p class="text-muted" style={{ color: "#474d6d", fontWeight: "500", fontSize: "16px" }}>
                            Anda dapat menghubungi KERJASAMA untuk memperoleh informasi atau menyampaikan masalah melalui 4 (empat) saluran resmi, antara lain:
                        </p>
                        <br />
                        <h2 style={{ color: "#343a40", fontWeight: "700", fontFamily: 'Helvetica', fontSize: "22px" }}>1. Whatsapp</h2>
                        <p class="text-muted" style={{ color: "#474d6d", fontWeight: "500", fontSize: "16px" }}>
                            Anda bisa langsung berkomunikasi dengan Tim CS KERJASAMA melalui pesan Messenger yang ada di halaman utama https://kerjasama.co.id. Layanan Whatsapp ini tersedia dari hari Senin – Jumat pukul 09.00 – 17.00 WIB mengikuti waktu operasional KERJASAMA.
                        </p>
                        <br />
                        <h2 style={{ color: "#343a40", fontWeight: "700", fontFamily: 'Helvetica', fontSize: "22px"  }}>2. Formulir pada laman “Hubungi Kami”</h2>
                        <p class="text-muted" style={{ color: "#474d6d", fontWeight: "500", fontSize: "16px" }}>
                            Pada laman Hubungi Kami di website  https://kerjasama.co.id terdapat formulir yang dapat Anda isi apabila Anda memiliki pertanyaan, saran, atau kritik. Sertakan pula identitas berupa nama, nomor telepon, dan alamat e-mail agar tim kami mudah untuk menghubungi Anda kembali. Saluran ini merupakan yang paling direkomendasikan oleh kami apabila Anda ingin melakukan pengaduan karena memudahkan dalam pelacakan.
                        </p>
                        <br />
                        <h2 style={{ color: "#343a40", fontWeight: "700", fontFamily: 'Helvetica', fontSize: "22px"  }}>3. E-mail</h2>
                        <p class="text-muted" style={{ color: "#474d6d", fontWeight: "500", fontSize: "16px" }}>
                            Kirimkan pengaduan Anda ke cs@kerjasama.co.id. Tim kami akan segera merespons sesuai waktu operasional KERJASAMA.
                        </p>
                        <br />
                        <h2 style={{ color: "#343a40", fontWeight: "700", fontFamily: 'Helvetica', fontSize: "22px"  }}>4. Off-line atau Tatap muka</h2>
                        {/* <p class="text-muted" style={{ color: "#474d6d", fontWeight: "500", fontSize: "16px" }}>
                            Anda bisa langsung berkomunikasi dengan tim CS Kerjasama melalui kunjungan tatap muka di Jl Boulevard Selatan, Ruko Sinpasa Commercial Blok B-27, Summarecon, Margamulya, Bekasi pada pukul 09:00 – 17:00 WIB sesuai dengan waktu operasional Kerjasama, yaitu dari hari senin – hari jumaat.
Pelaporan atas setiap pengaduan pengguna dari seluruh saluran pengaduan diatas, dilaporkan kepada Otoritas Jasa Keuangan setiap 3 (tiga) bulan sekali.
                        </p> */}
                        <p class="text-muted" style={{ color: "#474d6d", fontWeight: "500", fontSize: "16px" }}>
                            Anda bisa langsung berkomunikasi dengan tim CS Kerjasama melalui kunjungan tatap muka di Komplek Pluit Mas Blok BB No, Jl. Jemb. 3 Raya No.4, RT.1/RW.18, Pluit, Penjaringan, North Jakarta City, Jakarta pada pukul 09:00 – 17:00 WIB sesuai dengan waktu operasional Kerjasama, yaitu dari hari senin – hari jumaat.
Pelaporan atas setiap pengaduan pengguna dari seluruh saluran pengaduan diatas, dilaporkan kepada Otoritas Jasa Keuangan setiap 3 (tiga) bulan sekali.
                        </p>
                        <br />

                        <h2 style={{ color: "#343a40", fontWeight: "700", fontFamily: 'Helvetica', fontSize: "22px" }}>ALUR PENGADUAN</h2>
                        <p class="text-muted" style={{ color: "#474d6d", fontWeight: "500", fontSize: "16px" }}>
                            <img src={FlowPengaduan} class="img-fluid" style={{ width: "100%" }} alt="" />
                        </p>
                        <br />

                        <h2 style={{ color: "#343a40", fontWeight: "700", fontFamily: 'Helvetica', fontSize: "22px" }}>STANDAR WAKTU PENYELESAIAN PENGADUAN</h2>
                        <p class="text-muted" style={{ color: "#474d6d", fontWeight: "500", fontSize: "16px" }}>
                            Sama dengan bentuk bantuan yang diberikan oleh KERJASAMA di atas, pengukuran dan penentuan waktu penyelesaian pengaduan kami juga didasarkan pada tingkat urgensi masalah yang Anda hadapi sebagai pengguna layanan KERJASAMA. Klasifikasinya sama: level rendah, medium, tinggi, dan darurat. Keseluruhan prosesnya dilakukan dengan mengikuti waktu operasional yang berlaku di KERJASAMA.
                        </p>
                        <p class="text-muted" style={{ color: "#474d6d", fontWeight: "500", fontSize: "16px" }}>
                            Untuk pemberian informasi atas pertanyaan yang Anda ajukan, standar waktu  penyelesaiannya minimal 1 (satu) hari kerja hingga maksimal 4 (empat) hari kerja sampai status “terselesaikan”.
                        </p>
                        <p class="text-muted" style={{ color: "#474d6d", fontWeight: "500", fontSize: "16px" }}>
                            Untuk pemberian respons dan penanganan atas masalah yang Anda hadapi, standar waktu penyelesaiannya minimal 1 (satu) hari kerja hingga maksimal 5 (lima) hari kerja sampai status “terselesaikan”.
                        </p>
                        <br />
                        <p class="text-muted" style={{ color: "#474d6d", fontWeight: "500", fontSize: "16px" }}>
                            Untuk pemberian respons dan penanganan atas masalah yang memerlukan tindak lanjut dan koordinasi dengan unit lain, standar waktu penyelesaiannya minimal 1 (satu) hari kerja hingga maksimal 14 (empat) hari kerja sampai status “terselesaikan”.
                        </p>
                        <p class="text-muted" style={{ color: "#474d6d", fontWeight: "500", fontSize: "16px" }}>
                            <b>*) Berlaku selama waktu operasional yaitu hari Senin – Jumat pukul 09.00 – 17.00 WIB, tidak termasuk Hari Libur Nasional.</b>
                        </p>
                        <br />
                        <h2 style={{ color: "#343a40", fontWeight: "700", fontFamily: 'Helvetica', fontSize: "22px" }}>PENYELESAIAN PENGADUAN</h2>
                        <p class="text-muted" style={{ color: "#474d6d", fontWeight: "500", fontSize: "16px" }}>
                            Baik untuk kerugian yang bersifat materiil maupun non-materiil, penyelesaian pengaduan pasti akan diselesaikan terlebih dahulu oleh bantuan Tim CS KERJASAMA bekerja sama dengan unit-unit terkait. Apabila setelah melalui proses yang panjang Anda tetap merasa dirugikan atau penyelesaian dari KERJASAMA tidak bisa diterima, Anda berhak meminta bantuan kepada pihak ketiga yang dianggap mampu membantu menyelesaikan permasalahan tersebut. Bantuan dapat dimintakan melalui pengadilan maupun luar pengadilan yaitu Lembaga Alternatif Penyelesaian Sengketa yang dimuat dalam Daftar Lembaga Alternatif Penyelesaian Sengketa yang ditetapkan oleh Otoritas Jasa Keuangan.
                        </p>
                        <br />
                    </div>
                </div>
            </div>
            <br /><br /><br />
        </div>
    );
}

export default Pengaduan;
