
import React, { Component } from 'react';
import { Widget } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import { FaWhatsapp } from "react-icons/fa";
//import Kerja from "../assets/img/kerja.png";
import kerjasamaLogo from "../assets/img/logo_kerjasama_horizontal_blue.png";
import aludiLogo from '../assets/img/partner/logo_aludi.png';
import ojkLogo from '../assets/img/partner/logo_ojk.png';
import kominfoLogo from '../assets/img/partner/logo_kominfo.png';
import isoLogo from '../assets/img/partner/intertek_kan.png';
import "../App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { List } from 'semantic-ui-react';

const YearNow = new Date().getFullYear()

function Footer() {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-sm col-lg">
            <div className="mb-4 mt-5" style={{ fontSize: "18px", fontWeight: "bold"}}>Disclaimer</div>
            {/* <h5> */}
              <div style={{ textAlign: "-webkit-match-parent", marginTop: "10px" }}>
                PT Kerjasama Untuk Negeri atau biasa disebut Kerjasama.co.id adalah Penyelenggara Layanan Urun Dana melalui Penawaran Efek Berbasis Teknologi Informasi (Securities Crowdfunding Indonesia) yang telah berizin dan diawasi oleh Otoritas Jasa Keuangan (OJK) berdasarkan Kep XX.XXX Tentang Izin Usaha sebagai Penyelenggara Penawaran Efek Melalui Layanan Urun Dana Berbasis Teknologi Informasi.
              </div>
              <div style={{ textAlign: "-webkit-match-parent", marginTop: "10px" }}>  
                Aktivitas Investasi melalui layanan urun dana merupakan aktivitas beresiko tinggi, harap mempertimbangkan secara ekstra dalam hal mengambil keputusan Investasi yang Anda lakukan di Kerjasama.co.id, berdasarkan pengetahuan, pengalaman dan tujuan Anda berinvestasi pada efek bersifat ekuitas,{/* sukuk atau*/} utang yang ditawarkan melalui layanan urun dana.
              </div>
              <div style={{ textAlign: "-webkit-match-parent", marginTop: "10px" }}>  
                Kerjasama.co.id tidak pernah memaksa pengguna untuk membeli efek yang sedang ditawarkan. Semua keputusan merupakan masing-masing pengguna.
              </div>
              <div style={{ textAlign: "-webkit-match-parent", marginTop: "10px" }}>  
                Kerjasama.co.id bertindak sebagai penyelenggara urun dana yang mempertemukan pemodal dan penerbit, bukan sebagai pihak yang menjalankan bisnis (Penerbit).
              </div>
              <div style={{ textAlign: "-webkit-match-parent", marginTop: "10px" }}>  
                Otoritas Jasa Keuangan bertindak sebagai regulator dan pemberi izin, bukan sebagai penjamin investasi.
              </div>
              <div style={{ textAlign: "-webkit-match-parent", marginTop: "10px" }}>  
                Anda menyadari bahwa setiap berinvestasi melalui layanan urun dana memiliki resikonya masing-masing. Untuk itu, dengan berinvestasi melalui Kerjasama.co.id, Anda sudah mengerti akan segala resiko yang dapat terjadi di kemudian hari. Kerjasama.co.id TIDAK BERTANGGUNG JAWAB terhadap risiko kerugian dan gugatan hukum serta segala bentuk risiko lain yang timbul dikemudian hari. Beberapa risiko yang dapat terjadi saat Anda berinvestasi di antaranya adalah :
              </div>

              <div style={{ textAlign: "-webkit-match-parent", marginTop: "20px" }}>
                <strong>Risiko Usaha</strong>
                <div style={{ textAlign: "-webkit-match-parent", marginTop: "10px" }}>
                  Risiko Usaha adalah sesuatu yang tidak dapat dihindari dalam suatu usaha/bisnis. Beberapa risiko yang dapat terjadi seperti penutupan bisnis secara sementara akibat kebijakan Pemberlakukan Pembatasan Kegiatan Masyarakat (PPKM) oleh Pemerintah, maupun secara permanen akibat bisnis yang terdampak pandemi Covid 19.
                </div>
              </div>
              <div style={{ textAlign: "-webkit-match-parent", marginTop: "20px" }}>
                <strong>Kerugian Investasi</strong>
                <div style={{ textAlign: "-webkit-match-parent", marginTop: "10px" }}>
                  Setiap investasi memiliki tingkat risiko yang bervariasi seperti tidak terkumpulnya dana investasi yang dibutuhkan selama proses pengumpulan dana atau proyek yang dijalankan tidak menghasilkan keuntungan sesuai yang diharapkan.
                </div>
              </div>
              <div style={{ textAlign: "-webkit-match-parent", marginTop: "20px" }}>
                <strong>Kekurangan Likuiditas</strong>
                <div style={{ textAlign: "-webkit-match-parent", marginTop: "10px" }}>
                  Investasi Anda mungkin saja tidak likuid karena efek bersifat ekuitas yang ditawarkan tidak terdaftar di bursa efek. Ini berarti bahwa Anda mungkin tidak dapat dengan mudah menjual saham Anda di bisnis tertentu atau Anda mungkin tidak dapat menemukan pembeli sebelum berakhirnya jangka waktu investasi di pasar sekunder.
                </div>
              </div>
              <div style={{ textAlign: "-webkit-match-parent", marginTop: "20px" }}>
                <strong>Kegagalan Sistem Elektronik</strong>
                <div style={{ textAlign: "-webkit-match-parent", marginTop: "10px" }}>
                  Sistem di Kerjasama.co.id sudah menerapkan sistem elektronik dan keamanan data yang handal. Namun, tetap dimungkinkan terjadi gangguan sistem teknologi informasi dan kegagalan sistem, serta alih kelola sistem Teknologi Informasi yang menyebabkan aktivitas Anda di platform Kerjasama.co.id menjadi tertunda. 
                </div>
              </div>
              <div style={{ textAlign: "-webkit-match-parent", marginTop: "20px" }}>
                <strong>Kebijakan Keamanan Informasi :</strong>
                <div style={{ textAlign: "-webkit-match-parent", marginTop: "10px" }}>
                  <List bulleted style={{marginLeft:40}}>
                    <List.Item>Memenuhi harapan Stakeholder dengan mewujudkan Confidentiality, Integrity dan Availability informasi melalui implementasi ISO/IEC 27001:2013.</List.Item>
                    <List.Item>Selalu mentaati segala ketentuan dan peraturan terkait keamanan informasi yang berlaku di wilayah Republik Indonesia serta wilayah tempat dilakukannya pekerjaan.</List.Item>
                    <List.Item>Berjalannya perbaikan yang berkesinambungan terhadap kinerja Sistem Manajemen Keamanan Informasi.</List.Item>
                  </List>
                </div>
              </div>
              <div style={{ textAlign: "-webkit-match-parent", marginTop: "20px" }}>
                <strong>Kelangkaan Pembagian Dividen dan /atau Dilusi Kepemilikan Saham, Jika Efek Yang Diterbitkan Merupakan Saham</strong>
                <div style={{ textAlign: "-webkit-match-parent", marginTop: "10px" }}>
                  Setiap Investor yang berinvestasi pada efek bersifat ekuitas saham, berhak untuk mendapatkan dividen sesuai dengan jumlah kepemilikan saham yang dibagikan dari Penerbit secara periodik. Kelangkaan pembagian dividen dapat terjadi karena kinerja bisnis yang Anda investasikan kurang berjalan baik serta berpotensi terdilusi kepemilikan saham karena bertambahnya total jumlah saham yang beredar yang ditawarkan, dimana Investor yang bersangkutan tidak ikut membeli saham yang baru diterbitkan tersebut. Penerbit dapat menerbitkan saham baru jika jumlah penawaran yang diajukan masih dibawah batas maksimum. Jika Penerbit mengadakan urun dana kembali dan terjadi penerbitan saham baru, maka Kerjasama.co.id akan membuka bisnis tersebut di situs kerjasama.co.id dan menginformasikan kepada pemegang saham untuk memesan efek terlebih dahulu. 
                </div>
              </div>
              <div style={{ textAlign: "-webkit-match-parent", marginTop: "20px" }}>
                <strong>Risiko Gagal Bayar</strong>
                <div style={{ textAlign: "-webkit-match-parent", marginTop: "10px" }}>
                  Penawaran efek bersifat utang{/* atau sukuk*/}, berisiko penerbit akan gagal bayar (default), apabila penerbit gagal bayar maka Wali Amanat berdasarkan surat kuasa akan mengundang dan mengadakan Rapat Umum Pemegang Obligasi (RUPO), dalam RUPO tersebut akan membahas mekanisme Gagal Bayar Penerbit tersebut dan dapat berupa perpanjangan jatuh tempo kupon maupun eksekusi jaminan fidusia dan jaminan Penerbit lainnya (jika ada). 
                </div>
              </div>
              <div style={{ textAlign: "-webkit-match-parent", marginTop: "20px" }}>
                "OTORITAS JASA KEUANGAN TIDAK MEMBERIKAN PERNYATAAN MENYETUJUI ATAU TIDAK MENYETUJUI EFEK INI,
                TIDAK JUGA MENYATAKAN KEBENARAN ATAU KECUKUPAN INFORMASI DALAM LAYANAN URUN DANA INI.
                SETIAP PERNYATAAN YANG BERTENTANGAN DENGAN HAL TERSEBUT ADALAH PERBUATAN MELANGGAR HUKUM."
              </div>
              <div style={{ textAlign: "-webkit-match-parent", marginTop: "20px" }}>
                "INFORMASI DALAM LAYANAN URUN DANA INI PENTING DAN PERLU MENDAPAT PERHATIAN SEGERA.
                APABILA TERDAPAT KERAGUAN PADA TINDAKAN YANG AKAN DIAMBIL, SEBAIKNYA BERKONSULTASI DENGAN PENYELENGGARA."
              </div>
              <div style={{ textAlign: "-webkit-match-parent", marginTop: "20px" }}>
                "PENERBIT DAN PENYELENGGARA, BAIK SENDIRI-SENDIRI MAUPUN BERSAMA-SAMA,
                BERTANGGUNG JAWAB SEPENUHNYA ATAS KEBENARAN SEMUA INFORMASI YANG TERCANTUM DALAM LAYANAN URUN DANA INI."
              </div>
            {/* </h5> */}
          </div>
        </div>
      </div>
      <div className="footer-middle">  
        <div className='container'>
          <div className='footer-line-horizontal'></div>
          <div className="row">
            <div className="col-md-4 mrg20">
              {/* <h3 style={{marginBottom:"10px"}}>Hubungi Kami</h3> */}

              <img src={kerjasamaLogo} alt="" style={{ width: '60%', marginBottom: 10}} />
              <ul className="d-flex">
                {/* <li className="list-item" style={{ listStyle: 'none' }}>
                  <i className="fa fa-map-marker mr-3 " id="icon"></i>
                </li> */}
                <li className="list-item" style={{ listStyle: 'none' }}>
                  <div style={{marginBottom:"10px", fontWeight: "bold"}}>PT KERJASAMA UNTUK NEGERI </div>
                  <div>Kompleks Pluit Mas Blok BB No. 3-4,</div>
                  <div>Kel. Pejagalan, Kec. Penjaringan,</div>
                  <div>Jakarta Utara 14450.</div>
                </li>
              </ul>
              <ul className="d-flex">
                {/* <li className="list-item" style={{ listStyle: 'none' }}>
                  <i className="fa fa-phone mr-3 " id="icon"></i>
                </li> */}
                <li className="list-item" style={{ listStyle: 'none' }}>
                  <span>Telp : +62 21 6618272</span>
                </li>
              </ul>
              <ul className="d-flex" style={{ listStyle: 'none' }}>
                {/* <li className="list-item">
                  <i className="fa fa-envelope mr-3 " id="icon" width="150%"></i>
                </li> */}
                <li className="list-item" style={{ listStyle: 'none' }}>
                  <div>Email : cs@kerjasama.co.id</div>
                </li>
              </ul>
              <ul className="d-flex">
                {/* <li className="list-item" style={{ listStyle: 'none' }}>
                  <i className="fa fa-clock-o mr-3" id="icon"></i>
                </li> */}
                {/* <li className="list-item" style={{ listStyle: 'none' }}>
                  <div style={{marginBottom: "5px"}}>Jam Kerja :</div>
                  <ul style={{marginLeft: "15px"}}>
                    <li>08:00 - 16:00 WIB (Senin - Jum`at)</li>
                    <li>08:00 - 12:00 WIB (Sabtu)</li>
                  </ul>
                </li> */}
              </ul>
            </div>

            <div className="col-md-3 mrg20 ">
              <ul className="list-item">
                <li style={{ listStyle: 'none', fontWeight: "bold", marginBottom: "10px" }}>Panduan : </li>
                <li style={{ listStyle: 'none', marginBottom: "10px" }}><a href="/Panduan">Pemodal / Penerbit</a></li>
                <li style={{ listStyle: 'none', marginBottom: "10px" }}><a href="/Pengaduan">Layanan Pengaduan</a></li>
              </ul>
              <ul className="list-item" style={{marginTop: "30px"}}>
                <li style={{ listStyle: 'none', marginBottom: "10px" }}><a href="/Tentang">Tentang Kami</a></li>
                <li style={{ listStyle: 'none', marginBottom: "10px" }}><a href="/Faq">Faq</a></li>
                <li style={{ listStyle: 'none', marginBottom: "10px" }}><a href="/SyaratDanKetentuan">Syarat dan Ketentuan</a></li>
                <li style={{ listStyle: 'none', marginBottom: "10px" }}><a href="/KebijakanPrivasi">Kebijakan Privasi</a></li>
              </ul>
            </div>

            <div className="col-md-3 mrg20 ">
              <ul className="list-item">
                <li style={{ listStyle: 'none', fontWeight: "bold", marginBottom: "10px" }}>
                  {/* Berizin dan Diawasi Oleh :  */}
                  Proses Pendaftaran Ijin dan Pengawasan : 
                </li>
                <li style={{ listStyle: 'none' }}>
                  <img
                    src={ojkLogo}
                    alt=""
                    style={{ width: '50%' }}
                  />
                </li>
              </ul>
              <ul className="list-item" style={{marginTop: "30px"}}>
                <li style={{ listStyle: 'none', fontWeight: "bold", marginBottom: "10px" }}>Anggota Resmi : </li>
                <li style={{ listStyle: 'none' }}>
                  <img
                    src={aludiLogo}
                    alt=""
                    style={{ width: '45%', marginLeft: '-20px' }}
                  />
                </li>
              </ul>
            </div>
            
            <div className="col-md-2 mrg20 ">
              <ul className="list-item">
                <li style={{ listStyle: 'none', fontWeight: "bold", marginBottom: "10px" }}>Didukung Oleh : </li>
                <li style={{ listStyle: 'none' }}>
                  <img
                    src={kominfoLogo}
                    alt=""
                    style={{ width: '40%' }}
                  />
                  {/* <img
                    src={isoLogo}
                    alt=""
                    style={{ width: '30%', marginLeft: 20 }}
                  /> */}
                </li>
                <li style={{ listStyle: 'none', paddingTop: 20 }}>
                  <img
                    src={isoLogo}
                    alt=""
                    style={{ width: '100%' }}
                  />
                </li>
              </ul>
            </div>

          </div>
        </div>
      </div>

      <div className="container-fluid mt-4">
        <div className="row justify-content-center align-items-center pt-3 pb-3">
          <div className="col-auto text-gray-500 font-weight-light">
            Copyright &copy; 2020 - {YearNow} | PT Kerjasama untuk Negeri (PT KUNN) | Versi 1.0.7
          </div>
        </div>
      </div>

    </div>
  );
}

export default Footer;
