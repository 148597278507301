import React, { useState } from "react";
import "../../App.scss";
import topbar from "topbar";
import Ayat from "../../assets/img/ayat-l.png";
//import Kerja from "../../assets/img/kerjasama.svg";
import Logo from "../../assets/img/logo_kerjasama_vertical.png";
import Gambar from "../../assets/img/GAMBAR.jpg";
import { Row, Col, Button, Form, Alert, Modal } from "react-bootstrap";
import { Grid, Header, Segment, Portal, Icon } from "semantic-ui-react";
import { loginUser, authData } from "../../actions/auth.actions";
import { connect } from "react-redux";
import $ from "jquery";
import axios from "axios";
import swal from "sweetalert";

class Signin extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      open: false,
      fields: {},
      response: {},
      errors: {},
      alertLogin: "",
      alertErrorLogin: "",
      showSuccessLogin: false,
      showErrorLogin: false,
      blockedIP: false,
      errorSum: 0,
      ipAddr: "",
    };
  }

  loginUser = async (values) => {
    try {
      $(".loginDashboard").attr("disabled", "true");
      $(".loginDashboard").html("Loading...");

      // console.log(this.props.auth.isUserId);
      const response = await this.props.dispatch(loginUser(values));

      if (!response.responseBody.success) {
        swal({ text: response.responseBody.body.message, icon: "error" });
        $(".loginDashboard").removeAttr("disabled");
        $(".loginDashboard").html("Masuk");
        // throw response;
      } else if (response.responseBody.success) {
        $(".loginDashboard").removeAttr("disabled");
        $(".loginDashboard").html("Masuk");
        if (response.responseBody.type == 1) {
          swal({ text: "Login Success", icon: "success" });
          localStorage.token = response.responseBody.body.token;
          const { history } = this.props;
          history.push("/Userpemodal");
        } else if (response.responseBody.type == 2) {
          swal({ text: "Login Success", icon: "success" });
          localStorage.token = response.responseBody.body.token;
          const { history } = this.props;
          history.push("/Userpengelola");
        } else {
          alert(response.responseBody.type);
        }
      }
    } catch (error) {
      if (error.responseBody.body || error.responseBody) {
        $(".loginDashboard").removeAttr("disabled");
        $(".loginDashboard").html("Masuk");
        swal({
          text: "Terjadi kesalahan pada server silahkan coba lagi nanti",
          icon: "error",
        });
      } else {
        $(".loginDashboard").removeAttr("disabled");
        $(".loginDashboard").html("Masuk");
        swal({
          text: "Terjadi kesalahan pada server silahkan coba lagi nanti",
          icon: "error",
        });
      }
    }
  };

  componentDidMount() {
    topbar.config({
      autoRun: false,
      barThickness: 5,
      barColors: {
        0: "rgba(0, 199, 80, 1)",
        ".5": "rgba(255, 255, 255, 1)",
        ".8": "rgba(0, 199, 80, 1)",
        "1.0": "rgba(255, 255, 255, 1)",
      },
      shadowBlur: 5,
      shadowColor: "rgba(3, 60, 133, 1)",
      className: "topbar",
    });
    topbar.show();
    (function step() {
      setTimeout(function () {
        if (topbar.progress("+.01") < 1) step();
      }, 16);
    })();
    setTimeout(() => {
      topbar.hide();
    }, 4000);
    this.getIPAddress();
  }

  getIPAddress() {
    axios
      .get(
        "https://jsonip.com/",
        (axios.defaults.headers.common["Accept"] = "application/json")
      )
      .then((resp) => {
        this.setState({
          ipAddr: resp.data.ip,
        });
      })
      .catch((resp) => {});
  }

  authData = async (values) => {
    try {
      $(".loginDashboard").attr("disabled", "true");
      $(".loginDashboard").html("Loading...");

      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var hours = date.getHours();
      var minutes = date.getMinutes();

      var btoaIPLog = window.btoa(
        "iPAddressForLogs" +
          year +
          "-" +
          month +
          "-" +
          day +
          " " +
          hours +
          ":" +
          minutes
      );
      values[btoaIPLog] = window.btoa(this.state.ipAddr);

      const response = await this.props.dispatch(authData(values));

      if (!response.responseBody.success) {
        this.setState({
          alertErrorLogin: response.responseBody.body.message,
          showErrorLogin: true,
        });
        $(".loginDashboard").html("Masuk");
        setTimeout(() => {
          this.setState({
            showErrorLogin: false,
          });
          $(".loginDashboard").removeAttr("disabled");
        }, 7000);
      } else if (response.responseBody.success) {
        if (response.responseBody.type === 1) {
          let urlElelement = window.location.href.split("#");
          let pathUrl = "/Userpemodal";
          if (urlElelement[1]) {
            pathUrl = "/Userpemodal/#" + urlElelement[1];
          }

          this.setState({
            alertLogin: "Anda akan diarahkan ke halaman dashboard Pemodal...",
            open: true,
          });
          localStorage.token = response.responseBody.body.token;
          const { history } = this.props;
          setTimeout(() => {
            history.push(pathUrl);
            $(".loginDashboard").removeAttr("disabled");
            $(".loginDashboard").html("Masuk");
          }, 2000);
        } else if (response.responseBody.type === 2) {
          let urlElelement = window.location.href.split("#");
          let pathUrl = "/Userpengelola";
          if (urlElelement[1]) {
            pathUrl = "/Userpengelola/#" + urlElelement[1];
          }

          this.setState({
            alertLogin: "Anda akan diarahkan ke halaman dashboard Penerbit...",
            open: true,
          });
          localStorage.token = response.responseBody.body.token;
          const { history } = this.props;
          setTimeout(() => {
            history.push(pathUrl);
            $(".loginDashboard").removeAttr("disabled");
            $(".loginDashboard").html("Masuk");
          }, 2000);
        } else {
          alert(response.responseBody.type);
        }
      }
    } catch (error) {
      // if (error.responseBody.body || error.responseBody) {
      // $(".loginDashboard").removeAttr('disabled');
      // $(".loginDashboard").html('Masuk');
      // alert("Terjadi kesalahan pada server silahkan coba lagi nanti ...")
      // } else {
      $(".loginDashboard").removeAttr("disabled");
      $(".loginDashboard").html("Masuk");
      swal({
        text: "Terjadi kesalahan pada server silahkan coba lagi nanti",
        icon: "error",
      });
      // }
    }
  };

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //Email
    if (!fields["user_email"]) {
      formIsValid = false;
      errors["user_email"] = "Email Tidak Ditemukan...";
    }

    if (typeof fields["user_email"] !== "undefined") {
      let lastAtPos = fields["user_email"].lastIndexOf("@");
      let lastDotPos = fields["user_email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["user_email"].indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          fields["user_email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["user_email"] = "Email Tidak Valid";
      }
    }

    if (!fields["user_password"]) {
      formIsValid = false;
      errors["user_password"] = "Masukkan password";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  onSubmit = (event) => {
    event.preventDefault();

    if (this.handleValidation()) {
      // this.loginUser(this.state.fields);
      this.authData(this.state.fields);
    } else {
      this.setState({
        alertErrorLogin: "Form belum diisi dengan benar...",
        showErrorLogin: true,
      });
      setTimeout(() => {
        this.setState({
          showErrorLogin: false,
          errors: {},
        });
      }, 5000);
    }
  };

  handleChange = (event) => {
    let fields = this.state.fields;
    fields[event.currentTarget.getAttribute("name")] = event.target.value;
    this.setState({ fields });
  };

  setShow = (event) => {
    event.target.setAttribute("show2", false);
  };

  handleClose = () => this.setState({ open: false });

  render() {
    const error_text = this.props.loginUser.errors;
    const { open } = this.state;
    let error_panel;

    if (error_text) {
      error_panel = (
        <Alert variant="danger" show={this.props.loginUser.isError}>
          <center>{error_text.message}</center>
        </Alert>
      );
    } else {
      error_panel = "";
    }

    return (
      //  <div style={{backgroundImage:"url(" + Gambar + ")",backgroundSize:"cover",opacity:"0.7",height:"100%",width:"100%",position:"fixed"}} >
      <div>
        <Grid columns={2}>
          <Grid.Column>
            <Portal onClose={this.handleClose} open={open}>
              <Segment
                style={{
                  left: "36%",
                  position: "fixed",
                  top: "5%",
                  zIndex: 1000,
                }}
              >
                <Header>
                  <Icon loading name="spinner" color="green" /> Login
                  Berhasil...
                </Header>
                <p>{this.state.alertLogin}</p>
              </Segment>
            </Portal>
          </Grid.Column>
        </Grid>

        <div
          className="container"
          style={{ paddingTop: "100px", paddingBottom: "100px" }}
        >
          <div className="row mt-4 align-items-center justify-content-center">
            <div
              className="col-lg-5 col-sm-8 col-xs-8 col-lg-justify-content-left col-justify-content-center my-5"
              style={{ marginLeft: "-25px" }}
            >
              <div
                className="card"
                style={{ borderRadius: "20px", backgroundColor: "#fafafa" }}
              >
                <div
                  className="text-center"
                  style={{ padding: 20, paddingBottom: 30 }}
                >
                  <a href="/">
                    <img
                      src={Logo}
                      className="img-fluid card-image-top"
                      width="150"
                      alt="logo-kerjasama"
                    />
                  </a>
                </div>
                <div className="card-body" style={{ marginTop: "-30px" }}>
                  <Form onSubmit={this.onSubmit} autoComplete="off">
                    <Alert
                      className="alertErrorLogin"
                      variant="danger"
                      show={this.state.showErrorLogin}
                    >
                      <center>{this.state.alertErrorLogin}</center>
                    </Alert>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="text-muted"></Form.Label>
                      <input
                        className="form-control"
                        type="email"
                        name="user_email"
                        id="user_email"
                        placeholder="Enter email"
                        onChange={this.handleChange}
                        value={this.state.fields["user_email"] || ""}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["user_email"]}
                      </span>
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Label></Form.Label>
                      <input
                        className="form-control"
                        type="password"
                        name="user_password"
                        id="user_password"
                        placeholder="Password"
                        onChange={this.handleChange}
                        value={this.state.fields["user_password"] || ""}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["user_password"]}
                      </span>
                    </Form.Group>
                    <a href="Lupa-password" style={{ textDecoration: "none" }}>
                      {" "}
                      Lupa Password
                    </a>{" "}
                    <br /> <br />
                    <div className="text-center" style={{ marginTop: "20px" }}>
                      <Button
                        style={{
                          borderRadius: "30px",
                          padding: "5px 80px",
                          background: "#033C86",
                        }}
                        type="submit"
                      >
                        Masuk
                      </Button>
                      <br />
                      <Daftar />
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loginUser: state.authReducer.loginUser,
    getUser: state.userReducer.getUser,
    auth: state.authReducer.authData,
    login: state.authReducer.loginUser,
  };
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

function Daftar() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <a
        className="btn"
        style={{ color: "#033C86", fontSize: "16px", fontWeight: "700" }}
        onClick={handleShow}
      >
        Daftar
      </a>

      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Title
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            paddingTop: "50px",
            color: "#033C86",
            fontSize: "26px",
            fontWeight: "500",
          }}
        >
          Daftar Sebagai ?
        </Modal.Title>

        <div
          className="row text-center"
          style={{ paddingTop: "50px", paddingBottom: "50px" }}
        >
          <div className="col-md-6">
            <a
              href="Daftarpemodal"
              className="btn my-4"
              style={{
                background: "#214981",
                color: "#ffffff",
                padding: "5px 50px",
                borderRadius: "20px",
              }}
            >
              Pemodal
            </a>
          </div>
          <div className="col-md-6">
            <a
              href="Daftarpengelola"
              className="btn my-4"
              style={{
                background: "#214981",
                color: "#ffffff",
                padding: "5px 50px",
                borderRadius: "20px",
              }}
            >
              Penerbit
            </a>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
