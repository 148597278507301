import { api, fetchApi } from "../service/api";


export const loginUser = (payload) => {
    return async (dispatch) => {

        try {
            dispatch({
                type: "LOGIN_USER_LOADING"
            });
            const response = await fetchApi("/customer/auth", "POST", payload, 200);
            // console.log(response.responseBody.body.token);
            if (response.responseBody.success) {
                localStorage.clear();
                if (response.responseBody.type == 1) {
                    dispatch({
                        type: "LOGIN_USER_PEMODAL_SUCCESS",
                    });
                    // dispatch({
                    //     type: "AUTH_USER_PEMODAL_SUCCESS",
                    //      payload: response.responseBody.body.token ,
                    // });
                    return response;
                } if (response.responseBody.type == 2) {
                    dispatch({
                        type: "LOGIN_USER_KELOLA_SUCCESS",
                    });
                    // dispatch({
                    //     type: "AUTH_USER_PENGELOLA_SUCCESS",
                    //     payload: response.responseBody.body.token, 
                    // });
                    return response;
                }

            } else {
                throw response;
            }

        } catch (error) {
            dispatch({
                type: "LOGIN_USER_FAIL",
                payload: error.responseBody
            });
            dispatch({
                type: "AUTH_USER_FAIL"
            });
            return error;
        }
    }
}

export const logoutUser = () => {
    return async (dispatch, getState) => {
        const state = getState();
        try {
            const response = await fetchApi("/customer/auth/logout", "GET", 200);
            console.log(response)
            if (response.responseBody.success) {
                localStorage.clear();
                dispatch({
                    type: "USER_LOGGED_OUT_SUCCESS"
                });
            } else {
                throw response;
            }

        } catch (e) {
            // console.log(e);
        }
    }
}

export const authData = (payload) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: "AUTH_USER_LOADING"
            });
            const response = await fetchApi("/customer/auth", "POST", payload, 200);
            // console.log(response.responseBody);
            if (response.responseBody.success) {
                // localStorage.clear();
                localStorage.removeItem('token');
                localStorage.removeItem('DataUser');
                if (response.responseBody.type == 1) {
                    // dispatch({
                    //     type: "LOGIN_USER_PEMODAL_SUCCESS",
                    // });
                    dispatch({
                        type: "AUTH_USER_PEMODAL_SUCCESS",
                        payload: response.responseBody.body.token,
                    });
                    return response;
                } if (response.responseBody.type == 2) {
                    // dispatch({
                    //     type: "LOGIN_USER_PENGELOLA_SUCCESS",
                    // });
                    dispatch({
                        type: "AUTH_USER_KELOLA_SUCCESS",
                        payload: response.responseBody.body.token,
                    });
                    return response;
                }

            } else {
                throw response;
            }

        } catch (error) {
            dispatch({
                type: "AUTH_USER_FAIL",
                payload: error.responseBody
            });
            dispatch({
                type: "AUTH_USER_FAIL"
            });
            return error;
        }
    }
}

export const forgotPassword = (payload) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: "FORGOT_USER_LOADING"
            });
            const response = await fetchApi("/customer/forgot", "POST", payload, 200);
            // console.log(response.responseBody);
            if (response.responseBody.success) {
                // localStorage.clear();
                dispatch({
                    type: "FORGOT_USER_LOADING",
                    payload: response.responseBody.body.token,
                });
                return response;

            } else {
                throw response;
            }

        } catch (error) {
            dispatch({
                type: "FORGOT_USER_FAIL",
                payload: error.responseBody
            });
            dispatch({
                type: "FORGOT_USER_FAIL"
            });
            return error;
        }
    }
}


