import React, { memo, useState } from 'react';
import "../../../App.scss";
import { connect } from "react-redux";
import { compose } from "redux";
import { BallBeat } from 'react-pure-loaders';
import { Tabs, Tab, Row, Col, Card, Button, Container, CardGroup, Alert, Image, Table, Badge, Modal, Form, } from 'react-bootstrap';
import swal from 'sweetalert';


import Navbar_user from "../../../components/navbar_home";

import Footer from "../../../components/Footer";
// import SidebarUserpemodal from "../components/SidebarUserPenerbit";
import Dashboarduser from "../../../components/Dashboarduser";
import VerifikasiData from "../../../components/VerifikasiData";
import { getUserpengelola } from "../../../actions/userpengelola.actions";
import { updateUserpengelola } from "../../../actions/userpengelola.actions";
import { updatePasspengelola } from "../../../actions/userpengelola.actions";
import { fetchApi } from '../../../service/api';
import SidebarUser from './SidebarProfilePenerbit'

// import { Pas } from "../actions/userpengelola.actions";
// import { api } from '../service/api';

class ProfilePenerbit extends React.Component {

  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      c: false,
      userinfo: [],
      isLoad: false,
    }
  }

  getUserpengelola = async (values) => {

    try {
      // console.log(this.props.auth.isUserId);
      const response = await this.props.dispatch(getUserpengelola(values));

      localStorage.DataUser = JSON.stringify(response.responseBody);
      // console.log(response.responseBody.success);

      if (!response.responseBody.success) {
        throw response;
      } else if (response.responseBody.success) {

        this.state.userinfo = JSON.stringify(response.responseBody.user);
        this.state.isLoad = true;

      }

    } catch (error) {
      try{
        let errorStatus = error.responseBody.error
        if(errorStatus === 'UNAUTHORIZED_SINGLE_LOGIN_EXPIRED'){
          swal({text: error.responseBody.message, icon: 'warning', closeOnClickOutside: false})
          .then((value) => {
            setTimeout(() => {
              window.location.href = "/Signin"
              localStorage.removeItem('token');
              localStorage.clear();
              this.props.dispatch({
                type: "AUTH_USER_LOGOUT",
              })
            }, 500);
          });
        } else {
          setTimeout(() => {
            window.location.href = "/Signin"
            localStorage.removeItem('token');
            localStorage.clear();
            this.props.dispatch({
              type: "AUTH_USER_LOGOUT",
            })
          }, 500);
        }
      }catch(error){
        swal({ text: 'Error tidak terdefinisi!', icon: 'error' })
      }
    }
  }

  updateUser = async (values) => {
    try {
      const response = await this.props.dispatch(updateUserpengelola(values));

      if (response.responseBody.success == false) {
        // alert(response.responseBody.body.message);
        swal({text: response.responseBody.body.message, icon: 'error'})
        throw response;
      } else {
        // alert('Data Berhasil Di Update');
        swal({text: response.responseBody.body.message, icon: 'success', closeOnClickOutside: false})
            .then((value) => {
              window.location.reload(true);
            });
      }
    } catch (error) {
      console.log(error);
    }
  }

  updatePass = async (values) => {
    try {
      // dilempar kesini
      const response = await this.props.dispatch(updatePasspengelola(values));
      if (!response.success) {
        // alert(response.responseBody.body.message);
        swal({text: response.responseBody.body.message, icon: 'error'})
        throw response;
      } else {
        // alert(response.responseBody.message);
        swal({text: response.responseBody.body.message, icon: 'success'})
        const { history } = this.props;
      }
    } catch (error) {
      console.log(error);
    }
  }

  lengkapiData = async (values) => {
    try {
      // dilempar kesini
      const response = await fetchApi("/customer/pemodal/lengkapidata", "POST", values, localStorage.token, 200);
      console.log(response);
      if (!response.success) {
        // alert(response.responseBody.body.message);
        swal({text: response.responseBody.body.message, icon: 'error'})
        throw response;
      } else {
        // alert(response.responseBody.message);
        swal({text: response.responseBody.body.message, icon: 'success'})
        const { history } = this.props;
      }
    } catch (error) {
      console.log(error);
    }
  }

  // update Password
  updatePassword = (event, data) => {
    event.preventDefault()
    // console.log('berhasil',data);
    this.updatePass(data);
  }

  // update data
  updateData = (event, data) => {
    event.preventDefault()
    // console.log('berhasil', data);
    this.updateUser(data);
  }


  render() {

    // var content_data;
    if (!this.state.isLoad) {

      const d = this.getUserpengelola({ token: this.props.auth.isUserId });

      return (
        <div
          style={{ paddingTop: '300px', Width: 'initial', textAlign: 'center' }}
        >
          <BallBeat
            color={'#123abc'}
            loading
          />
          Loading....</div>
      );
    }
    else {
      //  , marginTop: "60px" 
      return (
        <div style={{ backgroundColor: "#EEEEEE" }}>
          <Navbar_user />
          <SidebarUser userinfo={this.state.userinfo} lengkapiData={this.lengkapidata} />
          {/* <Footer /> */}
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    getUserpengelola: state.userReducer.getUserpengelola,
    auth: state.authReducer.authData
  }
}

function mapDispatchToProps(dispatch) {
  return { dispatch }
}


const Katasandi = memo((props) => {
  const { update } = JSON.parse(localStorage.DataUser);

  const mergeUser = { ...data }
  const [data, setData] = useState(mergeUser)
  // console.log(data); 
  const handleChange = e => setData({ ...data, [e.target.name]: e.target.value })
  const handleSubmit = event => props.updatePassword(event, data)

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button style={{ marginLeft: "auto" }} variant="primary" onClick={handleShow}>
        <i className="fa fa-key"></i>Ganti Password
      </Button>

      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Ganti Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} >
            <Form.Group as={Row} controlId="oldpassword">
              <Form.Label column sm="4">
                Password Lama
              </Form.Label>
              <Col sm="8">
                <Form.Control type="password" name="oldpassword" onChange={handleChange} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="password">
              <Form.Label column sm="4">
                Password Baru
              </Form.Label>
              <Col sm="8">
                <Form.Control type="password" name="password" onChange={handleChange} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="refeatpassword">
              <Form.Label column sm="4">
                Refeat Password
              </Form.Label>
              <Col sm="8">
                <Form.Control type="password" name="repassword" onChange={handleChange} />
              </Col>
            </Form.Group>
            <Modal.Footer>
              <Button type="submit" variant="primary" onClick={() => window.location.reload(false)}  >
                Simpan
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>

      </Modal>
    </>
  );
})
// yang buat handle submit sebelumnya mana ya mas


function Update(props) {
  // const data = JSON.parse(localStorage.DataUser);
  const mergeUser = { ...data }
  const [data, setData] = useState(mergeUser)
  const handleChange = e => setData({ ...data, [e.target.name]: e.target.value })
  const handleSubmit = event => props.updatePhoto(event, data)


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        <i className="fa fa-fw fa-user"></i>Upload File
      </Button>

      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Upload File <i class="text-muted">(wajib)</i></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} >
            <Form.Group as={Row} controlId="nama">
              <Form.Label column sm="4">
                Foto Ktp
              </Form.Label>
              <Col sm="7">
                <Form.Control type="file" name="fotoktp" onChange={handleChange} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="nama">
              <Form.Label column sm="4">
                Foto Pribadi
              </Form.Label>
              <Col sm="8">
                <Form.Control type="file" name="fotopribadi" onChange={handleChange} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="nama">
              <Form.Label column sm="4">
                Foto Ktp+Pribadi
              </Form.Label>
              <Col sm="8">
                <Form.Control type="file" name="fotoktppribadi" onChange={handleChange} />
              </Col>
            </Form.Group>
            <Modal.Footer>
              <Button type="submit" variant="primary" >
                Simpan
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>

      </Modal>
    </>
  );
}

const Edit = memo((props) => {


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        <i className="fa fa-fw fa-user"></i>Lengkapi Data
      </Button>
      <Modal style={{ borderRadius: "30px" }} show={show} onHide={handleClose} animation={false}>
        <Modal.Title style={{ marginLeft: "auto", marginRight: "auto", paddingTop: "50px", color: "#1F1F1F", fontSize: "24px", fontWeight: "500", fontFamily: "Helvetica Neue LT Std" }} >Apakah Anda Pribadi atau Perusahaan ?</Modal.Title>
        <div className="row text-center" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
          <div className="col-md-6">
            <Sendiri />
          </div>
          <div className="col-md-6">
            <Perusahaan />
          </div>
        </div>
      </Modal>
    </>
  );
})

const UpdateData = memo((props) => {
  const { user } = JSON.parse(localStorage.DataUser);
  // const update = this.state.onSubmit;
  // console.log("dataInit", user)
  const mergeUser = { ...user }
  // console.log(user);
  const [data, setData] = useState(mergeUser)
  // console.log(data);
  const handleChange = e => setData({ ...data, [e.target.name]: e.target.value })
  // const handleSubmit = console.log('berhasil');
  const handleSubmit = event => props.updateData(event, data)
  // console.log(handleSubmit);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button onClick={handleShow} variant="primary">
        <i className="fa fa-fw fa-user mr-2"></i>Update Profile
      </Button>

      <Modal show={show} onHide={handleClose} animation={false}  >
        <Modal.Header className="text-center" closeButton>
          <Modal.Title >Update Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}  >
            <Form.Group as={Row} controlId="nama">
              <Form.Label column sm="4">
                Nama
              </Form.Label>
              <Col sm="8">
                <Form.Control type="text" name="full_name" value={data.full_name} onChange={handleChange} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="email">
              <Form.Label column sm="4" >
                Email
              </Form.Label>
              <Col sm="8">
                <Form.Control type="email" value={data.email || ''} readOnly />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="alamat">
              <Form.Label column sm="4">
                Alamat
              </Form.Label>
              <Col sm="8">
                <Form.Control type="text" name="alamat" value={data.alamat || ''} onChange={handleChange} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="alamat">
              <Form.Label column sm="4">
                No Telephone
              </Form.Label>
              <Col sm="8">
                <Form.Control type="text" name="no_hp" value={data.no_hp} onChange={handleChange} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="tanggal_lahir">
              <Form.Label column sm="4">
                Tanggal Lahir
              </Form.Label>
              <Col sm="8">
                <Form.Control type="date" name="tanggal_lahir" value={data.tanggal_lahir} onChange={handleChange} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="tempat_lahir">
              <Form.Label column sm="4">
                Tempat Lahir
              </Form.Label>
              <Col sm="8">
                <Form.Control type="text" name="tempat_lahir" value={data.tempat_lahir} onChange={handleChange} />
              </Col>
            </Form.Group>
            <Modal.Footer>
              <Button type="submit" variant="primary"   >

                Simpan
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
})


function Sendiri(props) {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [form, setForm] = useState({
    type: 'pribadi',
    nik_ktp: '',
    tempat_lahir: '',
    nama: '',
    jenis_kelamin: '',
    alamat: '',
    foto_ktp: '',
    selfie_ktp: '',
    npwp: '',
    no_rek: '',
    cabang_bank: '',
    data_bank: '',
    buku_tabungan: '',
  });

  const handleTextChange = event => setForm({ ...form, [event.target.name]: event.target.value })
  // fields['type'] = "pribadi";

  const handleInputSubmit = () => {
    new ProfilePenerbit().lengkapiData(form)
    console.log('berhasil', form)
    // this.lengkapiData(form);  
  }

  return (
    <>
      <a href="#" onClick={handleShow} className="btn my-4" style={{ background: "#214981", color: "#ffffff", padding: "5px 50px", borderRadius: "20px" }}>
        Pribadi
      </a>

      <Modal size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        show={show} onHide={handleClose} animation={false}>
        <Form style={{ padding: "20px" }} onSubmit={e => e.preventDefault()} encType="multipart/form-data"  >
          <h5>Data Sesuai Ktp</h5>
          {/* {JSON.stringify(form)} */}
          <Form.Group as={Row} controlId="ktp">

            <Col sm="6">
              <Form.Control type="text" name="nik_ktp" value={form.nik_ktp} onChange={handleTextChange} placeholder="NIK KTP" />
            </Col>
            <Col sm="6">
              <Form.Control type="text" name="tempat_lahir" value={form.tempat_lahir} onChange={handleTextChange} placeholder="Tempat Lahir" />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="nama">
            <Col sm="6">
              <Form.Control type="text" name="nama" value={form.nama} onChange={handleTextChange} placeholder="Nama" />
            </Col>
            <Col sm="6">
              <select class="custom-select" name="jenis_kelamin" value={form.jenis_kelamin} onChange={handleTextChange}>
                <option>Jenis Kelamin</option>
                <option>Laki</option>
                <option>perempuan</option>
              </select>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="alamat">
            <Col sm="12">
              <Form.Control type="text" name="alamat" value={form.alamat} onChange={handleTextChange} placeholder="Alamat" />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="foto_ktp">
            <Col sm="6">
              <Form.Label column sm="6" >
                Foto Ktp
              </Form.Label>
              <Form.Control type="file" name="foto_ktp" value={form.foto_ktp} onChange={handleTextChange} placeholder="Foto Ktp" />
            </Col>
            <Col sm="6">
              <Form.Label column sm="6">
                Selfie dengan Ktp
              </Form.Label>
              <Form.Control type="file" name="selfie_ktp" value={form.selfie_ktp} onChange={handleTextChange} placeholder="Selfie Pegang Ktp" />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="npwp">
            <Col sm="6">
              <Form.Label column sm="4">
                NPWP
              </Form.Label>
              <Form.Control type="file" name="npwp" value={form.npwp} onChange={handleTextChange} placeholder="NPWP" />
            </Col>
          </Form.Group>

          <h5>Data Bank</h5>
          <Form.Group as={Row} controlId="norek">
            <Col sm="6">
              <Form.Control type="text" name="no_rek" value={form.no_rek} onChange={handleTextChange} placeholder="No Rekening" />
            </Col>
            <Col sm="6">
              <Form.Control type="text" name="cabang_bank" value={form.cabang_bank} onChange={handleTextChange} placeholder="Cabang Bank" />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="data_bank">

            <Col sm="6">
              <select class="custom-select" name="data_bank" value={form.data_bank} onChange={handleTextChange}>
                <option>Nama Bank</option>
                <option>Bri</option>
                <option>Mandiri</option>
              </select>
            </Col>
            <Col sm="6">
              <Form.Control type="file" name="buku_tabungan" value={form.buku_tabungan} onChange={handleTextChange} placeholder="Buku Tabungan" />
            </Col>
          </Form.Group>
          <Modal.Footer>
            <Button style={{ marginRight: "auto", marginLeft: "auto", padding: "5px 50px", borderRadius: "20px" }} type="button" onClick={handleInputSubmit} variant="primary">
              Simpan
            </Button>
          </Modal.Footer>
        </Form>


      </Modal>
    </>
  );
}

function Perusahaan(props) {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [form, setForm] = useState({
    nik_ktp: '',
    tempat_lahir: '',
    full_name: '',
    jenis_kelamin: '',
    alamat: '',
    foto_ktp: '',
    selfie_ktp: '',
    akta: '',
    nib: '',
    sk_kemenkumham: '',
    npwp_perusahaan: '',
    ktp_pengurus: '',
    buku_tabungan: '',

  });

  const handleTextChange = event => setForm({ ...form, [event.target.name]: event.target.value })
  const handleInputSubmit = () => {
    console.log('berhasil', form)
    // api("kirimkeapi", "POST", form)
  }

  return (
    <>
      <a href="#" onClick={handleShow} className="btn my-4" style={{ background: "#214981", color: "#ffffff", padding: "5px 50px", borderRadius: "20px" }}>
        Perusahaan
      </a>

      <Modal size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        show={show} onHide={handleClose} animation={false}>
        <Form style={{ padding: "20px" }} onSubmit={e => e.preventDefault()} >
          <h5>Data Penangung Jawab</h5>
          <Form.Group as={Row} controlId="ktp">
            <Col sm="6">
              <Form.Control type="text" name="nik_ktp" value={form.nik_ktp} onChange={handleTextChange} placeholder="NIK KTP" />
            </Col>
            <Col sm="6">
              <Form.Control type="text" name="tempat_lahir" value={form.tempat_lahir} onChange={handleTextChange} placeholder="Tempat Tanggal Lahir" />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="nama">
            <Col sm="6">
              <Form.Control type="text" name="full_name" value={form.full_name} onChange={handleTextChange} placeholder="Nama" />
            </Col>
            <Col sm="6">
              <select class="custom-select" name="jenis_kelamin" value={form.jenis_kelamin} onChange={handleTextChange}>
                <option>Jenis Kelamin</option>
                <option>Laki</option>
                <option>perempuan</option>
              </select>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="alamat">
            <Col sm="12">
              <Form.Control type="text" name="alamat" value={form.alamat} onChange={handleTextChange} placeholder="Alamat" />
            </Col>

          </Form.Group>
          <Form.Group as={Row} controlId="foto_ktp">
            <Col sm="6">
              <Form.Label column sm="6" >
                Foto Ktp
              </Form.Label>
              <Form.Control type="file" name="foto_ktp" value={form.foto_ktp} onChange={handleTextChange} placeholder="Foto Ktp" />
            </Col>
            <Col sm="6">
              <Form.Label column sm="6">
                Selfie dengan Ktp
              </Form.Label>
              <Form.Control type="file" name="selfie_ktp" value={form.selfie_ktp} onChange={handleTextChange} placeholder="Selfie Pegang Ktp" />
            </Col>
          </Form.Group>


          <h5>Data Perusahaan</h5>
          <Form.Group as={Row} controlId="akta">
            <Col sm="6">
              <Form.Label column sm="4">
                Akta
              </Form.Label>
              <Form.Control type="file" name="akta" value={form.akta} onChange={handleTextChange} />
            </Col>
            <Col sm="6">
              <Form.Label column sm="4">
                NIB
              </Form.Label>
              <Form.Control type="file" name="nib" value={form.nib} onChange={handleTextChange} placeholder="NIB" />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="sk">
            <Col sm="6">
              <Form.Label column sm="12">
                SK Kemenkumham
              </Form.Label>
              <Form.Control type="file" name="sk_kemenkumham" value={form.sk_kemenkumham} onChange={handleTextChange} placeholder="NPWP" />
            </Col>
            <Col sm="6">
              <Form.Label column sm="12">
                Foto NPWP Perusahaan
              </Form.Label>
              <Form.Control type="file" name="npwp_perusahaan" value={form.npwp_perusahaan} onChange={handleTextChange} placeholder="NPWP" />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="pengurus">
            <Col sm="6">
              <Form.Label column sm="12">
                Foto Ktp Pengurus
              </Form.Label>
              <Form.Control type="file" name="ktp_pengurus" value={form.ktp_pengurus} onChange={handleTextChange} placeholder="NPWP" />
            </Col>
            <Col sm="6">
              <Form.Label column sm="12">
                Buku Tabungan
              </Form.Label>
              <Form.Control type="file" name="buku_tabungan" value={form.buku_tabungan} onChange={handleTextChange} placeholder="NPWP" />
            </Col>
          </Form.Group>

          <Modal.Footer>
            <Button style={{ marginRight: "auto", marginLeft: "auto", padding: "5px 50px", borderRadius: "20px" }} variant="primary" type="button" onClick={handleInputSubmit}>
              Simpan
            </Button>
          </Modal.Footer>
        </Form>


      </Modal>
    </>
  );
}


export default connect(mapStateToProps, mapDispatchToProps)(ProfilePenerbit);
