import React , {memo,useState} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    Link,
  } from "react-router-dom";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import seru from "../assets/img/seru.png";
import seru2 from "../assets/img/Group 895.png";
import proyk from "../assets/img/Group 863.png";
import dompet from "../assets/img/dompet.png";
import bali from "../assets/img/bali.png";
import icon_pengelola from "../assets/img/Icon Pengelola.svg";
import Kerja from "../assets/img/kerjasama.co.id.vertical.png";
import { BASE_URL, tokenGet, URLFront } from "../service/api";
import '../App.scss'
import '../AppMobile.scss'
import '../Bg.scss'
import axios from 'axios'
import $, { isEmptyObject } from 'jquery'
import {
    Button,Message,
    Checkbox,
    Grid,
    Card,
    Header,
    Icon,
    Image,
    Menu,
    Segment,
    Sidebar,
    Input,
    Label,
    List,
    StatisticValue,
} from 'semantic-ui-react'
import { connect } from "react-redux";
import { BallBeat } from 'react-pure-loaders';
import { getUserpemodal } from "../actions/userpemodal.actions";
import Navbar_user from "../components/navbar_home"; 
import swal from 'sweetalert';
import { StatefulPinInput } from 'react-input-pin-code'

class VerifikasiOTP extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            error: null,
            items: [],
            paramsVerifikasiHead : 1,
            c: false,
            userinfo: [],
            isLoad:true,  
            dataProyekTersimpan : [],
            pin : '',
            mail : '',
            otp : []
        };
    }

    componentDidMount() {
        this.state.isLoad = true;
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let mail = params.get('__ma');
        this.setState({ mail: mail });
        
        if (mail === null || mail === "") {
            // alert('Akses ditolak !');
            swal({text: 'Akses ditolak !', icon: 'error'})
            setTimeout(()=>{window.location = URLFront}, 0);
            return false;
        }

        setTimeout(()=>{$("#pin").focus()}, 1);
        $("body").addClass('bg');
    }

    handleInputChange = e => {
        this.setState({ pin: e.target.value });
    };

    submitPin = e => {
        let code = this.state.otp
        let pin = ''
        if(code.length > 0){
            pin = code[0] + code[1] + code[2] + code[3] + code[4] + code[5]
            if(pin.length < 6){
                swal({text: 'Masukkan 6 digit kode OTP', icon: 'error'})
                return
            } 
        } else {
            swal({text: 'Masukkan kode OTP', icon: 'error'})
            return
        }
        
        this.insertPin(pin);

        // if (this.state.pin.length < 5) {
        //     $(".errPinOTP").attr('style','')
        //     setTimeout(()=>{$("#pin").focus()}, 1);
        //     setTimeout(()=>{$(".errPinOTP").attr('style','display:none')}, 1000);
        // } else {
        //     this.insertPin();
        // }
    }

    insertPin (pin) {
        $(".submitOTP").addClass('loading');
        $(".submitOTP").attr('disabled','disabled');

        axios.get(BASE_URL+"/aktivasiotp", {params: {
            // otp : this.state.pin,
            mail : this.state.mail,
            otp: pin,
            sendulang : 0,
        }},
          axios.defaults.headers.common['Authorization'] = tokenGet,
          axios.defaults.headers.common['Accept']        = 'application/json'
          )
        .then((resp) => {
            $(".submitOTP").removeClass('loading');
            $(".submitOTP").removeAttr('disabled');
            // alert(resp.data.body.message)
            swal({text: resp.data.body.message, icon: 'success'})
            setTimeout(()=>{window.location = resp.data.body.location}, 1000);
        })
        .catch(error => {
            if (error.response) {
                
                swal({
                    text: error.response.data.body.message,
                    icon: 'error',
                    closeOnClickOutside: false
                }).then((value) => {
                    window.location.reload()
                });
            }
            $(".submitOTP").removeClass('loading');
            $(".submitOTP").removeAttr('disabled');
        });

    }

    kirimUlang() {
        if (this.state.mail === null) {
            // alert('Transaksi ditolak !');
            swal({text: 'Transaksi ditolak !', icon: 'error'})
            return false;
        }

        $(".submitOTP").addClass('loading');
        $(".submitOTP").attr('disabled','disabled');

        axios.get(BASE_URL+"/aktivasiotp", {params: {
            sendulang : 1,
            mail : this.state.mail
        }},
          axios.defaults.headers.common['Authorization'] = tokenGet,
          axios.defaults.headers.common['Accept']        = 'application/json'
          )
        .then((resp) => {
            $(".submitOTP").removeClass('loading');
            $(".submitOTP").removeAttr('disabled');
            // alert(resp.data.body.message)
            swal({text: resp.data.body.message, icon: 'success'})
            if (resp.data.body.location) {
                setTimeout(()=>{window.location = resp.data.body.location}, 1000);
            }
        })
        .catch(error => {
            
            if (error.response) {
                swal({
                    text: error.response.data.body.message,
                    icon: 'error',
                    closeOnClickOutside: false
                }).then((value) => {
                    window.location.reload()
                });
            }
            $(".submitOTP").removeClass('loading');
            $(".submitOTP").removeAttr('disabled');
        });
    }

    render() {
        const verified = this.props.verified
        const verified_advance = this.props.verified_advance
        const { error, isLoaded, items, pin, mail } = this.state;
        
        if (!this.state.isLoad) {
           
            return (
             <div
             style={{paddingTop:'300px',Width:'initial',textAlign:'center'}}
              >
               <BallBeat
              color={'#123abc'}
              loading
              />
              Loading....</div>
            );
          }
          else {
            return (
                <>
                {/* <div style={{ backgroundColor: "#EEEEEE" }}>
                    <Navbar_user verifikasiotp={this.state.paramsVerifikasiHead} />
                    <br/><br/><br/>
                    <div className="row VerifikasiOTP">
                        <div className="col-md-12">
                            <div className="containerOTP_">
                                <div className="judulOTP">Kode OTP</div>
                            </div>
                            <div className="bodyOTP">
                                <div className="judulkodeOTP">
                                    Masukkan Kode OTP
                                </div>
                                <div className="bodyInputOTP">
                                    <Input type="password" id="pin" name="pin" className="inputPin" 
                                    defaultValue={pin}
                                    onChange={this.handleInputChange} maxLength="6" placeholder="______"/>
                                    <br/><br/>
                                    <Label basic color='orange' pointing className="errPinOTP" style={{display:'none'}}>
                                        Masukkan minimal 5 digit OTP
                                    </Label>
                                </div>
                                <div className="footerInputOTP">
                                    <Button className="submitOTP" circular onClick={this.submitPin}>Submit</Button>
                                    <br/><br/>
                                    <a href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.kirimUlang()
                                    }}
                                    className="kirimUlangOTP">Kirim Ulang</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="container" style={{ paddingTop: "100px", paddingBottom: "100px" }}>
                    <div className="row mt-4 align-items-center justify-content-center">
                        <div className="col-lg-5">
                            <div className="card" style={{ borderRadius: "20px", backgroundColor: "#fafafa", paddingRight: "20px" }}>
                                <div className="text-center mt-4 ml-4"><img src={Kerja} className="img-fluid card-image-top" width="180" alt="logo-kerja" /></div>
                                <div className="text-center mt-4 ml-4" style={{ color: "#666666", fontSize: "16px" }}>Masukkan Kode Aktivasi Pendaftaran</div>
                                <div className="card-body">
                                    {/* <Alert className="alertErrorLogin" variant="danger" show={this.state.showErrorLogin}>
                                        <center>{this.state.alertErrorLogin}</center>
                                    </Alert> */}

                                    {/* <div className="bodyInputOTP text-center">
                                        <Input type="password" id="pin" name="pin" className="inputPin" 
                                            defaultValue={pin}
                                            onChange={this.handleInputChange} maxLength="6" placeholder="______"
                                        />
                                            <br/><br/>
                                            <Label basic color='orange' pointing className="errPinOTP" style={{display:'none'}} >
                                            Masukkan minimal 5 digit OTP
                                            </Label>
                                    </div> */}

                                    <div className="form-group mt-4 ml-4">
                                        <div className='row justify-content-center input-group input-group-lg' style={{marginLeft: 0}}>
                                            <StatefulPinInput
                                                name="otp"
                                                length={6}
                                                size={'lg'}
                                                type={'number'}
                                                placeholder={''}
                                                onChange={(value, index, values) => {
                                                    this.setState({otp: values})
                                                    this.setState({error: ''})
                                                }}
                                                // initialValue={'123456'}
                                            />
                                        </div>
                                        
                                    </div>

                                    <div className="footerInputOTP text-center " style={{ marginTop: '40px', marginLeft: '30px' }}>
                                        <Button className="submitOTP" circular onClick={this.submitPin}>Submit</Button>
                                        <br/><br/>
                                        <a href="#"  
                                        style={{ textDecoration: "none", fontWeight: "bold"}} 
                                        onClick={(e) => { e.preventDefault();  this.kirimUlang() }} >Kirim Ulang Kode</a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </>
            )
        }
     }
}

export default connect()(VerifikasiOTP);