import { combineReducers } from 'redux';


const authData = (state = {}, action) => {
  switch (action.type) {

    case "AUTH_USER_KELOLA_FAIL":
      return {
        isLoggedInPengelola: false,
        isLoggedInPemodal: false,
        isUserId: null,
      }

    case "AUTH_USER_KELOLA_SUCCESS":
      return {
        isLoggedInPengelola: true,
        isLoggedInPemodal: false,
        isUserId: action.payload,
      }


    case "AUTH_USER_PEMODAL_SUCCESS":
      return {
        isLoggedInPengelola: false,
        isLoggedInPemodal: true,
        isUserId: action.payload,
      }

    case "AUTH_USER_PEMODAL_FAIL":
      return {
        isLoggedInPengelola: false,
        isLoggedInPemodal: false,
        isUserId: null,
      }

    case "AUTH_USER_LOGOUT":
      return {
        isLoggedInPengelola: false,
        isLoggedInPemodal: false,
        isUserId: null,
      }

    default:
      return state;
  }
}

const loginUser = (state = {}, action) => {
  switch (action.type) {

    case "LOGIN_USER_LOADING":
      return {
        isLoading: true,
        isError: false,
        isSuccess: false,
        isType: null,
        errors: null
      }

    case "LOGIN_USER_PEMODAL_SUCCESS":
      return {
        isLoading: false,
        isError: false,
        isSuccess: true,
        isType: 1,
        errors: null,
      }

    case "LOGIN_USER_PENGELOLA_SUCCESS":
      return {
        isLoading: false,
        isError: false,
        isSuccess: true,
        isType: 2,
        errors: null,
      }

    case "LOGIN_USER_FAIL":
      return {
        isLoading: false,
        isError: true,
        isSuccess: false,
        isType: null,
        errors: action.payload
      }

    default:
      return state;
  }
}

export default combineReducers({
  authData,
  loginUser,
});
