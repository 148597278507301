import React , {memo,useState,useEffect} from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import seru from "../../../assets/img/seru.png";
import seru2 from "../../../assets/img/Group 895.png";
import proyk from "../../../assets/img/Group 863.png";
import dompet from "../../../assets/img/dompet.png";
import opsi1 from "../../../assets/img/Group 1395.svg";
import opsi2 from "../../../assets/img/Group 1397.svg";
import opsi3 from "../../../assets/img/Group 1398.svg";
import opsi4 from "../../../assets/img/Group 1399.svg";
import logoBank1 from "../../../assets/img/logo-bank-hana.svg";
import bni from "../../../assets/img/bnivec.svg";
import bali from "../../../assets/img/bali.png";
import icon_pengelola from "../../../assets/img/Icon Pengelola.svg";
import { BASE_URL, tokenGet } from "../../../service/api";
import '../../../App.scss'
import '../../../Bg.scss'
import DetailDompet from '../../DetailDompet'
import axios from 'axios'
import $ from 'jquery'
import {
    Button,Message,
    Checkbox,
    Grid,
    Card,
    Form,
    Header,
    Radio,
    Icon,
    Image,
    Menu,
    Segment,
    Sidebar,
    Input,
    Label,
    List,
    StatisticValue,
} from 'semantic-ui-react'
import { connect } from "react-redux";
import { BallBeat } from 'react-pure-loaders';
import { getUserpemodal } from "../../../actions/userpemodal.actions";
import Navbar_user from "../../../components/navbar_home"; 
import swal from 'sweetalert';
import { Link } from "react-router-dom";

class BayarStepVA extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            error: null,
            items: [],
            paramsPembayaranHead : 1,
            c: false,
            userinfo: [],
            itemsDompet: [],
            isLoad:false,  
            dataProyekTersimpan : [],
            valuePayment: 'VA',
        };
    }

    getUserpemodal = async (values) => {

        try {
            // console.log(this.props.auth.isUserId);
            const response = await this.props.dispatch(getUserpemodal(values));
            
            localStorage.DataUser = JSON.stringify(response.responseBody);
          // console.log(response.responseBody.success);
           
             if (!response.responseBody.success) {
                 throw response;
             } else if (response.responseBody.success) {
                if (!this.props.location.data) {
                    // alert('Maaf Refresh dilarang pada saat melakukan pembayaran, anda akan dikembalikan ke halaman dashboard utama')
                    // window.setTimeout(function(){
                    //     window.location.href = "/UserPemodal"
                    // },0);
                    
                    swal({
                        text: 'Maaf Refresh dilarang pada saat melakukan pembayaran, anda akan dikembalikan ke halaman dashboard utama', 
                        icon: 'warning', 
                        closeOnClickOutside: false
                    }).then((value) => {
                        window.setTimeout(function(){
                            window.location.href = "/UserPemodal"
                        },0);
                    });
                 } else {
                    this.state.userinfo = JSON.stringify(response.responseBody.user);
                    this.state.isLoad = true;
                    this.getListProyekTersimpan();
                    this.getDompet();
                    $("body").addClass('bg');
                    
                    // var dateN = new Date(this.props.location.data.dataPembayaran.datetime_expired);
                    // var countDownDate = dateN.setDate(dateN.getDate() + 1);
                    // var countDownDate = dateN.setHours(24,0,0);

                    // var x = setInterval(function() {
                    //     var now = new Date().getTime();

                    //     var distance = countDownDate - now;

                    //     var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    //     var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    //     // var minutes = Math.floor((distance % (1000 * 60 * 5)) / (1000 * 60));
                    //     var seconds = Math.floor((distance % (1000 * 60)) / 1000);

                    //     document.getElementById("waktuBayar").innerHTML = hours + ":" + minutes + ":" + seconds + ""; // 24 Jam
                    //     // document.getElementById("waktuBayar").innerHTML = "0"+minutes + ":" + seconds + ""; // 5 menit

                    //     if (distance < 0) {
                    //         clearInterval(x);
                    //         document.getElementById("waktuBayar").innerHTML = "EXPIRED";
                    //     }
                    // }, 1000);
                }
            }
          
        } catch (error) {
            setTimeout(() => {
                window.location.href = "/Signin"
                localStorage.removeItem('token');
                localStorage.clear();
        
                this.props.dispatch({
                  type: "AUTH_USER_LOGOUT",
                })
            }, 2000);
        }
    }

    timeToPayment = (value) => {
        this.setState({valuePayment: value})
    }

    getListProyekTersimpan () {
        const userinfo = JSON.parse(this.state.userinfo)
        
        axios.get(BASE_URL+"/customer/pemodal/proyektersimpan", {params: {
            id_pemodal  : userinfo.id,
            type        : 0,
            limit       : 10
        }}, 
        axios.defaults.headers.common['Authorization'] = localStorage.token,
        axios.defaults.headers.common['Accept']        = 'application/json'
        )
        .then((resp) => {

            var lengthdataSlot = resp.data.data.length;
            
            this.setState({ 
                dataProyekTersimpan : resp.data.data,
                totalSlot : lengthdataSlot,
                totalProyek : lengthdataSlot,
            })

            var tmpSaldoPemb = 0
            resp.data.data.map((answer, i) => {     
                var postSlot = {
                    "ProyekID" : answer.id_project,
                    "HargaSlot": answer.proyek_overview.slot_nominal,
                    "Slot"     : 1
                }

                var tmpPembayaran = answer.proyek_overview.slot_nominal * 1
                tmpSaldoPemb  = parseInt(tmpSaldoPemb) + parseInt(tmpPembayaran)

                this.setState({ 
                    dataSlot : [...this.state.dataSlot,postSlot],
                    totalPembayaran : tmpSaldoPemb
                })
            })
            
        })
        .catch((resp) =>{

        });
    }

    getDompet() {
        
        axios.get(BASE_URL+"/customer/dompet",
        axios.defaults.headers.common['Authorization'] = localStorage.token,
        axios.defaults.headers.common['Accept']        = 'application/json'
        )
        .then((resp) => {
            if (resp.data.data) {
                this.setState({ 
                    itemsDompet : resp.data.data,
                })

                if (this.state.valuePayment === 'Dompet') {
                    $(".ui.compact.segment.Dompet").attr('style','background-color:#00000017');
                    $(".TransferChild").attr('style','display:none')
                } else {
                    $(".TransferChild").attr('style','display:none')
                    $(".saldoDompet").attr('style','display:none');
                    $(".ringkasContainer").removeClass('methodpay');
                    $(".sisaSaldoDompet").attr('style','display:none');
                    $(".ui.compact.segment.Dompet").style('style','background-color:white');
                }
            } else {
                // alert('Terjadi Kesalahan, Silahkan refresh browser');
                // return false;
            }
        })
        .catch((resp) =>{
            // alert('Terjadi Kesalahan, Silahkan refresh browser');
            // return false;
        });
    }

    handleChange = (e, { value }) => 
    {
        this.setState({ valuePayment : value })

        if (value === 'Dompet') {
            $(".ui.compact.segment.Dompet").attr('style','background-color:#00000017');
            $(".ui.compact.segment.VA").attr('style','background-color:white');
            $(".ui.compact.segment.Transfer").attr('style','background-color:white');
            $(".saldoDompet").attr('style','');
            $(".ringkasContainer").addClass('methodpay');
            $(".sisaSaldoDompet").attr('style','');
            $(".TransferChild").attr('style','display:none')
        } else if (value === 'Trf') {
            $(".TransferChild").attr('style','')
            $(".ringkasContainer").removeClass('methodpay');
            $(".saldoDompet").attr('style','display:none');
            $(".sisaSaldoDompet").attr('style','display:none');
            $(".ui.compact.segment.Dompet").attr('style','background-color:white');
            $(".ui.compact.segment.VA").attr('style','background-color:white');
            $(".ui.compact.segment.Transfer").attr('style','background-color:#00000017');
        } else if (value === 'VA') {
            $(".ringkasContainer").removeClass('methodpay');
            $(".saldoDompet").attr('style','display:none');
            $(".sisaSaldoDompet").attr('style','display:none');
            $(".ui.compact.segment.Dompet").attr('style','background-color:white');
            $(".ui.compact.segment.VA").attr('style','background-color:#00000017');
            $(".ui.compact.segment.Transfer").attr('style','background-color:white');
            $(".TransferChild").attr('style','display:none')
        }
    }

     render() {
        const verified = this.props.verified
        const verified_advance = this.props.verified_advance
        const { error, isLoaded, items } = this.state;
        
        if (!this.state.isLoad) {
           
            const d = this.getUserpemodal({ token: this.props.auth.isUserId });
            
            return (
             <div
             style={{paddingTop:'300px',Width:'initial',textAlign:'center'}}
              >
               <BallBeat
              color={'#123abc'}
              loading
              />
              Loading....</div>
            );
          }
          else {
            const dataSlot = this.props.location.data.dataSlot
            const dataProyek = this.props.location.data.dataProyek
            const totalSlot = this.props.location.data.totalSlot
            const totalPembayaran = this.props.location.data.totalPembayaran
            const dataPembayaran = this.props.location.data.dataPembayaran
            
            return (
                <div style={{ backgroundColor: "#EEEEEE" }}>
                    <Navbar_user pembayaran={this.state.paramsPembayaranHead} />
                    <div className="row">
                        <div className="col-md-8">
                            <PetunjukBayar dataProyekTersimpan={this.state.dataProyekTersimpan} dataSlot={dataSlot} dataProyek={dataProyek}
                            totalSlot={totalSlot} totalPembayaran={totalPembayaran} valuePayment={this.state.valuePayment} handleChange={this.handleChange}
                            dataPembayaran = {dataPembayaran}
                            />
                        </div>
                        <div className="col-md-4">
                            <RingkasanPembayaran dataSlot={dataSlot} totalSlot={totalSlot} totalPembayaran={totalPembayaran} dataProyek={dataProyek}
                            dataPembayaran = {dataPembayaran}/>
                        </div>
                    </div>
                    <br/><br/><br/>
                    
                </div>
            )
        }
     }
}

function PetunjukBayar({dataProyekTersimpan,dataSlot,totalSlot,totalPembayaran,dataProyek,valuePayment,handleChange,dataPembayaran}) {
    return(
        <div className="boxContainer">
            <div className="judulboxTop">
                <div className="namaboxTop">Petunjuk Pembayaran</div>
            </div>
            <Col xs md="12" className="bodyPetunjukPembayaran">
                <div className="titlePembayaran">Lakukan pembayaran melalui ATM/transfer dengan detail sebagai berikut:</div>
                <div className="contentPetunjukBayar">
                    <div className="lblTotal col-12">Total :</div>
                    { (dataSlot.length == 1) ? 
                    (
                        <>
                        <div className="lblJumlahBayar col-12">
                            {/* Rp. {dataProyek.HargaSlot.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
                            Rp. {totalPembayaran.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </div>
                        </>
                    )
                    :
                    (
                        <>
                        <div className="lblJumlahBayar col-12">
                            Rp. {totalPembayaran.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </div>
                        </>
                    )
                    }
                    <div className="hrJumlahBayar"></div>
                    <div className="lblRekeningTujuan col-12">Rekening Tujuan :</div>
                    <div className="imgRekening col-12">
                        <div className="namaBank">Hana Bank Virtual Account</div> 
                        <div className="noRek">{dataPembayaran.virtual_account}</div>
                    </div>
                </div>
                <div className="titlePetunjuk">Petunjuk pembayaran melalui transfer</div>
                <div className="contentPetunjukTrf col-12">
                    <Grid divided='vertically'>
                        <Grid.Row columns={4}>
                            <Grid.Column>
                                <Image src={opsi1} /> <br />
                                <span className="lblPetunjuk">Pada <b>ATM</b> pilih opsi <b>transfer</b></span>
                            </Grid.Column>
                            <Grid.Column>
                                <Image src={opsi2} /> <br />
                                <span className="lblPetunjuk">Kemudian pilih <b>opsi virtual account</b></span>
                            </Grid.Column>
                            <Grid.Column>
                                <Image src={opsi3} /> <br />
                                {/* <Label className="lblPetunjuk">Masukan nomor rekening <b>BNI virtual account</b></Label> */}
                                <span className="lblPetunjuk">Masukan nomor rekening <b>virtual account</b></span>
                            </Grid.Column>
                            <Grid.Column>
                                <Image src={opsi4} /> <br />
                                <span className="lblPetunjuk">Pastikan <b>jumlah bayar</b>, lalu selesaikan pembayaran</span>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </Col>
        </div>
    )
}

function RingkasanPembayaran({dataSlot,totalSlot,totalPembayaran,dataProyek,dataPembayaran}) {

    var timeEnd = new Date(dataPembayaran.datetime_expired).getTime()
    const [times, setTimes] = useState('-');
    useEffect(() => {
        
        const interval = setInterval(() => {
            
            var date = new Date();
            date.setDate(date.getDate());
            var day = ('0' + date.getDate()).slice(-2);
            var month = ('0' + (date.getMonth() + 1)).slice(-2);
            var yy = date.getYear();
            var hours = ('0' + date.getHours()).slice(-2);
            var minutes = ('0' + date.getMinutes()).slice(-2);
            var seconds = ('0' + date.getSeconds()).slice(-2);
            var year = (yy < 1000) ? yy + 1900 : yy;

            var now = year + '-' + month + '-' + day + ' ' + hours + ":" + minutes + ":" + seconds

            var timeStart = new Date(now).getTime();
            var hourDiff = timeEnd - timeStart; //in ms
            var secDiff = hourDiff / 1000; //in s
            var minDiff = hourDiff / 60 / 1000; //in minutes
            var hDiff = hourDiff / 3600 / 1000; //in hours

            var humanReadable = {};

            humanReadable.hours = Math.floor((hourDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            humanReadable.minutes = Math.floor((hourDiff % (1000 * 60 * 60)) / (1000 * 60));
            humanReadable.seconds = Math.floor((hourDiff % (1000 * 60)) / 1000);

            if(humanReadable.hours < 0){
                setTimes('Expired')
            } else {

                if(humanReadable.hours.toString().length < 2) {
                    humanReadable.hours = "0" + humanReadable.hours.toString()
                }
                if(humanReadable.minutes.toString().length < 2) {
                    humanReadable.minutes = "0" + humanReadable.minutes.toString()
                }
                if(humanReadable.seconds.toString().length < 2) {
                    humanReadable.seconds = "0" + humanReadable.seconds.toString()
                }

                setTimes(humanReadable.hours + " : " + humanReadable.minutes + " : " + humanReadable.seconds)
            }

        }, 1000);
        
        return () => clearInterval(interval);

    }, []);

    return(
        <div className="ringkasContainer detailpay">
            <div className="judulboxTop">
                <div className="namaboxTop">
                    <div className="lblTagihan">Nomor Tagihan</div>
                    <div className="noTagihan">
                        {dataPembayaran.trx_id}
                    </div>
                </div>
            </div>
            <Col xs md="12" className="bodyStatusBayar">
                <div className="lblstatus">Status</div>
                <div className="lblstatusbayar">Menunggu Pembayaran</div>
                <div className="batasBayar">
                    <i className="lblbatasBayar">Batas pembayaran :</i> <i id="waktuBayar" className="waktuBayar">
                        {times}
                    </i>
                </div>
            </Col>
            <div className="" style={{marginTop: 50, textAlign: 'center'}}>
                <Link to={{ pathname: "/UserPemodal" }}>
                    <Button color='blue'>Kembali ke Halaman Utama</Button>
                </Link>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        getUserpemodal: state.userReducer.getUserpemodal,
        auth: state.authReducer.authData
    }
}

function mapDispatchToProps(dispatch) {
    return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(BayarStepVA);