import React, { Component, useState } from 'react';
import { Button } from 'semantic-ui-react'

export class CameraFeed extends Component {

    /**
     * Processes available devices and identifies one by the label
     * @memberof CameraFeed
     * @instance
     */
    processDevices(devices) {
        devices.forEach(device => {
            console.log(device.label);
            this.setDevice(device);
        });
    }

    /**
     * Sets the active device and starts playing the feed
     * @memberof CameraFeed
     * @instance
     */
    async setDevice(device) {
        const { deviceId } = device;
        const stream = await navigator.mediaDevices.getUserMedia({ audio: false, video: { deviceId } });
        this.videoPlayer.srcObject = stream;
        this.videoPlayer.play();
    }

    /**
     * On mount, grab the users connected devices and process them
     * @memberof CameraFeed
     * @instance
     * @override
     */
    async componentDidMount() {
        const cameras = await navigator.mediaDevices.enumerateDevices();
        this.processDevices(cameras);
    }

    /**
     * Handles taking a still image from the video feed on the camera
     * @memberof CameraFeed
     * @instance
     */

    takePhoto = () => {

        this.setState({isCapture : true})

        const { sendFile } = this.props;
        const context = this.canvas.getContext('2d');
        context.drawImage(this.videoPlayer, 0, 0, 655, 492);
        this.canvas.toBlob(sendFile);
    };

    repeatPhoto = () => {
        this.setState({isCapture : false})
        this.componentDidMount()

        const { sendFile } = this.props;
        sendFile('repeat')
    }

    state = ({isCapture : false})

    render() {
        return (
            <div className="c-camera-feed">
                <div className='foto-box'>
                    <div className="c-camera-feed__stage">
                        <canvas width="655" id='canvas' height="500" ref={ref => (this.canvas = ref)} />
                    </div>
                </div>

                {!this.state.isCapture && 
                    <div className='viewer-box' style={{zIndex: '1', position: 'absolute', top: '69px'}}>
                        <div className="c-camera-feed__viewer">
                            <video ref={ref => (this.videoPlayer = ref)} width="655"/>
                        </div>
                    </div>
                }
                
                {!this.state.isCapture && <Button color='green' onClick={this.takePhoto}>Ambil Gambar</Button>}
                {this.state.isCapture && <Button color='green' onClick={this.repeatPhoto}>Ulangi</Button>}
            </div>
        );
    }
}
