import React from "react";
import {Button} from 'react-bootstrap';


function Error(){
    return(
        <div className="error" style={{textAlign:"center",marginTop:"250px"}}>
            <img  src="https://i.ibb.co/Jr43rXn/kerjasama.png" alt=""/>
            <h3 style={{marginTop:"20px"}}>Mohon Maaf, Terjadi Kesalahan.</h3>
            <h6 class="text-muted">Silahkan tunggu beberapa saat, <br/> Jika hal ini berkelanjutan, silahkan hubungi <strong>Customer Service</strong> kami.<br /> Syukron, Jazakallah Khair.</h6>
            <Button href="/">Kembali ke Beranda</Button>

        </div>
    );
}

export default Error ;