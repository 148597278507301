
//  const BASE_URL = "https://c672d8b4.ngrok.io";
// Login
//  const BASE_URL = "https://c3a47734.ngrok.io/kerjasamacom";

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const URLFront = process.env.REACT_APP_URL_FRONT;
// export const BASE_URL = "https://c0e172a6b76c.ngrok.io/newkerjasamacom/";
export const tokenGet = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiMSIsImZ1bGxfbmFtZSI6IkFkbWluIiwiZW1haWwiOiJhZG1pbkBrZXJqYXNhbWEuY29tIiwicG9zaXRpb24iOiIwIn0.MbAr-9hL5iCzy7zT9BRvxqreAeNLROnR_4y9ZlHf47g";

export const api = async (url, method, body = null, headers = {}) => {

     try {
       const endPoint = BASE_URL.concat(url);
       const reqBody = body ? JSON.stringify(body) : null;
        
       const fetchParams = {headers};

       /* fetchParams.headers["Authorization"] = ""; */
       fetchParams.headers["Content-type"] = "application/json";
       fetchParams.headers["Origin"] = endPoint;
       fetchParams.headers["Access-Control-Allow-Credentials"] = true;
       fetchParams.headers["Access-Control-Allow-Methods"] = method;
    //    fetchParams.headers["Referer"] = endPoint;-

       if((method === "POST" || method === "PUT") && !reqBody) {
           throw new Error("Request body required");
       }

       if(reqBody) {
           fetchParams.body = reqBody;
        // fetchParams.body = new Blob( [ reqBody ], { type: 'text/plain' } );
       }

       fetchParams.method = method;

       console.log(endPoint, fetchParams); 
       const fetchPromise = fetch(endPoint, fetchParams);
       
       const timeOutPromise = new Promise((resolve, reject) => {
           setTimeout(() => {
               reject("Request Timeout");
           }, 7000);
         });

         const response = await Promise.race([fetchPromise, timeOutPromise]);

         return response;
     } catch (e) {
         return e;
     }
 }

 export const fetchApi = async (url, method, body, token , headers=null) => {
     try {
         const headers = {};
         const result = {
             token: null,
             success: false,
             responseBody: null
         };
         
        //  console.log(token);
     
         
         if(token) {
             
             headers["Authorization"] = token;
            //  console.log(token);
         }
         const response = await api(url, method, body, headers);

         if(response.status == 'success') {
             result.success = true;

             if(response.headers.get("Authorization")) {
                 result.token = response.headers.get("Authorization");
             }

             let responseBody;
             const responseText = await response.text();

             try {
                 responseBody = JSON.parse(responseText);
             } catch (e) {
                 responseBody = responseText;
             }

             result.responseBody = responseBody;
             return result;

         }else{
             let responseBody;
             const responseText = await response.text();
                
             try {     
                responseBody = JSON.parse(responseText);
             } catch (e) {
                responseBody = responseText;
             } 

             result.responseBody = responseBody;
             return result;
         }


     } catch (error) {
         return error;
     }
 }
