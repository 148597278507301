import React , {useState} from 'react';
import { connect } from "react-redux";
import { compose } from "redux";
import { BallBeat } from 'react-pure-loaders';
import { Tabs, Tab, Button,Modal,Form,Row,Col } from 'react-bootstrap';
import Invest from "../assets/img/investor.png";
import bali from "../assets/img/bali.png";
import dompet from "../assets/img/dompet.png";
import seru from "../assets/img/seru.png";
import Api from "../service/Api_home";
import { getUserpemodal } from "../actions/userpemodal.actions";

import "../App.scss";



class Dashboarduser extends React.Component {

    constructor(props) {
        super(props);
        this.props=props;
        this.isLoading=true;
        this.state = {
            items: []
          };
      }

      userGet = async (values) => {
        try {
            const response = await this.props.dispatch(getUserpemodal(values));
           localStorage.DataUser = JSON.stringify(response.responseBody);
            // console.log(localStorage.DataUser);
            if (!response.success) {
                // console.log(response);
                throw response;
            } else {
                this.setState({ errors: null });
            }
        } catch (error) {
            // console.log(error);
        }
    }

    componentDidMount() {
        // fetch("https://1fe344fe.ngrok.io")
           fetch("http://125.213.128.175/")
              .then(res => res.json())
              .then(
              (result) => {
                  this.setState({
                      isLoaded: true,
                      items: result.response
                  });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                  this.setState({
                    isLoaded: true,
                    
                  });
                }
                )
        }

    render() {
        const { items } = this.state;
        console.log(items);
           
            return (
              <div>
                  <Header/>
            
                 <Body/>  
              </div>         
            );


            
        }
        
    }
  
    function Header(){
        
        return(
            <div class="card info ">
            <div class="card-body">
                <div class="row"> 
                    <div class="col-md-6 col-sm-6 ">
                        <div class="kiri d-flex justify-content-center ">
                            <img src={require('../assets/img/Icon_total.svg')} className="#" style={{width:"20%"}} alt=""/>
                            <h6 className="ml-4" style={{color:"#214981",fontSize:"20px",fontWeight:"700"}}>Total Investasi <br/> <span className="text-muted"> RP </span></h6>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6"  style={{borderLeft:"3px solid #70707033"}}>
                        <div class="kanan d-flex justify-content-center ">
                            <img src={require('../assets/img/Icon_total.svg')} style={{width:"20%"}} className="#" alt=""/>
                            <h6 className="ml-4" style={{color:"#214981",fontSize:"20px",fontWeight:"700"}}>Bagi Hasil Diterima <br/> <span className="text-muted"> RP 0</span></h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }


    function Body(){
        const data = JSON.parse(localStorage.DataUser);
       
        return(
         
            <div className="mt-5">
                <Tabs   defaultActiveKey="Informasi" transition={false} id="noanim">
                {/* Informasi */}
                <Tab  eventKey="Informasi" title="Informasi">
                <div className="card p-3">
                    <div class="attention p-4">
                        <div class="info d-flex">
                            <div class="icon"><img src={seru} alt=""/></div>
                                <div class="isi text-muted ml-5">
                                <h5>Informasi</h5>
                                <p className="">Selamat, Data anda berhasil diverifikasi. Sekarang anda sudah bisa melakukan
                                pendanaan proyek atau pengajuan pendanaan. Terima kasih.Selamat, Data anda berhasil
                                diverifikasi. Sekarang anda sudah bisa melakukan pendanaan proyek atau pengajuan
                                pendanaan. Terima kasih.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="card identitas">
                                <div class="card-body">
                                    <h6 id="id">Data Identitas</h6>
                                    <p>Kartu Tanda Penduduk</p>
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <th>NIK</th>
                                                <td></td>
                                                <td></td>

                                            </tr>
                                            <tr>
                                                <th>NAMA</th>
                                                <td colSpan="2" className="text-center">{data.user.full_name}</td>

                                            </tr>
                                            <tr>
                                                <th>TEMPAT TANGGAL LAHIR</th>
                                                <td></td> 
                                                <td></td>

                                            </tr>
                                            <tr>
                                                <th>JENIS KELAMIN</th>
                                                <td colSpan="2" className="text-center">Laki-Laki</td>

                                            </tr>
                                            <tr>
                                                <th>ALAMAT</th>
                                                <td></td>
                                                <td></td>

                                            </tr>
                                            <tr>
                                                <th>FOTO KTP</th>
                                                <td colSpan="2" className="text-center">
                                                <div className="">
                                                        <Update/>
                                                      
                                                 </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>FOTO PEMILIK SERTA KTP</th>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <h6 class="npw">NPWP</h6>
                                    <p id="np">Nomor Pokok Wajib Pajak</p>
                                    <div class="npwp d-flex">
                                        <h6>NPWP</h6>
                                        {/* <p id="angka">12342</p> */}
                                    </div>
                                    <hr/>
                                </div>
                            </div>
                </div>
                </Tab>

                {/* Dompet */}
                <Tab   eventKey="Dompet" title="Dompet">
              <div style={{background:"#ffffff", padding:"20px"}}>
                <div class="card dompet" style={{border:"2px solid #70707033"}}>
                    <div class="card-body">
                        <h5 style={{color:"#214981",fontSize:"20px",fontWeight:"700"}}>Daftar Dompet <br/> <span className="text-muted">Dompet</span></h5>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="img-dompet d-flex ">
                                    <img src={dompet} width="50" alt=""/>
                                    <h6 className="ml-4" style={{color:"#214981",fontSize:"20px",fontWeight:"700"}}>Saldo <br/><span className="text-muted ">Rp 0{data.user.wallet}</span></h6>
                                </div>
                            </div>
                            <div className="col-md-6 text-right"> 
                            <a href="#" class="btn btn-primary"> Lihat Detail</a>
                            </div>
                        </div>      
                        </div>
                    </div>
              </div>
                   
                </Tab>

                {/* Proyek Berjalan */}
                <Tab  eventKey="Proyek Berjalan" title="Proyek Saya">
               <div className="card">
                     <form className="p-4">
                        <div class="row">
                            <div class="col-4">
                            <input type="text" class="form-control" placeholder="Cari Transaksi"/>
                            </div>

                        <div class="col-4">
                            <select className="form-control">
                                <option> Pilih Status </option>
                                <option> Pemilik Modal </option>
                                <option> Pengelola Modal </option>
                            </select>
                        </div>
                        </div>
                    </form>

                    <div className="card m-4 " style={{border:"2px solid #70707033"}}>
                 {/* Api */}
                     </div>

                    <div className="card m-4 " style={{border:"2px solid #70707033"}}>
                        <div className="d-flex m-4">
                            <img src={bali} alt="gambar" />
                            <div className="ml-3">
                                <h3 style={{color:"#214981", fontSize:"20px",fontWeight:"bold"}}> Pesona Prima Cikahuripan Bogor</h3>
                                <h5 className="text-muted" style={{fontSize:"16px",fontWeight:"650"}}>Rp 2.000.000.000</h5>
                               
                            </div>
                        </div>
                       <row className="d-flex ">
                        <div className="col-md-4">
                                <h3 style={{color:"#214981", fontSize:"20px",fontWeight:"bold"}}> No Transaksi</h3>
                                <h5 className="text-muted" style={{fontSize:"16px",fontWeight:"650"}}>1246632964566262</h5>
                                <p> 28 Agustus 2020</p>
                        </div>
                        <div className="col-md-4 text-center">
                                <h3 style={{color:"#214981", fontSize:"20px",fontWeight:"bold"}}> Total</h3>
                                <h5 className="text-muted" style={{fontSize:"16px",fontWeight:"650"}}>Rp 2.000.000.000 x 1 slot</h5>
                                
                        </div>
                        <div className="col-md-4 text-center">
                                <h3 style={{color:"#214981", fontSize:"20px",fontWeight:"bold"}}>Status</h3>
                                <h5 className="text-muted" style={{fontSize:"16px",fontWeight:"650"}}>Belum Selesai</h5>
                               
                        </div>
                       </row>              
                    </div>
             
             
               </div>
                </Tab>

                {/* Favorit */}
                <Tab eventKey="Pavorite" title="Favorite">
               <div className="card">
                    <form className="p-4">
                        <div class="row">
                            <div class="col-4">
                            <input type="text" class="form-control" placeholder="Cari Transaksi"/>
                            </div>

                        <div class="col-4">
                            <select className="form-control">
                                <option> Pilih Status </option>
                                <option> Pemilik Modal </option>
                                <option> Pengelola Modal </option>
                            </select>
                        </div>
                        </div>
                    </form>

                    <form class="was-validated p-4">
                        <div class="custom-control custom-checkbox mb-3">
                            <input type="checkbox" class="custom-control-input" id="customControlValidation1" required/>
                            <label class="custom-control-label text-center" htmlFor="customControlValidation1">Pilih Semua</label>
                            <button type="button" class="btn btn-success ml-5">Success</button>
        
                        </div>
                    </form>

                  <div className="row p-4">
                  <div className="col-md-3">
                         <div className="card p-2">
                            <img src={bali} alt="Gambar"/>
                            <div className="">
                                <h5>Pesona Prima</h5>
                                <h5>Rp 10.000.000</h5>
                                <div className="d-flex">
                                    <p>Selesai</p>
                                    <p style={{marginLeft:"auto"}}>20 November</p>
                                </div>
                            </div>
                         </div>
                      </div>
                      <div className="col-md-3">
                         <div className="card p-2">
                            <img src={bali} alt="Gambar"/>
                            <div className="">
                                <h5>Pesona Prima</h5>
                                <h5>Rp 10.000.000</h5>
                                <div className="d-flex">
                                    <p>Selesai</p>
                                    <p style={{marginLeft:"auto"}}>20 November</p>
                                </div>
                            </div>
                         </div>
                      </div>
                      <div className="col-md-3">
                         <div className="card p-2">
                            <img src={bali} alt="Gambar"/>
                            <div className="">
                                <h5>Pesona Prima</h5>
                                <h5>Rp 10.000.000</h5>
                                <div className="d-flex">
                                    <p>Selesai</p>
                                    <p style={{marginLeft:"auto"}}>20 November</p>
                                </div>
                            </div>
                         </div>
                      </div>
                      <div className="col-md-3 ">
                         <div className="card p-2">
                            <img src={bali} alt="Gambar"/>
                            <div className="">
                                <h5>Pesona Prima</h5>
                                <h5>Rp 10.000.000</h5>
                                <div className="d-flex">
                                    <p>Selesai</p>
                                    <p style={{marginLeft:"auto"}}>20 November</p>
                                </div>
                            </div>
                         </div>
                      </div>
                      <div className="col-md-3 my-3">
                         <div className="card p-2">
                            <img src={bali} alt="Gambar"/>
                            <div className="">
                                <h5>Pesona Prima</h5>
                                <h5>Rp 10.000.000</h5>
                                <div className="d-flex">
                                    <p>Selesai</p>
                                    <p style={{marginLeft:"auto"}}>20 November</p>
                                </div>
                            </div>
                         </div>
                      </div>
                      <div className="col-md-3 my-3">
                         <div className="card p-2">
                            <img src={bali} alt="Gambar"/>
                            <div className="">
                                <h5>Pesona Prima</h5>
                                <h5>Rp 10.000.000</h5>
                                <div className="d-flex">
                                    <p>Selesai</p>
                                    <p style={{marginLeft:"auto"}}>20 November</p>
                                </div>
                            </div>
                         </div>
                      </div>
                      <div className="col-md-3 my-3">
                         <div className="card p-2">
                            <img src={bali} alt="Gambar"/>
                            <div className="">
                                <h5>Pesona Prima</h5>
                                <h5>Rp 10.000.000</h5>
                                <div className="d-flex">
                                    <p>Selesai</p>
                                    <p style={{marginLeft:"auto"}}>20 November</p>
                                </div>
                            </div>
                         </div>
                      </div>
                  </div>
               </div>
                </Tab>

                {/* Proyek Tersimpan */}
                <Tab eventKey="Proyek Tersimpan" title="Proyek Tersimpan">
                <div className="card">
                    <form class="was-validated p-4">
                            <div class="custom-control custom-checkbox mb-3">
                                <input type="checkbox" class="custom-control-input" id="customControlValidation1" required/>
                                <label class="custom-control-label text-center" htmlFor="customControlValidation1">Pilih Semua</label>
                                <button type="button" class="btn btn-success ml-5">Success</button>
            
                            </div>
                    </form>
                    <div className="card m-4">
                        <div className="d-flex p-3">
                            <div className="">
                            <img src={bali} alt="" />
                            <p>Batal Otomatis Setelah 3 hari</p>
                            </div>
                          <div className="">
                          <h5>Pesona indah bogor</h5>
                            <h6>Rp 100.000.00</h6>
                          </div>
                        </div>
                    </div>
                </div>
                </Tab>

                {/* List Proyek */}
                <Tab  eventKey="List Proyek" title="Buat Proyek">
                <div className="card">
                    <form className="p-4">
                        <div class="row">
                            <div class="col-4">
                            <input type="text" class="form-control" placeholder="Cari Transaksi"/>
                            </div>
                            

                            <div class="col-4">
                                <select className="form-control">
                                    <option> Semua Kategori </option>
                                    <option> Bogor </option>
                                    <option> Bandung </option>
                                </select>
                            </div>
                            
                            <div class="col-4">
                                <select className="form-control">
                                    <option> Lokasi </option>
                                    <option> Bogor </option>
                                    <option> Bandung </option>
                                </select>
                            </div>
                        </div>
                    </form>

                    <div className="row p-4">
                        <div className="col-md-">
                      <Api/>
                        </div>
                    </div>
                </div>

                   

                </Tab>
               
             </Tabs>

            </div>
        );

   }

   function Update() {
    const data = JSON.parse(localStorage.DataUser);
  
     
      const [show, setShow] = useState(false);
      
      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);
    
      return (
        <>
          <Button variant="primary" onClick={handleShow}>
          <i class="fa fa-fw fa-user"></i>Upload File 
          </Button>
    
          <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>Upload File <i class="text-muted">(wajib)</i></Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form >
            <Form.Group as={Row} controlId="nama">
              <Form.Label column sm="4">
               Foto Ktp 
              </Form.Label>
              <Col sm="7">
                <Form.Control type="file" />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="nama">
              <Form.Label column sm="4">
               Foto Pribadi 
              </Form.Label>
              <Col sm="8">
                <Form.Control type="file" />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="nama">
              <Form.Label column sm="4">
               Foto Ktp+Pribadi 
              </Form.Label>
              <Col sm="8">
                <Form.Control type="file" />
              </Col>
            </Form.Group>
         </Form>
            </Modal.Body>
            <Modal.Footer>
             
              <Button type="submit" variant="primary" onClick={handleClose}>
                Simpan
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    }
export default Dashboarduser;
