import React from "react";
import {Button} from 'react-bootstrap';


function Error(){
    return(
        <div className="error" style={{textAlign:"center",marginTop:"250px"}}>
            <img  src="https://i.ibb.co/Jr43rXn/kerjasama.png" alt=""/>
            <h1 style={{marginTop:"20px"}}><strong>404 Page</strong></h1>
            <h2>Halaman Tidak Ditemukan</h2>
            <Button style={{marginTop:"20px"}} href="/">Kembali ke Halaman Utama</Button>

        </div>
    );
}

export default Error ;