import React, { memo, useState, useEffect } from 'react';
import "../../../App.scss";
import { connect } from "react-redux";
import { compose } from "redux";
import { BallBeat } from 'react-pure-loaders';
import seru from "../../../assets/img/seru.png";
import seru2 from "../../../assets/img/Group 895.png";
import swal from 'sweetalert';
import axios from 'axios'
import { BASE_URL, tokenGet } from "../../../service/api";

import Navbar_user from "../../../components/navbar_home";

import Footer from "../../../components/Footer";
import { getUserpemodal } from "../../../actions/userpemodal.actions";

import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";
import { Calendar, utils } from "react-modern-calendar-datepicker";
import dateFormat, { masks } from "dateformat";
import {
  Button, Reveal,
  Dropdown, Portal,
  Checkbox,
  Grid,
  Header,
  Icon,
  Image,
  Menu,
  Segment,
  Sidebar,
  Input,
  Label,
  List
} from 'semantic-ui-react'
import { Modal, Form, Alert, Row, Col, Container, Spinner, Tabs, Tab } from 'react-bootstrap';
import $ from 'jquery'
import { CameraFeed } from '../../../components/CameraFeed';
import { Hidden } from '@material-ui/core';

class LengkapiDataPemodal extends React.Component {

  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      c: false,
      userinfo: {},
      isLoad: false,
      page: 0,
      dataBank: [],
      dataCountries: [],
      dataProvince: [],
      dataDistrict: [],
      dataSubDistrict: [],
      // dataCountriesOther: [],
      dataProvinceOther: [],
      dataDistrictOther: [],
      dataSubDistrictOther: [],
      tipePemodal: null,
      tipeClient: null,
    }
  }

  getUserpemodal = async () => {

    var values = { token: this.props.auth.isUserId }

    try {

      const response = await this.props.dispatch(getUserpemodal(values));

      localStorage.DataUser = JSON.stringify(response.responseBody);

      if (!response.responseBody.success) {

        throw response;

      } else if (response.responseBody.success) {

        // this.state.userinfo = JSON.stringify(response.responseBody.user);
        Object.assign(this.state.userinfo, response.responseBody.user)
        this.state.isLoad = true;
        if(response.responseBody.user.is_complete == 5){
          window.location.href = "/Profilepemodal"
          return
        } else {
          var country = this.state.userinfo.country_code == null ? '360' : this.state.userinfo.country_code
          var province = this.state.userinfo.province
          var district = this.state.userinfo.district
          this.getDataProvince(1, country)
          this.getDataDistrict(1, province)
          this.getDataSubDistrict(1, province, district)

          var countryOther = this.state.userinfo.country_code_other
          var provinceOther = this.state.userinfo.province_other
          var districtOther = this.state.userinfo.district_other
          this.getDataProvince(2, countryOther)
          this.getDataDistrict(2, provinceOther)
          this.getDataSubDistrict(2, provinceOther, districtOther)
        }

        if(response.responseBody.user.is_complete > 0){
          var step = parseInt(response.responseBody.user.is_complete)
          this.state.page = step
          // $('.col-step').removeClass('col-step-actived')
          // $('#' + step).addClass('col-step-actived')
        }
      }

    } catch (error) {
      try{
        let errorStatus = error.responseBody.error
        if(errorStatus === 'UNAUTHORIZED_SINGLE_LOGIN_EXPIRED'){
          swal({text: error.responseBody.message, icon: 'warning', closeOnClickOutside: false})
          .then((value) => {
            setTimeout(() => {
              window.location.href = "/Signin"
              localStorage.removeItem('token');
              localStorage.clear();
              this.props.dispatch({
                type: "AUTH_USER_LOGOUT",
              })
            }, 500);
          });
        } else {
          setTimeout(() => {
            window.location.href = "/Signin"
            localStorage.removeItem('token');
            localStorage.clear();
            this.props.dispatch({
              type: "AUTH_USER_LOGOUT",
            })
          }, 500);
        }
      }catch(error){
        swal({ text: 'Error tidak terdefinisi!', icon: 'error' })
      }
    }
  }

  getDataBank() {
    axios.get(BASE_URL + "/allbanks",
      axios.defaults.headers.common['Authorization'] = tokenGet,
      axios.defaults.headers.common['Accept'] = 'application/json'
    )
      .then((resp) => {
        this.setState({
          dataBank: resp.data.data,
        })
      })
      .catch((resp) => {
        this.setState({
          dataBank: [],
        })
      });
  }

  getDataCountries() {
    axios.get(BASE_URL + "/customer/countries", {

    },
      axios.defaults.headers.common['Authorization'] = localStorage.token,
      axios.defaults.headers.common['Accept'] = 'application/json'
    )
      .then((resp) => {
        this.setState({
          dataCountries: resp.data.data,
        })
        // console.log(JSON.stringify(this.state.dataCountries))
      })
      .catch((resp) => {

      });
  }

  getDataProvince = (param, id) => {
    axios.get(BASE_URL + "/customer/province?id=" + id, {

    },
      axios.defaults.headers.common['Authorization'] = localStorage.token,
      axios.defaults.headers.common['Accept'] = 'application/json'
    )
      .then((resp) => {
        if(param == 1){
          this.setState({ dataProvince: resp.data.data })
        }else{
          this.setState({ dataProvinceOther: resp.data.data })
        }
      })
      .catch((resp) => {
        console.log("GET PROVINCE GAGAL >> " + resp)
      });
  }

  getDataDistrict = (param, province_id) => {
    axios.get(BASE_URL + "/customer/district?province_id=" + province_id, {

    },
      axios.defaults.headers.common['Authorization'] = localStorage.token,
      axios.defaults.headers.common['Accept'] = 'application/json'
    )
      .then((resp) => {
        if(param == 1){
          this.setState({ dataDistrict: resp.data.data })
        }else{
          this.setState({ dataDistrictOther: resp.data.data })
        }
      })
      .catch((resp) => {
        console.log("GET DISTRICT GAGAL >> " + resp)
      });
  }

  getDataSubDistrict = (param, province_id, district_id) => {
    axios.get(BASE_URL + "/customer/districtsub?province_id=" + province_id + "&district_id=" + district_id, {

    },
      axios.defaults.headers.common['Authorization'] = localStorage.token,
      axios.defaults.headers.common['Accept'] = 'application/json'
    )
      .then((resp) => {
        if(param == 1){
          this.setState({ dataSubDistrict: resp.data.data })
        }else{
          this.setState({ dataSubDistrictOther: resp.data.data })
        }
      })
      .catch((resp) => {
        console.log("GET SUB DISTRICT GAGAL >> " + resp)
      });
  }

  // lengkapiData = async (values) => {
  //   try {
  //     // dilempar kesini
  //     const response = await BASE_URL("/customer/pemodal/lengkapidata", "POST", values, localStorage.token, 200);
  //     console.log(response);
  //     if (!response.success) {
  //       // alert(response.responseBody.body.message);
  //       swal({ text: response.responseBody.body.message, icon: 'error' })
  //       throw response;
  //     } else {
  //       // alert(response.responseBody.message);
  //       swal({ text: response.responseBody.body.message, icon: 'success' })
  //       const { history } = this.props;
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  clearArrayProvinceEtc = (param) => {
    if(param == 1) {
      this.state.dataProvince = new Array()
      this.state.dataDistrict = new Array()
      this.state.dataSubDistrict = new Array()
    } else {
      this.state.dataProvinceOther = new Array()
      this.state.dataDistrictOther = new Array()
      this.state.dataSubDistrictOther = new Array()
    }
  }

  handleStepClick = (event) => {
    event.preventDefault()
    // $('.col-step').removeClass('col-step-actived')
    // var step = event.target.attributes.getNamedItem('step').value
    // $('#' + step).addClass('col-step-actived')
    // this.setState({ page: step })
  }

  handlePageNext = () => {
    var countPage = 0
    if (this.state.tipePemodal == "perusahaan") {
      countPage = 4
    } else {
      countPage = 3
    }
    if (this.state.page < countPage) {
      var step = parseInt(this.state.page) + 1
      this.setState({ page: step })
      $('.col-step').removeClass('col-step-actived')
      $('#' + step).addClass('col-step-actived')
    }

    window.scrollTo(0, 0)
  };

  handlePagePrev = () => {

    if (this.state.page > 0) {
      var step = parseInt(this.state.page) - 1
      this.setState({ page: step })
      $('.col-step').removeClass('col-step-actived')
      $('#' + step).addClass('col-step-actived')
      window.scrollTo(0, 0)
    }
  };

  setTipePemodal = () => {
    const paramsType = new URLSearchParams(this.props.location.search).get("type")
    const paramsClient = new URLSearchParams(this.props.location.search).get("client")
    var CryptoJS = require("crypto-js");
    var key = process.env.REACT_APP_PRIVATE_KEY

    var bytesType = CryptoJS.AES.decrypt(decodeURIComponent(paramsType), key);
    var type = bytesType.toString(CryptoJS.enc.Utf8);

    var bytesClient = CryptoJS.AES.decrypt(decodeURIComponent(paramsClient), key);
    var client = bytesClient.toString(CryptoJS.enc.Utf8);

    // alert(type +' | '+ client)

    this.setState({ tipePemodal: type })
    this.setState({ tipeClient: client })
  }

  componentDidMount() {
    this.getUserpemodal()
    this.getDataCountries()
    this.getDataBank()
    this.setTipePemodal()
  }

  render() {
    // var content_data;

    // this.setTipePemodal()

    if (!this.state.isLoad) {

      // this.getUserpemodal({ token: this.props.auth.isUserId })

      return (
        <div
          style={{ paddingTop: '300px', Width: 'initial', textAlign: 'center' }}
        >
          <BallBeat
            color={'#123abc'}
            loading
          />
          Loading....</div>
      );
    }
    else {
      //  , marginTop: "60px" 
      return (
        <div style={{ backgroundColor: "#EEEEEE" }}>
          <Navbar_user />

          <Container className="sidebarUser">
            <Row className="justify-content-md-center">
              <Col xs lg="10">
                <Sidebar.Pushable className="mPushable" as={Segment}>
                  <Sidebar.Pusher id="fonts" className="" dimmed={''} style={{ backgroundColor: '#eeeeee' }}>
                    <Segment basic style={{ backgroundColor: 'white' }}>
                      <div className="info d-flex">
                        <div className="icon"><img src={seru2} alt="" /></div>
                        <div className="isi text-muted ml-5">
                          <h5 id="fonts" >Form Isian Kelengkapan Data</h5>
                          <p id="fonts" className="">Silahkan lengkapi data agar dapat melakukan pendanaan.</p>
                        </div>
                      </div>
                    </Segment>

                    <Segment basic style={{ backgroundColor: 'white' }}>
                      {this.state.tipePemodal == 'perusahaan' ?
                        <>
                          <Container style={{ marginBottom: 20 }}>
                            <Row style={{ background: '#FAFAFA', margin: '1px' }}>
                              <Col sm='3' id='0' 
                                className={this.state.page == 0 ? 'col-step col-step-actived' : 'col-step'} 
                                onClick={this.handleStepClick} step='0'>
                                Data Pribadi {this.state.userinfo.is_complete > 0 && <Icon name="check" style={{color: '#28a745'}} step='0'/>}
                              </Col>
                              <Col sm='3' id='1' 
                                className={this.state.page == 1 ? 'col-step col-step-actived' : 'col-step'}
                                onClick={this.handleStepClick} step='1'>
                                Data Perusahaan {this.state.userinfo.is_complete > 1 && <Icon name="check" style={{color: '#28a745'}} step='1'/>} 
                              </Col>
                              <Col sm='3' id='2' 
                                className={this.state.page == 2 ? 'col-step col-step-actived' : 'col-step'}
                                onClick={this.handleStepClick} step='2'>
                                Data Bank {this.state.userinfo.is_complete > 2 && <Icon name="check" style={{color: '#28a745'}} step='2'/>}
                              </Col>
                              <Col sm='3' id='3' 
                                className={this.state.page == 3 ? 'col-step col-step-actived' : 'col-step'}
                                onClick={this.handleStepClick} step='3'>
                                Dokumen {this.state.userinfo.is_complete > 3 && <Icon name="check" style={{color: '#28a745'}} step='3'/>}
                              </Col>
                              {/* <Col sm='2' id='4' 
                                className={this.state.page == 4 ? 'col-step col-step-actived' : 'col-step'}
                                onClick={this.handleStepClick} step='4'>
                                Konfirmasi {this.state.userinfo.is_complete > 4 && <Icon name="check" style={{color: '#28a745'}} step='4'/>}
                              </Col> */}
                            </Row>
                          </Container>
                          {
                            this.state.page == 0 ?
                              <DataPribadi
                                userinfo={this.state.userinfo}
                                tipePemodal={this.state.tipePemodal}
                                tipeClient={this.state.tipeClient}
                                dataCountries={this.state.dataCountries}
                                dataProvince={this.state.dataProvince}
                                dataDistrict={this.state.dataDistrict}
                                dataSubDistrict={this.state.dataSubDistrict}
                                dataProvinceOther={this.state.dataProvinceOther}
                                dataDistrictOther={this.state.dataDistrictOther}
                                dataSubDistrictOther={this.state.dataSubDistrictOther}
                                getDataProvince={this.getDataProvince}
                                getDataDistrict={this.getDataDistrict}
                                getDataSubDistrict={this.getDataSubDistrict}
                                clearArrayProvinceEtc={this.clearArrayProvinceEtc}
                                handlePageNext={this.handlePageNext}
                                handlePagePrev={this.handlePagePrev}
                                getUserpemodal={this.getUserpemodal}
                              />
                              :
                              this.state.page == 1 ?
                                <DataPerusahaan
                                  userinfo={this.state.userinfo}
                                  tipePemodal={this.state.tipePemodal}
                                  handlePageNext={this.handlePageNext}
                                  handlePagePrev={this.handlePagePrev}
                                  getUserpemodal={this.getUserpemodal}
                                />
                                :
                                this.state.page == 2 ?
                                  <DataBank
                                    userinfo={this.state.userinfo}
                                    tipePemodal={this.state.tipePemodal}
                                    dataBank={this.state.dataBank}
                                    handlePageNext={this.handlePageNext}
                                    handlePagePrev={this.handlePagePrev}
                                    getUserpemodal={this.getUserpemodal}
                                  />
                                :
                                this.state.page == 3 ?
                                  <Dokumen
                                    userinfo={this.state.userinfo}
                                    tipePemodal={this.state.tipePemodal}
                                    handlePageNext={this.handlePageNext}
                                    handlePagePrev={this.handlePagePrev}
                                    getUserpemodal={this.getUserpemodal}
                                  />
                                  :
                                  // <Konfirmasi
                                  //   userinfo={this.state.userinfo}
                                  //   tipePemodal={this.state.tipePemodal}
                                  //   handlePageNext={this.handlePageNext}
                                  //   handlePagePrev={this.handlePagePrev}
                                  //   getUserpemodal={this.getUserpemodal}
                                  // />
                                  <></>
                          }
                        </>
                        :
                        <>
                          <Container style={{ marginBottom: 20 }}>
                            <Row style={{ background: '#FAFAFA', margin: '1px' }}>
                              <Col sm='4' id='0' 
                                className={this.state.page == 0 ? 'col-step col-step-actived' : 'col-step'} 
                                onClick={this.handleStepClick} step='0'>
                                Data Pribadi {this.state.userinfo.is_complete > 0 && <Icon name="check" style={{color: '#28a745'}} step='0'/>}
                              </Col>
                              <Col sm='4' id='1' 
                                className={this.state.page == 1 ? 'col-step col-step-actived' : 'col-step'} 
                                onClick={this.handleStepClick} step='1'>
                                Data Bank {this.state.userinfo.is_complete > 1 && <Icon name="check" style={{color: '#28a745'}} step='1'/>}
                              </Col>
                              <Col sm='4' id='2' 
                                className={this.state.page == 2 ? 'col-step col-step-actived' : 'col-step'} 
                                onClick={this.handleStepClick} step='2'>
                                Dokumen {this.state.userinfo.is_complete > 2 && <Icon name="check" style={{color: '#28a745'}} step='2'/>}
                              </Col>
                              {/* <Col sm='3' id='3' 
                                className={this.state.page == 3 ? 'col-step col-step-actived' : 'col-step'}  
                                onClick={this.handleStepClick} step='3'>
                                Konfirmasi {this.state.userinfo.is_complete > 3 && <Icon name="check" style={{color: '#28a745'}} step='3'/>}
                              </Col> */}
                            </Row>
                          </Container>
                          {
                            this.state.page == 0 ?
                              <DataPribadi
                                userinfo={this.state.userinfo}
                                tipePemodal={this.state.tipePemodal}
                                tipeClient={this.state.tipeClient}
                                dataCountries={this.state.dataCountries}
                                dataProvince={this.state.dataProvince}
                                dataDistrict={this.state.dataDistrict}
                                dataSubDistrict={this.state.dataSubDistrict}
                                dataProvinceOther={this.state.dataProvinceOther}
                                dataDistrictOther={this.state.dataDistrictOther}
                                dataSubDistrictOther={this.state.dataSubDistrictOther}
                                getDataProvince={this.getDataProvince}
                                getDataDistrict={this.getDataDistrict}
                                getDataSubDistrict={this.getDataSubDistrict}
                                clearArrayProvinceEtc={this.clearArrayProvinceEtc}
                                handlePageNext={this.handlePageNext}
                                handlePagePrev={this.handlePagePrev}
                                getUserpemodal={this.getUserpemodal}
                              />
                              :
                              this.state.page == 1 ?
                                <DataBank
                                  userinfo={this.state.userinfo}
                                  tipePemodal={this.state.tipePemodal}
                                  dataBank={this.state.dataBank}
                                  handlePageNext={this.handlePageNext}
                                  handlePagePrev={this.handlePagePrev}
                                  getUserpemodal={this.getUserpemodal}
                                />
                                :
                                this.state.page == 2 ?
                                  <Dokumen
                                    userinfo={this.state.userinfo}
                                    tipePemodal={this.state.tipePemodal}
                                    handlePageNext={this.handlePageNext}
                                    handlePagePrev={this.handlePagePrev}
                                    getUserpemodal={this.getUserpemodal}
                                  />
                                  :
                                  // <Konfirmasi
                                  //   userinfo={this.state.userinfo}
                                  //   tipePemodal={this.state.tipePemodal}
                                  //   handlePageNext={this.handlePageNext}
                                  //   handlePagePrev={this.handlePagePrev}
                                  //   getUserpemodal={this.getUserpemodal}
                                  // />
                                  <></>
                          }
                        </>
                      }
                    </Segment>
                  </Sidebar.Pusher>
                </Sidebar.Pushable>
              </Col>
            </Row>
          </Container>
          {/* <Footer /> */}
        </div>
      );
    }
  }
}

const DataPribadi = memo((props) => {

  const [actionForm, setActionForm] = useState(props.userinfo.is_complete > 0 ? 'edit' : 'insert')
  
  const initData = {
    member_code: props.userinfo.member_code == null ? '' : props.userinfo.member_code,
    client_code: props.userinfo.client_code == null ? '' : props.userinfo.client_code,
    tax_code: props.userinfo.tax_code == null ? '' : props.userinfo.tax_code,

    nama_depan: props.userinfo.nama_depan == null ? '' : props.userinfo.nama_depan,
    nama_tengah: props.userinfo.nama_tengah == null ? '' : props.userinfo.nama_tengah,
    nama_belakang: props.userinfo.nama_belakang == null ? '' : props.userinfo.nama_belakang,
    nationality: props.userinfo.nationality == null ? '360' : props.userinfo.nationality, // default >> Indonesia

    jenis_kelamin: props.userinfo.jenis_kelamin == null ? '' : props.userinfo.jenis_kelamin,
    tempat_lahir: props.userinfo.tempat_lahir == null ? '' : props.userinfo.tempat_lahir,
    tanggal_lahir: props.userinfo.tanggal_lahir == null ? '' : props.userinfo.tanggal_lahir,

    status_pernikahan: props.userinfo.status_pernikahan == null ? '' : props.userinfo.status_pernikahan,
    nama_pasangan: props.userinfo.nama_pasangan == null ? '' : props.userinfo.nama_pasangan,

    nama_ibu_kandung: props.userinfo.nama_ibu_kandung == null ? '' : props.userinfo.nama_ibu_kandung,

    nama_ahli_waris: props.userinfo.nama_ahli_waris == null ? '' : props.userinfo.nama_ahli_waris,
    hubungan_ahli_waris: props.userinfo.hubungan_ahli_waris == null ? '' : props.userinfo.hubungan_ahli_waris,
    ahliwaris_evidence: '',

    country: props.userinfo.country_code == null ? '360' : props.userinfo.country_code, // default >> Indonesia
    province: props.userinfo.province == null ? '' : props.userinfo.province,
    district: props.userinfo.district == null ? '' : props.userinfo.district,
    sub_district: props.userinfo.sub_district == null ? '' : props.userinfo.sub_district,
    alamat: props.userinfo.alamat == null ? '' : props.userinfo.alamat,
    alamat2: props.userinfo.alamat2 == null ? '' : props.userinfo.alamat2,
    alamat3: props.userinfo.alamat3 == null ? '' : props.userinfo.alamat3,
    kode_pos: props.userinfo.kode_pos == null ? '' : props.userinfo.kode_pos,
    no_telepon_kode: props.userinfo.no_telepon_kode == null ? '' : props.userinfo.no_telepon_kode,
    no_telepon: props.userinfo.no_telepon == null ? '' : props.userinfo.no_telepon,
    no_hp_kode: props.userinfo.no_hp_kode == null ? '' : props.userinfo.no_hp_kode,
    no_hp: props.userinfo.no_hp == null ? '' : props.userinfo.no_hp,
    email: props.userinfo.email == null ? '' : props.userinfo.email,
    no_fax_kode: props.userinfo.no_fax_kode == null ? '' : props.userinfo.no_fax_kode,
    no_fax: props.userinfo.no_fax == null ? '' : props.userinfo.no_fax,

    //Identitas Other
    country_other: props.userinfo.country_code_other == null ? '' : props.userinfo.country_code_other, 
    province_other: props.userinfo.province_other == null ? '' : props.userinfo.province_other,
    district_other: props.userinfo.district_other == null ? '' : props.userinfo.district_other,
    sub_district_other: props.userinfo.sub_district_other == null ? '' : props.userinfo.sub_district_other,
    alamat_other: props.userinfo.alamat_other == null ? '' : props.userinfo.alamat_other,
    alamat_other2: props.userinfo.alamat_other2 == null ? '' : props.userinfo.alamat_other2,
    alamat_other3: props.userinfo.alamat_other3 == null ? '' : props.userinfo.alamat_other3,
    kode_pos_other: props.userinfo.kode_pos_other == null ? '' : props.userinfo.kode_pos_other,
    no_telepon_kode_other: props.userinfo.no_telepon_kode_other == null ? '' : props.userinfo.no_telepon_kode_other,
    no_telepon_other: props.userinfo.no_telepon_other == null ? '' : props.userinfo.no_telepon_other,
    no_hp_kode_other: props.userinfo.no_hp_kode_other == null ? '' : props.userinfo.no_hp_kode_other,
    no_hp_other: props.userinfo.no_hp_other == null ? '' : props.userinfo.no_hp_other,
    email_other: props.userinfo.email_other == null ? '' : props.userinfo.email_other,
    no_fax_kode_other: props.userinfo.no_fax_kode_other == null ? '' : props.userinfo.no_fax_kode_other,
    no_fax_other: props.userinfo.no_fax_other == null ? '' : props.userinfo.no_fax_other,
    //End identitas other

    pendidikan: props.userinfo.pendidikan == null ? '' : props.userinfo.pendidikan,
    pekerjaan: props.userinfo.pekerjaan == null ? '' : props.userinfo.pekerjaan,
    slip_spt: '',

    deskripsi_pekerjaan: props.userinfo.deskripsi_pekerjaan == null ? '' : props.userinfo.deskripsi_pekerjaan,
    deskripsi_usaha: props.userinfo.deskripsi_usaha == null ? '' : props.userinfo.deskripsi_usaha,
    penghasilan: props.userinfo.penghasilan == null ? '' : props.userinfo.penghasilan,
    sumber_dana: props.userinfo.sumber_dana == null ? '' : props.userinfo.sumber_dana,
    deskripsi_sumber_dana: props.userinfo.deskripsi_sumber_dana == null ? '' : props.userinfo.deskripsi_sumber_dana,
    tujuan_investasi: props.userinfo.tujuan_investasi == null ? '' : props.userinfo.tujuan_investasi,
    sid: props.userinfo.sid == null ? '' : props.userinfo.sid,
    kepemilikan_efek: props.userinfo.kepemilikan_efek == null ? (props.tipeClient == 'direct' ? '1' : '2') : props.userinfo.kepemilikan_efek,
    ktp: props.userinfo.ktp == null ? '' : props.userinfo.ktp,
    ktp_register: props.userinfo.ktp_register == null ? '' : props.userinfo.ktp_register,
    ktp_expired: props.userinfo.ktp_expired == null ? '' : props.userinfo.ktp_expired,
    npwp: props.userinfo.npwp == null ? '' : props.userinfo.npwp,
    npwp_register: props.userinfo.npwp_register == null ? '' : props.userinfo.npwp_register,
    passport: props.userinfo.passport == null ? '' : props.userinfo.passport,
    passport_expired: props.userinfo.passport_expired == null ? '' : props.userinfo.passport_expired,
    kitas: props.userinfo.kitas == null ? '' : props.userinfo.kitas,
    kitas_expired: props.userinfo.kitas_expired == null ? '' : props.userinfo.kitas_expired,

    experience: props.userinfo.experience == null ? '' : props.userinfo.experience,
    experience_evidence: '',

    //Foto Identitas
    foto_selfie: '',
    foto_selfie_ktp: '',
    foto_ktp: '',
    foto_npwp: '',
  }

  const [form, setForm] = useState(initData)

  const sid_old = props.userinfo.sid == null ? '' : props.userinfo.sid

  const [fileSrc, setFileSrc] = useState({
    experience_evidence: props.userinfo.experience_evidence == null ? '' : props.userinfo.experience_evidence,
    ahliwaris_evidence: props.userinfo.ahliwaris_evidence == null ? '' : props.userinfo.ahliwaris_evidence,
    slip_spt: props.userinfo.slip_spt == null ? '' : props.userinfo.slip_spt,
  })

  const dateMin = new Date();
  dateMin.setDate(dateMin.getDate() + 1);
  const minimumDate = {
    year: dateFormat(dateMin, 'yyyy'),
    month: dateFormat(dateMin, 'mm'),
    day: dateFormat(dateMin, 'dd')
  }

  const dateMax = new Date();
  dateMax.setDate(dateMax.getDate() - 1);
  const maximumDate = {
    year: dateFormat(dateMax, 'yyyy'),
    month: dateFormat(dateMax, 'mm'),
    day: dateFormat(dateMax, 'dd')
  }

  const [selectedDayDOB, setSelectedDayDOB] = useState(form.tanggal_lahir == '' ? null : {
    year: parseInt(dateFormat(form.tanggal_lahir, 'yyyy')),
    month: parseInt(dateFormat(form.tanggal_lahir, 'mm')),
    day: parseInt(dateFormat(form.tanggal_lahir, 'dd')),
  });
  const handleChangeDOB = (event) => {
    setSelectedDayDOB(event)

    // var selectDate = event ? `${event.day}/${event.month}/${event.year}` : ''
    var selectDate = event ? `${event.year}-${event.month}-${event.day}` : ''
    setForm({ ...form, ['tanggal_lahir']: selectDate })
    setErrorMessage(errorMessage => ({ ...errorMessage, ['tanggal_lahir']: "" }));
  }
  // render regular HTML input element
  const renderCustomInputDOB = ({ ref }) => (
    <Form.Control
      readOnly
      ref={ref} // necessary
      placeholder={props.tipeClient == 'direct' ? 'Pilih tanggal' : 'Pilih tanggal (Optional)'}
      // value={selectedDay ? `✅ : ${selectedDay.year}` : ''}
      onClick={() => colZindex('col-tanggal-lahir')}
      value={selectedDayDOB ? `${selectedDayDOB.day}/${selectedDayDOB.month}/${selectedDayDOB.year}` : ''}
      disabled={(actionForm == 'insert' || isEdit == true) ? false : true}
    // style={{
    //   textAlign: 'center',
    //   padding: '1rem 1.5rem',
    //   fontSize: '1.5rem',
    //   border: '1px solid #9c88ff',
    //   borderRadius: '100px',
    //   boxShadow: '0 1.5rem 2rem rgba(156, 136, 255, 0.2)',
    //   color: '#9c88ff',
    //   outline: 'none',
    // }}
    // className="my-custom-input-class" // a styling class
    />
  )

  const colZindex = (id) => {
    $('.col-datepicker').removeAttr('style')
    $('#' + id).css('z-index', '999')
  };

  const [selectedDayKTPReg, setSelectedDayKTPReg] = useState(form.ktp_register == '' ? null : {
    year: parseInt(dateFormat(form.ktp_register, 'yyyy')),
    month: parseInt(dateFormat(form.ktp_register, 'mm')),
    day: parseInt(dateFormat(form.ktp_register, 'dd')),
  });
  const renderCustomInputKTPReg = ({ ref }) => (
    <Form.Control
      readOnly
      ref={ref}
      placeholder="Pilih tanggal (Optional)"
      onClick={() => colZindex('col-ktp-register')}
      value={selectedDayKTPReg ? `${selectedDayKTPReg.day}/${selectedDayKTPReg.month}/${selectedDayKTPReg.year}` : ''}
      disabled={(actionForm == 'insert' || isEdit == true) ? false : true}
    />
  )
  const handleChangeKTPReg = (event) => {
    setSelectedDayKTPReg(event)
    if (event !== '') {
      var selectDate = event ? `${event.year}-${event.month}-${event.day}` : ''
      setForm({ ...form, ['ktp_register']: selectDate })
      setErrorMessage(errorMessage => ({ ...errorMessage, ['ktp_register']: "" }));
    }
  }

  // const [selectedDayKTPExpired, setSelectedDayKTPExpired] = 
  // useState((form.ktp_expired == '' || form.ktp_expired == '9998-12-31') ? null : {
  //   year: parseInt(dateFormat(form.ktp_expired, 'yyyy')),
  //   month: parseInt(dateFormat(form.ktp_expired, 'mm')),
  //   day: parseInt(dateFormat(form.ktp_expired, 'dd')),
  // });
  const [selectedDayKTPExpired, setSelectedDayKTPExpired] = useState(null);
  const handleChangeKTPExpired = (event) => {
    setSelectedDayKTPExpired(event)
    if (event !== '') {
      setChecked(false)
      // var selectDate = event ? `${event.day}/${event.month}/${event.year}` : ''
      var selectDate = event ? `${event.year}-${event.month}-${event.day}` : ''
      setForm({ ...form, ['ktp_expired']: selectDate })
      setErrorMessage(errorMessage => ({ ...errorMessage, ['ktp_expired']: "" }));
    }
  }
  const renderCustomInputKTPExpired = ({ ref }) => (
    <Form.Control
      id={'textKTPExpired'}
      readOnly
      ref={ref}
      placeholder="Pilih tanggal"
      onClick={() => colZindex('col-ktp-expired')}
      value={selectedDayKTPExpired ? `${selectedDayKTPExpired.day}/${selectedDayKTPExpired.month}/${selectedDayKTPExpired.year}` : ''}
      disabled={(actionForm == 'insert' || isEdit == true) ? false : true}
    />
  )
  const [checked, setChecked] = useState(form.ktp_expired == '9998-12-31' ? true : false);
  const handleCheck = () => {
    setChecked(!checked);
    if (!checked) {
      setSelectedDayKTPExpired(null)
      setForm({ ...form, ['ktp_expired']: '9998-12-31' })
      setErrorMessage(errorMessage => ({ ...errorMessage, ['ktp_expired']: "" }));
    } else {
      setForm({ ...form, ['ktp_expired']: '' })
      setErrorMessage(errorMessage => ({ ...errorMessage, ['ktp_expired']: "Masukkan tanggal masa berlaku KTP!" }));
    }
  }

  const [checkedClientExperience, setCheckedClientExperience] = useState(form.experience == '1' ? true : false);
  const handleCheckClientExperience = () => {
    setErrorMessage(errorMessage => ({ ...errorMessage, ['experience']: "" }));
    if (!checkedClientExperience) {
      setCheckedClientExperience(true);

      form.experience = '1'

      setCheckedClientNonExperience(false)
    } 
  }
  const [checkedClientNonExperience, setCheckedClientNonExperience] = useState(form.experience == '0' ? true : false);
  const handleCheckClientNonExperience = () => {
    setErrorMessage(errorMessage => ({ ...errorMessage, ['experience']: "" }));
    if (!checkedClientNonExperience) {
      setCheckedClientNonExperience(true)

      form.experience = '0'
      form.experience_evidence = ''

      setCheckedClientExperience(false)
    } 
  }

  const [selectedDayNPWPReg, setSelectedDayNPWPReg] = useState(form.npwp_register == '' ? null : {
    year: parseInt(dateFormat(form.npwp_register, 'yyyy')),
    month: parseInt(dateFormat(form.npwp_register, 'mm')),
    day: parseInt(dateFormat(form.npwp_register, 'dd')),
  });
  const renderCustomInputNPWPReg = ({ ref }) => (
    <Form.Control
      readOnly
      ref={ref}
      placeholder="Pilih tanggal (Optional)"
      onClick={() => colZindex('col-npwp-register')}
      value={selectedDayNPWPReg ? `${selectedDayNPWPReg.day}/${selectedDayNPWPReg.month}/${selectedDayNPWPReg.year}` : ''}
      disabled={(actionForm == 'insert' || isEdit == true) ? false : true}
    />
  )
  const handleChangeNPWPReg = (event) => {
    setSelectedDayNPWPReg(event)
    if (event !== '') {
      // var selectDate = event ? `${event.day}/${event.month}/${event.year}` : ''
      var selectDate = event ? `${event.year}-${event.month}-${event.day}` : ''
      setForm({ ...form, ['npwp_register']: selectDate })
      setErrorMessage(errorMessage => ({ ...errorMessage, ['npwp_register']: "" }));
    }
  }

  const [selectedDayPassportExpired, setSelectedDayPassportExpired] = useState(form.passport_expired == '' ? null : {
    year: parseInt(dateFormat(form.passport_expired, 'yyyy')),
    month: parseInt(dateFormat(form.passport_expired, 'mm')),
    day: parseInt(dateFormat(form.passport_expired, 'dd')),
  });
  const renderCustomInputPassportExpired = ({ ref }) => (
    <Form.Control
      readOnly
      ref={ref}
      placeholder="Pilih tanggal"
      onClick={() => colZindex('col-passport-expired')}
      value={selectedDayPassportExpired ? `${selectedDayPassportExpired.day}/${selectedDayPassportExpired.month}/${selectedDayPassportExpired.year}` : ''}
      disabled={(actionForm == 'insert' || isEdit == true) ? false : true}
    />
  )
  const handleChangePassportExpired = (event) => {
    setSelectedDayPassportExpired(event)
    if (event !== '') {
      // var selectDate = event ? `${event.day}/${event.month}/${event.year}` : ''
      var selectDate = event ? `${event.year}-${event.month}-${event.day}` : ''
      setForm({ ...form, ['passport_expired']: selectDate })
      setErrorMessage(errorMessage => ({ ...errorMessage, ['passport_expired']: "" }));
    }
  }

  const [selectedDayKitasExpired, setSelectedDayKitasExpired] = useState(form.kitas_expired == '' ? null : {
    year: parseInt(dateFormat(form.kitas_expired, 'yyyy')),
    month: parseInt(dateFormat(form.kitas_expired, 'mm')),
    day: parseInt(dateFormat(form.kitas_expired, 'dd')),
  });
  const renderCustomInputKitasExpired = ({ ref }) => (
    <Form.Control
      readOnly
      ref={ref}
      placeholder="Pilih tanggal"
      onClick={() => colZindex('col-kitas-expired')}
      value={selectedDayKitasExpired ? `${selectedDayKitasExpired.day}/${selectedDayKitasExpired.month}/${selectedDayKitasExpired.year}` : ''}
      disabled={(actionForm == 'insert' || isEdit == true) ? false : true}
    />
  )
  const handleChangeKitasExpired = (event) => {
    setSelectedDayKitasExpired(event)
    if (event !== '') {
      // var selectDate = event ? `${event.day}/${event.month}/${event.year}` : ''
      var selectDate = event ? `${event.year}-${event.month}-${event.day}` : ''
      setForm({ ...form, ['kitas_expired']: selectDate })
      setErrorMessage(errorMessage => ({ ...errorMessage, ['kitas_expired']: "" }));
    }
  }

  const jenisKelaminOptions = [
    { key: '1', text: 'Pria', value: '1' },
    { key: '2', text: 'Wanita', value: '2' }
  ]

  const statusPernikahanOptions = [
    { key: '1', text: 'Single', value: '1' },
    { key: '2', text: 'Menikah', value: '2' },
    { key: '3', text: 'Duda', value: '3' },
    { key: '4', text: 'Janda', value: '4' }
  ]

  const pendidikanOptions = [
    { key: '1', text: 'Lainnya', value: '1' },
    { key: '2', text: 'SD', value: '2' },
    { key: '3', text: 'SMP', value: '3' },
    { key: '4', text: 'SMA (termasuk D1 dan D2)', value: '4' },
    { key: '5', text: 'D3/Akademi', value: '5' },
    { key: '6', text: 'S1', value: '6' },
    { key: '7', text: 'S2', value: '7' },
    { key: '8', text: 'S3', value: '8' },
  ]

  const pekerjaanOptions = [
    { key: '1', text: 'Lainnya', value: '1' },
    { key: '2', text: 'Pegawai Swasta', value: '2' },
    { key: '3', text: 'Pegawai Negeri Sipil', value: '3' },
    { key: '4', text: 'Ibu Rumah Tangga', value: '4' },
    { key: '5', text: 'Wiraswasta/Pengusaha', value: '5' },
    { key: '6', text: 'Pelajar', value: '6' },
    { key: '7', text: 'TNI/Polisi', value: '7' },
    { key: '8', text: 'Pensiunan', value: '8' },
    { key: '9', text: 'Guru', value: '9' },
  ]

  const penghasilanOptions__ = [
    { key: '1', text: '< Rp 10 juta', value: '1' }, // maximal buy efek 5%
    { key: '2', text: 'Rp 10 juta - Rp 50 juta', value: '2' }, // maximal buy efek 5%
    { key: '3', text: 'Rp 50 juta - Rp 100 juta', value: '3' }, // maximal buy efek 5%
    { key: '4', text: 'Rp 100 juta - Rp 500 juta', value: '4' }, // maximal buy efek 5%
    { key: '5', text: 'Rp 500 juta - Rp 1 milyar', value: '5' }, // maximal buy efek 10%
    { key: '6', text: '> Rp 1 milyar', value: '6' }, // maximal buy efek 10%
  ]

  const penghasilanOptions = [
    { key: '1', text: 'Rp 10 juta - Rp 50 juta', value: '1' }, // maximal buy efek 5%
    { key: '2', text: 'Rp 51 juta - Rp 100 juta', value: '2' }, // maximal buy efek 5%
    { key: '3', text: 'Rp 101 juta - Rp 150 juta', value: '3' }, // maximal buy efek 5%
    { key: '4', text: 'Rp 151 juta - Rp 200 juta', value: '4' }, // maximal buy efek 5%
    { key: '5', text: 'Rp 201 juta - Rp 250 juta', value: '5' }, // maximal buy efek 5%
    { key: '6', text: 'Rp 251 juta - Rp 300 juta', value: '6' }, // maximal buy efek 5%
    { key: '7', text: 'Rp 301 juta - Rp 350 juta', value: '7' }, // maximal buy efek 5%
    { key: '8', text: 'Rp 351 juta - Rp 400 juta', value: '8' }, // maximal buy efek 5%
    { key: '9', text: 'Rp 401 juta - Rp 450 juta', value: '9' }, // maximal buy efek 5%
    { key: '10', text: 'Rp 451 juta - Rp 500 juta', value: '10' }, // maximal buy efek 5%
    { key: '11', text: '> Rp 500 juta', value: '11' }, // maximal buy efek 10%
  ]

  //value mulai dari 4 agar sesuai format KSEI
  // const penghasilanOptions = [
  //   { key: '4', text: 'Sampai dengan Rp 500 juta', value: '4' }, // maximal buy efek 5%
  //   { key: '5', text: 'Lebih dari Rp 500 juta', value: '5' }, // maximal buy efek 10%
  // ]

  const sumberDanaOptions = [
    { key: '1', text: 'Lain-lain', value: '1' },
    { key: '2', text: 'Gaji', value: '2' },
    { key: '3', text: 'Profit bisnis', value: '3' },
    { key: '4', text: 'Bunga', value: '4' },
    { key: '5', text: 'Warisan', value: '5' },
    { key: '6', text: 'Hibah dari orang tua atau anak', value: '6' },
    { key: '7', text: 'Hibah dari pasangan', value: '7' },
    { key: '8', text: 'Dana pension', value: '8' },
    { key: '9', text: 'Undian', value: '9' },
    { key: '10', text: 'Hasil investasi', value: '10' },
    { key: '11', text: 'Deposito', value: '11' },
    { key: '12', text: 'Modal', value: '12' },
    { key: '13', text: 'Pinjaman', value: '13' },
  ]

  const tujuanInvestasiOptions = [
    { key: '1', text: 'Lain-lain', value: '1' },
    { key: '2', text: 'Price appreciation', value: '2' },
    { key: '3', text: 'Investasi jangka panjang', value: '3' },
    { key: '4', text: 'Spekulasi', value: '4' },
    { key: '5', text: 'Pendapatan', value: '5' },
  ]

  const kepemilikanEfekOptions = [
    { key: '1', text: 'Sendiri', value: '1' },
    { key: '2', text: 'Mewakili pihak lain', value: '2' },
  ]

  // const [countrySelected, setCountrySelected] = useState(null)
  const countryOptions = [
    { key: '360', text: 'Indonesia', value: '360' },
    { key: '840', text: 'United States', value: '840' },
  ]

  const [isDisplay, setIsDisplay] = useState('block')
  const refreshOtherCountries = () => {
    props.clearArrayProvinceEtc(1)
    if (form.country != '360') {
      form.province = 'other'
      form.district = 'other'
      form.sub_district = 'other'
      setIsDisplay('none')
    } else {
      form.province = ''
      form.district = ''
      form.sub_district = ''
      setIsDisplay('block')
    }
  }

  const [isDisplayOther, setIsDisplayOther] = useState('block')
  const refreshOtherCountries_other = () => {
    props.clearArrayProvinceEtc(2)
    if (form.country_other != '360') {
      form.province_other = 'other'
      form.district_other = 'other'
      form.sub_district_other = 'other'
      setIsDisplayOther('none')
    } else {
      form.province_other = ''
      form.district_other = ''
      form.sub_district_other = ''
      setIsDisplayOther('block')
    }
  }

  const [errorMessage, setErrorMessage] = useState({});

  const handleTextChange = event => {

    var index = event.target.name
    setErrorMessage(errorMessage => ({ ...errorMessage, [index]: "" }));

    if((index === 'member_code') && (event.target.value.length > 5)) return
    if((index === 'client_code') && (event.target.value.length > 4)) return
    if((index === 'tax_code') && (event.target.value.length > 4)) return

    if((index === 'nama_depan') && (event.target.value.length > 30)) return
    if((index === 'nama_tengah') && (event.target.value.length > 30)) return
    if((index === 'nama_belakang') && (event.target.value.length > 30)) return
    if((index === 'tempat_lahir') && (event.target.value.length > 100)) return
    if((index === 'nama_ibu_kandung') && (event.target.value.length > 30)) return

    if((index === 'alamat') && (event.target.value.length > 60)) return
    if((index === 'alamat2') && (event.target.value.length > 60)) return
    if((index === 'alamat3') && (event.target.value.length > 60)) return
    if((index === 'alamat_other') && (event.target.value.length > 60)) return
    if((index === 'alamat_other2') && (event.target.value.length > 60)) return
    if((index === 'alamat_other3') && (event.target.value.length > 60)) return

    if((index === 'deskripsi_pekerjaan') && (event.target.value.length > 100)) return
    if((index === 'deskripsi_usaha') && (event.target.value.length > 100)) return
    if((index === 'deskripsi_sumber_dana') && (event.target.value.length > 100)) return
    
    if(index === 'kode_pos' || index === 'kode_pos_other'){
      const re = /^[0-9\b]+$/;
      if (event.target.value != '' && !re.test(event.target.value)) {
        //  this.setState({value: e.target.value})
        return
      }
      if(event.target.value.length > 5){
        return
      }
    }

    if(index === 'no_telepon_kode') {
      setErrorMessage(errorMessage => ({ ...errorMessage, ['no_telepon']: "" }));
    }
    if(index === 'no_telepon_kode_other') {
      setErrorMessage(errorMessage => ({ ...errorMessage, ['no_telepon_other']: "" }));
    }
    if(index === 'no_hp_kode') {
      setErrorMessage(errorMessage => ({ ...errorMessage, ['no_hp']: "" }));
    }
    if(index === 'no_hp_kode_other') {
      setErrorMessage(errorMessage => ({ ...errorMessage, ['no_hp_other']: "" }));
    }

    if(index === 'no_telepon_kode' || index === 'no_hp_kode' || index === 'no_fax_kode'
      || index === 'no_telepon_kode_other' || index === 'no_hp_kode_other' || index === 'no_fax_kode_other'){
      const re = /^[+0-9\b]+$/;
      if (event.target.value != '' && !re.test(event.target.value)) {
        return
      }
      if((event.target.value.length > 1) && (event.target.value.slice(-1) == '+')){
        return
      }
      if(event.target.value.length > 5){
        return
      }
    }

    if(index === 'no_telepon' || index === 'no_hp' || index === 'no_fax'
      || index === 'no_telepon_other' || index === 'no_hp_other' || index === 'no_fax_other'){
      const re = /^[0-9\b]+$/;
      if (event.target.value != '' && !re.test(event.target.value)) {
        return
      }
      if(event.target.value.length > 11){
        return
      }
    }

    if(index === 'sid'){
      if(event.target.value.length > 15){
        return
      }
    }

    if(index === 'ktp'){
      const re = /^[0-9\b]+$/;
      if (event.target.value != '' && !re.test(event.target.value)) {
        return
      }
      if(event.target.value.length > 16){
        return
      }
    }

    if (index === 'npwp') {
      // Exp: 79.275.653.8.422.000
      const re = /^[0-9.\b]+$/;
      if (event.target.value != '' && !re.test(event.target.value)) {
        return
      }
      if(event.target.value.length > 20){
        return
      }
    }

    if (index === 'passport') {
      if (event.target.value.length < 1) {
        setErrorMessage(errorMessage => ({ ...errorMessage, ['passport_expired']: "" }))
        setSelectedDayPassportExpired(null)
      }
      if(event.target.value.length > 20){
        return
      }
    }

    if (index === 'kitas') {
      if (event.target.value.length < 1) {
        setErrorMessage(errorMessage => ({ ...errorMessage, ['kitas_expired']: "" }))
        setSelectedDayKitasExpired(null)
      }
      if(event.target.value.length > 30){
        return
      }
    }

    let resultValue = event.target.value

    if(index === 'client_code') {
      resultValue = event.target.value.toUpperCase()
    } 

    setForm({ ...form, [event.target.name]: resultValue})
  }

  const [loading, setLoading] = useState(false)

  const beforeUnloadListener = (event) => {
    event.preventDefault();
    event.returnValue = "";
    return ""
  }

  const postData = async (values) => {
    window.addEventListener("beforeunload", beforeUnloadListener);

    values.post_to = 'pribadi'
    values.tipe_pemodal = props.tipePemodal
    values.tipe_client = props.tipeClient

    // console.log(JSON.stringify(values))
    // console.log('FILE : ' + values.foto_selfie)
    // return

    var formData = new FormData()
    formData.append('post_to', values.post_to);
    formData.append('data', JSON.stringify(values));
    formData.append('foto_selfie', values.foto_selfie, 'foto_selfie.png')
    formData.append('foto_selfie_ktp', values.foto_selfie_ktp, 'foto_selfie_ktp.png')
    formData.append('foto_ktp', values.foto_ktp, 'foto_ktp.png')
    formData.append('foto_npwp', values.foto_npwp, 'foto_npwp.png')

    // Display the key/value pairs
    // for (var pair of formData.entries()) {
    //   console.log(pair[0]+ ', ' + pair[1]); 
    // }
    // return

    let config = {
      headers: {
        'Authorization': localStorage.token,
        // 'content-type': 'application/json',
        'content-type': 'multipart/form-data',
        'Access-Control-Allow-Credentials': true,
        'Access-Control-Allow-Methods': 'POST',
      },
      maxContentLength: 52428890,
      timeout: 10000000,
    }
    
    setLoading(true)

    // axios.post(BASE_URL + "/customer/pemodal/lengkapidata_v2", values, config)
    axios.post(BASE_URL + "/customer/pemodal/lengkapidata_v2", formData, config)
      .then((resp) => {

        setLoading(false)
        window.removeEventListener("beforeunload", beforeUnloadListener);

        swal({ text: resp.data.body.message, icon: 'success' })
        // console.log('RESPON ' + JSON.stringify(resp.data))

        // var dataRes = resp.data.body.data
        // Object.assign(props.userinfo, dataRes)

        props.getUserpemodal()

        props.handlePageNext()
        window.scrollTo(0, 0)
      })
      .catch(error => {
        
        setLoading(false)
        window.removeEventListener("beforeunload", beforeUnloadListener);

        try {
          let errorStatusCode = error.response.status

          if (errorStatusCode === 401) {
            setTimeout(() => {
              window.location.href = "/Signin"
              localStorage.removeItem('token');
              localStorage.clear();

              this.props.dispatch({
                type: "AUTH_USER_LOGOUT",
              })
            }, 500);
          } else {
            let errorBody = error.response.data.body
            // swal({ text: errorBody.message, icon: 'error' })
            var htmlFormat = document.createElement("div");
            htmlFormat.innerHTML = errorBody.message;
            swal({ content: htmlFormat, icon: "error" })
          }

        } catch (error) {
          swal({ text: 'Error tidak terdefinisi!', icon: 'error' })
        }
      });
  }

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  async function handleSubmit(event) {
    event.preventDefault()

    // postData(form)
    // return

    let errors = {};
    let isValid = true;

    if (form.kitas == '') form.kitas_expired = ''
    if (form.passport == '') form.passport_expired = ''

    if (form.member_code == '') {
      errors['member_code'] = "Masukkan kode member!"
      isValid = false
    }
    if (form.client_code == '') {
      errors['client_code'] = "Masukkan kode klien!"
      isValid = false
    }
    if (form.tax_code == '') {
      errors['tax_code'] = "Masukkan kode tingkat pajak!"
      isValid = false
    }

    if (form.nama_depan == '') {
      errors['nama_depan'] = "Masukkan nama depan!"
      isValid = false
    }
    if (form.nationality == '') {
      errors['nationality'] = "Pilih kewarganegara!"
      isValid = false
    }
    if (form.jenis_kelamin == '') {
      errors['jenis_kelamin'] = "Pilih jenis kelamin!"
      isValid = false
    }
    if (form.tempat_lahir == '') {
      errors['tempat_lahir'] = "Masukkan tempat lahir!"
      isValid = false
    }
    if (form.tanggal_lahir == '') {
      errors['tanggal_lahir'] = "Pilih tanggal lahir!"
      isValid = false
    }
    if (form.status_pernikahan == '') {
      errors['status_pernikahan'] = "Pilih status pernikahan!"
      isValid = false
    }

    if (form.nama_ibu_kandung == '' && props.tipeClient == 'direct') {
      errors['nama_ibu_kandung'] = "Masukkan nama Ibu kandung!"
      isValid = false
    }
    if (form.nama_ibu_kandung != '' && form.nama_ibu_kandung.length < 3) {
      errors['nama_ibu_kandung'] = "Masukkan minimal 3 karakter!"
      isValid = false
    }

    if (form.nama_pasangan != '' && form.nama_pasangan.length < 3) {
      errors['nama_pasangan'] = "Masukkan minimal 3 karakter!"
      isValid = false
    }

    if (form.nama_ahli_waris != '' && form.nama_ahli_waris.length < 3) {
      errors['nama_ahli_waris'] = "Masukkan minimal 3 karakter!"
      isValid = false
    }

    if (form.nama_ahli_waris != '' && form.hubungan_ahli_waris == '') {
      errors['hubungan_ahli_waris'] = "Masukkan hubungan dengan ahli waris!"
      isValid = false
    }

    if (form.nama_ahli_waris != '' && form.ahliwaris_evidence == '') {
      errors['ahliwaris_evidence'] = "Lampirkan bukti hubungan dengan ahli waris!"
      isValid = false
    }

    if(form.ahliwaris_evidence != '' && form.nama_ahli_waris != ''){
      var formField = document.querySelector('form[name="formDataPribadi"]')
      var ahliwarisEvidence = formField.querySelector('input[name="ahliwaris_evidence"]').files[0]
  
      if (ahliwarisEvidence.size > 2048000) {
        errors['ahliwaris_evidence'] = "Maksimal file upload 2 MB!"
        isValid = false
      } else {
        form.ahliwaris_evidence = await toBase64(ahliwarisEvidence)
      }
    } 

    if (form.country == '') {
      errors['country'] = "Pilih Negara!"
      isValid = false
    }
    if (form.province == '') {
      errors['province'] = "Pilih Provinsi!"
      isValid = false
    }
    if (form.district == '') {
      errors['district'] = "Pilih kota atau kabupaten!"
      isValid = false
    }
    if (form.sub_district == '') {
      errors['sub_district'] = "Pilih kecamatan!"
      isValid = false
    }
    if (form.alamat == '') {
      errors['alamat'] = "Masukkan alamat sesuai identitas!"
      isValid = false
    }
    if (form.kode_pos == '') {
      errors['kode_pos'] = "Masukkan kode pos!"
      isValid = false
    }
    if (form.no_telepon_kode == '' && props.tipeClient == 'direct') {
      errors['no_telepon'] = "Masukkan kode area telepon!"
      isValid = false
    }
    if (form.no_telepon == '' && props.tipeClient == 'direct') {
      errors['no_telepon'] = "Masukkan nomor telepon!"
      isValid = false
    }
    if (form.no_telepon_kode == '' && form.no_telepon != '') {
      errors['no_telepon'] = "Masukkan kode area telepon!"
      isValid = false
    }
    if (form.no_telepon_kode != '' && form.no_telepon == '') {
      errors['no_telepon'] = "Masukkan nomor telepon!"
      isValid = false
    }
    if (form.no_telepon_kode_other == '' && form.no_telepon_other != '') {
      errors['no_telepon_other'] = "Masukkan kode area telepon!"
      isValid = false
    }
    if (form.no_telepon_kode_other != '' && form.no_telepon_other == '') {
      errors['no_telepon_other'] = "Masukkan nomor telepon!"
      isValid = false
    }

    if (form.no_hp_kode == '') {
      errors['no_hp'] = "Masukkan kode area handphone!"
      isValid = false
    }
    if (form.no_hp == '') {
      errors['no_hp'] = "Masukkan nomor handphone!"
      isValid = false
    }
    if (form.no_hp_kode_other == '' && form.no_hp_other != '') {
      errors['no_hp_other'] = "Masukkan kode area handphone!"
      isValid = false
    }
    if (form.no_hp_kode_other != '' && form.no_hp_other == '') {
      errors['no_hp_other'] = "Masukkan nomor handphone!"
      isValid = false
    }

    if (form.email == '') {
      errors['email'] = "Masukkan email!"
      isValid = false
    } else {
      const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!re.test(form.email)) {
        errors['email'] = "Masukkan email dengan benar!"
        isValid = false
      }
    }

    if (form.email_other != '') {
      const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!re.test(form.email_other)) {
        errors['email_other'] = "Masukkan email dengan benar!"
        isValid = false
      }
    }

    if (form.pendidikan == '') {
      errors['pendidikan'] = "Pilih pendidikan terakhir!"
      isValid = false
    }
    if (form.pekerjaan == '') {
      errors['pekerjaan'] = "Pilih jenis pekerjaan!"
      isValid = false
    } else {
      if(form.slip_spt == ''){
        if(form.pekerjaan == '2' || form.pekerjaan == '3' || form.pekerjaan == '7' || form.pekerjaan == '9'){
          errors['slip_spt'] = "Lampirkan Slip Gaji!"
        } else {
          errors['slip_spt'] = "Lampirkan SPT tahun sebelumnya!"
        }
        isValid = false
      } else {
        var formField = document.querySelector('form[name="formDataPribadi"]')
        var slipSpt = formField.querySelector('input[name="slip_spt"]').files[0]
    
        if (slipSpt.size > 2048000) {
          errors['slip_spt'] = "Maksimal file upload 2 MB!"
          isValid = false
        } else {
          form.slip_spt = await toBase64(slipSpt)
        }
      }
    }
    if (form.pekerjaan == 1 && form.deskripsi_pekerjaan == '') {
      errors['deskripsi_pekerjaan'] = "Masukkan keterangan jenis pekerjaan!"
      isValid = false
    }
    if (form.pekerjaan == 5 && form.deskripsi_usaha == '') {
      errors['deskripsi_usaha'] = "Masukkan keterangan jenis usaha!"
      isValid = false
    }
    if (form.penghasilan == '') {
      errors['penghasilan'] = "Pilih penghasilan!"
      isValid = false
    }
    if (form.sumber_dana == '') {
      errors['sumber_dana'] = "Pilih sumber dana!"
      isValid = false
    }
    if (form.sumber_dana == 1 && form.deskripsi_sumber_dana == '') {
      errors['deskripsi_sumber_dana'] = "Masukkan keterangan sumber dana!"
      isValid = false
    }
    if (form.tujuan_investasi == '') {
      errors['tujuan_investasi'] = "Pilih tujuan investasi!"
      isValid = false
    }
    if (form.sid == '' && form.experience == '1') {
      errors['sid'] = "Masukkan nomor SID!"
      isValid = false
    }
    if(props.tipePemodal == 'pribadi'){
      if (!checkedClientExperience && !checkedClientNonExperience) {
        errors['experience'] = "Silahkan ceklist apakah anda berpengalaman atau tidak!"
        isValid = false
      }
    }
    if(actionForm == 'insert'){
      if (form.experience_evidence == '' && form.experience == '1') {
        errors['experience_evidence'] = "Lampirkan bukti kepemilikan rekening efek!"
        isValid = false
      }
    } else {
      if(sid_old != form.sid) {
        if (form.experience_evidence == '' && form.experience == '1') {
          errors['experience_evidence'] = "Lampirkan bukti kepemilikan rekening efek!"
          isValid = false
        }
      }
    }
    if(form.experience_evidence != '' && form.experience == '1'){
      var formField = document.querySelector('form[name="formDataPribadi"]')
      var experienceEvidence = formField.querySelector('input[name="experience_evidence"]').files[0]
  
      if (experienceEvidence.size > 2048000) {
        errors['experience_evidence'] = "Maksimal file upload 2 MB!"
        isValid = false
      } else {
        form.experience_evidence = await toBase64(experienceEvidence)
      }
    } 
    if (form.kepemilikan_efek == '') {
      errors['kepemilikan_efek'] = "Pilih kepemilikan efek!"
      isValid = false
    }
    if (form.ktp == '') {
      errors['ktp'] = "Masukkan nomor KTP!"
      isValid = false
    }
    if (form.ktp_expired == '') {
      errors['ktp_expired'] = "Masukkan tanggal masa berlaku KTP!"
      isValid = false
    }
    if (form.npwp == '') {
      errors['npwp'] = "Masukkan nomor NPWP!"
      isValid = false
    }
    // if (form.npwp_register == '') {
    //   errors['npwp_register'] = "Masukkan tanggal registrasi NPWP!"
    //   isValid = false
    // }
    if (form.country != '') {
      if (form.country != '360' && form.passport == '') {
        errors['passport'] = "Masukkan nomor Passport!"
        isValid = false
      }
      if ((form.passport != '' || form.country != '360') && form.passport_expired == '') {
        errors['passport_expired'] = "Masukkan tanggal masa berlaku Passport!"
        isValid = false
      }
    }
    // if (form.kitas == '') {
    //   errors['kitas'] = "Masukkan nomor KITAS/SKD!"
    //   isValid = false
    // }
    if (form.kitas != '' && form.kitas_expired == '') {
      errors['kitas_expired'] = "Masukkan tanggal masa berlaku KITAS/SKD!"
      isValid = false
    }

    /*********
     * FILE DAN FOTO
     */
    if (form.foto_selfie == '') {
      errors['foto_selfie'] = "Sertakan Foto Selfie!"
      isValid = false
    } else {
      
      if (form.foto_selfie.size > 1024000) { // Max 1024000
        errors['foto_selfie'] = "Maksimal file upload 1 MB!"
        isValid = false
      }
    }

    if (form.foto_selfie_ktp == '') {
      errors['foto_selfie_ktp'] = "Sertakan Foto Selfie dengan KTP!"
      isValid = false
    } else {
      
      if (form.foto_selfie_ktp.size > 1024000) { // Max 1024000
        errors['foto_selfie_ktp'] = "Maksimal file upload 1 MB!"
        isValid = false
      }
    }

    if (form.foto_ktp == '') {
      errors['foto_ktp'] = "Sertakan Foto KTP!"
      isValid = false
    } else {
      if (form.foto_ktp.size > 1024000) { // Max 1024000
        errors['foto_ktp'] = "Maksimal file upload 1 MB!"
        isValid = false
      }
    }

    if (form.foto_npwp == '') {
      errors['foto_npwp'] = "Sertakan Foto NPWP!"
      isValid = false
    } else {
      if (form.foto_npwp.size > 1024000) { // Max 1024000
        errors['foto_npwp'] = "Maksimal file upload 1 MB!"
        isValid = false
      }
    }

    setErrorMessage(errorMessage => ({
      ...errorMessage,
      ...errors
    }));

    if (isValid) {
      postData(form)
    } else {
      swal({ text: 'Periksa kembali data yang anda masukkan!', icon: 'error' })
    }
  }

  const onRefresh = () => {
    setErrorMessage({});
    setForm(initData)

    setSelectedDayDOB(initData.tanggal_lahir == '' ? null : {
      year: parseInt(dateFormat(initData.tanggal_lahir, 'yyyy')),
      month: parseInt(dateFormat(initData.tanggal_lahir, 'mm')),
      day: parseInt(dateFormat(initData.tanggal_lahir, 'dd')),
    });

    setSelectedDayKTPExpired(initData.ktp_expired == '' ? null : initData.ktp_expired == '9998-12-31' ? null : {
      year: parseInt(dateFormat(initData.ktp_expired, 'yyyy')),
      month: parseInt(dateFormat(initData.ktp_expired, 'mm')),
      day: parseInt(dateFormat(initData.ktp_expired, 'dd')),
    });
    setChecked(initData.ktp_expired == '9998-12-31' ? true : false);

    setSelectedDayNPWPReg(initData.npwp_register == '' ? null : {
      year: parseInt(dateFormat(initData.npwp_register, 'yyyy')),
      month: parseInt(dateFormat(initData.npwp_register, 'mm')),
      day: parseInt(dateFormat(initData.npwp_register, 'dd')),
    });

    setSelectedDayPassportExpired(initData.passport_expired == '' ? null : {
      year: parseInt(dateFormat(initData.passport_expired, 'yyyy')),
      month: parseInt(dateFormat(initData.passport_expired, 'mm')),
      day: parseInt(dateFormat(initData.passport_expired, 'dd')),
    });

    setSelectedDayKitasExpired(initData.kitas_expired == '' ? null : {
      year: parseInt(dateFormat(initData.kitas_expired, 'yyyy')),
      month: parseInt(dateFormat(initData.kitas_expired, 'mm')),
      day: parseInt(dateFormat(initData.kitas_expired, 'dd')),
    });
  }

  const [isEdit, setIsEdit] = useState(false)
  const handleEdit = () => {
    setIsEdit(true)
  }
  const handleCancelEdit = () => {
    setIsEdit(false)

    onRefresh()
  }

  const [typeFile, setTypeFile] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [showEvidence, setShowEvidence] = useState('')
  const handleShowModal = (param) => {
    let indexOf = fileSrc.experience_evidence.indexOf('.pdf')

    if(param == 'ahliwaris_evidence') {
      indexOf = fileSrc.ahliwaris_evidence.indexOf('.pdf')
    }
    if(param == 'slip_spt') {
      indexOf = fileSrc.slip_spt.indexOf('.pdf')
    }
    if(indexOf > -1){
      setTypeFile('pdf')
    } else {
      setTypeFile('image')
    }

    setShowEvidence(param)
    setShowModal(true)
  }
  const handleCloseModal = () => {
    setShowModal(false)
  }

  const [showModalUploadFoto, setShowModalUploadFoto] = useState(false)
  const handleShowModalUploadFoto = (param) => {
    setShowModalUploadFoto(true)
    setCaptureTo(param)
  }
  const handleCloseModalUploadFoto = () => {
    setShowModalUploadFoto(false)
    setCaptureTo('')
  }

  // Upload to local seaweedFS instance
  const [captureTo, setCaptureTo] = useState('')
  const [isCaptureCamera, setIsCaptureCamera] = useState(false)
  const [fotoCaptureResult, setFotoCaptureResult] = useState('')
  const uploadImage = async files => {
    
    if(files != 'repeat') {
      // setIsCaptureCamera(true)
      // form.foto_selfie = files
      setFotoCaptureResult(files)

    } else {
      // setIsCaptureCamera(false)
      // form.foto_selfie = ''
      setFotoCaptureResult('')
    }

    setErrorMessage({});

    // Connect to a seaweedfs instance
  };

  const handleSetFotoCapture = () => {
    
    if (fotoCaptureResult) {
      if(captureTo == 'foto_selfie'){

        form.foto_selfie = fotoCaptureResult

        var filename = 'Foto Selfie'
        var reader = new FileReader();
        reader.onload = function(e) {
          $('#upload-foto-selfie .image-upload-wrap').hide();
          $('#upload-foto-selfie .file-upload-image').attr('src', e.target.result);
          $('#upload-foto-selfie .file-upload-content').show();
          $('#upload-foto-selfie .image-title').html(filename);
        };
        reader.readAsDataURL(form.foto_selfie);
      } 

      if(captureTo == 'foto_selfie_ktp'){

        form.foto_selfie_ktp = fotoCaptureResult

        var filename = 'Foto Selfie KTP'
        var reader = new FileReader();
        reader.onload = function(e) {
          $('#upload-foto-selfie-ktp .image-upload-wrap').hide();
          $('#upload-foto-selfie-ktp .file-upload-image').attr('src', e.target.result);
          $('#upload-foto-selfie-ktp .file-upload-content').show();
          $('#upload-foto-selfie-ktp .image-title').html(filename);
        };
        reader.readAsDataURL(form.foto_selfie_ktp);
      } 

      if(captureTo == 'foto_ktp'){

        form.foto_ktp = fotoCaptureResult

        var filename = 'Foto KTP'
        var reader = new FileReader();
        reader.onload = function(e) {
          $('#upload-foto-ktp .image-upload-wrap').hide();
          $('#upload-foto-ktp .file-upload-image').attr('src', e.target.result);
          $('#upload-foto-ktp .file-upload-content').show();
          $('#upload-foto-ktp .image-title').html(filename);
        };
        reader.readAsDataURL(form.foto_ktp);
      } 

      if(captureTo == 'foto_npwp'){

        form.foto_npwp = fotoCaptureResult

        var filename = 'Foto NPWP'
        var reader = new FileReader();
        reader.onload = function(e) {
          $('#upload-foto-npwp .image-upload-wrap').hide();
          $('#upload-foto-npwp .file-upload-image').attr('src', e.target.result);
          $('#upload-foto-npwp .file-upload-content').show();
          $('#upload-foto-npwp .image-title').html(filename);
        };
        reader.readAsDataURL(form.foto_npwp);
      } 
    }

    setShowModalUploadFoto(false)
    setCaptureTo('')

    // console.log('FOTO : ' + form.foto_selfie)
  }

  const handleRemoveFoto = (param) => {
    if(param == 'foto_selfie') {
      form.foto_selfie = ''
      $('#upload-foto-selfie .file-upload-input').val('');
      $('#upload-foto-selfie .file-upload-content').hide();
      $('#upload-foto-selfie .image-upload-wrap').show();
    }
    if(param == 'foto_selfie_ktp') {
      form.foto_selfie_ktp = ''
      $('#upload-foto-selfie-ktp .file-upload-input').val('');
      $('#upload-foto-selfie-ktp .file-upload-content').hide();
      $('#upload-foto-selfie-ktp .image-upload-wrap').show();
    }
    if(param == 'foto_ktp') {
      form.foto_ktp = ''
      $('#upload-foto-ktp .file-upload-input').val('');
      $('#upload-foto-ktp .file-upload-content').hide();
      $('#upload-foto-ktp .image-upload-wrap').show();
    }
    if(param == 'foto_npwp') {
      form.foto_npwp = ''
      $('#upload-foto-npwp .file-upload-input').val('');
      $('#upload-foto-npwp .file-upload-content').hide();
      $('#upload-foto-npwp .image-upload-wrap').show();
    }
  }

  const [isOtherAlamat, setIsOtherAlamat] = useState(false)
  const handleClickOtherAlamat = () => {
    if(!isOtherAlamat){
      setIsOtherAlamat(true)
    }else{
      setIsOtherAlamat(false)
    }
  }

  const [isOtherContact, setIsOtherContact] = useState(false)
  const handleClickOtherContact = () => {
    if(!isOtherContact){
      setIsOtherContact(true)
    }else{
      setIsOtherContact(false)
    }
  }

  const [isWorkDescription, setIsWorkDescription] = useState({
    openWorkOtherDesc: false,
    openBusinessDesc: false
  })

  const [isFundResourceDescription, setIsFundResourceDescription] = useState(false)

  const OpenOtherAlamat = () => (
    <>
      <Form.Group as={Row}>
        <Col sm="4" style={{ color: 'gray' }}>
          Alamat Lainnya<br/>
          <span style={{ fontSize: '11px' }}>(Alamat sesuai domisili)</span>
          {/* <span style={{ color: 'red' }}>*</span> */}
        </Col>
        <Col sm="8">
          <Form.Control type="text" name="alamat_other" value={form.alamat_other} onChange={handleTextChange} placeholder='Masukkan nama jalan, nama pemukiman, dan nomor rumah' disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
          <br/>
          <Form.Control type="text" name="alamat_other2" value={form.alamat_other2} onChange={handleTextChange} placeholder='Masukkan RT/RW' disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
          <br/>
          <Form.Control type="text" name="alamat_other3" value={form.alamat_other3} onChange={handleTextChange} placeholder='Masukkan nama desa/kelurahan' disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
          <span style={{ color: "red", fontSize: 11 }}>{errorMessage["alamat_other"]}</span>
        </Col>
      </Form.Group>
      
      { form.nationality == '360' &&
        <>
          <div style={{ display: isDisplayOther }}>
            <Form.Group as={Row}>
              <Col sm="4" style={{ color: 'gray' }}>Provinsi Lainnya</Col>
              <Col sm="8">
                <Dropdown
                  name="province_other"
                  onChange={(e, { value }) => {
                    form.province_other = value
                    setErrorMessage(errorMessage => ({ ...errorMessage, ['province_other']: "" }))
                    props.getDataDistrict(2, form.province_other)
                  }}
                  placeholder='Provinsi' style={{ width: '100%' }}
                  closeOnChange search selection
                  value={form.province_other}
                  options={props.dataProvinceOther.map(item => ({ key: item.province_id, text: item.province_name, value: item.province_id }))}
                  disabled={(actionForm == 'insert' || isEdit == true) ? false : true}
                />
                <span style={{ color: "red", fontSize: 11 }}>{errorMessage["province_other"]}</span>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Col sm="4" style={{ color: 'gray' }}>Kota/Kabupaten Lainnya</Col>
              <Col sm="8" id='col-district'>
                <Dropdown
                  name="district_other"
                  onChange={(e, { value }) => {
                    form.district_other = value
                    setErrorMessage(errorMessage => ({ ...errorMessage, ['district_other']: "" }))
                    props.getDataSubDistrict(2, form.province_other, form.district_other)
                  }}
                  placeholder='Kota/Kabupaten' style={{ width: '100%' }}
                  closeOnChange search selection
                  value={form.district_other}
                  options={props.dataDistrictOther.map(item => ({ key: item.district_id, text: item.district_name, value: item.district_id }))}
                  onOpen={() => $('#col-district').css('z-index', '999')}
                  onClose={() => $('#col-district').removeAttr('style')}
                  disabled={(actionForm == 'insert' || isEdit == true) ? false : true}
                />
                <span style={{ color: "red", fontSize: 11 }}>{errorMessage["district_other"]}</span>
              </Col>
            </Form.Group>
            {/* sub_district wajib ada, untuk mendukung digital signature */}
            <Form.Group as={Row}>
              <Col sm="4" style={{ color: 'gray' }}>Kecamatan Lainnya</Col>
              <Col sm="8" id='col-sub-district'>
                <Dropdown
                  name="sub_district_other"
                  onChange={(e, { value }) => {
                    form.sub_district_other = value
                    setErrorMessage(errorMessage => ({ ...errorMessage, ['sub_district_other']: "" }))
                  }}
                  placeholder='Kecamatan' style={{ width: '100%' }}
                  closeOnChange search selection
                  value={form.sub_district_other}
                  // options={options} 
                  options={props.dataSubDistrictOther.map(item => ({ key: item.sub_district_id, text: item.sub_district_name, value: item.sub_district_id }))}
                  onOpen={() => $('#col-sub-district').css('z-index', '999')}
                  onClose={() => $('#col-sub-district').removeAttr('style')}
                  disabled={(actionForm == 'insert' || isEdit == true) ? false : true}
                />
                <span style={{ color: "red", fontSize: 11 }}>{errorMessage["sub_district_other"]}</span>
              </Col>
            </Form.Group>
          </div>

          <Form.Group as={Row}>
            <Col sm="4" style={{ color: 'gray' }}>Kode Pos Lainnya</Col>
            <Col sm="8">
              <Form.Control type="text" name="kode_pos_other" value={form.kode_pos_other} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
              <span style={{ color: "red", fontSize: 11 }}>{errorMessage["kode_pos_other"]}</span>
            </Col>
          </Form.Group>
        </>
      }

      <Form.Group as={Row}>
        <Col sm="4" style={{ color: 'gray' }}>Negara Lainnya</Col>
        <Col sm="8" id='col-country'>
          <Dropdown
            name="country_other"
            onChange={(e, { value }) => {
              form.country_other = value
              setErrorMessage(errorMessage => ({ ...errorMessage, ['country_other']: "" }))
              refreshOtherCountries_other()
              props.getDataProvince(2, form.country_other)
              if (form.country_other == '360') {
                form.no_telepon_kode_other = '+62'
                form.no_hp_kode_other = '+62'
              } else {
                form.no_telepon_kode_other = ''
                form.no_hp_kode_other = ''
              }
            }}
            placeholder='Negara' style={{ width: '100%' }}
            closeOnChange search selection
            value={form.country_other}
            options={props.dataCountries.map(item => ({ key: item.country_id, text: item.country_name, value: item.country_id }))}
            onOpen={() => $('#col-country').css('z-index', '999')}
            onClose={() => $('#col-country').removeAttr('style')}
            disabled={(actionForm == 'insert' || isEdit == true) ? false : true}
          />
          <span style={{ color: "red", fontSize: 11 }}>{errorMessage["country_other"]}</span>
        </Col>
      </Form.Group>
    </>
  )

  const OpenOtherContact = () => (
    <>
      <Form.Group as={Row}>
        <Col sm="4" style={{ color: 'gray' }}>
          Nomor Telepon Lainnya<br />
          <span style={{ fontSize: '11px' }}>(Contoh: +62 211234123)</span>
        </Col>
        <Col sm="8">
          <Form.Group as={Row}>
            <Col sm="2">
              <Form.Control type="text" name="no_telepon_kode_other" value={form.no_telepon_kode_other} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
            </Col>
            <Col sm="10">
              <Form.Control type="text" name="no_telepon_other" value={form.no_telepon_other} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
            </Col>
          </Form.Group>
          <span style={{ color: "red", fontSize: 11 }}>{errorMessage["no_telepon_other"]}</span>
        </Col>
      </Form.Group>
      
      <Form.Group as={Row}>
        <Col sm="4" style={{ color: 'gray' }}>
          Nomor Handphone Lainnya<br />
          <span style={{ fontSize: '11px' }}>(Contoh: +62 82112341234)</span>
        </Col>
        <Col sm="8">
          <Form.Group as={Row}>
            <Col sm="2">
              <Form.Control type="text" name="no_hp_kode_other" value={form.no_hp_kode_other} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
            </Col>
            <Col sm="10">
              <Form.Control type="text" name="no_hp_other" value={form.no_hp_other} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
            </Col>
          </Form.Group>
          <span style={{ color: "red", fontSize: 11 }}>{errorMessage["no_hp_other"]}</span>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col sm="4" style={{ color: 'gray' }}>Email Lainnya</Col>
        <Col sm="8">
          <Form.Control type="text" name="email_other" value={form.email_other} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
          <span style={{ color: "red", fontSize: 11 }}>{errorMessage["email_other"]}</span>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Col sm="4" style={{ color: 'gray' }}>
          Fax. Lainnya<br/>
          <span style={{ fontSize: '11px' }}>(Contoh: +62 211234123)</span>
        </Col>
        <Col sm="8">
          <Form.Group as={Row}>
            <Col sm="2">
              <Form.Control type="text" name="no_fax_other" value={form.no_fax_other} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
            </Col>
            <Col sm="10">
              <Form.Control type="text" name="no_fax_other" value={form.no_fax_other} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
            </Col>
          </Form.Group>
          <span style={{ color: "red", fontSize: 11 }}>{errorMessage["no_fax_other"]}</span>
        </Col>
      </Form.Group>
    </>
  )
  
  return (
    <>
      <Container style={{ display: 'block' }}>
        <Form name='formDataPribadi' onSubmit={e => e.preventDefault()} encType="multipart/form-data" autoComplete='off'>
          <Row>
            <Col md={12}>
              {/* <Header id="fonts" as='h6' color="blue" size='small'>
                <Header.Content>
                  DATA PRIBADI
                </Header.Content>
              </Header> */}

              <Form.Group as={Row}>
                <Col sm="12">
                  <div className='form-title-lengkapi-data-background'># DATA PRIBADI</div>
                </Col>
              </Form.Group>

              {/* {props.tipePemodal == 'pribadi' ?
                <Alert id="fonts" className="alertInfo" variant="warning" show={true} style={{ display: 'block' }}>
                  <p><strong>CATATAN!</strong></p>
                  <p>Setiap Pemodal dengan penghasilan sampai dengan
                    Rp500.000.000,00 (lima ratus juta rupiah) per
                    tahun, dapat membeli Efek melalui Layanan Urun
                    Dana paling banyak sebesar 5% (lima persen) dari
                    penghasilan per tahun; dan</p>
                  <p>Setiap Pemodal dengan penghasilan lebih dari
                    Rp500.000.000,00 (lima ratus juta rupiah) per
                    tahun, dapat membeli Efek melalui Layanan Urun
                    Dana paling banyak sebesar 10% (sepuluh persen)
                    dari penghasilan per tahun.</p>
                </Alert>
                : <></>
              } */}

              {props.tipePemodal == 'perusahaan' ?
                <Alert id="fonts" className="alertInfo" variant="warning" show={true} style={{ display: 'block' }}>
                  <p><strong>CATATAN!</strong></p>
                  <p>Silahkan isi dengan data pribadi Direksi.</p>
                </Alert>
                : <></>
              }

              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>Kode Member
                {/* <span style={{ color: 'red' }}>*</span> */}
                </Col>
                <Col sm="8">
                  <Form.Control type="text" name="member_code" value={form.member_code} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                  <span style={{ color: "red", fontSize: 11 }}>{errorMessage["member_code"]}</span>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>Kode Client
                {/* <span style={{ color: 'red' }}>*</span> */}
                </Col>
                <Col sm="8">
                  <Form.Control type="text" name="client_code" value={form.client_code} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                  <span style={{ color: "red", fontSize: 11 }}>{errorMessage["client_code"]}</span>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>Kode Tingkat Pajak Investor
                {/* <span style={{ color: 'red' }}>*</span> */}
                </Col>
                <Col sm="8">
                  <Form.Control type="text" name="tax_code" value={form.tax_code} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                  <span style={{ color: "red", fontSize: 11 }}>{errorMessage["tax_code"]}</span>
                </Col>
              </Form.Group>
              
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>Nama Depan
                {/* <span style={{ color: 'red' }}>*</span> */}
                </Col>
                <Col sm="8">
                  <Form.Control type="text" name="nama_depan" value={form.nama_depan} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                  <span style={{ color: "red", fontSize: 11 }}>{errorMessage["nama_depan"]}</span>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>Nama Tengah</Col>
                <Col sm="8"><Form.Control placeholder='Nama Tengah (Optional)' type="text" name="nama_tengah" value={form.nama_tengah} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/></Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>Nama Belakang</Col>
                <Col sm="8"><Form.Control placeholder='Nama Belakang (Optional)' type="text" name="nama_belakang" value={form.nama_belakang} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/></Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>Warga Negara{/* <span style={{ color: 'red' }}>*</span> */}</Col>
                <Col sm="8" id='col-country'>
                  <Dropdown
                    name="nationality"
                    onChange={(e, { value }) => {
                      form.nationality = value
                      setErrorMessage(errorMessage => ({ ...errorMessage, ['nationality']: "" }))
                    }}
                    placeholder='Warga Negara' style={{ width: '100%' }}
                    closeOnChange search selection
                    value={form.nationality}
                    options={props.dataCountries.map(item => ({ key: item.country_id, text: item.country_name, value: item.country_id }))}
                    onOpen={() => $('#col-country').css('z-index', '999')}
                    onClose={() => $('#col-country').removeAttr('style')}
                    disabled={(actionForm == 'insert' || isEdit == true) ? false : true}
                  />
                  <span style={{ color: "red", fontSize: 11 }}>{errorMessage["nationality"]}</span>
                </Col>
              </Form.Group>
              { form.nationality == '360' &&
                <>
                  <Form.Group as={Row}>
                    <Col sm="4" style={{ color: 'gray' }}>No. KTP{/* <span style={{ color: 'red' }}>*</span> */}</Col>
                    <Col sm="8">
                      <Form.Control type="text" name="ktp" value={form.ktp} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                      <span style={{ color: "red", fontSize: 11 }}>{errorMessage["ktp"]}</span>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col sm="4" style={{ color: 'gray' }}>Tanggal Registrasi KTP</Col>
                    <Col sm="8" id="col-ktp-register" className='col-datepicker'>
                      <DatePicker
                        name='ktp_register'
                        value={selectedDayKTPReg}
                        onChange={handleChangeKTPReg}
                        renderInput={renderCustomInputKTPReg}
                        shouldHighlightWeekends
                        maximumDate={maximumDate}
                        disabled={(actionForm == 'insert' || isEdit == true) ? false : true}
                      /><br />
                      <span style={{ color: "red", fontSize: 11 }}>{errorMessage["ktp_register"]}</span>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col sm="4" style={{ color: 'gray' }}>Masa berlaku KTP{/* <span style={{ color: 'red' }}>*</span> */}</Col>
                    <Col sm="3" id="col-ktp-expired" className='col-datepicker'>
                      <DatePicker
                        name='ktp_expired'
                        value={selectedDayKTPExpired}
                        onChange={handleChangeKTPExpired}
                        renderInput={renderCustomInputKTPExpired}
                        shouldHighlightWeekends
                        minimumDate={minimumDate}
                        disabled={(actionForm == 'insert' || isEdit == true) ? false : true}
                      /><br />
                      <span style={{ color: "red", fontSize: 11 }}>{errorMessage["ktp_expired"]}</span>
                    </Col>
                    <Col sm="5">
                      <Checkbox onChange={handleCheck} checked={checked} label="Seumur hidup" disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                    </Col>
                  </Form.Group>
                </>
              }

              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>NPWP{/* <span style={{ color: 'red' }}>*</span> */}</Col>
                <Col sm="8">
                  <Form.Control type="text" name="npwp" value={form.npwp} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                  <span style={{ color: "red", fontSize: 11 }}>{errorMessage["npwp"]}</span>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>Tanggal Registrasi NPWP{/* <span style={{ color: 'red' }}>*</span> */}</Col>
                <Col sm="8" id="col-npwp-register" className='col-datepicker'>
                  <DatePicker
                    name='npwp_register'
                    value={selectedDayNPWPReg}
                    onChange={handleChangeNPWPReg}
                    renderInput={renderCustomInputNPWPReg}
                    shouldHighlightWeekends
                    maximumDate={maximumDate}
                    disabled={(actionForm == 'insert' || isEdit == true) ? false : true}
                  /><br />
                  <span style={{ color: "red", fontSize: 11 }}>{errorMessage["npwp_register"]}</span>
                </Col>
              </Form.Group>

              { form.nationality != '360' &&
                <>
                  <Form.Group as={Row}>
                    <Col sm="4" style={{ color: 'gray' }}>
                      No. Passport<br />
                      <span style={{ fontSize: '11px' }}>(Wajib diisi jika warga negara selain Indonesia)</span>
                      <span style={{ color: 'red' }}>*</span>
                    </Col>
                    <Col sm="8">
                      <Form.Control type="text" name="passport" value={form.passport} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                      <span style={{ color: "red", fontSize: 11 }}>{errorMessage["passport"]}</span>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col sm="4" style={{ color: 'gray' }}>
                      Masa Berlaku Passport<br />
                      <span style={{ fontSize: '11px' }}>(Wajib diisi jika passport diisi)</span>
                      <span style={{ color: 'red' }}>*</span>
                    </Col>
                    <Col sm="8" id="col-passport-expired" className='col-datepicker'>
                      <DatePicker
                        name='passport_expired'
                        value={selectedDayPassportExpired}
                        onChange={handleChangePassportExpired}
                        renderInput={renderCustomInputPassportExpired}
                        shouldHighlightWeekends
                        minimumDate={minimumDate}
                        disabled={(actionForm == 'insert' || isEdit == true) ? false : true}
                      /><br />
                      <span style={{ color: "red", fontSize: 11 }}>{errorMessage["passport_expired"]}</span>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col sm="4" style={{ color: 'gray' }}>No. KITAS/SKD</Col>
                    <Col sm="8">
                      <Form.Control type="text" name="kitas" value={form.kitas} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                      <span style={{ color: "red", fontSize: 11 }}>{errorMessage["kitas"]}</span>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col sm="4" style={{ color: 'gray' }}>
                      Masa Berlaku KITAS/SKD<br />
                      <span style={{ fontSize: '11px' }}>(Wajib diisi jika KITAS/SKD diisi)</span>
                      <span style={{ color: 'red' }}>*</span>
                    </Col>
                    <Col sm="8" id="col-kitas-expired" className='col-datepicker'>
                      <DatePicker
                        name='kitas_expired'
                        value={selectedDayKitasExpired}
                        onChange={handleChangeKitasExpired}
                        renderInput={renderCustomInputKitasExpired}
                        shouldHighlightWeekends
                        minimumDate={minimumDate}
                        disabled={(actionForm == 'insert' || isEdit == true) ? false : true}
                      /><br />
                      <span style={{ color: "red", fontSize: 11 }}>{errorMessage["kitas_expired"]}</span>
                    </Col>
                  </Form.Group>
                </>
              }  

              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>
                  Tempat Lahir
                  {/* <br /><span style={{ fontSize: '11px' }}>(Wajib diisi untuk pemodal langsung)</span> */}
                </Col>
                <Col sm="8">
                  <Form.Control placeholder={props.tipeClient == 'direct' ? null : 'Tempat Lahir (Optional)'}
                  type="text" name="tempat_lahir" value={form.tempat_lahir} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                  <span style={{ color: "red", fontSize: 11 }}>{errorMessage["tempat_lahir"]}</span>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>Tanggal Lahir{/* <span style={{ color: 'red' }}>*</span> */}</Col>
                <Col sm="8" id="col-tanggal-lahir" className='col-datepicker'>
                  <DatePicker
                    name='tanggal_lahir'
                    value={selectedDayDOB}
                    onChange={handleChangeDOB}
                    renderInput={renderCustomInputDOB}
                    shouldHighlightWeekends
                    maximumDate={maximumDate}
                    calendarPopperPosition={'bottom'}
                    disabled={(actionForm == 'insert' || isEdit == true) ? false : true}
                  /><br />
                  <span style={{ color: "red", fontSize: 11 }}>{errorMessage["tanggal_lahir"]}</span>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Col sm="12">
                  <div className='form-title-lengkapi-data-background'># Alamat</div>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>
                  Alamat{/* <span style={{ color: 'red' }}>*</span> */}<br/>
                  <span style={{ fontSize: '11px' }}>
                    {form.nationality == '360' ? '(Alamat sesuai identitas KTP)' : '(Alamat sesuai identitas passport)'}
                  </span>
                  {/* <span style={{ color: 'red' }}>*</span> */}
                </Col>
                <Col sm="8">
                  <Form.Control type="text" name="alamat" value={form.alamat} onChange={handleTextChange} placeholder='Masukkan nama jalan, nama pemukiman, dan nomor rumah' disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                  <br/>
                  <Form.Control type="text" name="alamat2" value={form.alamat2} onChange={handleTextChange} placeholder='Masukkan RT/RW' disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                  <br/>
                  <Form.Control type="text" name="alamat3" value={form.alamat3} onChange={handleTextChange} placeholder='Masukkan nama desa/kelurahan' disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                  <span style={{ color: "red", fontSize: 11 }}>{errorMessage["alamat"]}</span>
                </Col>
              </Form.Group>

              { form.nationality == '360' &&
                <>
                  <div style={{ display: isDisplay }}>
                    <Form.Group as={Row}>
                      <Col sm="4" style={{ color: 'gray' }}>Provinsi{/* <span style={{ color: 'red' }}>*</span> */}</Col>
                      <Col sm="8">
                        <Dropdown
                          name="province"
                          onChange={(e, { value }) => {
                            form.province = value
                            setErrorMessage(errorMessage => ({ ...errorMessage, ['province']: "" }))
                            props.getDataDistrict(1, form.province)
                          }}
                          placeholder='Pilih Provinsi' style={{ width: '100%' }}
                          closeOnChange search selection
                          value={form.province}
                          options={props.dataProvince.map(item => ({ key: item.province_id, text: item.province_name, value: item.province_id }))}
                          disabled={(actionForm == 'insert' || isEdit == true) ? false : true}
                        />
                        <span style={{ color: "red", fontSize: 11 }}>{errorMessage["province"]}</span>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Col sm="4" style={{ color: 'gray' }}>Kota/Kabupaten{/* <span style={{ color: 'red' }}>*</span> */}</Col>
                      <Col sm="8" id='col-district'>
                        <Dropdown
                          name="district"
                          onChange={(e, { value }) => {
                            form.district = value
                            setErrorMessage(errorMessage => ({ ...errorMessage, ['district']: "" }))
                            props.getDataSubDistrict(1, form.province, form.district)
                          }}
                          placeholder='Pilih Kota/Kabupaten' style={{ width: '100%' }}
                          closeOnChange search selection
                          value={form.district}
                          options={props.dataDistrict.map(item => ({ key: item.district_id, text: item.district_name, value: item.district_id }))}
                          onOpen={() => $('#col-district').css('z-index', '999')}
                          onClose={() => $('#col-district').removeAttr('style')}
                          disabled={(actionForm == 'insert' || isEdit == true) ? false : true}
                        />
                        <span style={{ color: "red", fontSize: 11 }}>{errorMessage["district"]}</span>
                      </Col>
                    </Form.Group>
                    {/* sub_district wajib ada, untuk mendukung digital signature */}
                    <Form.Group as={Row}>
                      <Col sm="4" style={{ color: 'gray' }}>Kecamatan{/* <span style={{ color: 'red' }}>*</span> */}</Col>
                      <Col sm="8" id='col-sub-district'>
                        <Dropdown
                          name="sub_district"
                          onChange={(e, { value }) => {
                            form.sub_district = value
                            setErrorMessage(errorMessage => ({ ...errorMessage, ['sub_district']: "" }))
                          }}
                          placeholder='Pilih Kecamatan' style={{ width: '100%' }}
                          closeOnChange search selection
                          value={form.sub_district}
                          // options={options} 
                          options={props.dataSubDistrict.map(item => ({ key: item.sub_district_id, text: item.sub_district_name, value: item.sub_district_id }))}
                          onOpen={() => $('#col-sub-district').css('z-index', '999')}
                          onClose={() => $('#col-sub-district').removeAttr('style')}
                          disabled={(actionForm == 'insert' || isEdit == true) ? false : true}
                        />
                        <span style={{ color: "red", fontSize: 11 }}>{errorMessage["sub_district"]}</span>
                      </Col>
                    </Form.Group>
                  </div>

                  <Form.Group as={Row}>
                    <Col sm="4" style={{ color: 'gray' }}>Kode Pos{/* <span style={{ color: 'red' }}>*</span> */}</Col>
                    <Col sm="8">
                      <Form.Control type="text" name="kode_pos" value={form.kode_pos} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                      <span style={{ color: "red", fontSize: 11 }}>{errorMessage["kode_pos"]}</span>
                    </Col>
                  </Form.Group>
                </>
              }

              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>Negara{/* <span style={{ color: 'red' }}>*</span> */}</Col>
                <Col sm="8" id='col-country'>
                  <Dropdown
                    name="country"
                    onChange={(e, { value }) => {
                      form.country = value
                      setErrorMessage(errorMessage => ({ ...errorMessage, ['country']: "" }))
                      refreshOtherCountries()
                      props.getDataProvince(1, form.country)
                      if (form.country == '360') {
                        form.no_telepon_kode = '+62'
                        form.no_hp_kode = '+62'
                      } else {
                        form.no_telepon_kode = ''
                        form.no_hp_kode = ''
                      }
                    }}
                    placeholder='Pilih Negara' style={{ width: '100%' }}
                    closeOnChange search selection
                    value={form.country}
                    options={props.dataCountries.map(item => ({ key: item.country_id, text: item.country_name, value: item.country_id }))}
                    onOpen={() => $('#col-country').css('z-index', '999')}
                    onClose={() => $('#col-country').removeAttr('style')}
                    disabled={(actionForm == 'insert' || isEdit == true) ? false : true}
                  />
                  <span style={{ color: "red", fontSize: 11 }}>{errorMessage["country"]}</span>
                </Col>
              </Form.Group>

              {/** Alamat other */}
              <Form.Group as={Row}>
                <Col sm="12" style={{ color: 'gray'}}>
                  <Button size={'mini'} onClick={handleClickOtherAlamat.bind(this)}>
                    <span>Tambahkan Alamat Lainnya <Icon name={!isOtherAlamat ? 'chevron down' : 'chevron up'}></Icon></span>
                  </Button>
                </Col>
              </Form.Group>

              {isOtherAlamat ? <OpenOtherAlamat /> : null}
              {/** End Alamat other */}

              <Form.Group as={Row}>
                <Col sm="12">
                  <div className='form-title-lengkapi-data-background'># Kontak</div>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>
                  Nomor Telepon<br />
                  {/* <span style={{ fontSize: '11px' }}>(Wajib diisi untuk pemodal langsung.<br/>Contoh: +62 211234123)</span> */}
                  {/* <span style={{ color: 'red' }}>*</span> */}
                  <span style={{ fontSize: '11px' }}>(Contoh: +62 211234123)</span>
                </Col>
                <Col sm="8">
                  <Form.Group as={Row}>
                    <Col sm="2">
                      <Form.Control type="text" name="no_telepon_kode" value={form.no_telepon_kode} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                    </Col>
                    <Col sm="10">
                      <Form.Control placeholder={props.tipeClient == 'direct' ? null : 'Nomor Telepon (Optional)'} type="text" name="no_telepon" value={form.no_telepon} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                    </Col>
                  </Form.Group>
                  <span style={{ color: "red", fontSize: 11 }}>{errorMessage["no_telepon"]}</span>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>
                  Nomor Handphone{/* <span style={{ color: 'red' }}>*</span> */}<br />
                  <span style={{ fontSize: '11px' }}>(Contoh: +62 82112341234)</span>
                </Col>
                <Col sm="8">
                  <Form.Group as={Row}>
                    <Col sm="2">
                      <Form.Control type="text" name="no_hp_kode" value={form.no_hp_kode} onChange={handleTextChange} disabled={true}
                      />
                    </Col>
                    <Col sm="10">
                      <Form.Control type="text" name="no_hp" value={form.no_hp} onChange={handleTextChange} disabled={true}/>
                    </Col>
                  </Form.Group>
                  <span style={{ color: "red", fontSize: 11 }}>{errorMessage["no_hp"]}</span>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>Email{/* <span style={{ color: 'red' }}>*</span> */}</Col>
                <Col sm="8">
                  <Form.Control type="text" name="email" value={form.email} onChange={handleTextChange} disabled={true}/>
                  <span style={{ color: "red", fontSize: 11 }}>{errorMessage["email"]}</span>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>
                  Fax.<br/>
                  <span style={{ fontSize: '11px' }}>(Contoh: +62 211234123)</span>
                </Col>
                <Col sm="8">
                  <Form.Group as={Row}>
                    <Col sm="2">
                      <Form.Control type="text" name="no_fax_kode" value={form.no_fax_kode} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                    </Col>
                    <Col sm="10">
                      <Form.Control placeholder={'Fax. (Optional)'} type="text" name="no_fax" value={form.no_fax} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                    </Col>
                  </Form.Group>
                  <span style={{ color: "red", fontSize: 11 }}>{errorMessage["no_fax"]}</span>
                </Col>
              </Form.Group>
              
              {/** Kontak other */}
              <Form.Group as={Row}>
                <Col sm="12" style={{ color: 'gray'}}>
                  <Button size={'mini'} onClick={handleClickOtherContact.bind(this)}>
                    <span>Tambahkan Kontak Lainnya <Icon name={!isOtherContact ? 'chevron down' : 'chevron up'}></Icon></span>
                  </Button>
                </Col>
              </Form.Group> 
              
              {isOtherContact ? <OpenOtherContact /> : null}
              {/** End Kontak other */}

              <Form.Group as={Row}>
                <Col sm="12">
                  <div className='form-title-lengkapi-data-background'># Lainnya</div>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>Jenis Kelamin{/* <span style={{ color: 'red' }}>*</span> */}</Col>
                <Col sm="8" id='col-jenis-kelamin'>
                  <Dropdown
                    name="jenis_kelamin"
                    value={form.jenis_kelamin}
                    onChange={(e, { value }) => {
                      form.jenis_kelamin = value
                      setErrorMessage(errorMessage => ({ ...errorMessage, ['jenis_kelamin']: "" }))
                    }}
                    placeholder='Pilih Jenis Kelamin' style={{ width: '100%' }}
                    closeOnChange search selection
                    options={jenisKelaminOptions}
                    onOpen={() => $('#col-jenis-kelamin').css('z-index', '999')}
                    onClose={() => $('#col-jenis-kelamin').removeAttr('style')}
                    disabled={(actionForm == 'insert' || isEdit == true) ? false : true}
                  />
                  <span style={{ color: "red", fontSize: 11 }}>{errorMessage["jenis_kelamin"]}</span>
                </Col>
              </Form.Group>
              
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>Status Pernikahan{/* <span style={{ color: 'red' }}>*</span> */}</Col>
                <Col sm="8" id='col-status-pernikahan'>
                  <Dropdown
                    name="status_pernikahan"
                    value={form.status_pernikahan}
                    onChange={(e, { value }) => {
                      form.status_pernikahan = value
                      setErrorMessage(errorMessage => ({ ...errorMessage, ['status_pernikahan']: "" }))
                    }}
                    placeholder='Pilih Status Pernikahan' style={{ width: '100%' }}
                    closeOnChange search selection
                    options={statusPernikahanOptions}
                    onOpen={() => $('#col-status-pernikahan').css('z-index', '999')}
                    onClose={() => $('#col-status-pernikahan').removeAttr('style')}
                    disabled={(actionForm == 'insert' || isEdit == true) ? false : true}
                  />
                  <span style={{ color: "red", fontSize: 11 }}>{errorMessage["status_pernikahan"]}</span>
                </Col>
              </Form.Group>


              {props.tipePemodal == 'pribadi' ?
                <>
                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: 'gray' }}>Nama Pasangan (Suami / Istri)</Col>
                  <Col sm="8">
                    <Form.Control placeholder='Nama Pasangan (Suami / Istri) (Optional)' type="text" name="nama_pasangan" value={form.nama_pasangan} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                    <span style={{ color: "red", fontSize: 11 }}>{errorMessage["nama_pasangan"]}</span>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: 'gray' }}>
                    Nama Gadis Ibu Kandung<br />
                    {/* <span style={{ fontSize: '11px' }}>(Wajib diisi untuk pemodal langsung)</span>
                    <span style={{ color: 'red' }}>*</span> */}
                  </Col>
                  <Col sm="8">
                    <Form.Control placeholder={props.tipeClient == 'direct' ? null : 'Nama Gadis Ibu Kandung (Optional)'} type="text" name="nama_ibu_kandung" value={form.nama_ibu_kandung} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                    <span style={{ color: "red", fontSize: 11 }}>{errorMessage["nama_ibu_kandung"]}</span>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: 'gray' }}>Nama Ahli Waris</Col>
                  <Col sm="8">
                    <Form.Control placeholder='Nama Ahli Waris (Optional)' type="text" name="nama_ahli_waris" value={form.nama_ahli_waris} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                    <span style={{ color: "red", fontSize: 11 }}>{errorMessage["nama_ahli_waris"]}</span>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: 'gray' }}>Hubungan Ahli Waris</Col>
                  <Col sm="8">
                    <Form.Control placeholder='Hubungan Ahli Waris (Optional)'  type="text" name="hubungan_ahli_waris" value={form.hubungan_ahli_waris} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                    <span style={{ color: "red", fontSize: 11 }}>{errorMessage["hubungan_ahli_waris"]}</span>
                  </Col>
                </Form.Group>

                {(actionForm == 'insert' || isEdit == true) &&
                  <Form.Group as={Row}>
                    <Col sm="4" style={{ color: 'gray' }}></Col>
                    <Col sm="8">
                      <div style={{marginBottom: 10}}>
                        <span style={{ fontSize: '11px' }}>Wajib lampirkan bukti jika ahli waris diisi (format: pdf)</span>
                      </div>
                      <Form.Control type="file" name="ahliwaris_evidence" onChange={handleTextChange} placeholder="Bukti hubungan ahli waris" accept="application/pdf, image/jpg, image/jpeg, image/png" />
                      <br/>
                      <span style={{ color: "red", fontSize: 11 }}>{errorMessage["ahliwaris_evidence"]} </span>
                    </Col>
                  </Form.Group>
                }
                
                {actionForm == 'edit' ? 
                  <Form.Group as={Row}>
                    <Col sm="4" style={{ color: 'gray' }}></Col>
                    <Col sm="8">
                      <a href='#' onClick={handleShowModal.bind(this, 'ahliwaris_evidence')}>Lihat bukti hubungan ahli waris</a>
                    </Col>
                  </Form.Group>
                  : null
                }
                </>
              : null
              }

              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>Pendidikan Terakhir{/* <span style={{ color: 'red' }}>*</span> */}</Col>
                <Col sm="8" id='col-pendidikan'>
                  <Dropdown
                    name="pendidikan"
                    value={form.pendidikan}
                    onChange={(e, { value }) => {
                      form.pendidikan = value
                      setErrorMessage(errorMessage => ({ ...errorMessage, ['pendidikan']: "" }))
                    }}
                    placeholder='Pilih Pendidikan Terakhir' style={{ width: '100%' }}
                    closeOnChange search selection
                    options={pendidikanOptions}
                    onOpen={() => $('#col-pendidikan').css('z-index', '999')}
                    onClose={() => $('#col-pendidikan').removeAttr('style')}
                    disabled={(actionForm == 'insert' || isEdit == true) ? false : true}
                  />
                  <span style={{ color: "red", fontSize: 11 }}>{errorMessage["pendidikan"]}</span>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>Pekerjaan{/* <span style={{ color: 'red' }}>*</span> */}</Col>
                <Col sm="8" id='col-pekerjaan'>
                  <Dropdown
                    name="pekerjaan"
                    value={form.pekerjaan}
                    onChange={(e, { value }) => { 
                      form.pekerjaan = value
                      setErrorMessage(errorMessage => ({ ...errorMessage, ['pekerjaan']: "" }))

                      if(form.pekerjaan == '1'){
                        setIsWorkDescription({openWorkOtherDesc: true, openBusinessDesc: false})
                      } else if(form.pekerjaan == '5'){
                        setIsWorkDescription({openWorkOtherDesc: false, openBusinessDesc: true})
                      } else {
                        setIsWorkDescription({openWorkOtherDesc: false, openBusinessDesc: false})

                        setErrorMessage(errorMessage => ({ ...errorMessage, ['deskripsi_pekerjaan']: "" }))
                        setErrorMessage(errorMessage => ({ ...errorMessage, ['deskripsi_usaha']: "" }))
                      }
                    }}
                    placeholder='Pilih Pekerjaan' style={{ width: '100%' }}
                    closeOnChange search selection
                    options={pekerjaanOptions}
                    onOpen={() => $('#col-pekerjaan').css('z-index', '999')}
                    onClose={() => $('#col-pekerjaan').removeAttr('style')}
                    disabled={(actionForm == 'insert' || isEdit == true) ? false : true}
                  />
                  <span style={{ color: "red", fontSize: 11 }}>{errorMessage["pekerjaan"]}</span>
                </Col>
              </Form.Group>
              
              {props.tipePemodal == 'pribadi' && form.pekerjaan != '' ?
                <>
                  {(actionForm == 'insert' || isEdit == true) &&
                    <Form.Group as={Row}>
                      <Col sm="4" style={{ color: 'gray' }}></Col>
                      <Col sm="8">
                        <div style={{marginBottom: 10}}>
                          {
                            form.pekerjaan == '2' ? <span style={{ fontSize: '11px' }}>Lampirkan slip gaji (format: pdf)</span>
                            : form.pekerjaan == '3' ? <span style={{ fontSize: '11px' }}>Lampirkan slip gaji (format: pdf)</span>
                            : form.pekerjaan == '7' ? <span style={{ fontSize: '11px' }}>Lampirkan slip gaji (format: pdf)</span>
                            : form.pekerjaan == '9' ? <span style={{ fontSize: '11px' }}>Lampirkan slip gaji (format: pdf)</span>
                            : <span style={{ fontSize: '11px' }}>Lampirkan SPT tahun sebelumnya (format: pdf)</span>
                          }
                        </div>
                        <Form.Control type="file" name="slip_spt" onChange={handleTextChange} placeholder="Slip Gaji / SPT" accept="application/pdf, image/jpg, image/jpeg, image/png" />
                        <br/>
                        <span style={{ color: "red", fontSize: 11 }}>{errorMessage["slip_spt"]} </span>
                      </Col>
                    </Form.Group>
                  }

                  {actionForm == 'edit' ? 
                    <Form.Group as={Row}>
                      <Col sm="4" style={{ color: 'gray' }}></Col>
                      <Col sm="8">
                        {
                          form.pekerjaan == '2' ? <a href='#' onClick={handleShowModal.bind(this, 'slip_spt')}>Lihat slip gaji</a>
                          : form.pekerjaan == '3' ? <a href='#' onClick={handleShowModal.bind(this, 'slip_spt')}>Lihat slip gaji</a>
                          : form.pekerjaan == '7' ? <a href='#' onClick={handleShowModal.bind(this, 'slip_spt')}>Lihat slip gaji</a>
                          : form.pekerjaan == '9' ? <a href='#' onClick={handleShowModal.bind(this, 'slip_spt')}>Lihat slip gaji</a>
                          : <a href='#' onClick={handleShowModal.bind(this, 'slip_spt')}>Lihat SPT tahunan</a>
                        }
                      </Col>
                    </Form.Group>
                    : null
                  }
                </>
              : null
              }
              
              {isWorkDescription.openWorkOtherDesc ? 
                <Form.Group as={Row}> {/*Wajib diisi jika Pekerjaan dipilih = Lainnya*/}
                  <Col sm="4" style={{ color: 'gray' }}>
                    Deskripsi Pekerjaan
                    {/* <br /><span style={{ fontSize: '11px' }}>(Wajib diisi jika pekerjaan dipilih "Lainnya")</span> */}
                    {/* <span style={{ color: 'red' }}>*</span> */}
                  </Col>
                  <Col sm="8">
                    <Form.Control type="text" name="deskripsi_pekerjaan" value={form.deskripsi_pekerjaan} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                    <span style={{ color: "red", fontSize: 11 }}>{errorMessage["deskripsi_pekerjaan"]}</span>
                  </Col>
                </Form.Group>
              : null }

              {isWorkDescription.openBusinessDesc ? 
                <Form.Group as={Row}> {/*Wajib diisi jika Pekerjaan dipilih = Wiraswasta*/}
                  <Col sm="4" style={{ color: 'gray' }}>
                    Deskripsi Usaha / Bisnis
                    {/* <br /><span style={{ fontSize: '11px' }}>(Wajib diisi jika pekerjaan dipilih "Wiraswasta")</span> */}
                    {/* <span style={{ color: 'red' }}>*</span> */}
                  </Col>
                  <Col sm="8">
                    <Form.Control type="text" name="deskripsi_usaha" value={form.deskripsi_usaha} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                    <span style={{ color: "red", fontSize: 11 }}>{errorMessage["deskripsi_usaha"]}</span>
                  </Col>
                </Form.Group>
              : null}

              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>Penghasilan Pertahun{/* <span style={{ color: 'red' }}>*</span> */}</Col>
                <Col sm="8" id='col-penghasilan'>
                  <Dropdown
                    name="penghasilan"
                    value={form.penghasilan}
                    onChange={(e, { value }) => {
                      form.penghasilan = value
                      setErrorMessage(errorMessage => ({ ...errorMessage, ['penghasilan']: "" }))
                    }}
                    placeholder='Pilih Penghasilan Pertahun' style={{ width: '100%' }}
                    closeOnChange search selection
                    options={penghasilanOptions}
                    onOpen={() => $('#col-penghasilan').css('z-index', '999')}
                    onClose={() => $('#col-penghasilan').removeAttr('style')}
                    disabled={(actionForm == 'insert' || isEdit == true) ? false : true}
                  />
                  <span style={{ color: "red", fontSize: 11 }}>{errorMessage["penghasilan"]}</span>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>Sumber Dana Investasi{/* <span style={{ color: 'red' }}>*</span> */}</Col>
                <Col sm="8" id='col-sumber-dana'>
                  <Dropdown
                    name="sumber_dana"
                    value={form.sumber_dana}
                    onChange={(e, { value }) => {
                      form.sumber_dana = value
                      setErrorMessage(errorMessage => ({ ...errorMessage, ['sumber_dana']: "" }))
                      if (form.sumber_dana == '1') {
                        setIsFundResourceDescription(true)
                      } else {
                        setIsFundResourceDescription(false)
                        setErrorMessage(errorMessage => ({ ...errorMessage, ['deskripsi_sumber_dana']: "" }))
                      }
                    }}
                    placeholder='Pilih Sumber Dana Investasi' style={{ width: '100%' }}
                    closeOnChange search selection
                    options={sumberDanaOptions}
                    onOpen={() => $('#col-sumber-dana').css('z-index', '999')}
                    onClose={() => $('#col-sumber-dana').removeAttr('style')}
                    disabled={(actionForm == 'insert' || isEdit == true) ? false : true}
                  />
                  <span style={{ color: "red", fontSize: 11 }}>{errorMessage["sumber_dana"]}</span>
                </Col>
              </Form.Group>
              
              {isFundResourceDescription ? 
                <Form.Group as={Row}>{/*Wajib diisi jika sumber dana dipilih = lainnya*/}
                  <Col sm="4" style={{ color: 'gray' }}>Deskripsi Sumber dana</Col> 
                  <Col sm="8">
                    <Form.Control type="text" 
                      name="deskripsi_sumber_dana" 
                      value={form.deskripsi_sumber_dana} 
                      onChange={handleTextChange} 
                      disabled={(actionForm == 'insert' || isEdit == true) ? false : true}
                    />
                    <span style={{ color: "red", fontSize: 11 }}>{errorMessage["deskripsi_sumber_dana"]}</span>
                  </Col>
                </Form.Group>
              : null }

              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>Tujuan Investasi{/* <span style={{ color: 'red' }}>*</span> */}</Col>
                <Col sm="8" id='col-tujuan-investasi'>
                  <Dropdown
                    name="tujuan_investasi"
                    value={form.tujuan_investasi}
                    onChange={(e, { value }) => {
                      form.tujuan_investasi = value
                      setErrorMessage(errorMessage => ({ ...errorMessage, ['tujuan_investasi']: "" }))
                    }}
                    placeholder='Pilih Tujuan Investasi' style={{ width: '100%' }}
                    closeOnChange search selection
                    options={tujuanInvestasiOptions}
                    onOpen={() => $('#col-tujuan-investasi').css('z-index', '999')}
                    onClose={() => $('#col-tujuan-investasi').removeAttr('style')}
                    disabled={(actionForm == 'insert' || isEdit == true) ? false : true}
                  />
                  <span style={{ color: "red", fontSize: 11 }}>{errorMessage["tujuan_investasi"]}</span>
                </Col>
              </Form.Group>
              
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>Kepemilikan Efek{/* <span style={{ color: 'red' }}>*</span> */}</Col>
                <Col sm="8" id='col-kepemilikan-efek'>
                  <Dropdown
                    name="kepemilikan_efek"
                    value={form.kepemilikan_efek}
                    // onChange={(e, { value }) => {
                    //   form.kepemilikan_efek = value
                    //   setErrorMessage(errorMessage => ({ ...errorMessage, ['kepemilikan_efek']: "" }))
                    // }}
                    placeholder='Kepemilikan Efek' style={{ width: '100%' }}
                    closeOnChange search selection
                    options={kepemilikanEfekOptions}
                    onOpen={() => $('#col-kepemilikan-efek').css('z-index', '999')}
                    onClose={() => $('#col-kepemilikan-efek').removeAttr('style')}
                    // disabled={(actionForm == 'insert' || isEdit == true) ? false : true}
                    disabled={true}
                  />
                  <span style={{ color: "red", fontSize: 11 }}>{errorMessage["kepemilikan_efek"]}</span>
                </Col>
              </Form.Group>
              
              {props.tipePemodal == 'pribadi' ? 
                <Form.Group as={Row}>
                  <Col sm="4" style={{ color: 'gray' }}>Pengalaman Investasi</Col>
                  <Col sm="8">
                    <Checkbox onChange={handleCheckClientExperience} checked={checkedClientExperience} label="Pengalaman 2 tahun atau lebih" disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                    <br/>
                    <Checkbox onChange={handleCheckClientNonExperience} checked={checkedClientNonExperience} label="Non pengalaman atau pengalaman kurang dari 2 tahun" disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                    <br/>
                    <span style={{ color: "red", fontSize: 11 }}>{errorMessage["experience"]}</span>
                  </Col>
                </Form.Group>
              : null }
              
              {form.experience == '1' ?
                <>
                  <Form.Group as={Row}>
                    <Col sm="4" style={{ color: 'gray' }}>SID (<i>Single Investor Identification</i>)
                      {/* <br/><span style={{ fontSize: '11px' }}>(SID bisa diisi setelah anda memilih pengalaman atau non pengalaman)</span> */}
                      {/* <span style={{ color: 'red' }}>*</span> */}
                    </Col>
                    <Col sm="8">
                      <Form.Control type="text" name="sid" 
                        value={form.sid} 
                        onChange={handleTextChange} 
                        disabled={(actionForm == 'insert' || isEdit == true) ? false : true}
                        />
                      <span style={{ color: "red", fontSize: 11 }}>{errorMessage["sid"]}</span>
                    </Col>
                  </Form.Group>
                  {(actionForm == 'insert' || isEdit == true) &&
                      <Form.Group as={Row}>
                        <Col sm="4" style={{ color: 'gray' }}></Col>
                        <Col sm="8">
                          <div style={{marginBottom: 10}}>
                            <span style={{ fontSize: '11px' }}>Lampirkan bukti kepemilikan rekening efek (format: pdf, jpg, jpeg, png)</span>
                            {/* <span style={{ color: 'red' }}>*</span> */}
                          </div>
                          <Form.Control type="file" name="experience_evidence" onChange={handleTextChange} placeholder="Bukti pengalaman 2 tahun atau lebih" accept="application/pdf, image/jpg, image/jpeg, image/png" />
                          <br/>
                          <span style={{ color: "red", fontSize: 11 }}>{errorMessage["experience_evidence"]}</span>
                        </Col>
                      </Form.Group>
                  }
                  {actionForm == 'edit' && 
                      <Form.Group as={Row}>
                        <Col sm="4" style={{ color: 'gray' }}></Col>
                        <Col sm="8">
                          <a href='#' onClick={handleShowModal.bind(this, 'experience_evidence')}>Lihat bukti rekening kepemilikan efek</a>
                        </Col>
                      </Form.Group>
                  }
                </>
              : null } 
            </Col>
          </Row>
          {/* <Row>
            <Col md={12}>
              <div className='vertical-line' />
            </Col>
          </Row> */}
          <Row>
            <Col md={12}>
              {/* <Header id="fonts" as='h6' color="blue" size='small'>
                <Header.Content>
                  FOTO IDENTITAS
                </Header.Content>
              </Header> */}
              <Form.Group as={Row}>
                <Col sm="12">
                  <div className='form-title-lengkapi-data-background'># FOTO IDENTITAS</div>
                </Col>
              </Form.Group>

              <Form.Group as={Row}> 
                <Col sm="3">
                  <div id='upload-foto-selfie' className="file-upload">
                    <div className='file-upload-tile'>FOTO SELFIE{/* <span style={{ color: 'red' }}>*</span> */}</div>

                    <Button style={{width: '100%'}} type="button" color='green'
                      onClick={handleShowModalUploadFoto.bind(this, 'foto_selfie')}>Capture</Button>

                    <div class="image-upload-wrap" onClick={handleShowModalUploadFoto.bind(this, 'foto_selfie')}>
                      <div className="drag-text">
                        {/* <h3>Drag and drop a file or select add Image</h3> */}
                        <h3>+</h3>
                      </div>
                    </div>
                    <div className="file-upload-content">
                      <img className="file-upload-image" src="#" alt="your image" />
                      <div className="image-title-wrap">
                        <Button type="button" color='red' onClick={handleRemoveFoto.bind(this, 'foto_selfie')}
                          className="remove-image">Remove <span className="image-title">Uploaded Image</span>
                        </Button>
                      </div>
                    </div>
                    <span style={{ color: "red", fontSize: 11 }}>{errorMessage["foto_selfie"]}</span>
                  </div>
                </Col>
                <Col sm="3">
                  <div id='upload-foto-selfie-ktp' className="file-upload">
                    <div className='file-upload-tile'>FOTO SELFIE DENGAN KTP{/* <span style={{ color: 'red' }}>*</span> */}</div>

                    <Button style={{width: '100%'}} type="button" color='green'
                      onClick={handleShowModalUploadFoto.bind(this, 'foto_selfie_ktp')}>Capture</Button>

                    <div class="image-upload-wrap" onClick={handleShowModalUploadFoto.bind(this, 'foto_selfie_ktp')}>
                      <div className="drag-text">
                        {/* <h3>Drag and drop a file or select add Image</h3> */}
                        <h3>+</h3>
                      </div>
                    </div>
                    <div className="file-upload-content">
                      <img className="file-upload-image" src="#" alt="your image" />
                      <div className="image-title-wrap">
                        <Button type="button" color='red' onClick={handleRemoveFoto.bind(this, 'foto_selfie_ktp')}
                          className="remove-image">Remove <span className="image-title">Uploaded Image</span>
                        </Button>
                      </div>
                    </div>
                    <span style={{ color: "red", fontSize: 11 }}>{errorMessage["foto_selfie_ktp"]}</span>
                  </div>
                </Col>
                <Col sm="3">
                  <div id='upload-foto-ktp' className="file-upload">

                    <div className='file-upload-tile'>FOTO KTP{/* <span style={{ color: 'red' }}>*</span> */}</div>

                    <Button style={{width: '50%'}} type="button" color='green'
                      onClick={handleShowModalUploadFoto.bind(this, 'foto_ktp')}>Capture</Button>
                      
                    <Button style={{width: '46%'}} type="button" color='green'
                      onClick={()=>{ $('#upload-foto-ktp .file-upload-input').trigger('click') }}>Pilih</Button>

                    <div class="image-upload-wrap">
                      <input className={"file-upload-input"} type='file' 
                        onChange={(event) => {

                          if (event.target.files && event.target.files[0]) {

                            var filename = event.target.files[0].name

                            var reader = new FileReader();
                        
                            reader.onload = function(e) {
                              $('#upload-foto-ktp .image-upload-wrap').hide();
                              $('#upload-foto-ktp .file-upload-image').attr('src', e.target.result);
                              $('#upload-foto-ktp .file-upload-content').show();
                              $('#upload-foto-ktp .image-title').html(filename);
                            };
                            
                            form.foto_ktp = event.target.files[0]
                            reader.readAsDataURL(event.target.files[0]);
                        
                          } else {
                            $('#upload-foto-ktp .file-upload-input').replaceWith($('#upload-foto-ktp .file-upload-input').clone());
                            $('#upload-foto-ktp .file-upload-content').hide();
                            $('#upload-foto-ktp .image-upload-wrap').show();
                          }
                        }}
                        accept="image/*" />
                      <div className="drag-text">
                        <h3>+</h3>
                      </div>
                    </div>
                    <div className="file-upload-content">
                      <img className="file-upload-image" src="#" alt="your image" />
                      <div className="image-title-wrap">
                        <Button type="button" color='red' onClick={handleRemoveFoto.bind(this, 'foto_ktp')}
                          className="remove-image">Remove <span className="image-title">Uploaded Image</span>
                        </Button>
                      </div>
                    </div>
                    <span style={{ color: "red", fontSize: 11 }}>{errorMessage["foto_ktp"]}</span>
                  </div>
                </Col>
                <Col sm="3">
                  <div id='upload-foto-npwp' className="file-upload">

                    <div className='file-upload-tile'>FOTO NPWP{/* <span style={{ color: 'red' }}>*</span> */}</div>

                    <Button style={{width: '50%'}} type="button" color='green'
                      onClick={handleShowModalUploadFoto.bind(this, 'foto_npwp')}>Capture</Button>
                      
                    <Button style={{width: '46%'}} type="button" color='green'
                      onClick={()=>{ $('#upload-foto-npwp .file-upload-input').trigger('click') }}>Pilih</Button>

                    <div class="image-upload-wrap">
                      <input className={"file-upload-input"} type='file' 
                        onChange={(event) => {

                          if (event.target.files && event.target.files[0]) {

                            var filename = event.target.files[0].name

                            var reader = new FileReader();
                        
                            reader.onload = function(e) {
                              $('#upload-foto-npwp .image-upload-wrap').hide();
                              $('#upload-foto-npwp .file-upload-image').attr('src', e.target.result);
                              $('#upload-foto-npwp .file-upload-content').show();
                              $('#upload-foto-npwp .image-title').html(filename);
                            };
                            
                            form.foto_npwp = event.target.files[0]
                            reader.readAsDataURL(event.target.files[0]);
                        
                          } else {
                            $('#upload-foto-npwp .file-upload-input').replaceWith($('#upload-foto-npwp .file-upload-input').clone());
                            $('#upload-foto-npwp .file-upload-content').hide();
                            $('#upload-foto-npwp .image-upload-wrap').show();
                          }
                        }}
                        accept="image/*" />
                      <div className="drag-text">
                        <h3>+</h3>
                      </div>
                    </div>
                    <div className="file-upload-content">
                      <img className="file-upload-image" src="#" alt="your image" />
                      <div className="image-title-wrap">
                        <Button type="button" color='red' onClick={handleRemoveFoto.bind(this, 'foto_npwp')}
                          className="remove-image">Remove <span className="image-title">Uploaded Image</span>
                        </Button>
                      </div>
                    </div>
                    <span style={{ color: "red", fontSize: 11 }}>{errorMessage["foto_npwp"]}</span>
                  </div>
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className='vertical-line' />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div style={{ float: 'right' }}>
                {actionForm == 'insert' ?
                  <Button className='btnSimpan' color='green' onClick={handleSubmit} disabled={loading}>
                    {loading && <Spinner animation="border" variant="default" size='sm' style={{ marginRight: "5px" }}/>}
                    {loading && <span>Loading..</span>}
                    {!loading && <span>Simpan & Lanjutkan</span>}
                  </Button>
                  : 
                  <>
                    {isEdit == false ? 
                      <>
                        <Button className='btnEdit' color='blue' onClick={handleEdit}>Edit</Button>
                        <Button className='btnNext' color='green' onClick={props.handlePageNext}>Selanjutnya</Button>
                      </>
                    : 
                      <>
                        <Button className='btnEdit' color='blue' onClick={handleCancelEdit}>Batal</Button>
                        {/* <Button className='btnNext' color='green' onClick={props.handlePageNext}>Selanjutnya</Button> */}
                        <Button className='btnSimpan' color='green' onClick={handleSubmit} disabled={loading}>
                          {loading && <Spinner animation="border" variant="default" size='sm' style={{ marginRight: "5px" }}/>}
                          {loading && <span>Loading..</span>}
                          {!loading && <span>Simpan & Lanjutkan</span>}
                        </Button>
                      </>
                    }
                  </>
                }
              </div>
            </Col>
          </Row>
        </Form>
      </Container>

      <Modal size="lg" style={{ borderRadius: "30px" }} show={showModal} onHide={handleCloseModal} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span>{showEvidence == 'experience_evidence' ? 'Bukti Rekening Efek' : showEvidence == 'ahliwaris_evidence' ? 'Bukti Hubungan Ahli Waris' : ''}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showEvidence == 'experience_evidence' ?
            <>
              {typeFile == 'image' ? <Image src={fileSrc.experience_evidence}/> : <iframe src={fileSrc.experience_evidence} height="1000" width="775"/>}
            </>
          : showEvidence == 'ahliwaris_evidence' ?  
            <>
              {typeFile == 'image' ? <Image src={fileSrc.ahliwaris_evidence}/> : <iframe src={fileSrc.ahliwaris_evidence} height="1000" width="775"/>}
            </>
          : showEvidence == 'slip_spt' ?  
            <>
              {typeFile == 'image' ? <Image src={fileSrc.slip_spt}/> : <iframe src={fileSrc.slip_spt} height="1000" width="775"/>}
            </>
          : <></> 
          }   
        </Modal.Body>
      </Modal>

      {/* <Modal size="lg" style={{ borderRadius: "30px" }} show={showModalUploadFoto} onHide={handleCloseModalUploadFoto} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Capture Foto</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        </Modal.Body>
      </Modal> */}

      <Modal size="lg" style={{ borderRadius: "30px" }} show={showModalUploadFoto} onHide={false} animation={false}>
        {/* <Form name='formModalUploadFoto' onSubmit={e => e.preventDefault()} encType="multipart/form-data" autoComplete='off'> */}
          <Modal.Body>
            {captureTo == 'foto_selfie' && <strong>Foto Selfie</strong>}
            {captureTo == 'foto_ktp' && <strong>Foto KTP</strong>}
            {captureTo == 'foto_npwp' && <strong>Foto NPWP</strong>}
            <br/>
            <p>Capture foto dari web camera</p>
            <CameraFeed sendFile={uploadImage}/>
            <br/>
            <span style={{ color: "red", fontSize: 11 }}>{errorMessage["foto_error_message"]}</span>
            
          </Modal.Body>
          <Modal.Footer>
            <Button color='blue' onClick={handleCloseModalUploadFoto}>Close</Button>
            <Button color='green' onClick={handleSetFotoCapture}>OK</Button>
          </Modal.Footer>
        {/* </Form> */}
      </Modal>
    </>
  )
})

const DataPerusahaan = memo((props) => {

  const [actionForm, setActionForm] = useState(props.userinfo.is_complete > 1 ? 'edit' : 'insert')

  const initData = {
    nama_perusahaan: props.userinfo.nama_badan == null ? '' : props.userinfo.nama_badan,
    alamat_perusahaan: props.userinfo.alamat_badan == null ? '' : props.userinfo.alamat_badan,
    telepon_perusahaan_kode: props.userinfo.no_telepon_badan_kode == null ? '' : props.userinfo.no_telepon_badan_kode,
    telepon_perusahaan: props.userinfo.no_telepon_badan == null ? '' : props.userinfo.no_telepon_badan,
    fax_perusahaan_kode: props.userinfo.no_fax_badan_kode == null ? '' : props.userinfo.no_fax_badan_kode,
    fax_perusahaan: props.userinfo.no_fax_badan == null ? '' : props.userinfo.no_fax_badan,
    npwp_perusahaan: props.userinfo.npwp_badan == null ? '' : props.userinfo.npwp_badan,
    npwp_perusahaan_register: props.userinfo.npwp_badan_register == null ? '' : props.userinfo.npwp_badan_register,
  }

  const [form, setForm] = useState(initData);

  const dateMax = new Date();
  dateMax.setDate(dateMax.getDate() - 1);
  const maximumDate = {
    year: dateFormat(dateMax, 'yyyy'),
    month: dateFormat(dateMax, 'mm'),
    day: dateFormat(dateMax, 'dd')
  }
  
  const [selectedDayNPWPReg, setSelectedDayNPWPReg] = useState(form.npwp_perusahaan_register == '' ? null : {
    year: parseInt(dateFormat(form.npwp_perusahaan_register, 'yyyy')),
    month: parseInt(dateFormat(form.npwp_perusahaan_register, 'mm')),
    day: parseInt(dateFormat(form.npwp_perusahaan_register, 'dd')),
  });
  const renderCustomInputNPWPReg = ({ ref }) => (
    <Form.Control
      readOnly
      ref={ref}
      placeholder="Pilih tanggal"
      value={selectedDayNPWPReg ? `${selectedDayNPWPReg.day}/${selectedDayNPWPReg.month}/${selectedDayNPWPReg.year}` : ''}
      disabled={(actionForm == 'insert' || isEdit == true) ? false : true}
    />
  )
  const handleChangeNPWPReg = (event) => {
    setSelectedDayNPWPReg(event)
    if (event !== '') {
      // var selectDate = event ? `${event.day}/${event.month}/${event.year}` : ''
      var selectDate = event ? `${event.year}-${event.month}-${event.day}` : ''
      setForm({ ...form, ['npwp_perusahaan_register']: selectDate })
      setErrorMessage(errorMessage => ({ ...errorMessage, ['npwp_perusahaan_register']: "" }));
    }
  }

  const [errorMessage, setErrorMessage] = useState({});

  const handleTextChange = event => {

    var index = event.target.name
    setErrorMessage(errorMessage => ({ ...errorMessage, [index]: "" }));

    if((index === 'nama_perusahaan') && (event.target.value.length > 100)) return
    if((index === 'alamat_perusahaan') && (event.target.value.length > 180)) return

    if(index === 'telepon_perusahaan_kode' || index === 'fax_perusahaan_kode'){
      const re = /^[+0-9\b]+$/;
      if (event.target.value != '' && !re.test(event.target.value)) {
        return
      }
      if((event.target.value.length > 1) && (event.target.value.slice(-1) == '+')){
        return
      }
      if(event.target.value.length > 5){
        return
      }
    }

    if(index === 'telepon_perusahaan' || index === 'fax_perusahaan'){
      const re = /^[0-9\b]+$/;
      if (event.target.value != '' && !re.test(event.target.value)) {
        return
      }
      if(event.target.value.length > 10){
        return
      }
    }

    if (index === 'npwp_perusahaan') {
      // 79.275.653.8.422.000
      const re = /^[0-9.\b]+$/;
      if (event.target.value != '' && !re.test(event.target.value)) {
        return
      }
      if(event.target.value.length > 20){
        return
      }
    }

    setForm({ ...form, [event.target.name]: event.target.value })
  }

  const [loading, setLoading] = useState(false)

  const beforeUnloadListener = (event) => {
    event.preventDefault();
    event.returnValue = "";
    return ""
  }

  const postData = async (values) => {
    window.addEventListener("beforeunload", beforeUnloadListener);

    // console.log(JSON.stringify(values))
    // return

    values.post_to = 'perusahaan'
    values.tipe_pemodal = props.tipePemodal

    let config = {
      headers: {
        'Authorization': localStorage.token,
        'content-type': 'application/json',
        'Access-Control-Allow-Credentials': true,
        'Access-Control-Allow-Methods': 'POST',
      },
      maxContentLength: 52428890,
      timeout: 10000000,
    }
    
    setLoading(true)

    axios.post(BASE_URL + "/customer/pemodal/lengkapidata_v2", values, config)
      .then((resp) => {

        setLoading(false)
        window.removeEventListener("beforeunload", beforeUnloadListener);

        swal({ text: resp.data.body.message, icon: 'success' })
        // console.log(JSON.stringify(resp.data))

        // var dataRes = resp.data.body.data
        // Object.assign(props.userinfo, dataRes)

        props.getUserpemodal()

        props.handlePageNext()
        window.scrollTo(0, 0)
        
      })
      .catch(error => {
        
        setLoading(false)
        window.removeEventListener("beforeunload", beforeUnloadListener);

        try {
          let errorStatusCode = error.response.status

          if (errorStatusCode === 401) {
            setTimeout(() => {
              window.location.href = "/Signin"
              localStorage.removeItem('token');
              localStorage.clear();

              this.props.dispatch({
                type: "AUTH_USER_LOGOUT",
              })
            }, 500);
          } else {
            let errorBody = error.response.data.body
            swal({ text: errorBody.message, icon: 'error' })
          }

        } catch (error) {
          swal({ text: 'Error tidak terdefinisi!', icon: 'error' })
        }
      });
  }

  async function handleSubmit(event) {
    event.preventDefault()

    let errors = {};
    let isValid = true;

    if (form.nama_perusahaan == '') {
      errors['nama_perusahaan'] = "Masukkan nama perusahaan!"
      isValid = false
    }
    if (form.alamat_perusahaan == '') {
      errors['alamat_perusahaan'] = "Masukkan alamat perusahaan!"
      isValid = false
    }
    if (form.telepon_perusahaan_kode == '' || form.telepon_perusahaan == '') {
      errors['telepon_perusahaan'] = "Masukkan nomor telepon perusahaan!"
      isValid = false
    }
    // if (form.fax_perusahaan == '') {
    //   errors['fax_perusahaan'] = "Masukkan fax telepon perusahaan!"
    //   isValid = false
    // }
    if (form.npwp_perusahaan == '') {
      errors['npwp_perusahaan'] = "Masukkan nomor npwp perusahaan!"
      isValid = false
    }
    if (form.npwp_perusahaan_register == '') {
      errors['npwp_perusahaan_register'] = "Masukkan tanggal registrasi NPWP perusahaan!"
      isValid = false
    }

    setErrorMessage(errorMessage => ({
      ...errorMessage,
      ...errors
    }));

    if (isValid) {
      postData(form)
    } else {
      swal({ text: 'Periksa kembali data yang anda masukkan!', icon: 'error' })
    }
  }

  const onRefresh = () => {
    setErrorMessage({});
    setForm(initData)

    setSelectedDayNPWPReg(initData.npwp_perusahaan_register == '' ? null : {
      year: parseInt(dateFormat(initData.npwp_perusahaan_register, 'yyyy')),
      month: parseInt(dateFormat(initData.npwp_perusahaan_register, 'mm')),
      day: parseInt(dateFormat(initData.npwp_perusahaan_register, 'dd')),
    });
  }

  const [isEdit, setIsEdit] = useState(false)
  const handleEdit = () => {
    setIsEdit(true)
  }
  const handleCancelEdit = () => {
    setIsEdit(false)

    onRefresh()
  }

  const handleBack = () => {
    props.handlePagePrev()
  }

  return (
    <>
      <Container style={{ display: 'block' }}>
        <Form name='formDataPerusahaan' onSubmit={e => e.preventDefault()} encType="multipart/form-data" autoComplete='off'>
          <Row>
            <Col md={12}>
              <Header id="fonts" as='h6' color="blue" size='small'>
                <Header.Content>
                  DATA PERUSAHAAN
                </Header.Content>
              </Header>

              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>Nama Perusahaan</Col>
                <Col sm="8">
                  <Form.Control type="text" name="nama_perusahaan" value={form.nama_perusahaan} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                  <span style={{ color: "red", fontSize: 11 }}>{errorMessage["nama_perusahaan"]}</span>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>Alamat</Col>
                <Col sm="8">
                  <Form.Control type="text" name="alamat_perusahaan" value={form.alamat_perusahaan} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                  <span style={{ color: "red", fontSize: 11 }}>{errorMessage["alamat_perusahaan"]}</span>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>
                  Telepon<br />
                  <span style={{ fontSize: '11px' }}>(Contoh: +62 211234123)</span>
                </Col>
                <Col sm="8">
                  <Form.Group as={Row}>
                    <Col sm="2">
                      <Form.Control type="text" name="telepon_perusahaan_kode" value={form.telepon_perusahaan_kode} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                    </Col>
                    <Col sm="10">
                      <Form.Control type="text" name="telepon_perusahaan" value={form.telepon_perusahaan} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                    </Col>
                  </Form.Group>
                  <span style={{ color: "red", fontSize: 11 }}>{errorMessage["telepon_perusahaan"]}</span>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>
                  Fax.<br />
                  <span style={{ fontSize: '11px' }}>(Contoh: +62 211234123)</span>
                </Col>
                <Col sm="8">
                  <Form.Group as={Row}>
                    <Col sm="2">
                      <Form.Control type="text" name="fax_perusahaan_kode" value={form.fax_perusahaan_kode} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                    </Col>
                    <Col sm="10">
                      <Form.Control placeholder='Fax. (Optional)' type="text" name="fax_perusahaan" value={form.fax_perusahaan} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                    </Col>
                  </Form.Group>
                  <span style={{ color: "red", fontSize: 11 }}>{errorMessage["fax_perusahaan"]}</span>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>NPWP Perusahaan</Col>
                <Col sm="8">
                  <Form.Control type="text" name="npwp_perusahaan" value={form.npwp_perusahaan} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                  <span style={{ color: "red", fontSize: 11 }}>{errorMessage["npwp_perusahaan"]}</span>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>Tanggal Registrasi NPWP</Col>
                <Col sm="8">
                  <DatePicker
                    name='npwp_perusahaan_register'
                    value={selectedDayNPWPReg}
                    onChange={handleChangeNPWPReg}
                    renderInput={renderCustomInputNPWPReg}
                    shouldHighlightWeekends
                    maximumDate={maximumDate}
                  /><br />
                  <span style={{ color: "red", fontSize: 11 }}>{errorMessage["npwp_perusahaan_register"]}</span>
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className='vertical-line' />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div style={{ float: 'right' }}>
                <Button color='default' onClick={handleBack.bind(this)} disabled={loading}>
                  Kembali
                </Button>
                {actionForm == 'insert' ?
                  <Button className='btnSimpan' color='green' onClick={handleSubmit} disabled={loading}>
                    {loading && <Spinner animation="border" variant="default" size='sm' style={{ marginRight: "5px" }}/>}
                    {loading && <span>Loading..</span>}
                    {!loading && <span>Simpan & Lanjutkan</span>}
                  </Button>
                  : 
                  <>
                    {isEdit == false ? 
                      <>
                        <Button className='btnEdit' color='blue' onClick={handleEdit}>Edit</Button>
                        <Button className='btnNext' color='green' onClick={props.handlePageNext}>Selanjutnya</Button>
                      </>
                    : 
                      <>
                        <Button className='btnEdit' color='blue' onClick={handleCancelEdit}>Batal</Button>
                        {/* <Button className='btnNext' color='green' onClick={props.handlePageNext}>Selanjutnya</Button> */}
                        <Button className='btnSimpan' color='green' onClick={handleSubmit} disabled={loading}>
                          {loading && <Spinner animation="border" variant="default" size='sm' style={{ marginRight: "5px" }}/>}
                          {loading && <span>Loading..</span>}
                          {!loading && <span>Simpan & Lanjutkan</span>}
                        </Button>
                      </>
                    }
                  </>
                }
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  )
})

const DataBank = memo((props) => {

  const [actionForm, setActionForm] = useState(
    (props.tipePemodal == 'perusahaan' && props.userinfo.is_complete > 2) ? 'edit'  
    : (props.tipePemodal == 'pribadi' && props.userinfo.is_complete > 1) ? 'edit' 
    // : (props.tipePemodal == 'pribadiexpert' && props.userinfo.is_complete > 1) ? 'edit' 
    : 'insert'
  )

  const initData = {
    bank: props.userinfo.data_bank == null ? '' : props.userinfo.data_bank,
    no_rekening: props.userinfo.no_rek == null ? '' : props.userinfo.no_rek,
    nama_pemilik_rekening: props.userinfo.atas_nama_bank == null ? '' : props.userinfo.atas_nama_bank,
    cabang_bank: props.userinfo.cabang_bank == null ? '' : props.userinfo.cabang_bank,
  }

  const [form, setForm] = useState(initData);

  const [errorMessage, setErrorMessage] = useState({});

  const handleTextChange = event => {

    var index = event.target.name
    setErrorMessage(errorMessage => ({ ...errorMessage, [index]: "" }));

    if(index === 'no_rekening'){
      const re = /^[0-9\b]+$/;
      if (event.target.value != '' && !re.test(event.target.value)) {
        return
      }
      if(event.target.value.length > 30){
        return
      }
    }

    if((index === 'nama_pemilik_rekening') && (event.target.value.length > 50)) return
    if((index === 'cabang_bank') && (event.target.value.length > 100)) return

    let resultValue = event.target.value
    if(index === 'cabang_bank') {
      resultValue = event.target.value.toUpperCase()
    } 

    setForm({ ...form, [event.target.name]: resultValue })
  }

  const [loading, setLoading] = useState(false)

  const beforeUnloadListener = (event) => {
    event.preventDefault();
    event.returnValue = "";
    return ""
  }

  const postData = async (values) => {
    window.addEventListener("beforeunload", beforeUnloadListener);

    // console.log(JSON.stringify(values))
    // return

    values.post_to = 'bank'
    values.tipe_pemodal = props.tipePemodal

    let config = {
      headers: {
        'Authorization': localStorage.token,
        'content-type': 'application/json',
        'Access-Control-Allow-Credentials': true,
        'Access-Control-Allow-Methods': 'POST',
      },
      maxContentLength: 52428890,
      timeout: 10000000,
    }
    
    setLoading(true)

    axios.post(BASE_URL + "/customer/pemodal/lengkapidata_v2", values, config)
      .then((resp) => {

        setLoading(false)
        window.removeEventListener("beforeunload", beforeUnloadListener);

        swal({ text: resp.data.body.message, icon: 'success' })

        // var dataRes = resp.data.body.data
        // Object.assign(props.userinfo, dataRes)

        props.getUserpemodal()

        props.handlePageNext()
        window.scrollTo(0, 0)
        
      })
      .catch(error => {
        
        setLoading(false)
        window.removeEventListener("beforeunload", beforeUnloadListener);

        try {
          let errorStatusCode = error.response.status

          if (errorStatusCode === 401) {
            setTimeout(() => {
              window.location.href = "/Signin"
              localStorage.removeItem('token');
              localStorage.clear();

              this.props.dispatch({
                type: "AUTH_USER_LOGOUT",
              })
            }, 500);
          } else {
            let errorBody = error.response.data.body
            swal({ text: errorBody.message, icon: 'error' })
          }

        } catch (error) {
          swal({ text: 'Error tidak terdefinisi!', icon: 'error' })
        }
      });
  }

  async function handleSubmit(event) {
    event.preventDefault()
    
    // props.handlePageNext()
    // return

    let errors = {};
    let isValid = true;

    if (form.bank == '') {
      errors['bank'] = "Pilih Bank!"
      isValid = false
    }
    if (form.no_rekening == '') {
      errors['no_rekening'] = "Masukkan nomor rekening!"
      isValid = false
    }
    if (form.nama_pemilik_rekening == '') {
      errors['nama_pemilik_rekening'] = "Masukkan nama pemilik rekening!"
      isValid = false
    }
    if (form.cabang_bank == '') {
      errors['cabang_bank'] = "Masukkan cabang Bank!"
      isValid = false
    }

    setErrorMessage(errorMessage => ({
      ...errorMessage,
      ...errors
    }));

    if (isValid) {

      postData(form)

      // props.handlePageNext()

      // swal({ text: 'Submit Data', icon: 'success', closeOnClickOutside: false })
      //   .then((value) => {
      //     setTimeout(() => {
      //       window.location.reload(true);
      //     }, 0)
      //   });
    } else {
      swal({ text: 'Periksa kembali data yang anda masukkan!', icon: 'error' })
    }
  }

  const onRefresh = () => {
    setErrorMessage({});
    setForm(initData)
  }

  const [isEdit, setIsEdit] = useState(false)
  const handleEdit = () => {
    setIsEdit(true)
  }
  const handleCancelEdit = () => {
    setIsEdit(false)

    onRefresh()
  }

  const handleBack = () => {
    props.handlePagePrev()
  }

  return (
    <>

      <Container style={{ display: 'block' }}>
        <Form name='formDataBank' onSubmit={e => e.preventDefault()} encType="multipart/form-data" autoComplete='off'>
          <Row>
            <Col md={12}>
              <Header id="fonts" as='h6' color="blue" size='small'>
                <Header.Content>
                  DATA BANK
                </Header.Content>
              </Header>

              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>Nama Bank{/*<span style={{ color: 'red' }}>*</span>*/}</Col>
                <Col sm="8">
                  <Dropdown
                    name="bank"
                    value={form.bank}
                    onChange={(e, { value }) => {
                      form.bank = value
                      setErrorMessage(errorMessage => ({ ...errorMessage, ['bank']: "" }))
                    }}
                    placeholder='Pilih Bank' style={{ width: '100%' }}
                    closeOnChange search selection
                    options={props.dataBank.map(item => ({ key: item.id, text: item.nama_bank, value: item.nama_bank }))}
                    disabled={(actionForm == 'insert' || isEdit == true) ? false : true}
                  />
                  <span style={{ color: "red", fontSize: 11 }}>{errorMessage["bank"]}</span>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>No. Rekening{/*<span style={{ color: 'red' }}>*</span>*/}</Col>
                <Col sm="8">
                  <Form.Control type="text" name="no_rekening" value={form.no_rekening} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                  <span style={{ color: "red", fontSize: 11 }}>{errorMessage["no_rekening"]}</span>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>Nama Pemilik{/*<span style={{ color: 'red' }}>*</span>*/}</Col>
                <Col sm="8">
                  <Form.Control type="text" name="nama_pemilik_rekening" value={form.nama_pemilik_rekening} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                  <span style={{ color: "red", fontSize: 11 }}>{errorMessage["nama_pemilik_rekening"]}</span>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="4" style={{ color: 'gray' }}>Cabang{/*<span style={{ color: 'red' }}>*</span>*/}</Col>
                <Col sm="8">
                  <Form.Control type="text" name="cabang_bank" value={form.cabang_bank} onChange={handleTextChange} disabled={(actionForm == 'insert' || isEdit == true) ? false : true}/>
                  <span style={{ color: "red", fontSize: 11 }}>{errorMessage["cabang_bank"]}</span>
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className='vertical-line' />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div style={{ float: 'right' }}>
                <Button color='default' onClick={handleBack.bind(this)} disabled={loading}>
                  Kembali
                </Button>
                {actionForm == 'insert' ?
                  <Button className='btnSimpan' color='green' onClick={handleSubmit} disabled={loading}>
                    {loading && <Spinner animation="border" variant="default" size='sm' style={{ marginRight: "5px" }}/>}
                    {loading && <span>Loading..</span>}
                    {!loading && <span>Simpan & Lanjutkan</span>}
                  </Button>
                  : 
                  <>
                    {isEdit == false ? 
                      <>
                        <Button className='btnEdit' color='blue' onClick={handleEdit}>Edit</Button>
                        <Button className='btnNext' color='green' onClick={props.handlePageNext}>Selanjutnya</Button>
                      </>
                    : 
                      <>
                        <Button className='btnEdit' color='blue' onClick={handleCancelEdit}>Batal</Button>
                        <Button className='btnSimpan' color='green' onClick={handleSubmit} disabled={loading}>
                          {loading && <Spinner animation="border" variant="default" size='sm' style={{ marginRight: "5px" }}/>}
                          {loading && <span>Loading..</span>}
                          {!loading && <span>Simpan & Lanjutkan</span>}
                        </Button>
                      </>
                    }
                  </>
                }
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  )
})

const Dokumen = memo((props) => {

  const [actionForm, setActionForm] = useState(
    (props.tipePemodal == 'perusahaan' && props.userinfo.is_complete > 3) ? 'edit'  
    : (props.tipePemodal == 'pribadi' && props.userinfo.is_complete > 2) ? 'edit' 
    // : (props.tipePemodal == 'pribadiexpert' && props.userinfo.is_complete > 2) ? 'edit' 
    : 'insert'
  )

  const [form, setForm] = useState({
    foto_selfie_ktp: '',
    foto_ktp: '',
    foto_npwp: '',
    ktp_npwp: '',
    document1: [], // Akta Pendirian Perusahaan & Anggaran Dasar
    document2: '', // Anggaran Dasar Perubahan
    document3: '', // Company Profile, TDP/NIB, SIUP, NPWP
    document4: '', // Laporan Keuangan (3 Tahun Terakhir)
    document5: '', // Data mengenai bisnis dan proyeksi pendapatan
    buku_rekening: '',
  });

  const [file, setFile] = useState({
    file_upload: '',
    file_to: '',
  })

  // const [fileSrc, setFileSrc] = useState({
  //   foto_selfie_ktp: props.userinfo.foto_selfie_ktp == null ? '' : props.userinfo.foto_selfie_ktp,
  //   foto_ktp: props.userinfo.foto_ktp == null ? '' : props.userinfo.foto_ktp,
  //   foto_npwp: props.userinfo.foto_npwp == null ? '' : props.userinfo.foto_npwp,
  //   ktp_npwp: props.userinfo.ktp_npwp == null ? '' : props.userinfo.ktp_npwp,
  //   document1: props.userinfo.document1 == null ? '' : props.userinfo.document1,
  //   document2: props.userinfo.document2 == null ? '' : props.userinfo.document2,
  //   document3: props.userinfo.document3 == null ? '' : props.userinfo.document3,
  //   document4: props.userinfo.document4 == null ? '' : props.userinfo.document4,
  //   document5: props.userinfo.document5 == null ? '' : props.userinfo.document5,
  //   buku_rekening: props.userinfo.buku_rekening == null ? '' : props.userinfo.buku_rekening,
  // })

  const [fotoSelfieKtp, setFotoSelfieKtp] = useState(props.userinfo.face_verified == null ? '' : props.userinfo.face_verified)
  const [fotoKtp, setFotoKtp] = useState(props.userinfo.ocr_ktp_verified == null ? '' : props.userinfo.ocr_ktp_verified)
  const [fotoNpwp, setFotoNpwp] = useState(props.userinfo.ocr_npwp_verified == null ? '' : props.userinfo.ocr_npwp_verified)
  const [ktpNpwp, setKtpNpwp] = useState(props.userinfo.ktp_npwp == null ? '' : props.userinfo.ktp_npwp)
  const [bukuRekening, setBukuRekening] = useState(props.userinfo.buku_tabungan == null ? '' : props.userinfo.buku_tabungan)

  const [document1, setDocument1] = useState(props.userinfo.document1 == null ? '' : props.userinfo.document1)
  // const [document2, setDocument2] = useState(props.userinfo.document2 == null ? '' : props.userinfo.document2)
  const [document2, setDocument2] = useState(props.userinfo.document2)
  const [document3, setDocument3] = useState(props.userinfo.document3 == null ? '' : props.userinfo.document3)
  const [document4, setDocument4] = useState(props.userinfo.document4 == null ? '' : props.userinfo.document4)
  const [document5, setDocument5] = useState(props.userinfo.document5 == null ? '' : props.userinfo.document5)

  const [isTakeFoto, setTakeFoto] = useState(false)
  const [documentUpload, setDocumentUpload] = useState(null)
  const [showModalUpload, setShowModalUpload] = useState(false)
  const handleShowModalUpload = (param, isTake) => {

    if(param == 'document2'){
      setFile({
        file_upload: [],
        file_to: param,
      })
    } else {
      setFile({
        file_upload: '',
        file_to: param,
      })
    }

    setTakeFoto(isTake)
    setDocumentUpload(param)
    setShowModalUpload(true)
  }
  const handleCloseModalUpload = () => {
    setErrorMessage(errorMessage => ({ ...errorMessage, ['file_upload']: "" }));
    setFieldDocument2([''])
    setShowModalUpload(false)
  }

  const [documentShow, setDocumentShow] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const handleShowModal = (param) => {
    setDocumentShow(param)
    setShowModal(true)
  }
  const handleCloseModal = () => {
    setShowModal(false)
  }

  const [errorMessage, setErrorMessage] = useState({});

  const handleTextChange = event => {

    setForm({ ...form, [event.target.name]: event.target.value })

    var index = event.target.name
    setErrorMessage(errorMessage => ({ ...errorMessage, [index]: "" }));
  }

  const handleFileChange = event => {

    setFile({ ...file, [event.target.name]: event.target.value })

    var index = event.target.name
    setErrorMessage(errorMessage => ({ ...errorMessage, [index]: "" }));
  }

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const [loading, setLoading] = useState(false)
  const [loadingModalUpload, setLoadingModalUpload] = useState(false)

  const beforeUnloadListener = (event) => {
    event.preventDefault();
    event.returnValue = "";
    return ""
  }

  const postData = async (values, post_to) => {
    window.addEventListener("beforeunload", beforeUnloadListener);

    // values.post_to = 'document'
    values.post_to = post_to
    values.tipe_pemodal = props.tipePemodal

    var contentType = 'application/json'
    var contentData = values

    if(values.file_to == 'foto_selfie_ktp' || values.file_to == 'foto_ktp' || values.file_to == 'foto_npwp'){
    
      contentType = 'multipart/form-data'

      var formData = new FormData()
      formData.append('post_to', post_to);
      formData.append('file_to', values.file_to);
      formData.append('file_upload', values.file_upload, values.file_to + '.jpeg');
      // formData.append('file_upload', values.file_upload); //default name = blob
      formData.append('tipe_pemodal', props.tipePemodal);

      contentData = formData
    } 

    let config = {
      headers: {
        'Authorization': localStorage.token,
        'content-type': contentType,
        'Access-Control-Allow-Credentials': true,
        'Access-Control-Allow-Methods': 'POST',
      },
      maxContentLength: 52428890,
      timeout: 10000000,
    }
    
    if(post_to == 'document') {setLoading(true)}
    if(post_to == 'document_upload') (setLoadingModalUpload(true))

    axios.post(BASE_URL + "/customer/pemodal/lengkapidata_v2", contentData, config)
      .then((resp) => {

        window.removeEventListener("beforeunload", beforeUnloadListener);

        var dataRes = resp.data.body.data
        // Object.assign(props.userinfo, dataRes)

        // swal({ text: resp.data.body.message, icon: 'success' })
        // console.log(JSON.stringify(resp.data))

        if(post_to == 'document') {
          swal({text: resp.data.body.message, icon: 'success', closeOnClickOutside: false})
          .then((value) => {
            window.location.href = "/UserPemodal"
          });
          setLoading(false)
          // props.handlePageNext()
          window.scrollTo(0, 0)
        }
        if(post_to == 'document_upload') {

          swal({ text: resp.data.body.message, icon: 'success' })
          
          props.getUserpemodal()

          setLoadingModalUpload(false)
          setShowModalUpload(false)

          if(values.file_to == 'document2') {
            setDocument2(dataRes)
            setFieldDocument2([''])
            // console.log('FILEDATA: ' + JSON.stringify(dataRes))
          } else {
            // alert(dataRes.file +' | '+dataRes.type)

            if(dataRes.type == 'foto_selfie_ktp') {setFotoSelfieKtp(dataRes.file)}
            if(dataRes.type == 'foto_ktp') {setFotoKtp(dataRes.file)}
            if(dataRes.type == 'foto_npwp') {setFotoNpwp(dataRes.file)}
            // if(dataRes.type == 'ktp_npwp') {setKtpNpwp(dataRes.file)}
            if(dataRes.type == 'buku_tabungan') {setBukuRekening(dataRes.file)}

            if(dataRes.type == 'document1') {setDocument1(dataRes.file)}
            // if(dataRes.type == 'document2') {setDocument2(dataRes.file)}
            if(dataRes.type == 'document3') {setDocument3(dataRes.file)}
            if(dataRes.type == 'document4') {setDocument4(dataRes.file)}
            if(dataRes.type == 'document5') {setDocument5(dataRes.file)}
          }
        }
        
      })
      .catch(error => {
        
        if(post_to == 'document') {setLoading(false)}
        if(post_to == 'document_upload') (setLoadingModalUpload(false))

        window.removeEventListener("beforeunload", beforeUnloadListener);

        try {
          let errorStatusCode = error.response.status

          if (errorStatusCode === 401) {
            setTimeout(() => {
              window.location.href = "/Signin"
              localStorage.removeItem('token');
              localStorage.clear();

              this.props.dispatch({
                type: "AUTH_USER_LOGOUT",
              })
            }, 500);
          } else {
            let errorBody = error.response.data.body
            swal({ text: errorBody.message, icon: 'error' })
          }

        } catch (error) {
          swal({ text: 'Error tidak terdefinisi!', icon: 'error' })
        }
      });
  }

  // Upload to local seaweedFS instance
  const [isCaptureCamera, setIsCaptureCamera] = useState(false)
  const uploadImage = async files => {
    
    if(files != 'repeat') {
      setIsCaptureCamera(true)
      file.file_upload = files

    } else {
      setIsCaptureCamera(false)
      file.file_upload = ''
    }

    setErrorMessage({});

    // Connect to a seaweedfs instance
  };

  async function handleUpload(event) {
    event.preventDefault()

    let errors = {};
    let isValid = true;

    if (file.file_to == '') {
      errors['file_upload'] = "File upload belum ditentukan!"
      isValid = false
    }

    if (!isTakeFoto) {
      //File choosen
      var formField = document.querySelector('form[name="formDokumenModal"]')
      var fileUpload = null

      if(file.file_to != 'document2'){
        
        if(file.file_upload == ''){
          errors['file_upload'] = "Silahkan pilih file!"
          isValid = false
        } else {
          fileUpload = formField.querySelector('input[name="file_upload"]').files[0]
          if (fileUpload.size > 2048000) {
            errors['file_upload'] = "Maksimal file upload 2 MB!"
            isValid = false
          } else {
            if(file.file_to == 'foto_ktp' || file.file_to == 'foto_npwp'){
              file.file_upload = fileUpload
            } else {
              file.file_upload = await toBase64(fileUpload)
            }
          }
        }
        
      } else {

        var size = 0
        
        if(file.file_upload.length == 0){
          errors['file_upload'] = "Silahkan pilih file!"
          isValid = false
        } else {
          file.file_upload.map((element, index) => {
            if(file.file_upload[index] == '') {
              errors['file_upload'] = "Silahkan pilih file!"
              isValid = false
            } else {
              fileUpload = formField.querySelector('input[name="file_upload['+index+']"').files[0]
              size += fileUpload.size
            }
          })

          if(isValid){
            if (size > 2048000) {
              errors['file_upload'] = "Maksimal file upload 2 MB!"
              isValid = false
            } 
          }
        }
      }
    } else {
      //with capture web camera
      if(file.file_upload == ''){
        errors['file_upload'] = "Silahkan ambil gambar!"
        isValid = false
      } else {
        if(!isCaptureCamera){
          errors['file_upload'] = "Terjadi kesalahan saat mengambil gambar, silahkan ulangi!"
          isValid = false
        } else {
          if (file.file_upload.size > 2048000) {
            errors['file_upload'] = "Maksimal file upload 2 MB!"
            isValid = false
          }
        }
      }
    }

    setErrorMessage(errorMessage => ({
      ...errorMessage,
      ...errors
    }));

    if(isValid){
      postData(file, 'document_upload')
    }
  }

  async function handleSubmit(event) {
    event.preventDefault()

    swal({
      title: "Konfirmasi Kelengkapan Data",
      text: "Apakah data dan dokumen yang anda lengkapi sudah benar?",
      icon: "warning",
      buttons: ["Tidak", "Ya"],
      closeOnClickOutside: false 
    })
    .then((ok) => {
      if (ok) {
        postData(form, 'document')
      } 
    });
  }

  async function handleSubmit_old(event) {
    event.preventDefault()

    let errors = {};
    let isValid = true;

    if (form.foto_selfie_ktp == '') {
      errors['foto_selfie_ktp'] = "Sertakan foto selfie dengan ktp!"
      isValid = false
    }
    if (form.ktp_npwp == '') {
      errors['ktp_npwp'] = "Sertakan copy KTP dan NPWP!"
      isValid = false
    }

    if (props.tipePemodal == 'perusahaan') {
      if (form.document1 == '') {
        errors['document1'] = "Sertakan dokumen Akta dan Anggaran Dasar Perusahaan!"
        isValid = false
      }
      if (form.document2 == '') {
        errors['document2'] = "Sertakan dokumen Anggaran Dasar Perubahan!"
        isValid = false
      }
      if (form.document3 == '') {
        errors['document3'] = "Sertakan dokumen Company Profile, TDP/NIB, SIUP, NPWP!"
        isValid = false
      }
      if (form.document4 == '') {
        errors['document4'] = "Sertakan dokumen Laporan Keuangan (3 Tahun Terakhir)!"
        isValid = false
      }
      if (form.document5 == '') {
        errors['document5'] = "Sertakan dokumen Data mengenai bisnis dan proyeksi pendapatan!"
        isValid = false
      }
    }

    if (form.buku_rekening == '') {
      errors['buku_rekening'] = "Sertakan copy Rekening Bank!"
      isValid = false
    }

    var formField = document.querySelector('form[name="formDokumen"]')
    var fotoSelfieKTP = formField.querySelector('input[name="foto_selfie_ktp"]').files[0]
    var KtpNPWP = formField.querySelector('input[name="ktp_npwp"]').files[0]
    if (props.tipePemodal == 'perusahaan') {
      var document1 = formField.querySelector('input[name="document1"]').files[0]
      var document2 = formField.querySelector('input[name="document2"]').files[0]
      var document3 = formField.querySelector('input[name="document3"]').files[0]
      var document4 = formField.querySelector('input[name="document4"]').files[0]
      var document5 = formField.querySelector('input[name="document5"]').files[0]
    }
    var bukuRekening = formField.querySelector('input[name="buku_rekening"]').files[0]

    if (form.foto_selfie_ktp != '' && fotoSelfieKTP.size > 2048000) {
      errors['foto_ktp'] = "Maksimal file upload 2 MB!"
      isValid = false
    }
    if (form.ktp_npwp != '' && KtpNPWP.size > 2048000) {
      errors['ktp_npwp'] = "Maksimal file upload 2 MB!"
      isValid = false
    }

    if (props.tipePemodal == 'perusahaan') {
      if (form.document1 != '' && document1.size > 2048000) {
        errors['document1'] = "Maksimal file upload 2 MB!"
        isValid = false
      }
      if (form.document2 != '' && document2.size > 2048000) {
        errors['document2'] = "Maksimal file upload 2 MB!"
        isValid = false
      }
      if (form.document3 != '' && document3.size > 2048000) {
        errors['document3'] = "Maksimal file upload 2 MB!"
        isValid = false
      }
      if (form.document4 != '' && document4.size > 2048000) {
        errors['document4'] = "Maksimal file upload 2 MB!"
        isValid = false
      }
      if (form.document5 != '' && document5.size > 2048000) {
        errors['document5'] = "Maksimal file upload 2 MB!"
        isValid = false
      }
    }

    if (form.buku_rekening != '' && bukuRekening.size > 2048000) {
      errors['buku_rekening'] = "Maksimal file upload 2 MB!"
      isValid = false
    }

    setErrorMessage(errorMessage => ({
      ...errorMessage,
      ...errors
    }));


    if (isValid) {
      form.foto_selfie_ktp = await toBase64(fotoSelfieKTP)
      form.ktp_npwp = await toBase64(KtpNPWP)
      form.buku_rekening = await toBase64(bukuRekening)
      if (props.tipePemodal == 'perusahaan') {
        form.document1 = await toBase64(document1)
        form.document2 = await toBase64(document2)
        form.document3 = await toBase64(document3)
        form.document4 = await toBase64(document4)
        form.document5 = await toBase64(document5)
      }

      // console.log(JSON.stringify(form))

      postData(form)

    } else {
      swal({ text: 'Periksa kembali data yang anda masukkan!', icon: 'error' })
    }
  }

  const onRefresh = () => {
    setErrorMessage({});
  }

  const [isEdit, setIsEdit] = useState(false)
  const handleEdit = () => {
    setIsEdit(true)
  }
  const handleCancelEdit = () => {
    setIsEdit(false)

    onRefresh()
  }

  const handleBack = () => {
    props.handlePagePrev()
  }

  const [fieldDocument2, setFieldDocument2] = useState([''])

  const addFieldDocument2 = () => {
    setFieldDocument2([...fieldDocument2, ''])
  }

  const removeFieldDocument2 = (i) => {
    const rows = [...fieldDocument2];
    rows.splice(i, 1);
    setFieldDocument2(rows);
  }

  const handleChangeFieldDocument2 = async (event, index) => {

    var formField = document.querySelector('form[name="formDokumenModal"]')
    var fileUpload = formField.querySelector('input[name="file_upload['+index+']"').files[0]
    if(fileUpload.size > 2048000) {
      var msgErr = "File " + fileUpload.name + " melebihi 2 MB"
      setErrorMessage(errorMessage => ({ ...errorMessage, ['file_upload']: msgErr }));
      event.target.value = ''
    } else {

      var strBase64 = await toBase64(fileUpload)

      fieldDocument2[index] = strBase64
      setFile({file_to: 'document2', file_upload: fieldDocument2})
      setErrorMessage(errorMessage => ({ ...errorMessage, ['file_upload']: "" }));
    }
  }

  return (
    <>
      <Container style={{ display: 'block' }}>
        <Form name='formDokumen' onSubmit={e => e.preventDefault()} encType="multipart/form-data" autoComplete='off'>
          {/* <Row>
            <Col md={12}>
              <Header id="fonts" as='h6' color="blue" size='small'>
                <Header.Content>DOKUMEN PRIBADI</Header.Content>
              </Header>

              <Form.Group as={Row}>
                <Col sm="5" style={{ color: 'gray' }}>
                  Foto Selfie<span style={{ color: 'red' }}>*</span><br />
                  <span style={{ fontSize: '11px' }}>(Format: jpg, jpeg, png. Maksimal 2 MB.)</span>
                </Col>
                {(actionForm == 'insert' || isEdit == true) &&
                  <Col sm="5">
                    {fotoSelfieKtp == '' && <Button color='default' onClick={handleShowModalUpload.bind(this, 'foto_selfie_ktp', true)} >Ambil Gambar</Button>}
                  </Col>
                }
                {(actionForm == 'edit' || fotoSelfieKtp != '') &&
                  <Col sm="2">
                    {fotoSelfieKtp == '1' && <><Icon name="check" style={{color: '#28a745'}}/><span style={{color: '#28a745'}}>Lulus</span></> }
                    {fotoSelfieKtp == '0' && <><Icon name="delete" style={{color: 'red'}}/><span style={{color: 'red'}}>Tidak lulus</span></> }
                  </Col>
                }
              </Form.Group>

              <Form.Group as={Row}>
                <Col sm="5" style={{ color: 'gray' }}>
                  Foto KTP<span style={{ color: 'red' }}>*</span><br />
                  <span style={{ fontSize: '11px' }}>(Format: jpg, jpeg, png. Maksimal 2 MB.)</span>
                </Col>
                {(actionForm == 'insert' || isEdit == true) &&
                  <Col sm="5">
                    {fotoKtp == '' && 
                      <>
                        <Button color='default' onClick={handleShowModalUpload.bind(this, 'foto_ktp', true)} >Ambil Gambar</Button>
                        <Button color='default' onClick={handleShowModalUpload.bind(this, 'foto_ktp', false)} >Choose File</Button>
                      </>
                    }
                  </Col>
                }
                {(actionForm == 'edit' || fotoKtp != '') &&
                  <Col sm="2">
                    {fotoKtp == '1' && <><Icon name="check" style={{color: '#28a745'}}/><span style={{color: '#28a745'}}>Lulus</span></> }
                    {fotoKtp == '0' && <><Icon name="delete" style={{color: 'red'}}/><span style={{color: 'red'}}>Tidak lulus</span></> }
                  </Col>
                }
              </Form.Group>

              <Form.Group as={Row}>
                <Col sm="5" style={{ color: 'gray' }}>
                  Foto NPWP<span style={{ color: 'red' }}>*</span><br />
                  <span style={{ fontSize: '11px' }}>(Format: jpg, jpeg, png. Maksimal 2 MB.)</span>
                </Col>
                {(actionForm == 'insert' || isEdit == true) &&
                  <Col sm="5">
                    {fotoNpwp == '' && 
                      <>
                        <Button color='default' onClick={handleShowModalUpload.bind(this, 'foto_npwp', true)} >Ambil Gambar</Button>
                        <Button color='default' onClick={handleShowModalUpload.bind(this, 'foto_npwp', false)} >Choose File</Button>
                      </>
                    }
                  </Col>
                }
                {(actionForm == 'edit' || fotoNpwp != '') &&
                  <Col sm="2">
                    {fotoNpwp == '1' && <><Icon name="check" style={{color: '#28a745'}}/><span style={{color: '#28a745'}}>Lulus</span></> }
                    {fotoNpwp == '0' && <><Icon name="delete" style={{color: 'red'}}/><span style={{color: 'red'}}>Tidak lulus</span></> }
                  </Col>
                }
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className='vertical-line' />
            </Col>
          </Row> */}
          {props.tipePemodal == 'perusahaan' && 
            <>
            <Row>
              <Col md={12}>
                <Header id="fonts" as='h6' color="blue" size='small'>
                  <Header.Content>
                    DOKUMEN PERUSAHAAN
                  </Header.Content>
                </Header>

                <Form.Group as={Row}>
                  <Col sm="5" style={{ color: 'gray' }}>
                    Akta Pendirian Perusahaan & Anggaran Dasar<br />
                    <span style={{ fontSize: '11px' }}>(Format: pdf. Maksimal 2 MB.)</span>
                  </Col>
                  {(actionForm == 'insert' || isEdit == true) &&
                    <Col sm="5">
                      <Button color='default' onClick={handleShowModalUpload.bind(this, 'document1', false)} >Choose File</Button>
                    </Col>
                  }
                  {(actionForm == 'edit' || document1 != '') &&
                    <Col sm="2">
                      <a href='#' onClick={handleShowModal.bind(this, 3)}>Tampilkan</a>
                    </Col>
                  }  
                </Form.Group>

                {/* <div className='vertical-line' /> */}

                <Form.Group as={Row}>
                  <Col sm="5" style={{ color: 'gray' }}>
                    Anggaran Dasar Perubahan<br />
                    <span style={{ fontSize: '11px' }}>(Format: pdf. Maksimal 2 MB.)</span>
                  </Col>
                  {(actionForm == 'insert' || isEdit == true) &&
                    <Col sm="5">
                      <Button color='default' onClick={handleShowModalUpload.bind(this, 'document2', false)} >Choose File</Button>
                    </Col>
                  }
                  {(actionForm == 'edit' || (document2.length > 0)) &&
                    <Col sm="2">
                      <a href='#' onClick={handleShowModal.bind(this, 4)}>Tampilkan</a>
                    </Col>
                  }  
                </Form.Group>

                {/* <div className='vertical-line' /> */}

                <Form.Group as={Row}>
                  <Col sm="5" style={{ color: 'gray' }}>
                    Company Profile, TDP/NIB, SIUP, NPWP<br />
                    <span style={{ fontSize: '11px' }}>(Format: pdf. Maksimal 2 MB.)</span>
                  </Col>
                  {(actionForm == 'insert' || isEdit == true) &&
                    <Col sm="5">
                      <Button color='default' onClick={handleShowModalUpload.bind(this, 'document3', false)} >Choose File</Button>
                    </Col>
                  }
                  {(actionForm == 'edit' || document3 != '') &&
                  <Col sm="2">
                      <a href='#' onClick={handleShowModal.bind(this, 5)}>Tampilkan</a>
                    </Col>
                  }
                </Form.Group>

                {/* <div className='vertical-line' /> */}

                <Form.Group as={Row}>
                  <Col sm="5" style={{ color: 'gray' }}>
                    Laporan Keuangan (3 Tahun Terakhir)<br />
                    <span style={{ fontSize: '11px' }}>(Format: pdf. Maksimal 2 MB.)</span>
                  </Col>
                  {(actionForm == 'insert' || isEdit == true) &&
                    <Col sm="5">
                      <Button color='default' onClick={handleShowModalUpload.bind(this, 'document4', false)} >Choose File</Button>
                    </Col>
                  }
                  {(actionForm == 'edit' || document4 != '') &&
                    <Col sm="2">
                      <a href='#' onClick={handleShowModal.bind(this, 6)}>Tampilkan</a>
                    </Col>
                  }  
                </Form.Group>

                {/* <div className='vertical-line' /> */}

                <Form.Group as={Row}>
                  <Col sm="5" style={{ color: 'gray' }}>
                    Data mengenai bisnis dan proyeksi pendapatan<br />
                    <span style={{ fontSize: '11px' }}>(Format: pdf. Maksimal 2 MB.)</span>
                  </Col>
                  {(actionForm == 'insert' || isEdit == true) &&
                    <Col sm="5">
                      <Button color='default' onClick={handleShowModalUpload.bind(this, 'document5', false)} >Choose File</Button>
                    </Col>
                  }
                  {(actionForm == 'edit' || document5 != '') &&
                    <Col sm="2">
                      <a href='#' onClick={handleShowModal.bind(this, 7)}>Tampilkan</a>
                    </Col>
                  }  
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className='vertical-line' />
              </Col>
            </Row>
            </>
          }
          <Row>
            <Col md={12}>
              <Header id="fonts" as='h6' color="blue" size='small'>
                <Header.Content>
                  DOKUMEN BANK
                </Header.Content>
              </Header>

              <Form.Group as={Row}>
                <Col sm="5" style={{ color: 'gray' }}>
                  Copy Buku Rekening{/*<span style={{ color: 'red' }}>*</span>*/}<br />
                  <span style={{ fontSize: '11px' }}>(Halaman depan buku rekening, format: pdf. Maksimal 2 MB.)</span>
                </Col>
                {(actionForm == 'insert' || isEdit == true) &&
                  <Col sm="5">
                    <Button color='default' onClick={handleShowModalUpload.bind(this, 'buku_rekening', false)} >Choose File</Button>
                  </Col>
                }
                {(actionForm == 'edit' || bukuRekening != '') &&
                <Col sm="2">
                    <a href='#' onClick={handleShowModal.bind(this, 8)}>Tampilkan</a>
                  </Col>
                }
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className='vertical-line' />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div style={{ float: 'right' }}>
                <Button color='default' onClick={handleBack.bind(this)} disabled={loading}>
                  Kembali
                </Button>
                {actionForm == 'insert' ?
                  <Button className='btnSimpan' color='green' onClick={handleSubmit} disabled={loading}>
                    {loading && <Spinner animation="border" variant="default" size='sm' style={{ marginRight: "5px" }}/>}
                    {loading && <span>Loading..</span>}
                    {/* {!loading && <span>Simpan & Lanjutkan</span>} */}
                    {!loading && <span>Konfirmasi</span>}
                  </Button>
                  : 
                  <>
                    {isEdit == false ? 
                      <>
                        <Button className='btnEdit' color='blue' onClick={handleEdit}>Edit</Button>
                        <Button className='btnNext' color='green' onClick={props.handlePageNext}>Selanjutnya</Button>
                      </>
                    : 
                      <>
                        <Button className='btnEdit' color='blue' onClick={handleCancelEdit}>Batal</Button>
                        {/* <Button className='btnNext' color='green' onClick={props.handlePageNext}>Selanjutnya</Button> */}
                        <Button className='btnSimpan' color='green' onClick={handleSubmit} disabled={loading}>
                          {loading && <Spinner animation="border" variant="default" size='sm' style={{ marginRight: "5px" }}/>}
                          {loading && <span>Loading..</span>}
                          {/* {!loading && <span>Simpan & Lanjutkan</span>} */}
                          {!loading && <span>Konfirmasi</span>}
                        </Button>
                      </>
                    }
                  </>
                }
              </div>
            </Col>
          </Row>
        </Form>
      </Container>

      <Modal size="lg" style={{ borderRadius: "30px" }} show={showModal} onHide={handleCloseModal} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            {/* {documentShow == 1 && <span>Foto Selfie dengan KTP</span>} */}
            {documentShow == 1 && <span>Foto Selfie</span>}
            {documentShow == 2 && <span>Copy KTP, NPWP</span>}
            {documentShow == 3 && <span>Akta Pendirian Perusahaan & Anggaran Dasar</span>}
            {documentShow == 4 && <span>Anggaran Dasar Perubahan</span>}
            {documentShow == 5 && <span>Company Profile, TDP/NIB, SIUP, NPWP</span>}
            {documentShow == 6 && <span>Laporan Keuangan (3 Tahun Terakhir)</span>}
            {documentShow == 7 && <span>Data mengenai bisnis dan proyeksi pendapatan</span>}
            {documentShow == 8 && <span>Copy Buku Rekening</span>}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {documentShow == 1 && <Image src={fileSrc.foto_selfie_ktp}/>}
          {documentShow == 2 && <iframe src={fileSrc.ktp_npwp} height="1000" width="775"/>}
          {documentShow == 3 && <iframe src={fileSrc.document1} height="1000" width="775"/>}
          {documentShow == 4 && <iframe src={fileSrc.document2} height="1000" width="775"/>}
          {documentShow == 5 && <iframe src={fileSrc.document3} height="1000" width="775"/>}
          {documentShow == 6 && <iframe src={fileSrc.document4} height="1000" width="775"/>}
          {documentShow == 7 && <iframe src={fileSrc.document5} height="1000" width="775"/>}
          {documentShow == 8 && <iframe src={fileSrc.buku_rekening} height="1000" width="775"/>} */}

          {documentShow == 1 && <Image src={fotoSelfieKtp}/>}
          {documentShow == 2 && <iframe src={ktpNpwp} height="1000" width="775"/>}
          {documentShow == 3 && <iframe src={document1} height="1000" width="775"/>}
          {/* {documentShow == 4 && <iframe src={document2} height="1000" width="775"/>} */}
          {documentShow == 5 && <iframe src={document3} height="1000" width="775"/>}
          {documentShow == 6 && <iframe src={document4} height="1000" width="775"/>}
          {documentShow == 7 && <iframe src={document5} height="1000" width="775"/>}
          {documentShow == 8 && <iframe src={bukuRekening} height="1000" width="775"/>}

          {
          documentShow == 4 && 
            <>
              {
                document2.length > 1 ? 
                <>
                  <Tabs defaultActiveKey={0}>
                    {document2.map((e, index) => 
                      <Tab eventKey={index} title={"Perubahan " + (index + 1)}>
                        <iframe src={document2[index]} height="1000" width="775"/>
                      </Tab>
                    )}
                  </Tabs>
                </>
                : <iframe src={document2[0]} height="1000" width="775"/>
              }
            </>
          }
        </Modal.Body>
      </Modal>

      <Modal size="lg" style={{ borderRadius: "30px" }} show={showModalUpload} onHide={false} animation={false}>
        <Form name='formDokumenModal' onSubmit={e => e.preventDefault()} encType="multipart/form-data" autoComplete='off'>
          <Modal.Body>
            {/* {documentUpload == 'foto_selfie_ktp' && <strong>Upload Foto Selfie dengan KTP</strong>} */}
            {documentUpload == 'foto_selfie_ktp' && <strong>Upload Foto Selfie</strong>}
            {documentUpload == 'foto_ktp' && <strong>Upload Foto KTP</strong>}
            {documentUpload == 'foto_npwp' && <strong>Upload Foto NPWP</strong>}
            {documentUpload == 'ktp_npwp' && <strong>Upload Copy KTP, NPWP</strong>}
            {documentUpload == 'document1' && <strong>Upload Akta Pendirian Perusahaan & Anggaran Dasar</strong>}
            {documentUpload == 'document2' && <strong>Upload Anggaran Dasar Perubahan</strong>}
            {documentUpload == 'document3' && <strong>Upload Company Profile, TDP/NIB, SIUP, NPWP</strong>}
            {documentUpload == 'document4' && <strong>Upload Laporan Keuangan (3 Tahun Terakhir)</strong>}
            {documentUpload == 'document5' && <strong>Upload Data mengenai bisnis dan proyeksi pendapatan</strong>}
            {documentUpload == 'buku_rekening' && <strong>Upload Copy Buku Rekening</strong>}
            <br/>
            {(isTakeFoto == true) ? 
              <>
                {/* <h1>Image capture test</h1> */}
                <p>Capture foto dari web camera</p>
                <CameraFeed sendFile={uploadImage}/>
                <br/>
                <span style={{ color: "red", fontSize: 11 }}>{errorMessage["file_upload"]}</span>
              </>
              :
              <>
                <span style={{ fontSize: '11px' }}>
                  {
                    documentUpload == 'foto_selfie_ktp' ? '(Format: jpg, jpeg, png. Maksimal 2 MB.)' 
                    : documentUpload == 'foto_ktp' ? '(Format: jpg, jpeg, png. Maksimal 2 MB.)' 
                    : documentUpload == 'foto_npwp' ? '(Format: jpg, jpeg, png. Maksimal 2 MB.)'
                    : '(Format: pdf. Maksimal 2 MB.)'
                  }
                </span>
                <p/>

                {/* <Form.Control type="file" name="file_upload" onChange={handleFileChange}
                  accept={
                    documentUpload == 'foto_selfie_ktp' ? "image/jpg, image/jpeg, image/png" 
                    : documentUpload == 'foto_ktp' ? "image/jpg, image/jpeg, image/png" 
                    : documentUpload == 'foto_npwp' ? "image/jpg, image/jpeg, image/png" 
                    : "application/pdf"}/> */}

                { 
                  documentUpload == 'document2' ? 
                    <>
                      {fieldDocument2.map((element, index) =>  
                        <div className='row' style={{marginBottom: '5px'}}>
                          <Form.Control className='col col-sm-6' type="file" name={"file_upload["+index+"]"}
                            onChange={(event)=>handleChangeFieldDocument2(event, index)} accept={"application/pdf"} />
                          {
                            index > 0 &&
                            <Icon className='col col-sm-1' name='trash alternate outline' 
                              onClick={()=> removeFieldDocument2(index)}
                              style={{marginLeft: '20px', fontSize: '16px', color: '#c13b2a', cursor: 'pointer'}}/>
                          }
                        </div>
                      )}  
                      <Button style={{marginTop: '5px'}} className='mini' onClick={()=> addFieldDocument2()}>Tambah File</Button>
                    </>
                  :
                    <Form.Control type="file" name="file_upload" onChange={handleFileChange}
                      accept={
                        documentUpload == 'foto_selfie_ktp' ? "image/jpg, image/jpeg, image/png" 
                        : documentUpload == 'foto_ktp' ? "image/jpg, image/jpeg, image/png" 
                        : documentUpload == 'foto_npwp' ? "image/jpg, image/jpeg, image/png" 
                        : "application/pdf"} 
                    />
                }
                
                <br />
                <span style={{ color: "red", fontSize: 11 }}>{errorMessage["file_upload"]}</span>
              </>
            }
            
          </Modal.Body>
          <Modal.Footer>
            <Button color='blue' onClick={handleCloseModalUpload}>Close</Button>
            {/* <Button color='green' onClick={handleUpload}>Upload</Button> */}
            <Button color='green' onClick={handleUpload} disabled={loadingModalUpload}>
              {loadingModalUpload && <Spinner animation="border" variant="default" size='sm' style={{ marginRight: "5px" }}/>}
              {loadingModalUpload && <span>Loading..</span>}
              {!loadingModalUpload && <span>Upload</span>}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
})

const Konfirmasi = memo((props) => {

  const [form, setForm] = useState({
    type: '', // Badan hukum / pribadi
  }); 

  const [loading, setLoading] = useState(false)

  const beforeUnloadListener = (event) => {
    event.preventDefault();
    event.returnValue = "";
    return ""
  }

  const postData = async (values) => {
    window.addEventListener("beforeunload", beforeUnloadListener);

    // console.log(JSON.stringify(values))
    // return

    values.post_to = 'konfirmasi'
    values.tipe_pemodal = props.tipePemodal

    let config = {
      headers: {
        'Authorization': localStorage.token,
        'content-type': 'application/json',
        'Access-Control-Allow-Credentials': true,
        'Access-Control-Allow-Methods': 'POST',
      },
      maxContentLength: 52428890,
      timeout: 10000000,
    }
    
    setLoading(true)

    axios.post(BASE_URL + "/customer/pemodal/lengkapidata_v2", values, config)
      .then((resp) => {

        setLoading(false)
        window.removeEventListener("beforeunload", beforeUnloadListener);

        // swal({ text: resp.data.body.message, icon: 'success' })
        swal({text: resp.data.body.message, icon: 'success', closeOnClickOutside: false})
          .then((value) => {
            window.location.href = "/Profilepemodal"
          });
      })
      .catch(error => {
        
        setLoading(false)
        window.removeEventListener("beforeunload", beforeUnloadListener);

        try {
          let errorStatusCode = error.response.status

          if (errorStatusCode === 401) {
            setTimeout(() => {
              window.location.href = "/Signin"
              localStorage.removeItem('token');
              localStorage.clear();

              this.props.dispatch({
                type: "AUTH_USER_LOGOUT",
              })
            }, 500);
          } else {
            let errorBody = error.response.data.body
            swal({ text: errorBody.message, icon: 'error' })
          }

        } catch (error) {
          swal({ text: 'Error tidak terdefinisi!', icon: 'error' })
        }
      });
  }

  async function handleSubmit(event) {
    postData(form)
  }

  const handleBack = () => {
    props.handlePagePrev()
  }

  return (
    <>

      <Container style={{ display: 'block' }}>
        <Form name='formKonfirmasi' onSubmit={e => e.preventDefault()} encType="multipart/form-data" autoComplete='off'>
          <Row>
            <Col md={12} style={{ textAlign: 'center' }}>
              <div style={{ marginTop: 50 }}>
                Konfirmasikan data anda untuk proses verifikasi.
              </div>
              <div style={{ marginTop: 30, marginBottom: 50 }}>
                <Button color='default' onClick={handleBack.bind(this)} disabled={loading}>
                  Kembali
                </Button>
                <Button className='btnSimpan' color='green' onClick={handleSubmit} disabled={loading}>
                  {loading && <Spinner animation="border" variant="default" size='sm' style={{ marginRight: "5px" }}/>}
                  {loading && <span>Loading..</span>}
                  {!loading && <span>Konfirmasi</span>}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  )
})

function mapStateToProps(state) {
  return {
    getUserpemodal: state.userReducer.getUserpemodal,
    auth: state.authReducer.authData
  }
}

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(LengkapiDataPemodal);