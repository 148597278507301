import React from 'react';
import topbar from 'topbar';
import { connect } from "react-redux";
import { Row, Col, Card, Button, Container, CardGroup, Alert, Form, Modal } from 'react-bootstrap';
import { createNewUserpengelola } from "../../../actions/register.actions";
import Logo from "../../../assets/img/logo_kerjasama_vertical.png";
import $ from 'jquery'
import "../../../App.scss"
import "../../../AppMobile.scss"
import axios from "axios"
import swal from 'sweetalert';
import { Icon } from 'semantic-ui-react'

class DaftarPengelola extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            fields: {},
            response: {},
            errors: {},
            alertLogin: '', alertErrorLogin: '',
            showSuccessLogin: false, showErrorLogin: false,
            blockedIP: false, errorSum: 0, ipAddr: '',
            showModal: false, isModalShow: 0,
            isChecked: false, 
            isChecklistTerm: false, isChecklistPrivacy: false
        }

    }

    componentDidMount() {
        topbar.config({
            autoRun: false,
            barThickness: 5,
            barColors: {
                '0': 'rgba(0, 199, 80, 1)',
                '.5': 'rgba(255, 255, 255, 1)',
                '.8': 'rgba(0, 199, 80, 1)',
                '1.0': 'rgba(255, 255, 255, 1)'
            },
            shadowBlur: 5,
            shadowColor: 'rgba(3, 60, 133, 1)',
            className: 'topbar',
        })
        topbar.show();
        (function step() {
            setTimeout(function () {
                if (topbar.progress('+.01') < 1) step()
            }, 16)
        })()

        setTimeout(() => {
            topbar.hide();
        }, 4000);

        this.getIPAddress()
        // localStorage.clear()
        // console.log(localStorage)
    }

    getIPAddress() {
        axios.get("https://jsonip.com/",
            axios.defaults.headers.common['Accept'] = 'application/json'
        )
            .then((resp) => {
                this.setState({
                    ipAddr: resp.data.ip
                })
            })
            .catch((resp) => {

            });
    }

    daftarUser = async (values) => {

        try {
            // $(".daftar").attr('disabled', 'true');
            $(".daftar").html('Loading ...');
            $(".alertSuccessDaftar").attr('style', '')

            var date = new Date();
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var day = date.getDate();
            var hours = date.getHours();
            var minutes = date.getMinutes();

            var btoaIPLog = window.btoa('iPAddressForLogs' + year + "-" + month + "-" + day + " " + hours + ":" + minutes);
            values[btoaIPLog] = window.btoa(this.state.ipAddr)

            const response = await this.props.dispatch(createNewUserpengelola(values));
            
            if (!response.responseBody.success) {
                //err
                // $(".daftar").attr('disabled', 'false');
                $(".daftar").html('Daftar');
                $(".alertSuccessDaftar").attr('style', 'display:none')
                // this.setState({
                //     alertErrorLogin: response.responseBody.message,
                //     showErrorLogin: true,
                // });
                // setTimeout(() => {
                //     this.setState({
                //         showErrorLogin: false,
                //         errors: {},
                //         alertErrorLogin: ''
                //     });
                // }, 13000);

                if(this.state.isChecklistTerm && this.state.isChecklistPrivacy){
                    $(".daftar").attr('disabled', false)
                }

                var htmlFormat = document.createElement("div");
                htmlFormat.innerHTML = response.responseBody.body.message;

                swal({
                    content: htmlFormat,
                    icon: 'error',
                    closeOnClickOutside: false
                }).then((value) => {});
            } else {
                //success
                // var dt = new Date();
                // dt.setHours(dt.getHours() + 1);

                // localStorage.setItem('ip_address', this.state.ipAddr)
                // localStorage.setItem('time_regist_delay', dt)

                $(".daftar").attr('disabled', 'false');
                $(".daftar").html('Daftar');
                $(".alertSuccessDaftar").attr('style', 'display:none')

                // alert( response.responseBody.message);
                // const { history } = this.props;
                // window.setTimeout(history.push('/Signin'),2000);

                const { history } = this.props;

                if(this.state.isChecklistTerm && this.state.isChecklistPrivacy){
                    $(".daftar").attr('disabled', false)
                }

                var htmlFormat = document.createElement("div");
                htmlFormat.innerHTML = response.responseBody.body.message;

                swal({
                    content: htmlFormat,
                    icon: 'success',
                    closeOnClickOutside: false
                }).then((value) => {
                    window.setTimeout(history.push('/Signin'), 2000);
                });
            }

        } catch (error) {
            // $(".daftar").attr('disabled', 'false');
            $(".daftar").html('Daftar');
            $(".alertSuccessDaftar").attr('style', 'display:none')

            if(this.state.isChecklistTerm && this.state.isChecklistPrivacy){
                $(".daftar").attr('disabled', false)
            }

            try{
                var htmlFormat = document.createElement("div");
                htmlFormat.innerHTML = error.responseBody.body.message;
                swal({ content: htmlFormat, icon: 'error' })
            } catch {
                var htmlFormat = document.createElement("div");
                htmlFormat.innerHTML = 'Kesalahan tidak terdefinisi'
                swal({ content: htmlFormat, icon: 'error' })
            }
        }

    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //Name
        if (!fields["nama_depan"]) {
            formIsValid = false;
            errors["nama_depan"] = "Nama depan harus diisi";
        }

        //Email
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Email harus diisi";
        }

        if (typeof fields["email"] !== "undefined") {
            let lastAtPos = fields["email"].lastIndexOf('@');
            let lastDotPos = fields["email"].lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
                formIsValid = false;
                errors["email"] = "Email tidak valid";
            }
        }
        //No Telphone
        if (!fields["no_hp_kode"]) {
            formIsValid = false;
            errors["no_hp"] = "Kode Area Handphone harus diisi";
        }
        if (!fields["no_hp"]) {
            formIsValid = false;
            errors["no_hp"] = "Nomor Handphone harus diisi";
        }

        //Password
        if (!fields["password"]) {
            formIsValid = false;
            errors["password"] = "Password harus diisi";
        }
        //Refeat Password
        if (!fields["re_password"]) {
            formIsValid = false;
            errors["re_password"] = "Ketik ulang password";
        }

        if (typeof fields["password"] !== "undefined") {
            if (!fields["password"].match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/)) {
                formIsValid = false;
                // errors["password"] = "8 to 15 characters, one lowercase letter, one uppercase letter, one numeric digit, and one special character";
                errors["password"] = "Masukkan 8 sampai dengan 15 karakter (satu huruf kecil, satu huruf besar, satu angka numerik, dan satu karakter khusus)"
            }
        }

        if (fields["re_password"] != fields["password"]) {
            formIsValid = false;
            errors["re_password"] = "Konfirmasi password tidak sesuai";
        }


        this.setState({ errors: errors });
        return formIsValid;
    }

    onSubmit = (event) => {
        event.preventDefault();
        if (this.handleValidation()) {
            let data_kirim = this.state.fields;
            // data_kirim.no_hp = data_kirim.no_hp;

            // console.log("SUBMIT" + JSON.stringify(data_kirim))
            // return

            this.daftarUser(this.state.fields); // Force submit, tanpa validasi register berkali-kali
            return;
            
            /** Validasi untuk handling robot >> atau percobaan register berkali-kali */
            var nw = new Date();
            var errLog = localStorage.getItem('time_regist_delay');
            var errIp = localStorage.getItem('ip_address');
            var tmlog = new Date(errLog)

            if (tmlog.getTime() > nw.getTime() && errIp === this.state.ipAddr) {
                var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                var hr = tmlog.getHours();
                var min = tmlog.getMinutes();
                if (min < 10) {
                    min = "0" + min;
                }
                var ampm = "am";
                if (hr > 12) {
                    hr -= 12;
                    ampm = "pm";
                }
                var date = tmlog.getDate();
                var month = months[tmlog.getMonth()];
                var year = tmlog.getFullYear();

                this.setState({
                    alertErrorLogin: 'Anda baru mendaftar sebelumnya, silahkan tunggu sampai waktu \n' + date + " " + month + " " + year + " " + hr + ":" + min + ampm,
                    showErrorLogin: true,
                });
                setTimeout(() => {
                    this.setState({
                        showErrorLogin: false,
                        errors: {},
                        alertErrorLogin: ''
                    });
                }, 13000);
            } else {
                // var dt  = new Date();
                // dt.setHours(dt.getHours() + 1);

                // localStorage.setItem('ip_address', this.state.ipAddr)
                // localStorage.setItem('time_regist_delay', dt)
                this.daftarUser(this.state.fields);
            }

        } else {
           
            swal({
                text: 'Form belum diisi dengan benar..',
                icon: 'error',
                closeOnClickOutside: false
            }).then((value) => {
                // setTimeout(() => {
                //     this.setState({
                //         showErrorLogin: false,
                //         errors: {}
                //     });
                // }, 0);
            });
        }

    }

    handleChange = (event) => {
        let fields = this.state.fields;
        // fields[event.currentTarget.getAttribute('name')] = event.target.value;
        // this.setState({ fields });

        let name = event.currentTarget.getAttribute('name')
        this.state.errors[name] = ""

        if(name == 'no_hp_kode') {
            const re = /^[+0-9\b]+$/;
            if (event.target.value != '' && !re.test(event.target.value)) {
                return
            }
            if(event.target.value.length == 1){
                if(event.target.value != '+') {
                    event.target.value = '+' + event.target.value;
                } 
                // else {
                //     event.target.value = ''
                // }
            }
            if((event.target.value.length > 1) && (event.target.value.slice(-1) == '+')){
                return
            }
            if(event.target.value.length > 5){
                return
            }
        }

        if(name == 'no_hp'){
            const re = /^[0-9\b]+$/;
            if (event.target.value != '' && !re.test(event.target.value)) {
              return
            }
            if(event.target.value.length > 11){
              return
            }
        }

        fields[name] = event.target.value;
        this.setState({ fields });
    }

    setShow = (event) => {
        event.target.setAttribute('show2', false)
    }

    handleChangeSy = (event) => {
        this.setState({ setuju: event.target.checked });
    }

    wheel(e) {
        $('.text_kebijakan').scroll(function () {
            if ($(this).scrollTop() > $(this)[0].scrollHeight - $(this).height() - 100) {
                $('#checkKebijakan').removeAttr('disabled');
            }
        });
    }

    showHideTermPrivacyClick = () => {
        var btnValue = $('.btnShowHideTermPrivacy').html();
        if (btnValue == "Kebijakan Privasi") {
            $('.cardTermCondition').hide();
            $('.cardPrivacyPolicy').show();
            $('.btnShowHideTermPrivacy').html("Syarat dan Ketentuan");
        } else {
            $('.cardTermCondition').show();
            $('.cardPrivacyPolicy').hide();
            $('.btnShowHideTermPrivacy').html("Kebijakan Privasi");
        }
    }

    handleCloseModal = () => {
        this.setState({showModal: false})
    }

    handleShowModal = (param) => {
        this.setState({isModalShow: param})
        this.setState({showModal: true})

        if(param == 1 && this.state.isChecklistTerm == true) {
            this.setState({isChecked : true})
        } else if(param == 2 && this.state.isChecklistPrivacy == true) {
            this.setState({isChecked : true})
        } else {
            this.setState({isChecked : false})
        }
    }

    handleChecklist = () => {
        if(!this.state.isChecked) {
            this.setState({isChecked: true}) 
            if(this.state.isModalShow == 1) {
                this.setState({isChecklistTerm: true}) 
            }
            if(this.state.isModalShow == 2) {
                this.setState({isChecklistPrivacy: true}) 
            }
        }else{
            this.setState({isChecked: false})
            if(this.state.isModalShow == 1) {
                this.setState({isChecklistTerm: false}) 
            }
            if(this.state.isModalShow == 2) {
                this.setState({isChecklistPrivacy: false}) 
            }
        }

        this.setState({showModal: false})
    }

    render() {
        $('.text_kebijakan').scroll(function () {
            if ($(this).scrollTop() > $(this)[0].scrollHeight - $(this).height() - 100) {
                $('#checkKebijakan').removeAttr('disabled');
            }
        });

        const error_text = this.props.regUser.errors;
        const result = this.props.regUser.userDetails;

        let error_panel;
        let msg_text;

        if (error_text) {
            error_panel = '';
            // <Alert variant="danger" show={this.props.regUser.isError}>
            //     <center>{error_text.message}</center>
            // </Alert>
            ;
        } else {
            error_panel = '';
        }

        if (this.props.regUser.isSuccess) {
            msg_text =
                <Alert variant="success" show={true}>
                    <center>Pendaftaran berhasil, silahkan cek email anda untuk konfirmasi</center>
                </Alert>
                ;
        } else {
            msg_text = '';
        }

        return (
            <>
                <div className="topHeaderPengelola_">
                    <div className="container">

                        {msg_text}
                        {error_panel}

                        <div className="row mt-4 align-items-center justify-content-center">
                            <div className="col-lg-6 col-sm-8 col-xs-8 col-lg-justify-content-left col-justify-content-center my-5" style={{ marginLeft: '-25px' }}>
                                <div className="card" style={{ borderRadius: "20px", backgroundColor: "#fafafa" }}>
                                    <div className="text-center" style={{ marginTop: 20 }}>
                                        <a href="/"><img src={Logo} className="img-fluid card-image-top" width="150" alt="logo-kerjasama" /></a>
                                    </div>
                                    <div className="card-body">
                                        <div className=" text-center" style={{ marginBottom: 10, color: "#033C86", fontSize: "16px" }}>Daftar Sebagai Penerbit</div>
                                        <form onSubmit={this.onSubmit}>
                                            <Card.Body>
                                                <div className="divUnderFormDaftarPengelola_">
                                                    <Alert className="alertSuccessDaftar" variant="info" show={true} style={{ display: 'none' }}>
                                                        <center>Terimakasih, pendaftaran anda sedang kami proses...</center>
                                                    </Alert>
                                                    <Alert className="alertErrorLogin" variant="danger" show={this.state.showErrorLogin}>
                                                        <center>{this.state.alertErrorLogin}</center>
                                                    </Alert>

                                                    <div className='col'>
                                                        <div className="form-group row" style={{ fontSize: "14px" }}>Gunakan nama sesuai identitas untuk memudahkan proses verifikasi</div>

                                                        <div className="form-group row">
                                                            <input placeholder="Nama Depan" autoComplete="off" className="form-control form-control-rounded borderInput" name="nama_depan"
                                                                id="nama_depan" type="text" onChange={this.handleChange} value={this.state.fields["nama_depan"] || ''} />
                                                            <span style={{ color: "red", fontSize: '11px' }}>{this.state.errors["nama_depan"]}</span>
                                                        </div>
                                                        <div className="form-group row">
                                                            <input placeholder="Nama Tengah (Optional)" autoComplete="off" className="form-control form-control-rounded borderInput" name="nama_tengah"
                                                                id="nama_tengah" type="text" onChange={this.handleChange} value={this.state.fields["nama_tengah"] || ''} />
                                                        </div>
                                                        <div className="form-group row">
                                                            <input placeholder="Nama Belakang (Optional)" autoComplete="off" className="form-control form-control-rounded borderInput" name="nama_belakang"
                                                                id="nama_belakang" type="text" onChange={this.handleChange} value={this.state.fields["nama_belakang"] || ''} />
                                                        </div>
                                                        <div className="form-group" style={{ marginLeft: -15, marginRight: -15 }}>
                                                            <div className='row' style={{ marginRight: 0 }}>
                                                                <div className='col-sm-3'>
                                                                    <input placeholder="+62" autoComplete="off" className="form-control form-control-rounded borderInput" name="no_hp_kode" id="no_hp_kode" type="text" onChange={this.handleChange} value={this.state.fields["no_hp_kode"] || ''} />
                                                                </div>
                                                                <div className='col' style={{ marginLeft: -15, marginRight: -15 }}>
                                                                    <input placeholder="Nomor Handphone" autoComplete="off" className="form-control form-control-rounded borderInput" name="no_hp" id="no_hp" type="text" onChange={this.handleChange} value={this.state.fields["no_hp"] || ''} />
                                                                </div>
                                                            </div>
                                                            <span style={{ color: "red", fontSize: '11px' }}>{this.state.errors["no_hp"]}</span>
                                                        </div>

                                                        <div className="form-group row">
                                                            <input placeholder="Email" autoComplete="off" className="form-control form-control-rounded borderInput" name="email" id="email" type="text" onChange={this.handleChange} value={this.state.fields["email"] || ''} />
                                                            <span style={{ color: "red", fontSize: '11px' }}>{this.state.errors["email"]}</span>
                                                        </div>
                                                        <div className="form-group row">
                                                            <input placeholder="Password" autoComplete="off" className="form-control form-control-rounded borderInput" name="password" id="password" type="password" onChange={this.handleChange} value={this.state.fields["password"] || ''} />
                                                            <span style={{ color: "red", fontSize: '11px' }}>{this.state.errors["password"]}</span>
                                                        </div>
                                                        <div className="form-group row">
                                                            <input placeholder="Ketik ulang Password" autoComplete="off" className="form-control form-control-rounded borderInput" name="re_password" id="re_password" type="password" onChange={this.handleChange} value={this.state.fields["re_password"] || ''} />
                                                            <span style={{ color: "red", fontSize: '11px' }}>{this.state.errors["re_password"]}</span>
                                                        </div>

                                                        <div className="form-group row" style={{ marginTop: "20px", background: "#214981", borderRadius: 5 }}>
                                                            <a className="btn" style={{ color: "#ffffff", fontSize: "12px" }} onClick={() => this.handleShowModal(1)}>
                                                                Baca dan setujui syarat, ketentuan umum & cookies.
                                                                {this.state.isChecklistTerm == true && <Icon name="check" style={{ color: '#28a745' }} />}
                                                            </a>
                                                        </div>

                                                        <div className="form-group row" style={{ marginTop: "5px", background: "#214981", borderRadius: 5 }}>
                                                            <a className="btn" style={{ color: "#ffffff", fontSize: "12px" }} onClick={() => this.handleShowModal(2)}>
                                                                Baca dan setujui kebijakan privasi.
                                                                {this.state.isChecklistPrivacy == true && <Icon name="check" style={{ color: '#28a745' }} />}
                                                            </a>
                                                        </div>


                                                        <div className="text-center" style={{ marginTop: "20px" }}>
                                                            <Button variant="" type="submit" className="daftar"
                                                                style={{ background: "#3CB728", color: "#ffffff", padding: "5px 70px", borderRadius: "20px" }}
                                                                disabled={
                                                                    (this.state.isChecklistTerm == true && this.state.isChecklistPrivacy == true) ? false : true
                                                                }
                                                            >Daftar
                                                            </Button>
                                                            {/* <div style={{ marginTop: "10px" }}>
                                                                    <a href="/Signin" style={{ color: "#033C86", fontSize: "16px", fontWeight: "700", marginTop: "30px" }}>Masuk</a>
                                                                </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </form>
                                    </div>
                                </div>

                                <div className="text-center mt-3">
                                    <div className="row justify-content-center align-items-center pt-2 pb-2">
                                        <div className="col-auto text-gray-500 font-weight-light" style={{ color: "#999999", fontSize: "12px" }}>
                                            2020 @Copyright PT Kerjasama untuk Negeri (PT KUNN)
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <Modal size="lg" style={{ borderRadius: "30px" }} show={this.state.showModal} onHide={this.handleCloseModal} animation={false}>
                    {/* <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header> */}
                    <Modal.Body>
                        { this.state.isModalShow == 1 && <TermCondition /> }
                        { this.state.isModalShow == 2 && <PrivacyPolicy /> }
                    </Modal.Body>
                    <Modal.Footer>

                        <div className='mr-auto' style={{marginLeft: '30px'}}>
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" 
                                checked={this.state.isChecked}
                                onChange={this.handleChecklist}
                            />
                            <label class="form-check-label" for="flexCheckDefault">
                                { this.state.isModalShow == 1 && "Saya telah membaca dan menyetujui syarat, ketentuan umum dan cookies." }
                                { this.state.isModalShow == 2 && "Saya telah membaca dan menyetujui kebijakan privasi." }
                            </label>
                        </div>

                        <Button color='blue' onClick={this.handleCloseModal}>Keluar</Button>
                        {/* <Button color='green' onClick={handleDirectSign}>Selanjutnya</Button> */}
                    </Modal.Footer>
                </Modal>

            </>    

        );
    }

}

function TermCondition() {
    return (
        <div>
            <p style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 20 }}>SYARAT, KETENTUAN UMUM DAN COOKIES</p>

            <p><b>1. Kata Pengantar</b></p>
            <div style={{ marginLeft: 20, marginBottom: 20 }}>
                <p>Syarat dan Ketentuan Umum ini mengatur hak dan kewajiban yang mengikat secara hukum terhadap Pengguna untuk mengakses, menggunakan dan mengunjungi setiap dan seluruh laman situs (<i>website</i>) dan layanan yang terdapat pada situs https://kerjasama.co.id. Situs https://kerjasama.co.id merupakan situs milik dari PT Kerjasama Untuk Negeri (atau dikenal dengan nama “Kerjasama.co.id” atau “Penyelenggara”) yang merupakan penyelenggara penawaran efek melalui layanan urun dana berbasis teknologi informasi (<i>Securities Crowdfunding</i>) berdasarkan Peraturan Otoritas Jasa Keuangan No.57 Tahun 2020 (selanjutnya disebut <b>“POJK”</b>) yang telah memperoleh izin dari Otoritas Jasa Keuangan Republik Indonesia (selanjutnya disebut OJK RI) berdasarkan Surat Keputusan No: KEP-38/D.04/2021 tentang Pemberian Izin Usaha Penyelenggara Penawaran Efek Melalui Layanan Urun Dana Berbasis Teknologi Informasi (<i>Securities Crowdfunding</i>) PT Kerjasama Untuk Negeri.</p>
                <p>Situs https://kerjasama.co.id merupakan Penawaran Efek melalui Layanan Urun Dana Berbasis Teknologi Informasi yang selanjutnya disebut Layanan Urun Dana adalah penyelenggaraan layanan penawaran efek yang dilakukan oleh penerbit untuk menjual efek secara langsung kepada pemodal melalui jaringan sistem elektronik yang bersifat terbuka, yang mempertemukan Pemodal dan Penerbit yang menawarkan efeknya melalui https://kerjasama.co.id (Pemodal dan Penerbit selanjutnya disebut <b>“Pengguna”</b>) Dengan mengakses dan memiliki akun pada Situs https://kerjasama.co.id, anda selaku pengguna dengan ini menyatakan menerima Syarat dan Ketentuan umum di bawah ini secara keseluruhan.</p>
            </div>

            <p><b>2. Kerjasama.co.id</b></p>
            <div style={{ marginLeft: 20, marginBottom: 20 }}>
                <p>Kerjasama.co.id membantu para Penerbit <i>(Issuer)</i> untuk mendapatkan pendanaan dengan mengakomodir kebutuhan Usaha Kecil Menengah selanjutnya disebut (UKM) dalam memanfaatkan layanan urun dana sebagai salah satu sumber pendanaan di Pasar Modal, yaitu dengan instrumen Efek yang dapat ditawarkan melalui layanan urun dana tidak hanya berbentuk saham (Efek bersifat ekuitas) tetapi juga dapat berupa Efek bersifat utang{/* atau Sukuk*/}. Sehingga pendanaan usaha/proyek dalam rangka peningkatan modal kerja usaha, perluasan usaha (<i>business expansion</i>) dengan lebih cepat dan efisien, dengan cara menawarkan efek bersifat ekuitas, efek bersifat utang{/* atau Sukuk*/}, kepada Pemodal, tanpa harus melakukan Penawaran Umum Perdana atau <i>Initial Public Offering</i> (IPO) di Bursa Efek Indonesia atau pinjaman ke perbankan.</p>
                <p>Lebih lanjut, Pemodal dapat berinvestasi pada Penerbit yang sudah berjalan dengan mengakuisisi sebagian saham Penerbit (pengambilalihan/<i>take over</i>) dan/atau melakukan membuka usaha/proyek baru peningkatan modal /atau <i>Right Issue</i> (“<i>grand opening</i>“). Pemodal dapat juga berinvestasi pada Efek bersifat Utang (Obligasi) dimana proyek-proyek yang akan didanai akan jatuh tempo paling lama 2 (Dua) tahun dengan mendapatkan keuntungan <i>Fixed Coupon</i> atau Kupon tetap. Jika yang ditawarkan Efek bersifat utang, maka pemodal akan mendapatkan bagi hasil yang perhitungannya berdasarkan prinsip syariah dengan mendapatkan bagi hasil secara berkala dalam periode tertentu dengan modal yang tidak terlalu besar.</p>
                <p>Kerjasama.co.id akan menyajikan informasi mengenai usaha/proyek yang akan didanai bersama, legalitas penerbit/proyek, lokasi usaha/proyek, sejarah berdirinya penerbit, kondisi usaha/proyek, termasuk data lampau atas perkembangan usaha/proyek serta data laporan keuangan dari minimal 3 (tiga) usaha/proyek sejenis yang sudah berjalan sebagai bahan rujukan dan pertimbangan Pemodal sebelum berinvestasi melalui situs https://kerjasama.co.id.</p>
                <p>Yang dimaksud dengan Efek adalah surat berharga, yaitu surat pengakuan utang, surat berharga komersial, saham, obligasi, tanda bukti utang, unit penyertaan kontrak investasi kolektif, kontrak berjangka atas Efek, dan setiap derivatif dari Efek.</p>
            </div>

            <p><b>3. Pendanaan Usaha/Proyek</b></p>
            <div style={{ marginLeft: 20, marginBottom: 20 }}>
                <p>Proyek adalah kegiatan atau pekerjaan yang menghasilkan barang, jasa, dan/atau manfaat lain, baik yang sudah ada maupun yang akan ada, termasuk kegiatan investasi yang telah ditentukan yang akan menjadi dasar penerbitan atas Efek bersifat utang{/* atau sukuk*/}. Penerbit dapat mengajukan permohonan pendanaan usaha/proyek, baik yang sudah berjalan maupun usaha baru yang akan dibentuk (untuk selanjutnya disebut <b>“Pendanaan Usaha/Proyek”</b>) pada situs https://kerjasama.co.id. Untuk dapat melakukan penawaran pendanaan usaha/proyek (<b>“Penawaran Efek”</b>), Penerbit dapat mengajukan pendaftaran atas usaha/proyek yang akan didanai melalui layanan urun dana serta berhak untuk menggunakan layanan pada situs https://kerjasama.co.id, yang memuat data dan informasi atas usaha/proyek yang akan didanai bersama dengan cara penawaran efek melalui layanan urun dana berbasis teknologi informasi (securities crowdfunding). Usaha/proyek yang akan didanai melalui layanan urun dana harus berbadan hukum baik perseroan terbatas (PT), maupun berbadan usaha lainnya seperti Koperasi, Persekutuan Perdata, Firma, atau Persekutuan Komanditer (CV).</p>
                <p>Penawaran Efek dilakukan melalui situs https://kerjasama.co.id, paling lama 45 (Empat Puluh Lima) hari untuk selanjutnya disebut (<b>“Masa Penawaran”</b>) setelah Penyelenggara memuat usaha/proyek Penerbit yang akan didanai melalui layanan urun dana. Penghimpunan dana oleh Penerbit melalui layanan urun dana oleh setiap Penerbit dalam jangka waktu 12 (dua belas) bulan paling banyak sebesar Rp.10.000.000.000,- (sepuluh miliar Rupiah) yang dapat dilakukan dalam 1 (satu) kali penawaran dan melalui 1 (satu) Penyelenggara dalam waktu yang bersamaan. Berdasarkan kesepakatan dengan Penyelenggara dan Penerbit dapat menetapkan minimum jumlah dana yang harus terhimpun dengan menyampaikan rencana penggunaan dan sumber pendanaan lain untuk pendanaan usaha/proyek. Selama masa penawaran, Penerbit dilarang dan/atau tidak dibenarkan untuk mengubah jumlah minimum dana yang harus terhimpun, dan membatalkan secara sepihak pendanaan usaha/proyek pada masa penawaran.</p>
                <p>Apabila jumlah minimum dana yang harus terhimpun tidak terpenuhi, maka Penawaran Efek melalui layanan urun dana tersebut menjadi batal demi hukum (<i>null and void</i>). Untuk itu, penyelenggara akan mengembalikan dana beserta seluruh manfaat yang timbul dari dana tersebut selama dalam rekening penampung (<i>escrow account</i>) secara proporsional kepada pemodal selambat-lambatnya 2 (dua) hari kerja setelah penawaran Efek batal demi hukum.</p>
                <p>Pihak yang memiliki kemampuan analisis risiko terhadap Efek Penerbit dan memenuhi kriteria Pemodal sebagaimana diatur dalam POJK 57 tahun 2020 dan berkeinginan untuk melakukan penyertaan efek dan/atau pembelian Efek (selanjutnya disebut <b>“Pemodal”</b>) dapat membeli Efek melalui layanan urun dana dengan membuat akun dan mengisi data-data yang diperlukan dan melakukan penyetoran Efek yang akan dibeli ke dalam rekening penampung (<i>escrow account</i>) yang dikelola oleh penyelenggara serta memberi kuasa kepada penyelenggara untuk mewakili pemodal sebagai pemegang Efek Penerbit termasuk untuk menghadiri kuasa Rapat Umum Pemegang Saham (RUPS) dan atau Rapat Umum Pemegang Obligasi (RUPO) Penerbit dan sebagai kuasa Pemodal dalam melakukan penandatanganan dokumen terkait dan lain sebagainya.</p>
                <p>Pemodal dapat melakukan pembatalan atas pembelian Efek milik Penerbit melalui layanan urun dana paling lambat 2x24 jam setelah melakukan pembelian Efek dan paling lambat 2 (dua) hari kerja setelah pembatalan pemesanan pembelian Efek, Penyelenggara akan mengembalikan dana milik pemodal ke dalam rekening yang ditunjuk oleh Pemodal.</p>
                <p>Setelah dana yang diperlukan untuk pendanaan usaha/proyek telah mencukupi, maka Penyelenggara akan menyatakan bahwa pendanaan usaha/proyek tersebut telah “Terpenuhi“ atau “<i>Sold Out</i>“. Selanjutnya penyelenggara akan menyerahkan dana dari pemodal kepada penerbit selambat-lambatnya 2 (dua) hari kerja setelah fotokopi perjanjian pendaftaran efek diterima penyelenggara (apabila efek yang ditawarkan bersifat ekuitas berupa saham) atau 2 (dua) hari kerja setelah fotokopi perjanjian pendaftaran ek dan dokumen dasar pengerjaan atau bukti kepemilikan proyek (apabila Efek yang ditawarkan bersifat utang{/* atau sukuk*/}). alam hal Penerbit dinyatakan berhasil melakukan Pendanaan melalui Layanan Urun Dana penerbit diwajibkan menyerahkan efek kepada penyelenggara untuk didistribusikan kepada Pemodal paling lambat 2 (dua) hari kerja setelah masa penawaran Efek berakhir dan pendistribusian saham dapat dilakukan secara elektronik melalui penitipan kolektif pada kustodian paling lambat 10 (sepuluh) hari kerja.</p>
                <p>Dalam hal Efek yang ditawarkan bersifat ekuitas berupa saham, Penerbit wajib menandatangani perjanjian pendaftaran Efek dengan Lembaga Penyimpanan dan Penyelesaian (LPP), dan menyampaikan fotokopinya kepada Penyelenggara selambat-lambatnya 10 (sepuluh) hari kerja setelah Penerbit melakukan penyetoran Efek kepada Penyelenggara. Apabila dalam jangka waktu tersebut, Penerbit tidak menyerahkan fotokopi perjanjian pendaftaran Efek, maka Penawaran Efek yang dilakukan penerbit batal demi hukum, dan dengan demikian maka Penyelenggara akan mengembalikan dana beserta seluruh manfaat yang timbul dari dana tersebut selama dalam <i>escrow account</i> secara proporsional kepada Pemodal paling lambat 2 (dua) hari kerja setelah Penawaran Efek batal demi hukum.</p>
                <p>Dalam hal Efek yang ditawarkan bersifat utang{/* atau sukuk*/}, Penerbit wajib menandatangani perjanjian pendaftaran Efek dengan Lembaga Penyimpanan dan Penyelesaian, serta membuat akta pengakuan hutang yang dibuat secara notariil oleh notaris, dan menyampaikan fotokopi kedua dokumen tersebut kepada Penyelenggara selambat-lambatnya 10 (sepuluh) hari kerja setelah Penerbit melakukan penyetoran Efek kepada Penyelenggara. Apabila dalam jangka waktu tersebut, penerbit tidak menyerahkan dokumen-dokumen sebagaimana yang dimaksud beserta dokumen dasar pengerjaan atau bukti kepemilikan Proyek, maka Penawaran Efek yang dilakukan Penerbit batal demi hukum, dan dengan demikian maka Penyelenggara akan mengembalikan dana beserta seluruh manfaat yang timbul dari dana tersebut selama dalam <i>escrow account</i> secara proporsional kepada Pemodal paling lambat 2 (dua) hari kerja setelah penawaran Efek batal demi hukum.</p>
                <p>Apabila dalam pelaksanaan proses transaksi melalui Platform https://kerjasama.co.id ditemukan pelanggaran termasuk namun tidak terbatas pada ketentuan peraturan perundang-undangan, peraturan otoritas jasa keuangan tentang layanan urun dana serta syarat dan ketentuan umum ini, maka Penyelenggara berhak membatalkan transaksi sebagaimana dimaksud.</p>
            </div>

            <p><b>4. Bagi Hasil</b></p>
            <div style={{ marginLeft: 20, marginBottom: 20 }}>
                <p>Laba bersih dari hasil usaha Penerbit pada efek bersifat ekuitas (selanjutnya akan disebut <b>“Dividen”</b>) dan bagi hasil dari proyek penerbit pada efek bersifat utang{/* atau sukuk*/} (selanjutnya disebut bunga <b>“Kupon”</b>) nisbah bagi hasil, margin, imbal jasa atau imbal hasil atau imbalan dengan cara lain akan dibagikan kepada pemegang efek sesuai dengan jumlah kepemilikan efeknya pada perusahaan Penerbit. Pembagian laba bersih kepada Pemodal dilakukan pada setiap akhir tahun buku berdasarkan rekomendasi dari Direksi Perseroan dengan persetujuan dari rapat umum pemegang saham (Rups) Penerbit.</p>
                <p>Terlepas dari yang sudah ditentukan di atas, berdasarkan rekomendasi dari Direksi Perseroan dengan persetujuan dari rapat umum pemegang saham Penerbit, Penerbit dapat melakukan pembagian dividen pada masa tahun buku perseroan (selanjutnya disebut <b>“Dividen Interim”</b>) yang akan dilakukan oleh Penyelenggara melalui Saldo Efek. Pendistribusian Dividen Interim melalui Saldo Efek dilakukan Pemodal dengan menyampaikan instruksi pencairan Saldo Efek dengan mencantumkan jumlah nominal yang akan dilakukan pencairan.</p>
            </div>

            <p><b>5. Saldo Efek</b></p>
            <div style={{ marginLeft: 20, marginBottom: 20 }}>
                <p>Setiap dana yang masuk ke dalam Saldo Efek dapat ditarik sewaktu-waktu oleh Pemodal dengan cara menyampaikan instruksi pencairan dana melalui <i>platform</i> yang disediakan oleh kerjasama.co.id, Penyelenggara akan melakukan pemindahbukuan Saldo Efek ke dalam rekening yang ditunjuk oleh Pemodal selambat-lambatnya 3 (tiga) hari kerja.</p>
            </div>

            <p><b>6. Pernyataan dan Jaminan</b></p>
            <div style={{ marginLeft: 20, marginBottom: 20 }}>
                <table>
                    <tbody>
                        <tr>
                            <td style={{ verticalAlign: "baseline" }}>A. </td>
                            <td colSpan="2">Penyelenggara dengan ini menyatakan dan menjamin hal-hal sebagai berikut:</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>1. </td>
                            <td>Penyelenggara adalah badan hukum yang didirikan dan tunduk pada ketentuan hukum dan perundang-undangan Negara Kesatuan Republik Indonesia.</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>2. </td>
                            <td>Sepanjang yang diketahui oleh Penyelenggara, Penyelenggara dalam menjalankan kegiatan usahanya telah memiliki setiap perizinan yang dipersyaratkan termasuk namun tidak terbatas pada izin dalam bidang penyelenggaraan penawaran efek melalui layanan urun dana berbasis teknologi (<i>securities crowdfunding</i>) dari Otoritas Jasa Keuangan Republik Indonesia (OJK RI) dan perizinan tersebut, sampai ini masih berlaku dan/atau tidak sedang ditangguhkan oleh pihak otoritas yang berwenang.</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>3. </td>
                            <td>Sepanjang yang diketahui oleh Penyelenggara, Penyelenggara dalam menjalankan kegiatan usahanya dalam hal kepemilikan sahamnya penyelenggara oleh warga negara asing dan/atau badan hukum asing, baik secara langsung maupun tidak langsung paling banyak 49% (Empat Puluh Sembilan Persen).</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>4. </td>
                            <td>Sepanjang yang diketahui oleh Penyelenggara, penyelenggara dalam menjalankan kegiatan usahanya tidak melanggar hak atas kekayaan intelektual dari pihak ketiga lainnya.</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>5. </td>
                            <td>Penyelenggara dengan ini menyatakan bahwa dalam memberikan dan/atau menyajikan data-data dan informasi-informasi terkait dengan penerbit dan/atau penawaran Efek melalui layanan urun dana yang diselenggarakan oleh Penyelenggara, Penyelenggara sepenuhnya mempercayakan, mengandalkan dan bersandarkan pada semua data-data dan/atau informasi-informasi yang diberikan oleh Penerbit kepada Penyelenggara dengan terlebih dahulu <i>due diligence</i> baik secara legal maupun secara finansial.</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>6. </td>
                            <td>Penyelenggara dengan ini menyatakan bahwa Penyelenggara tidak pernah membuat pernyataan-pernyataan atau jaminan-jaminan, baik secara tegas maupun tersirat, terhadap kualitas, akurasi dan kelengkapan informasi yang disediakan oleh Penyelenggara pada layanan urun dana melalui penawaran efek berbasis teknologi terkait dengan kegiatan usaha Penerbit.</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>7. </td>
                            <td>Penyelenggara dengan ini menyatakan dan menjamin bahwa dana pemodal yang ada dalam rekening <i>virtual account</i> dan/atau <i>escrow account</i> atas nama Penyelenggara dan/atau atas nama bersama dari Penyelenggara, Penerbit dan Pemodal adalah merupakan dana milik masing-masing Pemodal, dan bukan merupakan harta kekayaan (aset) milik Penyelenggara. Selanjutnya, harta (aset) tersebut tidak dapat dan bukan merupakan harta pailit (<i>boedel pailit</i>), dalam hal Penyelenggara dinyatakan pailit berdasarkan peraturan perundang-undangan yang berlaku.</td>
                        </tr>
                        <tr>
                            <td colSpan="3">&nbsp;</td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: "baseline" }}>B. </td>
                            <td colSpan="2">Pemodal dengan ini menyatakan dan menjamin hal-hal sebagai berikut:</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>1. </td>
                            <td>Bahwa Pemodal adalah (a) badan hukum yang didirikan dan tunduk pada ketentuan hukum dan perundang-undangan Negara Kesatuan Republik Indonesia (dalam hal Pemodal merupakan badan hukum, dan (b) subjek hukum yang cukup umur, sehat akal pikiran dan berhak serta berwenang untuk mengikatkan diri (dalam hal Pemodal merupakan perorangan) pada syarat dan ketentuan umum penggunaan layanan ini.</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>2. </td>
                            <td>Pemodal yang dapat membeli Efek melalui layanan urun dana wajib memiliki Rekening Efek dan/atau dana melalui layanan urun dana, memiliki kemampuan untuk membeli Efek Penerbit dan memenuhi kriteria Pemodal dan batasan pembelian Efek.</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>3. </td>
                            <td>Pemodal yang dapat membeli Efek melalui layanan urun dana:<br />
                                a. Memiliki penghasilan sampai dengan Rp 500.000.000 (Lima Ratus Juta Rupiah) pertahun, maksimum investasi 5% (Lima Persen) dari penghasilan per tahun; b. Memiliki penghasilan lebih dari Rp 500.000.000 (Lima Ratus Juta), maksimum investasi 10% (Sepuluh Persen) dari penghasilan per tahun; dan c. Namun, apabila: 1) Pemodal merupakan badan hukum; 2) Pemodal memiliki pengalaman investasi di pasar modal; atau 3) Efek bersifat utang{/* atau Sukuk*/} dijamin atau ditanggung dengan nilai penjaminan atau nilai penanggungan paling sedikit 125% (Seratus Dua Puluh Lima Persen) dari nilai penghimpunan dana, maka jumlah investasi pemodal tidak dibatasi.
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>4. </td>
                            <td>Pemodal dengan ini menyatakan dan menjamin bahwa setiap data dan informasikan yang diberikan oleh Pemodal pada saat pembukaan akun untuk menggunakan penawaran efek melalui layanan urun dana berbasis teknologi yang disediakan Penyelenggara, adalah benar, lengkap dan sesuai dengan dokumen aslinya, otentik, dan akurat serta masih berlaku. Selanjutnya, Pemodal juga menyatakan bahwa tanda tangan yang diberikan dan/atau dibubuhkan baik secara fisik maupun elektronik adalah benar tanda tangan Pemodal dan bukan merupakan tanda tangan pihak lain.</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>5. </td>
                            <td>Pemodal dengan ini menyatakan dan menjamin bahwa Pemodal telah membaca, memeriksa, menganalisa dan mengevaluasi prospektus, data, informasi maupun kelengkapan dokumen dari usaha/proyek Penerbit yang dimuat melalui penawaran efek melalui layanan urun dana berbasis teknologi informasi yang disediakan Penyelenggara, Pemodal dengan ini menyatakan dan menjamin bahwa Pemodal telah memilih kegiatan usaha/proyek dari Penerbit secara sadar, tanpa ada bujuk rayu, paksaan, ancaman dan pengaruh dari pihak manapun termasuk dari Penyelenggara.</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>6. </td>
                            <td>Pemodal dengan ini menyatakan dan menjamin bahwa Pemodal dalam melakukan penyertaan efek (pembelian efek) pada Penerbit telah membaca, memeriksa, menganalisa dan mengevaluasi prospektus/mini prospektus, data, informasi maupun kelengkapan dokumen dari usaha/proyek Penerbit pada layanan urun dana yang disediakan oleh Penyelenggara dan dalam melakukan penyertaan modal pada Penerbit, Pemodal menyatakan dan mengakui risiko-risiko terkait dengan kualitas, akurasi dan kelengkapan informasi terkait dengan kegiatan usaha Penerbit yang disajikan dan/atau diinformasikan melalui layanan urun dana yang disediakan oleh Penyelenggara. Selanjutnya, Pemodal menyatakan dan mengetahui bahwa setiap data dan prospektus, keuntungan usaha/proyek maupun risiko kegiatan operasional Penerbit yang disajikan dan/atau diinformasikan oleh Penyelenggara penawaran efek melalui layanan urun dana berbasis teknologi, merupakan data dan prospektus yang disajikan oleh Penyelenggara berdasarkan informasi yang diberikan dan/atau disediakan oleh Penerbit kepada Penyelenggara dengan berdasarkan pada data dan/atau informasi serta kejadian yang telah lalu dan mengandung unsur ketidakpastian tergantung dari potensi kegiatan usaha/proyek Penerbit, kondisi umum dan khusus dari kegiatan operasional Penerbit. Dan karenanya, Pemodal dengan ini menyatakan membebaskan Penyelenggara dari setiap klaim, tuntutan, gugatan dari Pemodal dan/atau pihak ketiga lainnya terkait dengan risiko terkait dengan kualitas, akurasi dan kelengkapan informasi yang mungkin akan timbul dan atau mungkin akan terjadi terhadap setiap data dan prospektus kegiatan operasional, keuntungan usaha/proyek maupun risiko kegiatan operasional Penerbit yang disajikan dan/atau diinformasikan oleh Penyelenggara melalui layanan urun dana melalui penawaran efek berbasis teknologi.</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>7. </td>
                            <td>Pemodal dengan ini menyatakan, bahwa Pemodal sepenuhnya mengetahui, memahami dan menyadari resiko yang terkandung dan resiko yang mungkin akan timbul dikemudian hari atas kegiatan usaha Penerbit yang ditawarkan melalui penyelenggaraan layanan urun dana melalui penawaran efek berbasis teknologi (<i>securities crowdfunding</i>), termasuk namun tidak terbatas pada penurunan performa usaha dan/atau usaha Penerbit tidak menghasilkan keuntungan dan/atau profit, risiko gagal bayar pada efek bersifat utang, hingga Penerbit dinyatakan bangkrut maupun pailit. Dan karenanya, Pemodal dengan ini menyatakan membebaskan Penyelenggara dari setiap klaim, tuntutan, gugatan dari Pemodal dan/atau pihak ketiga lainnya terkait dengan risiko yang mungkin akan timbul dan atau mungkin akan terjadi atas kegiatan usaha Penerbit, termasuk namun tidak terbatas pada kegagalan kegiatan usaha/proyek Penerbit untuk memperoleh penghasilan dan/atau profit maupun Penerbit dinyatakan bangkrut maupun pailit.</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>8. </td>
                            <td>Pemodal dengan ini menyatakan bahwa Pemodal sepenuhnya mengetahui, memahami dan menyadari resiko yang terkandung dan resiko yang mungkin akan timbul dikemudian hari atas pelaksanaan perdagangan efek bersifat ekuitas atau saham Penerbit pada pasar sekunder, termasuk namun tidak terbatas tidak likuidnya saham Penerbit yang ditawarkan oleh Pemodal melalui layanan pasar sekunder yang disediakan oleh Penyelenggara melalui layanan urun dana melalui penawaran efek berbasis teknologi. Dan karenanya, Pemodal dengan ini menyatakan membebaskan Penyelenggara dari setiap klaim, tuntutan, gugatan dari Pemodal dan/atau pihak ketiga lainnya terkait dengan risiko yang mungkin akan timbul dan/atau mungkin akan terjadi atas pelaksanaan perdagangan saham Penerbit pada layanan pasar sekunder yang disediakan oleh Penyelenggara, termasuk namun tidak terbatas tidak likuidnya saham yang ditawarkan oleh Penerbit pada pasar sekunder yang disediakan oleh Penyelenggara melalui layanan urun dana melalui penawaran saham berbasis teknologi.</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>9. </td>
                            <td>Pemodal dengan ini menyatakan dan menjamin kepada Penyelenggara bahwa dana yang disetorkan oleh Pemodal ke dalam rekening <i>virtual account</i> dan/atau <i>escrow account</i> Penyelenggara, dan yang digunakan oleh Pemodal untuk melakukan penyertaan efek (pembelian efek) pada Penerbit merupakan milik Pemodal sendiri dan diperoleh dengan cara yang halal dan tidak bertentangan dengan hukum dan Perundang-undangan yang berlaku di Negara Kesatuan Republik Indonesia, termasuk dan tidak terbatas pada tindak pidana korupsi, tindak pidana pencucian uang dan pendanaan terorisme;</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>10. </td>
                            <td>Pemodal dengan ini menyatakan akan melindungi, menjamin, memberikan ganti kerugian dan membebaskan serta melepaskan Penyelenggara dari setiap klaim, tuntutan, gugatan, tuntutan ganti rugi, tuntutan pertanggungjawaban dan/atau tuntutan sejenis lainnya yang mungkin diajukan oleh Pemodal, Penerbit, dan/atau pihak ketiga lainnya, baik langsung maupun tidak langsung, terhadap setiap kerugian, kehilangan dan/atau kerusakan harta benda, cidera fisik maupun mental maupun kehilangan nyawa pihak ketiga lainnya, terkait dengan kegiatan usaha/proyek dan/atau pengelolaan kegiatan usaha/proyek dari Penerbit.</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>11. </td>
                            <td>Pemodal dengan ini menyatakan bahwa Pemodal sepenuhnya mengetahui, memahami dan menyadari bahwa Penyelenggara tidak melakukan kegiatan usaha/proyek dan/atau pengelolaan kegiatan usaha/proyek dari Penerbit. Pemodal selanjutnya menyerahkan sepenuhnya kegiatan usaha/proyek dan/atau pengelolaan kegiatan usaha/proyek dari Penerbit kepada pihak dan/atau pihak-pihak yang ditunjuk oleh Pemodal dan/atau para Pemodal bersama-sama dengan Penerbit dan Pemodal dengan ini menyatakan dan menjamin bahwa pemodal tidak akan melakukan intervensi kegiatan usaha/proyek dan/atau pengelolaan kegiatan usaha/proyek dari Penerbit baik langsung maupun tidak langsung. Selanjutnya, Pemodal dengan ini menyatakan dan menjamin bahwa Pemodal akan menyampaikan setiap keluh-kesah, keberatan dan/atau komplain baik langsung maupun tidak langsung kepada Penerbit, direksi, komisaris, maupun Penyelenggara dan/atau pihak-pihak yang ditunjuk oleh Pemodal dan/atau para Pemodal bersama-sama dengan Penerbit untuk melakukan kegiatan usaha/proyek dan/atau pengelolaan kegiatan usaha dari Penerbit.</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>12. </td>
                            <td>Pemodal dengan ini menyatakan bahwa Pemodal sepenuhnya mengetahui, memahami dan menyadari bahwa Penyelenggara akan membebankan biaya layanan sebesar 5% (lima persen) (selanjutnya disebut <b>“Platform Fee”</b>) dari hasil keuntungan yang diterima oleh Penerbit dan/atau Pemodal sebelum pajak, yang akan dikenakan pada saat pembagian dividen dan/atau profit dilakukan. Biaya manajemen ini merupakan biaya atas pengelolaan dan administrasi kegiatan usaha/proyek Penerbit, termasuk namun tidak terbatas pada penyampaian laporan keuangan dan/atau pembagian hasil usaha/proyek Penerbit (pembagian dividen, bunga kupon dan/atau bagi hasil) yang dilakukan oleh Penyelenggara kepada Pemodal;</td>
                        </tr>
                        <tr>
                            <td colSpan="3">&nbsp;</td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: "baseline" }}>C. </td>
                            <td colSpan="2">Penerbit dengan ini menyatakan dan menjamin hal-hal sebagai berikut:</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>1. </td>
                            <td>Penerbit menyatakan dan menjamin bahwa Penerbit adalah badan hukum atau badan usaha lainnya yang didirikan dan tunduk pada ketentuan hukum dan perundang-undangan Negara Kesatuan Republik Indonesia, bahwa Penerbit bukan termasuk dari: a. Badan usaha yang dikendalikan baik langsung maupun tidak langsung oleh suatu kelompok usaha atau konglomerasi; b. Perusahaan terbuka atau anak perusahaan terbuka; dan c. Badan usaha dengan kekayaan bersih lebih dari Rp 10.000.000.000 (Sepuluh Miliar Rupiah), tidak termasuk tanah dan bangunan tempat usaha.</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>2. </td>
                            <td>Penerbit dalam menjalankan kegiatan proyek/usahanya telah memiliki setiap perizinan yang dipersyaratkan dan perizinan tersebut, sampai ini masih berlaku dan/atau tidak sedang ditangguhkan oleh pihak yang berwenang.</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>3. </td>
                            <td>Penerbit dalam menjalankan kegiatan proyek/usahanya tidak melanggar hukum dan perundang-undangan yang berlaku pada Negara Kesatuan Republik Indonesia, termasuk namun tidak terbatas pada pelanggaran terhadap hak atas kekayaan intelektual dari pihak ketiga lainnya.</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>4. </td>
                            <td>Penerbit dengan ini menyatakan bertanggung jawab penuh terhadap kualitas, akurasi dan kelengkapan informasi yang disediakan oleh Penerbit kepada Penyelenggara dan menjadi acuan bagi Penyelenggara dalam memberikan dan/atau menyajikan data-data dan informasi-informasi terkait dengan kegiatan usaha/proyek Penyelenggara melalui layanan urun dana yang diselenggarakan oleh Penyelenggara dan yang menjadi acuan Pemodal dalam memeriksa, menganalisa dan mengevaluasi prospektus, data, informasi maupun kelengkapan dokumen dari usaha/proyek Penerbit serta dalam menentukan pilihan investasinya. Dan karenanya, Penerbit dengan ini menyatakan membebaskan Penyelenggara dari setiap klaim, tuntutan, gugatan dari Pemodal dan/atau pihak ketiga lainnya terkait dengan kualitas, akurasi dan kelengkapan informasi yang mungkin akan timbul dan/atau mungkin akan terjadi terhadap setiap data dan prospektus kegiatan operasional, keuntungan usaha maupun risiko kegiatan operasional Penerbit yang disajikan dan/atau diinformasikan oleh Penyelenggara kepada Pemodal melalui layanan urun dana melalui penawaran efek berbasis teknologi.</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>5. </td>
                            <td>Penerbit dengan ini menyatakan dan menjamin bahwa penawaran efek melalui layan urun dana yang diselenggarakan oleh Penyelenggara, tidak melanggar ketentuan sebagaimana termuat dalam anggaran dasar Penerbit maupun melanggar dan/atau bertentangan dengan perjanjian apapun antara Penerbit dengan pihak ketiga lainnya.</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>6. </td>
                            <td>Penerbit dengan ini menyatakan dan menjamin bahwa Penerbit wajib menyelesaikan setiap permasalahan yang timbul terkait dengan pendanaan melalui layanan urun dana, baik dengan Penyelenggara, Pemodal, pihak ketiga lainnya maupun pihak berwenang.</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>7. </td>
                            <td>Penerbit dengan ini menyatakan dan menjamin bahwa laporan keuangan yang diserahkan kepada Penyelenggara untuk didistribusikan kepada Pemodal adalah benar dan akurat, tidak ada satupun data maupun informasi yang tidak disampaikan ataupun disembunyikan atau dengan perkataan lain tidak disajikan dan/atau diserahkan kepada Penyelenggara.</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>8. </td>
                            <td>Penerbit dengan ini menyatakan dan menjamin tidak akan menjual, menggadaikan dan/atau dengan cara lain mengalihkan kegiatan usaha/proyek maupun aset Penerbit yang dikelolanya dan terhadap usaha/proyek dan/atau setiap aset yang dimiliki Penerbit tidak sedang dalam sengketa baik perdata maupun pidana dan/atau tidak sedang dikenakan sita eksekusi maupun sita jaminan serta tidak sedang digadaikan dan/atau tidak sedang dibebankan suatu hak tanggungan atau dengan cara lainnya dialihkan kepada pihak ketiga lainnya.</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>9. </td>
                            <td>Penerbit dengan ini menyatakan dan menjamin bahwa Penerbit memiliki kemampuan untuk melakukan pengelolaan atas usaha/proyek dari Penerbit, memiliki sumber daya manusia yang cakap, handal dan berkemampuan untuk menjalankan dan mengoperasikan kegiatan usaha dari Penerbit. Dalam hal terjadi kekurangan sumber daya manusia dalam menjalankan dan mengoperasikan kegiatan usaha/proyek Penerbit, Penerbit akan berusaha dan berupaya dengan kemampuan terbaiknya untuk mengatasi kekurangan sumber daya manusia yang diperlukan dalam menjalankan dan mengoperasikan kegiatan usaha/proyek Penerbit yang dibiayai melalui layanan urun dana yang diselenggarakan oleh Penyelenggara. Selanjutnya, Penerbit dengan ini menyatakan membebaskan Penyelenggara dari setiap klaim, tuntutan, gugatan dari Pemodal dan/atau pihak ketiga lainnya terkait dengan kemampuan penerbit dalam melakukan pengelolaan usaha/proyek Penerbit dan kekurangan sumber daya manusia yang diperlukan dalam menjalankan dan mengoperasikan kegiatan usaha/proyek Penerbit.</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>10. </td>
                            <td>Penerbit dengan ini menyatakan dan menjamin bahwa Penerbit bertanggung jawab sepenuhnya terhadap setiap kerusakan, kerugian maupun kehilangan nyawa dari karyawannya maupun pihak ketiga lainnya, terkait dengan kegiatan usaha/proyek yang dijalankan oleh Penerbit. Selanjutnya, Penerbit dengan ini menyatakan membebaskan Penyelenggara dari setiap klaim, tuntutan, gugatan dari Pemodal dan/atau pihak ketiga lainnya terkait dengan setiap kerusakan, kerugian maupun kehilangan nyawa dari karyawannya maupun pihak ketiga lainnya sehubungan dengan pelaksanaan kegiatan usaha oleh Penerbit.</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>11. </td>
                            <td>Penerbit dengan ini menyatakan dan menjamin tidak akan melakukan penyimpangan atas penggunaan setiap dana Pemodal dan setiap dana dari Pemodal yang diserahkan melalui Penyelenggara, akan digunakan sesuai dengan peruntukan dan penggunaannya sesuai dengan apa yang disampaikan dalam prospektus yang diberikan dan disajikan oleh Penyelenggara melalui layanan urun dana. Selanjutnya, Penerbit dengan ini menyatakan membebaskan Penyelenggara dari setiap klaim, tuntutan, gugatan dari Pemodal dan/atau pihak ketiga lainnya terkait dengan setiap penyimpangan dan/atau penggunaan dana Pemodal.</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>12. </td>
                            <td>Penerbit dengan ini menyatakan dan menjamin tidak sedang dalam masa penawaran Efek pada penyelenggara lain atau tidak memiliki kewajiban dengan penyelenggara lain.</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>13. </td>
                            <td>Penerbit dengan ini menyatakan dan menjamin bersedia untuk menandatangani perjanjian-perjanjian lain terkait dan sehubungan dengan kegiatan layanan urun dana dan/atau penghimpunan dana melalui Penyelenggara, termasuk namun tidak terbatas pada perjanjian penyelenggara dan penerbit, perjanjian sewa lahan dan/atau tempat usaha/proyek, Surat Perintah Kerja (SPK) maupun perjanjian-perjanjian turunan lainnya dari layanan urun dana yang diselenggarakan oleh Penyelenggara. Selanjutnya, Penerbit dengan ini menyatakan membebaskan Penyelenggara dari setiap klaim, tuntutan, gugatan dari Pemodal dan/atau pihak ketiga lainnya terkait dengan belum ditandatanganinya perjanjian-perjanjian turunan lainnya terkait dan sehubungan dengan kegiatan layanan urun dana dan/atau penghimpunan dana melalui Penyelenggara.</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <p><b>7. Biaya-biaya</b></p>
            <div style={{ marginLeft: 20, marginBottom: 20 }}>
                <p>Untuk setiap nominal nilai investasi yang disetorkan Pengguna, akan dikenakan biaya penggunaan layanan urun dana berbasis teknologi informasi(selanjutnya disebut <b>“<i>platform fee</i>”</b>) sebesar 5% (lima persen) dari keseluruhan nilai investasi yang disetorkan oleh Pemodal. Biaya <i>platform fee</i> tersebut akan ditransfer secara otomatis ke rekening PT Kerjasama Untuk Negeri sebagai biaya penggunaan layanan urun dana.</p>
                <p>Seluruh profit yang diterima oleh Pemodal, yaitu berupa dividen maupun kupon sebagaimana disebut dalam poin 3 di atas, akan dipotong <i>management fee</i> sebesar 5% (lima persen) secara otomatis melalui Saldo Efek.</p>
                <p>Penerbit dan Pemodal setuju dan membebaskan Kerjasama.co.id dari seluruh pajak yang timbul atas penyetoran dana, pembagian dividen, pembagian kupon dan transaksi apapun lainnya kecuali apabila hal tersebut telah diatur oleh peraturan perundang-undangan yang berlaku.</p>
            </div>

            <p><b>8. Risiko</b></p>
            <div style={{ marginLeft: 20, marginBottom: 20 }}>
                <p>Pemodal mengakui dan menyadari bahwa setiap usaha memiliki risikonya masing-masing. Untuk itu, dengan berinvestasi melalui Kerjasama.co.id, Pemodal dengan ini mengerti akan segala risiko yang dapat terjadi di kemudian hari, diantaranya meliputi risiko: 1. usaha; 2. investasi; 3. likuiditas; 4. kelangkaan pembagian dividen, 5. dilusi kepemilikan saham; 6. kegagalan sistem elektronik; dan 7. gagal bayar atas Efek bersifat utang{/* atau Sukuk*/};</p>
                <p>Atas resiko yang dijelaskan di atas, Pemodal dengan ini membebaskan Kerjasama.co.id dari segala klaim, tuntutan, ganti rugi yang terkait dengan risiko yang mungkin akan timbul dan atau mungkin akan terjadi atas kegiatan usaha/proyek Penerbit, termasuk namun tidak terbatas pada kegagalan kegiatan usaha Penerbit untuk memperoleh penghasilan dan/atau profit maupun Penerbit dinyatakan bangkrut maupun pailit yang dapat terjadi dikemudian hari.</p>
            </div>

            <p><b>9. Privasi dan Keamanan</b></p>
            <div style={{ marginLeft: 20, marginBottom: 20 }}>
                <p>Kerjasama.co.id menjamin keamanan data pribadi Penerbit dan Pemodal dalam menggunakan dan mengakses layanan urun dana berbasis teknologi informasi pada Situs https://kerjasama.co.id.</p>
                <p>Semua informasi pribadi dari Penerbit maupun Pemodal akan dilindungi sepenuhnya kerahasiaannya. Kerahasiaan informasi yang anda masukkan dalam Situs https://kerjasama.co.id akan kami jaga kerahasian nya sebaik-baiknya untuk kepentingan Penerbit maupun Pemodal.</p>
                <p>Kerjasama.co.id berhak untuk mengubah dan memperbarui syarat dan ketentuan penggunaan Situs https://kerjasama.co.id ini sewaktu-waktu tanpa pemberitahuan sebelumnya kepada Penerbit dan/atau Pemodal. Kerjasama.co.id akan memberitahukan setiap perubahan-perubahan pada Situs https://kerjasama.co.id dan akan mengirimkan pemberitahuan melalui email jika dirasa dan dipandang perlu. Perubahan dari syarat dan ketentuan akan berlaku setelah dimuat pada dalam Situs https://kerjasama.co.id.</p>
            </div>

            <p><b>10. Akun Anda</b></p>
            <div style={{ marginLeft: 20, marginBottom: 20 }}>
                <p>Anda dapat mendaftarkan diri menjadi anggota Situs https://kerjasama.co.id dengan mengisi formulir pendaftaran. Setelah pendaftaran disetujui dan memenuhi syarat dan ketentuan pembukaan akun, Pemodal dan Penerbit dapat mengunggah konten, mengajukan pendanaan usaha ataupun menjadi investor pada layanan urun dana yang diselenggarakan oleh Kerjasama.co.id.</p>
                <p>Untuk mendaftar, anda wajib memberikan alamat email aktif anda. Alamat email ini akan mempermudah kami melakukan verifikasi identitas anda pada kunjungan berikutnya. Apabila anda mendaftar dengan menggunakan alamat email fiktif atau alamat email milik orang lain, Kerjasama.co.id dapat menutup account anda tanpa pemberitahuan sebelumnya secara permanen. Selain itu anda wajib menyediakan password yang akan digunakan untuk mengakses materi dan layanan Situs https://kerjasama.co.id.</p>
                <p>Setelah anda mengisi data profil pada Situs https://kerjasama.co.id serta mengunggah dokumen kelengkapannya, kerjasama.co.id akan melakukan verifikasi akun anda dalam waktu 1 x 24 jam.</p>
                <p>Anda tidak akan dikenakan biaya untuk membuka <i>account</i> pada Situs https://kerjasama.co.id. Akan tetapi, anda akan dikenakan biaya sesuai dengan tarif sebagaimana tersebut dalam Syarat dan Ketentuan umum ini. Segala transaksi yang dilakukan harus melalui Saldo Efek.</p>
                <p>Jika masih terdapat pertanyaan mengenai layanan urun dana berbasis teknologi informasi yang diselenggarakan oleh Kerjasama.co.id, anda dapat menyampaikan setiap pertanyaan anda melalui <a href="mailto:cs@kerjasama.co.id">cs@kerjasama.co.id.</a></p>
            </div>

            <p><b>11. Penghapusan Akun</b></p>
            <div style={{ marginLeft: 20, marginBottom: 20 }}>
                <p>Anda dapat menghapus akun anda di Situs https://kerjasama.co.id dengan menghubungi alamat email cs@kerjasama.co.id yang kami sediakan. Untuk membatalkan akun tersebut, anda wajib memberikan informasi dan konfirmasi kepada Kerjasama.co.id bahwa anda adalah pemilik sesungguhnya dari akun tersebut. Akun anda akan dibatalkan setelah Kerjasama.co.id menerima konfirmasi dari anda mengenai otorisasi keabsahan identitas anda.</p>
                <p>Kerjasama.co.id berhak membatalkan akun anda tanpa pemberitahuan sebelumnya untuk kepentingan Situs https://kerjasama.co.id. Penghapusan akun ini dapat terjadi bila adanya hal-hal termasuk, tetapi tidak terbatas pada:</p>
                <table>
                    <tbody>
                        <tr>
                            <td width='20'></td>
                            <td style={{ verticalAlign: "baseline" }}>A. </td>
                            <td>Pelanggaran Syarat dan Ketentuan Umum yang berlaku di Situs https://kerjasama.co.id;</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>B. </td>
                            <td>Permintaan dari badan hukum atau instansi milik pemerintah;</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>C. </td>
                            <td>Permintaan dari anda sendiri;</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>D. </td>
                            <td>Periode ketidakaktifan akun yang cukup lama; dan</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>E. </td>
                            <td>Kasus penipuan atau penggelapan yang dilakukan oleh anda atau hal-hal yang telah atau berpotensi merugikan pihak lain.</td>
                        </tr>
                        <tr>
                            <td colSpan='3'>&nbsp;</td>
                        </tr>
                        <tr>
                            <td colSpan='3'>Pembatalan akun anda dapat berakibat sebagai berikut:</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>A. </td>
                            <td>Pembatasan keikutsertaan Pemodal dalam kegiatan layanan urun dana yang dilakukan oleh Penerbit lain melalui Kerjasama.co.id (<i>blacklist</i>).</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>B. </td>
                            <td><i>Password</i> anda tidak akan berlaku lagi sehingga anda tidak dapat mengakses bagian-bagian situs yang dilindungi dengan <i>password</i>; dan/atau</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>C. </td>
                            <td>Anda tidak dapat lagi menggunakan layanan Kerjasama.co.id secara permanen.</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <p><b>12. Hak Kekayaan Intelektual</b></p>
            <div style={{ marginLeft: 20, marginBottom: 20 }}>
                <p>Seluruh desain, foto, logo dan gambar yang termuat pada Situs Kerjasama.co.id adalah milik dari PT Kerjasama Untuk Negeri yang dilindungi hukum dan perundang-undangan Negara Kesatuan Republik Indonesia dan tidak diperkenankan untuk menyebarluaskan desain, foto, logo dan gambar baik sebagian maupun secara keseluruhan oleh pihak manapun dan untuk kepentingan apapun, tanpa pemberitahuan dan persetujuan secara tertulis dari Kerjasama.co.id.</p>
            </div>

            <p><b>13. Keadaan Kahar (<i>Force Majeure</i>)</b></p>
            <div style={{ marginLeft: 20, marginBottom: 20 }}>
                <table>
                    <tbody>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>A. </td>
                            <td>Para Pihak dibebaskan dari kewajibannya berdasarkan Perjanjian ini dalam hal terjadinya, dan selama terjadinya suatu Keadaan Kahar.</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>B. </td>
                            <td>Yang dimaksud dengan Keadaan Kahar (“<i>force majeure</i>”) adalah suatu kejadian atau peristiwa yang yang terjadi di luar daya upaya manusia dan/atau tidak dapat diduga atau diprediksi sebelumnya dan/atau di luar kendali kekuasaan salah satu dari Para Pihak dan/atau Penerbit untuk mengatasinya, yang mengakibatkan terhambatnya pelaksanaan kewajiban salah satu dari Para Pihak dan/atau Penerbit, termasuk namun tidak terbatas pada:</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td style={{ verticalAlign: "baseline" }}>1. </td>
                                            <td>Kejadian atau peristiwa yang terjadi atas kehendak Tuhan, termasuk namun tidak terbatas pada bencana alam angin topan, angin puting beliung, wabah penyakit, gempa bumi, petir, banjir, tsunami, kebakaran, tanah longsor dan/atau bencana alam lainnya.</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td style={{ verticalAlign: "baseline" }}>2. </td>
                                            <td>Huru-hara, kerusuhan sipil, peperangan, pemberontakan, mogok kerja, sabotase, perbuatan perusakan/penghancuran (vandalisme), embargo, tindakan pemerintah, perubahan peraturan perundang-undangan, gangguan sistem teknologi informasi dan/atau kegagalan sistem serta alih kelola sistem teknologi informasi, baik yang disebabkan oleh penyadapan oleh pihak ketiga maupun bukan dan/atau sebab-sebab serupa lainnya, yang terjadi di luar kekuasaan dan kemampuan Para Pihak dan/atau Penerbit yang menyebabkan Para Pihak dan/atau Penerbit tidak dapat memenuhi kewajibannya berdasarkan Perjanjian ini;</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td style={{ verticalAlign: "baseline" }}>3. </td>
                                            <td>Dalam hal terjadi <i>force majeure</i>, Pihak yang tertimpa dan/atau terdampak oleh <i>force majeure</i>, harus memberitahukan secara tertulis atau melalui surat elektronik (<i>email</i>) kepada Pihak lain yang tidak tertimpa dan/atau terdampak oleh <i>force majeure</i>, selambat-lambatnya 3 (tiga) Hari Kalender sejak terjadinya <i>force majeure</i> dimaksud;</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td style={{ verticalAlign: "baseline" }}>4. </td>
                                            <td>Dalam hal <i>force majeure</i> berlanjut hingga dan/atau berlangsung lebih dari 30 (tiga puluh) Hari Kalender berturut-turut dan/atau berdasarkan besarnya dampak <i>force majeure</i> terhadap pelaksanaan Perjanjian ini, dengan berdasarkan itikad baik, Para pihak dapat melakukan konsultasi dan/atau musyawarah untuk memutuskan kelanjutan dan/atau keberlangsungan dari Perjanjian ini.</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td style={{ verticalAlign: "baseline" }}>5. </td>
                                            <td>Tidak satupun dari Para Pihak bertanggung jawab atas kerugian yang mungkin timbul dan/atau diderita oleh pihak lainnya dan/atau pihak ketiga lainnya yang disebabkan oleh suatu kegagalan atau keterlambatan pelaksanaan dari Perjanjian ini yang disebabkan oleh <i>force majeure</i>. Namun demikian, Pihak yang tertimpa dan/atau terdampak oleh <i>force majeure</i>, akan melakukan upaya yang sewajarnya untuk memenuhi kewajibannya dan/atau mengurangi kerugian bagi Para Pihak atas terjadinya <i>force majeure</i>;</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <p><b>14. Kerahasiaan</b></p>
            <div style={{ marginLeft: 20, marginBottom: 20 }}>
                <table>
                    <tbody>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>1. </td>
                            <td>Para Pihak sepakat dan setuju untuk menjaga kerahasiaan dari setiap data dan/atau data-data, catatan-catatan, ringkasan, perjanjian, kontrak, laporan maupun informasi, termasuk namun tidak terbatas pada laporan keuangan dan/atau prospektus dalam format dan bentuk apapun, yang diungkapkan oleh salah satu dari Para Pihak kepada Pihak lainnya dan/atau yang diperoleh berdasarkan Perjanjian ini dan/atau Perjanjian ikutan lainnya (untuk selanjutnya disebut “Informasi Rahasia”);</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>2. </td>
                            <td>Tidak satupun dari Para Pihak berhak dan/atau berwenang untuk melakukan pengungkapan kepada pihak ketiga, membuat pengumuman kepada khalayak umum dan/atau siaran pers yang berkaitan dengan subjek maupun objek dari perjanjian ini.</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>3. </td>
                            <td>Dalam hal, salah satu dari Para Pihak diwajibkan oleh perintah pengadilan, penetapan pengadilan, ketentuan hukum, peraturan perundang-undangan, peraturan dari badan dan/atau instansi pemerintah yang berwenang, termasuk namun tidak terbatas pada Otoritas Jasa Keuangan Republik Indonesia, untuk mengungkapkan Informasi Rahasia terkait dengan Perjanjian ini, Pihak yang terkena kewajiban tersebut wajib untuk melakukan pemberitahuan secara tertulis selambat-lambatnya 3 (tiga) Hari Kerja sebelumnya kepada Pihak lainnya. Selanjutnya, ketika pengungkapan Informasi Rahasia akan dilakukan oleh Pemodal, Pemodal wajib untuk meminta persetujuan tertulis terlebih dahulu dari Penyelenggara dan Penerbit sebelum pengungkapan dilakukan dan Penyelenggara tidak akan menunda dalam pemberian persetujuan tertulisnya tanpa alasan yang wajar.</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>4. </td>
                            <td>Kewajiban untuk menjaga kerahasiaan dari Informasi Rahasia dan/atau Perjanjian ini sebagaimana diatur dalam dalam Pasal ini akan tetap dan terus berlaku sekurang-kurangnya sampai dengan tiga (3) tahun setelah Perjanjian ini berakhir.</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{ verticalAlign: "baseline" }}>5. </td>
                            <td>Setiap saat, apabila diminta secara tertulis oleh pemilik Informasi Rahasia, seluruh Informasi Rahasia wajib dikembalikan oleh pihak yang memegang dan/atau menguasai Informasi Rahasia dimaksud kepada pemilik Informasi Rahasia. Akan tetapi, terhadap Informasi Rahasia yang tidak diminta untuk dikembalikan oleh pemilik Informasi Rahasia, wajib dimusnahkan dalam waktu selambat-lambatnya 7 (tujuh) hari setelah pemutusan dan/atau pengakhiran Perjanjian ini, dan pemegang dan/atau pihak yang menguasai Informasi Rahasia tersebut akan memberikan konfirmasi dan/atau pernyataan tertulis yang menyatakan bahwa pemusnahan tersebut telah dilakukan.</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <p><b>15. Penyelesaian dan Hukum</b></p>
            <div style={{ marginLeft: 20, marginBottom: 20 }}>
                <p>Syarat dan Ketentuan umum yang termuat pada Situs kerjasama.co.id diatur oleh dan diartikan secara keseluruhan sesuai dengan Hukum Negara Kesatuan Republik Indonesia. Para Pihak dengan ini secara tegas menyetujui untuk melaksanakan kewajiban mereka masing-masing sesuai dengan peraturan perundang-undangan yang berlaku saat ini dan/atau di kemudian hari.</p>
                <p>Segala perselisihan yang timbul dari pelaksanaan layanan urun dana maupun Syarat dan Ketentuan ini akan diselesaikan secara musyawarah untuk mufakat, apabila penyelesaian Sengketa secara musyawarah untuk mufakat tidak tercapai dalam waktu selambat-lambatnya 30 (tiga puluh) Hari Kalender, Para Pihak sepakat untuk menyelesaikan Sengketa yang timbul melalui Badan Arbitrase Nasional Indonesia (“BANI”) berdasarkan dan sesuai dengan ketentuan dan peraturan hukum Republik Indonesia serta aturan penyelesaian Sengketa yang yang berlaku pada BANI.</p>
            </div>
        </div>
    )
}

function PrivacyPolicy() {
    return(
        <div>
            <p style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 20 }}>KEBIJAKAN PRIVASI</p>

            <p><b>GAMBARAN SINGKAT</b></p>
            <ol style={{ marginLeft: '-20px', lineHeight: '1.5em' }}>
                <li>Kerjasama.co.id berkomitmen untuk melindungi dan menghormati privasi Anda.</li>
                <li>Kebijakan Privasi ini berlaku untuk semua orang dan entitas yang mengakses Situs Ini. Dengan mengakses dan/atau menggunakan layanan-layanan yang tersedia atau melalui Situs, Anda dengan ini mengakui bahwa Anda telah membaca dan setuju dengan ketentuan-ketentuan Kebijakan Privasi ini (beserta dengan Ketentuan-Ketentuan Penggunaan), Kebijakan Cookie kami, dan setiap dokumen lainnya yang dimaksud atasnya).</li>
                <li>Kebijakan Privasi (beserta Ketentuan-Ketentuan Penggunaan kami, Kebijakan Cookie kami, dan setiap dokumen lain yang dimaksud dalam Kebijakan Privasi ini) dirancang untuk membantu Anda mengerti bagaimana kami mengumpulkan, menggunakan, mengungkapkan dan/atau mengolah Data Pribadi yang Anda telah berikan kepada kami, serta membantu Anda dalam mengambil keputusan yang diberitahukan sebelum memberikan Data Pribadi Anda kepada kami. Bacalah hal-hal berikut ini dengan teliti untuk memahami pandangan dan kebiasaan kami sehubungan dengan Data Pribadi Anda dan bagaimana kami akan memperlakukannya.</li>
            </ol>

            <p><b>DEFINISI</b></p>
            <ol style={{ marginLeft: '-20px', lineHeight: '1.5em' }}>
                <li>"Kerjasama.co.id" memiliki arti yang sama sebagaimana ditetapkan dalam Ketentuan-Ketentuan Penggunaan.</li>
                <li>"Grup Kerjasama.co.id" memiliki arti yang sama sebagaimana ditetapkan dalam Ketentuan-Ketentuan Penggunaan.</li>
                <li>"Kebijakan Cookie" mengacu kepada kebijakan cookie milik Situs Kerjasama.co.id.</li>
                <li>"Layanan Interaktif" memiliki arti yang sama sebagaimana ditetapkan dalam Ketentuan-Ketentuan Penggunaan.</li>
                <li>"Data Pribadi" berarti informasi atau data yang dapat digunakan secara langsung atau tidak langsung untuk mengidentifikasi seorang individu yang merupakan orang perseorangan.</li>
                <li>"Produk" memiliki arti yang sama sebagaimana ditetapkan dalam Ketentuan-Ketentuan Penggunaan.</li>
                <li>"Pihak Ketiga Yang Dipilih" mengacu kepada para pihak ketiga yang ditetapkan dalam pasal 4.5 di bawah.</li>
                <li>"Layanan" memiliki arti yang sama sebagaimana ditetapkan dalam Ketentuan-Ketentuan Penggunaan.</li>
                <li>"Situs" memiliki arti yang sama sebagaimana ditetapkan dalam Ketentuan-Ketentuan Penggunaan.</li>
                <li>"Konten Situs" memiliki arti yang sama sebagaimana ditetapkan dalam Ketentuan-Ketentuan Penggunaan.</li>
                <li>"Ketentuan-Ketentuan Penggunaan" mengacu kepada Ketentuan-Ketentuan Penggunaan di situs ini.</li>
                <li>"Konten Pihak Ketiga" memiliki arti yang sama sebagaimana ditetapkan dalam Ketentuan-Ketentuan Penggunaan.</li>
                <li>"Situs Pihak Ketiga" memiliki arti yang sama sebagaimana ditetapkan dalam Ketentuan-Ketentuan Penggunaan.</li>
                <li>"Para Pengguna" memiliki arti yang sama sebagaimana ditetapkan dalam Ketentuan-Ketentuan Penggunaan.</li>
                <li>"Kami" dan "Milik Kami" memiliki arti yang sama sebagaimana ditetapkan dalam Ketentuan-Ketentuan Penggunaan.</li>
                <li>"Anda" dan "Milik Anda" memiliki arti yang sama sebagaimana ditetapkan dalam Ketentuan-Ketentuan Penggunaan.</li>
            </ol>

            <p><b>PENGUMPULAN INFORMASI</b></p>
            <ol style={{ marginLeft: '-20px', lineHeight: '1.5em' }}>
                <li>
                    Dari waktu ke waktu, Kerjasama.co.id dapat mengumpulkan informasi dan data tentang Anda, termasuk informasi dan data:
                    <ul style={{ listStyleType: 'square', marginLeft: '15px' }}>
                        <li>bahwa diberikan oleh Anda atau diberikan oleh siapapun yang diizinkan oleh Anda;</li>
                        <li>dalam kaitannya dengan setiap kunjungan Anda ke Situs kami; dan</li>
                        <li>dari para pihak ketiga lainnya yang diizinkan oleh Anda dan sumber-sumber yang tersedia untuk umum termasuk lembaga atau biro pemeringkat kredit.</li>
                    </ul>
                </li>
                <li>Melalui Anda, Kerjasama.co.id juga dapat mengumpulkan informasi dan data tentang para anggota keluarga, teman, penerima manfaat, pengacara, pemegang saham, pemilik manfaat, kuasa, orang-orang di bawah perwaliamanatan, wali amanat, penjamin Anda, penyedia jaminan lainnya dan individu-individu lain (secara kolektif semua yang tersebut di atas yang adalah orang perseorangan, "Individu Yang Relevan").</li>
                <li>
                    Dalam hal ini, Kerjasama.co.id dapat mengumpulkan informasi dan data berikut yang dinyatakan dalam pasal 3.1 dan 3.2 sebagai berikut:
                    <ul style={{ listStyleType: 'square', marginLeft: '15px' }}>
                        <li>Setiap informasi atau data tentang Anda dan/atau Individu Yang Relevan tersebut yang dapat mengidentifikasi Anda atau Individu Yang Relevan tersebut, termasuk tetapi tidak terbatas pada nama, tanggal lahir, KTP, paspor atau nomor atau keterangan identifikasi lain, alamat, alamat email, nomor telepon, rincian kontak, informasi keuangan dan kartu kredit, deskripsi dan foto pribadi, informasi portofolio produk dan layanan, pola dan perilaku transaksi, latar belakang keuangan, sejarah pendanaan, latar belakang pendidikan dan data kependudukan;</li>
                        <li>Setiap informasi dan data tentang Anda dan/atau Individu yang relevan tersebut dihasilkan dan/atau diberikan kepada kami selama mengakses Situs kami dan melakukan kegiatan apapun pada Situs kami. Contohnya, username terdaftar Anda untuk Situs, hasil pencarian dan sejarah untuk Produk dan Layanan dalam Situs kami, sejarah penyerahan Produk, catatan transaksi untuk Layanan dan Produk yang diberikan melalui Situs kami, catatan partisipasi dalam setiap Layanan Interaktif pada situs kami, dan jawaban Anda atas pertanyaan-pertanyaan yang ditujukan untuk verifikasi keamanan;</li>
                        <li>Setiap informasi dan data tentang Anda dan Individu Yang Relevan tersebut dihasilkan dan/atau diberikan kepada kami dalam perjalanan mempertahankan hubungan antara kami dan Anda termasuk laporan tinjauan akun dan catatan korespondensi dengan Anda melalui telepon atau melalui email;</li>
                        <li>
                            Setiap informasi dan data tentang Anda yang dikumpulkan dalam kaitannya dengan setiap kunjungan Anda ke Situs kami termasuk:
                            <ol style={{ marginLeft: '-25px', lineHeight: '1.5em' }}>
                                <li>informasi teknis, termasuk alamat Internet Protocol (IP) yang digunakan untuk menghubungkan komputer Anda dengan Internet, informasi login Anda, tipe dan versi browser, pengaturan zona waktu, tipe dan versi browser plug-in, sistem operasi dan platform;</li>
                                <li>informasi kunjungan situs, termasuk semua Uniform Resources Locators (URL) clickstream ke, melalui dan dari Situs kami (termasuk tanggal dan waktu); produk-produk yang Anda lihat atau cari; waktu respon halaman, kesalahan unduh, lama kunjungan ke laman-laman tertentu, informasi interaksi laman (seperti scrolling, klik, dan mouse-over), dan metode-metode yang digunakan untuk pindah dari laman tersebut dan nomor telepon apapun yang digunakan untuk menghubungi nomor layanan pelanggan kami; dan</li>
                                <li>
                                    informasi dan data dari cookies atau teknologi lainnya yang digunakan pada Situs kami. Untuk informasi lebih lanjut tentang cookie dan teknologi tersebut dan tujuan kami menggunakannya, lihat di Kebijakan Cookie kami.
                                    <ul style={{ listStyleType: 'square', marginLeft: '15px' }}>
                                        <li>Setiap informasi dan data tentang Anda yang dikumpulkan oleh kami ketika Anda berpartisipasi dalam acara yang kami selenggarakan, seperti pesta, seminar, program kontes atau penghargaan, yang dapat termasuk foto, rekaman video dan suara Anda;</li>
                                        <li>Setiap informasi dan data tentang Anda yang kami terima jika Anda menggunakan website lain apapun yang dioperasikan oleh kami, afiliasi dan anak perusahaan kami atau layanan lain yang disediakan oleh kami, afiliasi dan anak perusahaan kami. Dalam hal tersebut, kami akan memberitahu Anda dan mendapatkan persetujuan Anda untuk pengumpulan informasi atau data tentang Anda dengan Kebijakan Privasi yang terkait yang terkandung dalam website lain yang dioperasikan oleh kami.</li>
                                        <li>Setiap informasi dan data tentang Anda yang kami terima dari para pihak ketiga Yang Dipilih (ditetapkan pada pasal [4.5] di bawah);</li>
                                    </ul>
                                </li>
                            </ol>
                        </li>
                    </ul>
                </li>
                <li>Informasi dan data tentang Anda dan Individu yang relevan yang dimaksud di atas pada pasal [3.3] secara kolektif merupakan bagian dari data Pribadi.</li>
            </ol>

            <p><b>PENGGUNAAN DAN PENGUNGKAPAN INFORMASI DAN DATA PRIBADI</b></p>
            <ol style={{ marginLeft: '-20px', lineHeight: '1.5em' }}>
                <li>
                    Apabila dianggap perlu dan pantas oleh Kerjasama.co.id dan sesuai dengan hukum, aturan, dan peraturan yang berlaku, Data Pribadi dapat digunakan dan diungkapkan oleh Kerjasama.co.id dan/atau Grup Kerjasama.co.id untuk tujuan berikut ini:
                    <ul style={{ listStyleType: 'square', marginLeft: '15px' }}>
                        <li>memberikan akses kepada Anda dan memfasilitasi penggunaan Situs kami dan memastikan bahwa Konten Situs, Produk, Layanan, Layanan Interaktif, Konten Pihak Ketiga dan Situs Pihak Ketiga ditampilkan dengan cara yang paling efektif untuk Anda dan Perangkat Anda;</li>
                        <li>memberikan kepada Anda informasi, Konten Situs, Produk, Layanan dan Layanan Interaktif yang Anda minta dari kami dan akses ke Situs Pihak Ketiga dan Konten Pihak Ketiga;</li>
                        <li>melaksanakan kewajiban kami yang timbul dari setiap kontrak yang diadakan antara Anda dan kami;</li>
                        <li>memberikan kepada Anda informasi tentang Produk dan Layanan lain yang kami tawarkan yang serupa dengan Produk dan Layanan yang Anda telah beli atau tanyakan;</li>
                        <li>memberikan kepada Anda, atau mengizinkan Para Pihak Ketiga Yang Dipilih untuk memberikan informasi tentang Produk atau Layanan kepada Anda yang kami rasa mungkin menarik bagi Anda. Apabila Anda telah menjadi Pengguna, kami hanya akan menghubungi Anda melalui email, SMS atau telepon untuk memberikan Informasi tentang Produk dan Layanan yang serupa dengan yang sebelumnya telah Anda pertimbangkan, pendanaan atau manfaatkan. Jika Anda seorang pelanggan baru, dan apabila Anda setuju untuk mengungkapkan Data Pribadi Anda kepada Para Pihak Ketiga Yang Dipilih, kami (atau Para Pihak Ketiga Yang Dipilih) dapat menghubungi Anda melalui email atau SMS untuk memberikan informasi tentang Produk atau Layanan;</li>
                        <li>memberitahu Anda tentang perubahan-perubahan di Situs, Konten Situs, Produk, Layanan, Layanan Interaktif, Konten Pihak Ketiga dan Situs Pihak Ketiga;</li>
                        <li>mengelola Situs kami dan untuk pengoperasian internalnya, termasuk pemecahan masalah (troubleshooting), analisis data, pengujian, penelitian, tujuan statistik dan survei;</li>
                        <li>meningkatkan Situs kami untuk memastikan bahwa Konten Situs, Produk, Layanan, Layanan Interaktif, Konten Pihak Ketiga dan Situs Pihak Ketiga disampaikan dengan cara yang paling efektif untuk Anda dan Perangkat Anda;</li>
                        <li>memungkinkan Anda untuk berpartisipasi dalam Layanan Interaktif dan fitur interaktif lainnya dari Situs, Produk dan Layanan kami, jika Anda memilih untuk melakukannya;</li>
                        <li>menerapkan dan meninjau langkah-langkah pengamanan dan perlindungan pada Situs kami sehingga menjaga Situs kami tetap aman dan terjamin;</li>
                        <li>menilai atau memahami efektivitas iklan kami dan iklan pihak ketiga pada Situs kami dan pada website pihak ketiga lain, dan untuk menyampaikan iklan yang relevan kepada Anda;</li>
                        <li>membuat saran dan rekomendasi untuk Anda dan Para Pengguna lain Situs kami tentang Produk, Layanan atau produk dan layanan pihak ketiga yang mungkin menarik bagi Anda atau mereka;</li>
                        <li>membuat keputusan yang berkaitan dengan pembukaan atau kelanjutan akun(-akun) Pengguna dan pembuatan, penyediaan atau kelanjutan dari Layanan(-Layanan) dan Produk(-Produk) selain itu menjaga akurasi informasi "kenali pelanggan Anda" dan melakukan pemeriksaan anti pencucian uang, kredit dan latar belakang;</li>
                        <li>menyediakan, mengoperasikan, memproses dan mengelola akun(-akun) Pengguna, Layanan(-Layanan) dan Produk(-Produk) atau mengelola aplikasi-aplikasi untuk Akun(-Akun) Pengguna, Layanan(-Layanan) dan Produk(-Produk), setiap transaksi (termasuk transaksi pengiriman uang), dan menjaga kualitas layanan dan melatih staf;</li>
                        <li>melakukan kegiatan-kegiatan yang berkaitan dengan penyediaan akun(-akun) Pengguna, Layanan(-Layanan) dan Produk(-Produk), termasuk langganan atau usulan langganan atas Produk(-Produk) atau Layanan(-Layanan) (baik yang ditawarkan atau yang dikeluarkan oleh Kerjasama.co.id atau yang lainnya) dan penyediaan laporan penelitian atau bahan untuk analisis;</li>
                        <li>menyediakan layanan dan dukungan terkait Produk, termasuk tetapi tidak terbatas pada, penyediaan dukungan pengelolaan atau administratif atau bertindak sebagai perantara/pihak yang ditunjuk (nominee)/agen sehubungan dengan partisipasi Anda dalam Produk(-Produk);</li>
                        <li>menyediakan atau memberikan akses kepada materi-materi terkait Produk seperti dokumen penawaran, laporan penelitian, profil produk, lembar fakta, lembar ketentuan (term sheet) atau materi-materi lain terkait produk;</li>
                        <li>memenuhi persyaratan-persyaratan hukum, regulasi dan kepatuhan di dalam dan luar negeri yang berlaku pada Kerjasama.co.id dan Grup Kerjasama.co.id berdasarkan undang-undang yang berlaku (termasuk anti pencucian uang dan kewajiban pajak yang berlaku pada Kerjasama.co.id dan Grup Kerjasama.co.id, dan pengungkapan pada setiap bursa efek, pengadilan, otoritas pemerintah, otoritas pengawas atau regulator lain apapun sesuai dengan pedoman, peraturan, perintah, petunjuk atau permintaan yang relevan dari organisasi-organisasi tersebut) dan mematuhi setiap perjanjian internasional atau perjanjian yang berlaku dengan atau antara pemerintah asing dan dalam negeri yang berlaku pada Kerjasama.co.id dan Grup Kerjasama.co.id;</li>
                        <li>memeriksa identitas dan/atau kewenangan dari para kuasa Anda yang menghubungi Kerjasama.co.id atau Grup Kerjasama.co.id atau dapat dihubungi oleh Kerjasama.co.id atau Grup Kerjasama.co.id dan untuk melaksanakan atau menanggapi permintaan, pertanyaan atau instruksi dari para kuasa yang telah diperiksa atau para pihak lain sesuai dengan prosedur pengamanan kami yang berlaku;</li>
                        <li>melakukan penilaian risiko, analisis dan perencanaan statistik dan tren, termasuk melaksanakan pengolahan data, analisis statistik, kredit, risiko dan anti pencucian uang, membuat dan mengelola model penilaian kredit, melakukan pemeriksaan dan peninjauan kredit dan latar belakang lain, dan menyimpan sejarah kredit Anda dan kuasa Anda yang sah (terlepas apakah ada hubungan langsung antara kuasa yang sah tersebut dengan Kerjasama.co.id atau Grup Kerjasama.co.id atau tidak) untuk referensi saat ini dan di masa mendatang;</li>
                        <li>memantau dan mencatat panggilan dan komunikasi elektronik dengan Anda untuk tujuan pencatatan, kualitas, pelatihan, investigasi, dan pencegahan penipuan;</li>
                        <li>mendeteksi, mencegah, melakukan penyidikan dan mendakwa kejahatan dan penipuan termasuk membantu dalam setiap penyidikan kejahatan oleh otoritas yang relevan terhadap Anda, Individu Yang Relevan atau orang lain;</li>
                        <li>melaksanakan (termasuk, namun tidak terbatas pada, penagihan jumlah yang terutang kepada Kerjasama.co.id dan/atau Grup Kerjasama.co.id) atau membela hak-hak Kerjasama.co.id atau Grup Kerjasama.co.id, yang berasal dari kontrak atau lainnya;</li>
                        <li>melakukan menajemen internal untuk mengoperasikan sistem informasi kontrol dan manajemen dan melaksanakan audit internal atau mengizinkan pelaksanaan audit eksternal;</li>
                        <li>memungkinkan penerima hak yang sebenarnya atau yang diajukan dari Kerjasama.co.id, atau peserta atau sub-peserta dari hak-hak Kerjasama.co.id sehubungan dengan Anda, untuk mengevaluasi transaksi yang dimaksudkan untuk menjadi obyek dari pengalihan, partisipasi atau sub-partisipasi;</li>
                        <li>mematuhi pengaturan kontrak oleh atau antara industri otonom, badan industri, asosiasi penyedia industri atau lembaga lain yang serupa dengan Kerjasama.co.id atau Grup Kerjasama.co.id; dan</li>
                        <li>mengelola hubungan Kerjasama.co.id dengan Anda, yang dapat termasuk menyediakan informasi kepada Anda atau Individu Yang Relevan, tentang produk dan layanan Kerjasama.co.id atau Grup Kerjasama.co.id, apabila secara khusus disetujui atau apabila diizinkan berdasarkan peraturan perundang-undangan yang berlaku;</li>
                        <li>untuk memasarkan produk atau layanan keuangan atau yang terkait dengan pinjaman melalui berbagai moda komunikasi termasuk surat, telepon, SMS, fax, email, internet, riset pasar, dan merancang atau menyaring produk atau layanan yang serupa termasuk dengan melakukan riset pasar, analisis data, dan survei;</li>
                        <li>mematuhi setiap kewajiban, persyaratan, kebijakan, prosedur, penilaian atau pengaturan untuk berbagi data dan informasi dalam Kerjasama.co.id atau Grup Kerjasama.co.id dan penggunaan data dan informasi lainnya sesuai dengan setiap program Grup Kerjasama.co.id untuk pemenuhan sanksi atau pencegahan atau pendeteksian pencucian uang, pendanaan teroris atau kegiatan melanggar hukum lainnya.</li>
                    </ul>
                </li>
                <li>Kami dapat menggabungkan setiap informasi dan data, terlepas dari bagaimana informasi dan data tersebut diberikan kepada kami atau dikumpulkan oleh kami, dan menggunakan informasi atau data yang digabungkan untuk tujuan yang ditetapkan dalam pasal [4.1] di atas.</li>
                <li>Kami juga dapat menggunakan dan mengungkapkan Data Pribadi untuk tujuan lain yang berkaitan dengan hal-hal apapun di atas sebagaimana ditetapkan dalam pasal 4.1 dan 4.2, yang orang normal akan menganggap sesuai dengan situasi tersebut.</li>
                <li>Sebagaimana tujuan dari kami dapat mengumpulkan, menggunakan, mengungkapkan atau mengolah Data Pribadi Anda tergantung pada keadaan pada saat itu, tujuan tersebut mungkin tidak muncul dalam pasal di atas. Namun, kami akan memberitahu Anda tentang tujuan lain tersebut pada saat meminta persetujuan Anda untuk pengumpulan Data Pribadi tersebut, kecuali pengolahan Data Pribadi Anda tanpa persetujuan Anda yang diizinkan oleh hukum.</li>
                <li>
                    Kami juga dapat berbagi Data Pribadi dengan Para Pihak Ketiga Yang Dipilih termasuk:
                    <ul style={{ listStyleType: 'square', marginLeft: '15px' }}>
                        <li>para mitra usaha, pemasok dan sub-kontraktor untuk pelaksanaan setiap kontrak yang kami adakan dengan mereka atau Anda;</li>
                        <li>para pengiklan dan jaringan-jaringan iklan yang memerlukan data untuk memilih dan menyediakan iklan yang relevan kepada Anda dan orang lain. Kami tidak mengungkapkan informasi tentang individu yang dapat diidentifikasi kepada para pengiklan, tetapi kami dapat memberikan mereka informasi agregat tentang Para Pengguna kami (misalnya, kami dapat memberitahu mereka bahwa 500 pria berusia di bawah 30 tahun mengklik iklan mereka pada hari tertentu). Kami juga bisa menggunakan informasi agregat tersebut untuk membantu para pengiklan menjangkau jenis audiens yang mereka ingin targetkan. Kami dapat menggunakan informasi atau data yang dikumpulkan dari Anda untuk memungkinkan kami memenuhi keinginan para pengiklan kami dengan menampilkan iklan mereka kepada audiens yang menjadi target;</li>
                        <li>penyedia analisis dan mesin pencari yang membantu kami dalam peningkatan dan optimalisasi situs kami;</li>
                        <li>calon penjual atau pembeli bisnis atau aset apabila kami menjual atau membeli bisnis atau aset apapun, dalam hal ini kami dapat mengalihkan Data Pribadi Anda kepada pembeli atau penjual tersebut sebagai bagian dari transaksi;</li>
                        <li>setiap organisasi atau orang tersebut, jika kami berada di bawah kewajiban untuk mengungkapkan atau membagikan Data Pribadi Anda untuk mematuhi setiap kewajiban hukum, atau untuk melaksanakan atau menerapkan Ketentuan-Ketentuan Penggunaan kami, Kebijakan Cookie kami, dan perjanjian lainnya, atau untuk melindungi hak, aset, atau keamanan dari Kerjasama.co.id dan Grup Kerjasama.co.id, pelanggan kami, Para Pengguna atau lainnya. Ini termasuk bertukar informasi dengan perusahaan dan organisasi lain untuk tujuan perlindungan dari penipuan dan pengurangan risiko kredit;</li>
                        <li>agen, kontraktor atau penyedia layanan pihak ketiga yang menyediakan administrasi, pengiriman pos, telemarketing, telekomunikasi penjualan langsung, call center, proses bisnis, perjalanan, visa, manajemen pengetahuan, sumber daya manusia, pengolahan data, teknologi informasi, komputer, pembayaran, penagihan utang, referensi kredit atau pemeriksaan-pemeriksaan latar belakang lain atau layanan-layanan lain kepada Kerjasama.co.id atau Grup Kerjasama.co.id sehubungan dengan pengoperasian bisnis dari Kerjasama.co.id atau Grup Kerjasama.co.id;</li>
                        <li>orang atau entitas yang merupakan bagian dari Grup Kerjasama.co.id walaupun hanya sepanjang diperlukan untuk memenuhi Tujuan Yang Diizinkan yang relevan;</li>
                        <li>bank tertarik atau penarik sehubungan dengan setiap cek atau yang setara yang diproses oleh Kerjasama.co.id atau Grup Kerjasama.co.id;</li>
                        <li>lembaga keuangan yang dengannya Anda memiliki atau mengajukan untuk memiliki urusan terkait dengan setiap Produk dan/atau Layanan;</li>
                        <li>orang atau entitas yang kepada orang atau entitas tersebut Kerjasama.co.id atau Grup Kerjasama.co.id berkewajiban atau disyaratkan untuk melakukan pengungkapan sesuai dengan proses hukum atau berdasarkan persyaratan dari hukum, peraturan, perintah pengadilan di dalam dan luar negeri atau perjanjian yang diadakan, yang mengikat atau berlaku untuk Kerjasama.co.id atau Grup Kerjasama.co.id dan setiap otoritas pemerintah, di dalam atau luar negeri, atau antara dua otoritas pemerintah atau lebih di dalam atau di luar negeri, atau setiap pengungkapan berdasarkan dan untuk tujuan pedoman atau petunjuk yang diberikan atau dikeluarkan oleh badan penegak hukum, regulasi, badan pemerintah, otoritas pajak, lembaga penegak hukum atau otoritas-otoritas lain, atau peraturan otonom dari badan industri atau asosiasi dari penyedia jasa keuangan atau bursa efek yang disyaratkan atau diharapkan untuk dipenuhi oleh Kerjasama.co.id atau Grup Kerjasama.co.id atau setiap pengungkapan sesuai dengan setiap kontrak atau komitmen lain dari Kerjasama.co.id atau Grup Kerjasama.co.id dengan lembaga hukum, regulasi, badan pemerintah, pajak, lembaga penegak hukum atau otoritas-otoritas lain, atau badan otonom atau industri atau asosiasi dari penyedia jasa keuangan, atau bursa efek, semua yang mungkin di dalam atau di luar Singapura termasuk pengungkapan ke sidang pengadilan, pengadilan tribunal, dan/atau lembaga hukum, regulasi, pajak dan badan pemerintah, terlepas apakah proses hukum, kewajiban, permintaan, persyaratan, perjanjian atau pedoman tersebut saat ini masih ada atau dibuat di masa mendatang;</li>
                        <li>lembaga keuangan, agen proses, atau pihak lain yang akan terlibat dalam transaksi pengiriman uang atau kegiatan perbankan/keuangan apapun;</li>
                        <li>penerima pengalihan yang sebenarnya atau yang diajukan dari Kerjasama.co.id atau Grup Kerjasama.co.id, atau peserta atau sub-peserta atau penerima pengalihan hak-hak Kerjasama.co.id atau Grup Kerjasama.co.id sehubungan dengan Anda atau Individu Yang Relevan lain, semua atau sebagian dari aset atau bisnis dari Kerjasama.co.id atau Grup Kerjasama.co.id; dan </li>
                        <li>pihak yang memberikan atau mengajukan untuk memberikan jaminan atau jaminan pihak ketiga kepada penerima jaminan atau menjamin kewajiban-kewajiban Anda atau orang-orang dari Individu Yang Relevan manapun.</li>
                    </ul>
                </li>
                <li>Dalam hal ini, tujuan atas penggunaan dan pengungkapan informasi dan data tentang Anda yang dimaksud berdasarkan pasal 4 ini secara kolektif selanjutnya disebut sebagai "Tujuan Yang Diizinkan".</li>
            </ol>

            <p><b>DATA PRIBADI DAN LEMBAGA RUJUKAN KREDIT</b></p>
            <ol style={{ marginLeft: '-20px', lineHeight: '1.5em' }}>
                <li>
                    Kerjasama.co.id atau Grup Kerjasama.co.id dapat, dengan sendirinya dan/atau sebagai agen Anda, menyediakan informasi dan data yang relevan tentang Anda dan Individu Yang Relevan tersebut kepada lembaga-lembaga referensi kredit untuk tujuan memperbarui catatan kredit Anda dalam basis data kredit pelanggan dari lembaga-lembaga tersebut. Dalam hal ini, informasi dan data yang relevan tentang Anda dan Individu Yang Relevan akan sehubungan dengan Produk(-Produk) dan/atau Layanan(-Layanan) yang diadakan atau digunakan oleh Anda dan Individu Yang Relevan tersebut (apakah sebagai peminjam, pemberi hak tanggungan, penjamin atau penyedia jaminan dan apakah dengan nama Anda dan nama Individu Yang Relevan sendiri atau bersama dengan orang lain) dan informasi dan data ini akan termasuk:</li>
                <ul style={{ listStyleType: 'square', marginLeft: '15px' }}>
                    <li>nama panjang;</li>
                    <li>kapasitas sehubungan dengan Produk (sebagai peminjam, pemberi hak tanggungan, penjamin atau penyedia jaminan, dan apakah dengan nama sendiri atau dengan nama bersama dengan orang lain);</li>
                    <li>nomor identitas (misalnya, nomor kartu identitas, paspor atau KTP);</li>
                    <li>tanggal lahir;</li>
                    <li>alamat korespondensi;</li>
                    <li>akun atau nomor referensi berkenaan dengan setiap Produk;</li>
                    <li>jenis Produk yang diadakan dan/atau digunakan;</li>
                    <li>status akun (contohnya, aktif, ditutup, dihapus (selain karena putusan kepailitian), dihapus karena karena putusan kepailitian);</li>
                    <li>tren dan status pembayaran (misalnya, pembayaran semua atau sebagian, saat ini atau di akhir periode);</li>
                    <li>tanggal penutupan akun, jika ada, berkenaan dengan setiap Produk;</li>
                    <li>tanggal penggunaan Produk; dan</li>
                    <li>informasi umum tentang akun dan informasi mengenai setiap standar materi, jika ada, yang berkaitan dengan standar materi.</li>
                </ul>
                <li>Untuk tujuan menentukan risiko kredit Anda ketika Anda mengajukan untuk setiap Produk atau Layanan, Kerjasama.co.id atau Grup Kerjasama.co.id dapat mengakses dan memperoleh dari lembaga-lembaga referensi kredit informasi pribadi dan akun atau catatan tentang Anda dan Individu Yang Relevan tersebut (termasuk informasi tentang jumlah perhitungan fasilitas/pinjaman) yang disimpan oleh lembaga referensi kredit manapun dalam yurisdiksi yang relevan.</li>
                <li>
                    Kerjasama.co.id atau Grup Kerjasama.co.id dan Individu Yang Relevan dari waktu ke waktu dapat juga mengakses informasi pribadi dan akun atau catatan tentang Anda dan Individu Yang Relevan tersebut (termasuk informasi tentang perhitungan hak tanggungan) yang disimpan oleh lembaga referensi kredit untuk tujuan meninjau salah satu hal berikut dalam kaitannya dengan Produk yang sudah ada yang diberikan kepada Anda atau pihak ketiga yang kewajibannya dijamin oleh Anda:
                    <ul style={{ listStyleType: 'square', marginLeft: '15px' }}>
                        <li>peningkatan jumlah kredit;</li>
                        <li>pengetatan kredit (termasuk pembatalan kredit atau penurunan jumlah kredit);</li>
                        <li>pemberian Produk tambahan; atau</li>
                        <li>penempatan atau pelaksanaan skema pengaturan yang berkaitan dengan Anda atau pihak ketiga manapun yang kewajibannya dijamin oleh Anda.</li>
                    </ul>
                </li>
                <li>Kerjasama.co.id atau Grup Kerjasama.co.id juga dapat memperoleh laporan kredit mengenai Anda dari lembaga referensi kredit dalam mempertimbangkan setiap permohonan dari Anda untuk mendaftarkan dan mengunggah suatu Produk pada Situs kami untuk langganan. Dalam hal ini, jika Anda ingin mengakses laporan kredit tentang Anda tersebut, Kerjasama.co.id akan memberikan kepada Anda rincian kontak dari lembaga referensi kredit yang terkait kepada Anda untuk mendapatkan laporan secara independen.</li>
            </ol>

            <p><b>ADMINISTRASI DAN PENGELOLAAN DATA PRIBADI ANDA</b></p>
            <span style={{ lineHeight: '1.5em' }}>Kami akan melakukan upaya yang wajar untuk memastikan bahwa Data Pribadi Anda akurat dan lengkap, jika Data Pribadi Anda kemungkinan akan digunakan oleh Kerjasama.co.id untuk membuat keputusan yang mempengaruhi Anda, atau mengungkapkan kepada organisasi lain. Namun, ini berarti bahwa Anda harus juga memberitahukan kepada kami setiap perubahan pada Data Pribadi Anda yang awalnya Anda berikan kepada kami. Kami tidak akan bertanggung jawab untuk mengandalkan Data Pribadi yang tidak akurat dan tidak lengkap yang diakibatkan karena Anda tidak memberitahukan kepada kami setiap perubahan pada Data Pribadi Anda yang awalnya Anda berikan kepada kami.</span>
            <ol style={{ marginLeft: '-20px', lineHeight: '1.5em' }}>
                <li>Kami juga akan menempatkan pengaturan pengamanan yang wajar untuk memastikan Data Pribadi Anda cukup terlindungi dan aman. Pengaturan pengamanan yang sesuai akan dilakukan untuk mencegah akses, pengumpulan, penggunana, pengungkapan, penyalinan, perubahan, kebocoran, kehilangan, kerusakan dan/atau perubahan yang tidak sah terhadap Data Pribadi Anda. Namun, kami tidak menanggung suatu tanggung jawab apapun atas setiap penggunaan tidak sah dari Data Pribadi Anda oleh para pihak ketiga yang sepenuhnya disebabkan oleh faktor-faktor di luar kendali kami.</li>
                <li>Kami juga akan menempatkan langkah-langkah yang memastikan Data Pribadi Anda yang ada di bawah penguasaan atau berada di bawah kendali kami dimusnahkan dan/atau dijadikan bersifat anonim sesegera mungkin saat dianggap wajar untuk mengasumsikan bahwa (i) tujuan Data Pribadi itu dikumpulkan tidak lagi dipenuhi dengan penyimpanan Data Pribadi tersebut; dan (ii) penyimpanan tidak lagi perlu dilakukan untuk tujuan hukum atau bisnis lain apapun.</li>
                <li>Apabila Data Pribadi Anda akan dipindahkan dari negara manapun di tempat Anda mengakses Situs ini, kami akan mematuhi hukum yang berlaku dalam melakukannya.</li>
            </ol>

            <p><b>HAK UNTUK MENOLAK PENGGUNAAN DATA PRIBADI UNTUK TUJUAN PEMASARAN</b></p>
            <p><span style={{ lineHeight: '1.5em' }}>Anda memiliki hak untuk meminta kami agar tidak mengolah Data Pribadi Anda untuk tujuan pemasaran. Kami akan memberitahu Anda dan meminta persetujuan dari Anda sebelum kami menggunakan Data Pribadi Anda untuk tujuan tersebut atau jika kami bermaksud mengungkapkan Data Pribadi Anda kepada pihak ketiga manapun untuk tujuan tersebut. Anda dapat menggunakan hak Anda untuk menolak pengolahan tersebut dengan cara memberikan tanda pada formulir yang terkait yang kami gunakan untuk mengumpulkan data Anda. Bahkan setelah Anda memberikan persetujuan Anda atas Data Pribadi yang akan digunakan untuk tujuan pemasaran, Anda dapat, setiap saat setelah itu, menarik persetujuan Anda dengan menghubungi kami di cs@kerjasama.co.id.</span></p>

            <p><b>LINK KE SITUS LAIN</b></p>
            <p><span style={{ lineHeight: '1.5em' }}>Situs kami dapat, dari waktu ke waktu, berisi link ke Situs-Situs Pihak Ketiga dan website dari jaringan mitra, pengiklan, afiliasi atau anak perusahaan kami. Jika Anda mengikuti suatu link ke salah satu website tersebut, perlu Anda ketahui bahwa website tersebut dapat memiliki atau tidak memiliki kebijakan privasi mereka sendiri dan bahwa kami tidak menerima tanggung jawab atau kewajiban atas kebijakan-kebijakan ini atau tidak adanya kebijakan-kebijakan ini. Bacalah kebijakan ini sebelum Anda menyerahkan informasi apapun termasuk Data Pribadi Anda pada website tersebut.</span></p>

            <p><b>AKSES KE INFORMASI</b></p>
            <ol style={{ marginLeft: '-20px', lineHeight: '1.5em' }}>
                <li>Anda memiliki hak untuk meminta dari kami Data Pribadi tentang Anda yang disimpan oleh Kerjasama.co.id. Jika Anda ingin mengakses, memutakhirkan atau mengubah atau menghapus setiap informasi yang Anda berikan kepada kami, hubungi kami di cs@kerjasama.co.id untuk mendapatkan bantuan. Setiap permintaan akses dapat dikenakan biaya sebesar [jumlah biaya yang dibebankan] untuk memenuhi biaya kami dalam menyediakan detail informasi yang kami miliki tentang Anda kepada Anda.</li>
                <li>
                    Dalam keadaan-keadaan luar biasa, Kerjasama.co.id dapat menolak akses Anda ke Data Pribadi Anda tetapi kami akan memberikan kepada Anda penjelasan tentang alasan(-alasan) yang mencegah kami melakukan hal itu berdasarkan hukum yang berlaku. Keadaan-keadaan luar biasa tersebut termasuk tetapi tidak terbatas pada apabila:
                    <ul style={{ listStyleType: 'square', marginLeft: '15px' }}>
                        <li>Otoritas yang melakukan penyelidikan atau lembaga pemerintah berkeberatan terhadap pemenuhan permintaan pelanggan oleh Kerjasama.co.id;</li>
                        <li>Informasi dapat, dalam pelaksanaan kewenangan dan/atau penilaian yang wajar dari Kerjasama.co.id, mempengaruhi hidup atau keamanan seorang individu; dan</li>
                        <li>Data dikumpulkan sehubungan dengan investigasi terhadap pelanggaran kontrak, kecurigaan terhadap aktivitas-aktivitas penipuan atau pelanggaran hukum.</li>
                    </ul>
                </li>
            </ol>

            <p><b>PERUBAHAN TERHADAP KEBIJAKAN PRIVASI KAMI</b></p>
            <p><span style={{ lineHeight: '1.5em' }}>Setiap perubahan yang kami lakukan terhadap Kebijakan Privasi kami di masa mendatang akan diposting pada halaman ini dan, jika perlu, diberitahukan kepada Anda melalui email. Harap sering memeriksa kembali untuk melihat pemutakhiran (update) atau perubahan terhadap kebijakan privasi kami.</span></p>

            <p><b>ANAK DI BAWAH UMUR</b></p>
            <p><span style={{ lineHeight: '1.5em' }}>Kerjasama.co.id tidak dapat membedakan usia orang yang mengakses dan menggunakan website-nya. Jika seorang anak di bawah umur (menurut hukum yang berlaku) telah memberikan Data Pribadinya kepada Kerjasama.co.id tanpa persetujuan orang tua atau walinya, orang tua atau walinya itu harus menghubungi Kerjasama.co.id untuk menghapus Data Pribadi yang terkait dan menghapus nama anak di bawah umur tersebut dari daftar pelanggannya.</span></p>

            <p><b>PENGAKUAN</b></p>
            <ol style={{ marginLeft: '-20px', lineHeight: '1.5em' }}>
                <li>Anda mengakui bahwa apabila Anda tidak memberikan informasi atau data yang relevan atau menarik persetujuan sehubungan dengan pengumpulan, penggunaan dan/atau pengungkapan informasi atau data yang relevan sebagaimana dijelaskan dalam Kebijakan Privasi, Kerjasama.co.id berpeluang untuk tidak dapat membuka atau melanjutkan akun Pengguna, atau membuat atau memberikan atau melanjutkan setiap Produk dan Layanan, atau terus mengizinkan Anda menggunakan atau mengakses Situs, Konten Situs, Produk, Layanan atau Layanan Interaktif kami. Kerjasama.co.id atau Grup Kerjasama.co.id akan memberitahu Anda tentang konsekuensi dari penarikan persetujuan tersebut seandainya Anda memberitahukan kepada kami tentang keinginan Anda untuk menarik persetujuan Anda.</li>
                <li>Anda menjamin kepada Kerjasama.co.id dan Grup Kerjasama.co.id bahwa apabila Anda atau kuasa Anda bertanggung jawab atas penyediaan setiap informasi atau data berkaitan dengan siapapun kepada Kerjasama.co.id dan Grup Kerjasama.co.id, atau menyediakan setiap informasi atau data tersebut kepada Kerjasama.co.id dan Grup Kerjasama.co.id, Anda telah memberitahukan dan mendapatkan persetujuan dari orang(-orang) yang bersangkutan untuk mengizinkan Kerjasama.co.id dan/atau Grup Kerjasama.co.id untuk mengumpulkan, menggunakan dan/atau mengungkapkan informasi berkaitan dengan orang(-orang) yang bersangkutan sebagaimana dijelaskan dalam Kebijakan Privasi ini.</li>
                <li>Anda setuju bahwa Anda telah memperoleh persetujuan dari semua Individu Yang Relevan tersebut sehingga setiap infromasi atau data dari Individu Yang Relevan dapat diolah, disimpan, ditransfer atau diungkapkan dalam dan kepada negara manapun sebagaimana yang dianggap oleh Kerjasama.co.id dan Grup Kerjasama.co.id sesuai dengan hukum yang berlaku untuk Tujuan Yang Diizinkan. Data tersebut juga dapat diolah, disimpan, ditansfer atau diungkapkan sesuai dengan hukum dan praktik, aturan dan peraturan setempat (termasuk setiap permintaan berdasarkan peraturan, tindakan dan perintah pemerintah) di negara/yurisdiksi tersebut.</li>
                <li>Sesuai dengan Ketentuan-Ketentuan Penggunaan, Kebijakan Cookie dan [Perjanjian Induk], Anda setuju bahwa Kerjasama.co.id dapat mengubah dan memodifikasi ketentuan-ketentuan dari Kebijakan Privasi ini dari waktu ke waktu dan bawa setelah pemberitahuan kepada Anda terhadap Kebijakan Privasi yang diubah tersebut, konten-konten daripadanya sama halnya akan mengubah, meragamkan dan melengkapi Ketentuan-Ketentuan Penggunaan, Kebijakan Cookie dan [Perjanjian Induk], perjanjian-perjanjian dan/atau pengaturan-pengaturan tersebut di atas akan berlaku sejak tanggal yang ditetapkan dalam Kebijakan Privasi yang diubah tersebut, dan tanpa mengurangi hal tersebut di atas, akses/penggunaan atau kelanjutan akses/penggunaan Anda atas Situs, Konten Situs, Produk, Layanan, Layanan Interaktif setelah perubahan tersebut juga harus dianggap sebagai penerimaan dan persetujuan Anda pada hal-hal tersebut.</li>
            </ol>

            <p><b>KONTAK</b></p>
            <p><span style={{ lineHeight: '1.5em' }}>Pertanyaan, komentar dan permintaan mengenai Kebijakan Privasi ini diterima dan dapat diarahkan kepada Petugas Perlindungan Data Kerjasama.co.id di alamat berikut: Komplek Pluit Mas Blok BB No. 3-4, Kel. Pejagalan, Kec. Penjaringan, Jakarta Utara 14450. </span></p>
        </div>
    )
}

function mapStateToProps(state) {
    return { regUser: state.userReducer.regUser }
}

function mapDispatchToProps(dispatch) {
    return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(DaftarPengelola);
