import React, { memo, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    Link,
} from "react-router-dom";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import seru from "../../../assets/img/seru.png";
import seru2 from "../../../assets/img/Group 895.png";
import proyk from "../../../assets/img/Group 863.png";
import dompet from "../../../assets/img/dompet.png";
import bali from "../../../assets/img/bali.png";
import icon_pengelola from "../../../assets/img/Icon Pengelola.svg";
import { BASE_URL, tokenGet } from "../../../service/api";
import '../../../App.scss'
import '../../../Bg.scss'
import DetailDompet from '../../DetailDompet'
import axios from 'axios'
import $ from 'jquery'
import {
    Button, Message,
    Checkbox,
    Grid,
    Card,
    Header,
    Icon,
    Image,
    Menu,
    Segment,
    Sidebar,
    Input,
    Label,
    List,
    StatisticValue,
} from 'semantic-ui-react'
import { connect } from "react-redux";
import { BallBeat } from 'react-pure-loaders';
import { getUserpemodal } from "../../../actions/userpemodal.actions";
import Navbar_user from "../../../components/navbar_home";
import swal from 'sweetalert';

class BayarStep1 extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            error: null,
            items: [],
            paramsPembayaranHead: 1,
            c: false,
            userinfo: [],
            isLoad: false,
            dataProyekTersimpan: [],
        };
    }

    getUserpemodal = async (values) => {

        try {
            // console.log(this.props.auth.isUserId);
            const response = await this.props.dispatch(getUserpemodal(values));

            localStorage.DataUser = JSON.stringify(response.responseBody);
            // console.log(response.responseBody.success);

            if (!response.responseBody.success) {
                throw response;
            } else if (response.responseBody.success) {
                if (!this.props.location.data) {
                    // alert('Maaf Refresh dilarang pada saat melakukan pembayaran, anda akan dikembalikan ke halaman dashboard utama')
                    // window.setTimeout(function(){
                    //     window.location.href = "/UserPemodal"
                    // },0);
                    swal({
                        text: 'Maaf Refresh dilarang pada saat melakukan pembayaran, anda akan dikembalikan ke halaman dashboard utama',
                        icon: 'warning',
                        closeOnClickOutside: false
                    }).then((value) => {
                        window.setTimeout(function () {
                            window.location.href = "/UserPemodal"
                        }, 0);
                    });

                } else {
                    this.state.userinfo = JSON.stringify(response.responseBody.user);
                    this.state.isLoad = true;
                    this.getListProyekTersimpan();
                    $("body").addClass('bg');
                }
            }

        } catch (error) {
            setTimeout(() => {
                window.location.href = "/Signin"
                localStorage.removeItem('token');
                localStorage.clear();
        
                this.props.dispatch({
                  type: "AUTH_USER_LOGOUT",
                })
            }, 2000);
        }
    }

    getListProyekTersimpan() {
        const userinfo = JSON.parse(this.state.userinfo)

        axios.get(BASE_URL + "/customer/pemodal/proyektersimpan", {
            params: {
                id_pemodal: userinfo.id,
                type: 0,
                limit: 10,
                status: 0
            }
        },
            axios.defaults.headers.common['Authorization'] = localStorage.token,
            axios.defaults.headers.common['Accept'] = 'application/json'
        )
            .then((resp) => {

                var lengthdataSlot = resp.data.data.length;

                this.setState({
                    dataProyekTersimpan: resp.data.data,
                    totalSlot: lengthdataSlot,
                    totalProyek: lengthdataSlot,
                })

                var tmpSaldoPemb = 0
                resp.data.data.map((answer, i) => {
                    var postSlot = {
                        "ProyekID": answer.id_project,
                        "HargaSlot": answer.proyek_overview.slot_nominal * answer.slot,
                        // "Slot"     : 1
                        "Slot": answer.slot
                    }

                    var tmpPembayaran = answer.proyek_overview.slot_nominal * answer.slot
                    tmpSaldoPemb = parseInt(tmpSaldoPemb) + parseInt(tmpPembayaran)

                    this.setState({
                        dataSlot: [...this.state.dataSlot, postSlot],
                        totalPembayaran: tmpSaldoPemb
                    })
                })

            })
            .catch((resp) => {

            });
    }

    clearConsole() {
        if (window.console || window.console.firebug) {
            console.clear();
        }
    }

    render() {
        const verified = this.props.verified
        const verified_advance = this.props.verified_advance
        const { error, isLoaded, items } = this.state;

        if (!this.state.isLoad) {

            const d = this.getUserpemodal({ token: this.props.auth.isUserId });

            return (
                <div
                    style={{ paddingTop: '300px', Width: 'initial', textAlign: 'center' }}
                >
                    <BallBeat
                        color={'#123abc'}
                        loading
                    />
                    Loading....</div>
            );
        }
        else {
            this.clearConsole();
            const dataSlot = this.props.location.data.dataSlot
            const dataProyek = this.props.location.data.dataProyek
            const totalSlot = this.props.location.data.totalSlot
            const totalPembelian = this.props.location.data.totalPembelian
            return (
                <div style={{ backgroundColor: "#EEEEEE" }}>
                    <Navbar_user pembayaran={this.state.paramsPembayaranHead} />
                    <div className="row">
                        <div className="col-md-8">
                            <ListProyekTerpilih key={dataSlot.length}
                                dataProyekTersimpan={this.state.dataProyekTersimpan} 
                                dataSlot={dataSlot} 
                                dataProyek={dataProyek}
                                totalSlot={totalSlot} 
                                totalPembelian={totalPembelian}
                            />
                        </div>
                        <div className="col-md-4">
                            <RingkasanPembayaran 
                                dataSlot={dataSlot}
                                totalSlot={totalSlot} 
                                totalPembelian={totalPembelian} 
                                platformFee={totalPembelian * 0.05} // 5%
                                totalPembayaran={totalPembelian + (totalPembelian * 0.05)}
                                dataProyek={dataProyek} />
                        </div>
                    </div>
                    <br /><br /><br />
                </div>
            )
        }
    }
}

function ListProyekTerpilih({ dataProyekTersimpan, dataSlot, totalSlot, totalPembelian, dataProyek }) {
    return (
        <div className="boxContainer">
            <div className="judulboxTop">
                <div className="namaboxTop">Bisnis Terpilih</div>
            </div>
            {(dataSlot.length == 1) ?
                (
                    <>
                        {(dataProyekTersimpan && dataProyekTersimpan.length > 0) ?
                            dataProyekTersimpan.map((itemWishlist, index) => (
                                <>
                                    {(itemWishlist.id_project == dataProyek.ProyekID) ?
                                        (
                                            <>
                                                <div className="segmentProyek" key={itemWishlist.id}>
                                                    {/* <div className="judulnamaboxTop">
                                                        <Icon.Group size='large'>
                                                            <Image src={icon_pengelola} style={{ width: '20%', marginTop: '-10px' }} />
                                                        </Icon.Group>
                                                        <span className="textnamaboxTop">{itemWishlist.data_pengelola.full_name}</span>
                                                    </div> */}
                                                    <Row>
                                                        <Col>
                                                            <Grid columns={3}>
                                                                <Grid.Row>
                                                                    <Grid.Column width={4}>
                                                                        <Segment style={{ marginLeft: '20px', border: 'none', boxShadow: 'none' }}>
                                                                            <div className="ui fluid image">
                                                                                <Image className="byrstp1" src={itemWishlist.proyek_data.nama_file} wrapped ui={false} />
                                                                            </div>
                                                                        </Segment>
                                                                    </Grid.Column>
                                                                    <Grid.Column width={8}>
                                                                        <Segment style={{ marginLeft: '-10px', border: 'none', boxShadow: 'none', padding: '10px' }}>
                                                                            <Header id="fonts" as='h3' color='blue'>
                                                                                {itemWishlist.proyek_overview.nama_project}
                                                                                <Header.Subheader id="fonts" >
                                                                                    Rp. {itemWishlist.proyek_overview.slot_nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} / Slot
                                                                                </Header.Subheader>
                                                                            </Header>
                                                                            <div>
                                                                                <Button.Group>
                                                                                    <Button size='mini' disabled icon color="blue">
                                                                                        <Icon name='minus' />
                                                                                    </Button>
                                                                                    <Button size='mini' basic>
                                                                                        {dataSlot.filter(isiSlot => isiSlot.ProyekID == itemWishlist.id_project).map(filteredIsi => (filteredIsi.Slot))}
                                                                                    </Button>
                                                                                    <Button size='mini' disabled icon color="blue">
                                                                                        <Icon name='plus' />
                                                                                    </Button>
                                                                                </Button.Group>
                                                                            </div>
                                                                        </Segment>
                                                                    </Grid.Column>
                                                                    <Grid.Column width={4}>
                                                                        <Segment style={{ marginLeft: '-10px', border: 'none', boxShadow: 'none' }}>
                                                                            <Header id="fonts" as='h3' color='blue'>
                                                                                Rp. {dataSlot.filter(isiSlot => isiSlot.ProyekID == itemWishlist.id_project).map(filteredIsi => (filteredIsi.HargaSlot.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")))}
                                                                            </Header>
                                                                        </Segment>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div></div>
                                            </>
                                        )

                                    }
                                </>
                            )) :
                            <Row className="justify-content-md-center" style={{ paddingTop: '20px' }}>
                                <Col xs md="12">
                                    <Container style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                                        <div style={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>Belum ada bisnis yang dapat di tampilkan</div>
                                        {/* <div style={{ fontSize: '14px', textAlign: 'center', lineHeight: '1.5em' }}>Mulai investasi sekarang.. </div> */}
                                        <div style={{ marginTop: '10px', textAlign: 'center' }}>
                                            <Button href="/UserPemodal" color="blue">
                                                Kembali ke Dashboard Utama
                                            </Button>
                                        </div>
                                    </Container>
                                    
                                </Col>
                            </Row>
                        }
                    </>
                )
                :
                (
                    <>
                        {(dataProyekTersimpan && dataProyekTersimpan.length > 0) ?
                            dataProyekTersimpan.map((itemWishlist, index) => (
                                <>
                                    <div className="segmentProyek" key={itemWishlist.id}>
                                        <div className="judulnamaboxTop">
                                            <Icon.Group size='large'>
                                                <Image src={icon_pengelola} style={{ width: '23%', marginTop: '-10px' }} />
                                            </Icon.Group>
                                            <span className="textnamaboxTop">{itemWishlist.data_pengelola.full_name}</span>
                                        </div>
                                        <Row>
                                            <Col>
                                                <Grid columns={3}>
                                                    <Grid.Row>
                                                        <Grid.Column width={4}>
                                                            <Segment style={{ marginLeft: '45px', border: 'none', boxShadow: 'none' }}>
                                                                <div className="ui fluid image">
                                                                    <Image className="byrstp1" src={itemWishlist.proyek_data.nama_file} wrapped ui={false} />
                                                                </div>
                                                            </Segment>
                                                        </Grid.Column>
                                                        <Grid.Column width={8}>
                                                            <Segment style={{ marginLeft: '10px', border: 'none', boxShadow: 'none', padding: '10px' }}>
                                                                <Header id="fonts" as='h3' color='blue'>
                                                                    {itemWishlist.proyek_overview.nama_project}
                                                                    <Header.Subheader id="fonts" >
                                                                        Rp. {itemWishlist.proyek_overview.slot_nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} / Slot
                                                                    </Header.Subheader>
                                                                </Header>
                                                                <div>
                                                                    <Button.Group>
                                                                        <Button size='tiny' disabled icon color="blue">
                                                                            <Icon name='minus' />
                                                                        </Button>
                                                                        <Button size='small' basic>
                                                                            {dataSlot.filter(isiSlot => isiSlot.ProyekID == itemWishlist.id_project).map(filteredIsi => (filteredIsi.Slot))}
                                                                        </Button>
                                                                        <Button size='tiny' disabled icon color="blue">
                                                                            <Icon name='plus' />
                                                                        </Button>
                                                                    </Button.Group>
                                                                </div>
                                                            </Segment>
                                                        </Grid.Column>
                                                        <Grid.Column width={4}>
                                                            <Segment style={{ marginLeft: '-10px', border: 'none', boxShadow: 'none' }}>
                                                                <Header id="fonts" as='h3' color='blue'>
                                                                    Rp. {dataSlot.filter(isiSlot => isiSlot.ProyekID == itemWishlist.id_project).map(filteredIsi => (filteredIsi.HargaSlot.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")))}
                                                                </Header>
                                                            </Segment>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Col>
                                        </Row>
                                    </div>
                                </>
                            )) :
                            <Row className="justify-content-md-center" style={{ paddingTop: '85px', paddingLeft: '200px' }}>
                                <Col xs md="12">
                                    <Image src={proyk} size='medium' verticalAlign="middle" />
                                    <br /><br />
                                    <Button href="/UserPemodal" color="facebook">
                                        Kembali ke Dashboard Utama
                                    </Button>
                                </Col>
                            </Row>
                        }
                    </>
                )}
        </div>
    )
}

function RingkasanPembayaran({ dataSlot, totalSlot, totalPembelian, platformFee, totalPembayaran, dataProyek }) {
    return (
        <div className="ringkasContainer">
            <div className="judulboxTop">
                <div className="namaboxTop">Ringkasan Pembayaran</div>
            </div>
            {(dataSlot.length == 1) ?
                (
                    <>
                        <div className="totalProyek">
                            <Header as='h5' floated='left' color='grey'>
                                Total Pembelian
                            </Header>
                            <Header as='h5' floated='right' color='grey'>
                                {/* Rp. {dataProyek.HargaSlot.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
                                Rp. {totalPembelian.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </Header>
                        </div>
                        <br />
                        <div className="totalProyek">
                            <Header as='h5' floated='left' color='grey'>
                                Biaya Layanan (5%)
                            </Header>
                            <Header as='h5' floated='right' color='grey'>
                                {/* Rp. {(dataProyek.HargaSlot).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
                                Rp. {platformFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </Header>
                        </div>
                        <br /><br />
                        <hr />
                        <div className="totalProyek">
                            <Header as='h5' floated='left'>
                                Total Pembayaran
                            </Header>
                            <Header as='h5' floated='right' style={{ color: '#214981' }}>
                                {/* Rp. {dataProyek.HargaSlot.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
                                Rp. {totalPembayaran.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </Header>
                        </div>
                        <br /><br />
                        <div style={{ textAlign: 'center' }}>
                            <Link to={{
                                pathname: "/Pembayaran/MetodePembayaran", data: {
                                    dataSlot: dataSlot, totalSlot: totalSlot, 
                                    totalPembelian, platformFee, totalPembayaran,
                                    dataProyek: dataSlot[0],
                                }
                            }}>
                                <Button id="fonts" className="col-md-11" size='medium' color="blue">Pilih Metode Pembayaran</Button>
                            </Link>
                        </div>
                    </>
                )
                :
                (
                    <>
                        <div className="totalProyek">
                            <Header as='h5' floated='left' color='grey'>
                                Total Pembelian
                            </Header>
                            <Header as='h5' floated='right' color='grey'>
                                Rp. {totalPembelian.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </Header>
                        </div>
                        <br />
                        <div className="totalProyek">
                            <Header as='h5' floated='left' color='grey'>
                                Biaya Layanan (5%)
                            </Header>
                            <Header as='h5' floated='right' color='grey'>
                                Rp. {platformFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </Header>
                        </div>
                        <br /><br />
                        <hr />
                        <div className="totalProyek">
                            <Header as='h5' floated='left'>
                                Total Pembayaran
                            </Header>
                            <Header as='h5' floated='right' style={{ color: '#214981' }}>
                                Rp. {totalPembayaran.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </Header>
                        </div>
                        <br /><br />
                        <div style={{ textAlign: 'center' }}>
                            <Link to={{
                                pathname: "/Pembayaran/MetodePembayaran", data: {
                                    dataSlot: dataSlot, totalSlot: totalSlot, totalPembayaran: totalPembayaran,
                                    dataProyek: dataSlot[0],
                                }
                            }}>
                                <Button id="fonts" className="col-md-11" size='medium' color="blue">Pilih Metode Pembayaran</Button>
                            </Link>
                        </div>
                    </>
                )}

        </div>
    )
}

function mapStateToProps(state) {
    return {
        getUserpemodal: state.userReducer.getUserpemodal,
        auth: state.authReducer.authData
    }
}

function mapDispatchToProps(dispatch) {
    return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(BayarStep1);