import React, { useState } from 'react';
import "../../App.scss";
import Ayat from "../../assets/img/ayat-l.png";
import Logo from "../../assets/img/logo_kerjasama_vertical.png";
import Gambar from "../../assets/img/GAMBAR.jpg";
import { Row, Col, Button, Form, Alert, Modal } from 'react-bootstrap';
import { forgotPassword } from "../../actions/auth.actions";
import { connect } from "react-redux";
import topbar from 'topbar';
import $ from 'jquery';
import axios from 'axios';
import swal from 'sweetalert';

class Lupa_password extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            fields: {},
            response: {},
            errors: {},
            alertLogin: '', alertErrorLogin: '',
            showSuccessLogin: false, showErrorLogin: false,
            blockedIP: false, errorSum: 0, ipAddr: ''
        }
    }



    componentDidMount() {
        topbar.config({
            autoRun: false,
            barThickness: 5,
            barColors: {
                '0': 'rgba(0, 199, 80, 1)',
                '.5': 'rgba(255, 255, 255, 1)',
                '.8': 'rgba(0, 199, 80, 1)',
                '1.0': 'rgba(255, 255, 255, 1)'
            },
            shadowBlur: 5,
            shadowColor: 'rgba(3, 60, 133, 1)',
            className: 'topbar',
        })
        topbar.show();
        (function step() {
            setTimeout(function () {
                if (topbar.progress('+.01') < 1) step()
            }, 16)
        })()

        setTimeout(() => {
            topbar.hide();
        }, 4000);

        this.getIPAddress()

        // localStorage.clear()
        // console.log(localStorage)
    }

    getIPAddress() {
        axios.get("https://jsonip.com/",
            axios.defaults.headers.common['Accept'] = 'application/json'
        )
            .then((resp) => {
                this.setState({
                    ipAddr: resp.data.ip
                })
            })
            .catch((resp) => {

            });
    }

    forgotPassword = async (values) => {
        try {
            $(".forgotDashboard").attr('disabled', 'true');
            $(".forgotDashboard").html('Loading...');

            var date = new Date();
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var day = date.getDate();
            var hours = date.getHours();
            var minutes = date.getMinutes();

            var btoaIPLog = window.btoa('iPAddressForLogs' + year + "-" + month + "-" + day + " " + hours + ":" + minutes);
            values[btoaIPLog] = window.btoa(this.state.ipAddr)

            // dilempar kesini
            const response = await this.props.dispatch(forgotPassword(values));
            // console.log(response);
            if (!response.responseBody.success) {
                localStorage.setItem('ip_address', this.state.ipAddr)
                var errLog = localStorage.getItem('errors_log_password');
                var dataerrLog = errLog ? parseInt(errLog) + 1 : 1;
                localStorage.setItem('errors_log_password', dataerrLog);

                if (dataerrLog >= 3) {
                    localStorage.setItem('blockedIP', true);
                    this.setState({
                        blockedIP: true
                    })
                }

                this.setState({
                    alertErrorLogin: response.responseBody.body.message,
                    showErrorLogin: true,
                });
                setTimeout(() => {
                    this.setState({
                        showErrorLogin: false,
                        errors: {},
                        alertErrorLogin: ''
                    });
                }, 13000);

                $(".forgotDashboard").removeAttr('disabled', 'true');
                $(".forgotDashboard").html('Kirim');

                throw response;
            } else {
                swal({ text: response.responseBody.body.message, icon: 'success' })
                const { history } = this.props;
                history.push('/Reset-password-user');
                $(".forgotDashboard").removeAttr('disabled', 'true');
                $(".forgotDashboard").html('Kirim');
            }
        } catch (error) {
            //   console.log(error);
            $(".forgotDashboard").removeAttr('disabled', 'true');
            $(".forgotDashboard").html('Kirim');
        }
    }


    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //Email
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Email Tidak Ditemukan...";
        }

        if (typeof fields["email"] !== "undefined") {
            let lastAtPos = fields["email"].lastIndexOf('@');
            let lastDotPos = fields["email"].lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
                formIsValid = false;
                errors["email"] = "Email Tidak Valid";
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }


    onSubmit = (event) => {
        event.preventDefault();

        if (this.handleValidation()) {
            var errLog = localStorage.getItem('blockedIP');
            var errIp = localStorage.getItem('ip_address');

            if (errLog == "true" && errIp === this.state.ipAddr) {
                this.setState({
                    alertErrorLogin: 'Email yang anda masukkan tidak ditemukan atau sudah melebihi 3 kali. anda belum diizinkan melakukan aksi lupa password lagi. Silahkan hubungi Customer Service kami jika diperlukan..',
                    showErrorLogin: true,
                });
                setTimeout(() => {
                    this.setState({
                        showErrorLogin: false,
                        errors: {},
                        alertErrorLogin: ''
                    });
                }, 13000);
            } else {
                this.forgotPassword(this.state.fields);
            }
        } else {
            setTimeout(() => {
                this.setState({
                    errors: {}
                });
            }, 5000);
        }

    }

    handleChange = (event) => {
        let fields = this.state.fields;
        fields[event.currentTarget.getAttribute('name')] = event.target.value;
        fields['type'] = "cek_email";
        this.setState({ fields });
    }

    setShow = (event) => {
        event.target.setAttribute('show2', false)
    }

    render() {
        const error_text = this.props.loginUser.errors;
        let error_panel;

        if (error_text) {
            error_panel =
                <Alert variant="danger" show={this.props.loginUser.isError}>
                    <center>{error_text.message}</center>
                </Alert>
                ;
        } else {
            error_panel = '';
        }

        return (
            <div>
                <div className="container" style={{ paddingTop: "100px", paddingBottom: "100px" }}>
                    <div className="row mt-4 align-items-center justify-content-center">
                        <div className="col-lg-5">
                            <div className="card" style={{ borderRadius: "20px", backgroundColor: "#fafafa", paddingRight: "20px" }}>
                                <div className="text-center mt-4 ml-4"><img src={Logo} className="img-fluid card-image-top" width="150" alt="logo-kerjasama" /></div>
                                <div className="text-center mt-4 ml-4" style={{ color: "#666666", fontSize: "16px" }}>Masukkan alamat email anda</div>
                                <div className="card-body">
                                    <Form onSubmit={this.onSubmit} className="pl-4">
                                        <Alert className="alertErrorLogin" variant="danger" show={this.state.showErrorLogin}>
                                            <center>{this.state.alertErrorLogin}</center>
                                        </Alert>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label className="text-muted"></Form.Label>
                                            <input className="form-control" type="email" name="email" id="email" placeholder="Email" onChange={this.handleChange} value={this.state.fields["email"] || ''} />
                                            <span style={{ color: "red" }}>{this.state.errors["email"]}</span>
                                        </Form.Group>
                                        
                                        <div className="text-center" style={{ marginTop: "30px" }}>
                                            <Button style={{ borderRadius: "30px", padding: "5px 80px", background: "#033C86" }} className="forgotDashboard" type="submit">
                                                Kirim
                                            </Button>
                                        </div>

                                        <div className="text-center" style={{ marginTop: "20px" }}>
                                            <a href="Signin" style={{ textDecoration: "none", fontWeight: "bold"}}> Masuk</a> <br /> <br />
                                        </div>
                                    </Form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
// }

function mapStateToProps(state) {
    return {
        loginUser: state.authReducer.loginUser,
        getUser: state.userReducer.getUser,
        auth: state.authReducer.authData
    }
}

function mapDispatchToProps(dispatch) {
    return { dispatch }
}



export default connect(mapStateToProps, mapDispatchToProps)(Lupa_password);