import { api, fetchApi } from "../service/api";

export const createNewUserpemodal = (payload) => {
    return async (dispatch) => {

        try {
            dispatch({
                type: "REGISTER_USER_LOADING"
            });
            const response = await fetchApi("/customer/pemodal/register_v2", "POST", payload, 200);
            // console.log(response);

            if (response.success) {
                dispatch({
                    type: "REGISTER_USER_SUCCESS",
                    payload: response
                });
                return response;
            } else {
                dispatch({
                    type: "REGISTER_USER_FAIL",
                    payload: response
                });
                return response;
            }

        } catch (error) {
            dispatch({
                type: "REGISTER_USER_FAIL",
                payload: error
            });
            return error;
        }
    }
}

export const createNewUserpengelola = (payload) => {
    return async (dispatch) => {

        try {
            dispatch({
                type: "REGISTER_USER_LOADING"
            });
            const response = await fetchApi("/customer/pengelola/register_v2", "POST", payload, 200);
            console.log("RESPONSESSSSS", response);

            if (response.success) {
                dispatch({
                    type: "REGISTER_USER_SUCCESS",
                    payload: response.responseBody
                });
                return response;
            } else {
                dispatch({
                    type: "REGISTER_USER_FAIL",
                    payload: response.responseBody
                });
                return response;
            }

        } catch (error) {
            dispatch({
                type: "REGISTER_USER_FAIL",
                payload: error.responseBody
            });
            return error;
        }
    }
}


