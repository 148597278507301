import React, { memo, useState } from "react";
import "../../App.scss";
import "../../AppMobile.scss";
import { connect } from "react-redux";
import { compose } from "redux";
import { Player } from "video-react";
import { BallBeat } from "react-pure-loaders";
import ErrorPage from "../../pages/landing/ErrorPage";
import { BallClipRotateMultiple } from "react-pure-loaders";
//import Logo from "../../assets/img/logo_kerja.svg"
import Logo from "../../assets/img/logo_kerjasama_vertical.png";
import Waktu from "../../assets/img/waktu3.svg";
import Invest from "../../assets/img/invesment2.svg";
import icon4v1 from "../../assets/img/how-work-icon4-v1.svg";
import icon5v1 from "../../assets/img/how-work-icon5-v1.svg";
import icon6v1 from "../../assets/img/how-work-icon6-v1.svg";
import Arrow from "../../assets/img/icon-arrow-kerja.svg";
import Project1 from "../../assets/img/project1.svg";
import Halal from "../../assets/img/halal.svg";
import Memberdayakan from "../../assets/img/memberdayakan.svg";
import Amanah from "../../assets/img/amanah.svg";
import Transparan from "../../assets/img/transparan.svg";
import Slider from "react-animated-slider";
import Navbar from "../../components/navbar_home";
import Footer from "../../components/Footer";
import Api from "../../service/Api_home";
import ApiProyek from "../../service/ApiProyek";
import { BASE_URL, tokenGet } from "../../service/api";
import { FaWhatsapp } from "react-icons/fa";
import { getProject } from "../../actions/frontend.action";
import "react-animated-slider/build/horizontal.css";
import { Picture } from "react-responsive-picture";
import Carousel from "react-bootstrap/Carousel";
import aludiLogo from "../../assets/img/partner/logo_aludi.png";
import ojkLogo from "../../assets/img/partner/logo_ojk.png";
import kominfoLogo from "../../assets/img/partner/logo_kominfo.png";
import isoLogo from "../../assets/img/partner/intertek_kan.png";
import slide1new from "../../assets/img/slider/slider1.png";
import slide2new from "../../assets/img/slider/slider2.png";
import slide3new from "../../assets/img/slider/slider3.png";
import $ from "jquery";
import {
  Card,
  Button,
  Container,
  Col,
  Row,
  body,
  Image,
  Alert,
} from "react-bootstrap";
import { Tab, Divider, Progress, Pagination } from "semantic-ui-react";
import { loginUser } from "../../actions/auth.actions";
import axios from "axios";
import { Redirect } from "react-router-dom";
import swal from "sweetalert";

const slideres = [
  {
    image: require("../../assets/img/slider/Slider 23-02.png"),
    head: `Kerjasama.co.id`,
    description: `Platform yang mempertemukan penerbit usaha dengan pemodal dalam kerjasama usaha sesuai syariat islam dengan menekankan kepada etika, keadilan dan kesetaraan.`,
    modal: `#Modal Minimal 1 Jt `,
    untung: `#Imbas Hasil untung Hingga 20% Per Tahun`,
  },
  {
    image: require("../../assets/img/slider/Slider 2-02.png"),
    head: " Pemodal",
    description: `Solusi bagi pemilik modal yang membutuhkan usaha untuk memperoleh keuntungan, dapat tumbuh dan berkembang serta berkontribusi terhadap perekonomian syariah di Indonesia.`,
  },
  {
    image: require("../../assets/img/slider/Slider 1-02.png"),
    head: "Penerbit",
    description: `
    Solusi bagi penerbit usaha yang membutuhkan modal untuk mengembangkan usahanya dengan skema yang adil dan saling menguntungkan.`,
  },
];

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      error: null,
      isLoading: true,
      isLoaded: false,
      hasError: false,
      items: [],
      batasDaftar: [],
      itemsTerbaru: [],
      itemsTerpopuler: [],
      itemsSelesai: [],
      data: [],
      begin: 0,
      end: 6,
      activePage: 1,
      activePagePop: 1,
      activePageTerb: 1,
      activePageSels: 1,
      dataListItems: [],
      dataListItemsTerpopuler: [],
      dataListItemsTerbaru: [],
      dataListItemsSelesai: [],
    };
  }

  timeBetweenDates(startDate, toDate, status) {
    var dateEntered = new Date(toDate);
    var now = new Date();
    var difference = dateEntered.getTime() - now.getTime();

    var seconds = Math.floor(difference / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);

    var bataspendaftaran = "Berakhir";
    hours %= 24;
    minutes %= 60;
    seconds %= 60;
    if (days == 0) {
      bataspendaftaran = "Sampai Hari Ini";
      return bataspendaftaran;
    } else if (days < 0 || status >= 7) {
      bataspendaftaran = "Berakhir";
      return bataspendaftaran;
    } else {
      bataspendaftaran = days + " Hari Lagi";
      return bataspendaftaran;
    }
  }

  PaginationClick = (e, { activePage }) => {
    if (activePage == 1) {
      this.setState({ begin: 0, end: 6 });
    } else if (activePage == 2) {
      this.setState({ begin: 6, end: 12 });
    } else if (activePage == 3) {
      this.setState({ begin: 12, end: 18 });
    } else if (activePage == 4) {
      this.setState({ begin: 18, end: 24 });
    } else if (activePage == 5) {
      this.setState({ begin: 24, end: 30 });
    } else if (activePage == 6) {
      this.setState({ begin: 30, end: 36 });
    } else if (activePage == 7) {
      this.setState({ begin: 36, end: 42 });
    } else if (activePage == 8) {
      this.setState({ begin: 42, end: 48 });
    } else if (activePage == 9) {
      this.setState({ begin: 48, end: 54 });
    } else if (activePage == 10) {
      this.setState({ begin: 54, end: 60 });
    } else if (activePage == 11) {
      this.setState({ begin: 60, end: 66 });
    } else if (activePage == 12) {
      this.setState({ begin: 66, end: 72 });
    } else if (activePage == 13) {
      this.setState({ begin: 72, end: 78 });
    } else if (activePage == 14) {
      this.setState({ begin: 78, end: 84 });
    } else if (activePage == 15) {
      this.setState({ begin: 84, end: 90 });
    } else if (activePage == 16) {
      this.setState({ begin: 90, end: 96 });
    } else if (activePage == 17) {
      this.setState({ begin: 96, end: 102 });
    } else if (activePage == 18) {
      this.setState({ begin: 102, end: 108 });
    } else if (activePage == 19) {
      this.setState({ begin: 108, end: 114 });
    } else if (activePage == 20) {
      this.setState({ begin: 114, end: 120 });
    }

    this.setState({ activePage });
    this.getListItemsSemua();
  };

  PaginationClickPopuler = (e, { activePagePop }) => {
    if (activePagePop == 1) {
      this.setState({ begin: 0, end: 6 });
    } else if (activePagePop == 2) {
      this.setState({ begin: 6, end: 12 });
    } else if (activePagePop == 3) {
      this.setState({ begin: 12, end: 18 });
    } else if (activePagePop == 4) {
      this.setState({ begin: 18, end: 24 });
    } else if (activePagePop == 5) {
      this.setState({ begin: 24, end: 30 });
    } else if (activePagePop == 6) {
      this.setState({ begin: 30, end: 36 });
    } else if (activePagePop == 7) {
      this.setState({ begin: 36, end: 42 });
    } else if (activePagePop == 8) {
      this.setState({ begin: 42, end: 48 });
    } else if (activePagePop == 9) {
      this.setState({ begin: 48, end: 54 });
    } else if (activePagePop == 10) {
      this.setState({ begin: 54, end: 60 });
    } else if (activePagePop == 11) {
      this.setState({ begin: 60, end: 66 });
    } else if (activePagePop == 12) {
      this.setState({ begin: 66, end: 72 });
    } else if (activePagePop == 13) {
      this.setState({ begin: 72, end: 78 });
    } else if (activePagePop == 14) {
      this.setState({ begin: 78, end: 84 });
    } else if (activePagePop == 15) {
      this.setState({ begin: 84, end: 90 });
    } else if (activePagePop == 16) {
      this.setState({ begin: 90, end: 96 });
    } else if (activePagePop == 17) {
      this.setState({ begin: 96, end: 102 });
    } else if (activePagePop == 18) {
      this.setState({ begin: 102, end: 108 });
    } else if (activePagePop == 19) {
      this.setState({ begin: 108, end: 114 });
    } else if (activePagePop == 20) {
      this.setState({ begin: 114, end: 120 });
    }

    this.setState({ activePagePop });
    this.getListItemsPopulter();
  };

  PaginationClickTerbaru = (e, { activePageTerb }) => {
    if (activePageTerb == 1) {
      this.setState({ begin: 0, end: 6 });
    } else if (activePageTerb == 2) {
      this.setState({ begin: 6, end: 12 });
    } else if (activePageTerb == 3) {
      this.setState({ begin: 12, end: 18 });
    } else if (activePageTerb == 4) {
      this.setState({ begin: 18, end: 24 });
    } else if (activePageTerb == 5) {
      this.setState({ begin: 24, end: 30 });
    } else if (activePageTerb == 6) {
      this.setState({ begin: 30, end: 36 });
    } else if (activePageTerb == 7) {
      this.setState({ begin: 36, end: 42 });
    } else if (activePageTerb == 8) {
      this.setState({ begin: 42, end: 48 });
    } else if (activePageTerb == 9) {
      this.setState({ begin: 48, end: 54 });
    } else if (activePageTerb == 10) {
      this.setState({ begin: 54, end: 60 });
    } else if (activePageTerb == 11) {
      this.setState({ begin: 60, end: 66 });
    } else if (activePageTerb == 12) {
      this.setState({ begin: 66, end: 72 });
    } else if (activePageTerb == 13) {
      this.setState({ begin: 72, end: 78 });
    } else if (activePageTerb == 14) {
      this.setState({ begin: 78, end: 84 });
    } else if (activePageTerb == 15) {
      this.setState({ begin: 84, end: 90 });
    } else if (activePageTerb == 16) {
      this.setState({ begin: 90, end: 96 });
    } else if (activePageTerb == 17) {
      this.setState({ begin: 96, end: 102 });
    } else if (activePageTerb == 18) {
      this.setState({ begin: 102, end: 108 });
    } else if (activePageTerb == 19) {
      this.setState({ begin: 108, end: 114 });
    } else if (activePageTerb == 20) {
      this.setState({ begin: 114, end: 120 });
    }

    this.setState({ activePageTerb });
    this.getListItemsTerbaru();
  };

  PaginationClickSelesai = (e, { activePageSels }) => {
    if (activePageSels == 1) {
      this.setState({ begin: 0, end: 6 });
    } else if (activePageSels == 2) {
      this.setState({ begin: 6, end: 12 });
    } else if (activePageSels == 3) {
      this.setState({ begin: 12, end: 18 });
    } else if (activePageSels == 4) {
      this.setState({ begin: 18, end: 24 });
    } else if (activePageSels == 5) {
      this.setState({ begin: 24, end: 30 });
    } else if (activePageSels == 6) {
      this.setState({ begin: 30, end: 36 });
    } else if (activePageSels == 7) {
      this.setState({ begin: 36, end: 42 });
    } else if (activePageSels == 8) {
      this.setState({ begin: 42, end: 48 });
    } else if (activePageSels == 9) {
      this.setState({ begin: 48, end: 54 });
    } else if (activePageSels == 10) {
      this.setState({ begin: 54, end: 60 });
    } else if (activePageSels == 11) {
      this.setState({ begin: 60, end: 66 });
    } else if (activePageSels == 12) {
      this.setState({ begin: 66, end: 72 });
    } else if (activePageSels == 13) {
      this.setState({ begin: 72, end: 78 });
    } else if (activePageSels == 14) {
      this.setState({ begin: 78, end: 84 });
    } else if (activePageSels == 15) {
      this.setState({ begin: 84, end: 90 });
    } else if (activePageSels == 16) {
      this.setState({ begin: 90, end: 96 });
    } else if (activePageSels == 17) {
      this.setState({ begin: 96, end: 102 });
    } else if (activePageSels == 18) {
      this.setState({ begin: 102, end: 108 });
    } else if (activePageSels == 19) {
      this.setState({ begin: 108, end: 114 });
    } else if (activePageSels == 20) {
      this.setState({ begin: 114, end: 120 });
    }

    this.setState({ activePageSels });
    this.getListItemsSelesai();
  };

  openAjukanDana = (auth) => {
    if (auth.isLoggedInPemodal) {
      swal("Ooopss..", "Anda sedang masuk sebagai pemodal");
      return;
    }
    window.location.href = "/Userpengelola/#submit";
  };

  openMulaiInvestasi = (auth) => {
    if (auth.isLoggedInPengelola) {
      swal("Ooopss..", "Anda sedang masuk sebagai penerbit");
      return;
    }
    window.location.href = "/Userpemodal/#listproject";
  };

  getListItemsSelesai() {
    axios
      .get(
        BASE_URL + "/pendanaanproyek",
        {
          params: {
            tag: "selesai",
            limit: 10,
          },
        },
        (axios.defaults.headers.common["Authorization"] = tokenGet),
        (axios.defaults.headers.common["Accept"] = "application/json")
      )
      .then((resp) => {
        this.setState({
          dataListItemsSelesai: resp.data.data.slice(
            this.state.begin,
            this.state.end
          ),
        });
      })
      .catch((resp) => {});
  }

  formatRupiah(bilangan) {
    var rev = parseInt(bilangan);
    return "Rp. " + rev.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  getListItemsTerbaru() {
    axios
      .get(
        BASE_URL + "/pendanaanproyek",
        {
          params: {
            tag: "terbaru",
            limit: 10,
          },
        },
        (axios.defaults.headers.common["Authorization"] = tokenGet),
        (axios.defaults.headers.common["Accept"] = "application/json")
      )
      .then((resp) => {
        this.setState({
          dataListItemsTerbaru: resp.data.data.slice(
            this.state.begin,
            this.state.end
          ),
        });
      })
      .catch((resp) => {});
  }

  getListItemsPopuler() {
    axios
      .get(
        BASE_URL + "/pendanaanproyek",
        {
          params: {
            tag: "terpopuler",
            limit: 10,
          },
        },
        (axios.defaults.headers.common["Authorization"] = tokenGet),
        (axios.defaults.headers.common["Accept"] = "application/json")
      )
      .then((resp) => {
        this.setState({
          dataListItemsTerpopuler: resp.data.data.slice(
            this.state.begin,
            this.state.end
          ),
        });
      })
      .catch((resp) => {});
  }

  getListItemsSemua() {
    axios
      .get(
        BASE_URL + "/pendanaanproyek",
        {
          params: {
            tag: "semua",
            limit: 10,
          },
        },
        (axios.defaults.headers.common["Authorization"] = tokenGet),
        (axios.defaults.headers.common["Accept"] = "application/json")
      )
      .then((resp) => {
        this.setState({
          dataListItems: resp.data.data.slice(this.state.begin, this.state.end),
        });
      })
      .catch((resp) => {});
  }

  getData() {
    axios
      .get(
        BASE_URL + "/pendanaanproyek",
        {
          params: {
            tag: "semua",
            limit: 10,
          },
        },
        (axios.defaults.headers.common["Authorization"] = tokenGet),
        (axios.defaults.headers.common["Accept"] = "application/json")
      )
      .then((resp) => {
        this.setState({
          items: resp.data.data,
        });

        var lengthdataSlot = resp.data.data.length;

        if (lengthdataSlot > 0) {
          this.getListItemsSemua();
        }
      })
      .catch((resp) => {});

    axios
      .get(
        BASE_URL + "/pendanaanproyek",
        {
          params: {
            tag: "terbaru",
            limit: 10,
          },
        },
        (axios.defaults.headers.common["Authorization"] = tokenGet),
        (axios.defaults.headers.common["Accept"] = "application/json")
      )
      .then((resp) => {
        this.setState({
          itemsTerbaru: resp.data.data,
        });

        var lengthdataSlot = resp.data.data.length;

        if (lengthdataSlot > 0) {
          this.getListItemsTerbaru();
        }
      })
      .catch((resp) => {});

    axios
      .get(
        BASE_URL + "/pendanaanproyek",
        {
          params: {
            tag: "terpopuler",
            limit: 10,
          },
        },
        (axios.defaults.headers.common["Authorization"] = tokenGet),
        (axios.defaults.headers.common["Accept"] = "application/json")
      )
      .then((resp) => {
        this.setState({
          itemsTerpopuler: resp.data.data,
        });
        var lengthdataSlot = resp.data.data.length;

        if (lengthdataSlot > 0) {
          this.getListItemsPopuler();
        }
      })
      .catch((resp) => {});

    axios
      .get(
        BASE_URL + "/pendanaanproyek",
        {
          params: {
            tag: "selesai",
            limit: 10,
          },
        },
        (axios.defaults.headers.common["Authorization"] = tokenGet),
        (axios.defaults.headers.common["Accept"] = "application/json")
      )
      .then((resp) => {
        this.setState({
          itemsSelesai: resp.data.data,
        });

        var lengthdataSlot = resp.data.data.length;

        if (lengthdataSlot > 0) {
          this.getListItemsSelesai();
        }
      })
      .catch((resp) => {});
  }

  clearConsole() {
    if (window.console || window.console.firebug) {
      console.clear();
    }
  }

  redirectDocumentSign() {
    let pathUrl = window.location.pathname.split("/");

    if (pathUrl[1] == "document-sign") {
      if (this.props.auth.isLoggedInPemodal) {
        window.location.href = "/UserPemodal";
      }
      if (this.props.auth.isLoggedInPengelola) {
        window.location.href = "/UserPengelola";
      }
    }

    if (pathUrl[1] == "register-sign") {
      if (this.props.auth.isLoggedInPemodal) {
        window.location.href = "/UserPemodal";
      }
      if (this.props.auth.isLoggedInPengelola) {
        window.location.href = "/UserPengelola";
      }
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    fetch(Api)
      .then((response) => response.json())
      .then((data) => this.setState({ data: data.data, isLoading: false }))
      .catch((error) => this.setState({ error, isLoading: false }));

    $("#CarouselBergabung .carousel-control-prev-icon").after(
      '<p>MENJADI PEMODAL</p> <i aria-hidden="true" class="angle double right big white icon"></i>'
    );
    $("#CarouselBergabung .carousel-control-next-icon").after(
      '<i aria-hidden="true" class="angle double left big white icon"></i> <p>MENJADI PENERBIT</p>'
    );

    this.getData();
    this.redirectDocumentSign();
  }

  render() {
    const {
      error,
      isLoaded,
      items,
      itemsTerbaru,
      itemsTerpopuler,
      itemsSelesai,
      activePage,
      begin,
      end,
      dataListItems,
      dataListItemsTerpopuler,
      activePagePop,
      dataListItemsTerbaru,
      activePageTerb,
      dataListItemsSelesai,
      activePageSels,
    } = this.state;

    $("#CarouselBergabung .carousel-control-prev").click(function () {
      window.location.href = "/Daftarpemodal";
    });
    $("#CarouselBergabung .carousel-control-next").click(function () {
      window.location.href = "/Daftarpengelola";
    });
    this.clearConsole();

    return (
      <div>
        <BallBeat color={"#123abc"} loading={this.isLoading} />
        <Navbar />
        <Header />
        <BtnDirectPengajuan
          openAjukanDana={this.openAjukanDana}
          openMulaiInvestasi={this.openMulaiInvestasi}
          auth={this.props.auth}
        />
        <Gabung
          // openAjukanDana={this.openAjukanDana}
          // openMulaiInvestasi={this.openMulaiInvestasi}
          // auth={this.props.auth}
        />
        <Pendanaan timeBetweenDates={this.timeBetweenDates} items={items} itemsTerbaru={itemsTerbaru} itemsTerpopuler={itemsTerpopuler} itemsSelesai={itemsSelesai}
          activePage={activePage} PaginationClick={this.PaginationClick} begin={begin} end={end} dataListItems={dataListItems}
          activePagePop={activePagePop} PaginationClickPopuler={this.PaginationClickPopuler} dataListItemsTerpopuler={dataListItemsTerpopuler}
          activePageTerb={activePageTerb} PaginationClickTerbaru={this.PaginationClickTerbaru} dataListItemsTerbaru={dataListItemsTerbaru}
          activePageSels={activePageSels} PaginationClickSelesai={this.PaginationClickSelesai} dataListItemsSelesai={dataListItemsSelesai} formatRupiah={this.formatRupiah}
        />
        <Proyek />
        {/* <Agency /> */}
        <CaraKerja />
        <Kerjasama />
        <Partner />
        <Lokasi />
        <Footer />
      </div>
    );
  }
}

function Header() {
  return (
    <div style={{ height: "600px" }}>
      <Carousel style={{ height: "75%" }}>
        <Carousel.Item className="Slide1">
          <Image
            className="d-block w-100"
            src={slide1new}
            alt="First slide"
            id="hed"
          />
          <Carousel.Caption
            style={{
              top: "50%",
              transform: "translateY(-50%)",
              bottom: "initial",
            }}
          >
            <div>
              <div
                className="text-left col-lg-5"
                style={{ padding: "5px 0px" }}
              >
                <h2 className="titleSlide1">Kerjasama</h2>
                <span className="descSlide1" style={{ fontSize: "20px" }}>
                  Platform Investasi Bisnis Yang Menyediakan Solusi Bagi
                  Penerbit Dan Pemodal
                </span>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item className="Slide2">
          <img
            className="d-block w-100"
            src={slide2new}
            alt="Second slide"
            id="hed"
          />
          <Carousel.Caption
            style={{
              top: "50%",
              transform: "translateY(-50%)",
              bottom: "initial",
            }}
          >
            <div>
              <div
                className="text-left col-lg-5"
                style={{ padding: "5px 0px", float: "right" }}
              >
                <h2 className="titleSlide1">PEMODAL</h2>
                <span className="descSlide1" style={{ fontSize: "20px" }}>
                  Kami Menyediakan Solusi Bagi Pemodal Yang Ingin Melakukan
                  Investasi Dan Meraih Kebebasan Finansial
                </span>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item className="Slide3">
          <img
            className="d-block w-100"
            src={slide3new}
            alt="Third slide"
            id="hed"
          />
          <Carousel.Caption
            style={{
              top: "50%",
              transform: "translateY(-50%)",
              bottom: "initial",
            }}
          >
            <div>
              <div
                className="text-left col-lg-5"
                style={{ padding: "5px 0px" }}
              >
                <h2 className="titleSlide1">PENERBIT</h2>
                <span className="descSlide1" style={{ fontSize: "20px" }}>
                  Kami Menyediakan Solusi Pendanaan Bagi Penerbit Efek Yang
                  Membutuhkan Modal Untuk Meningkatkan Dan Mengembangkan Bisnis
                </span>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

function BtnDirectPengajuan(props) {
  return (
    <div
      className="col-md-12"
      style={{
        marginTop: "-150px",
        padding: "20px 100px 20px 100px",
        position: "absolute",
        zIndex: "1",
      }}
    >
      <div
        className="btn-gabung jadi-investor"
        onClick={(event) => {
          props.openMulaiInvestasi(props.auth);
        }}
      >
        Mulai Investasi
      </div>
      <div
        className="btn-gabung ajukan-dana"
        onClick={(event) => {
          props.openAjukanDana(props.auth);
        }}
      >
        Ajukan Pendanaan
      </div>
    </div>
  );
}

function Gabung(props) {
  return (
    <div style={{ height: "600px" }}>
      <div>
        <h4
          className="text-center mb-5"
          style={{
            textTransform: "uppercase",
            color: "#033c85",
            fontSize: "30px",
            fontWeight: "700",
            fontFamily: "Helvetica",
          }}
        >
          Bergabunglah Bersama Kami
        </h4>
      </div>
      <div>
        <Carousel id="CarouselBergabung" className="gabung">
          <Carousel.Item>
            <Carousel.Caption style={{ paddingBottom: "150px" }}>
              {/* <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
}

const Pendanaan = memo((props) => {
  const panes = [
    {
      menuItem: "SEMUA",
      render: () => (
        <Tab.Pane attached={false}>
          <TabPendanaanSemua
            items={props.items}
            timeBetweenDates={props.timeBetweenDates}
            activePage={props.activePage}
            PaginationClick={props.PaginationClick}
            begin={props.begin}
            end={props.end}
            dataListItems={props.dataListItems}
            formatRupiah={props.formatRupiah}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "TERPOPULER",
      render: () => (
        <Tab.Pane attached={false}>
          <TabPendanaanTerpopuler
            items={props.itemsTerpopuler}
            timeBetweenDates={props.timeBetweenDates}
            activePage={props.activePagePop}
            PaginationClick={props.PaginationClickPopuler}
            begin={props.begin}
            end={props.end}
            dataListItems={props.dataListItemsTerpopuler}
            formatRupiah={props.formatRupiah}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "TERBARU",
      render: () => (
        <Tab.Pane attached={false}>
          <TabPendanaanTerbaru
            items={props.itemsTerbaru}
            timeBetweenDates={props.timeBetweenDates}
            activePage={props.activePageTerb}
            PaginationClick={props.PaginationClickTerbaru}
            begin={props.begin}
            end={props.end}
            dataListItems={props.dataListItemsTerbaru}
            formatRupiah={props.formatRupiah}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "SELESAI",
      render: () => (
        <Tab.Pane attached={false}>
          <TabPendanaanSelesai
            items={props.itemsSelesai}
            timeBetweenDates={props.timeBetweenDates}
            activePage={props.activePageSels}
            PaginationClick={props.PaginationClickSelesai}
            begin={props.begin}
            end={props.end}
            dataListItems={props.dataListItemsSelesai}
            formatRupiah={props.formatRupiah}
          />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <section className="pendanaan" id="pendanaan">
      <div className="container">
        <div className="row text-center">
          <div className="col-lg">
            {/* <h3 style={{ fontFamily: 'Helvetica' }}>Pendanaan</h3> */}
            <h3 style={{ fontFamily: "Helvetica" }}>
              Daftar Bisnis Yang Berlangsung
            </h3>
            <Tab
              className="tabPendanaan"
              menu={{
                color: "blue",
                secondary: true,
                pointing: true,
                renderactiveonly: "true",
              }}
              panes={panes}
            />
          </div>
        </div>
      </div>
    </section>
  );
});

const TabPendanaanSemua = memo((props) => {
  return (
    <div
      className="row justify-content-center DivPendanaan"
      style={{ textAlign: "left" }}
    >
      {props.items.length > 0 ? (
        props.dataListItems.map((item) => (
          <div
            key={item.id_project}
            className="col-md-6 col-sm-6 col-lg-4 promo-kun-3 my-3"
          >
            <div className="card">
              <div>
                <img
                  className="card-img-top imgHomeSampul"
                  src={item.proyek_data[0].nama_file}
                  width="180"
                  alt=""
                />
                <label className="lblhari">
                  {props.timeBetweenDates(
                    item.date_awal_val,
                    item.date_akhir_val,
                    item.status
                  )}
                </label>
              </div>
              <div className="card-body">
                <div className="card-title">
                  <div className="text-muted" style={{ float: "left" }}>
                    {item.id_project}
                  </div>
                  <div style={{ float: "right", color: "green" }}>
                    {item.slot_nominal} /Slot
                  </div>
                </div>
                <div className="row"></div>
                <h5 className="mb-2" style={{ marginTop: "20px" }}>
                  {item.nama_project}
                </h5>
                <h6 className="text-muted mb-2">{item.category}</h6>
                {/* <h6 className="mb-2">{item.deskripsi.slice(0, 150)}</h6>
                <a href={`/Detail/${item.id_project}`} className="btnSelengkapnya">Selengkapnya...</a> */}
                <h6 className="text-muted my-3">By: {item.full_name}</h6>
                <Progress
                  percent={item.persentase_slot}
                  progress
                  className="progressProjectHome"
                  style={{ margin: "0 0 0" }}
                />
                <div className="row detailProyek">
                  <div className="col-md-6 col-sm-6">
                    <h6>
                      Kebutuhan Dana <br />{" "}
                      <span className="font-weight-bold">
                        {props.formatRupiah(item.capital_needed)}
                      </span>{" "}
                    </h6>{" "}
                    <br />
                    <h6>
                      Slot <br />{" "}
                      <span className="font-weight-bold">
                        {item.total_slot_req} Slot
                      </span>
                      <br />
                    </h6>{" "}
                    <br />
                    <h6>
                      Penggalangan dimulai <br />{" "}
                      <span className="font-weight-bold">{item.date_awal}</span>
                    </h6>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <h6>
                      Masa investasi/Tenor <br />
                      <span className="font-weight-bold">
                        {item.durasi_crowd} Hari
                      </span>
                    </h6>{" "}
                    <br />
                    <h6>
                      Estimasi Keuntungan <br />{" "}
                      <span className="font-weight-bold">
                        {item.est_profit}%
                      </span>{" "}
                    </h6>{" "}
                    <br />
                    <h6>
                      Penggalangan Berakhir <br />{" "}
                      <span className="font-weight-bold">
                        {item.date_akhir}
                      </span>
                    </h6>
                  </div>
                </div>
              </div>
              <a
                href={`/Detail/${item.id_project}`}
                className="btn btn-primary btn-block detail"
              >
                {" "}
                Detail
              </a>
            </div>
          </div>
        ))
      ) : (
        <div
          style={{
            paddingTop: "50px",
            paddingBottom: "50px",
            Width: "initial",
            textAlign: "center",
          }}
        ></div>
      )}

      {props.items.length > 6 ? (
        <>
          <Container align="center" className="pagiHome">
            <Row>
              <Col xs md="12">
                <Pagination
                  activePage={props.activePage}
                  totalPages={Math.ceil(props.items.length / 6)}
                  onPageChange={props.PaginationClick}
                />
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        ""
      )}
    </div>
  );
});

const TabPendanaanTerpopuler = memo((props) => {
  return (
    <div
      className="row justify-content-center DivPendanaan"
      style={{ textAlign: "left" }}
    >
      {props.items.length > 0 ? (
        props.dataListItems.map((item) => (
          <div
            key={item.id_project}
            className="col-md-6 col-sm-6 col-lg-4 promo-kun-3 my-3"
          >
            <div className="card">
              <div>
                <img
                  className="card-img-top imgHomeSampul"
                  src={item.proyek_data[0].nama_file}
                  width="180"
                  alt=""
                />
                <label className="lblhari">
                  {props.timeBetweenDates(
                    item.date_awal_val,
                    item.date_akhir_val,
                    item.status
                  )}
                </label>
              </div>
              <div className="card-body">
                <div className="card-title">
                  <div className="text-muted" style={{ float: "left" }}>
                    {item.id_project}
                  </div>
                  <div style={{ float: "right", color: "green" }}>
                    {item.slot_nominal} /Slot
                  </div>
                </div>
                <div className="row"></div>
                <h5 className="mb-2" style={{ marginTop: "20px" }}>
                  {item.nama_project}
                </h5>
                <h6 className="text-muted mb-2">{item.category}</h6>
                {/* <h6 className="mb-2">{item.deskripsi.slice(0, 150)}</h6>
                <a href={`/Detail/${item.id_project}`} className="btnSelengkapnya">Selengkapnya...</a> */}
                <h6 className="text-muted my-3">By: {item.full_name}</h6>
                <Progress
                  percent={item.persentase_slot}
                  progress
                  className="progressProjectHome"
                  style={{ margin: "0 0 0" }}
                />
                <div className="row detailProyek">
                  <div className="col-md-6 col-sm-6">
                    <h6>
                      Kebutuhan Dana <br />{" "}
                      <span className="font-weight-bold">
                        {props.formatRupiah(item.capital_needed)}
                      </span>{" "}
                    </h6>{" "}
                    <br />
                    <h6>
                      Slot <br />{" "}
                      <span className="font-weight-bold">
                        {item.total_slot_req} Slot
                      </span>
                      <br />
                    </h6>{" "}
                    <br />
                    <h6>
                      Penggalangan dimulai <br />{" "}
                      <span className="font-weight-bold">{item.date_awal}</span>
                    </h6>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <h6>
                      Masa investasi/Tenor <br />
                      <span className="font-weight-bold">
                        {item.durasi_crowd} Hari
                      </span>
                    </h6>{" "}
                    <br />
                    <h6>
                      Estimasi Keuntungan <br />{" "}
                      <span className="font-weight-bold">
                        {item.est_profit}%
                      </span>{" "}
                    </h6>{" "}
                    <br />
                    <h6>
                      Penggalangan Berakhir <br />{" "}
                      <span className="font-weight-bold">
                        {item.date_akhir}
                      </span>
                    </h6>
                  </div>
                </div>
              </div>
              <a
                href={`/Detail/${item.id_project}`}
                className="btn btn-primary btn-block detail"
              >
                {" "}
                Detail
              </a>
            </div>
          </div>
        ))
      ) : (
        <div
          style={{
            paddingTop: "50px",
            paddingBottom: "50px",
            Width: "initial",
            textAlign: "center",
          }}
        ></div>
      )}

      {props.items.length > 6 ? (
        <>
          <Container align="center" className="pagiHome">
            <Row>
              <Col xs md="12">
                <Pagination
                  activePage={props.activePage}
                  totalPages={Math.ceil(props.items.length / 6)}
                  onPageChange={props.PaginationClick}
                />
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        ""
      )}
    </div>
  );
});

const TabPendanaanSelesai = memo((props) => {
  return (
    <div
      className="row justify-content-center DivPendanaan"
      style={{ textAlign: "left" }}
    >
      {props.items.length > 0 ? (
        props.dataListItems.map((item) => (
          <div
            key={item.id_project}
            className="col-md-6 col-sm-6 col-lg-4 promo-kun-3 my-3"
          >
            <div className="card">
              <div>
                <img
                  className="card-img-top imgHomeSampul"
                  src={item.proyek_data[0].nama_file}
                  width="180"
                  alt=""
                />
                <label className="lblhari">
                  {props.timeBetweenDates(
                    item.date_awal_val,
                    item.date_akhir_val,
                    item.status
                  )}
                </label>
              </div>
              <div className="card-body">
                <div className="card-title">
                  <div className="text-muted" style={{ float: "left" }}>
                    {item.id_project}
                  </div>
                  <div style={{ float: "right", color: "green" }}>
                    {item.slot_nominal} /Slot
                  </div>
                </div>
                <div className="row"></div>
                <h5 className="mb-2" style={{ marginTop: "20px" }}>
                  {item.nama_project}
                </h5>
                <h6 className="text-muted mb-2">{item.category}</h6>
                {/* <h6 className="mb-2">{item.deskripsi.slice(0, 150)}</h6>
                <a href={`/Detail/${item.id_project}`} className="btnSelengkapnya">Selengkapnya...</a> */}
                <h6 className="text-muted my-3">By: {item.full_name}</h6>
                <Progress
                  percent={item.persentase_slot}
                  progress
                  className="progressProjectHome"
                  style={{ margin: "0 0 0" }}
                />
                <div className="row detailProyek">
                  <div className="col-md-6 col-sm-6">
                    <h6>
                      Kebutuhan Dana <br />{" "}
                      <span className="font-weight-bold">
                        {props.formatRupiah(item.capital_needed)}
                      </span>{" "}
                    </h6>{" "}
                    <br />
                    <h6>
                      Slot <br />{" "}
                      <span className="font-weight-bold">
                        {item.total_slot_req} Slot
                      </span>
                      <br />
                    </h6>{" "}
                    <br />
                    <h6>
                      Penggalangan dimulai <br />{" "}
                      <span className="font-weight-bold">{item.date_awal}</span>
                    </h6>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <h6>
                      Masa investasi/Tenor <br />
                      <span className="font-weight-bold">
                        {item.durasi_crowd} Hari
                      </span>
                    </h6>{" "}
                    <br />
                    <h6>
                      Estimasi Keuntungan <br />{" "}
                      <span className="font-weight-bold">
                        {item.est_profit}%
                      </span>{" "}
                    </h6>{" "}
                    <br />
                    <h6>
                      Penggalangan Berakhir <br />{" "}
                      <span className="font-weight-bold">
                        {item.date_akhir}
                      </span>
                    </h6>
                  </div>
                </div>
              </div>
              <a
                href={`/Detail/${item.id_project}`}
                className="btn btn-primary btn-block detail"
              >
                {" "}
                Detail
              </a>
            </div>
          </div>
        ))
      ) : (
        <div
          style={{
            paddingTop: "50px",
            paddingBottom: "50px",
            Width: "initial",
            textAlign: "center",
          }}
        ></div>
      )}

      {props.items.length > 6 ? (
        <>
          <Container align="center" className="pagiHome">
            <Row>
              <Col xs md="12">
                <Pagination
                  activePage={props.activePage}
                  totalPages={Math.ceil(props.items.length / 6)}
                  onPageChange={props.PaginationClick}
                />
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        ""
      )}
    </div>
  );
});

const TabPendanaanTerbaru = memo((props) => {
  return (
    <div
      className="row justify-content-center DivPendanaan"
      style={{ textAlign: "left" }}
    >
      {props.items.length > 0 ? (
        props.dataListItems.map((item) => (
          <div
            key={item.id_project}
            className="col-md-6 col-sm-6 col-lg-4 promo-kun-3 my-3"
          >
            <div className="card">
              <div>
                <img
                  className="card-img-top imgHomeSampul"
                  src={item.proyek_data[0].nama_file}
                  width="180"
                  alt=""
                />
                <label className="lblhari">
                  {props.timeBetweenDates(
                    item.date_awal_val,
                    item.date_akhir_val,
                    item.status
                  )}
                </label>
              </div>
              <div className="card-body">
                <div className="card-title">
                  <div className="text-muted" style={{ float: "left" }}>
                    {item.id_project}
                  </div>
                  <div style={{ float: "right", color: "green" }}>
                    {item.slot_nominal} /Slot
                  </div>
                </div>
                <div className="row"></div>
                <h5 className="mb-2" style={{ marginTop: "20px" }}>
                  {item.nama_project}
                </h5>
                <h6 className="text-muted mb-2">{item.category}</h6>
                {/* <h6 className="mb-2">{item.deskripsi.slice(0, 150)}</h6>
                <a href={`/Detail/${item.id_project}`} className="btnSelengkapnya">Selengkapnya...</a> */}
                <h6 className="text-muted my-3">By: {item.full_name}</h6>
                <Progress
                  percent={item.persentase_slot}
                  progress
                  className="progressProjectHome"
                  style={{ margin: "0 0 0" }}
                />
                <div className="row detailProyek">
                  <div className="col-md-6 col-sm-6">
                    <h6>
                      Kebutuhan Dana <br />{" "}
                      <span className="font-weight-bold">
                        {props.formatRupiah(item.capital_needed)}
                      </span>{" "}
                    </h6>{" "}
                    <br />
                    <h6>
                      Slot <br />{" "}
                      <span className="font-weight-bold">
                        {item.total_slot_req} Slot
                      </span>
                      <br />
                    </h6>{" "}
                    <br />
                    <h6>
                      Penggalangan dimulai <br />{" "}
                      <span className="font-weight-bold">{item.date_awal}</span>
                    </h6>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <h6>
                      Masa investasi/Tenor <br />
                      <span className="font-weight-bold">
                        {item.durasi_crowd} Hari
                      </span>
                    </h6>{" "}
                    <br />
                    <h6>
                      Estimasi Keuntungan <br />{" "}
                      <span className="font-weight-bold">
                        {item.est_profit}%
                      </span>{" "}
                    </h6>{" "}
                    <br />
                    <h6>
                      Penggalangan Berakhir <br />{" "}
                      <span className="font-weight-bold">
                        {item.date_akhir}
                      </span>
                    </h6>
                  </div>
                </div>
              </div>
              <a
                href={`/Detail/${item.id_project}`}
                className="btn btn-primary btn-block detail"
              >
                {" "}
                Detail
              </a>
            </div>
          </div>
        ))
      ) : (
        <div
          style={{
            paddingTop: "50px",
            paddingBottom: "50px",
            Width: "initial",
            textAlign: "center",
          }}
        ></div>
      )}

      {props.items.length > 6 ? (
        <>
          <Container align="center" className="pagiHome">
            <Row>
              <Col xs md="12">
                <Pagination
                  activePage={props.activePage}
                  totalPages={Math.ceil(props.items.length / 6)}
                  onPageChange={props.PaginationClick}
                />
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        ""
      )}
    </div>
  );
});

function Proyek() {
  return (
    <div className="proyek" style={{ background: "fff" }}>
      <div className="proyek text-center" style={{ background: "#fff" }}>
        {/* <h2 style={{ fontFamily: 'Helvetica' }}>proyek yang sedang berjalan</h2> */}
        <h2 style={{ fontFamily: "Helvetica" }}>
          pendanaan yang sedang berjalan
        </h2>
      </div>
      <ApiProyek />
    </div>
  );
}

function Agency() {
  return (
    <section className="agency">
      <div className="container ">
        <div className="row text-center">
          <div className="col-lg">
            <h4 style={{ fontFamily: "Helvetica" }}>Mengapa kerjasama?</h4>
          </div>
        </div>
        <br />
        <br />
        <div className="row text-center">
          <div className="col-md-3 col-sm-3">
            <img src={Halal} className="img-fluid" alt="" />
            <div className="halal" />
            <h5>Halal</h5>
            <div className="isi-body">
              Halal, yang mengikuti konsep ekonomi Islam untuk manusia.
            </div>
          </div>
          <div className="col-md-3 col-sm-3">
            <img src={Amanah} className="img-fluid" alt="" />
            <div className="amanah">
              <h5>Amanah</h5>
              <div className="isi-body">
                Terpercaya, dikelola oleh profesional dengan transparan dan
                adil, serta memungkinkan untuk dipantau kapan saja di mana saja.
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-3">
            <img src={Transparan} className="img-fluid" alt="" />
            <div className="transparan">
              <h5>Transparent</h5>
              <div className="isi-body">
                Transparan, Insya Allah memiliki kebaikan untuk kebahagiaan
                dunia dan akhirat.
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-3">
            <img src={Memberdayakan} className="img-fluid" alt="" />
            <div className="memberdayakan">
              <h5>Empowering</h5>
              <div className="isi-body">
                Pemberdayaan, memberi manfaat kepada umat dan menciptakan
                masyarakat untuk lebih memahami Ekonomi Islam yang Amanah,
                Berkeadilan, dan Menguntungkan.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function CaraKerja() {
  const panes = [
    {
      // menuItem: 'Beri Pendanaan',
      menuItem: "Mulai Investasi",
      render: () => (
        <Tab.Pane attached={false}>
          <Tab1CaraKerja />
        </Tab.Pane>
      ),
    },
    {
      // menuItem: 'Ajukan Investasi Bisnis',
      menuItem: "Ajukan Pendanaan",
      render: () => (
        <Tab.Pane attached={false}>
          <Tab2CaraKerja />
        </Tab.Pane>
      ),
    },
    // {
    //   menuItem: 'Simulasi',
    //   render: () => <Tab.Pane disabled attached={false}></Tab.Pane>,
    // },
  ];

  return (
    <section className="cara-kerja">
      <div className="container">
        <div className="row text-center">
          <div className="col-lg">
            <h4 className="title-kun">Cara kerja</h4>
            <Tab
              className="tabCaraKerja"
              menu={{
                color: "blue",
                secondary: true,
                pointing: true,
                renderactiveonly: "true",
              }}
              panes={panes}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

function Tab1CaraKerja() {
  return (
    <div className="row conten-tab justify-content-center">
      <div className="col-lg-4 col-sm-4 col-md-4">
        <div className="content-gambar">
          {" "}
          <img src={Project1} className="img-fluid" alt="" />
          <div className="content-body justify-content-center">
            <h5 className="text-center">
              Jelajahi Penerbit <br /> yang membutuhkan modal
            </h5>
            <img
              src={Arrow}
              className="img-fluid"
              id="arrow-kerja"
              width="20%"
              alt=""
            />
            <ul id="testi">
              <li>
                Jelajahi penerbit efek yang ada di platform kami di semua lokasi
                di Indonesia dan di berbagai sektor industry.
              </li>
              <li className="mt-4">
                Berinvestasi mulai dari IDR 1 Juta dan memiliki kesempatan untuk
                memperoleh imbal hasil yang menarik.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-sm-4 col-md-4">
        <img src={Invest} className="img-fluid" alt="" />
        <div className="content-body">
          <h5 className="text-center">
            Berinvestasi di <br /> Efek Penerbit
          </h5>
          <img
            src={Arrow}
            className="img-fluid"
            id="arrow-kerja"
            width="20%"
            alt=""
          />
          <ul id="testi">
            <li>Pemodal wajib memiliki rekening efek di bank custodian.</li>
            <li className="mt-4">
              Memenuhi kriteria dan batasan pembelian efek.
            </li>
            <li className="mt-4">
              Semua dana yang diterima akan disimpan di rekening Escrow untuk
              menjamin kemudahan dan keamanan dalam transaksi digital.
            </li>
          </ul>
        </div>
      </div>
      <div className="col-lg-4 col-sm-4">
        <img src={Waktu} className="img-fluid" alt="" />
        <div className="content-body">
          <h5 className="text-center">
            Dapatkan Laporan secara periodic dan memperoleh imbal hasil yang
            menarik
          </h5>
          <ul id="testi">
            <li>
              Merasakan kemudahan dalam memperoleh laporan secara periodic atas
              perusahaan penerbit yang didanai semudah hanya dengan ujung jari
              anda.
            </li>
            <li className="mt-4">
              Dapatkan pengembalian investasi tahunan hingga 20%.
            </li>
            <li className="mt-4">
              Dukung peningkatan ekosistem Islam dengan pembelian efek di
              platform syariah islam.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

function Tab2CaraKerja() {
  return (
    <div className="row conten-tab justify-content-center">
      <div className="col-lg-4 col-sm-4 col-md-4">
        <div className="content-gambar">
          {" "}
          <img src={icon4v1} className="img-fluid" alt="" />
          <div className="content-body justify-content-center">
            <h5 className="text-center">Memenuhi persyaratan</h5>
            <img
              src={Arrow}
              className="img-fluid"
              id="arrow-kerja"
              width="20%"
              alt=""
            />
            <ul id="testi">
              <li>
                Penerbit wajib menyerahkan dokumen dan/atau informasi kepada
                penyelenggara sesuai ketentuan POJK No. 57/POJK.04/2020
              </li>
              <li className="mt-4">
                Bukan merupakan perusahaan terbuka atau anak perusahaan terbuka
                dan tidak memiliki kekayaan bersih dari IDR 10 Milyar (tidak
                termasuk tanah dan bangunan tempat usaha.
              </li>
              <li className="mt-4">
                Bukan badan usaha yang dikendalikan baik langsung maupun tidak
                langsung oleh suatu kelompok usaha atau konglomerasi.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-sm-4 col-md-4">
        <img src={icon5v1} className="img-fluid" alt="" />
        <div className="content-body">
          <h5 className="text-center">Pengujian dan Verifikasi</h5>
          <img
            src={Arrow}
            className="img-fluid"
            id="arrow-kerja"
            width="20%"
            alt=""
          />
          <ul id="testi">
            <li>
              {/* Penyelenggara melakukan penelaahan atas legalitas proyek yang menjadi dasar penerbitan efek melalui layanan urun dana. */}
              Penyelenggara melakukan penelaahan atas legalitas perusahaan yang
              akan melakukan penerbitan efek dan legalitas lainnya sesuai POJK
              57 Tahun 2020.
            </li>
            <li className="mt-4">
              Penyelenggara wajib mempertimbangkan informasi perkreditan dari
              lembaga pengelola informasi perkreditan.
            </li>
          </ul>
        </div>
      </div>
      <div className="col-lg-4 col-sm-4">
        <img src={icon6v1} className="img-fluid" alt="" />
        <div className="content-body">
          <h5 className="text-center">Penyerahan Dana dan Efek</h5>
          <ul id="testi">
            <li>
              Penerbit wajib melakukan penyetoran efek sesuai dengan hasil
              penawaran efek kepada penyelenggara paling lambat 2 hari kerja
              setelah masa penawaran efek berakhit.
            </li>
            <li className="mt-4">
              Penerbit wajib menandatangani perjanjian pendaftaran efek dengan
              Lembaga penyimpanan dan penyelesaian dan menyampaikan fotokopinya
              kepada penyelenggara paling lambat 10 hari kerja.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

function Kerjasama() {
  return (
    <section className="kerjasama">
      <div className="container" id="kerjasama-yt">
        <div className="row">
          <div className="col-md-12 text-center">
            {/*<img
              src={Logo}
              id="join-kerjasama"
              className="img-fluid"              
	      alt=""
            />*/}
            <img
              src={Logo}
              className="img-fluid"
              alt=""
              style={{ margin: "40px", width: "200px" }}
            />
          </div>
        </div>

        <div className="row">
          <div
            className="col-md-12 col-lg-12 col-sm-12 text-center"
            id="row-kanan"
          >
            <h3>PT. KERJASAMA UNTUK NEGERI (KUNN)</h3>
            {/* <div className="isi">
              Sebagai penyedia layanan portal crowdfunding syariah kerjasama.co.id
              merupakan perusahaan yang didirikan berdasarkan hukum Negara
              Kesatuan Republik Indonesia yang tercatat dalam regulatory sandbox
              Inovasi keuangan digital Otoritas Jasa Keuangan (OJK) dengan nomor
              tercatat :5-87/MS.72/2019, dalam cluster project financing.
              kerjasama.co.id menyatakan tunduk terhadap perundang-undangan dan
              peraturan hokum Negara Kesatuan Republik Indonesia. kerjasama.co.id
              adalah penyelenggara kerjasama antara penerbit usaha dan pemodal
              dengan menerapkan model bisnis sesuai syariat Islam menggunakan
              akad Musyarakah dan Mudharabah.
            </div> */}
            <div className="isi">
              Sebagai penyelenggara layanan penawaran efek berbasis terknologi
              informasi yang selanjutnya disebut kerjasama.co.id.
              Kerjasama.co.id adalah penyelenggara yang menyediakan, mengelola
              dan mengoperasikan penawaran efek melalui layanan urun dana
              berbasis teknologi informasi. Kerjasama.co.id membantu pemodal
              dalam melakukan manajemen risiko sehingga pemodal akan mendapatkan
              imbal hasil yang menarik dengan tetap memperhatikan adanya risiko
              usaha, risiko investasi, risiko likuiditas, kegagalan sistem
              elektronik, kelangkaan pembagian dividen dan/atau dilusi efek
              serta gagal bayar atas efek. Selain itu, Kerjasama.co.id juga
              membantu meningkatkan pertumbuhan ekonomi usaha mikro dan menengah
              bagi penerbit efek yang membutuhkan modal untuk melakukan
              pengembangan usaha.
            </div>
          </div>
        </div>

        {/*<div className="row" id="row-kiri">
          <div className="col-md-6 col-lg-6 col-sm-12">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                className="embed-responsive-item"
                src="https://www.youtube.com/embed/Kg0EIIKUsSc"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className=" col-md-6 col-lg-6 col-sm-12" id="row-kanan">
            <h3>PT.Kerjasama untuk negeri (KUNN)</h3>
            <div className="isi">
              sebagai penyedia layanan portal crowdfunding syariah kerjasama.co.id
              merupakan perusahaan yang didirikan berdasarkan hukum Negara
              Kesatuan Republik Indonesia yang tercatat dalam regulatory sandbox
              Inovasi keuangan digital Otoritas Jasa Keuangan (OJK) dengan nomor
              tercatat :5-87/MS.72/2019, dalam cluster project financing.
              kerjasama.co.id menyatakan tunduk terhadap perundang-undangan dan
              peraturan hokum Negara Kesatuan Republik Indonesia. kerjasama.co.id
              adalah penyelenggara kerjasama antara penerbit usaha dan pemodal
              dengan menerapkan model bisnis sesuai syariat Islam menggunakan
              akad Musyarakah dan Mudharabah.
            </div>
          </div>
        </div>*/}
      </div>
    </section>
  );
}

function Partner() {
  return (
    <div className="jumbotron jumbotron-fluid">
      <div className="container">
        <div className="row partner">
          <div className="col-sm-12 col-lg-12" style={{ textAlign: "center" }}>
            <h5>
              SEDANG DALAM PROSES PENDAFTARAN IJIN KE SECURITIES CROWDFUNDING
            </h5>
          </div>
        </div>
        <div className="row logo" style={{ textAlign: "center" }}>
          <div
            className="col-md-12 col-sm-12 col-lg-12 img-fluid"
            style={{ padding: "40px" }}
          >
            <Image
              src={ojkLogo}
              id="logo1"
              alt=""
              width="250"
              className="img-fluid"
            />
          </div>
        </div>
        {/* <div className="row">
          <div className="col-sm-12 col-lg-12" style={{ textAlign: 'center', fontSize: '16px' }}>
            Didukung Oleh :
          </div>
        </div> */}
        <div
          className="row logo"
          style={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="col-md-2 col-sm-2 col-lg-3 img-fluid">
            <span>
              Telah Terdaftar di Kementerian Komunikasi dan Informatika
            </span>
            <Image
              src={kominfoLogo}
              width="100"
              id="logo2"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-md-2 col-sm-2 col-lg-2 img-fluid">
            <span>Didukung Oleh</span>
            <img
              src={isoLogo}
              width="100%"
              id="logo4"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-md-2 col-sm-2 col-lg-2 img-fluid">
            <span>Member Of</span>
            <img
              src={aludiLogo}
              width="100"
              id="logo4"
              alt=""
              className="img-fluid"
            />
          </div>
        </div>{" "}
      </div>
    </div>
  );
}

function Lokasi() {
  return (
    <div className="hubungi-kami">
      <h4>Hubungi Kami</h4>
      <div className="frame">
        <div
          title="my-frame"
          className="embed-responsive embed-responsive-16by9"
        >
          <iframe
            className="embed-responsive-item"
            // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d991.5653792362398!2d107.00038884286627!3d-6.229212719465191!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69912eb0bfb885%3A0x930ecd6b6cbdfdb0!2sKerjasama.com!5e0!3m2!1sid!2sid!4v1575441977593!5m2!1sid!2sid"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63471.6365260155!2d106.71950525820311!3d-6.133754499999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6a1d3371165d39%3A0x3599617848edea66!2sPT%20KERJASAMA%20UNTUK%20NEGERI!5e0!3m2!1sen!2sid!4v1665738378859!5m2!1sen!2sid"
            width="100%"
            allowFullScreen
          />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    loginUser: state.authReducer.loginUser,
    auth: state.authReducer.authData,
  };
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
