import PropTypes from 'prop-types'
import React, { Component, memo, useState  } from 'react'
import { connect } from "react-redux";
import { getUserpemodal } from "../actions/userpemodal.actions";
import { updateUserpemodal } from "../actions/userpemodal.actions";
import { getUserpengelola } from "../actions/userpengelola.actions";
import { updateUserpengelola } from "../actions/userpengelola.actions";
import { fetchApi } from '../service/api';
import { Button,Modal,Dropdown,Form,Row, Col } from "react-bootstrap";
import swal from 'sweetalert';


class UbahProfil extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            c: false,
            userinfo: [],
            isLoad:false,  
        }
    }

    getUserpemodal = async (values) => {

        try {
            // console.log(this.props.auth.isUserId);
            const response = await this.props.dispatch(getUserpemodal(values));
            
            localStorage.DataUser = JSON.stringify(response.responseBody);
          // console.log(response.responseBody.success);
           
             if (!response.responseBody.success) {
                 throw response;
             } else if (response.responseBody.success) {
                 
                 this.state.userinfo = JSON.stringify(response.responseBody.user);
               this.state.isLoad = true;
                 
                 }
          
        } catch (error) {
            console.log(error);
        }
    }

    updateUser = async (values) => {
        try {
            const response =  await this.props.dispatch(updateUserpemodal(values));
           
            if (response.responseBody.success == false) {
              // alert(response.responseBody.body.message );
              swal({text: response.responseBody.body.message, icon: 'error'})
              throw response;
            } else {
              // alert('Data Berhasil Di Update');
              swal({text: response.responseBody.body.message, icon: 'success', closeOnClickOutside: false})
                  .then((value) => {
                    window.location.reload(true);
                  });
            }
        } catch (error) {
          console.log(error);
        }
    }
    
    getUserpengelola = async (values) => {

      try {
        // console.log(this.props.auth.isUserId);
        const response = await this.props.dispatch(getUserpengelola(values));
       
        localStorage.DataUser = JSON.stringify(response.responseBody);
  
        if (!response.responseBody.success) {
          throw response;
        } else if (response.responseBody.success) {
  
          this.state.userinfo = response.responseBody.user;
          this.state.isLoad = true;
  
        }
  
      } catch (error) {
        console.log(error);
      }
    }
  
    updateUserPengelola = async (values) => {
      try {
        // dilempar kesini
          const response =  await this.props.dispatch(updateUserpengelola(values));
          if (response.responseBody.success == false) {
            // alert(response.responseBody.body.message );
            swal({text: response.responseBody.body.message, icon: 'error'})
            throw response;
          } else {
            //  alert('Data Berhasil Di Update');
            swal({text: response.responseBody.body.message, icon: 'success', closeOnClickOutside: false})
                .then((value) => {
                  window.location.reload(true);
                });
          }
      } catch (error) {
        console.log(error);
      }
  }

    // update data
    updateData = (event, data) => {
      event.preventDefault()   
      // console.log('berhasil', data);
      if (this.props.isUser == 1) {
        this.updateUser(data);
      } else {
        this.updateUserPengelola(data);
      }
    }

    render() {

      return (
          <UbahProfilComp updateData={this.updateData} isUser={this.props.isUser} />
      )

    }
}

const UbahProfilComp = memo((props) => {
  const { user } = (props.isUser == 1) ? JSON.parse(localStorage.DataUser) : JSON.parse(localStorage.DataUser);
  const handleSubmit = event => props.updateData(event, data)
  

  const mergeUser = {...user }
  const [data, setData] = useState(mergeUser)
  const handleChange = e => setData({ ...data, [e.target.name]: e.target.value })

  const [show, setShow] = useState(false);
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
      <>
        <Dropdown.Item onClick={handleShow}><i className="fa fa-fw fa-user mr-2" ></i>Ubah Profil</Dropdown.Item>

        <Modal show={show} onHide={handleClose} animation={false}  >
          <Modal.Header className="text-center" closeButton>
            <Modal.Title id="fonts">Ubah Profil</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form id="fonts" onSubmit={handleSubmit}  >
          <Form.Group as={Row} controlId="nama">
            <Form.Label column sm="4">
              Nama
            </Form.Label>
            <Col sm="8">
              <Form.Control type="text" name="full_name" value={data.full_name} onChange={handleChange} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="email">
            <Form.Label column sm="4" >
              Email
            </Form.Label>
            <Col sm="8">
              <Form.Control type="email" value={data.email || ''} readOnly />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="alamat">
            <Form.Label column sm="4">
              Alamat
            </Form.Label>
            <Col sm="8">
              <Form.Control type="text" name="alamat" value={data.alamat || ''} onChange={handleChange} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="alamat">
            <Form.Label column sm="4">
              No Handphone
            </Form.Label>
            <Col sm="8">
              <Form.Control type="text" name="no_hp" value={data.no_hp} onChange={handleChange} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="tanggal_lahir">
            <Form.Label column sm="4">
              Tanggal Lahir
            </Form.Label>
            <Col sm="8">
              <Form.Control type="date" name="tanggal_lahir" value={data.tanggal_lahir} onChange={handleChange} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="tempat_lahir">
            <Form.Label column sm="4">
              Tempat Lahir
            </Form.Label>
            <Col sm="8">
              <Form.Control type="text" name="tempat_lahir" value={data.tempat_lahir} onChange={handleChange} />
            </Col>
          </Form.Group>
          <Modal.Footer>
            <Button type="submit" variant="primary"   >
              Simpan
            </Button>
          </Modal.Footer>
        </Form>
          </Modal.Body>
        </Modal>
      </>
    );
})

function mapStateToProps(state) {
  return {
      getUserpemodal: state.userReducer.getUserpemodal,
      auth: state.authReducer.authData
  }
}

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(UbahProfil);

