/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom'
import "../../App.scss";
import { connect } from "react-redux";
import { BallBeat } from 'react-pure-loaders';
import Navbar from "../../components/navbar_home";
import Dasar from "../../components/Footer";
import Body from "./NotFound";
import { Tabs, Tab, Table, Sonnet, Carousel, Form, FormText } from 'react-bootstrap';
import { BASE_URL, tokenGet } from "../../service/api";
import axios from 'axios';
import { Image, Message, Button, Icon, Progress, Header, FormInput, FormTextArea } from 'semantic-ui-react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { getUserpemodal } from "../../actions/userpemodal.actions";
import proyk from "../../assets/img/Group 863.png";
import { getUserpengelola } from "../../actions/userpengelola.actions";
import $, { event } from 'jquery'
import img_profile from '../../assets/img/img_avatar.png'
import { arrayInsert } from 'redux-form';
import ReactDOM from "react-dom";
import { SkipNext } from '@material-ui/icons';
import swal from 'sweetalert';

class Detail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            itemsDiscussion: [],
            statusLogin: false,
            userinfo: [],
            slot: 1,
            alertsuccess: 'test',
            alertinfo: 'test',
            alertdanger: 'test',
            slotSim: 1,
            estimasi: 0,
            est_profit: 0,
            slot_nominal_real: 0,
            bghslPemodal: 0,
            bghslPengelola: 0,
            prospektus: '',
            legalitas: '',
            errors: {},
            childIndexComment: 0,
            project_id: '',
            nominal: 0
        };
    }

    clearConsole() {
        if (window.console || window.console.firebug) {
            console.clear();
        }
    }

    timeBetweenDates(startDate, toDate, status) {
        var dateEntered = new Date(toDate);
        var now = new Date();
        var difference = dateEntered.getTime() - now.getTime();

        var seconds = Math.floor(difference / 1000);
        var minutes = Math.floor(seconds / 60);
        var hours = Math.floor(minutes / 60);
        var days = Math.floor(hours / 24);

        var bataspendaftaran = 'Berakhir'
        hours %= 24;
        minutes %= 60;
        seconds %= 60;
        if (days == 0) {
            bataspendaftaran = 'Sampai Hari Ini'
            return bataspendaftaran
        } else if (days < 0 || status >= 7) {
            bataspendaftaran = 'Berakhir'
            return bataspendaftaran
        } else {
            bataspendaftaran = days + ' Hari Lagi'
            return bataspendaftaran
        }
    }

    getData() {
        const { match: { params } } = this.props;

        this.project_id = params.id_project

        axios.get(BASE_URL + "/projectdetail", {
            params: {
                id_project: params.id_project,
                pblc: 'pblc'
            }
        },
            axios.defaults.headers.common['Authorization'] = tokenGet,
            axios.defaults.headers.common['Accept'] = 'application/json'
        )
            .then((resp) => {

                var legalitas = ''
                var prospektus = ''

                resp.data.data[0].proyek_data.map((a, i) => {
                    if (a.type == 'legalitas') {
                        legalitas = a.nama_file
                    }

                    if (a.type == 'prospektus') {
                        prospektus = a.nama_file
                    }
                });

                var estimasiProfit = (resp.data.data[0].proyeksi_profit == null) ? 0 : resp.data.data[0].proyeksi_profit;
                // var estimasiProfit      = resp.data.data[0].est_profit;
                var slot_nominal_real = resp.data.data[0].slot_nominal_real;
                var ttlestimasiProfit = (this.state.slotSim / resp.data.data[0].total_slot_req_real) * estimasiProfit;
                // var ttlestimasiProfit   = estimasiProfit;
                var nisbah = resp.data.data[0].est_profit
                var sisanisbah = 100 - nisbah
                var bghslPemodal = ttlestimasiProfit * (nisbah / 100);
                var bghslPengelola = ttlestimasiProfit * (sisanisbah / 100);

                //versi 2
                // var estimasiProfit      = (resp.data.data[0].proyeksi_profit == null) ? 0 : resp.data.data[0].proyeksi_profit;
                // var slot_nominal_real   = resp.data.data[0].slot_nominal_real;
                // var nisbah              = resp.data.data[0].est_profit 
                // var sisanisbah          = 100 - nisbah
                // var tmbghslPemodal        = (estimasiProfit * (nisbah / 100));
                // var tmbghslPengelola      = (estimasiProfit * (sisanisbah / 100));
                // var bghslPemodal        = (this.state.slotSim / resp.data.data[0].total_slot_req_real) * tmbghslPemodal;
                // console.log(bghslPemodal)
                // var bghslPengelola      = (this.state.slotSim / resp.data.data[0].total_slot_req_real) * tmbghslPengelola;
                // var ttlestimasiProfit   = estimasiProfit;


                this.setState({
                    isLoaded: true,
                    items: resp.data.data,
                    sisanisbah: sisanisbah,
                    nisbah: nisbah,
                    est_profit: estimasiProfit,
                    slot_nominal_real: slot_nominal_real,
                    nominal: slot_nominal_real,
                    estimasi: ttlestimasiProfit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."),
                    bghslPemodal: bghslPemodal,
                    bghslPengelola: bghslPengelola,
                    legalitas: legalitas,
                    prospektus: prospektus
                })
            })
            .catch((resp) => {

            });

    }

    getDiscussion = async () => {

        const { match: { params } } = this.props;

        let config = {
            headers: {
                'Authorization': localStorage.token,
                'content-type': 'application/json',
                'Access-Control-Allow-Credentials': true,
                'Access-Control-Allow-Methods': 'GET',
            },
        }


        axios.get(BASE_URL + "/customer/discussion?id_project=" + params.id_project, config)
            .then((resp) => {

                // console.log(resp.data.data)

                this.setState({
                    itemsDiscussion: resp.data.data
                })
            })
            .catch((error) => {
                console.log('DATA_DISKUSI ERROR >>> ' + JSON.stringify(error))
            });
    }

    getUserpemodal = async (values) => {

        try {
            // console.log(this.props.auth.isUserId);
            const response = await this.props.dispatch(getUserpemodal(values));

            localStorage.DataUser = JSON.stringify(response.responseBody);
            // console.log(response.responseBody.success);

            if (!response.responseBody.success) {
                throw response;
            } else if (response.responseBody.success) {

                this.state.userinfo = JSON.stringify(response.responseBody.user);
                this.state.isLoaded = true;

            }

        } catch (error) {
            console.log(error);
        }
    }

    getUserpengelola = async (values) => {

        try {
            // console.log(this.props.auth.isUserId);
            const response = await this.props.dispatch(getUserpengelola(values));

            localStorage.DataUser = JSON.stringify(response.responseBody);
            // console.log(response.responseBody.success);

            if (!response.responseBody.success) {
                throw response;
            } else if (response.responseBody.success) {

                this.state.userinfo = JSON.stringify(response.responseBody.user);
                this.state.isLoaded = true;

            }

        } catch (error) {
            console.log(error);
        }
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        if (this.props.auth.isLoggedInPemodal === true) {
            const d = this.getUserpemodal({ token: this.props.auth.isUserId });
            this.setState({
                statusLogin: true
            })
        } else if (this.props.auth.isLoggedInPengelola === true) {
            const d = this.getUserpengelola({ token: this.props.auth.isUserId });
            this.setState({
                statusLogin: true
            })
        } else {
            this.setState({
                statusLogin: false
            })
        }
        this.getData();
        this.getDiscussion();
    }

    plus(value) {
        var nilai = 1 + parseInt(value)
        if(parseInt(value) > 9999){
            return
        }
        this.setState({
            slot: nilai,
            nominal: nilai * this.state.slot_nominal_real
        })
    }

    minus(value) {
        var nilai = parseInt(value) - 1
        if (nilai < 1) {
            nilai = 1
        }
        this.setState({
            slot: nilai,
            nominal: nilai * this.state.slot_nominal_real
        })
    }

    handleChange(value) {
        const re = /^[0-9\b]+$/;
        if (value != '' && !re.test(value)) {
            return
        }
        if(parseInt(value) > 9999){
            return
        }
        this.setState({
            slot: value,
            nominal: value * this.state.slot_nominal_real
        })
    }

    plusSim(value) {
        var nilai = 1 + parseInt(value)
        // var ttlestimasiProfit   = (nilai * this.state.slot_nominal_real) * (this.state.est_profit / 100);
        var estimasiProfit = (this.state.items[0].proyeksi_profit == null) ? 0 : this.state.items[0].proyeksi_profit;
        var ttlestimasiProfit = (nilai / this.state.items[0].total_slot_req_real) * estimasiProfit;

        this.setState({
            slotSim: nilai,
            estimasi: ttlestimasiProfit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        })
        this.simulasikan(nilai)
    }

    simulasikan(value) {
        // var ttlestimasiProfit   = (value * this.state.slot_nominal_real) * (this.state.est_profit / 100);
        var estimasiProfit = (this.state.items[0].proyeksi_profit == null) ? 0 : this.state.items[0].proyeksi_profit;
        var ttlestimasiProfit = (value / this.state.items[0].total_slot_req_real) * estimasiProfit;

        var nisbah = this.state.items[0].est_profit
        var sisanisbah = 100 - nisbah
        var bghslPemodal = ttlestimasiProfit * (nisbah / 100);
        var bghslPengelola = ttlestimasiProfit * (sisanisbah / 100);

        // var bghslPemodal        = ttlestimasiProfit * 0.4;
        // var bghslPengelola      = ttlestimasiProfit * 0.6;

        this.setState({
            bghslPemodal: bghslPemodal,
            bghslPengelola: bghslPengelola,
        })
    }

    minusSim(value) {
        var nilai = parseInt(value) - 1
        // var ttlestimasiProfit   = (nilai * this.state.slot_nominal_real) * (this.state.est_profit / 100);
        var estimasiProfit = (this.state.items[0].proyeksi_profit == null) ? 0 : this.state.items[0].proyeksi_profit;
        var ttlestimasiProfit = (nilai / this.state.items[0].total_slot_req_real) * estimasiProfit;


        if (nilai < 1) {
            nilai = 1
            // var ttlestimasiProfit   = (nilai * this.state.slot_nominal_real) * (this.state.est_profit / 100);
            var ttlestimasiProfit = (nilai / this.state.items[0].total_slot_req_real) * estimasiProfit;
        }
        this.setState({
            slotSim: nilai,
            estimasi: ttlestimasiProfit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        })
        this.simulasikan(nilai)
    }

    formatRupiah(bilangan) {
        var rev = parseInt(bilangan);
        return 'Rp. ' + rev.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    testing(e) {
        var value = $("input[name='inputComment']").val(e);
        alert(value)
    }

    render() {
        // this.clearConsole();
        const { match: { params } } = this.props;
        const { error, isLoaded, items, prospektus, legalitas } = this.state;

        const saveDanai = async () => {
            if (this.state.statusLogin == false) {
                window.location.href = "/Signin"
            } else {
                if(!JSON.parse(localStorage.getItem('DataUser')).success){
                    window.location.href = "/Signin"
                    return
                }

                // if (JSON.parse(this.state.userinfo).status_user != 99) {
                //     this.setState({
                //         alertinfo: 'Maaf anda belum bisa melakukan transaksi, sebelum status anda terverifikasi'
                //     })
                //     $('.msginfo').removeAttr('style');
                //     window.setTimeout(function () {
                //         $(".msginfo").attr('style', 'display:none')
                //     }, 5000);
                // }

                // cek income jika tipe pemodal perorangan tanpa pengalaman
                // var userinfo = JSON.parse(this.state.userinfo)
                // if (userinfo.tipe_pemodal == 1) {
                //     var income = userinfo.income
                //     var income_indicator = process.env.REACT_APP_INCOME_INDICATOR
                //     var percent1 = (income * process.env.REACT_APP_PERCENT1) / 100
                //     var percent2 = (income * process.env.REACT_APP_PERCENT2) / 100

                //     if (income <= income_indicator) {
                //         if (this.state.slot_nominal_real > percent1) {
                //             this.setState({
                //                 alertinfo: 'Maaf anda tidak bisa mendanai lebih dari ' + percent1
                //             })
                //             $('.msginfo').removeAttr('style');
                //             window.setTimeout(function () {
                //                 $(".msginfo").attr('style', 'display:none')
                //             }, 5000);
                //             return false;
                //         }
                //     }

                //     if (income > income_indicator) {
                //         if (this.state.slot_nominal_real > percent2) {
                //             this.setState({
                //                 alertinfo: 'Maaf anda tidak bisa mendanai lebih dari ' + percent2
                //             })
                //             $('.msginfo').removeAttr('style');
                //             window.setTimeout(function () {
                //                 $(".msginfo").attr('style', 'display:none')
                //             }, 5000);
                //             return false;
                //         }
                //     }
                // }

                let config = {
                    headers: {
                        'Authorization': localStorage.token,
                        'content-type': 'application/json',
                        'Access-Control-Allow-Credentials': true,
                        'Access-Control-Allow-Methods': 'POST',
                    },
                }

                var postData = {
                    "id_project": params.id_project,
                    "id_pemodal": JSON.parse(this.state.userinfo).id,
                    "type": 0,
                    "slot": this.state.slot
                }

                axios.post(BASE_URL + "/addwishlist", postData, config)
                    .then((resp) => {

                        if (resp.data.body.tipemsg == 1) {
                            this.setState({
                                alertsuccess: resp.data.body.message
                            })
                            window.location.href = "/UserPemodal/?tabs=proyektersimpan"
                        } else if (resp.data.body.tipemsg == 2) {
                            this.setState({
                                alertinfo: resp.data.body.message
                            })
                            window.location.href = "/UserPemodal/?tabs=proyektersimpan"
                        } else {
                            this.setState({
                                alertdanger: 'Maaf, terjadi kesalahan pada sistem kami coba lagi nanti'
                            })
                            $('.msgdanger').removeAttr('style');
                            window.setTimeout(function () {
                                $(".msgdanger").attr('style', 'display:none')
                            }, 3000);
                        }
                    })
                    .catch((error) => {
                        // this.setState({
                        //     alertdanger: 'Maaf, terjadi kesalahan pada sistem kami coba lagi nanti'
                        // })
                        // $('.msgdanger').removeAttr('style');
                        // window.setTimeout(function () {
                        //     $(".msgdanger").attr('style', 'display:none')
                        // }, 3000);

                        try {
                            let errorStatusCode = error.response.status
                  
                            if (errorStatusCode === 401) {
                              setTimeout(() => {
                                window.location.href = "/Signin"
                                localStorage.removeItem('token');
                                localStorage.clear();
                  
                                this.props.dispatch({
                                  type: "AUTH_USER_LOGOUT",
                                })
                              }, 500);
                            } else {
                              let errorBody = error.response.data.body
                              swal({ text: errorBody.message, icon: 'error' })
                            }
                  
                          } catch (error) {
                            swal({ text: 'Error tidak terdefinisi!', icon: 'error' })
                          }
                    });

            }
        }

        const saveFavorit = async () => {
            if (this.state.statusLogin == false) {
                window.location.href = "/Signin"
            } else {
                if(!JSON.parse(localStorage.getItem('DataUser')).success){
                    window.location.href = "/Signin"
                    return
                }

                if (JSON.parse(this.state.userinfo).status_user != 3) {
                    this.setState({
                        alertinfo: 'Maaf anda belum bisa melakukan transaksi, sebelum status anda terverifikasi'
                    })
                    $('.msginfo').removeAttr('style');
                    window.setTimeout(function () {
                        $(".msginfo").attr('style', 'display:none')
                    }, 5000);
                    return false;
                }

                let config = {
                    headers: {
                        'Authorization': localStorage.token,
                        'content-type': 'application/json',
                        'Access-Control-Allow-Credentials': true,
                        'Access-Control-Allow-Methods': 'POST',
                    },
                }

                var postData = {
                    "id_project": params.id_project,
                    "id_pemodal": JSON.parse(this.state.userinfo).id,
                    "type": 1,
                }

                axios.post(BASE_URL + "/addwishlist", postData, config)
                    .then((resp) => {

                        if (resp.data.body.tipemsg == 1) {
                            this.setState({
                                alertsuccess: 'Favorit Proyek Sudah Ditambahkan'
                            })
                            $('.msgsuccess').removeAttr('style');
                            window.setTimeout(function () {
                                $(".msgsuccess").attr('style', 'display:none')
                            }, 3000);
                        } else if (resp.data.body.tipemsg == 2) {
                            this.setState({
                                alertinfo: 'Favorit Proyek Sudah Pernah Ditambahkan'
                            })
                            $('.msginfo').removeAttr('style');
                            window.setTimeout(function () {
                                $(".msginfo").attr('style', 'display:none')
                            }, 3000);
                        } else {
                            this.setState({
                                alertdanger: 'Maaf, terjadi kesalahan pada sistem kami coba lagi nanti'
                            })
                            $('.msgdanger').removeAttr('style');
                            window.setTimeout(function () {
                                $(".msgdanger").attr('style', 'display:none')
                            }, 3000);
                        }
                    })
                    .catch((resp) => {
                        this.setState({
                            alertdanger: 'Maaf, terjadi kesalahan pada sistem kami coba lagi nanti'
                        })
                        $('.msgdanger').removeAttr('style');
                        window.setTimeout(function () {
                            $(".msgdanger").attr('style', 'display:none')
                        }, 3000);
                    });

            }
        }

        const saveDaftarProyek = async () => {

            if (this.state.statusLogin == false) {
                window.location.href = "/Signin"
            } else {

                if(!JSON.parse(localStorage.getItem('DataUser')).success){
                    window.location.href = "/Signin"
                    return
                }

                if (JSON.parse(this.state.userinfo).status_user != 3) {
                    this.setState({
                        alertinfo: 'Maaf anda belum bisa melakukan transaksi, sebelum status anda terverifikasi'
                    })
                    $('.msginfo').removeAttr('style');
                    window.setTimeout(function () {
                        $(".msginfo").attr('style', 'display:none')
                    }, 5000);
                    return false;
                }

                // cek income jika tipe pemodal perorangan tanpa pengalaman
                var userinfo = JSON.parse(this.state.userinfo)
                if (userinfo.tipe_pemodal == 1) {
                    var income = userinfo.income
                    var income_indicator = process.env.REACT_APP_INCOME_INDICATOR
                    var percent1 = (income * process.env.REACT_APP_PERCENT1) / 100
                    var percent2 = (income * process.env.REACT_APP_PERCENT2) / 100

                    if (income <= income_indicator) {
                        if (this.state.slot_nominal_real > percent1) {
                            this.setState({
                                alertinfo: 'Maaf anda tidak bisa mendanai lebih dari ' + percent1
                            })
                            $('.msginfo').removeAttr('style');
                            window.setTimeout(function () {
                                $(".msginfo").attr('style', 'display:none')
                            }, 5000);
                            return false;
                        }
                    }

                    if (income > income_indicator) {
                        if (this.state.slot_nominal_real > percent2) {
                            this.setState({
                                alertinfo: 'Maaf anda tidak bisa mendanai lebih dari ' + percent2
                            })
                            $('.msginfo').removeAttr('style');
                            window.setTimeout(function () {
                                $(".msginfo").attr('style', 'display:none')
                            }, 5000);
                            return false;
                        }
                    }
                }

                let config = {
                    headers: {
                        'Authorization': localStorage.token,
                        'content-type': 'application/json',
                        'Access-Control-Allow-Credentials': true,
                        'Access-Control-Allow-Methods': 'POST',
                    },
                }

                var postData = {
                    "id_project": params.id_project,
                    "id_pemodal": JSON.parse(this.state.userinfo).id,
                    "type": 0,
                }

                axios.post(BASE_URL + "/addwishlist", postData, config)
                    .then((resp) => {

                        if (resp.data.body.tipemsg == 1) {
                            this.setState({
                                alertsuccess: resp.data.body.message
                            })
                            $('.msgsuccess').removeAttr('style');
                            window.setTimeout(function () {
                                $(".msgsuccess").attr('style', 'display:none')
                            }, 3000);
                        } else if (resp.data.body.tipemsg == 2) {
                            this.setState({
                                alertinfo: resp.data.body.message
                            })
                            $('.msginfo').removeAttr('style');
                            window.setTimeout(function () {
                                $(".msginfo").attr('style', 'display:none')
                            }, 3000);
                        } else {
                            this.setState({
                                alertdanger: 'Maaf, terjadi kesalahan pada sistem kami coba lagi nanti'
                            })
                            $('.msgdanger').removeAttr('style');
                            window.setTimeout(function () {
                                $(".msgdanger").attr('style', 'display:none')
                            }, 3000);
                        }
                    })
                    .catch((resp) => {
                        this.setState({
                            alertdanger: 'Maaf, terjadi kesalahan pada sistem kami coba lagi nanti'
                        })
                        $('.msgdanger').removeAttr('style');
                        window.setTimeout(function () {
                            $(".msgdanger").attr('style', 'display:none')
                        }, 3000);
                    });

            }
        }

        const itemsImg = []

        for (const [index, value] of items.entries()) {
            for (const [indexImg, valueImg] of value.proyek_data.entries()) {
                if (valueImg.type === "avatar" || valueImg.type === 'gambar') {
                    itemsImg.push(<Image id="myImg" className="detailProyek" bordered rounded key={indexImg} src={valueImg.nama_file}
                        href={valueImg.nama_file}
                        onClick={(e) => {
                            e.preventDefault();
                            clickDetImg(valueImg.nama_file);
                        }}
                    />)
                }
            }
        }

        const clickDetImg = function (nama_file) {
            if (nama_file) {
                var modal = document.getElementById("myModal");
                var modalImg = document.getElementById("img01");
                var captionText = document.getElementById("caption");

                modal.style.display = "block";
                modalImg.src = nama_file;
                captionText.innerHTML = 'Detail Gambar';
            }
        }

        const closeDetImg = function () {
            var modal = document.getElementById("myModal");
            modal.style.display = "none";
        }

        window.onclick = function (event) {
            var modal = document.getElementById("myModal");
            if (event.target == modal) {
                modal.style.display = "none";
            }
        }

        const UIShowDiscussion = () => {
            if(this.state.statusLogin && JSON.parse(localStorage.getItem('DataUser'))){
                if(!JSON.parse(localStorage.getItem('DataUser')).success){
                    return (<></>)
                }

                return (
                    <ShowDiskusi
                        statusLogin={this.state.statusLogin}
                        itemsDiscussion={this.state.itemsDiscussion}
                        getDiscussion={this.getDiscussion}
                        projectId={this.project_id}
                    />
                )
            } else {
                return (<></>)
            }
        }

        if (!this.state.isLoaded) {

            return (
                <div
                    style={{ paddingTop: '300px', Width: 'initial', textAlign: 'center' }}
                >
                    <BallBeat
                        color={'#123abc'}
                        loading
                    />
                    Loading....</div>
            );
        }
        else {
            return (
                <div>
                    <Navbar />
                    <section className="header" style={{ background: "#EEEEEE" }}>
                        {items.map(item => {
                            return (
                                <div key={item.id_project} className="container">
                                    <div className="row mb-">
                                        <div className="col-md-12 text-muted" style={{ marginTop: '0.5cm'}}>
                                            {/* <h2 style={{ marginTop: '0.5cm', fontFamily: 'Helvetica' }}>{item.nama_project}</h2> */}
                                            {/* <h6>By {item.full_name}</h6> */}
                                            <div style={{fontSize: '18px', fontWeight: 700}}>{item.nama_project}</div>
                                            <div style={{fontSize: '14px', marginTop: '5px', marginBottom: '5px'}}>By {item.full_name}</div>
                                        </div>
                                    </div>
                                    <div className="row row-header">
                                        <div className="col-md-12 col-lg-12 col-xs-12 p-1">
                                            <Message icon color="teal" className="msginfo" style={{ display: 'none' }}>
                                                <Icon name='circle notched' loading />
                                                <Message.Content>
                                                    <Message.Header>INFO</Message.Header>
                                                    {this.state.alertinfo}
                                                </Message.Content>
                                            </Message>
                                            <Message icon color="green" className="msgsuccess" style={{ display: 'none' }}>
                                                <Icon name='circle notched' loading />
                                                <Message.Content>
                                                    <Message.Header>SUCCESS</Message.Header>
                                                    {this.state.alertsuccess}
                                                </Message.Content>
                                            </Message>
                                            <Message icon color="red" className="msgdanger" style={{ display: 'none' }}>
                                                <Icon name='circle notched' loading />
                                                <Message.Content>
                                                    <Message.Header>ERROR</Message.Header>
                                                    {this.state.alertdanger}
                                                </Message.Content>
                                            </Message>
                                        </div>
                                        <div className="card" style={{ width: '-webkit-fill-available' }}>
                                            <div className="row">
                                                <div className="col-lg-6 p-4">
                                                    <div className="card card-gambar">
                                                        {(this.props.auth.isLoggedInPengelola !== true) ?
                                                            (<div className="ui fluid image">
                                                                <a onClick={(e) => {
                                                                    e.preventDefault();
                                                                    saveFavorit();
                                                                }} className="ui green right corner label"><i aria-hidden="true" className="heart icon"></i></a>
                                                            </div>) : ''}
                                                        <Image src={item.proyek_data[0].nama_file} className="card-img-top imgDetailSampul" size='big' alt="..." />
                                                        <a href="#" className="btn btn-hari btn-block btn-primary" style={{ background: "#02A852" }}>{this.timeBetweenDates(item.date_awal_val, item.date_akhir_val, item.status)}</a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 ">
                                                    <div className="pinjam p-4">
                                                        <Progress percent={item.persentase_slot} progress className="progressProject" style={{ margin: '0 0 0' }} />
                                                        <Form autocomplete="off">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h5 className="text-muted">Dana dibutuhkan : <br /> <span className="font-weight-bold">
                                                                    {this.formatRupiah(item.capital_needed)}</span>
                                                                </h5> <br /> <br />

                                                                <h5 className="text-muted">Tipe Efek : <br /><span
                                                                    className="font-weight-bold">
                                                                    {(item.type_efek == "EBU") ? "Efek Bersifat Utang (EBU)" : ""}
                                                                    {(item.type_efek == "EBE") ? "Efek Bersifat Ekuitas (EBE)" : ""}
                                                                </span></h5> <br /><br />
                                                                <h5 className="text-muted">{/*Nisbah Bagi Hasil*/}Dividen :<br /> <span
                                                                    className="font-weight-bold">{item.est_profit}</span>%</h5><br /><br />

                                                                {/* <div>
                                                                    <a href="#" onClick={(event) => { this.minus(this.state.slot), event.preventDefault() }}><i className="fa fa-minus"></i></a> 
                                                                    <span id="satu"> {this.state.slot}/</span> <span id="rp">{item.slot_nominal}</span>

                                                                    <a href="#" onClick={(event) => { this.plus(this.state.slot), event.preventDefault() }}> <i className="fa fa-plus-square"></i></a>
                                                                </div> */}
                                                            </div>
                                                            <div className="col-md-6">
                                                                <h5 className="text-muted">Masa Investasi/Tenor : <br /><span className="font-weight-bold">{item.durasi_crowd} Hari</span> </h5> <br /><br />
                                                                <h5 className="text-muted">Slot : <br /><span className="font-weight-bold">{item.total_slot_req} Slot</span> </h5><br /><br />
                                                                <h5 className="text-muted">Tanggal berakhir : <br /><span className="font-weight-bold">{item.date_akhir}</span> </h5>
                                                                {/* {(this.props.auth.isLoggedInPengelola !== true && this.timeBetweenDates(item.date_awal_val, item.date_akhir_val, item.status) !== 'Berakhir' && item.persentase_slot < 100) ?
                                                                    (
                                                                        <a href="#" onClick={(e) => {
                                                                            e.preventDefault();
                                                                            saveDanai();
                                                                        }} className="btn" style={{ background: "#02A852", color: "#ffffff", borderRadius: "25px", padding: "5px 50px" }}> Danai </a>
                                                                    ) : ''} */}
                                                            </div>
                                                        </div>

                                                        <div className="text-center row">
                                                            <div className='col-md-6'>
                                                                <div class="input-group">
                                                                    <span class="input-group-btn">
                                                                        <button type="button" class="btn" 
                                                                            onClick={(event) => { this.minus(this.state.slot), event.preventDefault() }} 
                                                                            style={{background: '#033C86', color: '#FFFFFF'}}><span class="fa fa-minus"></span>
                                                                        </button>
                                                                    </span>
                                                                    <input type="text" name="slot" class="form-control col-sm-4" 
                                                                        value={this.state.slot} 
                                                                        onChange={(event) => { this.handleChange(event.target.value)}}
                                                                        style={{textAlign: 'center'}}/>
                                                                    <span class="input-group-btn">
                                                                        <button type="button" class="btn" 
                                                                            onClick={(event) => { this.plus(this.state.slot), event.preventDefault() }}
                                                                            style={{background: '#033C86', color: '#FFFFFF'}}><span class="fa fa-plus"></span>
                                                                        </button>
                                                                    </span>
                                                                </div>
                                                             </div>
                                                             <div className='col-md-6'>
                                                                {/* <span id="rp">{item.slot_nominal}</span> */}
                                                                <span id="rp">{this.formatRupiah(this.state.nominal)}</span>
                                                            </div>
                                                        </div>
                                                        
                                                        {(this.props.auth.isLoggedInPengelola !== true && this.timeBetweenDates(item.date_awal_val, item.date_akhir_val, item.status) !== 'Berakhir' && item.persentase_slot < 100) ?
                                                            (
                                                                <div className="col-md-12 text-center daftarProyek">
                                                                    <a href="#" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        saveDanai();
                                                                    }} className="btn col-md-12" style={{ background: "#02A852", color: "#ffffff", borderRadius: "25px", padding: "5px 50px" }}> Danai </a>
                                                                </div>
                                                            ) : null
                                                        }
                                                        
                                                        {/* <div className="text-center daftarProyek">
                                                            {(this.props.auth.isLoggedInPengelola !== true && this.timeBetweenDates(item.date_awal_val, item.date_akhir_val, item.status) !== 'Berakhir' && item.persentase_slot < 100) ?
                                                                (<a href="#" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    saveDaftarProyek();
                                                                }} className="btn" style={{ background: "#02A852", color: "#ffffff", borderRadius: "20px", padding: "5px 30px" }}>
                                                                    Tambahkan ke Daftar Bisnis Tersimpan
                                                                </a>)
                                                                : ''}
                                                        </div> */}
                                                        </Form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ background: "#EEEEEE" }} >
                                        <div className="container" style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Tabs defaultActiveKey="home" transition={false} id="noanim-tab-example">
                                                        <Tab eventKey="home" title="Deskripsi">
                                                            <div className="card" style={{ height: "487px", fontFamily: "Helvetica" }}>
                                                                <div className="card-body">
                                                                    <div style={{ textAlign: "justify" }}>
                                                                        {item.deskripsi}
                                                                    </div>
                                                                </div>
                                                                {(prospektus) ?  // && legalitas
                                                                    <div style={{ textAlign: "center", paddingBottom: '50px' }}>
                                                                        <a href={prospektus} className="btn" target="_blank" style={{
                                                                            marginTop: "30px", background: "#02A852", color: "#ffffff",
                                                                            borderRadius: "20px", padding: "5px 50px"
                                                                        }}>
                                                                            {/* Download Prospektus */}
                                                                            Download Proposal Bisnis
                                                                        </a>
                                                                        {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <a href={legalitas} className="btn" target="_blank" style={{marginTop:"30px",background:"#214981",color:"#ffffff",
                                            borderRadius:"20px",padding:"5px 50px"}}>Download Legalitas</a> */}
                                                                    </div>
                                                                    : ''}
                                                            </div>

                                                        </Tab>
                                                        <Tab eventKey="profile" title="Gallery">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div style={{ height: "450px" }}>
                                                                        <div className="row">
                                                                            <div className="col-md-12 col-lg-12 col-xs-12">
                                                                                <Image.Group size='small'>
                                                                                    {itemsImg}
                                                                                </Image.Group>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div id="myModal" className="modalMdl">
                                                                    <span className="closeMdl" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        closeDetImg();
                                                                    }} >&times;</span>
                                                                    <img className="modalMdl-content" id="img01" style={{ width: '100%' }} />
                                                                    <div id="caption"></div>
                                                                </div>
                                                            </div>

                                                        </Tab>

                                                    </Tabs>
                                                </div>
                                                {/* <div className="col-md " style={{ paddingTop: "40px" }}>
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <Table responsive className="bordered tblBagihasil" style={{ border: "2px solid #02A852" }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th colSpan="4" className="text-center" style={{ background: "#02A852", color: "#ffffff" }}>Bagi Hasil Antara Pihak Penerbit & Investor </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Pemodal</td>
                                                                        <td colSpan="2" >{this.state.nisbah}%</td>
                                                                        <td>Rp {this.state.bghslPemodal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Penerbit</td>
                                                                        <td colSpan="2">{this.state.sisanisbah}%</td>
                                                                        <td>Rp {this.state.bghslPengelola.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>

                                                            <Table responsive className="mt-5 bordered tblSimulasi" style={{ border: "2px solid #02A852" }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th colSpan="4" className="text-center" style={{ background: "#02A852", color: "#ffffff" }}>Kalkulator Simulasi Investasi</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td colSpan="2"> Jumlah Slot</td>
                                                                        <td colSpan="2">
                                                                            <a href="#" onClick={(event) => { this.minusSim(this.state.slotSim), event.preventDefault() }}><i className="fa fa-minus"></i></a>
                                                                            <span id="satu">{this.state.slotSim}/</span> <span id="rp">{item.slot_nominal}</span>
                                                                            <a href="#" onClick={(event) => { this.plusSim(this.state.slotSim), event.preventDefault() }}> <i className="fa fa-plus-square"></i></a>
                                                                        </td>
                                                                        {item.total_slot_req}
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan="2">Estimasi Keuntungan</td>
                                                                        <td colSpan="2">Rp {this.state.estimasi}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                            <div className="text-center">
                                                                <a href="#" className="btn" style={{ marginTop: "30px", background: "#02A852", color: "#ffffff", borderRadius: "20px", padding: "5px 50px" }}
                                                                    onClick={(event) => { this.simulasikan(this.state.slotSim), event.preventDefault() }}>Simulasikan</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>

                                   { UIShowDiscussion() }

                                </div>

                            );
                        })}
                    </section>
                    {(items.length === 0) ? <Body /> : <Dasar />}
                </div>
            )
        }

    }
}

function ShowDiskusi(props) {

    let imageProfile = ''
    let dataUser = JSON.parse(localStorage.getItem('DataUser')).user

    dataUser.dokumen.map((a, i) => {
        if (a.type == 'profile') {
            imageProfile = a.nama_file
        }
    })

    const reff = React.useRef(null);

    const [form, setForm] = useState({
        type: 'penerbit',
        diskusi_text: '',
        project_id: props.projectId,
        user_id: '',
    });

    const postDiskusi = async (values) => {
        // alert(JSON.stringify(values))
        // return

        values.user_id = dataUser.user_id

        let config = {
            headers: {
                'Authorization': localStorage.token,
                'content-type': 'application/json',
                'Access-Control-Allow-Credentials': true,
                'Access-Control-Allow-Methods': 'POST',
            },
            maxContentLength: 52428890,
            timeout: 10000000,
        }

        $(".btnSending").addClass('loading');
        $(".btnSending").addClass('disabled');

        axios.post(BASE_URL + "/customer/discussion", values, config)
            .then((resp) => {

                reff.current.reset();
                setForm({
                    type: 'penerbit',
                    diskusi_text: '',
                    project_id: props.projectId,
                    user_id: '',
                });

                const element = document.getElementById("card_discussion");
                element.scrollIntoView({ behavior: 'smooth' });

                props.getDiscussion()

                $(".btnSending").removeClass('loading');
                $(".btnSending").removeClass('disabled');
            })
            .catch(error => {
                let errorStatusCode = error.response.status

                if (errorStatusCode === 401) {
                    setTimeout(() => {
                        window.location.href = "/Signin"
                        localStorage.removeItem('token');
                        localStorage.clear();

                        this.props.dispatch({
                            type: "AUTH_USER_LOGOUT",
                        })
                    }, 500);
                } else {
                    swal({text: 'Error tidak terdefinisi!', icon: 'error'})
                }

                $(".btnSending").removeClass('loading');
                $(".btnSending").removeClass('disabled');
            });
    }

    const deleteDiskusi = async (id) => {
        // alert(JSON.stringify(values))
        // return

        const _data = { 'discuss_id': id };

        let config = {
            headers: {
                'Authorization': localStorage.token,
                'content-type': 'application/json',
                'Access-Control-Allow-Credentials': true,
                'Access-Control-Allow-Methods': 'DELETE',
            },
            maxContentLength: 52428890,
            timeout: 10000000,
        }

        // alert(discuss_id)
        // return 

        $('#iconDeleteDiscussion-' + id).addClass('loading')
        $('#iconDeleteDiscussion-' + id).addClass('disabled')

        axios.delete(BASE_URL + "/customer/discussion/", { data: _data }, config)
            .then((resp) => {

                // alert(resp.data.message)
                // return

                props.getDiscussion()

                $('#iconDeleteDiscussion-' + id).removeClass('loading')
                $('#iconDeleteDiscussion-' + id).removeClass('disabled')

            })
            .catch(error => {
                let errorStatusCode = error.response.status

                if (errorStatusCode === 401) {
                    setTimeout(() => {
                        window.location.href = "/Signin"
                        localStorage.removeItem('token');
                        localStorage.clear();

                        this.props.dispatch({
                            type: "AUTH_USER_LOGOUT",
                        })
                    }, 500);
                } else {
                    swal({text: 'Error tidak terdefinisi!', icon: 'error'})
                }

                $('#iconDeleteDiscussion-' + id).removeClass('loading')
                $('#iconDeleteDiscussion-' + id).removeClass('disabled')
            });
    }

    const postComment = async (values, index) => {
        // console.log(JSON.stringify(values))
        // return

        values.user_id = dataUser.user_id

        let config = {
            headers: {
                'Authorization': localStorage.token,
                'content-type': 'application/json',
                'Access-Control-Allow-Credentials': true,
                'Access-Control-Allow-Methods': 'POST',
            },
            maxContentLength: 52428890,
            timeout: 10000000,
        }

        $(".btnSendingComment-" + index).addClass('loading');
        $(".btnSendingComment-" + index).addClass('disabled');

        axios.post(BASE_URL + "/customer/discussion/comment", values, config)
            .then((resp) => {

                // console.log(JSON.stringify(resData))
                // return

                if (!resp.data.success) {
                    swal({text: resp.data.message, icon: 'error'})

                    $(".btnSendingComment-" + index).removeClass('disabled');
                    $(".btnSendingComment-" + index).removeClass('loading');
                    return
                }

                let resData = resp.data.data

                resDataComment.comment_id = resData.comment_id
                resDataComment.comment_value = resData.comment_text
                resDataComment.comment_date = resData.comment_date

                $('#textAreaComment-' + index).val('')

                // let div = document.createElement('div');
                // div.setAttribute('id', indexState)
                // div.setAttribute('class', 'boxCommentManualAdd' + values.discuss_id)
                // $('#BOX-' + index).append(div)

                // ReactDOM.render(<LoadBoxNewComment indexState={indexState} resDataComment={resDataComment} />,
                //     document.getElementById(indexState))

                // setIndexState(indexState + 1)

                setFormComment([initialStateComment])

                $(".btnSendingComment-" + index).removeClass('loading');

                props.getDiscussion()
            })
            .catch(error => {

                let errorStatusCode = error.response.status

                if (errorStatusCode === 401) {
                    setTimeout(() => {
                        window.location.href = "/Signin"
                        localStorage.removeItem('token');
                        localStorage.clear();

                        this.props.dispatch({
                            type: "AUTH_USER_LOGOUT",
                        })
                    }, 500);
                } else {
                    swal({text: 'Error tidak terdefinisi!', icon: 'error'})
                }

                $(".btnSendingComment-" + index).removeClass('loading');
                $(".btnSendingComment-" + index).removeClass('disabled');
            });
    }

    const deleteComment = async (id) => {
        // alert(JSON.stringify(values))
        // return

        const _data = { 'comment_id': id };

        let config = {
            headers: {
                'Authorization': localStorage.token,
                'content-type': 'application/json',
                'Access-Control-Allow-Credentials': true,
                'Access-Control-Allow-Methods': 'DELETE',
            },
            maxContentLength: 52428890,
            timeout: 10000000,
        }

        // alert(discuss_id)
        // return 

        $('#iconDeleteComment-' + id).addClass('loading')
        $('#iconDeleteComment-' + id).addClass('disabled')

        axios.delete(BASE_URL + "/customer/discussion/comment", { data: _data }, config)
            .then((resp) => {

                // alert(resp.data.message)
                // return

                props.getDiscussion()

                $('#iconDeleteComment-' + id).removeClass('loading')
                $('#iconDeleteComment-' + id).removeClass('disabled')

            })
            .catch(error => {
                let errorStatusCode = error.response.status

                if (errorStatusCode === 401) {
                    setTimeout(() => {
                        window.location.href = "/Signin"
                        localStorage.removeItem('token');
                        localStorage.clear();

                        this.props.dispatch({
                            type: "AUTH_USER_LOGOUT",
                        })
                    }, 500);
                } else {
                    swal({text: 'Error tidak terdefinisi!', icon: 'error'})
                }

                $('#iconDeleteComment-' + id).removeClass('loading')
                $('#iconDeleteComment-' + id).removeClass('disabled')
            });
    }

    const handleTextChange = event => {
        setForm({ ...form, [event.target.name]: event.target.value })

        var index = event.target.name
        setErrorMessage(errorMessage => ({ ...errorMessage, [index]: "" }));

        if (index == 'diskusi_text') {
            if (event.target.value.length >= 5) {
                $("textarea[name='diskusi_text']").css('borderColor', '')
            }
        }
    }

    const initialStateComment = {
        discuss_id: '',
        comment_text: '',
        user_id: ''
    };

    const [formComment, setFormComment] = useState([
        initialStateComment
    ]);

    const handleTextCommentChange = (event, index) => {
        const { value, name } = event.target;
        const newState = [...formComment];
        newState[index] = {
            ...newState[index],
            [name]: value,
            [name]: value
        };

        setFormComment(newState)

        if (event.target.value.length >= 5) {
            $(".btnSendingComment-" + index).removeClass('disabled');
        } else {
            $(".btnSendingComment-" + index).addClass('disabled');
        }
    }

    const [errorMessage, setErrorMessage] = useState({});

    const handleTextClick = event => {
        event.preventDefault();

        setForm({ ...form, [event.target.name]: event.target.value })
        event.target.style.display = 'none'
        let index = (event.target).getAttribute('data-index');
        $('#boxComment-' + index).css('display', 'block')
    }

    const onCancelCommentClick = event => {
        event.preventDefault();

        let index = (event.target).getAttribute('data-index');
        $('#boxComment-' + index).css('display', 'none')
        $('#inputComment-' + index).css('display', 'block')
        $('#textAreaComment-' + index).val('')

        $(".btnSendingComment-" + index).removeClass('loading');
        $(".btnSendingComment-" + index).addClass('disabled');

        setFormComment([initialStateComment])
    }

    const [indexState, setIndexState] = useState(0);
    const [resDataComment, setResDataComment] = useState({
        comment_id: '',
        comment_value: '',
        comment_date: ''
    });

    const handleSendComment = (event) => {
        event.preventDefault();

        let index = (event.target).getAttribute('data-index');
        let discussId = (event.target).getAttribute('data-value');

        try {
            if (formComment[index].comment_text.length >= 5) {
                formComment[index].discuss_id = discussId
                postComment(formComment[index], index)
            }
        } catch (error) {
            console.log(error)
        }
    };

    // const LoadBoxNewComment = (props) => {

    //     return (
    //         <>
    //             <div style={{ marginTop: "20px" }}>
    //                 <Image src={imageProfile} size='small' verticalAlign="top" circular
    //                     style={{ width: "40px", height: "40px", marginRight: "10px" }} />
    //                 <div style={{ display: "inline-block", width: "calc(100% - 50px)", wordWrap: "break-word", verticalAlign: "top" }}>
    //                     <strong>{dataUser.full_name}</strong> | {props.resDataComment.comment_date}
    //                     <div style={{ marginTop: "5px" }}>
    //                         {props.resDataComment.comment_value}
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // }

    async function handleInputSubmit() {

        let errors = {};
        let isValid = true;

        if (form.diskusi_text.length < 5) {
            errors['diskusi_text'] = "Minimal 5 karakter!"
            $("textarea[name='diskusi_text']").css('borderColor', 'red')
            isValid = false
        }

        setErrorMessage(errorMessage => ({
            ...errorMessage,
            ...errors
        }));

        if (isValid) {
            postDiskusi(form)
        }

    }

    const handleDeleteDiscussion = (discuss_id) => {
        deleteDiskusi(discuss_id)
    }

    const handleDeleteComment = (comment_id) => {
        deleteComment(comment_id)
    }

    const addDefaultSrc = (ev) => {
        ev.target.src = img_profile;
        ev.target.onerror = null;
    }

    if (props.statusLogin === true) {

        return (
            <>
                <div className="col-md-12" style={{ paddingBottom: "20px" }}>
                    <div id="card_discussion" className="card">
                        <div className="card-body">

                           <div style={{marginBottom: '10px'}}>DISKUSI ({props.itemsDiscussion.length})</div>

                            {props.itemsDiscussion.map((item, index) => {
                                return (
                                    <div className='col-md-12'>
                                        <div style={{ border: "1px solid #DDDDDD", padding: "10px", marginTop: "10px", marginBottom: "10px" }}>
                                            <Image
                                                src={item.nama_file === null ? '' : item.nama_file}
                                                onError={(e) => addDefaultSrc(e)}
                                                size='small' verticalAlign="top" circular
                                                style={{ width: "50px", height: "50px", marginRight: "10px" }} />

                                            <div id="boxComment" key={index} style={{ display: "inline-block", width: "calc(100% - 100px)", wordWrap: "break-word", verticalAlign: "top" }}>
                                                <strong>{item.full_name}</strong> | <span style={{ color: '#808B96', fontSize: '12px' }}>{item.create_at}</span>
                                                {
                                                    item.create_by === dataUser.user_id ?
                                                        <Icon name='trash grey'
                                                            id={'iconDeleteDiscussion-' + item.discussion_id}
                                                            style={{ float: 'right', cursor: 'pointer' }}
                                                            onClick={(event) => { handleDeleteDiscussion(item.discussion_id), event.preventDefault() }}
                                                        />
                                                        : ''
                                                }
                                                <div style={{ marginTop: "5px" }}>
                                                    {item.discussion}
                                                </div>

                                                {
                                                    item.comments.map(citem => {
                                                        return (
                                                            <>
                                                                <div style={{ marginTop: "20px" }}>
                                                                    <Image
                                                                        src={citem.nama_file === null ? '' : citem.nama_file}
                                                                        onError={(e) => addDefaultSrc(e)}
                                                                        size='small' verticalAlign="top" circular
                                                                        style={{ width: "40px", height: "40px", marginRight: "10px" }} />
                                                                    <div style={{ display: "inline-block", width: "calc(100% - 50px)", wordWrap: "break-word", verticalAlign: "top" }}>
                                                                        <strong>{citem.full_name}</strong> | <span style={{ color: '#808B96', fontSize: '12px' }}>{citem.create_at}</span>
                                                                        {
                                                                            citem.create_by === dataUser.user_id ?
                                                                                <Icon name='trash grey'
                                                                                    id={'iconDeleteComment-' + citem.comment_id}
                                                                                    style={{ float: 'right', cursor: 'pointer' }}
                                                                                    onClick={(event) => { handleDeleteComment(citem.comment_id), event.preventDefault() }}
                                                                                />
                                                                                : ''
                                                                        }
                                                                        <div style={{ marginTop: "5px" }}>
                                                                            {citem.comment}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })
                                                }

                                                <div id={'BOX-' + index}></div>

                                                <div style={{ marginTop: "20px" }}>
                                                    <Image src={imageProfile} onError={(e) => addDefaultSrc(e)} size='small' verticalAlign="top" circular
                                                        style={{ width: "40px", height: "40px", marginRight: "10px" }} />
                                                    <div style={{ display: "inline-block", width: "calc(100% - 50px)", wordWrap: "break-word", verticalAlign: "top" }}>
                                                        <Form.Control id={'inputComment-' + index} data-index={index} readOnly='false' onClick={handleTextClick} placeholder='Isi komentar di sini..' />
                                                        <div id={'boxComment-' + index} data-index={index} style={{ display: 'none' }} >

                                                            <textarea
                                                                className="form-control"
                                                                id={"textAreaComment-" + index}
                                                                name="comment_text"
                                                                data-value={item.discussion_id}
                                                                data-index={index}
                                                                placeholder='Isi komentar di sini..'
                                                                onChange={(e) => handleTextCommentChange(e, index)}
                                                                value={formComment.comment_text} />

                                                            <Button id="fonts" className={"btnSendingComment-" + index + " disabled"}
                                                                onClick={handleSendComment}
                                                                data-value={item.discussion_id}
                                                                data-index={index}
                                                                style={{
                                                                    marginRight: "auto", marginLeft: "auto",
                                                                    padding: "5px 20px", borderRadius: "20px",
                                                                    float: "right", fontSize: '11px', marginTop: '5px'
                                                                }}
                                                                color="green" type="button"
                                                            >
                                                                Kirim
                                                            </Button>

                                                            <Button id="fonts" className="btnCancelComment"
                                                                onClick={onCancelCommentClick}
                                                                data-value={item.discussion_id}
                                                                data-index={index}
                                                                style={{
                                                                    marginRight: "10px", marginLeft: "10px",
                                                                    padding: "5px 20px", borderRadius: "20px",
                                                                    float: "right", fontSize: '11px', marginTop: '5px'
                                                                }}
                                                                color="default" type="button">
                                                                Batal
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                            <div className='col-md-12'>
                                <Form ref={reff} id="fonts" name="FormDuskusi" onSubmit={e => e.preventDefault()} encType="multipart/form-data" >
                                    <Form.Group controlId="diskusi_text">
                                        <textarea className="form-control" name='diskusi_text'
                                            onChange={handleTextChange} placeholder="Apa yang ingin Anda tanyakan mengenai proyek ini?">

                                        </textarea>
                                        <span style={{ color: "red", fontSize: 11 }}>{errorMessage["diskusi_text"]}</span>
                                    </Form.Group>

                                    <Button id="fonts" className="btnSending" onClick={handleInputSubmit}
                                        style={{
                                            marginRight: "auto", marginLeft: "auto",
                                            padding: "15px 50px", borderRadius: "20px",
                                            float: "right"
                                        }}
                                        color="green" type="button">
                                        Kirim
                                    </Button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        getUserpemodal: state.userReducer.getUserpemodal,
        getUserpengelola: state.userReducer.getUserpengelola,
        auth: state.authReducer.authData
    }
}

function mapDispatchToProps(dispatch) {
    return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(Detail);