import React, { useState } from "react";
import _ from 'lodash';
import topbar from 'topbar';
import axios from 'axios';
import { connect } from "react-redux";
import { BASE_URL, tokenGet } from "../service/api";
import "../App.scss";
import { logoutUser } from "../actions/auth.actions";
//import logo_kerjasama from "../assets/img/kerjasama-white.png";
import logo_kerjasama from "../assets/img/logo_kerjasama_horizontal_white.png";
import Signin from "../pages/landing/Signin";
import UbahProfil from "../pages/UbahProfil";
import UbahSandi from "../pages/UbahKataSandi";
import { getUserpemodal } from "../actions/userpemodal.actions";
import { getUserpengelola } from "../actions/userpengelola.actions";
import { Navbar, Nav, NavDropdown, Dropdown, ButtonGroup, Button, Modal } from "react-bootstrap";
import { Grid, Header, Segment, Portal, Icon, Label } from 'semantic-ui-react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";
import $ from 'jquery'
import {
  Dropdown as DropdownSemantic,
} from 'semantic-ui-react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class Navbar_user extends React.Component {

  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      open: false,
      dataNotif: [], countNotif: 0
    }
  }


  getUserpemodal = async (values) => {

    try {
      // console.log(this.props.auth.isUserId);
      const response = await this.props.dispatch(getUserpemodal(values));

      localStorage.DataUser = JSON.stringify(response.responseBody);
      // console.log(response.responseBody.success);

      if (!response.responseBody.success) {
        throw response;
      } else if (response.responseBody.success) {

        this.state.userinfo = JSON.stringify(response.responseBody.user);
        this.state.isLoad = true;

      }

    } catch (error) {
      console.log(error);
    }
  }

  getUserpengelola = async (values) => {

    try {
      // console.log(this.props.auth.isUserId);
      const response = await this.props.dispatch(getUserpengelola(values));

      localStorage.DataUser = JSON.stringify(response.responseBody);
      // console.log(response.responseBody.success);

      if (!response.responseBody.success) {
        throw response;
      } else if (response.responseBody.success) {

        this.state.userinfo = JSON.stringify(response.responseBody.user);
        this.state.isLoad = true;

      }

    } catch (error) {
      console.log(error);
    }
  }
  componentDidMount() {
    topbar.config({
      autoRun: false,
      barThickness: 5,
      barColors: {
        '0': 'rgba(0, 199, 80, 1)',
        '.5': 'rgba(255, 255, 255, 1)',
        '.8': 'rgba(0, 199, 80, 1)',
        '1.0': 'rgba(255, 255, 255, 1)'
      },
      shadowBlur: 5,
      shadowColor: 'rgba(3, 60, 133, 1)',
      className: 'topbar',
    })
    topbar.show();
    (function step() {
      setTimeout(function () {
        if (topbar.progress('+.01') < 1) step()
      }, 16)
    })()

    setTimeout(() => {
      topbar.hide();
    }, 4000);
    if (localStorage.token) {
      this.getNotif();
    }
  }

  getNotif() {
    try {
      var userData = JSON.parse(localStorage.DataUser);
      axios.get(BASE_URL + "/customer/notification", {
        params: {
          id_user: userData.user.user_id,
        }
      },
        axios.defaults.headers.common['Authorization'] = localStorage.token,
        axios.defaults.headers.common['Accept'] = 'application/json'
      )
        .then((resp) => {
          var tmpBlmDibaca = []
          resp.data.data.map((a, i) => {
            if (a.view == 0) {
              tmpBlmDibaca.push(a)
            }
          })

          this.setState({
            dataNotif: resp.data.data,
            countNotif: tmpBlmDibaca.length,
          })

          resp.data.data.map((a, i) => {
            if (a.view == 1) {
              $(".headerNotifikasi" + a.id_notifikasi).attr('style', 'opacity:0.5')
              $('.contentNotifikasi' + a.id_notifikasi).attr('style', 'opacity:0.5')
            } else {
              $(".headerNotifikasi" + a.id_notifikasi).removeAttr('style')
              $('.contentNotifikasi' + a.id_notifikasi).removeAttr('style')
            }
            $('.contentNotifikasi' + a.id_notifikasi).html(a.content)
          })

        })
        .catch((resp) => {
          setTimeout(() => {
            window.location.href = "/Signin"
            localStorage.removeItem('token');
            localStorage.clear();

            this.props.dispatch({
              type: "AUTH_USER_LOGOUT",
            })
          }, 2000);
        });
    } catch (e) {
      console.log(e)
    }
  }

  allRead() {
    var userData = JSON.parse(localStorage.DataUser);
    var values = {}
    values.id_user = userData.user.user_id

    let config = {
      headers: {
        'Authorization': localStorage.token,
        'content-type': 'application/json',
        'Access-Control-Allow-Credentials': true,
        'Access-Control-Allow-Methods': 'POST',
      },
      maxContentLength: 52428890,
      timeout: 10000000,
    }
    var tmpBlmDibaca = []

    axios.post(BASE_URL + "/customer/notification", values, config)
      .then((resp) => {

        resp.data.data.map((a, i) => {
          if (a.view == 1) {
            $(".headerNotifikasi" + a.id_notifikasi).attr('style', 'opacity:0.5')
            $('.contentNotifikasi' + a.id_notifikasi).attr('style', 'opacity:0.5')
          } else {
            tmpBlmDibaca.push(i)
            $(".headerNotifikasi" + a.id_notifikasi).removeAttr('style')
            $('.contentNotifikasi' + a.id_notifikasi).removeAttr('style')
          }
          $('.contentNotifikasi' + a.id_notifikasi).html(a.content)
        })

        this.setState({
          countNotif: '0'
        })
      })
      .catch((resp) => {

      });

  }

  renderDashboard() {
    if (this.props.auth.isLoggedInPemodal == true) {
      return <Dropdown.Item href="/UserPemodal"><i className="fa fa-fw fa-tachometer mr-2"></i>Dashboard</Dropdown.Item>;
    } else {
      return <Dropdown.Item href="/UserPengelola"><i className="fa fa-fw fa-tachometer mr-2"></i>Dashboard</Dropdown.Item>
    }
  }

  renderShowProfil() {
    if (this.props.auth.isLoggedInPemodal == true) {
      return <Dropdown.Item href="/Profilepemodal"><i className="fa fa-fw fa-user mr-2" ></i>Lihat Profil</Dropdown.Item>
    } else {
      return <Dropdown.Item href="/Profilepenerbit"><i className="fa fa-fw fa-user mr-2" ></i>Lihat Profil</Dropdown.Item>
    }
  }

  renderubahProfil() {
    if (this.props.auth.isLoggedInPemodal == true) {
      return <UbahProfil isUser='1' />
    } else {
      return <UbahProfil isUser='2' />
    }
  }

  renderChangeDashboard() {
    if (this.props.auth.isLoggedInPemodal == true) {
      return <NavDropdown
        title="Pemodal"
        id="collasible-nav-dropdown"
        className="mx-2"
      >
        <NavDropdown.Item href="/UserPengelola"><i className="fa fa-fw fa-tachometer mr-2"> <span>Penerbit</span></i></NavDropdown.Item>
      </NavDropdown>
    } else {
      return <NavDropdown
        title="Penerbit"
        id="collasible-nav-dropdown"
        className="mx-2"
      >
        <NavDropdown.Item href="/UserPemodal"><i className="fa fa-fw fa-tachometer mr-2"> <span>Pemodal</span></i></NavDropdown.Item>
      </NavDropdown>
    }
  }

  renderubahSandi() {
    if (this.props.auth.isLoggedInPemodal == true) {
      return <UbahSandi isUser='1'/>
    } else {
      return <UbahSandi isUser='2'/>
    }
  }

  handleClose = () => this.setState({ open: false })

  logout = () => {
    this.setState({ open: true })

    axios.get(BASE_URL + "/customer/auth/logout", {
      params: {
        token: localStorage.token,
      }
    },
      axios.defaults.headers.common['Authorization'] = localStorage.token,
      axios.defaults.headers.common['Accept'] = 'application/json'
    )
      .then((resp) => {
        setTimeout(() => {
          window.location.href = "/Signin"
          localStorage.clear();
          // localStorage.removeItem('token');
          // localStorage.removeItem('DataUser');

          this.props.dispatch({
            type: "AUTH_USER_LOGOUT",
          })
        }, 500);
      })
      .catch((resp) => {

      });
  }

  render() {
    const { open, dataNotif } = this.state
    const trigger = (
      <span>
        <Icon name='bell' style={{ color: 'white !important' }}>
          {(this.state.countNotif > 0) ?
            <><Label color='red' floating size='mini' className='notifBell'>
              {this.state.countNotif}
            </Label></>
            : ''}
        </Icon>
      </span>
    )

    return (
      <Router>

        <Switch>

          <Route path="/Signin">
            <Signin />
          </Route>


        </Switch>

        <Grid columns={2}>
          <Grid.Column>
            <Portal onClose={this.handleClose} open={open}>
              <Segment
                style={{
                  left: '40%',
                  position: 'fixed',
                  top: '40%',
                  zIndex: 1000,
                }}
              >
                <Header><Icon loading name='spinner' color="green" /> Logout Berhasil...</Header>
                <p>Anda akan diarahkan ke halaman login...</p>
              </Segment>
            </Portal>
          </Grid.Column>
        </Grid>

        <Navbar
          collapseOnSelect
          expand="lg"
          variant="dark"
          fixed="top"
          className="navbar"
          style={{
            top: 0,
            boxShadow: "0px 2px 10px 1px #0009"
          }}
        >
          <div className="container">
            {
              (this.props.pembayaran == 1) ?
                (
                  <>
                    {(this.props.auth.isLoggedInPemodal == true) ?
                      (<><a href="/UserPemodal">
                        <img
                          src={logo_kerjasama}
                          style={{ height: "35px" }}
                        />
                      </a></>)
                      :
                      (
                        <><a href="/UserPengelola">
                          <img
                            src={logo_kerjasama}
                            style={{ height: "35px" }}
                          />
                        </a></>
                      )
                    }

                    <div className="row ml-auto">
                      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                      <Navbar.Collapse id="responsive-navbar-nav" className="mr-auto">
                        <Nav className="ml-auto">
                          <Nav.Link href='#' className="nav-link mx-2" id="nav">
                            Pembayaran
                          </Nav.Link>
                        </Nav>
                      </Navbar.Collapse>
                    </div>
                  </>
                )
                :
                (this.props.verifikasiotp == 1) ?
                  (
                    <>
                      <img
                        src={logo_kerjasama}
                        style={{ height: "35px" }} />

                      <div className="row ml-auto">
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav" className="mr-auto">
                          <Nav className="ml-auto">
                            <Nav.Link href='#' className="nav-link mx-2" id="nav">
                              Verifikasi OTP
                            </Nav.Link>
                          </Nav>
                        </Navbar.Collapse>
                      </div>
                    </>
                  )
                  :
                  (
                    <>
                      <a href="/">
                        <img
                          src={logo_kerjasama}
                          style={{ height: "50px" }}
                        />
                      </a>

                      <div className="row ml-auto">
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav" className="mr-auto">
                          <Nav className="ml-auto">
                            {/* <Nav.Link href='/' className="nav-link mx-2" id="nav">BERANDA</Nav.Link> */}
                            <NavDropdown
                              title="PANDUAN"
                              id="collasible-nav-dropdown"
                              className="mx-2">
                              <NavDropdown.Item href="/Panduan">Pemodal/Penerbit</NavDropdown.Item>
                              <NavDropdown.Item href="/Pengaduan">Layanan Pengaduan</NavDropdown.Item>
                              {/* <NavDropdown.Item href="/Simulasi">SIMULASI PEMBAYARAN</NavDropdown.Item> */}
                            </NavDropdown>
                            <Nav.Link href="/Tentang" className="mx-2">
                              TENTANG KAMI
                            </Nav.Link>
                            <Nav.Link href="/Faq" className="mx-2">
                              FAQ
                            </Nav.Link>
                            {/* { (!localStorage.token) ? '' : this.renderChangeDashboard()  } */}
                            {(!localStorage.token) ?
                              (
                                <div className="d-flex">
                                  <a href="/Signin" className="btn masuk mx-2 ">MASUK</a>
                                  <i className="nav-line-horizontal"></i>
                                  <Daftar />
                                </div>
                              ) : (
                                <Dropdown as={ButtonGroup}>
                                  <Nav.Link className="mx-2">
                                    {/* <i className="fa fa-fw fa-bell mr-3" id="font"></i> */}
                                    <DropdownSemantic simple item trigger={trigger} >
                                      <DropdownSemantic.Menu className='detNotif'>
                                        {(this.state && dataNotif && dataNotif.length > 0) ?
                                          dataNotif.map(item => (
                                            <>
                                              <DropdownSemantic.Header icon='mail' content='Notifikasi' className={'headerNotifikasi' + item.id_notifikasi} />
                                              <Container style={{ color: '#212529' }}>
                                                <Col xs md="12">
                                                  <DropdownSemantic.Item className={'contentNotifikasi' + item.id_notifikasi} />
                                                </Col>
                                              </Container>
                                            </>
                                          )) : ''}

                                        {(this.state && dataNotif && dataNotif.length > 0) ?
                                          <><DropdownSemantic.Item onClick={this.allRead.bind(this)} content='Tandai Semua Dibaca' style={{ textAlign: 'center', color: '#214981' }} /></>
                                          : <><DropdownSemantic.Item content='Tidak Ada Pesan Baru' style={{ textAlign: 'center', color: '#214981' }} /></>}

                                      </DropdownSemantic.Menu>
                                    </DropdownSemantic>
                                  </Nav.Link>
                                  <Button style={{ background: "#214981", outline: "none" }} ><i className="fa fa-fw fa-user" id="font"></i></Button>

                                  <Dropdown.Toggle split style={{ background: "#214981" }} id="dropdown-split-basic" />

                                  <Dropdown.Menu className="mrghead">
                                    {this.renderDashboard()}
                                    <Dropdown.Divider />
                                      {/* <Dropdown.Item href="/Profilepemodal"><i className="fa fa-fw fa-user mr-2" ></i>Lihat Profil</Dropdown.Item> */}
                                      {/* {this.renderubahProfil()} */}
                                      {this.renderShowProfil()}
                                      {this.renderubahSandi()}
                                    <Dropdown.Divider />
                                    <Dropdown.Item href="/SyaratDanKetentuan"><i className="fa fa-fw fa-file-text mr-2" ></i>Syarat dan Ketentuan</Dropdown.Item>
                                    <Dropdown.Item href="/KebijakanPrivasi"><i className="fa fa-fw fa-shield mr-2" ></i>Kebijakan Privasi</Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={this.logout}><i className="fa fa-fw fa-sign-out mr-2" ></i>Logout</Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              )}
                          </Nav>
                        </Navbar.Collapse>
                      </div>
                    </>
                  )}

          </div>
        </Navbar>

      </Router>
    );
  }
}

function Daftar() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" className="colorGreenBtn" onClick={handleShow}>
        DAFTAR
      </Button>

      <Modal show={show} onHide={handleClose} animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered>

        <Modal.Title style={{ marginLeft: "auto", marginRight: "auto", paddingTop: "50px", color: "#033C86", fontSize: "26px", fontWeight: "500" }}>Daftar Sebagai ?</Modal.Title>


        <div className="row text-center" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
          <div className="col-md-6">
            <a href="/Daftarpemodal" className="btn my-4" style={{ background: "#214981", color: "#ffffff", padding: "5px 50px", borderRadius: "20px" }}>Pemodal</a>
          </div>
          <div className="col-md-6">
            <a href="/Daftarpengelola" className="btn my-4" style={{ background: "#214981", color: "#ffffff", padding: "5px 50px", borderRadius: "20px" }}>Penerbit</a>
          </div>
        </div>


      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  return { getUser: state.userReducer.getUser, auth: state.authReducer.authData }
}

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar_user);
