import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import Navbar from "../../components/navbar_home";
import Footer from "../../components/Footer";
import Pemodal from "../../assets/img/pemodal.png";
import "../../App.scss";

class Panduan extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.isLoading = true;
    console.log(this.props);
  }

  render() {
    return (
      <div>
        <Navbar />

        <Body />
        <Footer />
      </div>
    );
  }
}
function Body() {
  return (
    <div class="container" style={{ marginTop: "100px" }}>
      <div class="row">
        <div class="col-lg-12">
          <div class="title">
            <h1
              style={{
                color: "#033c85",
                fontWeight: "700",
                textTransform: "uppercase",
                fontFamily: "Helvetica",
              }}
            >
              panduan menjadi pemodal/penerbit
            </h1>
            <img
              src={Pemodal}
              class="img-fluid"
              className="mt-5"
              style={{ width: "100%" }}
              alt=""
            />
          </div>
          <div class="isi mt-5">
            <h2
              style={{
                color: "#343a40",
                fontWeight: "700",
                fontFamily: "Helvetica",
              }}
            >
              1. Kerjasama.co.id sebagai Penyelenggara (platform)
            </h2>
            <p
              class="text-muted"
              style={{ color: "#474d6d", fontWeight: "500", fontSize: "18px" }}
            >
              Kerjasama.co.id mempertemukan Pemodal dengan Penerbit dalam
              kerjasama usaha dengan model Syirkah secara Syar’i yang menekankan
              kepada etika, keadilan dan kesetaraan.
            </p>
            <br />
            <h2
              className="mb-5"
              style={{
                color: "#343a40",
                fontWeight: "700",
                fontFamily: "Helvetica",
              }}
            >
              2. Bagaimana menjadi Pemodal melalui platform Kerjasama.co.id?
            </h2>
            <div class="row text-center">
              <div class="col-md-4">
                <img src="https://i.ibb.co/kSz0P8s/Group1.png" alt="" />
                <h6
                  style={{
                    color: "#343a40",
                    fontWeight: "600",
                    fontSize: "22px",
                  }}
                >
                  Daftar Di Kerjasama.co.id
                </h6>
                <p
                  id="isi"
                  class="text-muted mt-4"
                  style={{
                    color: "#474d6d",
                    fontWeight: "500",
                    fontSize: "18px",
                  }}
                >
                  Lakukan pendaftaraan pengguna layanan di portal
                  kerjasama.co.id melalui
                </p>
              </div>
              <div class="col-md-4">
                <img src="https://i.ibb.co/M5hk8km/Group2.png" alt="" />
                <h6
                  style={{
                    color: "#343a40",
                    fontWeight: "600",
                    fontSize: "22px",
                  }}
                >
                  Melengkapi Dokumen
                </h6>
                <p
                  id="isi"
                  class="text-muted mt-4"
                  style={{
                    color: "#474d6d",
                    fontWeight: "500",
                    fontSize: "18px",
                  }}
                >
                  Lengkapi data-data pribadi anda dengan mengunggah beberapa
                  dokumen, seperti e-KTP, NPWP, Buku tabungan. Tunggu
                  verivikasi.
                </p>
              </div>
              <div class="col-md-4">
                <img src="https://i.ibb.co/rFqNzW3/Group3.png" alt="" />
                <h6
                  class="text-center"
                  style={{
                    color: "#343a40",
                    fontWeight: "600",
                    fontSize: "22px",
                  }}
                >
                  Pilih Proyek Yang Anda Danai
                </h6>
                <p
                  id="isi"
                  class="text-muted mt-4"
                  style={{
                    color: "#474d6d",
                    fontWeight: "500",
                    fontSize: "18px",
                  }}
                >
                  Tentukan proyek yang akan anda danai, review prospektusnya
                  beserta simulasi proyeksi keuntungan anda dan masukan jumlah
                  slot pendanaan anda.
                </p>
              </div>
              <div class="col-md-4 mt-5">
                <img src="https://i.ibb.co/8M2G3JP/Group4.png" alt="" />
                <h6
                  class="text-center"
                  style={{
                    color: "#343a40",
                    fontWeight: "600",
                    fontSize: "22px",
                  }}
                >
                  Danai Proyek Pilihan Anda
                </h6>
                <br />
                <p
                  class="text-muted mt-4"
                  style={{
                    color: "#474d6d",
                    fontWeight: "500",
                    fontSize: "18px",
                  }}
                >
                  Lakukan pembayaran menggunakan Virtual Account Bank pilihan
                  anda. Tanda tangani akad-akad syariahnya menggunakan Digital
                  Signature.
                </p>
              </div>
              <div class="col-md-4 mt-5">
                <img src="https://i.ibb.co/h76jjgM/Group5.png" alt="" />
                <h6
                  class="text-center"
                  style={{
                    color: "#343a40",
                    fontWeight: "600",
                    fontSize: "22px",
                  }}
                >
                  Pencairan Dana Anda Terhadap Proyek
                </h6>
                <p
                  class="text-muted mt-4"
                  style={{
                    color: "#474d6d",
                    fontWeight: "500",
                    fontSize: "18px",
                  }}
                >
                  Dana anda segera kami cairkan pada penerbit proyek, setelah
                  seluruh kebutuhan dana terkumpul pada escrow account
                  kerjasma.co.id
                </p>
              </div>
            </div>
            <br />
            <h2
              className="mb-5"
              style={{
                color: "#343a40",
                fontWeight: "700",
                fontFamily: "Helvetica",
              }}
            >
              3. Bagaimana menjadi Penerbit melalui platform Kerjasama.co.id?
            </h2>
            <div class="row text-center">
              <div class="col-md-4">
                <img src="https://i.ibb.co/hDWHXV5/Group-891.png" alt="" />
                <h6
                  style={{
                    color: "#343a40",
                    fontWeight: "600",
                    fontSize: "22px",
                  }}
                >
                  Daftar Di Kerjasama.co.id
                </h6>
                <p
                  class="text-muted mt-4"
                  style={{
                    color: "#474d6d",
                    fontWeight: "500",
                    fontSize: "18px",
                  }}
                >
                  Lakukan pendaftaraan pengguna layanan di portal
                  kerjasama.co.id melalui
                </p>
              </div>
              <div class="col-md-4">
                <img src="https://i.ibb.co/hDWHXV5/Group-891.png" alt="" />
                <h6
                  style={{
                    color: "#343a40",
                    fontWeight: "600",
                    fontSize: "22px",
                  }}
                >
                  Melengkapi Dokumen
                </h6>
                <p
                  class="text-muted mt-4"
                  style={{
                    color: "#474d6d",
                    fontWeight: "500",
                    fontSize: "18px",
                  }}
                >
                  Lengkapi data-data pribadi anda dengan mengunggah beberapa
                  dokumen, seperti e-KTP, NPWP, Buku tabungan. Tunggu
                  verivikasi.
                </p>
              </div>
              <div class="col-md-4">
                <img src="https://i.ibb.co/hDWHXV5/Group-891.png" alt="" />
                <h6
                  style={{
                    color: "#343a40",
                    fontWeight: "600",
                    fontSize: "22px",
                  }}
                >
                  Ajukan Pembiayaan Proyek Anda
                </h6>
                <p
                  class="text-muted mt-4"
                  style={{
                    color: "#474d6d",
                    fontWeight: "500",
                    fontSize: "18px",
                  }}
                >
                  Lengkapi data seperti, laporan keuangan, legalitas, beserta
                  foto yang mewakili proyek anda, tentukan presentase bagi hasil
                  yang anda inginkan, serta kebutuhan modal anda saat pengajuan.
                </p>
              </div>
              <div class="col-md-4">
                <img src="https://i.ibb.co/VMtrsbt/Group-892.png" alt="" />
                <h6
                  style={{
                    color: "#343a40",
                    fontWeight: "600",
                    fontSize: "22px",
                  }}
                >
                  Proses Survey, Analisa Pembiayaan, Hingga Persetujuan
                  Pembiayaan
                </h6>
                <p
                  class="text-muted mt-4"
                  style={{
                    color: "#474d6d",
                    fontWeight: "500",
                    fontSize: "18px",
                  }}
                >
                  Tim kami segera melakukan penilaian proyek anda melalui
                  survey, Analisa keuangan serta legalitas hukum dan Syariah
                  nya, sehingga kami dapat memutuskan secara fair dan transparan
                  kelayakan proyek anda untuk dibiayai pemodal.
                </p>
              </div>
              <div class="col-md-4">
                <img src="https://i.ibb.co/dtkZmBx/Group-893.png" alt="" />
                <h6
                  style={{
                    color: "#343a40",
                    fontWeight: "600",
                    fontSize: "22px",
                  }}
                >
                  Pengajuan Proyek Anda Disetujui
                </h6>{" "}
                <br />
                <br />
                <p
                  class="text-muted mt-4"
                  style={{
                    color: "#474d6d",
                    fontWeight: "500",
                    fontSize: "18px",
                  }}
                >
                  Pengajuan pembiayaan proyek anda, kami setujui. Tanda tangani
                  akad - akad Syariah nya setelah penyerahan surat - surat
                  jaminan anda. proyek anda siap kami rilis pada portal
                  kerjasama.co.id
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
}

export default Panduan;
